import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {Button} from "antd";

export const StyledLinkContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: rgba(217, 217, 217, 0.15);
    transform: scaleY(0);
    transition: transform 0.1s, width 0.2s cubic-bezier(1, 0, 0, 1) 0.1s;
    // border-radius: 1.2rem;
  }

  &:hover::before {
    transform: scaleY(1);
    width: 100%;
  }
`;

export const StyledNavLink = styled(NavLink)<{
  visible?: boolean;
}>`
  display: flex;
  align-items:center;
  height: 3.9rem;
  padding: 0 1.7rem;
  z-index: 10;
  position: relative;
  // margin-top: 1rem;
  
  .nav-label {
    margin-left: 1rem;
    color: #00171F;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
  }

  .nav-icon-container {
    width: 2rem;
    height: 2rem;
    color: #8C8C8C;

    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }    
  }

  .coming-soon-bubble {
    background-color: #F0F5FF;
    border: 1px solid #ADC6FF;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1rem;
    color: #2F54EB;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin-left: auto;
  }

  &:hover {
    // background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 100%;
    //   width: 5px;
    //   background-color: #000000;
    //   left: 0;
    //   top: 0;
    // }
  }

  &.active {
    background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: #000000;
      left: 0;
      top: 0;
    }
  }

  @media(max-width: 960px) {
    .nav-label {
      opacity: 1;
    }
    
  );
`;
export const StyledBtnLink = styled(Button)<{
  visible?: boolean;
}>`
  display: flex;
  align-items:center;
  height: 3.9rem;
  padding: 0 1.7rem;
  z-index: 10;
  position: relative;
  border: none;
  // margin-top: 1rem;
  
  .nav-label {
    margin-left: 1rem;
    color: #00171F;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
  }

  .nav-icon-container {
    width: 2rem;
    height: 2rem;
    color: #8C8C8C;

    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }    
  }

  .coming-soon-bubble {
    background-color: #F0F5FF;
    border: 1px solid #ADC6FF;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1rem;
    color: #2F54EB;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin-left: auto;
  }

  &:hover {
    // background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 100%;
    //   width: 5px;
    //   background-color: #000000;
    //   left: 0;
    //   top: 0;
    // }
  }

  &.active {
    background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: #000000;
      left: 0;
      top: 0;
    }
  }

  @media(max-width: 960px) {
    .nav-label {
      opacity: 1;
    }
    
  );
`;
export const StyledStaticNavLink = styled.span<{
  visible?: boolean;
}>`
  display: flex;
  align-items:center;
  height: 3.9rem;
  padding: 0 1.7rem;
  z-index: 10;
  position: relative;
  // margin-top: 1rem;
  
  .nav-label {
    margin-left: 1rem;
    color: #00171F;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
  }

  .nav-icon-container {
    width: 2rem;
    height: 2rem;
    color: #8C8C8C;

    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }    
  }

  .coming-soon-bubble {
    background-color: #F0F5FF;
    border: 1px solid #ADC6FF;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1rem;
    color: #2F54EB;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin-left: auto;
  }

  &:hover {
    // background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 100%;
    //   width: 5px;
    //   background-color: #000000;
    //   left: 0;
    //   top: 0;
    // }
  }

  &.active {
    background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: #000000;
      left: 0;
      top: 0;
    }
  }

  @media(max-width: 960px) {
    .nav-label {
      opacity: 1;
    }
    
  );
`;
export const StyledComingSoon = styled.span<{
  visible?: boolean;
}>`
  display: flex;
  align-items:center;
  height: 3.9rem;
  padding: 0 1.7rem;
  z-index: 10;
  position: relative;
  // margin-top: 1rem;
  
  .nav-label {
    margin-left: 1rem;
    color: #00171F;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
  }

  .nav-icon-container {
    width: 2rem;
    height: 2rem;
    color: #8C8C8C;

    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }    
  }

  .coming-soon-bubble {
    background-color: #F0F5FF;
    border: 1px solid #ADC6FF;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1rem;
    color: #2F54EB;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin-left: auto;
  }

  &:hover {
    // background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 100%;
    //   width: 5px;
    //   background-color: #000000;
    //   left: 0;
    //   top: 0;
    // }
  }

  &.active {
    background-color: rgba(217, 217, 217, 0.15);
    color: #9747FF;
    
    .nav-label , .nav-icon-container{
      font-weight: 600;
      color: #9747FF;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 5px;
      background-color: #000000;
      left: 0;
      top: 0;
    }
  }

  @media(max-width: 960px) {
    .nav-label {
      opacity: 1;
    }
    
  );
`;
