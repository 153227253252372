import {useState} from "react";
import {useCalendar} from "../../../hooks/use-calendar";
import {CalendarBody} from "../calendar-body";
import {CalendarHeader} from "../calendar-header";
import {StyledCalendarWrapper} from "../calendar.style";
import {
  TPublicProfileBookingApiResponse,
  TTimeslotsPayload,
} from "../../../react-query-hooks/public-profile";
import {UpdateStateDateInfoParams} from "../../calls/upcoming-calls/call-card/call-card-menu/reschedule-call-modal/calendar";

type NewCustomCalendarProps = {
  fetchTimeSlots: (args: TTimeslotsPayload) => void;
  unavailableDates: TPublicProfileBookingApiResponse["unavailableDates"];
  availableDays: TPublicProfileBookingApiResponse["days"];
  coachTimezone: TPublicProfileBookingApiResponse["timeZone"];
  customerTimezone: TPublicProfileBookingApiResponse["timeZone"];
  mentorId: TPublicProfileBookingApiResponse["profile"]["userId"];
  serviceId: TPublicProfileBookingApiResponse["service"]["id"];
  resetStateTimeSelected: () => void;
  updateStateDateInfo: (args: UpdateStateDateInfoParams) => void;
};

export function NewCustomCalendar({
  fetchTimeSlots,
  unavailableDates,
  availableDays,
  coachTimezone,
  customerTimezone,
  mentorId,
  serviceId,
  resetStateTimeSelected,
  updateStateDateInfo,
}: NewCustomCalendarProps) {
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  // const {dispatch} = useBookingContext();

  function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      const dateToAdd = new Date(date);
      dates.push(
        `${dateToAdd.getFullYear()}-${dateToAdd.getMonth() + 1}-${dateToAdd.getDate()}`
      );
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const unavailableDateRanges = unavailableDates.map((date) =>
    getDatesInRange(new Date(date.from), new Date(date.to))
  );
  let unavailableDateRangesState: string[] = [];

  if (unavailableDateRanges) {
    const newUD = [].concat(...(unavailableDateRanges as any));
    const uniqueArryDateRange = [...(new Set(newUD) as any)];
    unavailableDateRangesState = [...uniqueArryDateRange];
  }

  const arrDays = [];
  for (const key in availableDays) {
    arrDays.push({
      name: key,
      value: (availableDays as any)[key].length > 0,
    });
  }
  const arrDaysNum = arrDays
    .filter((day) => day.value)
    .map((day) =>
      day.name === "sun"
        ? 0
        : day.name === "mon"
        ? 1
        : day.name === "tue"
        ? 2
        : day.name === "wed"
        ? 3
        : day.name === "thu"
        ? 4
        : day.name === "fri"
        ? 5
        : day.name === "sat"
        ? 6
        : null
    );
  const {
    days,
    currentMonth: newCurrentMonth,
    currentYear: year,
    currentSelectedDate,
    setCurrentSelectedDate,
  } = useCalendar(currentMonth, currentYear, arrDaysNum, unavailableDateRangesState);

  const handlePrev = () => {
    setCurrentMonth((prevState) => prevState - 1);
    if (currentMonth < 1) {
      setCurrentMonth(11);
      setCurrentYear((prevState) => prevState - 1);
    }
  };

  const handleNext = () => {
    setCurrentMonth((prevState) => prevState + 1);
    if (currentMonth > 10) {
      setCurrentMonth(0);
      setCurrentYear((prevState) => prevState + 1);
    }
  };
  const handleSetCurrentDate = (day: number | null) => {
    if (!day) {
      return;
    }
    const selectedDateString = new Date(year, currentMonth, day);
    // dispatch({
    //   type: "update-selected-date",
    //   payload: selectedDateString,
    // });
    resetStateTimeSelected();
    updateStateDateInfo({
      currentMonth,
      day,
      selectedDateString,
      year,
    });

    setCurrentSelectedDate(selectedDateString);

    const payload = {
      mentorId,
      serviceId,
      date: day,
      year,
      month: currentMonth + 1,
      day:
        selectedDateString.getDay() === 0
          ? "sun"
          : selectedDateString.getDay() === 1
          ? "mon"
          : selectedDateString.getDay() === 2
          ? "tue"
          : selectedDateString.getDay() === 3
          ? "wed"
          : selectedDateString.getDay() === 4
          ? "thu"
          : selectedDateString.getDay() === 5
          ? "fri"
          : "sat",
      mentorTimeZone: String(coachTimezone),
      userTimeZone: String(customerTimezone),
    };
    fetchTimeSlots(payload);
  };
  return (
    <StyledCalendarWrapper>
      <CalendarHeader
        currentMonth={newCurrentMonth}
        year={year}
        month={currentMonth}
        currentSelectedDate={currentSelectedDate}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <CalendarBody
        days={days}
        setCurrentDate={handleSetCurrentDate}
        currentSelectedDate={currentSelectedDate}
      />
    </StyledCalendarWrapper>
  );
}
