import {CheckCircleTwoTone} from "@ant-design/icons";
import {Space, Button} from "antd";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

const CustomerCancelBookingSuccessPage = () => {
  const {customerBooking} = useSelector((state: RootState) => state.booking);
  return (
    <div>
      <div className="profile-widget-card">
        <Space size={16} direction="vertical" style={{display: "flex"}}>
          <div>
            <CheckCircleTwoTone twoToneColor="#52C41A" style={{fontSize: "2.1rem"}} />
          </div>
          <div className="profile-widget-card-header">
            <h2>Cancellation confirmed</h2>
            <p
              style={{
                marginTop: "20px",
              }}
            >
              Your call with {customerBooking.mentor?.firstName}{" "}
              {customerBooking.mentor?.lastName} has been cancelled, you will recieve a
              full refund
            </p>
            {/* <h3 className="profile-widget-card-user-name">{serviceData.name}</h3> */}
            {/* <Button
              style={{
                width: "180px",
                marginTop: "40px",
              }}
              size="large"
              block
              shape="round"
              htmlType="button"
            >
              Return to profile
            </Button> */}
          </div>
        </Space>
      </div>
    </div>
  );
};

export {CustomerCancelBookingSuccessPage};
