import {AxiosError} from "axios";
import {useClient} from "../../utils/hooks";
import {useQuery} from "@tanstack/react-query";

type TDashboardMetricsApiResponse = {
  totalCustomers: number;
  totalRevenue: {currency?: string; amount?: number}[];
  totalPurchases: number;
  currency: string;
};

const useGetDashboardMetrics = () => {
  const client = useClient();

  return useQuery<
    TDashboardMetricsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["dashboard_metrics"],
    queryFn: () => client("dashboard/metrics-count").then((data) => data.data),
  });
};

export {useGetDashboardMetrics};
