import {yupResolver} from "@hookform/resolvers/yup";
import {useFieldArray, useForm, Controller} from "react-hook-form";
import {CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Space, Col, Row, message, Select} from "antd";
import {StyledServiceButtonWrapper} from "../../../styles/onboarding/onboarding";
import {IEditProfileFormValues} from "./edit-profile";
import {step1Schema} from "./edit-profile-validation-schema";
import {CustomInputComponent} from "../../../components/input/input-component";
import {CustomSelectInput} from "../../../components/select";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux/store";
import {useEffect, useState} from "react";
import {TProfileApiResponse, TProfilePayload} from "../../../redux/profile/i-profile-1";
import useDebounce from "../../../hooks/use-debounce";
import {resetUsernameState, verifyUsername} from "../../../redux/username/username-slice";
import {SocialOptions} from "../../../container/onboarding/step-1/step-1-utils";
import {useUpdateProfile} from "../../../react-query-hooks/profile";
import {
  buildSocialsObject,
  handleAddNewSocialLink,
  handleRemoveSocialLink,
  handleSocialSelectOnChange,
  updateSocialOptionsState,
} from "../../../utils/profile";
import {AvatarUpload} from "./avatar-upload";
import {ProfileCoverImageUpload} from "./cover-image-upload";

declare const window: Window &
  typeof globalThis & {
    Intercom: any;
    heap: any;
  };

const {Option} = Select;

export type TEditProfileFormProps = {
  countries: {
    name: string;
    code: string;
  }[];
  profileData: TProfileApiResponse;
};

export type TProfileSocial = {
  behance: string | null;
  dribble: string | null;
  discord: string | null;
  facebook: string | null;
  github: string | null;
  instagram: string | null;
  linkedin: string | null;
  tiktok: string | null;
  twitch: string | null;
  twitter: string | null;
  website: string | null;
  youtube: string | null;
};

const EditProfileForm: React.FC<TEditProfileFormProps> = ({countries, profileData}) => {
  const [socialOptions, setSocialOptions] = useState(SocialOptions);
  const dispatch = useDispatch<AppDispatch>();

  const updateProfileQueryMutation = useUpdateProfile();

  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const {
    isError: isErrorUsername,
    isSuccess: isSuccessUsername,
    message: usernameMessage,
  } = useSelector((state: RootState) => state.username);

  const usernameString =
    profileData.username && profileData.username.split("https://www.coachli.co/");

  const {
    handleSubmit,
    formState: {errors},
    reset,
    watch,
    control,
  } = useForm<IEditProfileFormValues>({
    resolver: yupResolver(step1Schema),
    mode: "onChange",
    defaultValues: {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      country: profileData.country,
      username: usernameString ? usernameString[0] : "",
      shortDescription: profileData.description,
      shortBio: profileData.shortBio,
      socials: [...buildSocialsObject(profileData)],
    },
  });

  const {fields, append, remove} = useFieldArray({
    control,
    name: "socials",
  });

  const socialsArrayValue = watch("socials");
  const usernameValue: string = watch("username").toLowerCase();
  const deb = useDebounce(usernameValue.toLowerCase(), 1000);

  useEffect(() => {
    if (deb && deb.length > 3) {
      dispatch(verifyUsername(usernameValue));
    }
    dispatch(resetUsernameState());
  }, [deb, usernameValue, dispatch]);

  // TO ALWAYS KEEP THE SOCIAL OPTIONS UP TO DATE WITH NEW VALUES
  useEffect(() => {
    updateSocialOptionsState(socialsArrayValue, setSocialOptions);
  }, [socialsArrayValue]);

  const onSubmit = async (values: IEditProfileFormValues) => {
    const {firstName, country, lastName, shortBio, shortDescription, socials, username} =
      values;
    const socialValues = socials;
    const socialObject: TProfileSocial = {
      twitter: null,
      facebook: null,
      instagram: null,
      linkedin: null,
      tiktok: null,
      discord: null,
      youtube: null,
      dribble: null,
      behance: null,
      twitch: null,
      github: null,
      website: null,
    };

    if (socialValues.length > 0) {
      socialValues.forEach((elem, i) => {
        if (elem.name === "Dribbble") {
          socialObject["dribble"] = elem.link;
        } else {
          socialObject[elem.name.toLowerCase() as keyof typeof socialObject] = elem.link;
        }
      });
    }

    // console.log({...values, ...socialObject});
    const payload: TProfilePayload = {
      firstName,
      country,
      lastName,
      shortBio,
      shortDescription,
      username,
      ...socialObject,
    };

    updateProfileQueryMutation.mutate(payload, {
      onSuccess: (data) => {
        // console.log("update profile data", data);
        window.scrollTo(0, 0);
        message.success("Your profile has been updated successfully!");
        window.heap.identify(
          `${userInfo.user.id}: ${data.data.firstName} ${data.data.lastName}`
        );
        window.Intercom("update", {
          // api_base: "https://api-iam.intercom.io",
          // app_id: "lhv6fa0p",
          name: `${data.data.firstName} ${data.data.lastName}`, // Full name
          email: `${data.data.email.toLowerCase()}`, // Email address
          user_id: `${userInfo.user.id}`,
        });
      },
    });
  };
  return (
    <Space direction="vertical" size={24} style={{display: "flex"}}>
      <ProfileCoverImageUpload />
      <AvatarUpload username={usernameValue} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={29} style={{display: "flex"}}>
          <Row gutter={[16, 29]}>
            <Col span={24} md={12}>
              {" "}
              <CustomInputComponent
                id="firstName"
                label="First name"
                placeholder="Enter your first name"
                error={errors.firstName?.message}
                control={control}
                name="firstName"
              />
            </Col>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="lastName"
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                error={errors.lastName?.message}
                control={control}
              />
            </Col>
          </Row>
          <CustomInputComponent
            id="username"
            name="username"
            label="Username"
            placeholder="your username"
            error={
              errors?.username
                ? errors.username.message
                : isErrorUsername
                ? usernameMessage
                : ""
            }
            control={control}
            addonBefore={`${window.location.origin}/`}
          />
          <CustomInputComponent
            id="description"
            name="shortDescription"
            label="Short description"
            placeholder="E.g UX Designer"
            error={errors.shortDescription?.message}
            control={control}
          />

          <CustomSelectInput
            control={control}
            id="country"
            label="Country"
            name="country"
            placeholder="Select your Country"
            options={countries}
            showSearch
            error={errors.country?.message}
            style={{width: "100%"}}
          />
          <CustomInputComponent
            id="shortBio"
            name="shortBio"
            type="textarea"
            label="Short bio"
            placeholder="Tell your audience about yourself"
            error={errors.shortBio?.message}
            control={control}
          />

          {fields.slice(0, 12).map((item, index) => (
            <div key={item.id} style={{display: "flex", gap: "1.6rem"}}>
              {/* // <Space size={16} key={item.id}> */}
              <div style={{flex: "1"}}>
                <CustomInputComponent
                  id={`socials[${index}].value`}
                  name={`socials[${index}].link`}
                  placeholder="your profile link"
                  error={errors.socials?.[index]?.link?.message}
                  control={control}
                  // style={{width: '9'}}
                  addonBefore={
                    <Controller
                      control={control}
                      name={`socials.${index}.name`}
                      render={({field: {onChange, value, ref}}) => (
                        <>
                          <Select
                            onChange={(val) =>
                              handleSocialSelectOnChange(
                                socialsArrayValue,
                                setSocialOptions,
                                onChange,
                                val
                              )
                            }
                            value={value}
                            ref={ref}
                            size="large"
                            status={errors.socials?.[index]?.name?.message ? "error" : ""}
                            showSearch={false}
                            notFoundContent={null}
                            placeholder={""}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {socialOptions.map((option, optionIdx: number | string) => (
                              <Option key={optionIdx} value={option.name}>
                                {option.name}
                              </Option>
                            ))}
                          </Select>
                          {errors.socials?.[index]?.name?.message && (
                            <div role="alert" className="ant-form-item-explain-error">
                              {errors.socials?.[index]?.name?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                  }
                />
              </div>

              <Button
                onClick={() =>
                  handleRemoveSocialLink(
                    socialsArrayValue,
                    setSocialOptions,
                    remove,
                    index
                  )
                }
                type="link"
                shape="circle"
                icon={<CloseOutlined />}
                size="large"
              />
              {/* </Space> */}
            </div>
          ))}

          {fields.length < 12 && (
            <Button
              icon={<PlusCircleOutlined />}
              type="link"
              onClick={() =>
                handleAddNewSocialLink(socialsArrayValue, setSocialOptions, append)
              }
              style={{paddingLeft: 0}}
            >
              Add social media
            </Button>
          )}

          <Row gutter={[0, 16]}>
            <Col span={24} sm={12}>
              <StyledServiceButtonWrapper>
                <Button
                  disabled={
                    Object.keys(errors).length > 0 || Object.values(watch()).length < 1
                  }
                  size="large"
                  block
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  loading={updateProfileQueryMutation.isLoading}
                >
                  Update Profile
                </Button>
                <Button
                  onClick={() => reset()}
                  block
                  size="large"
                  shape="round"
                  type="default"
                >
                  Discard changes
                </Button>
              </StyledServiceButtonWrapper>
            </Col>
          </Row>
        </Space>
      </form>
    </Space>
  );
};

export {EditProfileForm};
