import {
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {Button, Image, Result, Space, Spin} from "antd";
import {useParams} from "react-router-dom";
import {LoadingSpinner} from "../../../components/icons/icons";
import {PublicLayout} from "../../../layouts/public";
import {useGetCustomerBooking} from "../../../react-query-hooks/booking";
import {StyledFlexContainer} from "../../../styles/email-verification/email-verification";
import {
  StyledJoinCallBox,
  StyledJoinCallExtraInfo,
} from "../../../styles/meetings/meetings";
import {handleMeetingLink} from "../../../utils/meeting";

const JoinMeetingPage = () => {
  const {reference} = useParams();

  const getCustomerBookingQuery = useGetCustomerBooking({
    reference,
    userType: "guest_user",
  });

  if (getCustomerBookingQuery.isLoading) {
    return (
      <PublicLayout>
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      </PublicLayout>
    );
  }
  if (getCustomerBookingQuery.isError) {
    return (
      <PublicLayout>
        <Result
          status="error"
          title="An Error Occured"
          subTitle={getCustomerBookingQuery.error.message}
          extra={[
            <Button
              onClick={() => getCustomerBookingQuery.refetch}
              shape="round"
              type="default"
              key="console"
            >
              Try again
            </Button>,
          ]}
        />
      </PublicLayout>
    );
  }

  const callDate = new Date(
    Number(getCustomerBookingQuery.data.booking.booking_year),
    Number(getCustomerBookingQuery.data.booking.booking_month) - 1,
    Number(getCustomerBookingQuery.data.booking.booking_date)
  ).toDateString();

  const disableButton = () => {
    if (!getCustomerBookingQuery.data.bookingToken) {
      return true;
    } else {
      return false;
    }
  };

  const handleMeetingPlatform = (meetingPlatform: "dailyCo" | "videoSdk") => {
    if (meetingPlatform.toLowerCase() === "dailyco") {
      return `${getCustomerBookingQuery.data.dailyCoUrl}?t=${getCustomerBookingQuery.data.bookingToken}`;
    } else {
      return handleMeetingLink("guest", {
        token: getCustomerBookingQuery.data.bookingToken,
        roomId: getCustomerBookingQuery.data.booking.roomId,
        firstName: getCustomerBookingQuery.data.booking.first_name,
        lastName: getCustomerBookingQuery.data.booking.last_name,
        meetingTitle: getCustomerBookingQuery.data.service.name,
      });
    }
  };

  return (
    <PublicLayout>
      <StyledFlexContainer>
        <Spin
          spinning={getCustomerBookingQuery.isFetching}
          indicator={LoadingSpinner}
          tip="Updating..."
        >
          <StyledJoinCallBox>
            <div>
              <Image
                preview={false}
                //   width={"100%"}
                src="/images/join-meeting-image.svg"
                alt="meeting"
              />
            </div>
            <p className="join-meeting-title">
              "{getCustomerBookingQuery.data?.service.name}" with{" "}
              {getCustomerBookingQuery.data?.mentor.firstName}{" "}
              {getCustomerBookingQuery.data?.mentor.lastName}
            </p>
            <div>
              <Button
                size="large"
                block
                disabled={disableButton()}
                shape="round"
                type="primary"
                htmlType="button"
                target="_blank"
                href={
                  disableButton()
                    ? undefined
                    : handleMeetingPlatform(getCustomerBookingQuery.data.videoPlatform)
                }
              >
                Join video call now
              </Button>
            </div>
            {disableButton() && (
              <p>
                You will be able to join this video call 10 minutes before the start time
              </p>
            )}

            <div>
              <StyledJoinCallExtraInfo>
                {/* <div className="call-card-extra-info-item">
                <Space size={10}>
                  <DollarOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">
                  </span>
                </Space>
              </div> */}
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <CalendarOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {getCustomerBookingQuery.data?.booking.timeslot}, {callDate}
                    </span>
                  </Space>
                </div>
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <HourglassOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {getCustomerBookingQuery.data?.service.callDuration} minutes
                    </span>
                  </Space>
                </div>
              </StyledJoinCallExtraInfo>
              <StyledJoinCallExtraInfo style={{marginTop: "2.5rem"}}>
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <GlobalOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {getCustomerBookingQuery.data?.booking.user_timezone}
                    </span>
                  </Space>
                </div>
                <div className="phone-number-extra-details">
                  <div className="call-card-extra-info-item">
                    <Space size={10}>
                      <>
                        <VideoCameraOutlined style={{color: "#7939CC"}} />
                        <span className="call-card-extra-info-item-value">
                          Video call
                        </span>
                      </>
                    </Space>
                  </div>
                </div>
              </StyledJoinCallExtraInfo>
            </div>
          </StyledJoinCallBox>
        </Spin>
      </StyledFlexContainer>
    </PublicLayout>
  );
};

export default JoinMeetingPage;
