import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  activateCustomerService,
  activateStandardUserService,
  resendActivationLinkService,
} from "./account-activation-service";

interface IInitialState {
  userInfo: any;
  isError: boolean;
  isSuccess: boolean;
  isSuccessResendActivationLink: boolean;
  activateCustomerStatus: "idle" | "pending" | "resolved" | "rejected";
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  userInfo: null,
  isError: false,
  isSuccess: false,
  activateCustomerStatus: "idle",
  isSuccessResendActivationLink: false,
  isLoading: false,
  message: "",
};

//activate standard user
export const activateStandardUser = createAsyncThunk(
  "userAccountActivation/activateStandardUser",
  async (link: string, thunkAPI) => {
    try {
      return await activateStandardUserService(link);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//activate customer user
export const activateCustomerUser = createAsyncThunk(
  "userAccountActivation/activateCustomerUser",
  async (link: string, thunkAPI) => {
    try {
      return await activateCustomerService(link);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//resend activation link
export const resendActivationLink = createAsyncThunk(
  "userAccountActivation/resendActivationLink",
  async (email: string, thunkAPI) => {
    try {
      return await resendActivationLinkService(email.toLowerCase());
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userAccountActivationSlice = createSlice({
  name: "userAccountActivation",
  initialState,
  reducers: {
    resetUserAccountActivationState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessResendActivationLink = false;
      state.activateCustomerStatus = "idle";
      state.message = "";
      // state.userInfo = null;
    },
    userAccountActivationLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(activateStandardUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateStandardUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userInfo = action.payload.data;
      })
      .addCase(activateStandardUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userInfo = null;
      })
      .addCase(activateCustomerUser.pending, (state) => {
        state.activateCustomerStatus = "pending";
      })
      .addCase(activateCustomerUser.fulfilled, (state, action) => {
        state.activateCustomerStatus = "resolved";
        state.userInfo = {
          user: {
            firstName: action.payload.data.customer.firstName,
            lastName: action.payload.data.customer.firstName,
            emailAddress: action.payload.data.customer.emailAddress.toLowerCase(),
            phoneNumber: action.payload.data.customer.phoneNumber,
          },
          accessToken: action.payload.data.accessToken,
        };
      })
      .addCase(activateCustomerUser.rejected, (state, action: any) => {
        state.activateCustomerStatus = "rejected";
        state.message = action.payload;
        state.userInfo = null;
      })
      .addCase(resendActivationLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendActivationLink.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccessResendActivationLink = true;
      })
      .addCase(resendActivationLink.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {resetUserAccountActivationState, userAccountActivationLogoutReset} =
  userAccountActivationSlice.actions;

export default userAccountActivationSlice.reducer;
