import {
  ArrowLeftOutlined,
  UserOutlined,
  DollarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  CalendarOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {Button, Avatar, Space, Select} from "antd";
import moment from "moment";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useBookingData} from "../../../../container/booking/booking-context";
import {AppDispatch, RootState} from "../../../../redux/store";
import {getTimeByTimeZone, numberWithCommas} from "../../../../services/helpers";
import {TIMEZONES} from "../../../../services/timezones";
import {
  StyledBookingLeftColumn,
  StyledHeading,
  StyledCoachInfoSection,
  StyledCoachMainInfoSection,
  StyledServiceInfoSection,
  StyledCustomSelect,
} from "../left-column.style";
import {ResheduleBookingLeftColumnMobile} from "./reschedule-left-column-mobile";
import {getBookingTimeSlot} from "../../../../redux/booking/booking-slice";

const {Option} = Select;

const RescheduleBookingLeftColumn = ({currentStep}: {currentStep: number}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const {setValues, data} = useBookingData();
  const {customerBooking} = useSelector((state: RootState) => state.booking);
  // console.log(customerBooking);
  // const [dateState, setDateState] = useState(new Date());
  const tz = moment.tz.guess(true);
  const handleChange = (
    value: unknown
    // option: DefaultOptionType | BaseOptionType | (DefaultOptionType | BaseOptionType)[]
  ) => {
    // console.log(option["children"].split());
    setValues({...data, selectedTimeZone: value});
    setValues({...data, selectedTimeZone: value});
    const payload = {
      mentorId: customerBooking.profile?.userId,
      serviceId: customerBooking.service?.id,
      date: data.booking_date,
      year: data.booking_year,
      month: data.booking_month,
      day:
        data.selectedDateString.getDay() === 0
          ? "sun"
          : data.selectedDateString.getDay() === 1
          ? "mon"
          : data.selectedDateString.getDay() === 2
          ? "tue"
          : data.selectedDateString.getDay() === 3
          ? "wed"
          : data.selectedDateString.getDay() === 4
          ? "thu"
          : data.selectedDateString.getDay() === 5
          ? "fri"
          : "sat",
      mentorTimeZone: customerBooking?.timeZone as string,
      userTimeZone: value as string,
    };
    if (payload.day && payload.date && payload.year && payload.month) {
      dispatch(getBookingTimeSlot(payload));
    }
  };
  // console.log(moment.utc().tz("America/Tijuana").format("Z"));

  return (
    <>
      <StyledBookingLeftColumn>
        {/* <div>
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-link"
            onClick={() => navigate(-1)}
            type="link"
            size="large"
            style={{
              paddingLeft: 0,
              marginLeft: 0,
            }}
          >
            Back to profile
          </Button>
        </div> */}
        <StyledHeading>{customerBooking.service?.name}</StyledHeading>
        <StyledCoachInfoSection>
          <div>
            {customerBooking.mentor?.avatar ? (
              <Avatar size={96} src={customerBooking.mentor.avatar} />
            ) : (
              <Avatar size={96} icon={<UserOutlined />} />
            )}
          </div>
          <StyledCoachMainInfoSection>
            <h2 className="coach-name">
              {customerBooking.mentor?.firstName} {customerBooking.mentor?.lastName}
            </h2>
            <p className="coach-role">{customerBooking.profile?.description}</p>
            <div
              className="coach-bio"
              dangerouslySetInnerHTML={{
                __html: String(customerBooking.service?.description),
              }}
            />
          </StyledCoachMainInfoSection>
        </StyledCoachInfoSection>
        <StyledServiceInfoSection>
          <Space direction="vertical" size={15}>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {customerBooking.service?.callPrice !== 0 &&
                    customerBooking.service?.callPriceCurrency}{" "}
                  {customerBooking.service?.callPrice === 0
                    ? "Free"
                    : numberWithCommas(Number(customerBooking.service?.callPrice))}
                </span>
              </Space>
            </div>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <HourglassOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {customerBooking.service?.callDuration} Minutes
                </span>
              </Space>
            </div>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                {customerBooking.service?.videoLocation ? (
                  <>
                    <VideoCameraOutlined style={{color: "#7939CC"}} />
                    <span className="service-info-item-value">Video call</span>
                  </>
                ) : (
                  <>
                    <PhoneOutlined style={{color: "#7939CC"}} />
                    <span className="service-info-item-value">Phone call</span>
                  </>
                )}
              </Space>
            </div>
            {currentStep > 1 && (
              <div className="service-card-extra-info-item">
                <Space size={10}>
                  <CalendarOutlined style={{color: "#7939CC"}} />
                  <span className="service-info-item-value">
                    {data.timeSelected}, {data.selectedDateString.toDateString()}
                  </span>
                </Space>
              </div>
            )}

            <div className="service-card-extra-info-item">
              <Space size={10}>
                <GlobalOutlined style={{color: "#7939CC"}} />
                {/* <span className="service-info-item-value">
                  {`(${getTimeZone("longOffset")})`} {customerBooking.timeZone} (
                  {formatAMPM(new Date())})
                </span> */}
                {currentStep > 1 ? (
                  <span className="service-info-item-value">
                    {data.selectedTimeZone ?? tz}
                  </span>
                ) : (
                  <StyledCustomSelect
                    bordered={false}
                    onChange={handleChange}
                    suffixIcon={
                      <DownOutlined
                        size={15}
                        style={{fontWeight: "bold", color: "#00171F"}}
                      />
                    }
                    size={"large"}
                    defaultValue={tz}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // console.log({option, input});
                      return (option!.children[3] as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {TIMEZONES.map((option, optionIdx: number | string) => (
                      <Option key={optionIdx} value={option.value}>
                        ({option.name}) {option.value} ({getTimeByTimeZone(option.value)})
                      </Option>
                    ))}
                  </StyledCustomSelect>
                )}
              </Space>
            </div>
          </Space>
        </StyledServiceInfoSection>
      </StyledBookingLeftColumn>
      <ResheduleBookingLeftColumnMobile currentStep={currentStep} />
    </>
  );
};

export {RescheduleBookingLeftColumn};
