import {Button, Result, message} from "antd";
import {PublicProfileNavbar} from "../../../../../components/public-profile/navbar";
import {StyledPublicProfileContainer} from "../../../../../styles/public-profile/public-profile";
import {Footer} from "../../../../../components/public-profile/footer";
import {NewSuccessStep} from "../../../../../container/live-classes/booking/new/success-step";
import {Link, useNavigate} from "react-router-dom";
import {LiveClassSuccessDetailsCard} from "../../../../../components/public-profile/live-classes-section/details/success-details-card";
import {DollarOutlined} from "@ant-design/icons";
import {StyledSuccessBookingWrapper} from "../../../../../components/public-profile/live-classes-section/details/success-details-card/success-details-card.style";
import {useProductPurchaseContext} from "../../../../../context/products/product-purchase-context";
import {
  handleFacebookLink,
  handleMailLink,
  handleProductLink,
  handleTwitterLink,
} from "../../../../../utils/products";
import {useEffect} from "react";

export function ProductSuccessBooking() {
  const {state, dispatch} = useProductPurchaseContext();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state.productData?.product.url_redirect) {
      const redirectLink = state.productData.product.url_redirect.startsWith("http")
        ? state.productData?.product.url_redirect
        : `//${state.productData?.product.url_redirect}`;
      message.info("Redirecting ...", 2, () =>
        setTimeout(() => {
          window.open(redirectLink, "_blank", "noopener noreferrer");
        }, 0)
      );
    }
  }, [state.productData?.product.url_redirect]);

  if (!state.productData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link to={`/${state.urlParams.username}`}>Back to profile</Link>
          </Button>,
        ]}
      />
    );
  }
  if (!state.productPurchaseApiResponseData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some booking parameters!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link
              to={handleProductLink({
                productId: String(state.productData.product.id),
                slug: state.productData.product.slug,
                username: state.productData.profile.username,
              })}
            >
              Book event
            </Link>
          </Button>,
        ]}
      />
    );
  }
  function handleGotoDashboard() {
    dispatch({type: "reset-state"});
    navigate(`/auth/customer/login`);
  }
  function handleGotoProfile() {
    dispatch({type: "reset-state"});
    navigate(`/${state.productData?.profile.username}`);
  }
  const extraParams = {
    username: state.productData.profile.username,
    emailAddress: state.productPurchaseFormValues?.emailAddress,
    firstName: state.productData.user.firstName,
    lastName: state.productData.user.lastName,
    shareCopyLink: handleProductLink({
      productId: String(state.productData.product.id),
      slug: state.productData.product.slug,
      username: state.productData.profile.username,
      windowNav: true,
    }),
    handleGotoDashboard,
    handleGotoProfile,
  };

  const successContent = {
    successTitle: "Purchase successful",
    successMessage: (
      <span>
        You've successfully purchased <strong>{state.productData.product.title}</strong>
        .Your access details will be sent to your email{" "}
        <strong>{state.productPurchaseFormValues?.emailAddress}</strong>.
      </span>
    ),
    successShareMessage: (
      <p className="info-text">
        Let people know you just bought a product from{" "}
        <strong>
          {state.productData.user.firstName} {state.productData.user.lastName}
        </strong>
      </p>
    ),
  };
  const socialLinks = {
    facebookLink: handleFacebookLink({
      username: state.productData.profile.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just bought a product from ${state.productData.user.firstName} ${state.productData.user.lastName} on Coachli!`,
      username: state.productData.profile.username,
    }),
    mailLink: handleMailLink({
      body: `I just bought a product from ${state.productData.user.firstName} ${state.productData.user.lastName} on Coachli! ${window.location.origin}/${state.productData.profile.username}`,
      subject: "",
    }),
  };
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "96.6rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.productData.user.avatar}
            firstName={state.productData.user.firstName}
            lastName={state.productData.user.lastName}
            username={state.productData.profile.username}
          />
          <StyledSuccessBookingWrapper>
            <div style={{width: "100%"}}>
              <LiveClassSuccessDetailsCard
                title={state.productData.product.title}
                extraInfo={[
                  {
                    icon: <DollarOutlined style={{color: "#7939CC"}} />,
                    value: `${
                      state.productData.currencies.length < 1
                        ? "Free"
                        : `${
                            state.productPurchaseApiResponseData?.currency
                          } ${state.productPurchaseApiResponseData?.amount.toLocaleString(
                            "en-US"
                          )}`
                    }`,
                  },
                ]}
                imageSrc={state.productData.product.coverImage}
              />
            </div>
            <div>
              <NewSuccessStep
                successContent={successContent}
                extraParams={extraParams}
                socialLinks={socialLinks}
              />
            </div>
          </StyledSuccessBookingWrapper>
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
}
