import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Checkbox} from "antd";
// import {RangePickerProps} from "antd/lib/date-picker";
import {Controller, useFieldArray} from "react-hook-form";
import {CustomCheckboxInput} from "../checkbox";
import {CustomTimePickerComponent} from "../time-picker";
import {StyledDayTimeWrapper, StyledTimeInputWrapper} from "./day-time-picker.style";
import {IDayTimePickerProps} from "./i-day-time-picker";
import {ITimeCopyFilterValues} from "./time-copy-filter/i-time-copy-filter";
import {TimeCopyFilter} from "./time-copy-filter/time-copy-filter";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {CustomSelectInput} from "../select";
import {TIMEOPTIONS} from "../../services/timezones";

const DayTimePicker: React.FC<IDayTimePickerProps> = ({
  control,
  watch,
  setValue,
  checkboxError,
  checkboxLabel,
  checkboxName,
  checkboxId,
  timePickerId,
  timePickerName,
  timePickerError,
}) => {
  const {fields, append, remove} = useFieldArray({
    control,
    name: timePickerName,
  });

  const [isUnavailabile, setIsUnavailable] = useState(false);

  const {
    watch: copyFilterWatch,
    reset,
    control: copyFilterControl,
  } = useForm<ITimeCopyFilterValues>({
    mode: "onChange",
  });
  // console.log("copyFilterwatch", copyFilterWatch());

  // console.log({timePickerName, watch: watch(timePickerName)});

  const handleCopy = () => {
    const allValues = watch();
    const timePickerValues = watch(timePickerName);
    var selectedCopyDays = Object.entries(copyFilterWatch());
    // const selectedCopyDays = Object.entries(copyFilterWatch()).map((entry) => {
    //   return {[entry[0]]: entry[1]};
    // });
    // console.log({allValues, timePickerValues, selectedCopyDays});
    selectedCopyDays.forEach((day) => {
      if (day[1].timesAvailable) {
        setValue(`${day[0]}.isAvailable`, true);
        setValue(`${day[0]}.timesAvailable`, timePickerValues);
      }
    });
    reset();
    // console.log("copyFilterwatch", copyFilterWatch());
  };

  const handleTimeRemove = (index: number) => {
    remove(index);
    if (watch(timePickerName).length < 1) {
      setValue(checkboxName, false);
    }
  };
  const handleCheckboxChange = (e: any, onChange: any) => {
    onChange(e);
    // if (watch(checkboxName && watch(timePickerName).length < 1)) {
    //   console.log(watch(checkboxName));
    //   setValue(timePickerName, {from: "", to: ""});
    // }
  };
  //TODO: Sort on append
  // const handleAppendTime = () => {
  //   append({from: "09:00 AM", to: "05:00 PM"})

  // }

  return (
    <StyledDayTimeWrapper>
      <div
      //   style={{marginTop: "1rem"}}
      >
        {/* <CustomCheckboxInput
          id={checkboxId}
          name={checkboxName}
          label={checkboxLabel}
          error={checkboxError}
          control={control}
        /> */}
        <Controller
          control={control}
          name={checkboxName}
          render={({field: {onChange, value, ref}}) => (
            <>
              <Checkbox
                checked={value}
                id={checkboxId}
                ref={ref}
                onChange={(x) => handleCheckboxChange(x, onChange)}
              >
                {checkboxLabel}
              </Checkbox>
              {/* {error && (
                <div role="alert" className="ant-form-item-explain-error">
                  {error}
                </div>
              )} */}
            </>
          )}
        />
      </div>
      {watch(checkboxName) ? (
        <>
          <StyledTimeInputWrapper>
            {fields.map((item, index) => (
              // <>
              <div className="main-day-time-input" key={item.id}>
                <CustomSelectInput
                  control={control}
                  id={`${timePickerId}[${index}].value`}
                  name={`${timePickerName}[${index}].from`}
                  showSearch
                  options={TIMEOPTIONS}
                  placeholder="Nigeria"
                  style={{maxWidth: "13.5rem"}}
                />
                {/* <CustomTimePickerComponent
                  id={`${timePickerId}[${index}].value`}
                  name={`${timePickerName}[${index}].from`}
                  // type="range"
                  error={timePickerError?.message}
                  control={control}
                  // disabledTime={disabledRangeTime}
                /> */}
                <div className="line" />
                <CustomSelectInput
                  control={control}
                  id={`${timePickerId}[${index}].value`}
                  name={`${timePickerName}[${index}].to`}
                  showSearch
                  options={TIMEOPTIONS}
                  placeholder="Nigeria"
                  style={{maxWidth: "13.5rem"}}
                />
                {/* <CustomTimePickerComponent
                  id={`${timePickerId}[${index}].value`}
                  name={`${timePickerName}[${index}].to`}
                  // type="range"
                  error={timePickerError?.message}
                  control={control}
                  // disabledTime={disabledRangeTime}
                /> */}
                <Button
                  onClick={() => handleTimeRemove(index)}
                  shape="circle"
                  icon={<DeleteOutlined style={{fontSize: "1.6rem"}} />}
                  size="large"
                  className="availability-delete-btn"
                />
              </div>
              //</>
            ))}
          </StyledTimeInputWrapper>
          {/* {fields.length < 4 && ( */}
          <div className="time-picker-extra-btns">
            <Button
              className="add-time-btn"
              icon={<PlusOutlined style={{fontSize: "1.6rem"}} />}
              type="primary"
              style={{backgroundColor: "#F5EDFF", border: "none", color: "#9747FF"}}
              shape="circle"
              onClick={() => append({from: "09:00 AM", to: "05:00 PM"})}
              size="large"
            />
            <TimeCopyFilter
              copyFilterControl={copyFilterControl}
              timePickerName={timePickerName}
              handleCopy={handleCopy}
            />
          </div>
          {/* )} */}
        </>
      ) : (
        <p>Unavailable</p>
      )}
    </StyledDayTimeWrapper>
  );
};

export {DayTimePicker};
