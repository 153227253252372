import {Row, Col} from "antd";
import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {StyledContainer} from "../features-section/features-section.style";
import {StyledWaitListButton} from "../waitlist-section/waitlist-section.style";
import {
  StyledHeroSection,
  StyledHeroSectionInfo,
  StyledWaitListBtnContainer,
} from "./hero-section.style";
import {motion, useScroll, useTransform} from "framer-motion";
import {
  imageAnimation,
  riseWithFade,
  shakeVariants,
  staggerChildren,
  wordAnimation,
} from "../../../utils/animations/home/home-animations";
import {useRef} from "react";

const HeroSection = () => {
  const imageTargetRef = useRef<HTMLDivElement>(null);
  const textTargetRef = useRef<HTMLDivElement>(null);
  const {scrollYProgress} = useScroll({
    target: imageTargetRef,
    offset: ["start end", "end start"],
  });
  const {scrollYProgress: scrollYProgressText} = useScroll({
    target: textTargetRef,
    offset: ["start end", "end start"],
  });
  const imageOpacity = useTransform(scrollYProgress, [0, 0.5, 0.9, 1], [1, 1, 0, 0]);
  const imageScale = useTransform(scrollYProgress, [0, 0.5, 0.9, 1], [1, 1, 0.8, 0.8]);
  const imageDisplay = useTransform(scrollYProgress, (display) => {
    return display >= 1 ? "none" : "block";
  });
  const textLeft = useTransform(
    scrollYProgressText,
    [0, 0.5, 0.6, 0.9, 1],
    [0, 0, 0, -500, -500]
  );
  return (
    <motion.div
      // initial={{opacity: 0}}
      // animate={{opacity: 1}}
      // transition={{
      //   type: "spring",
      //   stiffness: 260,
      //   damping: 20,
      // }}
      initial="initial"
      animate="animate"
    >
      <StyledHeroSection>
        <StyledContainer>
          <Row gutter={[16, 30]}>
            <Col span={24} lg={12}>
              <StyledHeroSectionInfo
                // initial={{
                //   x: 0,
                // }}
                // animate={{
                //   x: 0,
                //   opacity: 1,
                // }}
                // transition={{
                //   duration: 1,
                // }}
                ref={textTargetRef}
                style={{
                  opacity: imageOpacity,
                  x: textLeft,
                }}
              >
                <h2 className="hero-section-info-main-title">
                  <AnimatedWords
                    title="The simplest way to host paid 1:1 calls with your clients, mentees and
                  audience"
                  />
                </h2>
                <motion.p variants={riseWithFade} className="hero-section-info-sub-title">
                  Coachli helps you connect with your clients, mentees and audience by
                  hosting paid or free 1:1 sessions with them. We handle scheduling and
                  payments while you can focus on getting value for your time
                </motion.p>
                <StyledWaitListBtnContainer
                  variants={riseWithFade}
                  animate={{scale: 1.1}}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 1,
                  }}
                  onClick={() =>
                    AnalyticsEvent("Home Banner", `Clicked on Create a free account`)
                  }
                >
                  <Link to="/auth/register" style={{color: "#fff"}}>
                    <StyledWaitListButton
                      className="styled-waitlist-hero-btn"
                      style={{backgroundColor: "#9747ff", color: "#fff"}}
                    >
                      Create a free account
                    </StyledWaitListButton>
                  </Link>
                </StyledWaitListBtnContainer>
              </StyledHeroSectionInfo>
            </Col>
            <Col span={24} lg={12}>
              <motion.div
                // initial={{
                //   scale: 0,
                //   opacity: 0,
                // }}
                // animate={{
                //   scale: 1,
                //   opacity: 1,
                // }}
                // transition={{
                //   duration: 1,
                // }}

                variants={imageAnimation}
                style={{
                  opacity: imageOpacity,
                  scale: imageScale,
                }}
                ref={imageTargetRef}
              >
                <img
                  style={{maxWidth: "100%", height: "auto", display: "inline-block"}}
                  src="/images/hero-image.png"
                  alt="hero"
                />
              </motion.div>
            </Col>
          </Row>
        </StyledContainer>
      </StyledHeroSection>
    </motion.div>
  );
};

type AnimatedWordsProps = {
  title: string;
};

const AnimatedWords: React.FC<AnimatedWordsProps> = ({title}) => {
  return (
    <motion.span
      initial="initial"
      whileInView="animate"
      viewport={{once: false}}
      variants={staggerChildren}
    >
      {title.split(" ").map((word, idx) => (
        <div
          key={idx}
          style={{
            display: "inline-block",
            overflow: "hidden",
          }}
        >
          <motion.span
            style={{
              display: "inline-block",
              overflow: "hidden",
            }}
            variants={wordAnimation}
          >
            {word + "\u00A0"}
          </motion.span>
        </div>
      ))}
    </motion.span>
  );
};

export {HeroSection, AnimatedWords};
