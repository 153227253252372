import {Alert, Tabs, Space, Spin} from "antd";
import {useGetCustomerUpcomingCalls} from "../../react-query-hooks/customer";
import {useGetLiveClassesCustomer} from "../../react-query-hooks/live-events/live-classes";
import {StyledNoUpcomingCallBox} from "../calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../full-page-loader";
import {CustomerCallCard} from "../calls/upcoming-calls/call-card/customer";
import {UpcomingClassesCustomer} from "../live-classes/upcoming-classes/customer/upcoming-classes";
import {LoadingSpinner} from "../icons/icons";

export const CustomerUpcomingEvents = () => {
  const getCustomerUpcomingCalls = useGetCustomerUpcomingCalls();
  const getLiveClassesQuery = useGetLiveClassesCustomer();
  if (getLiveClassesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (
    getLiveClassesQuery.isError &&
    getLiveClassesQuery.error.message !==
      "Sorry, You currently do not have any live events!"
  ) {
    return (
      <Alert
        message="Error"
        description={getLiveClassesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  if (getCustomerUpcomingCalls.isLoading) {
    return <FullPageLoader />;
  }

  if (
    getCustomerUpcomingCalls.isError &&
    getCustomerUpcomingCalls.error.message !==
      "Sorry, You currently do not have any upcoming calls! Please, book a session and try again"
  ) {
    return (
      <Alert
        message="Error"
        description={getCustomerUpcomingCalls.error.message}
        type="error"
        showIcon
      />
    );
  }
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="All" key="1">
          {(getLiveClassesQuery.data &&
            getLiveClassesQuery.data.data.events.length < 1 &&
            getCustomerUpcomingCalls.data &&
            getCustomerUpcomingCalls.data.data.session.length < 1) ||
          (!getLiveClassesQuery.data && !getCustomerUpcomingCalls.data) ? (
            <StyledNoUpcomingCallBox>
              <p className="info-text">You do not have upcoming events at the moment.</p>
            </StyledNoUpcomingCallBox>
          ) : (
            <>
              <Spin
                spinning={
                  getLiveClassesQuery.isFetching || getCustomerUpcomingCalls.isFetching
                }
                tip="Updating..."
                indicator={LoadingSpinner}
              >
                <Space direction="vertical" size={42} style={{display: "flex"}}>
                  {getCustomerUpcomingCalls.data?.data.session.map((call) => (
                    <CustomerCallCard key={call.id} call={call} />
                  ))}
                  {getLiveClassesQuery.data &&
                    getLiveClassesQuery.data.data.events.length > 0 && (
                      <UpcomingClassesCustomer
                        events={getLiveClassesQuery.data.data.events}
                      />
                    )}
                </Space>
              </Spin>
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="1:1 Calls" key="2">
          {getCustomerUpcomingCalls.data &&
          getCustomerUpcomingCalls.data.data.session.length > 0 ? (
            <Space direction="vertical" size={42} style={{display: "flex"}}>
              {getCustomerUpcomingCalls.data.data.session.map((call) => (
                <CustomerCallCard key={call.id} call={call} />
              ))}
            </Space>
          ) : (
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                You do not have upcoming calls at the moment. Book a new service to get
                started
              </p>
            </StyledNoUpcomingCallBox>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Live events" key="3">
          <Spin
            spinning={getLiveClassesQuery.isFetching}
            tip="Updating..."
            indicator={LoadingSpinner}
          >
            <UpcomingClassesCustomer events={getLiveClassesQuery.data?.data.events} />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
