import {AnalyticsSection} from "../../components/home/analytics-section";
import {BannerSection} from "../../components/home/banner-section";
import {CategoriesSection} from "../../components/home/categories-section";
import {DoMoreSection} from "../../components/home/do-more-section";
import {ExtrasSection} from "../../components/home/extras-section";
import {FeaturesSection} from "../../components/home/features-section";
import {GetStartedSection} from "../../components/home/get-started-section-old";
import {HeroSection} from "../../components/home/hero-section";
import {TestimonialSection} from "../../components/home/testimonial-section";
import {WhatMakesUsSpecialSection} from "../../components/home/what-makes-us-special-section";
import {HomeWrapper} from "../../layouts/home-wrapper/home-wrapper";

const HomePage = () => {
  return (
    <HomeWrapper>
      <BannerSection />
      <AnalyticsSection />
      <ExtrasSection />
      <WhatMakesUsSpecialSection />
      <TestimonialSection />
    </HomeWrapper>
  );
};

export default HomePage;
