import {Space} from "antd";
import {
  StyledWidgetInfoBlock,
  StyledWidgetWrapper,
} from "../browse-events/browse-events.style";
import {VideoCameraIcon} from "../../icons/icons";
import {Fragment} from "react";

type TourWidgetProps = {
  buttons: React.ReactNode[];
  textInfo?: string;
};

export function TourWidget({buttons, textInfo}: TourWidgetProps) {
  return (
    <StyledWidgetWrapper>
      <h2 className="widget-heading">
        Take a guided tour {"  "} <VideoCameraIcon />
      </h2>
      <div className="widget-card">
        <Space direction="vertical" size={12} style={{display: "flex"}}>
          <StyledWidgetInfoBlock style={{background: "#FFF7E6"}}>
            <p>
              {textInfo ||
                "Watch a video guide on how to start hosting webinars, group sessions and classes online"}
            </p>
          </StyledWidgetInfoBlock>
          {buttons.map((el, index) => (
            <Fragment key={index}>{el}</Fragment>
          ))}
        </Space>
      </div>
    </StyledWidgetWrapper>
  );
}
