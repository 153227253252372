import {Space} from "antd";
import {TermsListItem} from "./terms-list-item";
import {StyledTermsOrderList, StyledTermsOrderListItem} from "./terms-list.style";

export function TermsList() {
  return (
    <div>
      <StyledTermsOrderList>
        <StyledTermsOrderListItem>
          <TermsListItem title="overview">
            <Space direction="vertical" size={20}>
              <p>
                This Terms of Service Agreement (<strong>"Agreement"</strong>) is entered
                into by and between <strong>Coachli Technology LTD</strong>, registered
                address 1233, 1007 N Orange St. 4th Floor, Wilmington, United States (
                <strong>"Company"</strong>) and you, and is made effective as of the date
                of your use of this website{" "}
                <a
                  style={{color: "#000", textDecoration: "underline"}}
                  href="https://www.coachli.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.coachli.co/
                </a>{" "}
                (<strong>"Site"</strong>) or the date of electronic acceptance.{" "}
              </p>
              <p>
                This Agreement sets forth the general terms and conditions of your use of
                the{" "}
                <a
                  style={{color: "#000", textDecoration: "underline"}}
                  href="https://www.coachli.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.coachli.co/
                </a>{" "}
                as well as the products and/or services purchased or accessed through this
                Site (the <strong>"Services"</strong>
                ).Whether you are simply browsing or using this Site or purchase Services,
                your use of this Site and your electronic acceptance of this Agreement
                signifies that you have read, understand, acknowledge and agree to be
                bound by this Agreement our Privacy policy.{" "}
              </p>
              <p>
                The terms <strong>"we"</strong>, <strong>"us"</strong>,{" "}
                <strong>"our"</strong>, <strong>“the service”</strong> or{" "}
                <strong>“the platform”</strong> shall refer to Company. The terms{" "}
                <strong>"you"</strong>,<strong>"your"</strong>, <strong>"User"</strong> or{" "}
                <strong>"customer"</strong> shall refer to any individual or entity who
                accepts this agreement, uses our Site, has access or uses the Services.
                Nothing in this agreement shall be deemed to confer any third-party rights
                or benefits.
              </p>
              <p>
                Company may, in its sole and absolute discretion, change or modify this
                agreement, and any policies or agreements which are incorporated herein,
                at any time, and such changes or modifications shall be effective
                immediately upon posting to this Site. Your use of this Site or the
                Services after such changes or modifications have been made shall
                constitute your acceptance of this agreement as last revised.
              </p>
              <p className="text-uppercase">
                if you do not agree to be bound by this agreement as last revised do not
                use (or continue to use) this site or the services.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="eligibility">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  This Site and the Services are available only to users who can form
                  legally binding contracts under applicable law. By using this Site or
                  the Services, you represent and warrant that you are:
                </p>
                <ol type="i">
                  <li>at least eighteen (18) years of age,</li>
                  <li>
                    otherwise recognised as being able to form legally binding contracts
                    under applicable law, and
                  </li>
                  <li>
                    are not a person barred from purchasing or receiving the Services
                    found under the laws of the United States or other applicable
                    jurisdiction.
                  </li>
                </ol>
              </div>
              <p>
                If you are entering into this agreement on behalf of a company or any
                corporate entity, you represent and warrant that you have the legal
                authority to bind such corporate entity to the terms and conditions
                contained in this agreement, in which case the terms{" "}
                <strong>"you"</strong>, <strong>"your"</strong>, <strong>"User"</strong>{" "}
                or <strong>"customer"</strong> shall refer to such corporate entity. If,
                after your electronic acceptance of this Agreement, Company finds that you
                do not have the legal authority to bind such corporate entity, you will be
                personally responsible for the obligations contained in this agreement.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="rules of user conduct">
            <Space direction="vertical" size={20}>
              <div>
                <p>By using this Site You acknowledge and agree that:</p>
                <ol type="i">
                  <li>
                    Your use of this Site, including any content you submit, will comply
                    with this agreement and all applicable local, state, national and
                    international laws, rules and regulations.
                  </li>
                  <li>
                    <div>
                      <p>You will not use this Site in a manner that:</p>
                      <ul>
                        <li>Is illegal, or promotes or encourages illegal activity</li>
                        <li>
                          Promotes, encourages or engages in child pornography or the
                          exploitation of children
                        </li>
                        <li>
                          Promotes, encourages or engages in terrorism, violence against
                          people, animals, or property
                        </li>
                        <li>
                          Promotes, encourages or engages in any spam or other unsolicited
                          bulk email, or computer or network hacking or cracking
                        </li>
                        <li>
                          Infringes on the intellectual property rights of another user or
                          any other person or entity
                        </li>
                        <li>
                          Violates the privacy or publicity rights of another user or any
                          other person or entity, or breaches any duty of confidentiality
                          that you owe to another user or any other person or entity
                        </li>
                        <li>Interferes with the operation of this Site</li>
                        <li>
                          Contains or installs any viruses, worms, bugs, trojan horses,
                          cryptocurrency miners or other code, files or programs designed
                          to, or capable of, using many resources, disrupting, damaging,
                          or limiting the functionality of any software or hardware.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    You will not: copy or distribute in any medium any part of this Site,
                    except where expressly authorised by Company, modify or alter any part
                    of this site or any of its related technologies, access companies
                    content (as defined below) or user content through any technology or
                    means other than through this Site itself.
                  </li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="intellectual property">
            <Space direction="vertical" size={20}>
              <p>
                In addition to the general rules above, the provisions in this section
                apply specifically to your use of companies content posted to Site.
                Companies content on this Site, including without limitation the text,
                software, scripts, source code, API, graphics, photos, sounds, music,
                videos and interactive features and the trademarks, service marks and
                logos contained therein (<strong>"Companies Content"</strong>), are owned
                by or licensed to
                <strong>Coachli Technology LTD</strong> in perpetuity, and are subject to
                copyright, trademark, and/or patent protection.{" "}
              </p>
              <p>
                Companies content is provided to you <strong>"as is"</strong>,{" "}
                <strong>"as available"</strong> and <strong>"with all faults"</strong> for
                your information and personal, non-commercial use only and may not be
                downloaded, copied, reproduced, distributed, transmitted, broadcast,
                displayed, sold, licensed, or otherwise exploited for any purposes
                whatsoever without the express prior written consent of Company.{" "}
              </p>
              <p>
                No right or license under any copyright, trademark, patent, or other
                proprietary right or license is granted by this Agreement.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="your use of user content">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  Some of the features of this Site may allow Users to view, post,
                  publish, share, or manage
                </p>
                <ol type="a">
                  <li>
                    ideas, opinions, recommendations, or advice (
                    <strong>"User Submissions"</strong>), or
                  </li>
                  <li>
                    literary, artistic, musical, or other content, including but not
                    limited to photos and videos (together with User Submissions,{" "}
                    <strong>"User Content"</strong>).
                  </li>
                </ol>
              </div>
              <div>
                <p>
                  By posting or publishing User Content to this Site, you represent and
                  warrant to Company that:
                </p>
                <ol type="i">
                  <li>
                    you have all necessary rights to distribute User Content via this Site
                    or via the Services, either because you are the author of the User
                    Content and have the right to distribute the same, or because you have
                    the appropriate distribution rights, licenses, consents, and/or
                    permissions to use, in writing, from the copyright or other owner of
                    the User Content, and
                  </li>
                  <li>
                    the User Content does not violate the rights of any third party. You
                    agree not to circumvent, disable or otherwise interfere with the
                    security-related features of this Site (including without limitation
                    those features that prevent or restrict use or copying of any
                    Companies Content or User Content) or enforce limitations on the use
                    of this Site, the Companies Content or the User Content therein.
                  </li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="company's use of user content">
            <Space direction="vertical" size={20}>
              <p>
                The provisions in this Section apply specifically to Companies use of User
                Content posted to Site. You shall be solely responsible for any and all of
                your User Content or User Content that is submitted by you, and the
                consequences of, and requirements for, distributing it.
              </p>
              <p>
                With Respect to User Submissions, you acknowledge and agree that: Your
                User Submissions are entirely voluntary. Your User Submissions do not
                establish a confidential relationship or obligate Company to treat your
                User Submissions as confidential or secret.{" "}
              </p>
              <p>
                Company has no obligation, either express or implied, to develop or use
                your User Submissions, and no compensation is due to you or to anyone else
                for any intentional or unintentional use of your User Submissions. Company
                shall own exclusive rights (including all intellectual property and other
                proprietary rights) to any User Submissions posted to this Site, and shall
                be entitled to the unrestricted use and dissemination of any User
                Submissions posted to this Site for any purpose, commercial or otherwise,
                without acknowledgment or compensation to you or to anyone else.{" "}
              </p>
              <p>
                With Respect to User Content, by posting or publishing User Content to
                this Site, you authorise Company to use the intellectual property and
                other proprietary rights in and to your User Content to enable inclusion
                and use of the User Content in the manner contemplated by this Site and
                this Agreement.{" "}
              </p>
              <p>
                You hereby grant Company a worldwide, non-exclusive, royalty-free,
                sublicensable, and transferable license to use, reproduce, distribute,
                prepare derivative works of, combine with other works, display, and
                perform your User Content in connection with this Site, including without
                limitation for promoting and redistributing all or part of this Site in
                any media formats and through any media channels without restrictions of
                any kind and without payment or other consideration of any kind, or
                permission or notification, to you or any third party.{" "}
              </p>
              <p>
                You also hereby grant each User of this Site a non-exclusive license to
                access your User Content through this Site, and to use, reproduce,
                distribute, prepare derivative works of, combine with other works,
                display, and perform your User Content as permitted through the
                functionality of this Site and under this Agreement.{" "}
              </p>
              <p>
                The above licenses granted by you in your User Content terminate within a
                commercially reasonable time after you remove or delete your User Content
                from this Site. You understand and agree, however, that Company may retain
                (but not distribute, display, or perform) server copies of your User
                Content that have been removed or deleted.
              </p>
              <p>
                The above licenses granted by you in your User Content are perpetual and
                irrevocable. Company generally does not pre-screen User Content but
                reserves the right (but undertakes no duty) to do so and decide whether
                any item of User Content is appropriate and/or complies with this
                Agreement. Company may remove any item of User Content if it violating
                this Agreement, at any time and without prior notice.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="links to third-party websites">
            <Space direction="vertical" size={20}>
              <p>
                This Site may contain links to third-party websites that are not owned or
                controlled by Company. Company assumes no responsibility for the content,
                terms and conditions, privacy policies, or practices of any third-party
                websites. In addition, Company does not censor or edit the content of any
                third-party websites.
              </p>
              <p>
                By using this Site you expressly release Company from any and all
                liability arising from your use of any third-party website. Accordingly,
                Company encourages you to be aware when you leave this Site and to review
                the terms and conditions, privacy policies, and other governing documents
                of each other website that you may visit.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="disclaimer of representations and warrantees">
            <Space direction="vertical" size={20}>
              <p className="text-uppercase">
                you specifically acknowledge and agree that your use of this site shall be
                at your own risk and that this site are provided <strong>"as is"</strong>,{" "}
                <strong>"as available"</strong>
                and <strong>"with all faults"</strong>.
              </p>
              <div>
                <p className="text-uppercase">
                  company, it's officers, directors, employees, agents, disclaim all
                  warranties, statutory, express or implied, including, but not limited
                  to, any implied warranties of title, merchantability fitness for a
                  particular purpose and noninfringement. company, it's officers,
                  directors, employees, and agents make no representations or warranties
                  about:
                </p>
                <ol type="i">
                  <li className="text-uppercase">
                    the accuracy, completeness, or content of this site
                  </li>
                  <li className="text-uppercase">
                    the accuracy, completeness, or content of any sites linked (through
                    hyperlinks banner advertising or otherwise) to this site, and/or
                  </li>
                  <li className="text-uppercase">
                    the services found at this site or any sites lined (through
                    hyperlinks, banner advertising or otherwise) to this site, and company
                    assumes no liability or responsibility for the same
                  </li>
                </ol>
              </div>
              <div>
                <p className="text-uppercase">
                  in addition, you specifically acknowledge and agree that no oral or
                  written information or advice provided by company, it's officers,
                  directions, employees, or agents, and third-party service providers will
                </p>
                <ol type="i">
                  <li className="text-uppercase">
                    constitute legal or financial advice or
                  </li>
                  <li>
                    <span className="text-uppercase">
                      create a warranty of any kind with respect to this site or the
                      services found at this site, and users should not rely on any such
                      information or advice. The forgoing disclaimer or representations
                      and warranties shall apply to the fullest extent permitted by law
                    </span>
                    , and shall survive any termination or expiration of this Agreement or
                    your use of this Site or the Services found at this Site.
                  </li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="limitation of liability">
            <Space direction="vertical" size={20}>
              <div>
                <p className="text-uppercase">
                  in no event shall company, it's officers, directors, employees, agents,
                  and all third party service providers, be liable to you or any other
                  person or entity for any direct, indirect, incidental, special,
                  punitive, or consequential damages whatsoever, including any damages
                  that may result from:
                </p>
                <ol type="i">
                  <li className="text-uppercase">
                    the accuracy, completeness, or content of this site,
                  </li>
                  <li className="text-uppercase">
                    the accuracy, completeness, or content of any sites linked (through
                    hyperlinks, banner advertising or otherwise) to this site
                  </li>
                  <li className="text-uppercase">
                    the services found at this site or any sites lined (through
                    hyperlinks, banner advertising or otherwise) to this site
                  </li>
                  <li className="text-uppercase">
                    personal injury or property damage of any nature whatsoever,
                  </li>
                  <li className="text-uppercase">
                    third-party conduct of any nature whatsoever,
                  </li>
                  <li className="text-uppercase">
                    any interruption or cessation of services to or from this site or any
                    sites linked (through hyperlinks, banner advertising or otherwise) to
                    this site,
                  </li>
                  <li className="text-uppercase">
                    any viruses, worms, bugs, trojan horses, or the like, which may be
                    transmitted to or from this site or any sites linked (through
                    hyperlinks, banner advertising or otherwise) to this site,
                  </li>
                  <li className="text-uppercase">
                    any user content or content that is defamatory, harassing, abusive,
                    harmful to minors or any projected class, pornographic, "X-rated",
                    obscene or otherwise objectionable, and/or
                  </li>
                  <li className="text-uppercase">
                    any loss or damage of any kind incurred as a result of your use of
                    this site or the services found at this site, whether based on
                    warranty, contract, tort, or any other legal or equitable theory, and
                    whether or not company is advised of the possibility of such damages.
                  </li>
                </ol>
              </div>
              <p>
                <span className="text-uppercase">in addition</span>, You{" "}
                <span className="text-uppercase">specifically acknowledge and</span> agree
                that any cause of action arising out of or related to this Site or the
                Services found at this Site must be commenced within one (1) year after
                the cause of action accrues, otherwise such cause of action shall be
                permanently barred.{" "}
                <span className="text-uppercase">
                  the foregoing limitation of liability shall apply to the fullest extent
                  permitted by law, and
                </span>{" "}
                shall survive any termination or expiration of this Agreement or your use
                of this Site or the Services found at this Site.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="indemnity">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  You agree to protect, defend, indemnify and hold harmless Company and
                  its officers, directors, employees, agents from and against any and all
                  claims, demands, costs, expenses, losses, liabilities and damages of
                  every kind and nature (including, without limitation, reasonable
                  attorneys’ fees) imposed upon or incurred by Company directly or
                  indirectly arising from:
                </p>
                <ol type="i">
                  <li>your use of and access to this Site</li>
                  <li>
                    your violation of any provision of this Agreement or the policies or
                    agreements which are incorporated herein; and/or
                  </li>
                  <li>
                    your violation of any third-party right, including without limitation
                    any intellectual property or other proprietary right. The
                    indemnification obligations under this section shall survive any
                    termination or expiration of this Agreement or your use of this Site
                    or the Services found at this Site.
                  </li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="data transfer">
            <Space direction="vertical" size={20}>
              <p>
                If you are visiting this Site from a country other than the country in
                which our servers are located, your communications with us may result in
                the transfer of information across international boundaries. By visiting
                this Site and communicating electronically with us, you consent to such
                transfers.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="availability of website">
            <Space direction="vertical" size={20}>
              <p>
                Subject to the terms and conditions of this Agreement and our policies, we
                shall use commercially reasonable efforts to attempt to provide this Site
                on 24/7 basis.
              </p>
              <p>
                You acknowledge and agree that from time to time this Site may be
                inaccessible for any reason including, but not limited to, periodic
                maintenance, repairs or replacements that we undertake from time to time,
                or other causes beyond our control including, but not limited to,
                interruption or failure of telecommunication or digital transmission links
                or other failures.
              </p>
              <p>
                You acknowledge and agree that we have no control over the availability of
                this Site on a continuous or uninterrupted basis, and that we assume no
                liability to you or any other party with regard thereto.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="discontinued services">
            <Space direction="vertical" size={20}>
              <p>
                Company reserves the right to cease offering or providing any of the
                Services at any time, for any or no reason, and without prior notice.
              </p>
              <p>
                Although Company makes great effort to maximize the lifespan of all its
                Services, there are times when a Service we offer will be discontinued. If
                that is the case, that product or service will no longer be supported by
                Company. In such case, Company will either offer a comparable Service for
                you to migrate to or a refund. Company will not be liable to you or any
                third party for any modification, suspension, or discontinuance of any of
                the Services we may offer or facilitate access to.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="fees and payments">
            <Space direction="vertical" size={20}>
              <p>
                Coachli may charge fees to coaches (<strong>“Coach Fees”</strong>) and
                Users (<strong>“Customer Fees”</strong>) (collectively{" "}
                <strong>“Service Fees”</strong>) for the use of the Services. Detailed
                information about when Service Fees apply and how they are calculated can
                be found in our FAQs.
              </p>
              <p>
                Coachli reserves the right to change the Service Fees at any time and will
                provide Members with sufficient notice before the changes take effect.
                These changes will not impact purchases made before the effective date of
                the fee change. Coachli’s payment processing services, including credit
                card data processing and storage, are provided by Flutterwave Inc. (
                <strong>“Flutterwave”</strong>) and are subject to Flutterwave’s Services
                Agreement - United States. By accepting these terms and continuing to use
                Coachli, Users agree to the Flutterwave Services Agreement as Flutterwave
                may modify it from time to time. To use Flutterwave’s payment processing
                services, you must provide Coachli with accurate information about
                yourself and your business, and authorise Coachli to share this
                information and transaction details with Flutterwave.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="no third-party beneficiaries">
            <Space direction="vertical" size={20}>
              <p>
                Nothing in this Agreement shall be deemed to confer any third-party rights
                or benefits.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="compliance with local laws">
            <Space direction="vertical" size={20}>
              <p>
                Company makes no representation or warranty that the content available on
                this Site are appropriate in every country or jurisdiction, and access to
                this Site from countries or jurisdictions where its content is illegal is
                prohibited. Users who choose to access this Site are responsible for
                compliance with all local laws, rules and regulations.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="governing law">
            <Space direction="vertical" size={20}>
              <p>
                This Agreement and any dispute or claim arising out of or in connection
                with it or its subject matter or formation shall be governed by and
                construed in accordance with the laws of United States, Delaware, to the
                exclusion of conflict of law rules.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="dispute resolution">
            <Space direction="vertical" size={20}>
              <p>
                Any controversy or claim arising out of or relating to these Terms of
                Services will be settled by binding arbitration. Any such controversy or
                claim must be arbitrated on an individual basis, and must not be
                consolidated in any arbitration with any claim or controversy of any other
                party. The arbitration must be conducted in United States, and judgment on
                the arbitration award may be entered into any court having jurisdiction
                thereof.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="titles and headings">
            <Space direction="vertical" size={20}>
              <p>
                The titles and headings of this Agreement are for convenience and ease of
                reference only and shall not be utilized in any way to construe or
                interpret the agreement of the parties as otherwise set forth herein.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="severability">
            <Space direction="vertical" size={20}>
              <p>
                Each covenant and agreement in this Agreement shall be construed for all
                purposes to be a separate and independent covenant or agreement.
              </p>
              <p>
                If a court of competent jurisdiction holds any provision (or portion of a
                provision) of this Agreement to be illegal, invalid, or otherwise
                unenforceable, the remaining provisions (or portions of provisions) of
                this Agreement shall not be affected thereby and shall be found to be
                valid and enforceable to the fullest extent permitted by law.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="refunds">
            <Space direction="vertical" size={20}>
              <p>
                Refund requests must be lodged within 24 hours of the call. To apply for a
                refund, please contact{" "}
                <a
                  href="mailto:hello@coachli.co"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  hello@coachli.co
                </a>{" "}
                and explain why you require a refund in detail.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="accounts">
            <Space direction="vertical" size={20}>
              <p>
                To access the Services, you need to create a personal account. When you
                create the account, you affirm that you are at least 18 years old. You are
                responsible for providing and updating accurate personal information and
                protecting your account details. Impersonating someone else is not
                allowed. A valid email address must be used and Coachli may verify it at
                any time. Coachli will not be held liable for any losses or damages
                resulting from your failure to meet these requirements.
              </p>
              <p>
                Your account is for personal use only and must not be used by others
                without your supervision. You can't transfer your account to anyone else
                either. You are solely responsible for all activity on your account.
                Coachli reserves the right to change, terminate, or restrict access to any
                aspect of the Service or your account, without notice. They may also
                impose usage limitations. You are accountable for your use of Coachli,
                including the legality of any content you display, share, upload or make
                available through the Service.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="currency conversion and payouts">
            <Space direction="vertical" size={20}>
              <p>
                We are not required to pay merchants more than the rate set by the Central
                Bank of Nigeria (CBN) for currency conversion and payouts. However, to
                foster a positive business relationship with both parties, we aim to use
                the best available rate at the time of payment by the buyer. This rate may
                fluctuate slightly from time to time. Keep in mind that we reserve the
                right to change the payment gateway at any time as we see fit.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="termination">
            <Space direction="vertical" size={20}>
              <p>
                We can cancel or halt your account and prevent access to the Service
                without prior notice, for any reason, and we can do this at our
                discretion. You can end your account by stopping to use the Service. The
                provisions of these Terms that are meant to continue even after
                termination will continue, including, but not limited to, ownership,
                disclaimer of warranties, indemnification, and limitations of liability.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="purchases">
            <Space direction="vertical" size={20}>
              <p>
                If you want to buy a product or service through Coachli, you may need to
                provide information such as your credit card number, expiration date,
                billing address, and shipping information. You guarantee that you have the
                legal right to use the payment method for the purchase and that the
                information you provide is accurate.
              </p>
              <p>
                We may use third-party services to process payments and complete
                purchases. By giving us your information, you agree to let us share it
                with these third parties as outlined in our Privacy Policy. We reserve the
                right to reject or cancel your order for reasons including but not limited
                to product unavailability, incorrect product information, errors in your
                order, or suspected fraud or illegal activity.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="coach obligations">
            <Space direction="vertical" size={20}>
              <div>
                <p>By publishing content on Coachli, you promise to:</p>
                <ol type="i">
                  <li>
                    Honour all enrollment requests made through the platform at the listed
                    price and time and do not decline participation for discriminatory or
                    illegal reasons
                  </li>
                  <li>
                    Ensure that any content you share on the service does not infringe on
                    any third party copyrights or proprietary rights unless you have
                    permission from the rightful owner
                  </li>
                  <li>
                    Guarantee that you will always follow all relevant laws while using
                    the service.
                  </li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="intellectual property ownership">
            <Space direction="vertical" size={20}>
              <p>
                Coachli and its licensors retain exclusive ownership of the Service and
                its original content, features, and functionality. Service is protected by
                copyright, trademark, and other U.S. and foreign laws. Coachli's
                trademarks and trade dress must not be used for any product or service
                without its prior written consent.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="copyright policy">
            <Space direction="vertical" size={20}>
              <p>
                Coachli respects the intellectual property rights of others and has the
                policy to address any claim that posted content on the Service infringes
                on someone's copyright or other intellectual property rights. Anyone who
                posts content on the Service can be held responsible for damages,
                including legal fees, for false claims or misrepresentation of copyright
                infringement.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="procedure for copyright infringement claims">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  Claims of copyright infringement can be made by submitting a
                  notification to our Copyright Agent in accordance with the Digital
                  Millennium Copyright Act (DMCA). This notification must include the
                  following information in writing:{" "}
                </p>
                <ol type="i">
                  <li>
                    An electronic or physical signature of the person authorized to act on
                    behalf of the copyright owner
                  </li>
                  <li>
                    A description of the copyrighted work that has allegedly been
                    infringed, including its URL or a copy of the work Identification of
                    the specific location on the Service where the allegedly infringing
                    content is located Your address, phone number, and email address
                  </li>
                  <li>
                    A statement that you have a good faith belief that the disputed use is
                    not authorized by the copyright owner or law
                  </li>
                  <li>
                    A statement made under penalty of perjury that the above information
                    is accurate and that you are the copyright owner or authorized to act
                    on their behalf.
                  </li>
                </ol>
              </div>
              <p>
                Our Copyright Agent can be contacted via email at{" "}
                <a
                  href="mailto:hello@coachli.co"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  hello@coachli.co
                </a>
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="contact information">
            <Space direction="vertical" size={20}>
              <p>
                If you have any questions about this Agreement, please contact us by email
                or regular mail at the following address: <br />
                Coachli Technology LTD
                <br /> 1233, 1007 N Orange St. 4th Floor, Wilmington
                <br /> United States
                <br />
                <a
                  href="mailto:hello@coachli.co"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  hello@coachli.co
                </a>
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
      </StyledTermsOrderList>
    </div>
  );
}
