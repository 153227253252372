import * as yup from "yup";
import {Button, Select, Space, Switch} from "antd";
import {
  StyledWidgetInfoBlock,
  StyledWidgetWrapper,
} from "../browse-events/browse-events.style";
import {AlertOutlined, CalendarOutlined, GlobalOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useGetTimezone, useUpdateTimezone} from "../../../react-query-hooks/timezone";
import {useState} from "react";
import moment from "moment";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {TIMEZONES} from "../../../services/timezones";
import {
  StyledModalContentWrapper,
  StyledModalContentInfo,
} from "../../calls/upcoming-calls/call-card/call-card.style";
import {CustomModal} from "../../modal";
import {AvailabilityWidgetSkeleton} from "../../calls/upcoming-calls/availability-widget/availability-widget-skeleton";

const {Option} = Select;

interface ITimezoneFormValues {
  autoDetect: boolean;
  timezone: string;
}

const timezoneSchema = yup.object().shape({
  autoDetect: yup.bool(),
  timezone: yup.string(),
});

export function NewAvailabilityWidget() {
  const [openTimezoneModal, setOpenTimezoneModal] = useState<boolean>(false);
  const getTimezoneQuery = useGetTimezone();
  const updateTimezoneQueryMutation = useUpdateTimezone();

  const showTimezoneModal = () => {
    setOpenTimezoneModal(true);
  };

  const handleOk = () => {
    setOpenTimezoneModal(false);
  };

  const tz = moment.tz.guess(true);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    control,
  } = useForm<ITimezoneFormValues>({
    resolver: yupResolver(timezoneSchema),
    mode: "onChange",
    defaultValues: {
      timezone: getTimezoneQuery.data ? getTimezoneQuery.data : undefined,
    },
  });

  const handleCancel = () => {
    setOpenTimezoneModal(false);
  };
  const handleAutoDetect = (e: unknown, onChange: (arg0: unknown) => void) => {
    onChange(e);
    if (watch("autoDetect")) {
      setValue("timezone", tz);
    }
  };
  const onSubmit = async (values: ITimezoneFormValues) => {
    updateTimezoneQueryMutation.mutate(
      {timeZone: values.timezone},
      {
        onSuccess: () => {
          setOpenTimezoneModal(false);
        },
      }
    );
  };
  return (
    <StyledWidgetWrapper>
      <h2 className="widget-heading">Availability 🕰️</h2>
      {getTimezoneQuery.isLoading || getTimezoneQuery.isFetching ? (
        <AvailabilityWidgetSkeleton />
      ) : (
        <>
          <div className="widget-card">
            <Space direction="vertical" size={12} style={{display: "flex"}}>
              <StyledWidgetInfoBlock>
                <p>
                  Keep your availability for 1:1 sessions updated to avoid scheduling
                  conflicts
                </p>
              </StyledWidgetInfoBlock>
              <Link to="/dashboard/calls/availability">
                <Button block icon={<CalendarOutlined />} size="large" shape="round">
                  <span>Availability</span>
                </Button>
              </Link>
              <Link to="/dashboard/calls/availability/unavailability">
                <Button block icon={<AlertOutlined />} size="large" shape="round">
                  <span>Add unavailability</span>
                </Button>
              </Link>
              <Button
                onClick={showTimezoneModal}
                block
                icon={<GlobalOutlined />}
                size="large"
                shape="round"
              >
                <span>Change time zone</span>
              </Button>
            </Space>
          </div>
          <CustomModal
            visibleState={openTimezoneModal}
            title="Update your timezone"
            okFunc={handleOk}
            cancelFunc={handleCancel}
            footerContent={[
              <Button
                key="cancel"
                shape="round"
                type="default"
                onClick={handleCancel}
                //   onClick={handleSubmit(onSubmit)}
              >
                Cancel
              </Button>,
              <Button
                key="save"
                type="primary"
                shape="round"
                loading={updateTimezoneQueryMutation.isLoading}
                onClick={handleSubmit(onSubmit)}
                disabled={!watch("timezone")}
              >
                Save changes
              </Button>,
            ]}
          >
            <>
              <StyledModalContentWrapper>
                <StyledModalContentInfo>
                  <p className="content-text">
                    Receive sessions scheduled in your timezone. This change will affect
                    all session timings.
                  </p>
                </StyledModalContentInfo>
              </StyledModalContentWrapper>
              <StyledModalContentWrapper>
                <Space
                  size={10}
                  wrap
                  style={{justifyContent: "space-between", display: "flex"}}
                >
                  <p style={{fontWeight: 600}}>Auto-detect timezone</p>
                  <Controller
                    control={control}
                    name="autoDetect"
                    render={({field: {value, onChange}}) => (
                      <Switch
                        onChange={(e) => handleAutoDetect(e, onChange)}
                        checked={value}
                      />
                    )}
                  />
                </Space>
              </StyledModalContentWrapper>
              <StyledModalContentWrapper>
                <Controller
                  control={control}
                  name="timezone"
                  render={({field}) => (
                    <>
                      <Select
                        {...field}
                        size="large"
                        status={errors.timezone?.message ? "error" : ""}
                        showSearch
                        placeholder="Europe  London (GMT + 00:00)"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.value.toLowerCase().includes(input.toLowerCase())
                        }
                        style={{width: "100%"}}
                      >
                        {TIMEZONES.map((option, optionIdx: number | string) => (
                          <Option key={optionIdx} value={option.value}>
                            {option.value} ({option.name})
                          </Option>
                        ))}
                      </Select>
                      {errors.timezone?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.timezone?.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </StyledModalContentWrapper>
            </>
          </CustomModal>
        </>
      )}
    </StyledWidgetWrapper>
  );
}
