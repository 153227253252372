import styled, {css} from "styled-components";

export const StyledPublicProfileOfferingDescriptionWrapper = styled.div`
  .content-title {
    font-size: 2rem;
    font-weight: 700;
  }
  .content-description {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 400;
  }
`;

export const StyledPublicProfileOfferingDescriptionText = styled.div<{
  showMore?: boolean;
}>`
  margin: 0;
  font-size: 1.6rem;
  font-weight: 400;

  ${({showMore}) =>
    !showMore &&
    css`
      overflow: hidden;
      display: -webkit-box;
      /* display 1 lines only */
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
    `}
`;

export const StyledMediaLogsWrapper = styled.div`
  padding: 2rem 1.6rem;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background: #fff;

  /* Card elevation */
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  .media-log-item {
    display: flex;
    align-items: center;
    gap: 2rem;
    .media-log-item-title {
      font-size: 1.6rem;
      font-weight: 600;
      width: 16.3rem;
    }
    .media-log-item-download-btn {
      margin-left: auto;
    }
  }
`;
