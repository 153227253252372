import {Alert, Col, Modal, Row, Spin} from "antd";
import {StyledInfoItem} from "../../../customers/details-page/info-section/info-section.style";
import {useGetAttendeesDetails} from "../../../../react-query-hooks/live-events/live-classes";
import {FullPageLoader} from "../../../full-page-loader";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";

type TAttendeeDetailsModalProps = {
  eventId?: string;
  attendeeId?: string | number;
  attendeeEmail?: string;
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
  footerContent?: React.ReactNode[];
};

export function AttendeesDetailsModal({
  eventId,
  attendeeId,
  attendeeEmail,
  cancelFunc,
  okFunc,
  visibleState,
  footerContent,
}: TAttendeeDetailsModalProps) {
  const getAttendeesDetailsQuery = useGetAttendeesDetails({
    attendeeId,
    eventId,
    attendeeEmail,
  });

  if (getAttendeesDetailsQuery.isLoading) {
    return (
      <Modal
        title="Attendee details"
        visible={visibleState}
        onOk={okFunc}
        onCancel={cancelFunc}
        footer={footerContent}
      >
        <StyledModalContentWrapper>
          <div
            style={{
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: " center",
            }}
          >
            <Spin size="default" />
          </div>
        </StyledModalContentWrapper>
      </Modal>
    );
  }

  if (getAttendeesDetailsQuery.isError) {
    return (
      <Modal
        title="Details Modal"
        visible={visibleState}
        onOk={okFunc}
        onCancel={cancelFunc}
        footer={footerContent}
      >
        <StyledModalContentWrapper>
          <Alert
            message="Error"
            description={getAttendeesDetailsQuery.error.message}
            type="error"
            showIcon
          />
        </StyledModalContentWrapper>
      </Modal>
    );
  }

  // console.log({getAttendeesDetailsQuery});
  return (
    <Modal
      title="Details Modal"
      visible={visibleState}
      onOk={okFunc}
      onCancel={cancelFunc}
      footer={footerContent}
    >
      <Spin spinning={getAttendeesDetailsQuery.isFetching}>
        <StyledModalContentWrapper>
          <Row gutter={[32, 20]}>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Name</p>
                <p className="info-value">
                  {getAttendeesDetailsQuery.data.booking.firstName}{" "}
                  {getAttendeesDetailsQuery.data.booking.lastName}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Email</p>
                <p className="info-value">
                  {getAttendeesDetailsQuery.data.booking.emailAddress}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Number of purchases</p>
                <p className="info-value">
                  {getAttendeesDetailsQuery.data.totalPurchases}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Total volume</p>
                <p className="info-value">
                  {getAttendeesDetailsQuery.data.totalVolume.toLocaleString("en-US")}
                </p>
              </StyledInfoItem>
            </Col>
            {getAttendeesDetailsQuery.data.booking.phone_number && (
              <Col span={24} md={12}>
                <StyledInfoItem>
                  <p className="info-title">Phone number</p>
                  <p className="info-value">
                    {getAttendeesDetailsQuery.data.booking.phone_number}
                  </p>
                </StyledInfoItem>
              </Col>
            )}
          </Row>
        </StyledModalContentWrapper>
      </Spin>
    </Modal>
  );
}
