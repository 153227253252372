import styled from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

export const StyledWaitListSection = styled.section`
  // background-color: transparent;
  // background-image: radial-gradient(at bottom center, #9747ff 40%, #246bfd00 100%);
  background-color: #9747ff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
  padding: 2rem;
`;
export const StyledWaitListInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  justify-content: center;

  .main-title {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
    max-width: 47.5rem;
    text-align: center;
    width: 100%;

    line-height: 1.15;

    // @media (max-width: 1024px) {
    //   font-size: 3rem;
    // }
    @media (max-width: 767px) {
      font-size: 2.6rem;
    }
  }
`;

export const StyledWaitListButton = styled(motion.a)`
  background-color: #ffffff;
  border-radius: 3.7rem;
  padding: 1.9rem 3.8rem;
  font-weight: 700;

  &:hover {
    color: #ffffff;
    background-color: #101a29;
  }
`;

export const MotionLink = motion(Link);
