import {
  TCustomerMessageContact,
  TCustomerMessageResObj,
  TMessageContact,
  TMessageResObj,
} from "../react-query-hooks/messages";

type TUpdateCoachMessageContactList = {
  type: "update-coach-message-contact-list";
  payload: {
    coachContacts: TMessageResObj[];
  };
};
type TUpdateCustomerMessageContactList = {
  type: "update-customer-message-contact-list";
  payload: {
    customerContacts: TCustomerMessageResObj[];
  };
};

type TAddToCoachMessageContactList = {
  type: "add-to-coach-message-contact-list";
  payload: {
    contact: TMessageContact;
  };
};
type TAddToCustomerMessageContactList = {
  type: "add-to-customer-message-contact-list";
  payload: {
    contact: TCustomerMessageContact;
  };
};

type TSetCoachActiveMessage = {
  type: "set-coach-active-message";
  payload: {
    activeMessage: TMessageResObj;
  };
};
type TSetCustomerActiveMessage = {
  type: "set-customer-active-message";
  payload: {
    activeMessage: TCustomerMessageResObj;
  };
};

export type TMessageActions =
  | TUpdateCoachMessageContactList
  | TUpdateCustomerMessageContactList
  | TAddToCoachMessageContactList
  | TAddToCustomerMessageContactList
  | TSetCoachActiveMessage
  | TSetCustomerActiveMessage;

type TMessageState = {
  coachContacts: TMessageResObj[];
  customerContacts: TCustomerMessageResObj[];
  coachContact: TMessageContact | null;
  customerContact: TCustomerMessageContact | null;
  coachActiveMessage: TMessageResObj | null;
  customerActiveMessage: TCustomerMessageResObj | null;
};

export const initialMessageState: TMessageState = {
  coachContacts: [],
  customerContacts: [],
  coachContact: null,
  customerContact: null,
  coachActiveMessage: null,
  customerActiveMessage: null,
};

export const messageReducer = (
  state: TMessageState = initialMessageState,
  action: TMessageActions
) => {
  const {coachContact, coachContacts, customerContacts} = state;
  if (action.type === "update-coach-message-contact-list") {
    const {coachContacts} = action.payload;
    return {...state, coachContacts};
  }
  if (action.type === "add-to-coach-message-contact-list") {
    const {contact} = action.payload;

    // const newContacts = [...coachContacts, contact]
    //   .filter(
    //     (value, index, self) =>
    //       index ===
    //       self.findIndex(
    //         (t) =>
    //           t.customerName === value.customerName &&
    //           t.emailAddress === value.emailAddress
    //       )
    //   )
    //   .reverse();
    return {...state, coachContact: contact};
  }

  if (action.type === "set-coach-active-message") {
    const {activeMessage} = action.payload;

    return {...state, coachActiveMessage: activeMessage};
  }
  if (action.type === "update-customer-message-contact-list") {
    const {customerContacts} = action.payload;
    return {...state, customerContacts};
  }
  if (action.type === "add-to-customer-message-contact-list") {
    const {contact} = action.payload;

    // const newContacts = [...customerContacts, contact]
    //   .filter(
    //     (value, index, self) =>
    //       index ===
    //       self.findIndex(
    //         (t) =>
    //           t.mentorName === value.mentorName &&
    //           t.mentorEmailAddress === value.mentorEmailAddress
    //       )
    //   )
    //   .reverse();
    return {...state, customerContact: contact};
  }

  if (action.type === "set-customer-active-message") {
    const {activeMessage} = action.payload;

    return {...state, customerActiveMessage: activeMessage};
  }

  return state;
};
