import {TwitterOutlined} from "@ant-design/icons";
import {Row, Col, Button, Space} from "antd";
import {
  StyledContactDetailsBox,
  StyledContactSection,
  StyledContactInfoList,
} from "./contact.style";
import {AiFillLinkedin} from "react-icons/ai";
import {MdMail} from "react-icons/md";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {CustomInputComponent} from "../../input/input-component";
import {contactSchema} from "./contact-schema";
import {TContactFormValues} from "./contact-form";
import {AnalyticsEvent} from "../../../utils/analytics";

const ContactSection = () => {
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<TContactFormValues>({
    resolver: yupResolver(contactSchema),
    mode: "onChange",
  });

  const onSubmit = async (values: TContactFormValues) => {
    // console.log({values});
    AnalyticsEvent(
      "Contact form",
      `Submitted contact form with name:${values.name}, email: ${values.email}, message: ${values.message}`
    );
  };
  return (
    <StyledContactSection>
      <Row gutter={[16, 30]}>
        <Col span={24} lg={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              <CustomInputComponent
                id="name"
                name="name"
                label="Name *"
                placeholder="Enter your name"
                error={errors.name?.message}
                control={control}
              />
              <CustomInputComponent
                id="email"
                name="email"
                label="E-mail Address *"
                type="email"
                placeholder="Enter your e-mail address"
                error={errors.email?.message}
                control={control}
              />
              <CustomInputComponent
                id="message"
                type="textarea"
                label="Message *"
                placeholder="Enter your message"
                error={errors.message?.message}
                control={control}
                name="message"
              />
              <div>
                <Button
                  disabled={
                    Object.keys(errors).length > 0 || Object.values(watch()).length < 1
                  }
                  block
                  //   loading={isLoadingUserLogin}
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Space>
          </form>
        </Col>
        <Col span={24} lg={12}>
          <StyledContactDetailsBox>
            <div className="contact-details-box-top">
              <h2 className="contact-details-box-top-title">Contact Information</h2>
              <p className="contact-details-box-top-sub-title">
                Feel free to reach out to us on our social media or fill out the form for
                any questions, enquires or partnerships.
              </p>
            </div>
            <div className="contact-details-box-bottom">
              <StyledContactInfoList>
                <li
                  className="contact-info-list-item"
                  onClick={() => AnalyticsEvent("Contact page", `Clicked on email`)}
                >
                  <a href="mailto:hello@coachli.co">
                    <span>
                      <MdMail />
                    </span>{" "}
                    <span className="link-text">hello@coachli.co</span>
                  </a>
                </li>
                <li
                  className="contact-info-list-item"
                  onClick={() => AnalyticsEvent("Contact page", `Clicked on twitter`)}
                >
                  <a
                    href="https://twitter.com/useCoachli"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <TwitterOutlined />
                    </span>{" "}
                    <span className="link-text">Twitter</span>
                  </a>
                </li>
                <li
                  className="contact-info-list-item"
                  onClick={() => AnalyticsEvent("Contact page", `Clicked on linkedin`)}
                >
                  <a
                    href="https://www.linkedin.com/company/coachli/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <AiFillLinkedin />
                    </span>{" "}
                    <span className="link-text">Linkedin</span>
                  </a>
                </li>
              </StyledContactInfoList>
            </div>
          </StyledContactDetailsBox>
        </Col>
      </Row>
    </StyledContactSection>
  );
};

export {ContactSection};
