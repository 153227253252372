import styled from "styled-components";

export const StyledCoverImageContent = styled.div`
  position: relative;
  height: 26.4rem;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;

  .cover-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    /* opacity: 0; */
    transition: 0.5s ease;
  }
  .cover-image-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .content-controls {
    position: absolute;
    top: 2.1rem;
    right: 2.1rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    color: #fff;
  }

  :hover .cover-image-overlay {
    opacity: 1;
  }
`;
