import {BankOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Button, Input, message, Modal, Select, Space, Spin, Row, Col} from "antd";
import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {connectBankFormSchema} from "../../../pages/dashboard/payouts/connect-bank-form-validation-schema";
import {IConnectBankFormValues} from "../../../pages/dashboard/payouts/i-connect-bank";
import {
  fetchAccountBalance,
  resetPayoutAccountName,
} from "../../../redux/payout/payout-slice";
import {AppDispatch, RootState} from "../../../redux/store";
import {StyledConnectBankBox} from "../../../styles/payouts/payouts";
import {StyledModalContentWrapper} from "../../calls/upcoming-calls/call-card/call-card.style";
import {StyledInputLabelWrapper} from "../../input/input.style";
import {CustomModal} from "../../modal";
import {
  TConnectBankPayload,
  TConnectToStripePayload,
  useCompleteStripeRegistration,
  useConnectBank,
  useConnectToStripe,
  useGetConnectedBankAccount,
  useGetPayoutBanks,
  useGetPayoutCountries,
  useLoginToStripe,
  useValidateBankAccount,
} from "../../../react-query-hooks/payout";
import {isNonEu} from "../../../utils/bank";
const {Option} = Select;

const DisconnectBankComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [openUpdateBankModal, setOpenUpdateBankModal] = useState<boolean>(false);
  const getPayoutCountriesQuery = useGetPayoutCountries();
  const getConnectedBankAccount = useGetConnectedBankAccount();
  const getPayoutBanksQueryMutation = useGetPayoutBanks();
  const connectBankQueryMutation = useConnectBank();
  const connectToStripeQueryMutation = useConnectToStripe();
  const completeStripeRegistrationQueryMutation = useCompleteStripeRegistration();
  const loginToStripeQueryMutation = useLoginToStripe();

  const {accountBalance} = useSelector((state: RootState) => state.userPayout);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    setValue,
    reset,
  } = useForm<IConnectBankFormValues>({
    resolver: yupResolver(connectBankFormSchema),
    mode: "onChange",
  });
  const accountNumberValue = watch("accountNumber");
  const countryCodeValue = watch("countryCode");
  const bankCodeValue = watch("bankCode");
  // const kenyaSelected = isKenyanBank.includes(countryCodeValue);
  const bankName: {id: number; code: string; name: string} =
    getPayoutBanksQueryMutation.isSuccess &&
    getPayoutBanksQueryMutation.data.data.find(
      (bank: {id: number; code: string; name: string}) => bank.code === bankCodeValue
    );

  const validateBankAccountQueryMutation = useValidateBankAccount({
    accountNumber:
      accountNumberValue && accountNumberValue.length === 10
        ? accountNumberValue
        : undefined,
    bankCode: bankCodeValue,
    bankName: bankName?.name,
    countryCode: countryCodeValue,
  });
  useEffect(() => {
    dispatch(fetchAccountBalance(null));
  }, [dispatch]);

  if (getPayoutCountriesQuery.isLoading || getConnectedBankAccount.isLoading) {
    return (
      <div
        style={{
          height: "200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (getPayoutCountriesQuery.isError || getConnectedBankAccount.isError) {
    return (
      <Alert
        message="Error"
        description={
          getPayoutCountriesQuery.error?.message || getConnectedBankAccount.error?.message
        }
        type="error"
        showIcon
      />
    );
  }
  const handleCompleteStripeRegistration = () => {
    if (
      getConnectedBankAccount.data.bank.provider === "stripe" &&
      getConnectedBankAccount.data.bank.statusName === "Inactive"
    ) {
      completeStripeRegistrationQueryMutation.mutate(null, {
        onSuccess: (data) => {
          if (data.data.isVerified) {
            return;
          }
          message.info("Redirecting to stripe....", 1, () => {
            // const url = data.data.link;
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("target", "_blank");
            // document.body.appendChild(link);
            // link.click();
            setTimeout(() => {
              window.open(data.data.link);
            }, 0);
          });
        },
      });
    }
  };
  const handleLoginToStripe = () => {
    if (
      getConnectedBankAccount.data.bank.provider === "stripe" &&
      getConnectedBankAccount.data.bank.statusName === "Active"
    ) {
      // console.log("Login to stripe");

      loginToStripeQueryMutation.mutate(null, {
        onSuccess: (data) => {
          if (data.data.isVerified) {
            return;
          }
          message.info("Redirecting to stripe....", 1, () => {
            // const url = data.data.link;
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("target", "_blank");
            // document.body.appendChild(link);
            // link.click();
            setTimeout(() => {
              window.open(data.data.link);
            }, 0);
          });
        },
      });
    }
  };
  const showConnectBankModal = () => {
    setOpenUpdateBankModal(true);
  };
  const handleCancel = () => {
    // dispatch(payoutLogoutReset());
    reset();
    dispatch(resetPayoutAccountName());
    setOpenUpdateBankModal(false);
  };
  const handleOk = () => {
    reset();
    setOpenUpdateBankModal(false);
  };

  const handleCountrySelectChange = (e: any, onChange: any) => {
    onChange(e);
    setValue("accountNumber", "");
    if (isNonEu.includes(e)) {
      getPayoutBanksQueryMutation.mutate({countryCode: e});
      setValue("bankCode", "");
    }
  };

  const handleBankSelectChange = (e: any, onChange: any) => {
    onChange(e);
  };
  const onSubmit = async (values: IConnectBankFormValues) => {
    if (isNonEu.includes(values.countryCode)) {
      const bankName: {id: number; code: string; name: string} =
        getPayoutBanksQueryMutation.data.data.find(
          (bank: {id: number; code: string; name: string}) =>
            bank.code === values.bankCode
        );
      const payload: TConnectBankPayload = {
        accountNumber: values.accountNumber,
        bankCode: values.bankCode,
        bankName: bankName.name,
        countryCode: values.countryCode,
      };
      //TODO: COMPLETE CONNECTION FOR NORMAL BANK
      connectBankQueryMutation.mutate(payload, {
        onSuccess: () => {
          message.success("Bank updated successfully!");
          handleOk();
        },
      });
    } else {
      const countryPayload = getPayoutCountriesQuery.data.data.countries.find(
        (country: {name: string; code: string; currency: string}) =>
          country.code === values.countryCode
      );
      //TODO: COMPLETE CONNECTION FOR STRIPE
      const stripePayload: TConnectToStripePayload = {
        countryCode: values.countryCode,
        countryName: countryPayload ? countryPayload.name : "",
        currencyCode: countryPayload ? countryPayload.currency : "",
      };
      connectToStripeQueryMutation.mutate(stripePayload, {
        onSuccess: (data) => {
          handleCancel();
          message.success(
            "Your bank account has been created successfully! Please, complete your registration!"
          );
          setTimeout(() => {
            window.open(data.data.LoginLink);
          }, 0);
          // window.open(data.data.LoginLink);
          // console.log({data});
        },
      });
    }
  };
  return (
    <>
      <StyledConnectBankBox>
        <div className="bank-icon-container">
          <BankOutlined style={{fontSize: "2.5rem", color: "#2F54EB"}} />
        </div>
        <div className="bank-connect-text">
          <h3>{getConnectedBankAccount.data.bank.account_name}</h3>
          <p>
            {getConnectedBankAccount.data.bank.bank_name} -{" "}
            {getConnectedBankAccount.data.bank.account_number}
          </p>
        </div>
        <div className="bank-connect-btn">
          {getConnectedBankAccount.data.bank.provider === "stripe" ? (
            <>
              <div>
                <Button
                  onClick={
                    getConnectedBankAccount.data.bank.statusName === "Inactive" &&
                    Number(accountBalance.balance) === 0
                      ? showConnectBankModal
                      : undefined
                  }
                  // onClick={showConnectBankModal}
                  type="primary"
                  block
                  shape="round"
                  size="large"
                  disabled={
                    getConnectedBankAccount.data.bank.statusName === "Active" &&
                    Number(accountBalance.balance) > 0
                  }
                >
                  Update account details
                </Button>
              </div>
              <div style={{marginTop: "2rem"}}>
                <Button
                  onClick={
                    getConnectedBankAccount.data.bank.statusName === "Inactive"
                      ? handleCompleteStripeRegistration
                      : handleLoginToStripe
                  }
                  type="primary"
                  block
                  shape="round"
                  size="large"
                  loading={
                    completeStripeRegistrationQueryMutation.isLoading ||
                    loginToStripeQueryMutation.isLoading
                  }
                >
                  {getConnectedBankAccount.data.bank.statusName === "Inactive"
                    ? "Complete registration"
                    : "Login to your account"}
                </Button>
              </div>
            </>
          ) : (
            <Button
              onClick={showConnectBankModal}
              type="primary"
              block
              shape="round"
              size="large"
              disabled={
                getConnectedBankAccount.data.bank.statusName === "Inactive" ||
                Number(accountBalance.balance) > 0
              }
            >
              Update account details
            </Button>
          )}
        </div>
      </StyledConnectBankBox>
      <CustomModal
        visibleState={openUpdateBankModal}
        title="Update your bank details"
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            shape="round"
            onClick={handleSubmit(onSubmit)}
            disabled={
              Object.keys(errors).length > 0 ||
              Object.values(watch()).length < 1 ||
              validateBankAccountQueryMutation.isError
            }
            loading={
              connectBankQueryMutation.isLoading || connectToStripeQueryMutation.isLoading
            }
          >
            Complete connection
          </Button>,
        ]}
      >
        <>
          <StyledModalContentWrapper>
            <p>Your earnings will be sent to this bank account </p>
            <div style={{margin: "3.3rem 0"}}>
              <Space direction="vertical" size={25} style={{display: "flex"}}>
                <Controller
                  control={control}
                  name="countryCode"
                  render={({field: {onChange, value, ref}}) => (
                    <div>
                      <StyledInputLabelWrapper>
                        <label id="country-label" htmlFor="country">
                          <span style={{color: "#f5222d", marginRight: ".8rem"}}>*</span>{" "}
                          Country
                        </label>
                      </StyledInputLabelWrapper>

                      <Select
                        onChange={(e) => handleCountrySelectChange(e, onChange)}
                        value={value}
                        ref={ref}
                        style={{width: "100%"}}
                        id="country"
                        size="large"
                        status={errors.countryCode?.message ? "error" : ""}
                        showSearch
                        placeholder="Select country"
                        loading={
                          getPayoutCountriesQuery.isFetching ||
                          getPayoutCountriesQuery.isLoading
                        }
                        disabled={
                          getPayoutCountriesQuery.isLoading ||
                          getPayoutCountriesQuery.isFetching ||
                          getPayoutCountriesQuery.isError
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        notFoundContent={
                          getPayoutCountriesQuery.isLoading ||
                          getPayoutCountriesQuery.isFetching ? (
                            <Spin />
                          ) : null
                        }
                      >
                        {getPayoutCountriesQuery.data.data.countries.map(
                          (option, optionIdx: number) => (
                            <Option key={optionIdx} value={option.code}>
                              {option.name}
                            </Option>
                          )
                        )}
                      </Select>
                      {errors.countryCode?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.countryCode?.message}
                        </div>
                      )}
                    </div>
                  )}
                />
                {isNonEu.includes(countryCodeValue) && (
                  <Controller
                    control={control}
                    name="bankCode"
                    render={({field: {onChange, value, ref}}) => (
                      <div>
                        <StyledInputLabelWrapper>
                          <label id="bank-label" htmlFor="bank">
                            <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                              *
                            </span>
                            Bank
                          </label>
                        </StyledInputLabelWrapper>

                        <Select
                          onChange={(e) => handleBankSelectChange(e, onChange)}
                          value={value}
                          ref={ref}
                          style={{width: "100%"}}
                          id="bank"
                          size="large"
                          status={errors.bankCode?.message ? "error" : ""}
                          showSearch
                          placeholder="Select bank"
                          loading={getPayoutBanksQueryMutation.isLoading}
                          disabled={
                            getPayoutBanksQueryMutation.isLoading || !countryCodeValue
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          notFoundContent={
                            getPayoutBanksQueryMutation.isLoading ? <Spin /> : null
                          }
                        >
                          {getPayoutBanksQueryMutation.isSuccess
                            ? getPayoutBanksQueryMutation.data.data.map(
                                (option: {id: number; code: string; name: string}) => (
                                  <Option key={option.id} value={option.code}>
                                    {option.name}
                                  </Option>
                                )
                              )
                            : []}
                        </Select>
                        {errors.bankCode?.message && (
                          <div role="alert" className="ant-form-item-explain-error">
                            {errors.bankCode?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
                {isNonEu.includes(countryCodeValue) && (
                  <>
                    <Controller
                      control={control}
                      name="accountNumber"
                      render={({field: {onChange, value}}) => (
                        <>
                          <StyledInputLabelWrapper>
                            <label id="accountNumber" htmlFor="accountNumber">
                              <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                                *
                              </span>{" "}
                              <span>Account number</span>
                            </label>
                          </StyledInputLabelWrapper>
                          <Input
                            id="accountNumber"
                            status={errors.accountNumber?.message && "error"}
                            size="large"
                            placeholder="Enter account number"
                            disabled={
                              !watch("countryCode") ||
                              !watch("bankCode") ||
                              (accountNumberValue?.length === 10 &&
                                validateBankAccountQueryMutation.isSuccess)
                            }
                            onChange={onChange}
                            value={value}
                          />
                          {errors.accountNumber?.message && (
                            <div role="alert" className="ant-form-item-explain-error">
                              {errors.accountNumber?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                    {validateBankAccountQueryMutation.isSuccess && (
                      <p style={{fontSize: "1.4rem", textTransform: "uppercase"}}>
                        {validateBankAccountQueryMutation.data.name}
                      </p>
                    )}
                  </>
                )}
              </Space>
            </div>
          </StyledModalContentWrapper>
        </>
      </CustomModal>
    </>
  );
};

export {DisconnectBankComponent};
