import {DollarOutlined, DownOutlined} from "@ant-design/icons";
import {CurrencyResponse} from "../../../../../../redux/service/i-service";
import {StyledCustomCurrencySelect} from "../../../../../booking/left-column/left-column.style";
import {Select, Space, message} from "antd";
import {useEffect, useState} from "react";
import {useServiceBookingContext} from "../../../../../../context/service-booking/service-booking.context";

type TProductDetailsWidgetInfoProps = {
  serviceCurrencies: CurrencyResponse[];
  defaultServicePrice?: number;
  defaultServiceCurrency?: string;
  selectedCurrency?: {
    label: string;
    value: string | number;
  };
};

export function ServiceCurrencyInfo({serviceCurrencies}: TProductDetailsWidgetInfoProps) {
  const {state, dispatch} = useServiceBookingContext();
  const defaultCallPriceCurrency = state.serviceData?.service.callPriceCurrency;
  const defaultCallPrice = state.serviceData?.service.callPrice;
  const defaultCallCurrencies = serviceCurrencies;
  // console.log({state});

  function handleCurrencyListOptions() {
    const defaultCurrencyObj = {
      label: `${defaultCallPriceCurrency} ${defaultCallPrice?.toLocaleString()}`,
      value: `default`,
    };
    const mappedCurrencies = defaultCallCurrencies.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString()}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies) {
      return [defaultCurrencyObj, ...mappedCurrencies];
    } else {
      return [];
    }
  }
  const [defaultCurrencyOption, setDefaultCurrencyOption] = useState<string | number>(
    () => handleCurrencyListOptions()[0].value
  );

  useEffect(() => {
    if (state.selectedCurrency) {
      setDefaultCurrencyOption(state.selectedCurrency.value);
    }
  }, [state.selectedCurrency]);

  const handleCurrencyChange = (value: any) => {
    setDefaultCurrencyOption(value);
    const selectedValue = defaultCallCurrencies.find((x) => x.id === Number(value));
    let selectedCurrency;

    // console.log({selectedValue, value, defaultCallCurrencies});

    if (selectedValue) {
      if (value === "default") {
        selectedCurrency = {
          label: `${defaultCallPriceCurrency} ${defaultCallPrice}`,
          value: "default",
          amount: defaultCallPrice as number,
          currency: defaultCallPriceCurrency as string,
        };
      } else {
        selectedCurrency = {
          label: `${selectedValue.currency} ${selectedValue.amount}`,
          value: selectedValue.id,
          amount: selectedValue.amount,
          currency: selectedValue.currency,
        };
      }
      dispatch({
        type: "update-selected-currency",
        payload: {
          selectedCurrency,
        },
      });
    } else {
      return message.info("Currency not accepted");
    }
  };
  return (
    <div>
      <label style={{fontSize: "1.6rem"}} id="currency_label" htmlFor="currency">
        {defaultCallCurrencies.length > 0 && handleCurrencyListOptions().length > 1
          ? "Select currency"
          : "Price"}
      </label>
      <div>
        <Space size={10}>
          <DollarOutlined style={{color: "#7939CC"}} />
          {defaultCallPrice === 0 ? (
            <span style={{fontSize: "1.6rem", fontWeight: "600"}}>Free</span>
          ) : handleCurrencyListOptions().length > 1 ? (
            <div>
              <StyledCustomCurrencySelect
                // labelInValue
                bordered={false}
                suffixIcon={
                  <DownOutlined
                    size={15}
                    style={{fontWeight: "bold", color: "#00171F"}}
                  />
                }
                onChange={handleCurrencyChange}
                value={String(defaultCurrencyOption)}
                size={"large"}
                defaultValue={defaultCurrencyOption}
              >
                {handleCurrencyListOptions().map((option, optionIdx: number | string) => (
                  <Select.Option key={optionIdx} value={option.value}>
                    {`${option.label}`}
                  </Select.Option>
                ))}
              </StyledCustomCurrencySelect>
            </div>
          ) : (
            <span
              style={{fontSize: "1.6rem", fontWeight: "600"}}
            >{`${defaultCallPriceCurrency} ${defaultCallPrice?.toLocaleString(
              "en-US"
            )}`}</span>
          )}
        </Space>
      </div>
    </div>
  );
}
