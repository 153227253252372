import {DownloadOutlined} from "@ant-design/icons";
import {Alert, Button, message, Table} from "antd";
import type {ColumnsType} from "antd/es/table";
import {useNavigate} from "react-router-dom";
import {useExportCSV, useGetCustomers} from "../../../react-query-hooks/customer";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {
  StyledDescriptionText,
  StyledNoUpcomingCallBox,
  StyledUpcomingCallsWrapper,
} from "../../calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../full-page-loader";

interface DataType {
  key: string;
  name: string;
  email: string;
  reference: string;
}

const CustomerDetailsSection = () => {
  const getCustomersQuery = useGetCustomers();
  const exportCsvQueryMutation = useExportCSV();
  const navigate = useNavigate();

  if (getCustomersQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getCustomersQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getCustomersQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const customersData: DataType[] = getCustomersQuery.data.map((transaction) => ({
    key: transaction.id.toString(),
    name: transaction.first_name + " " + transaction.last_name,
    email: transaction.email_address.toLowerCase(),
    reference: transaction.reference,
  }));
  // console.log({customersData});

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {text}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "17.425rem",
      key: "email",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "17.425rem",
      key: "reference",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/dashboard/customers/view/${record.key}`)}
        >
          View details
        </Button>
      ),
    },
  ];

  const handleOnRow = (record: any) => {
    return {
      onClick: () => navigate(`/dashboard/customers/view/${record.key}`), // click row
    };
  };

  const handleExportAsCSV = () => {
    exportCsvQueryMutation.mutate(undefined, {
      onSuccess: (data) => {
        message.success("File ready to be downloaded!");
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Coachli.csv");
        document.body.appendChild(link);
        link.click();
      },
    });
  };

  return (
    <>
      <div style={{marginTop: "7rem"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "4rem",
          }}
        >
          <StyledPageHeader>Customer details 📇</StyledPageHeader>
          <div>
            <Button
              onClick={handleExportAsCSV}
              disabled={customersData.length < 1}
              block
              icon={<DownloadOutlined />}
              size="large"
              shape="round"
              loading={exportCsvQueryMutation.isLoading}
            >
              Export CSV
            </Button>
          </div>
        </div>
        {customersData.length < 1 ? (
          <StyledUpcomingCallsWrapper style={{paddingTop: 0}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">Your customers will appear here</p>
            </StyledNoUpcomingCallBox>
          </StyledUpcomingCallsWrapper>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={customersData}
              loading={getCustomersQuery.isFetching}
              scroll={{x: 100}}
              // pagination={false}
            />
            {/* <CustomPagination
              isPreviousData={getCustomersQuery.isPreviousData}
              page={page}
              setPage={setPage}
              paginationMeta={getCustomersQuery.data.meta}
            /> */}
          </>
        )}
      </div>
    </>
  );
};

export {CustomerDetailsSection};
