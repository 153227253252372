import styled from "styled-components";
import {Card} from "antd";

export const StyledCallCard = styled(Card)`
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .call-card-description {
    font-weight: 400;
    font-size: 1.4rem;
    color: #00171f;
  }
`;

export const StyledCallCardExtraInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex-wrap: wrap;

  .phone-number-extra-details {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .call-card-extra-info-item {
    .call-card-extra-info-item-value {
      // font-family: Red Hat Display !important;
      font-weight: 500;
      font-size: 1.4rem;
      color: #00171f;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: initial;
  }
`;
export const StyledModalContentWrapper = styled.div`
  padding: 1.6rem 2.4rem;
`;
export const StyledModalContentInfo = styled.div`
  font-size: 1.4rem;
  .content-heading {
    font-weight: 500;
  }
  .content-text {
    font-weight: 400;
    margin-top: 1rem;
  }
`;
export const StyledCardDetailsButtonWrapper = styled.div`
  display: flex;
  // align-items: center;
  gap: 1rem;

  @media (max-width: 500px) {
    margin-top: 1.3rem;
    flex-direction: column;
  }
`;
