import * as yup from "yup";
import {Button, Space} from "antd";
import {StyledPublicProfileOfferingDrawer} from "../../../../public-profile/service/booking-modal";
import {CloseOutlined} from "@ant-design/icons";
import {LiveClassTimeReschedule} from "../time-reschedule";
import {CustomInputComponent} from "../../../../input/input-component";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

const messageSchema = yup.object().shape({
  message: yup.string().trim(),
});

type LiveClassRescheduleModalProps = {
  visible: boolean;
  onClose: () => void;
  modalData:
    | Partial<{
        session_info: {
          name: string;
          startDate: string;
          startTime: string;
          duration: string | number;
          eventId: string | number;
          customDuration: string | number;
        }[];
      }>
    | undefined;
};

export function LiveClassRescheduleModal({
  modalData,
  onClose,
  visible,
}: LiveClassRescheduleModalProps) {
  const {
    formState: {errors, isValid},
    control,
    reset,
    getValues,
    watch,
  } = useForm<{
    message: string;
  }>({
    resolver: yupResolver(messageSchema),
    mode: "onChange",
  });
  return (
    <StyledPublicProfileOfferingDrawer
      title={"Reschedule live class"}
      footer={null}
      visible={visible}
      // onOk={handleBookingModalOk}
      onClose={() => {
        reset();
        onClose();
      }}
      maskClosable={true}
      closable={false}
      extra={
        <Button
          type="ghost"
          size="small"
          icon={<CloseOutlined />}
          onClick={onClose}
        ></Button>
      }
    >
      <div>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <div>
            <p>
              We will notify your registered customers after you have set your new date
              and time.
            </p>
            <CustomInputComponent
              id="personalMessage"
              name="message"
              type="textarea"
              label={`Include a personal message to your customers`}
              placeholder="Type a message"
              error={errors.message?.message}
              control={control}
            />
          </div>
          <div>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              {modalData?.session_info?.map((sesh, index) => (
                <LiveClassTimeReschedule
                  customDuration={sesh.customDuration}
                  duration={sesh.duration}
                  name={sesh.name}
                  startDate={sesh.startDate}
                  startTime={sesh.startTime}
                  eventId={sesh.eventId}
                  scheduleId={index}
                  msgValid={isValid}
                  msgGetValues={getValues}
                  msgReset={reset}
                  msgWatch={watch}
                  closeModal={onClose}
                  key={index}
                />
              ))}
            </Space>
          </div>
        </Space>
      </div>
    </StyledPublicProfileOfferingDrawer>
  );
}
