import styled from "styled-components";

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  // width: 100%;
`;
export const StyledVerificationBox = styled.div`
  max-width: 64.2rem;
  padding: 0 1rem;

  .email-verification-text {
    // margin-top: 1.6rem;
    .main-text {
      font-weight: 700;
      font-size: 2.4rem;
      margin: 1.6rem 0;
    }
  }
`;
