import {Space} from "antd";
import {paymentOptions} from "../../utils/currency";
import {PaymentOption, PaymentOptionList} from "./payment-options.style";

export function PaymentOptions() {
  return (
    <PaymentOptionList>
      <p className="payment-option-text">Multiple payment options supported</p>
      <Space size={8} wrap>
        {paymentOptions.map((option) => (
          <PaymentOption style={{}} key={option.alt}>
            <img
              className="payment-option-image"
              src={option.imageSrc}
              alt={option.alt}
            />
          </PaymentOption>
        ))}
      </Space>
    </PaymentOptionList>
  );
}
