import {Button} from "antd";
import {StyledNoUpcomingCallBox} from "../../calls/upcoming-calls/upcoming-calls.style";
import {Link} from "react-router-dom";

export function NoProducts() {
  return (
    <StyledNoUpcomingCallBox>
      <p className="info-text">
        You do not have any digital product at the moment. Create a new digital product to
        get started.
      </p>
      <div>
        <Link to="/dashboard/products/new">
          <Button size="large" block shape="round" type="primary" htmlType="button">
            Create new digital product
          </Button>
        </Link>
      </div>
    </StyledNoUpcomingCallBox>
  );
}
