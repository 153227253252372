import {StarFilled} from "@ant-design/icons";
import {
  StyledTestimonialItem,
  StyledTestimonialItemRatingWrapper,
  StyledTestimonialItemReviewText,
  StyledTestimonialItemReviewerInfo,
} from "./testimonial-item.style";

type TTestimonialItem = {
  ratingCount: number;
  review: string;
  imgSrc: string;
  reviewerName: string;
  reviewerRole: string;
};

function TestimonialItem({
  ratingCount,
  imgSrc,
  review,
  reviewerName,
  reviewerRole,
}: TTestimonialItem) {
  return (
    <StyledTestimonialItem>
      <StyledTestimonialItemRatingWrapper>
        {Array.from(Array(ratingCount).keys()).map((rating) => (
          <StarFilled />
        ))}
      </StyledTestimonialItemRatingWrapper>
      <StyledTestimonialItemReviewText>
        <p className="review-text">{review}</p>
      </StyledTestimonialItemReviewText>
      <StyledTestimonialItemReviewerInfo>
        <div className="reviewer-info-image-wrapper">
          <img src={imgSrc} alt="" />
        </div>
        <div className="reviewer-info-personal-info">
          <h3 className="reviewer-name">{reviewerName}</h3>
          <p className="reviewer-role">{reviewerRole}</p>
        </div>
      </StyledTestimonialItemReviewerInfo>
    </StyledTestimonialItem>
  );
}

export {TestimonialItem};
