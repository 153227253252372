import {Space} from "antd";
import {StyledLiveClassesMainSectionWrapper} from "../../../live-classes-section/details/main-details-section.style";
import {OfferingCreatorDetails} from "../../../offering/creator-details";
import {ShareOfferingWidget} from "../../../offering/share-offering";
import {TPublicProfileProductDetailsApiResponse} from "../../../../../react-query-hooks/products";
import {
  handleFacebookLink,
  handleTwitterLink,
  handleMailLink,
  handleProductLink,
} from "../../../../../utils/products";
import {ProductDetailsWidgetInfo} from "../../../offering/product-details/widget";

export type TProductMainDetailsSectionProps = {
  productData: TPublicProfileProductDetailsApiResponse;
};

export function ProductMainDetailsSection({
  productData,
}: TProductMainDetailsSectionProps) {
  const extraParams = {
    facebookLink: handleFacebookLink({
      username: productData.profile.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just bought a product from ${productData.user.firstName} ${productData.user.lastName} on Coachli!`,
      username: productData.profile.username,
    }),
    mailLink: handleMailLink({
      body: `I just bought a product from ${productData.user.firstName} ${productData.user.lastName} on Coachli! ${window.location.origin}/${productData.profile.username}`,
      subject: "",
    }),
    copyLink: handleProductLink({
      productId: productData.product.id,
      slug: productData.product.slug,
      username: productData.profile.username,
      windowNav: true,
    }),
  };
  return (
    <StyledLiveClassesMainSectionWrapper>
      <div className="live-classes-main-details-section live-classes-main-details-about-content">
        <Space
          direction="vertical"
          size={25}
          style={{
            display: "flex",
          }}
        >
          <div>
            <h1 className="content-heading">About this product</h1>
            <div
              className="content-info-text"
              dangerouslySetInnerHTML={{__html: productData.product.description}}
            />
          </div>
        </Space>
      </div>

      <div className="live-classes-main-details-section live-classes-main-details-call-content">
        <Space
          direction="vertical"
          size={25}
          style={{
            display: "flex",
          }}
        >
          <ProductDetailsWidgetInfo productCurrencies={productData.currencies} />
          <OfferingCreatorDetails
            title="Created by"
            creatorInfo={{
              avatar: productData.user.avatar,
              description: productData.profile.description,
              firstName: productData.user.firstName,
              lastName: productData.user.lastName,
            }}
          />
          <ShareOfferingWidget extraParams={extraParams} title="Share product via" />
        </Space>
      </div>
    </StyledLiveClassesMainSectionWrapper>
  );
}
