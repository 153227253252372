import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {DashboardHeader} from "../../components/dashboard-header";
import {SideNav} from "../../components/dashboard-sidebar";
import {RootState} from "../../redux/store";
import {
  DashboardContainer,
  DashboardHeaderContainer,
  DashboardMainContainer,
  StyledMainSectionContainer,
  StyledMainSectionInnerContainer,
} from "./dashboard-wrapper.style";
import ReactGA from "react-ga";
import {useGetSavedCategories} from "../../react-query-hooks/category";
import {Helmet} from "react-helmet-async";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {Alert} from "antd";
import {useCurrencyContext} from "../../context/currency-context";

declare const window: Window &
  typeof globalThis & {
    Intercom: any;
    heap: any;
  };

interface IDashboardWrapper {
  children: React.ReactNode;
  pageTitle: string;
  pageDesc?: string;
  pageSlug?: string;
}

const DashboardWrapper: React.FC<IDashboardWrapper> = ({
  children,
  pageTitle,
  pageDesc,
  pageSlug,
}) => {
  const {state} = useCurrencyContext();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [showNav, setShowNav] = useState(false);
  const [showConnectBankMessage, setShowConnectBankMessage] = useState(true);

  const handleCloseConnectBankMessage = () => {
    setShowConnectBankMessage(false);
  };
  const navigate = useNavigate();
  const getSavedCategoriesQuery = useGetSavedCategories();

  const toggle = () => {
    setShowNav(!showNav);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.isCustomer) {
      navigate("/dashboard/customer/home");
    }
  }, [navigate, userInfo]);
  window.heap.identify(
    `${userInfo.user.id}: ${userInfo.user.firstName} ${userInfo.user.lastName}`
  );
  window.heap.addUserProperties({
    name: `${userInfo.user.firstName} ${userInfo.user.lastName}`, // Full name
    email: `${userInfo.user.email.toLowerCase()}`, // Email address
    user_id: `${userInfo.user.id}`,
  });
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "lhv6fa0p",
    name: `${userInfo.user.firstName} ${userInfo.user.lastName}`, // Full name
    email: `${userInfo.user.email.toLowerCase()}`, // Email address
    user_id: `${userInfo.user.id}`,
    created_at: `${userInfo.user.createdAt}`, // Signup date as a Unix timestamp
  });

  if (
    (getSavedCategoriesQuery.data && getSavedCategoriesQuery.data.length < 1) ||
    getSavedCategoriesQuery.isError
  ) {
    return <Navigate to="/dashboard/onboarding" />;
  }

  console.log({state});

  return (
    <>
      <Helmet>
        <title>Coachli | {pageTitle ?? "Do more with Coachli"} </title>
        <meta
          name="description"
          content={
            pageDesc ||
            "The simplest way to host paid 1:1 calls with your clients, mentees and audience."
          }
        />
        <link rel="canonical" href={pageSlug} />
        <meta property="og:url" content={pageSlug} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />
        {/* <meta property="og:image" content={image} />
            <meta name="og:image" content={image} /> */}
        <meta property="og:type" content="website" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDesc} />
        {/* <meta property="twitter:image" content={image} /> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:alt" content="Coachli Logo" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={pageSlug} />
      </Helmet>
      <DashboardContainer>
        {/* <DashboardSideBarContainer> */}
        {/* <DashboardSidebar toggleNav={toggle} visible={showNav} close={toggle} /> */}
        <SideNav toggleNav={toggle} visible={showNav} />
        {/* </DashboardSideBarContainer> */}

        <DashboardMainContainer>
          <DashboardHeaderContainer>
            <DashboardHeader toggleNav={toggle} />
          </DashboardHeaderContainer>
          <StyledMainSectionContainer>
            <StyledMainSectionInnerContainer>
              {!state.connectedBankStatus &&
                (showConnectBankMessage ? (
                  <div style={{marginBottom: "2rem", maxWidth: "72rem"}}>
                    <Alert
                      message={
                        <div style={{color: "#9747FF", fontSize: "1.4rem"}}>
                          <span>
                            Setup your payout details to enable you receive your earnings.
                          </span>{" "}
                          <Link
                            style={{fontWeight: 600, textDecoration: "underline"}}
                            to="/dashboard/payouts"
                          >
                            Connect account
                          </Link>
                        </div>
                      }
                      style={{background: "#F5EDFF", border: "none"}}
                      // closable
                      // afterClose={handleCloseConnectBankMessage}
                    />
                  </div>
                ) : null)}
              {children}
            </StyledMainSectionInnerContainer>
          </StyledMainSectionContainer>
        </DashboardMainContainer>
      </DashboardContainer>
    </>
  );
};

export {DashboardWrapper};
