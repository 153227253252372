import {useScroll, useTransform} from "framer-motion";
import {useRef} from "react";
import {EXTRAS_DATA} from "../../../services/home/extras";
import {StyledContainer, StyledSection} from "../features-section/features-section.style";
import {ExtraItem} from "./extra-item";

const ExtrasSection = () => {
  const textTargetRef = useRef<HTMLDivElement>(null);

  const {scrollYProgress: scrollYProgressText} = useScroll({
    target: textTargetRef,
    offset: ["start end", "end start"],
  });
  const textScale = useTransform(scrollYProgressText, [0, 0.5, 0.9, 1], [1, 1, 0.8, 0.8]);
  const textOpacity = useTransform(scrollYProgressText, [0, 0.5, 0.9, 1], [1, 1, 0, 0]);
  return (
    <StyledSection style={{paddingTop: "7.5rem"}}>
      <StyledContainer>
        {/* <StyledSectionHeader
          ref={textTargetRef}
          style={{opacity: textOpacity, scale: textScale}}
        >
          <h2 className="section-header-main-title">Do more with Coachli</h2>
          <p className="section-header-sub-title">
            Engage and connect with your audience at your own pace on your own terms
          </p>
        </StyledSectionHeader> */}
        <div>
          {EXTRAS_DATA.map((item) => (
            <ExtraItem
              tag={item.tag}
              key={item.title}
              title={item.title}
              desc={item.desc}
              imgSrc={item.imgSrc}
              fallbackImgSrc={item.fallbackImgSrc}
              isComingSoon={item.isComingSoon}
              isReverse={item.isReverse}
              extras={item.extras}
            />
          ))}
        </div>
      </StyledContainer>
    </StyledSection>
  );
};

export {ExtrasSection};
