import {Spin} from "antd";
import {useGetCoachMessages} from "../../../react-query-hooks/messages";
import {LoadingSpinner} from "../../icons/icons";
import {StyledChatMenu} from "../chat-list/chat-list.style";
import {MessageCustomerListItem} from "./message-customer-list-item";

const MessageCustomerList = () => {
  const getCoachMessagesQuery = useGetCoachMessages();
  return (
    <StyledChatMenu>
      <div
        style={{
          borderRight: "1px solid #F0F0F0",
          // width: "30.4rem",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Spin
          spinning={getCoachMessagesQuery.isFetching}
          // tip="Updating..."
          indicator={LoadingSpinner}
        >
          {getCoachMessagesQuery.data &&
            getCoachMessagesQuery.data.data.messages.map((contact) => (
              <MessageCustomerListItem key={contact.id} contact={contact} />
            ))}
        </Spin>
      </div>
    </StyledChatMenu>
  );
};

export {MessageCustomerList};
