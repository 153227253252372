import {Spin, Alert} from "antd";
import {IOnboardingStepProps} from "../i-step";
import {Step1Form} from "./step-1-form";
import {useGetCountries} from "../../../react-query-hooks/general";
import {useGetProfile} from "../../../react-query-hooks/profile";
import {FullPageLoader} from "../../../components/full-page-loader";

const OnboardingStep1: React.FC<IOnboardingStepProps> = ({updateStep}) => {
  const getCountriesQuery = useGetCountries();
  const getProfileQuery = useGetProfile();
  if (getProfileQuery.isLoading || getCountriesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getProfileQuery.isError || getCountriesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getProfileQuery.error?.message || getCountriesQuery.error?.message}
        type="error"
        showIcon
      />
    );
  }
  return (
    <div>
      <Spin spinning={getProfileQuery.isFetching}>
        <Step1Form
          updateStep={updateStep}
          countries={getCountriesQuery.data}
          profileData={getProfileQuery.data}
        />
      </Spin>
    </div>
  );
};

export {OnboardingStep1};
