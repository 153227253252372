import axios from "axios";
import {TWithdrawFormValues} from "../../components/payouts/balance-section/i-balance";
import {
  IConnectBankFormValues,
  IVerifyAccountNumber,
} from "../../pages/dashboard/payouts/i-connect-bank";
import {API_BASE_URL} from "../../services/config";

// Get list pf payout countries
export const getPayoutCountriesService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "banks/countries", config);

  return response.data;
};

// Get list pf payout banks
export const getPayoutBanksService = async (token: string, countryCode: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    API_BASE_URL + "banks/countries/" + countryCode,
    config
  );

  return response.data;
};

// Validate bank account service
export const validateBankAccountService = async (
  token: string,
  payload: IVerifyAccountNumber
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "banks", payload, config);

  return response.data;
};

// connect bank account service
export const connectBankAccountService = async (
  token: string,
  payload: IVerifyAccountNumber
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_BASE_URL + "banks/accounts", payload, config);
  return response.data;
};

// fetch connected bank account service
export const fetchConnectedBankAccountService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "banks", config);

  return response.data;
};

// fetch bank service
export const fetchBankBranchesService = async (token: string, branchCode: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "banks/branches/" + branchCode, config);

  return response.data;
};

// update connected bank account service
export const updateBankAccountService = async (
  token: string,
  payload: IVerifyAccountNumber
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_BASE_URL + "banks/disconnect", payload, config);

  return response.data;
};
// disconnect connected bank account service
export const disconnectConnectedBankAccountService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_BASE_URL + "bank", config);

  return response.data;
};

// fetch account balance service
export const fetchAccountBalanceService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "account/balance", config);

  return response.data;
};

// fetch account balance service
export const fetchPayoutsService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "account/withdrawals", config);

  return response.data;
};
// fetch pending payout service
export const fetchPendingPayoutService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "account/pending/withdrawals", config);

  return response.data;
};

// fetch account balance service
export const withdrawRequestService = async (
  token: string,
  payload: TWithdrawFormValues
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "account/withdrawal", payload, config);

  return response.data;
};
