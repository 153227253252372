import axios from "axios";
import {IGoogleLoginValues} from "../../../components/google-button/i-google-button";
import {ILoginFormValues} from "../../../pages/auth/login/i-login";
import {API_BASE_URL} from "../../../services/config";

// const API_BASE_URL = "http://207.154.220.50:7890/api/v1/";

// login standard user
export const loginStandardUserService = async (userData: ILoginFormValues) => {
  const response = await axios.post(API_BASE_URL + "login", userData);

  return response.data;
};
// login social user
export const loginSocialUserService = async (userData: IGoogleLoginValues) => {
  const response = await axios.post(API_BASE_URL + "login/social", userData);

  return response.data;
};
// logout user
export const logoutUserService = async () => {
  return;
};
