import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Space} from "antd";
import {useForm} from "react-hook-form";
import {useUpdateEmail} from "../../../react-query-hooks/settings";
import {CustomInputComponent} from "../../input/input-component";
import {emailUpdateSchema} from "./email-update-form-schema";
import {StyledEmailUpdateFormContainer} from "./email-update-form.style";
import {IEmailUpdateFormValues} from "./i-email-update-form";

const EmailUpdateForm = ({email}: {email: string}) => {
  const updateEmailQueryMutation = useUpdateEmail();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<IEmailUpdateFormValues>({
    resolver: yupResolver(emailUpdateSchema),
    mode: "onChange",
    defaultValues: {
      emailAddress: email.toLowerCase(),
    },
  });

  const onSubmit = async (values: IEmailUpdateFormValues) => {
    updateEmailQueryMutation.mutate(values.emailAddress.toLowerCase());
  };
  return (
    <div>
      <StyledEmailUpdateFormContainer>
        <h2 className="email-heading-text">Email address </h2>
        <p className="email-heading-sub-text">
          Used to sign in, for email receipts and product updates.
        </p>
      </StyledEmailUpdateFormContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: "2.4rem"}}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <CustomInputComponent
            id="email"
            name="emailAddress"
            type="email"
            placeholder="Please enter your email address"
            error={errors.emailAddress?.message}
            control={control}
          />
          <div>
            <Button
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              loading={updateEmailQueryMutation.isLoading}
              size="large"
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Save changes
            </Button>
          </div>
        </Space>
      </form>
    </div>
  );
};

export {EmailUpdateForm};
