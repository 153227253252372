import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {BundleForm} from "../../../../components/calls/service/bundle-form";
// import {ShareServiceWidget} from "../../../../components/calls/service/share-service-widget/share-service-widget";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";

const AddBundlePage = () => {
  const pageTitle = "Create new bundle";
  return (
    <DashboardWrapper pageTitle={pageTitle}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/calls">1:1 Calls</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create new bundle</Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <BundleForm type="new" />
        </div>
        {/* <div className="widget-area">
          <ShareServiceWidget />
        </div> */}
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {AddBundlePage};
