import * as yup from "yup";
import {useForm, Controller} from "react-hook-form";
import {StyledContainer} from "../../features-section/features-section.style";
// import {AnimatedWords} from "../../hero-section";
import {motion} from "framer-motion";
import {riseWithFade} from "../../../../utils/animations/home/home-animations";
import {Button, Input, message} from "antd";
import {
  StyledLiveEventsHeroSection,
  StyledLiveEventsHeroSectionContent,
  StyledLiveEventsHeroSectionInput,
} from "./hero-section.style";
import {useSubscribeToLiveEvent} from "../../../../react-query-hooks/live-events/events";
import {yupResolver} from "@hookform/resolvers/yup";

export const subscribeToLiveEventSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
});

export function LiveEventsHeroSection() {
  const subscribeToLiveEventMutation = useSubscribeToLiveEvent();

  const {
    handleSubmit,
    formState: {errors},
    reset,
    control,
  } = useForm<{emailAddress: string}>({
    resolver: yupResolver(subscribeToLiveEventSchema),
    mode: "onChange",
  });
  const onSubmit = (values: {emailAddress: string}) => {
    const payload = {
      emailAddress: values.emailAddress,
    };
    subscribeToLiveEventMutation.mutate(payload, {
      onSuccess(data) {
        reset();
        message.info(data.message || "Success");
      },
    });
  };
  return (
    <StyledLiveEventsHeroSection>
      <StyledContainer>
        <StyledLiveEventsHeroSectionContent>
          <h1 className="live-events-hero-main-heading">
            Discover life-changing events with Coachli
            {/* <AnimatedWords title="Discover Life-changing Events with Coachli" /> */}
          </h1>
          <motion.p
            initial="initial"
            whileInView="animate"
            viewport={{once: false}}
            style={{overflow: "hidden"}}
            variants={riseWithFade}
            className="live-events-hero-sub-heading"
          >
            Be the first to get exciting updates about Coachli's engaging event.
          </motion.p>
          <div className="live-events-hero-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input.Group compact style={{display: "flex"}}>
                <Controller
                  control={control}
                  name="emailAddress"
                  render={({field: {onChange, value, ref}}) => (
                    <StyledLiveEventsHeroSectionInput
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      status={errors.emailAddress?.message ? "error" : ""}
                      size="large"
                      placeholder="Enter your email address"
                    />
                  )}
                />

                <Button
                  style={{borderRadius: "0 2.5rem 2.5rem 0"}}
                  size="large"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  loading={subscribeToLiveEventMutation.isLoading}
                >
                  Get updates
                </Button>
              </Input.Group>
              {errors.emailAddress?.message && (
                <div role="alert" className="ant-form-item-explain-error">
                  {errors.emailAddress?.message}
                </div>
              )}
            </form>
          </div>
        </StyledLiveEventsHeroSectionContent>
      </StyledContainer>
    </StyledLiveEventsHeroSection>
  );
}
