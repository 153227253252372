import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {IStep1FormValues} from "../../container/onboarding/step-1/i-step-1";
import {RootState} from "../store";
import {IProfileData} from "./i-profile-1";
import {
  changePassword,
  getUserProfileService,
  updateUserProfileService,
} from "./profile-service";

interface IInitialState {
  profileData: null | any;
  changePass: null | any;
  profileData2: {
    createdAt?: string;
    id?: number;
    firstName?: string;
    lastName?: string;
    username?: string;
    description?: string;
    emailAddress?: string;
    email?: string;
    country?: string;
    shortBio?: string;
    twitter?: null;
    facebook?: null;
    instagram?: null;
    linkedin?: null;
    tiktok?: null;
    discord?: null;
    youtube?: null;
    dribble?: null;
    behance?: null;
    twitch?: null;
    github?: null;
    website?: null;
    avatar?: null;
  };
  isError: boolean;
  isSuccess: boolean;
  isSuccessUpdate: boolean;
  isLoading: boolean;
  message: string;
}

type Ctx = {
  [key: string]: any; // 👈️ variable key
};

const initialState: IInitialState = {
  profileData: null,
  changePass: null,
  profileData2: {},
  isError: false,
  isSuccess: false,
  isSuccessUpdate: false,
  isLoading: false,
  message: "",
};

//Get user profile
export const getUserProfile = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("profile/getUserProfile", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getUserProfileService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Update user profile
export const updateUserProfile = createAsyncThunk<
  // Return type of the payload creator
  Ctx,
  // First argument to the payload creator
  IProfileData,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("profile/updateUserProfile", async (profileData, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateUserProfileService(profileData, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const changePasswordFunc = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  any,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/changePasswordFunc", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await changePassword(token, payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetUserProfileUpdatesState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessUpdate = false;
      state.message = "";
    },
    resetUserProfileState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessUpdate = false;
      state.message = "";
      state.profileData = null;
      state.profileData2 = {};
    },
    userProfileLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileData = action.payload.data;
        state.profileData2 = action.payload.data;
      })
      .addCase(getUserProfile.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profileData = null;
        state.profileData2 = {};
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessUpdate = true;
        state.profileData = action.payload.data;
        state.profileData2 = action.payload.data;
      })
      .addCase(updateUserProfile.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.profileData = null;
        state.profileData2 = {};
      })
      .addCase(changePasswordFunc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePasswordFunc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessUpdate = true;
        state.changePass = action.payload.data;
      })
      .addCase(changePasswordFunc.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.changePass = null;
      });
  },
});

export const {
  resetUserProfileUpdatesState,
  resetUserProfileState,
  userProfileLogoutReset,
} = profileSlice.actions;

export default profileSlice.reducer;
