import {useMutation, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {IPasswordUpdateFormValues} from "../components/settings/password-update-form/i-password-update-form";
import {TProfileApiResponse} from "../redux/profile/i-profile-1";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

declare const window: Window &
  typeof globalThis & {
    Intercom: any;
  };

const useUpdateEmail = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (emailAddress: string) =>
      client(`settings/email`, {
        method: "POST",
        data: {emailAddress},
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["profile"]);

      const oldProfile: TProfileApiResponse | undefined = queryClient.getQueryData([
        "profile",
      ]);
      queryClient.setQueryData(["profile"], {...oldProfile, email: values});

      return () => queryClient.setQueryData(["profile"], oldProfile);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Email update", "Email update fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data) => {
      message.success("Email address updated successfully!");
      AnalyticsEvent("Email update", "Email update success");
      window.Intercom("update", {
        email: `${data.data.user.email}`, // Email address
      });
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({queryKey: ["profile"]}),
  });
};
const useUpdatePassword = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: IPasswordUpdateFormValues) =>
      client(`settings/password`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Password update", "Password update fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Password update", "Password update success");
    },
  });
};

export {useUpdateEmail, useUpdatePassword};
