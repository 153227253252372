import {Button, Space, Tabs} from "antd";
import {PastCallsComponent} from "../../../components/calls/past-calls";
import {UpcomingCallsComponent} from "../../../components/calls/upcoming-calls";
import {
  StyledPageHeaderWrapper,
  StyledUpcomingCallsWrapper,
} from "../../../components/calls/upcoming-calls/upcoming-calls.style";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageHeader, StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {AnalyticsEvent} from "../../../utils/analytics";
import {TourWidget} from "../../../components/widgets/tour-widget";
import {NewAvailabilityWidget} from "../../../components/widgets/availability-widget";
import {ProfileTemplateWidget} from "../../../components/widgets/template-widget";
import {VideoCameraOutlined} from "@ant-design/icons";
// import {ServicesTab} from "../../../components/calls/upcoming-calls/services-tab";
import {CallCategoryModal} from "../../../components/calls/upcoming-calls/call-category-modal";
import {useState} from "react";
// import {useGetServices} from "../../../react-query-hooks/service";
import {useGetUpcomingCalls} from "../../../react-query-hooks/booking";

const CallsPage = () => {
  const pageTitle = "1:1 Calls";
  const [openCallCategoryModal, setOpenCallCategoryModal] = useState<boolean>(false);

  // const getServicesQuery = useGetServices();
  const getUpcomingCallsQuery = useGetUpcomingCalls();

  const handleTabClick = (key: string) => {
    if (key === "1") AnalyticsEvent("Coach calls", `Clicked on services tab`);
    if (key === "2") {
      getUpcomingCallsQuery.refetch();
      AnalyticsEvent("Coach calls", `Clicked on Upcoming calls tab`);
    }
    if (key === "3") AnalyticsEvent("Coach calls", `Clicked on Past calls tab`);
  };

  const handleOk = () => {
    AnalyticsEvent("Service Option Modal", `Selected a service option`);
    setOpenCallCategoryModal(false);
  };
  const handleCancel = () => {
    AnalyticsEvent("Service Option Modal", `Closed service option modal`);
    setOpenCallCategoryModal(false);
  };
  const showCallCategoryModal = () => {
    setOpenCallCategoryModal(true);
  };
  const handleNewServiceBtnClick = () => {
    AnalyticsEvent("Coach calls", `Clicked on Add new service button`);
    showCallCategoryModal();
  };
  return (
    <DashboardWrapper pageTitle={pageTitle}>
      <StyledPageWrapper>
        <div className="main-area">
          <StyledPageHeaderWrapper>
            <StyledPageHeader>1:1 Calls 🤙</StyledPageHeader>
            {/* {getServicesQuery.data && getServicesQuery.data.length > 0 && (
              <div style={{display: "flex", justifyContent: "end"}}>
                <Button
                  onClick={handleNewServiceBtnClick}
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="button"
                >
                  Add new 1:1 service
                </Button>
              </div>
            )} */}
          </StyledPageHeaderWrapper>
          <Tabs defaultActiveKey="1" onTabClick={(key) => handleTabClick(key)}>
            {/* <Tabs.TabPane tab="Services" key="1">
              <ServicesTab showCallCategoryModal={showCallCategoryModal} />
            </Tabs.TabPane> */}
            <Tabs.TabPane tab="Upcoming calls" key="1">
              <UpcomingCallsComponent
                handleNewServiceBtnClick={handleNewServiceBtnClick}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Past calls"} key="2">
              <StyledUpcomingCallsWrapper>
                <PastCallsComponent />
              </StyledUpcomingCallsWrapper>
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="widget-area">
          <Space direction="vertical" size={32} style={{display: "flex"}}>
            <NewAvailabilityWidget />
            <TourWidget
              buttons={[
                <Button
                  href="https://www.loom.com/share/62d5824c84d24ed7bb1c596c7c8a34d6?sid=bd23eb7c-e3b2-4d3b-8a56-e3ab5b583f2c"
                  block
                  icon={<VideoCameraOutlined />}
                  size="large"
                  shape="round"
                  target="_blank"
                >
                  <span>Watch demo video</span>
                </Button>,
              ]}
              textInfo="Watch a video guide on how to start hosting 1:1 sessions with your audience and clients."
            />
            <ProfileTemplateWidget />
          </Space>
        </div>
        <CallCategoryModal
          okFunc={handleOk}
          cancelFunc={handleCancel}
          visibleState={openCallCategoryModal}
        />
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {CallsPage};
