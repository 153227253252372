import {QueryCache, useQueryClient} from "@tanstack/react-query";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {userAccountActivationLogoutReset} from "../../redux/auth/account-activation/account-activation-slice";
import {userResetPasswordLogoutReset} from "../../redux/auth/forgot-password/forgot-password-slice";
import {
  logoutUser,
  resetUserLoginState,
  userLoginLogoutReset,
} from "../../redux/auth/login/user-login-slice";
import {userRegisterlogoutReset} from "../../redux/auth/register/user-register-slice";
import {callLogoutReset} from "../../redux/call/call-slice";
import {categoryLogoutReset} from "../../redux/category/category-slice";
import {countryLogoutReset} from "../../redux/country/countrySlice";
import {userProfileLogoutReset} from "../../redux/profile/profile-slice";
import {AppDispatch, RootState} from "../../redux/store";
import {userTimezoneLogoutReset} from "../../redux/timezone/timezone-slice";
import {BrandImage} from "../brand-image";
import {StyledHeader, StyledHeaderWrapper} from "./navbar.style";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.clear();
    dispatch(logoutUser());
    dispatch(userRegisterlogoutReset());
    dispatch(userResetPasswordLogoutReset());
    dispatch(userAccountActivationLogoutReset());
    dispatch(categoryLogoutReset());
    dispatch(countryLogoutReset());
    dispatch(userProfileLogoutReset());
    dispatch(userTimezoneLogoutReset());
    dispatch(callLogoutReset());
    dispatch(userLoginLogoutReset());
    navigate("/auth/login");
  };
  return (
    <StyledHeaderWrapper>
      <StyledHeader>
        <BrandImage />
        {userInfo && (
          <Button style={{alignSelf: "center"}} onClick={handleLogout}>
            Log out
          </Button>
        )}
      </StyledHeader>
    </StyledHeaderWrapper>
  );
};

export {Navbar};
