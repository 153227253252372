import {Dispatch, PropsWithChildren, useReducer} from "react";
import {
  initialMessageState,
  messageReducer,
  TMessageActions,
} from "../reducers/message-reducer";
import {createContext} from "./create-context";

type TMessageContextState = {
  state: typeof initialMessageState;
  dispatch: Dispatch<TMessageActions>;
};

const [useContext, ContextProvider] = createContext<TMessageContextState>();

export const useMessageContext = useContext;

export const MessageProvider = ({children}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(messageReducer, initialMessageState);

  return <ContextProvider value={{state, dispatch}}>{children}</ContextProvider>;
};
