import {Space} from "antd";
import {StyledLiveClassesMainSectionWrapper} from "./main-details-section.style";
import {SessionsSection} from "./sessions-section/sessions-section";
import {TPublicProfileLiveClassApiResponse} from "../../../../react-query-hooks/live-events/live-classes";
import {handleLiveClassLink} from "../../../../utils/live-class";
import {ShareOfferingWidget} from "../../offering/share-offering";
import {
  handleFacebookLink,
  handleMailLink,
  handleTwitterLink,
} from "../../../../utils/products";
import {OfferingCreatorDetails} from "../../offering/creator-details";
import {ClassInfoWidget} from "./info-widget";
import {OfferingCohostDetails} from "../../offering/creator-details/co-host-details";

export type TMainDetailsSectionProps = {
  eventData: TPublicProfileLiveClassApiResponse;
  params: {
    username: string | undefined;
    slug: string | null;
    eventId: string | null;
    customerTimezone: string | null;
  };
};

const MainDetailsSection = ({eventData}: TMainDetailsSectionProps) => {
  const extraParams = {
    facebookLink: handleFacebookLink({
      username: eventData.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just booked a live class by ${eventData.firstName} ${eventData.lastName} titled "${eventData.event.name}" on Coachli!`,
      username: eventData.username,
    }),
    mailLink: handleMailLink({
      body: `I just booked a live class by ${eventData.firstName} ${eventData.lastName} titled "${eventData.event.name}" on Coachli! ${window.location.origin}/${eventData.username}`,
      subject: "",
    }),
    copyLink: handleLiveClassLink({
      eventId: eventData.event.id,
      slug: eventData.event.slug,
      username: eventData.username,
      windowNav: true,
    }),
  };

  return (
    <StyledLiveClassesMainSectionWrapper>
      <div className="live-classes-main-details-section live-classes-main-details-about-content">
        <Space
          direction="vertical"
          size={25}
          style={{
            display: "flex",
          }}
        >
          <div className="view-widget-mobile">
            <ClassInfoWidget />
          </div>
          <div>
            <h1 className="content-heading">About this class</h1>
            <div
              className="content-info-text"
              dangerouslySetInnerHTML={{__html: eventData.event.description}}
            />
          </div>
          {eventData.event.topic_type === "series" && (
            <SessionsSection
              activeSession={eventData.activeSession}
              sessions={eventData.event.session_info}
            />
          )}
        </Space>
      </div>

      <div className="live-classes-main-details-section live-classes-main-details-call-content">
        <Space
          direction="vertical"
          size={25}
          style={{
            display: "flex",
          }}
        >
          <div className="view-widget-web">
            <ClassInfoWidget />
          </div>
          <OfferingCreatorDetails
            title="Hosted by"
            creatorInfo={{
              avatar: eventData.avatar,
              description: eventData.description,
              firstName: eventData.firstName,
              lastName: eventData.lastName,
            }}
          />
          <OfferingCohostDetails
            title="Co-hosted by"
            coHosts={eventData.event.co_hosts}
          />
          <ShareOfferingWidget extraParams={extraParams} title="Share class via" />
        </Space>
      </div>
    </StyledLiveClassesMainSectionWrapper>
  );
};

export {MainDetailsSection};
