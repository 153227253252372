import {
  AudioOutlined,
  BankOutlined,
  FireOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {ProductNavIcon, RecordIcon} from "../components/icons/icons";

interface IDashboardMenuItem {
  // title: JSX.Element;
  title?: string;
  navLink: {
    to: string;
    label: string;
    icon: React.ReactNode;
    isComingSoon?: boolean;
    isBtnLink?: boolean;
  }[];
}

interface IDashboardMenuData extends Array<IDashboardMenuItem> {}

// interface IDashboardMenuData {
//   [index: number]: {
//     navLink: {
//       title?: string;
//       to: string;
//       label: string;
//       icon: React.ReactNode;
//       isComingSoon?: boolean;
//     }[];
//   };
// }

export const DashboardMenuData: IDashboardMenuData = [
  {
    navLink: [
      {
        to: "/dashboard/home",
        label: "Home",
        icon: <FireOutlined />,
      },
      {
        to: "/dashboard/calls",
        label: "1:1 Calls",
        icon: <PhoneOutlined />,
      },
      {
        // to: "#",
        to: "/dashboard/products",
        label: "Products",
        icon: <ProductNavIcon />,
        // isComingSoon: true,
      },
      {
        to: "/dashboard/live-classes",
        label: "Live classes",
        icon: <AudioOutlined />,
      },
      {
        to: "/dashboard/recordings",
        label: "Recordings",
        icon: <RecordIcon />,
      },
      {
        to: "/dashboard/messages",
        label: "Messages",
        icon: <MessageOutlined />,
      },
      {
        to: "/dashboard/customers",
        label: "Customers",
        icon: <UsergroupAddOutlined />,
      },
    ],
  },
  {
    title: "YOUR ACCOUNT",
    navLink: [
      {
        to: "/dashboard/profile",
        label: "Profile",
        icon: <UserOutlined />,
      },
      {
        to: "/dashboard/payouts",
        label: "Payouts",
        icon: <BankOutlined />,
      },
      {
        to: "/dashboard/settings",
        label: "Settings",
        icon: <SettingOutlined />,
      },
    ],
  },
  {
    title: "HELP AND SUPPORT",
    navLink: [
      {
        to: "https://coachli.notion.site/Helpdesk-4039d986b45e4236a5ee76a5e4de45a5",
        label: "FAQs",
        icon: <QuestionCircleOutlined />,
        isBtnLink: true,
      },
      {
        to: "mailto:hello@coachli.co",
        label: "Contact us",
        icon: <MailOutlined />,
        isBtnLink: true,
      },
    ],
  },
];

export const CustomerDashboardMenuData: IDashboardMenuData = [
  {
    navLink: [
      {
        to: "/dashboard/customer/home",
        label: "Home",
        icon: <FireOutlined />,
      },
      {
        to: "/dashboard/customer/calls",
        label: "1:1 Calls",
        icon: <PhoneOutlined />,
      },
      {
        to: "/dashboard/customer/live-classes",
        label: "Live classes",
        icon: <AudioOutlined />,
      },
      {
        // to: "#",
        to: "/dashboard/customer/products",
        label: "Products",
        icon: <ShoppingOutlined />,
        // isComingSoon: true,
      },
      {
        to: "/dashboard/customer/messages",
        label: "Messages",
        icon: <MessageOutlined />,
      },
    ],
  },
  // {
  //   title: "YOUR ACCOUNT",
  //   navLink: [
  //     {
  //       to: "/dashboard/customer/settings",
  //       label: "Settings",
  //       icon: <SettingOutlined />,
  //     },
  //   ],
  // },
  {
    title: "HELP AND SUPPORT",
    navLink: [
      {
        to: "https://coachli.notion.site/Helpdesk-4039d986b45e4236a5ee76a5e4de45a5",
        label: "FAQs",
        icon: <QuestionCircleOutlined />,
        isBtnLink: true,
      },
      {
        to: "mailto:hello@coachli.co",
        label: "Contact us",
        icon: <MailOutlined />,
        isBtnLink: true,
      },
    ],
  },
];
