import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Space, Radio, Tooltip, Row, Col, Select, Button} from "antd";
import {Controller, useFieldArray} from "react-hook-form";
import {
  handleCurrencySelectOnChange,
  handleAddNewCurrency,
  updateCurrencyOptionsState,
  handleRemoveCurrency,
} from "../../../../utils/currency";
import {CustomInputComponent} from "../../../input/input-component";
import {StyledInputLabelWrapper} from "../../../input/input.style";
import {TProductInputProps} from "../form-values";
import {useEffect, useState} from "react";
import {useCurrencyContext} from "../../../../context/currency-context";
import {useDeleteCurrency} from "../../../../react-query-hooks/currency";

const {Option} = Select;

type TProductDescriptionInputProps = TProductInputProps & {};

export function ProductPriceInput({
  control,
  watch,
  formState,
}: TProductDescriptionInputProps) {
  const {state} = useCurrencyContext();
  const [currencyOptions, setCurrencyOptions] = useState(() => state.formCurrencies);

  const deleteCurrencyMutation = useDeleteCurrency();

  const {
    fields: currencyFields,
    append: appendCurrency,
    remove: removeCurrency,
  } = useFieldArray({
    control,
    name: "currencies",
  });
  const currencyArrayValue = watch("currencies");
  const defaultCallCurrency =
    watch("currencies")[0].callPriceCurrency || state.formCurrencies[0];

  // TO ALWAYS KEEP THE SOCIAL OPTIONS UP TO DATE WITH NEW VALUES
  useEffect(() => {
    updateCurrencyOptionsState(
      state.formCurrencies,
      currencyArrayValue,
      defaultCallCurrency,
      setCurrencyOptions
    );
  }, [state.formCurrencies, currencyArrayValue, defaultCallCurrency]);

  function handleDeleteCurrency(currency: string, currencyIndex: number) {
    handleRemoveCurrency(
      state.formCurrencies,
      currencyArrayValue,
      defaultCallCurrency,
      setCurrencyOptions,
      removeCurrency,
      currencyIndex
    );
  }

  return (
    <div>
      <Space direction="vertical" size={24} style={{display: "flex"}}>
        <div>
          {/* <StyledInputLabelWrapper>
            <label id="slug-id" htmlFor="slug">
              Product price
            </label>
          </StyledInputLabelWrapper> */}
          <Controller
            control={control}
            name="callPriceType"
            render={({field: {onChange, value}}) => (
              <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                <Tooltip
                  title={
                    !state.connectedBankStatus
                      ? "Please add your bank account details in Payouts to enable paid offerings"
                      : null
                  }
                >
                  <Radio value={"paid"} disabled={!state.connectedBankStatus}>
                    Paid
                  </Radio>
                </Tooltip>

                <Radio value={"free"}>Free</Radio>
              </Radio.Group>
            )}
          />
        </div>
        {watch("callPriceType") === "paid" && state.connectedBankStatus && (
          <Row gutter={[10, 24]}>
            {currencyFields.slice(0, 4).map((item, index) => (
              <Col key={item.id} span={24} sm={12}>
                <div style={{display: "flex", gap: "1.6rem"}}>
                  <div style={{flex: "1"}}>
                    <CustomInputComponent
                      id="productPrice"
                      name={`currencies.${index}.callPrice`}
                      type="text"
                      label="Product price"
                      placeholder="Enter product price"
                      error={
                        formState.errors.currencies?.[index]?.callPriceCurrency
                          ? formState.errors.currencies?.[index]?.callPriceCurrency
                              ?.message
                          : formState.errors.currencies?.[index]?.callPrice?.message
                      }
                      control={control}
                      addonBefore={
                        <Controller
                          control={control}
                          name={`currencies.${index}.callPriceCurrency`}
                          render={({field: {onChange, value, ref}}) => (
                            <>
                              <Select
                                onChange={(val) =>
                                  handleCurrencySelectOnChange(
                                    state.formCurrencies,
                                    currencyArrayValue,
                                    defaultCallCurrency,
                                    setCurrencyOptions,
                                    onChange,
                                    val
                                  )
                                }
                                value={value}
                                ref={ref}
                                size="large"
                                status={
                                  formState.errors.currencies?.[index]?.callPriceCurrency
                                    ?.message
                                    ? "error"
                                    : ""
                                }
                                showSearch={false}
                                notFoundContent={null}
                                placeholder={""}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {currencyOptions.map(
                                  (option, optionIdx: number | string) => (
                                    <Option key={optionIdx} value={option}>
                                      {option}
                                    </Option>
                                  )
                                )}
                              </Select>
                              {formState.errors.currencies?.[index]?.callPriceCurrency
                                ?.message && (
                                <div role="alert" className="ant-form-item-explain-error">
                                  {
                                    formState.errors.currencies?.[index]
                                      ?.callPriceCurrency?.message
                                  }
                                </div>
                              )}
                            </>
                          )}
                        />
                      }
                    />
                  </div>
                  {index !== 0 && (
                    <div style={{marginTop: "auto"}}>
                      <Button
                        onClick={() =>
                          handleDeleteCurrency(item.callPriceCurrency, index)
                        }
                        type="link"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size="large"
                        //   loading={deleteCurrencyMutation.isLoading}
                      />
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        )}
        {watch("callPriceType") === "paid" && currencyFields.length < 4 && (
          <div>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() =>
                handleAddNewCurrency(
                  state.formCurrencies,
                  currencyArrayValue,
                  defaultCallCurrency,
                  setCurrencyOptions,
                  appendCurrency
                )
              }
              size="large"
              shape="round"
            >
              Add currency
            </Button>
          </div>
        )}
        {formState.errors.currencies?.message && (
          <div role="alert" className="ant-form-item-explain-error">
            {formState.errors.currencies.message}
          </div>
        )}
      </Space>
    </div>
  );
}
