import {useState} from "react";
import {Button, Space} from "antd";
import {
  StyledPublicProfileOfferingDescriptionText,
  StyledPublicProfileOfferingDescriptionWrapper,
} from "../../../product-details/description/description.style";
import {useServiceBookingContext} from "../../../../../../context/service-booking/service-booking.context";

export function ServiceDescription() {
  const [showMore, setShowMore] = useState(false);
  const {state} = useServiceBookingContext();

  if (!state.serviceData) {
    return <div></div>;
  }
  return (
    <StyledPublicProfileOfferingDescriptionWrapper>
      <Space
        direction="vertical"
        size={24}
        style={{
          display: "flex",
        }}
      >
        <div>
          {/* <h2 className="content-title">About this product</h2> */}
          <div>
            <StyledPublicProfileOfferingDescriptionText
              showMore={showMore}
              dangerouslySetInnerHTML={{
                __html: state.serviceData.service.description,
              }}
            />
            {state.serviceData.service.description.length > 400 && (
              <Button
                type="link"
                size="small"
                style={{padding: 0}}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            )}
          </div>
        </div>
      </Space>
    </StyledPublicProfileOfferingDescriptionWrapper>
  );
}
