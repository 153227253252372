import {Backdrop} from "../backdrop";
import {StyledLogoContainer, StyledSideNav} from "./dashboard-sidebar.style";
import {NavLinksGroup} from "./nav-links-group";

interface ISideNavProps {
  toggleNav: () => void;
  visible: boolean;
}

const CustomerSideNav: React.FC<ISideNavProps> = ({toggleNav, visible}) => {
  return (
    <>
      <Backdrop visible={visible} onClick={toggleNav} />
      <StyledSideNav visible={visible}>
        <StyledLogoContainer>
          <img src="/images/coachli-logo-dark.png" alt="Brand Logo" />
        </StyledLogoContainer>
        <NavLinksGroup />
      </StyledSideNav>
    </>
  );
};

export {CustomerSideNav};
