import axios from "axios";
import {IGoogleRegisterValues} from "../../../components/google-button/i-google-button";
import {IRegisterFormValues} from "../../../pages/auth/register/i-register";
import {API_BASE_URL} from "../../../services/config";

// const API_BASE_URL = "http://207.154.220.50:7890/api/v1/";

// Register standard user
export const registerStandardUserService = async (userData: IRegisterFormValues) => {
  const response = await axios.post(API_BASE_URL + "register", {
    ...userData,
    isSocialAuth: false,
  });

  return response.data;
};

// Register social user
export const registerSocialUserService = async (userData: IGoogleRegisterValues) => {
  const response = await axios.post(API_BASE_URL + "register/social", userData);

  return response.data;
};
