import styled from "styled-components";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

export const StyledGetStartedSectionWrapper = styled.section`
  background: linear-gradient(180deg, #714eff 0%, #aa86ff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
  padding: 2rem;
`;

export const StyledGetStartedSectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;

  .main-title {
    font-size: 5.2rem;
    font-weight: 700;
    color: #fff;
    // max-width: 47.5rem;
    line-height: 6rem;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 2.6rem;
      line-height: 3.1rem;
    }
  }

  .sub-title {
    font-weight: 400;
    font-size: 1.8rem;
    text-align: center;
    color: #ffffff;
    max-width: 52rem;
    width: 100%;
  }
`;

export const StyledGetStartedButton = styled(motion.a)`
  background-color: #ffffff;
  border-radius: 3.7rem;
  padding: 1.9rem 3.8rem;
  font-weight: 700;
  display: inline-block;

  &:hover {
    color: #ffffff;
    background-color: #101a29;
  }
`;

export const MotionLink = motion(Link);
