import {Button, Modal} from "antd";
import {FlutterwaveConfig} from "flutterwave-react-v3/dist/types";
import {PaystackProps} from "react-paystack/dist/types";
import {useProductPurchaseContext} from "../../../../../context/products/product-purchase-context";
import {usePurchaseProduct} from "../../../../../react-query-hooks/products";
import {handleProductSuccessBookingLink} from "../../../../../utils/products";
import {NewPayWithPaystack} from "../../../../../container/live-classes/booking/new/pay-with-paystack/pay-with-paystack";
import {NewPayWithFlutterwaveStep} from "../../../../../container/live-classes/booking/new/pay-with-flutterwave";
import {StyledModalContentWrapper} from "../../../../calls/upcoming-calls/call-card/call-card.style";
import {ProductPurchaseForm} from "./purchase-form/index.";
import {StyledPublicProfileOfferingDrawer} from "../../../service/booking-modal";
import {CloseOutlined} from "@ant-design/icons";

type Currency = "NGN" | "GHS" | "USD" | "ZAR";

export function ProductPurchaseModal() {
  const {state, dispatch} = useProductPurchaseContext();
  const purchaseProductMutation = usePurchaseProduct();
  if (!state.productData) {
    return null;
  }

  function updateStep(step: number) {
    if (step > 3) {
      return;
    }
    dispatch({
      type: "update-purchase-step",
      payload: step,
    });
  }

  function handleBookingModalOk() {
    dispatch({type: "show-purchase-modal", payload: false});
  }

  function handleBookingModalCancel() {
    // if (state.currentPurchaseStep === 3) {
    //   navigate(`/${state.productData?.profile.username}`);
    //   dispatch({type: "reset-state"});
    // }
    Modal.destroyAll();
    dispatch({type: "show-purchase-modal", payload: false});
    dispatch({
      type: "update-purchase-step",
      payload: 1,
    });
  }

  const fwConfigParams: FlutterwaveConfig = {
    amount: Number(state.productPurchaseApiResponseData?.amount) || 0,
    customer: {
      email: `${state.productPurchaseFormValues?.emailAddress}`,
      name: `${state.productPurchaseFormValues?.firstName} ${state.productPurchaseFormValues?.lastName}`,
      phone_number: "",
    },
    customizations: {
      title: `Payment for ${state.productData?.product.title}`,
      description: "",
      logo: "https://pbs.twimg.com/profile_images/1594744754356604929/WHpvwR8w_400x400.jpg",
    },
    payment_options: "card,mobilemoney,ussd",
    public_key: process.env.REACT_APP_FLWPUBK_TEST as string,
    tx_ref: state.productPurchaseApiResponseData?.reference as string,
    currency: state.productPurchaseApiResponseData?.currency as string,
    meta: {
      firstName: state.productPurchaseFormValues?.firstName,
      lastName: state.productPurchaseFormValues?.lastName,
      emailAddress: state.productPurchaseFormValues?.emailAddress,
    },
  };

  const psConfigParams: PaystackProps = {
    amount: (Number(state.productPurchaseApiResponseData?.amount) || 0) * 100,
    email: state.productPurchaseApiResponseData?.emailAddress as string,
    publicKey: process.env.REACT_APP_PSPUBK_TEST as string,
    currency: state.productPurchaseApiResponseData?.currency as Currency,
    firstname: state.productPurchaseApiResponseData?.firstName as string,
    lastname: state.productPurchaseApiResponseData?.lastName as string,
    metadata: {
      firstName: state.productPurchaseApiResponseData?.firstName,
      lastName: state.productPurchaseApiResponseData?.lastName,
      emailAddress: state.productPurchaseApiResponseData?.emailAddress,
      custom_fields: [
        {
          display_name: "firstName",
          variable_name: "firstName",
          value: state.productPurchaseApiResponseData?.firstName as string,
        },
        {
          display_name: "lastName",
          variable_name: "lastName",
          value: state.productPurchaseApiResponseData?.lastName as string,
        },
        {
          display_name: "emailAddress",
          variable_name: "emailAddress",
          value: state.productPurchaseApiResponseData?.emailAddress as string,
        },
      ],
    },
    reference: state.productPurchaseApiResponseData?.reference as string,
  };

  const successRedirectLink = handleProductSuccessBookingLink({
    productId: String(state.productData.product.id),
    slug: state.productData.product.slug,
    username: state.productData.profile.username,
  });

  function handleView() {
    let content;
    switch (state.currentPurchaseStep) {
      case 3:
        content = (
          <NewPayWithPaystack
            updateStep={updateStep}
            psConfigParams={psConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      case 2:
        content = (
          <NewPayWithFlutterwaveStep
            updateStep={updateStep}
            fwConfigParams={fwConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      default:
      case 1:
        if (!state.productData) {
          return (content = null);
        }
        content = (
          <ProductPurchaseForm
            updateStep={updateStep}
            productPurchaseMutation={purchaseProductMutation}
            productCurrencies={state.productData.currencies}
          />
        );
    }
    return content;
  }
  return (
    <StyledPublicProfileOfferingDrawer
      title={
        state.currentPurchaseStep === 1
          ? "Checkout"
          : state.currentPurchaseStep === 2 || state.currentPurchaseStep === 3
          ? "Payment"
          : null
      }
      footer={null}
      visible={state.isPurchaseModalOpen}
      // onOk={handleBookingModalOk}
      onClose={handleBookingModalCancel}
      maskClosable={false}
      closable={false}
      extra={
        <Button
          type="ghost"
          size="small"
          icon={<CloseOutlined />}
          onClick={handleBookingModalCancel}
          disabled={state.currentPurchaseStep !== 1}
        ></Button>
      }
    >
      {handleView()}
    </StyledPublicProfileOfferingDrawer>
  );
}
