import {Space, Button, message} from "antd";
import {useForm} from "react-hook-form";
import {StyledBookingRightColumnHeader} from "../../../styles/booking/booking";
import {CustomInputComponent} from "../../input/input-component";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {TCustomerCancelBookingPayload} from "../../../redux/booking/i-time-slots-payload";
import {customerCancelBooking} from "../../../redux/booking/booking-slice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux/store";
import {useEffect} from "react";

const CustomerCancelBookingFormSchema = yup.object().shape({
  description: yup.string().trim().required("Description is required"),
});

export type TCustomerCancelBookingFormValues = {
  description: string;
};

const CustomerCancelBookingForm = ({bookingReference}: {bookingReference: string}) => {
  const dispatch = useDispatch<AppDispatch>();

  const {customerCancelBookingStatus, message: customerCancelBookingMessage} =
    useSelector((state: RootState) => state.booking);

  useEffect(() => {
    if (customerCancelBookingStatus === "rejected") {
      message.error(customerCancelBookingMessage);
    }
    if (customerCancelBookingStatus === "resolved") {
      message.success("The selected booking has been cancelled successfully!");
    }
  }, [customerCancelBookingMessage, customerCancelBookingStatus]);
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<TCustomerCancelBookingFormValues>({
    resolver: yupResolver(CustomerCancelBookingFormSchema),
  });

  const onSubmit = async (values: TCustomerCancelBookingFormValues) => {
    const payload: TCustomerCancelBookingPayload = {
      description: values.description,
      reference: bookingReference,
    };

    dispatch(customerCancelBooking(payload));
    // console.log({values, payload});
    // setValues({...data, ...payload});
    // await cancelBookingService(payload, currentCustomerBooking.reference);
  };
  return (
    <form style={{marginTop: "2.4rem"}} onSubmit={handleSubmit(onSubmit)}>
      <Space direction="vertical" size={24} style={{display: "flex"}}>
        <StyledBookingRightColumnHeader>Cancel call</StyledBookingRightColumnHeader>
        <div style={{display: "flex", flexDirection: "column"}}>
          <CustomInputComponent
            id="description"
            type="textarea"
            label="Reason for canceling"
            placeholder="Let them know why you are cancelling the call"
            error={errors.description?.message}
            control={control}
            name="description"
          />
        </div>
        <div>
          <Button
            loading={customerCancelBookingStatus === "pending"}
            shape="round"
            type="primary"
            htmlType="submit"
            size="large"
            disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
          >
            Cancel Call
          </Button>
        </div>
      </Space>
    </form>
  );
};

export {CustomerCancelBookingForm};
