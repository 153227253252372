import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {
  PaginationMeta,
  TBundleApiPayload,
  TServiceApiPayload,
  TServiceApiResponse,
  TUpdateServiceApiPayload,
} from "../redux/service/i-service";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

export type TBundleApiPayloadReq = Omit<TBundleApiPayload, "isPaused" | "id"> & {
  isBundle: true;
};
export type TUpdateBundleApiPayloadReq = TBundleApiPayload & {
  isBundle: true;
};

const useGetServices = (page: number = 1, per_page: number = 5) => {
  const client = useClient();

  return useQuery<
    {data: TServiceApiResponse[]; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["services", page],
    queryFn: () =>
      client(`services/all?page=${page}&per_page=${per_page}`).then((data) => data),
    // staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetService = (serviceId: string) => {
  const client = useClient();

  return useQuery<TServiceApiResponse, AxiosError<{status: boolean; message: string}>>({
    queryKey: ["service", serviceId],
    queryFn: () => client(`service/id/${serviceId}`).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

const useAddService = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (serviceData: TServiceApiPayload) =>
      client(`services/add/new-service`, {
        method: "POST",
        data: serviceData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Service create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Service", "Service create success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["services"]}),
  });
};

const useUpdateService = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (serviceData: TUpdateServiceApiPayload) =>
      client(`services/${serviceData.id}`, {
        method: "PUT",
        data: serviceData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["service", String(values.id)]);

      const oldService = queryClient.getQueryData(["service", String(values.id)]);
      queryClient.setQueryData(["service", String(values.id)], values);

      return () => queryClient.setQueryData(["service", String(values.id)], oldService);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Service update fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      // console.log({data, values});
      AnalyticsEvent("Service", "Service update success");
      queryClient.setQueryData(["service", String(values.id)], {
        ...data.data,
        // cover_picture: values.cover_image,
        currencies: values.currencies,
      });
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["service", String(values.id)]}),
        queryClient.invalidateQueries({queryKey: ["services"]}),
      ]),
  });
};

const useDeleteService = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (serviceId: string) =>
      client(`service/${serviceId}`, {
        method: "DELETE",
      }),
    onMutate: (serviceId) => {
      const oldServices = queryClient.getQueryData(["services"]);
      queryClient.setQueryData(
        ["services"],
        (oldValues: TServiceApiResponse[] | undefined) => {
          return oldValues?.filter((item) => String(item.id) !== String(serviceId));
        }
      );

      return () => queryClient.setQueryData(["services"], oldServices);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Service delete fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Service", "Service delete success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["services"]}),
  });
};

const useAddBundle = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (bundleData: TBundleApiPayloadReq) =>
      client(`bundle`, {
        method: "POST",
        data: bundleData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Bundle service create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Service", "Bundle service create success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["services"]}),
  });
};

const useUpdateBundle = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (bundleData: TUpdateBundleApiPayloadReq) =>
      client(`bundle/${bundleData.id}`, {
        method: "PUT",
        data: bundleData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["service", String(values.id)]);

      const oldBundle = queryClient.getQueryData(["service", String(values.id)]);
      queryClient.setQueryData(["service", String(values.id)], values);

      return () => queryClient.setQueryData(["service", String(values.id)], oldBundle);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Bundle service update fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      AnalyticsEvent("Service", "Bundle service update success");
      queryClient.setQueryData(["service", String(values.id)], {
        ...data.data,
        currencies: values.currencies,
      });
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["service", String(values.id)]}),
        queryClient.invalidateQueries({queryKey: ["services"]}),
      ]),
  });
};

const useDeleteBundle = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (serviceId: string) =>
      client(`bundle/${serviceId}`, {
        method: "DELETE",
      }),
    onMutate: (serviceId) => {
      const oldServices = queryClient.getQueryData(["services"]);
      queryClient.setQueryData(
        ["services"],
        (oldValues: TServiceApiResponse[] | undefined) => {
          return oldValues?.filter((item) => String(item.id) !== String(serviceId));
        }
      );

      return () => queryClient.setQueryData(["services"], oldServices);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Service", "Bundle service delete fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Service", "Bundle service delete success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["services"]}),
  });
};

export {
  useGetServices,
  useGetService,
  useAddService,
  useUpdateService,
  useDeleteService,
  useAddBundle,
  useUpdateBundle,
  useDeleteBundle,
};
