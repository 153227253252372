type TViewProductLinkPayload = {
  username: string;
  slug: string;
  productId: string | number;
  windowNav?: boolean;
};

export function handleProductLink({
  productId,
  slug,
  username,
  windowNav,
}: TViewProductLinkPayload) {
  const baseLink = `/${username}/products/details?slug=${slug}&productId=${productId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}
export function handleTwitterLink({
  textContent,
  username,
}: {
  textContent: string;
  username: string;
}) {
  return `https://twitter.com/intent/tweet?text=${textContent}&url=${window.location.origin}/${username}&hashtags=coachli,useCoachli`;
}
export function handleFacebookLink({username}: {username: string}) {
  return `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${username}&amp`;
}
export function handleMailLink({subject, body}: {subject: string; body: string}) {
  return `mailto:?subject=${subject}&amp;body=${body}`;
}
export function handleProductSuccessBookingLink({
  productId,
  slug,
  username,
  windowNav,
}: TViewProductLinkPayload) {
  const baseLink = `/${username}/products/booking/success?slug=${slug}&productId=${productId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}
