import {Alert} from "antd";
import {useGetCoHostedEvents} from "../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {StyledNoUpcomingCallBox} from "../../calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../full-page-loader";
import {LiveClassCard} from "../upcoming-classes/card";
import {CustomLoadMoreButton} from "../../load-more";

export function CohostedClasses() {
  const getCoHostedEvent = useGetCoHostedEvents("upcoming");
  // console.log({getCoHostedEvent: getCoHostedEvent.data});
  if (getCoHostedEvent.isLoading) {
    return <FullPageLoader />;
  }

  if (getCoHostedEvent.isError) {
    return (
      <Alert
        message="Error"
        description={getCoHostedEvent.error.message}
        type="error"
        showIcon
      />
    );
  }

  const flatMapCohostPages = getCoHostedEvent.data.pages.flatMap((page) => page.data);
  function fetchNextPage() {
    getCoHostedEvent.fetchNextPage();
  }
  return (
    <div>
      {flatMapCohostPages.length > 0 ? (
        <>
          {getCoHostedEvent.data.pages.map((group) =>
            group.data.map((liveClass) => (
              <LiveClassCard
                key={liveClass.id}
                name={liveClass.name}
                activeSession={liveClass.activeSession}
                attendees={liveClass.attendees}
                callPrice={liveClass.callPrice}
                callPriceCurrency={liveClass.callPriceCurrency}
                cover_image={liveClass.cover_image}
                description={liveClass.description}
                eventId={liveClass.id}
                slug={liveClass.slug}
                statusName={liveClass.statusName}
                hostUsername={liveClass.hostProfile.username}
                is_co_host
              />
            ))
          )}
          <CustomLoadMoreButton
            fetchNextPage={fetchNextPage}
            hasNextPage={!!getCoHostedEvent.hasNextPage}
            isFetchingNextPage={getCoHostedEvent.isFetchingNextPage}
          />
        </>
      ) : (
        <StyledNoUpcomingCallBox>
          <p className="info-text">You are not a co-host for any event</p>
        </StyledNoUpcomingCallBox>
      )}
    </div>
  );
}
