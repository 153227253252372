import {DownOutlined, GlobalOutlined} from "@ant-design/icons";
import {Select, Space} from "antd";
import {StyledCustomSelect} from "../../../../../booking/left-column/left-column.style";
import {TIMEZONES} from "../../../../../../services/timezones";
import {getTimeByTimeZone} from "../../../../../../services/helpers";
import moment from "moment";
import {useServiceBookingContext} from "../../../../../../context/service-booking/service-booking.context";
import {TTimeslotsPayload} from "../../../../../../react-query-hooks/public-profile";
const {Option} = Select;

export function ServiceTimezoneInfo() {
  const customerTimezone = moment.tz.guess(true);

  const {state, dispatch} = useServiceBookingContext();
  if (!state.serviceData) {
    return <div></div>;
  }
  function fetchTimeSlots(payload: TTimeslotsPayload) {
    state.timeSlotsMutation?.mutate(payload);
  }
  function handleTimezoneChange(value: unknown) {
    dispatch({
      type: "update-selected-timezone",
      payload: String(value),
    });
    if (state.selectedDateString) {
      const payload: TTimeslotsPayload = {
        mentorId: state.serviceData?.profile.userId,
        serviceId: state.serviceData?.service.id,
        date: state.booking_date,
        year: state.booking_year,
        month: state.booking_month,
        day:
          state.selectedDateString.getDay() === 0
            ? "sun"
            : state.selectedDateString.getDay() === 1
            ? "mon"
            : state.selectedDateString.getDay() === 2
            ? "tue"
            : state.selectedDateString.getDay() === 3
            ? "wed"
            : state.selectedDateString.getDay() === 4
            ? "thu"
            : state.selectedDateString.getDay() === 5
            ? "fri"
            : "sat",
        mentorTimeZone: String(state.serviceData?.timeZone),
        userTimeZone: String(value),
      };
      fetchTimeSlots(payload);
    }
  }

  return (
    <div>
      <div>
        <label style={{fontSize: "1.6rem"}}>Select time zone</label>
      </div>
      <Space size={10}>
        <GlobalOutlined style={{color: "#7939CC"}} />
        <StyledCustomSelect
          bordered={false}
          onChange={handleTimezoneChange}
          suffixIcon={
            <DownOutlined size={15} style={{fontWeight: "bold", color: "#00171F"}} />
          }
          optionLabelProp="label"
          size={"large"}
          defaultValue={customerTimezone}
          dropdownMatchSelectWidth={false}
          placeholder="Select your timezone"
          // searchValue={timezoneInputValue}
          // open={true}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (option?.value?.toString() ?? "")
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
        >
          {TIMEZONES.map((option, optionIdx: number | string) => (
            <Option key={optionIdx} value={option.value} label={option.value}>
              <p
                style={{
                  width: "20rem",
                  whiteSpace: "break-spaces",
                  wordBreak: "break-all",
                }}
              >
                ({option.name}) {option.value} ({getTimeByTimeZone(option.value)})
              </p>
            </Option>
          ))}
        </StyledCustomSelect>
      </Space>
    </div>
  );
}
