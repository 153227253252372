import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {IPasswordUpdateFormValues} from "../../components/settings/password-update-form/i-password-update-form";
import {RootState} from "../store";
import {updateEmailService, updatePasswordService} from "./settings-service";

interface IInitialState {
  message: string;
  updateUserEmailStatus: "idle" | "pending" | "resolved" | "rejected";
  updateUserPasswordStatus: "idle" | "pending" | "resolved" | "rejected";
}

const initialState: IInitialState = {
  message: "",
  updateUserEmailStatus: "idle",
  updateUserPasswordStatus: "idle",
};

//Update user email
export const updateUserEmail = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("settings/updateUserEmail", async (emailAddress, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateEmailService(emailAddress, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//Update user password
export const updateUserPassword = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IPasswordUpdateFormValues,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("settings/updateUserPassword", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updatePasswordService(payload, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    userSettingsLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserEmail.pending, (state) => {
        state.updateUserEmailStatus = "pending";
      })
      .addCase(updateUserEmail.fulfilled, (state, _) => {
        state.updateUserEmailStatus = "resolved";
      })
      .addCase(updateUserEmail.rejected, (state, action: any) => {
        state.updateUserEmailStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.updateUserPasswordStatus = "pending";
      })
      .addCase(updateUserPassword.fulfilled, (state, _) => {
        state.updateUserPasswordStatus = "resolved";
      })
      .addCase(updateUserPassword.rejected, (state, action: any) => {
        state.updateUserPasswordStatus = "rejected";
        state.message = action.payload;
      });
  },
});

export const {userSettingsLogoutReset} = settingsSlice.actions;

export default settingsSlice.reducer;
