import styled from "styled-components";
import {Card} from "antd";

export const StyledCard = styled(Card)`
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .ant-card-head-title {
    font-weight: 600;
    flex-basis: 40rem;
    flex-grow: initial;
  }
`;

export const StyledClassCardContent = styled.div`
  display: grid;
  gap: 1.5rem;
  .class-card-image-wrapper {
    width: 100%;
    height: 18.5rem;
    border-radius: 5px;
    .class-card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .class-card-info-text {
    font-weight: 400;
    font-size: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    /* display 4 lines only */
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .class-card-extra-info {
    font-weight: 600;
    font-size: 1.4rem;
  }
  @media (min-width: 670px) {
    grid-template-columns: 32rem auto auto;
    grid-template-rows: auto;

    .class-card-image-wrapper {
      grid-column: 1/2;
      grid-row: 1/5;
      // height: 16rem;
      .class-card-image {
        height: 100%;
      }
    }
    .class-card-info-text {
      grid-column: 2/4;
      grid-row: 1/2;
    }
    .class-card-extra-info {
      grid-column: 2/4;
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
    }
    .reg-tag {
      grid-column: 2/4;
      grid-row: 3/4;
    }
    .class-card-start-btn {
      grid-column: 2/3;
      grid-row: 4/5;
    }
    .class-card-details-btn {
      grid-column: 3/4;
      grid-row: 4/5;
    }
  }
`;
