import {Alert, Button, Input, Result, Space, Tooltip} from "antd";
import {
  CopyOutlined,
  FacebookFilled,
  MailFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useFetchCoHosts} from "../../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {CustomModal} from "../../../modal";
import {StyledSuccessServiceContent} from "../../../calls/service/service-form/service-form.style";
import {handleLiveClassLink} from "../../../../utils/live-class";
import {useGetProfileContext} from "../../../../context/profile/profile-context";
import {StyledFormWrapper} from "../../../../styles/onboarding/onboarding";
import {CohostList} from "./cohost-list";
import {useCreateLiveClassContext} from "../../../../context/live-class/create-live-class";
import {FullPageLoader} from "../../../full-page-loader";

export function AddCoHost() {
  const navigate = useNavigate();
  const {state, dispatch} = useCreateLiveClassContext();
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState<boolean>(false);
  const [toolTipText, setToolTipText] = useState<string>("copy service url");
  const {state: profileState} = useGetProfileContext();
  const eventId = state.liveClassData?.id;
  const eventName = state.liveClassData?.name;
  const slug = state.liveClassData?.slug;
  const fetchClassCoHostsQuery = useFetchCoHosts(String(eventId));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleCreateLiveClass() {
    navigate("/dashboard/live-classes/new");
    dispatch({
      payload: "basic",
      type: "update-create-live-class-form-step",
    });
  }

  //   console.log({formErrors: cohostListWatch("co_hosts")});
  if (!state.liveClassData) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle="Please create a class before adding a co-host"
        extra={[
          <Button
            onClick={handleCreateLiveClass}
            type="primary"
            size="large"
            shape="round"
          >
            Create new live class
          </Button>,
        ]}
      ></Result>
    );
  }
  if (fetchClassCoHostsQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (fetchClassCoHostsQuery.isError) {
    return (
      <Alert
        message="Error"
        description={fetchClassCoHostsQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  const handleSuccessModalOk = () => {
    setOpenSuccessfulModal(false);
    navigate("/dashboard/live-classes");
  };

  function handleSaveChanges() {
    setOpenSuccessfulModal(true);
    // if (navFrom === "create") {
    //   setOpenSuccessfulModal(true);
    // } else {
    //   message.success("Class updated successfully!");
    //   navigate("/dashboard/live-classes");
    // }
  }
  return (
    <div style={{marginTop: "1.8rem"}}>
      <StyledFormWrapper style={{marginTop: 0}}>
        <form>
          <Space direction="vertical" size={24} style={{display: "flex"}}>
            {/* <div>
              <Link to={`/dashboard/live-classes/edit/${eventId}?formType=edit`}>
                <Button type="link" size="large" icon={<ArrowLeftOutlined />}>
                  Back
                </Button>
              </Link>
            </div> */}

            <CohostList eventId={eventId} coHosts={fetchClassCoHostsQuery.data} />
            <div>
              <Button
                //   disabled={productData ? Boolean(productData.medias.length < 1) : false}
                shape="round"
                type="primary"
                size="large"
                onClick={handleSaveChanges}
              >
                Create live class
              </Button>
            </div>
          </Space>
        </form>
      </StyledFormWrapper>
      {/* ADD COHOST MODAL */}

      {/* SUCCESS LIVE CLASS CREATE MODAL */}
      {eventId && eventName && slug && (
        <CustomModal
          visibleState={openSuccessfulModal}
          title="Live class created"
          okFunc={handleSuccessModalOk}
          cancelFunc={handleSuccessModalOk}
          footerContent={[
            <Button
              key="publicProfile"
              type="primary"
              shape="round"
              // onClick={() => navigate(`/${profileState.profileData.username}`)}
              href={`/${profileState.profileData.username}`}
              target="_blank"
            >
              View my public profile
            </Button>,
            <Button
              key="gotIt"
              shape="round"
              type="default"
              onClick={handleSuccessModalOk}
            >
              Got it!
            </Button>,
          ]}
        >
          <StyledModalContentWrapper>
            <StyledSuccessServiceContent>
              <p className="success-text">
                Your live class has been successfully created! Share your event link to
                start getting bookings
              </p>
              <div className="success-share-info">
                <Input.Group compact style={{display: "flex"}}>
                  <Input
                    size="large"
                    readOnly
                    defaultValue={handleLiveClassLink({
                      eventId: eventId,
                      slug: slug,
                      username: profileState.profileData.username,
                      windowNav: true,
                    })}
                  />
                  <Tooltip title={toolTipText}>
                    <Button
                      size="large"
                      icon={<CopyOutlined />}
                      onClick={() =>
                        navigator.clipboard
                          .writeText(
                            handleLiveClassLink({
                              eventId: eventId,
                              slug: slug,
                              username: profileState.profileData.username,
                              windowNav: true,
                            })
                          )
                          .then(() => setToolTipText("copied"))
                      }
                    />
                  </Tooltip>
                </Input.Group>
                <p>or</p>
                <Space direction="horizontal" size={16}>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<FacebookFilled />}
                      style={{
                        backgroundColor: "#EBEEF5",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${profileState.profileData.username}/live-classes/details?slug=${slug}&eventId=${eventId}&amp`}
                      title="Share on facebook"
                    />
                  </div>
                  <div>
                    <Button
                      title="Share on twitter"
                      shape="circle"
                      size="large"
                      icon={<TwitterOutlined />}
                      target="_blank"
                      style={{
                        backgroundColor: "#E6F7FD",
                        color: "#00ACEE",
                        border: "none",
                      }}
                      href={`https://twitter.com/intent/tweet?text=I just created a live class called "${eventName}" on Coachli. Book a live class with me today on Coachli!&url=${window.location.origin}/${profileState.profileData.username}/live-classes/details?slug=${slug}%26eventId=${eventId}&hashtags=coachli,useCoachli`}
                    />
                  </div>
                  <div>
                    <Button
                      title="Send mail"
                      shape="circle"
                      size="large"
                      icon={<MailFilled />}
                      style={{
                        backgroundColor: "#FBECEB",
                        color: "#DB4437",
                        border: "none",
                      }}
                      href={`mailto:?subject=I wanted you to see this site&amp;body=Book a live class with me on Coachli ${window.location.origin}/${profileState.profileData.username}/live-classes/details?slug=${slug}&eventId=${eventId}`}
                    />
                  </div>
                </Space>
              </div>
            </StyledSuccessServiceContent>
          </StyledModalContentWrapper>
        </CustomModal>
      )}
    </div>
  );
}
