// import "./calendar-header.style.css";

import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Button, Divider, Space} from "antd";
import {StyledCalendarHeader, StyledCalendarHeaderTop} from "./calendar-header.style";

interface ICalenderHeaderProps {
  currentMonth: string;
  month: number;
  year: number;
  currentSelectedDate?: Date;
  handlePrev: () => void;
  handleNext: () => void;
}

const CalendarHeader: React.FC<ICalenderHeaderProps> = ({
  currentMonth,
  month,
  year,
  currentSelectedDate,
  handlePrev,
  handleNext,
}) => {
  return (
    <StyledCalendarHeader className="calendar-header">
      <StyledCalendarHeaderTop className="calendar-header-top">
        <p className="selected-month">
          {currentMonth} {year}
        </p>
        <div className="year-change">
          <Space size={25}>
            <Button
              type="text"
              disabled={
                month <= new Date().getMonth() && year === new Date().getFullYear()
              }
              shape="circle"
              icon={<LeftOutlined />}
              onClick={handlePrev}
            />
            <Button
              type="text"
              shape="circle"
              icon={<RightOutlined />}
              onClick={handleNext}
            />
            {/* <span onClick={handlePrev}>
              <LeftOutlined />
            </span>
            <span onClick={handleNext}>
              <RightOutlined />
            </span> */}
          </Space>
        </div>
      </StyledCalendarHeaderTop>
      <Divider style={{marginTop: "1.4rem", marginBottom: "2.4rem"}} />
      {/* <p className="calendar-header-current-date">
        {currentSelectedDate?.toDateString()}
      </p> */}
    </StyledCalendarHeader>
  );
};

export {CalendarHeader};
