type TMeetingParticipant = "host" | "guest";
type TMeetingPayload = {
  token: any;
  roomId: string;
  firstName: string;
  lastName: string;
  meetingTitle: string;
};

type TServiceSuccessBookingLinkPayload = {
  username: string;
  slug: string;
  serviceId: string | number;
  windowNav?: boolean;
};

export function handleMeetingLink(
  type: TMeetingParticipant,
  meetingPayload: TMeetingPayload
) {
  const baseMeetingLink = `https://meeting.coachli.co//?token=${meetingPayload.token}&meetingId=${meetingPayload.roomId}&name=${meetingPayload.firstName}-${meetingPayload.lastName}&joinScreenTitle=${meetingPayload.meetingTitle}&participantCanToggleSelfWebcam&participantCanToggleSelfMic`;

  if (type === "host") {
    return `${baseMeetingLink}&canCreatePoll=true&canToggleWhiteboard=true&participantCanToggleRecording=true`;
  }

  return baseMeetingLink;
}

export function handleServiceBookingLink({
  serviceId,
  slug,
  username,
  windowNav,
}: TServiceSuccessBookingLinkPayload) {
  const baseLink = `/${username}/services/details?slug=${slug}&serviceId=${serviceId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}

export function handleServiceSuccessBookingLink({
  serviceId,
  slug,
  username,
  windowNav,
}: TServiceSuccessBookingLinkPayload) {
  const baseLink = `/${username}/services/booking/success?slug=${slug}&serviceId=${serviceId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}
