import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {StyledEditorWrapper, StyledInputLabelWrapper} from "../../../input/input.style";
import {Controller} from "react-hook-form";
import {TProductInputProps} from "../form-values";

type TProductDescriptionInputProps = TProductInputProps & {};

export function ProductDescriptionInput({
  control,
  formState,
}: TProductDescriptionInputProps) {
  return (
    <div>
      <StyledInputLabelWrapper>
        <label id="product_description" htmlFor="product_description">
          Description
        </label>
      </StyledInputLabelWrapper>
      <Controller
        name="description"
        control={control}
        render={({field: {onChange, value}}) => (
          <StyledEditorWrapper>
            <ReactQuill
              theme="snow"
              onChange={(description) => {
                onChange(description);
              }}
              value={value}
              placeholder="What is your digital product about?"
              modules={{
                toolbar: {
                  container: [
                    [{header: [1, 2, 3, 4, 5, 6, false]}],
                    ["bold", "italic", "underline", "strike", "color", "link", "size"],
                    [{color: []}, {background: []}],
                    [{script: "sub"}, {script: "super"}],
                    ["blockquote", "code-block"],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{indent: "-1"}, {indent: "+1"}, {align: []}],
                    ["link"],
                    ["clean"],
                  ],
                },
              }}
            />
          </StyledEditorWrapper>
        )}
      />
      {formState.errors.description?.message && (
        <div role="alert" className="ant-form-item-explain-error">
          {formState.errors.description.message}
        </div>
      )}
    </div>
  );
}
