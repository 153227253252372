import {CreateLiveClassProvider} from "../../context/live-class/create-live-class";
import {MessageProvider} from "../../context/message-context";
import {CreateProductProvider} from "../../context/products/add-product-context";
import {ProductsProvider} from "../../context/products/products-context";
import {ViewProductProvider} from "../../context/products/view-product-context";
import {CallsPage} from "../../pages/dashboard/calls";
import {AddCallPage} from "../../pages/dashboard/calls/add";
import {AvailabilityPage} from "../../pages/dashboard/calls/availability";
import {EditBundlePage} from "../../pages/dashboard/calls/bundle/edit";
import {AddBundlePage} from "../../pages/dashboard/calls/bundle/new";
import {EditCallPage} from "../../pages/dashboard/calls/edit";
import {CustomersPage} from "../../pages/dashboard/customers";
import {ViewCustomerDetailsPage} from "../../pages/dashboard/customers/view";
import {GoogleVerify} from "../../pages/dashboard/google/verify";
import {DashboardHomepage} from "../../pages/dashboard/home";
import {LiveClassesPage} from "../../pages/dashboard/live-classes";
import {LiveClassCreatePage} from "../../pages/dashboard/live-classes/new";
import {ViewLiveClassPage} from "../../pages/dashboard/live-classes/view";
import {MessagesPage} from "../../pages/dashboard/messages";
import {PayoutsPage} from "../../pages/dashboard/payouts";
import {DigitalProductsPage} from "../../pages/dashboard/products";
import {EditProductPage} from "../../pages/dashboard/products/edit";
import {AddNewProductPage} from "../../pages/dashboard/products/new";
import {ViewProductPage} from "../../pages/dashboard/products/view";
import {EditProfilePage} from "../../pages/dashboard/profile/edit-profile";
import {RecordingsPage} from "../../pages/dashboard/recordings";
import {SettingPage} from "../../pages/dashboard/settings";

type TDashboardPages = {
  path: string;
  element: React.ReactNode;
}[];

export const DashboardRoutes: TDashboardPages = [
  {
    path: "/dashboard/home",
    element: <DashboardHomepage />,
  },
  {
    path: "/dashboard/calls",
    element: <CallsPage />,
  },
  {
    path: "/dashboard/messages",
    element: (
      <MessageProvider>
        <MessagesPage />
      </MessageProvider>
    ),
  },
  {
    path: "/dashboard/settings",
    element: <SettingPage />,
  },
  {
    path: "/dashboard/products",
    element: (
      <ProductsProvider>
        <DigitalProductsPage />
      </ProductsProvider>
    ),
  },
  {
    path: "/dashboard/products/new",
    element: (
      <CreateProductProvider>
        <AddNewProductPage />
      </CreateProductProvider>
    ),
  },
  {
    path: "/dashboard/products/edit/:id",
    element: (
      <CreateProductProvider>
        <EditProductPage />
      </CreateProductProvider>
    ),
  },
  {
    path: "/dashboard/products/view/:id",
    element: (
      <ViewProductProvider>
        <ViewProductPage />
      </ViewProductProvider>
    ),
  },
  {
    path: "/dashboard/calls/availability",
    element: <AvailabilityPage />,
  },
  {
    path: "/dashboard/calls/availability/:tabKey",
    element: <AvailabilityPage />,
  },
  {path: "/dashboard/calls/new", element: <AddCallPage />},
  {path: "/dashboard/calls/edit/:id", element: <EditCallPage />},
  {path: "/dashboard/calls/bundle/new", element: <AddBundlePage />},
  {path: "/dashboard/calls/bundle/edit/:id", element: <EditBundlePage />},
  {path: "/dashboard/profile", element: <EditProfilePage />},
  {path: "/dashboard/live-classes", element: <LiveClassesPage />},
  {
    path: "/dashboard/live-classes/new",
    element: (
      <CreateLiveClassProvider>
        <LiveClassCreatePage />
      </CreateLiveClassProvider>
    ),
  },
  {
    path: "/dashboard/live-classes/edit/:id",
    element: (
      <CreateLiveClassProvider>
        <ViewLiveClassPage />
      </CreateLiveClassProvider>
    ),
  },
  {path: "/dashboard/recordings", element: <RecordingsPage />},
  {path: "/dashboard/customers", element: <CustomersPage />},
  {path: "/dashboard/customers/view/:id", element: <ViewCustomerDetailsPage />},
  {path: "/dashboard/payouts", element: <PayoutsPage />},
  {path: "/dashboard/google/verify", element: <GoogleVerify />},
];
