import * as yup from "yup";
import {Avatar, Button, Col, Input, Modal, Row, Space, message} from "antd";
import {DeleteOutlined, PlusCircleFilled, UserOutlined} from "@ant-design/icons";
import {
  StyledContentListItem,
  StyledContentListUploadWrapper,
} from "../../../../products/product-form/content-upload/content-upload.style";
import {
  GetCoHostsApiResponse,
  useAddCoHost,
  useDeleteCoHost,
  useValidateCohost,
} from "../../../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {useEffect, useState} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import useDebounce from "../../../../../hooks/use-debounce";
import {StyledModalContentWrapper} from "../../../../calls/upcoming-calls/call-card/call-card.style";
import {CustomInputComponent} from "../../../../input/input-component";
import {StyledInputLabelWrapper} from "../../../../input/input.style";
import {StyledCreateServiceHeadingBox} from "../../../../../styles/onboarding/onboarding";

export type CoHost = {
  first_name: string;
  last_name: string;
  email_address: string;
  host_id: number | null;
  normal_host_id: number;
  avatar: string | null;
};

const cohostFormSchema = yup.object().shape({
  host_id: yup.string().nullable().trim(),
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .typeError("Must be a valid string"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .typeError("Must be a valid string"),
  email_address: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
});

export function CohostList({
  coHosts,
  eventId,
}: {
  coHosts: GetCoHostsApiResponse | undefined;
  eventId: string | number | undefined;
}) {
  const deleteCoHostMutation = useDeleteCoHost();
  const [isCohostModalOpen, setIsCohostModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [cohostToDelete, setCohostToDelete] = useState<{
    avatar: string | null;
    email: string;
    id: string;
  } | null>(null);

  const addCoHostMutation = useAddCoHost();
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm<CoHost>({
    resolver: yupResolver(cohostFormSchema),
    mode: "onChange",
    defaultValues: {
      email_address: "",
    },
  });

  const emailValue = watch("email_address").toLowerCase();
  const debouncedEmailInput = useDebounce(
    !errors.email_address ? emailValue.toLowerCase() : "",
    1000
  );

  const validateCohostQuery = useValidateCohost(
    debouncedEmailInput.toLowerCase(),
    !errors.email_address && debouncedEmailInput.length > 0
  );

  useEffect(() => {
    if (validateCohostQuery.error) {
      // message.error(validateCohostQuery.error.message);
      // console.log({
      //   valid: validateCohostQuery.error.message.includes("user with this email address"),
      // });
      if (validateCohostQuery.error.message.includes("user with this email address")) {
        setValue("host_id", null);
        setValue("avatar", null);
      } else {
        setError("email_address", {
          message: validateCohostQuery.error.message,
        });
      }
    }

    if (validateCohostQuery.data) {
      setValue("host_id", validateCohostQuery.data.profile_id);
      clearErrors("email_address");
    }
  }, [
    clearErrors,
    setError,
    setValue,
    validateCohostQuery.data,
    validateCohostQuery.error,
  ]);
  const {control: coHostListControl, setValue: setValueCoHostList} = useForm<{
    co_hosts: CoHost[];
  }>({
    mode: "onChange",
    defaultValues: {
      co_hosts: coHosts
        ? coHosts.coHosts.map((cohost) => {
            return {
              email_address: cohost.host ? cohost.host.email : cohost.email_address,
              first_name: cohost.host ? cohost.host.firstName : cohost.first_name,
              host_id: cohost.host ? cohost.host.id : cohost.host_id,
              last_name: cohost.host ? cohost.host.lastName : cohost.last_name,
              avatar: cohost.host ? cohost.host.avatar : null,
              normal_host_id: cohost.id,
            };
          })
        : [],
    },
  });

  function handleShowDeleteModal(cohost: {
    avatar: string | null;
    id: string;
    email: string;
  }) {
    setCohostToDelete(cohost);
    setOpenDeleteModal(true);
  }
  const {fields: cohostFields, append: appendCohost} = useFieldArray({
    control: coHostListControl,
    name: "co_hosts",
    keyName: "_id",
  });

  const onSubmit = (values: CoHost) => {
    if (eventId) {
      addCoHostMutation.mutate(
        {
          email_address: values.email_address,
          event_id: String(eventId),
          first_name: values.first_name,
          host_id: values.host_id,
          last_name: values.last_name,
        },
        {
          onSuccess(data) {
            // console.log({data});
            appendCohost({
              avatar: data && data.data ? data.data.coHost.profile.avatar : null,
              email_address: values.email_address,
              first_name: values.first_name,
              host_id: values.host_id,
              last_name: values.last_name,
              normal_host_id: data.data.coHost.id,
            });
            message.success("Co host added successfully");
            setIsCohostModalOpen(false);
            reset();
          },
        }
      );
    } else {
      appendCohost(values);
      setIsCohostModalOpen(false);
      reset();
    }
  };

  const showCohostModal = () => {
    setIsCohostModalOpen(true);
  };
  function handleCancelDeleteCoHostModal() {
    setCohostToDelete(null);
    setOpenDeleteModal(false);
  }

  function handleDeleteCohost(cohostId: string) {
    deleteCoHostMutation.mutate(
      {
        event_id: String(eventId),
        model_id: cohostId,
      },
      {
        onSuccess() {
          if (coHosts) {
            const newCohosts: CoHost[] = coHosts.coHosts
              .filter((x) => String(x.id) !== cohostId)
              .map((v) => {
                return {
                  avatar: v.host ? v.host.avatar : null,
                  email_address: v.email_address,
                  first_name: v.first_name,
                  host_id: v.host_id,
                  last_name: v.last_name,
                  normal_host_id: v.id,
                };
              });
            setValueCoHostList("co_hosts", newCohosts);
            setCohostToDelete(null);
            setOpenDeleteModal(false);
            message.success("Co host deleted successfully");
          }
        },
      }
    );
  }
  const handleCancelAddCoHostModal = () => {
    setIsCohostModalOpen(false);
    clearErrors();
    reset();
  };
  return (
    <>
      <Space direction="vertical" size={24} style={{display: "flex"}}>
        <div>
          <Space size={8}>
            <StyledCreateServiceHeadingBox>
              <h1 className="main-heading">Add co-host</h1>
            </StyledCreateServiceHeadingBox>
            <span style={{color: "#8c8c8c"}}>(optional)</span>
          </Space>
        </div>
        <StyledContentListUploadWrapper>
          <Space size={16} direction="vertical" style={{display: "flex"}}>
            <div>
              <p style={{fontWeight: 400, fontSize: "1.6rem"}}>
                You can add up to ten co-host for your live class. If they’re not
                registered on Coachli, we’ll invite them. Co-hosts can perform all hosting
                tasks.
              </p>
              <Space direction="vertical" size={12} style={{display: "flex"}}>
                {cohostFields.slice(0, 4).map((item, index) => (
                  <StyledContentListItem key={item._id}>
                    <div className="content-list-item-info">
                      <div className="content-list-item-name-wrapper">
                        {/* <FileOutlined className="content-list-item-file-icon" />{" "} */}
                        <Space size={8}>
                          {item.avatar ? (
                            <Avatar size={24} src={item.avatar} />
                          ) : (
                            <Avatar size={24} icon={<UserOutlined />} />
                          )}
                          <p className="content-list-item-name">{item.email_address}</p>
                        </Space>
                      </div>
                      <div>
                        <Space size={16}>
                          <div
                          //   onClick={() => handleContentDelete(item.id)}
                          >
                            <Button
                              style={{backgroundColor: "#f5edff", border: "none"}}
                              size="large"
                              shape="circle"
                              onClick={() =>
                                handleShowDeleteModal({
                                  avatar: item.avatar,
                                  email: item.email_address,
                                  id: String(item.normal_host_id),
                                })
                              }
                              icon={<DeleteOutlined />}
                              loading={deleteCoHostMutation.isLoading}
                            />
                          </div>
                        </Space>
                      </div>
                    </div>
                  </StyledContentListItem>
                ))}
              </Space>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={showCohostModal}
                size="large"
                shape="round"
                icon={<PlusCircleFilled />}
                //   disabled={deleteProductContentMutation.isLoading}
                //   loading={uploadProductContentMutation.isLoading}
              >
                Add a co-host
              </Button>
            </div>
          </Space>
        </StyledContentListUploadWrapper>
      </Space>
      <Modal
        title="Enter your co-host details here"
        visible={isCohostModalOpen}
        onCancel={handleCancelAddCoHostModal}
        footer={
          <div>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="primary"
              shape="round"
              loading={
                addCoHostMutation.isLoading ||
                (validateCohostQuery.isLoading &&
                  !errors.email_address &&
                  debouncedEmailInput.length > 0)
              }
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
            >
              Add co-host
            </Button>
            <Button onClick={handleCancelAddCoHostModal} shape="round" type="ghost">
              Cancel
            </Button>
          </div>
        }
      >
        <StyledModalContentWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              <p>
                An email notification will be sent to your co-host after the class has
                been created.
              </p>
              <Row gutter={[27, 24]}>
                <Col span={24} md={12}>
                  <CustomInputComponent
                    id="first_name"
                    label="First name"
                    placeholder="Enter co-host first name"
                    error={errors.first_name?.message}
                    control={control}
                    name="first_name"
                  />
                </Col>
                <Col span={24} md={12}>
                  <CustomInputComponent
                    id="last_name"
                    name="last_name"
                    label="Last name"
                    placeholder="Enter co-host last name"
                    error={errors.last_name?.message}
                    control={control}
                  />
                </Col>
              </Row>
              {/* <CustomInputComponent
            id="email"
            name="email_address"
            label="Email address"
            type="email"
            placeholder="Enter co-host email address"
            error={errors.email_address?.message}
            control={control}
          /> */}
              <div>
                <StyledInputLabelWrapper>
                  <label id="email" htmlFor="email">
                    Email address
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="email_address"
                  render={({field: {onChange, value, ref}}) => (
                    <Input
                      id="cohostemail"
                      status={errors.email_address?.message ? "error" : ""}
                      onChange={onChange}
                      size="large"
                      placeholder="Enter co-host email address"
                      value={value}
                      ref={ref}
                      //   suffix={
                      //     validateCohostQuery.isSuccess ? (
                      //       <Tooltip title="User found">
                      //         <CheckCircleTwoTone twoToneColor="#52C41A" />
                      //       </Tooltip>
                      //     ) : null
                      //   }
                    />
                  )}
                />
                {errors.email_address?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.email_address?.message}
                  </div>
                )}
              </div>
            </Space>
          </form>
        </StyledModalContentWrapper>
      </Modal>
      {cohostToDelete && (
        <Modal
          title="Delete co-host"
          visible={openDeleteModal}
          onCancel={handleCancelDeleteCoHostModal}
          footer={
            <div>
              <Button
                onClick={() => handleDeleteCohost(cohostToDelete.id)}
                type="primary"
                shape="round"
                loading={deleteCoHostMutation.isLoading}
                disabled={!cohostToDelete}
              >
                Delete co-host
              </Button>
              <Button onClick={handleCancelDeleteCoHostModal} shape="round" type="ghost">
                Cancel
              </Button>
            </div>
          }
        >
          <StyledModalContentWrapper>
            <Space direction="vertical" size={16} style={{display: "flex"}}>
              <p style={{fontSize: "1.6rem"}}>
                Are you sure you want to delete this co-host from your event?
              </p>
              <div>
                <Space size={8}>
                  {cohostToDelete.avatar ? (
                    <Avatar size={32} src={cohostToDelete.avatar} />
                  ) : (
                    <Avatar size={32} icon={<UserOutlined />} />
                  )}
                  <p style={{fontSize: "1.6rem"}}>{cohostToDelete.email}</p>
                </Space>
              </div>
            </Space>
          </StyledModalContentWrapper>
        </Modal>
      )}
    </>
  );
}
