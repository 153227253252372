import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  IGoogleLoginValues,
  IGoogleRegisterValues,
} from "../../../components/google-button/i-google-button";
import {ILoginFormValues} from "../../../pages/auth/login/i-login";
import {AnalyticsEvent} from "../../../utils/analytics";
import {store} from "../../store";
import {activateStandardUser} from "../account-activation/account-activation-slice";
import {registerSocialUserService} from "../register/user-register-service";
import {
  loginSocialUserService,
  loginStandardUserService,
  logoutUserService,
} from "./user-login-service";

interface IInitialState {
  userInfo: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  userInfo: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Register social user
// Using it here because it does the same thing as login
export const registerSocialUser = createAsyncThunk(
  "userRegister/registerSocialUser",
  async (user: IGoogleRegisterValues, thunkAPI) => {
    try {
      return await registerSocialUserService(user);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//login standard user
export const loginStandardUser = createAsyncThunk(
  "userLogin/loginStandardUser",
  async (user: ILoginFormValues, thunkAPI) => {
    try {
      return await loginStandardUserService(user);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//login social user
export const loginSocialUser = createAsyncThunk(
  "userLogin/loginSocialUser",
  async (user: IGoogleLoginValues, thunkAPI) => {
    try {
      return await loginSocialUserService(user);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Logout user
export const logoutUser = createAsyncThunk(
  "userLogin/logoutUser",
  async () => await logoutUserService()
);

export const userLoginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    resetUserLoginState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      // state.userInfo = null;
    },
    userLoginLogoutReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.userInfo = null;
    },
    updateLoginState: (state, action) => {
      // console.log(action);
      // const reduxStore = store.getState();
      // const userInfo = reduxStore.userAccountActivation.userInfo;
      state.userInfo = action.payload;
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "";
      // state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerSocialUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerSocialUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userInfo = action.payload.data;
        AnalyticsEvent("Social Registration", "Social Registration Success");
      })
      .addCase(registerSocialUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userInfo = null;
        AnalyticsEvent("Social Registration", "Social Registration Failure");
      })
      .addCase(loginStandardUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginStandardUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userInfo = action.payload.data;
        AnalyticsEvent("Standard Login", "Standard Login Success");
      })
      .addCase(loginStandardUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userInfo = null;
        AnalyticsEvent("Standard Login", "Standard Login Failure");
      })
      .addCase(loginSocialUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginSocialUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userInfo = action.payload.data;
        AnalyticsEvent("Social Login", "Social Login Success");
      })
      .addCase(loginSocialUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userInfo = null;
        AnalyticsEvent("Social Login", "Social Login Failure");
      });
    // .addCase(activateStandardUser.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(activateStandardUser.fulfilled, (state, action) => {
    //   state.isSuccess = true;
    //   state.userInfo = action.payload.data;
    // })
    // .addCase(activateStandardUser.rejected, (state) => {
    //   state.userInfo = null;
    // })
    // .addCase(logoutUser.fulfilled, (state) => {
    //   state.userInfo = null;
    // });
  },
});

export const {resetUserLoginState, updateLoginState, userLoginLogoutReset} =
  userLoginSlice.actions;

export default userLoginSlice.reducer;
