import styled from "styled-components";

export const StyledRevenueList = styled.div``;
export const StyledRevenueListItem = styled.div`
  padding: 4px 0;
  /*display: flex;
  gap: 5rem;
  justify-content: space-between; */
  text-align: right;
  .item-volume {
    padding-left: 2.3rem;
  }
  .item-unit {
    padding-right: 2.3rem;
  }
  .item-title {
    color: #595959;
    font-size: 1.2rem;
  }
  .item-value {
    color: #000709;
    font-size: 1.4rem;
    font-weight: 600;
    /* margin-top: 8px; */
  }
`;
