import axios from "axios";
import {API_BASE_URL} from "../../services/config";

// Get defualt user services
export const getCustomerMetricsService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "customer/metrics", config);

  return response.data;
};

// Get list of customers
export const getCustomersService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "customer/booking", config);

  return response.data;
};

// Get customer details
export const getCustomerService = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "customer/booking/" + id, config);

  return response.data;
};
// Get customer previous purchases
export const getCustomerPrevPurchasesService = async (
  payload: {mentorId: string; customerEmail: string},
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {mentorId, customerEmail} = payload;
  const url = `${API_BASE_URL}customer/booking-purchases?emailAddress=${customerEmail.toLowerCase()}&currentId=${mentorId}`;
  const response = await axios.get(url, config);

  return response.data;
};

// Export customer CSV data
export const exportCSVService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${API_BASE_URL}customer/booking-csv`;
  const response = await axios.get(url, config);

  return response.data;
};
