import {Alert, Row, Col, Space, Button, Drawer, Tooltip} from "antd";
import {useGetCustomerMetrics} from "../../../react-query-hooks/customer";
import {StyledCustomersDataBox} from "../../../styles/customers/customers";
import {FullPageLoader} from "../../full-page-loader";
import {CustomerMetricsSkeleton} from "../customer-details-section/customer-metrics-skeleton";
import {Fragment, useState} from "react";
import {CloseOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {StyledRevenueListItem} from "../../products/sales-and-insights/revenue-section/revenue-section.style";

const CustomerMetricsSection = () => {
  const getCustomerMetricsQuery = useGetCustomerMetrics();

  const [openRevenueBreakDown, setOpenRevenueBreakDown] = useState(false);

  const showRevenueBreakDown = () => {
    setOpenRevenueBreakDown(true);
  };

  const onCloseRevenueBreakDown = () => {
    setOpenRevenueBreakDown(false);
  };

  if (getCustomerMetricsQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getCustomerMetricsQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getCustomerMetricsQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const filteredCurrencies = getCustomerMetricsQuery.data.totalRevenue.filter(
    (value, index) =>
      index ===
      getCustomerMetricsQuery.data.totalRevenue.findIndex(
        (o) => value.currency === o.currency
      )
  );

  const handleTotalRevenue = () => {
    return filteredCurrencies.reduce((total, num) => total + Number(num.amount), 0);
  };

  return (
    <>
      {getCustomerMetricsQuery.isFetching ? (
        <CustomerMetricsSkeleton />
      ) : (
        <>
          <Space size={16} direction="vertical" style={{display: "flex"}}>
            <Row gutter={[32, 20]}>
              {/* <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">
                    Total revenue{" "}
                    <Tooltip
                      placement="left"
                      title="Your total revenue is calculated in your payout currency"
                    >
                      <Button
                        icon={<QuestionCircleOutlined />}
                        size="small"
                        type="text"
                      />
                    </Tooltip>
                  </p>
                  <h2 className="data-box-value">
                    {(getCustomerMetricsQuery.data.currency ?? "") +
                      " " +
                      (handleTotalRevenue() || 0)}
                  </h2>
                </StyledCustomersDataBox>
              </Col>
              <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total Calls</p>
                  <h2 className="data-box-value">
                    {getCustomerMetricsQuery.data.totalCalls ?? 0}
                  </h2>
                </StyledCustomersDataBox>
              </Col> */}
              <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total Customers</p>
                  <h2 className="data-box-value">
                    {getCustomerMetricsQuery.data.totalUniqueCustomers ?? 0}
                  </h2>
                </StyledCustomersDataBox>
              </Col>
            </Row>
            {/* {getCustomerMetricsQuery.data.totalRevenue.length > 0 && (
              <p style={{color: "#595959"}}>
                Check your earnings in all your currencies,{" "}
                <Button
                  onClick={showRevenueBreakDown}
                  style={{padding: "0"}}
                  type="link"
                  size="small"
                >
                  click here
                </Button>{" "}
                to view
              </p>
            )} */}
          </Space>
          <Drawer
            title="Revenue breakdown"
            placement="right"
            onClose={onCloseRevenueBreakDown}
            visible={openRevenueBreakDown}
            // mask={false}
            closable={false}
            width={320}
            extra={
              <Button
                type="ghost"
                size="small"
                icon={<CloseOutlined />}
                onClick={onCloseRevenueBreakDown}
              ></Button>
            }
          >
            <Row gutter={[20, 16]}>
              {filteredCurrencies.map((revenue) => (
                <Fragment key={revenue.currency}>
                  <Col span={12}>
                    <StyledRevenueListItem>
                      <div className="item-volume">
                        <p className="item-title">Currency</p>
                        <p className="item-value">{revenue.currency}</p>
                      </div>
                    </StyledRevenueListItem>
                  </Col>
                  <Col span={12}>
                    <StyledRevenueListItem>
                      <div className="item-unit">
                        <p className="item-title">Total amount</p>
                        <p className="item-value">
                          {Number(revenue.amount).toLocaleString("en-us")}
                        </p>
                      </div>
                    </StyledRevenueListItem>
                  </Col>
                </Fragment>
              ))}
            </Row>
          </Drawer>
        </>
      )}
    </>
  );
};

export {CustomerMetricsSection};
