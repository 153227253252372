import styled from "styled-components";

export const StyledSideNav = styled.aside<{
  visible?: boolean;
}>`
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: relative;
  /* height: 100vh;
  position: sticky;
  top: 0;
  z-index: 90;
  display: flex;
  flex-direction: column; */
  transition-property: width, transform !important;
  transition-duration: 0.3s !important;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  /* overflow: hidden; */
  @media (max-width: 960px) {
    padding-top: 7rem;
    z-index: 90;
    position: fixed;
    width: 23.1rem;
    transform: translate3d(
      ${({visible}) => (visible ? 0 : "calc(23.1rem - 23.1rem*2)")},
      0,
      0
    );
    transition: transform 0.3s
      ${({visible}) =>
        visible ? "cubic-bezier(0.4, 0, 1, 1)" : "cubic-bezier(0, 0, 0.2, 1)"} !important;
  }
`;
export const StyledLogoContainer = styled.div<{
  visible?: boolean;
}>`
  margin: 1.15rem 1rem 1.6rem 1rem;
  cursor: pointer;
  width: 15.9rem;
  height: 3.8rem;

  img {
    width: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;
