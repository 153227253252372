import {
  DollarOutlined,
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  NumberOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {Space, Button} from "antd";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  TCustomerPaidBundleApiResponse,
  useGetCustomerBundleBooking,
} from "../../../../../../../react-query-hooks/customer";
import {capitalizeFirstLetter} from "../../../../../../../services/helpers";
import {PastCallDetailsModal} from "../../../../../past-calls/past-call-details-modal";
import {
  StyledCallCard,
  StyledCallCardExtraInfo,
  StyledCardDetailsButtonWrapper,
} from "../../../../call-card/call-card.style";
import {CallDetailsModal} from "../../../../call-card/call-details-modal";
import {CalendarModal} from "../calendar-modal";
import {CalendarModalContent} from "../calendar-modal/calendar-modal-content";
import {PaidBundlesDetailsModal} from "../details-modal";

const PurchasedBundleCard = ({call}: {call: TCustomerPaidBundleApiResponse}) => {
  const navigate = useNavigate();
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState<boolean>(false);
  const [openCalendarModal, setOpenCalendarModal] = useState<boolean>(false);
  // const customerBundleBooking = useGetCustomerBundleBooking(call.reference);

  const showViewDetailsModal = () => {
    setOpenViewDetailsModal(true);
  };

  const handleOk = () => {
    setOpenViewDetailsModal(false);
  };

  const handleCancel = () => {
    setOpenViewDetailsModal(false);
  };
  const showCalendarModal = () => {
    setOpenCalendarModal(true);
    // customerBundleBooking.refetch();
  };

  const handleCalendarOk = () => {
    setOpenCalendarModal(false);
  };

  const handleCalendarCancel = () => {
    setOpenCalendarModal(false);
  };

  if (!call.service) {
    return <div>Invalid</div>;
  }
  const stripedHtmlDesscription = call.service.description.replace(/<[^>]+>/g, "");

  return (
    <StyledCallCard
      title={`${call.coach.firstName} ${call.coach.lastName} - ${call.service.name}`}
      //   extra={<CallCardMenu call={call} />}
    >
      <Space direction="vertical" size={18} style={{display: "flex"}}>
        <p className="call-card-description">{stripedHtmlDesscription}</p>
        <StyledCallCardExtraInfo>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.bookedSessions} out of {call.noOfSessions} sessions
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.price === 0
                  ? "Free"
                  : call.service.callPriceCurrency +
                    " " +
                    call.price.toLocaleString("en-US")}
              </span>
            </Space>
          </div>

          <div className="phone-number-extra-details">
            <div className="call-card-extra-info-item">
              <Space size={10}>
                {call.service.videoLocation ? (
                  <>
                    <VideoCameraOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">Video call</span>
                  </>
                ) : (
                  <>
                    <PhoneOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">Phone call</span>
                  </>
                )}
              </Space>
            </div>
            {call.service.audioLocation && (
              <div className="call-card-extra-info-item">
                <Space size={10}>
                  <NumberOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">
                    {call.phoneNumber}
                  </span>
                </Space>
              </div>
            )}
          </div>
        </StyledCallCardExtraInfo>

        <StyledCardDetailsButtonWrapper>
          {call.bookedSessions !== call.noOfSessions ? (
            <div>
              <Button
                size="large"
                block
                shape="round"
                type="primary"
                onClick={showCalendarModal}
              >
                Schedule calls
              </Button>
            </div>
          ) : (
            <div>
              <Button
                size="large"
                block
                shape="round"
                type="primary"
                href={`/${call.coach_username}`}
                target="_blank"
              >
                Book a new session
              </Button>
            </div>
          )}

          <div>
            {/* <StyledServiceButtonWrapper> */}
            <Button
              size="large"
              block
              shape="round"
              type="default"
              onClick={showViewDetailsModal}
            >
              View details
            </Button>
          </div>

          {/* </StyledServiceButtonWrapper> */}
        </StyledCardDetailsButtonWrapper>
      </Space>

      <CalendarModal
        visibleState={openCalendarModal}
        title={`Schedule call ${call.bookedSessions + 1} of ${call.noOfSessions} with ${
          call.coach.firstName
        } ${call.coach.lastName}`}
        okFunc={handleCalendarOk}
        cancelFunc={handleCalendarCancel}
        footerContent={[
          <Button onClick={handleCalendarOk} key="okay" type="primary" shape="round">
            Ok
          </Button>,
        ]}
      >
        <CalendarModalContent bundle={call} />
      </CalendarModal>
      <CallDetailsModal
        visibleState={openViewDetailsModal}
        title={`${capitalizeFirstLetter(call.service.name)} with ${capitalizeFirstLetter(
          call.coach.firstName
        )} ${capitalizeFirstLetter(call.coach.lastName)}`}
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button onClick={handleOk} key="okay" type="primary" shape="round">
            Ok
          </Button>,
        ]}
      >
        <PaidBundlesDetailsModal call={call} />
      </CallDetailsModal>
    </StyledCallCard>
  );
};

export {PurchasedBundleCard};
