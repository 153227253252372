import styled from "styled-components";

export const StyledContentListUploadWrapper = styled.div`
  padding: 1.6rem 5.45rem;
  border-radius: 5px;
  background: #fafafa;

  .content-list-title {
    color: #00171f;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px #d9d9d9 solid;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;
export const StyledContentListItem = styled.div`
  display: flex;

  .content-list-item-info {
    display: flex;
    gap: 2.4rem;
    /* align-items: center; */
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 2rem;
    flex: 1;

    .content-list-item-name-wrapper {
      display: flex;

      .content-list-item-file-icon {
        color: #595959;
        font-size: 2rem;
        margin-right: 8px;
      }
      .content-list-item-name {
        color: #8c8c8c;
        /* overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical; */
        max-width: 32.8rem;
        /* text-overflow: ellipsis;
        white-space: nowrap; */
        /* align-self: center; */
      }
    }
  }
`;
