import {Breadcrumb, Tabs} from "antd";
import {Link, useParams} from "react-router-dom";
import {
  Availability,
  CalendarTabContent,
  UnavailabilityTabContent,
} from "../../../../components/calls/availability";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";

const AvailabilityPage = () => {
  const {tabKey} = useParams();
  return (
    <DashboardWrapper pageTitle="Availability">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/calls">1:1 Calls</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Availability</Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <Tabs
            defaultActiveKey={
              // tabKey === "unavailability" ? "2" : tabKey === "calendar" ? "3" : "1"
              tabKey === "unavailability" ? "2" : "1"
            }
          >
            <Tabs.TabPane tab="Availability" key="1">
              <Availability />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Unavailability" key="2">
              <UnavailabilityTabContent />
            </Tabs.TabPane>
            {/* <Tabs.TabPane
              tab={
                <>
                  <span>Calendar</span>
                  <Tag style={{marginLeft: "1rem"}} color="blue">
                    Coming soon
                  </Tag>
                </>
              }
              key="3"
              disabled
            >
              <CalendarTabContent />
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {AvailabilityPage};
