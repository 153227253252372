import {CustomCheckboxInput} from "../../../checkbox";
import {TProductInputProps} from "../form-values";

type TProductHideFromProfileInputProps = TProductInputProps & {};

export function ProductHideFromProfileInput({
  control,
}: TProductHideFromProfileInputProps) {
  return (
    <CustomCheckboxInput
      control={control}
      id="hideProductFromProfile"
      name="hideProductFromProfile"
      label="Hide product from your profile page"
    />
  );
}
