import {Row, Col, Spin} from "antd";
import {useParams} from "react-router";
import {useGetPublicProfile} from "../../../react-query-hooks/public-profile";
import {StyledUpcomingCallsWrapper} from "../../calls/upcoming-calls/upcoming-calls.style";
import {LoadingSpinner} from "../../icons/icons";
import {ServiceCard} from "./service-card";
import {StyledServicesSectionWrapper} from "./services-section.style";

const ServicesSection = () => {
  const {username} = useParams();
  const getPublicProfileQuery = useGetPublicProfile(username);

  return (
    <StyledServicesSectionWrapper>
      <StyledUpcomingCallsWrapper>
        <>
          <Spin
            spinning={getPublicProfileQuery.isFetching}
            tip="Updating"
            indicator={LoadingSpinner}
          >
            <h1 className="services-section-heading">1:1 Sessions</h1>
            <Row style={{marginTop: "2.4rem"}} gutter={[32, 20]}>
              {getPublicProfileQuery.data?.services?.map((service, idx) => (
                <Col key={idx} span={24} md={12}>
                  <ServiceCard
                    service={service}
                    username={getPublicProfileQuery.data.username}
                  />
                </Col>
              ))}
            </Row>
          </Spin>
        </>
        {/* )} */}
      </StyledUpcomingCallsWrapper>
    </StyledServicesSectionWrapper>
  );
};

export {ServicesSection};
