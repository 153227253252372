import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Footer} from "../../components/home/footer";
import {HomeNavbar} from "../../components/home/navbar";
import {RootState} from "../../redux/store";
import ReactGA from "react-ga";
import {GetStartedSection} from "../../components/home/get-started-section";

declare const window: Window &
  typeof globalThis & {
    Intercom: any;
  };

const HomeWrapper = ({children}: {children: React.ReactNode}) => {
  const navigate = useNavigate();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "lhv6fa0p",
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo && userInfo.isCustomer) {
      navigate("/dashboard/customer/home");
    }
    if (userInfo && !userInfo.isCustomer) {
      navigate("/dashboard/onboarding");
    }
  }, [navigate, userInfo]);
  return (
    <div>
      <HomeNavbar />
      {children}
      <GetStartedSection />
      <Footer />
    </div>
  );
};

export {HomeWrapper};
