import {useQueryClient, useMutation} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {AnalyticsEvent} from "../../utils/analytics";
import {useClient} from "../../utils/hooks";

export type TCurrencyDeletePayload = {
  currencyId: string;
  eventId: string;
  module: "live_classes" | "service";
};

const useDeleteCurrency = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TCurrencyDeletePayload) =>
      client(`currency/${payload.currencyId}`, {
        method: "DELETE",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Currency", "Currency delete fail");
    },
    onSuccess: (data) => {
      AnalyticsEvent("Currency", "Currency delete success");
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({
        queryKey: [`${values.module}`, String(values.eventId)],
      }),
  });
};

export {useDeleteCurrency};
