import styled from "styled-components";

export const StyledJoinCallBox = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 5.2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  // width: 62.3rem;

  .join-meeting-title {
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;
    text-transform: capitalize;
  }
`;

export const StyledJoinCallExtraInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;

  .phone-number-extra-details {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .call-card-extra-info-item {
    .call-card-extra-info-item-value {
      // font-family: Red Hat Display !important;
      font-weight: 500;
      font-size: 1.4rem;
      color: #00171f;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: initial;
  }
`;
