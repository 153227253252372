import {Alert, Button, Table, Tag} from "antd";
import type {ColumnsType} from "antd/es/table";
import {useNavigate} from "react-router-dom";
import {AnalyticsEvent} from "../../../../utils/analytics";
import {StyledDescriptionText, StyledPageHeaderWrapper} from "../upcoming-calls.style";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";
import {useState} from "react";
import {useGetServices} from "../../../../react-query-hooks/service";
import {CustomPagination} from "../../../pagination";

interface DataType {
  key: string;
  name: string;
  price: number | string;
  currency: string;
  duration: string;
  sessions: string | null;
  status: string;
  description: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: "left",
    width: "17.425rem",
    key: "name",
    render: (text, record, index) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
        {record.status === "Active" ? (
          <Tag style={{marginTop: "1rem", fontWeight: "600"}} color="green">
            Active
          </Tag>
        ) : (
          <Tag style={{marginTop: "1rem", fontWeight: "600"}}>Paused</Tag>
        )}
      </>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    width: "17.425rem",
    key: "price",
    render: (text, record) => (
      <>
        <StyledDescriptionText>
          {record.price !== "0" && record.currency}{" "}
          {record.price === "0" ? "Free" : record.price}
        </StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Duration",
    dataIndex: "duration",
    width: "17.425rem",
    key: "duration",
    render: (text, record, index) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
        {record.sessions ? (
          <Tag style={{marginTop: "1rem", fontWeight: "600"}} color="purple">
            Bundle {record.sessions} sessions
          </Tag>
        ) : (
          <Tag style={{marginTop: "1rem", fontWeight: "600"}}>One off</Tag>
        )}
      </>
    ),
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   width: "17.425rem",
  //   key: "status",
  //   render: (text, record, index) => (
  //     <StyledDescriptionText>
  //       {text}
  //       {record.status}
  //       {index}
  //     </StyledDescriptionText>
  //   ),
  // },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    width: "17.425rem",
    render: (text) => {
      const stripedHtml = text.replace(/<[^>]+>/g, "");
      return <StyledDescriptionText>{stripedHtml}</StyledDescriptionText>;
    },
  },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space size="middle">
  //         <a>Invite {record.name}</a>
  //         <a>Delete</a>
  //       </Space>
  //     ),
  //   },
];

const ServicesSection = ({
  showCallCategoryModal,
}: {
  showCallCategoryModal: () => void;
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const {
    data: servicesQuery,
    isLoading: isLoadingServicesQuery,
    isFetching: isFetchingServicesQuery,
    error: servicesQueryErrorMessage,
    isPreviousData,
  } = useGetServices(page, 5);
  if (isLoadingServicesQuery) {
    return <>Loading....</>;
  }

  if (servicesQueryErrorMessage) {
    return (
      <Alert
        message="Error"
        description={servicesQueryErrorMessage.message}
        type="error"
        showIcon
      />
    );
  }

  const servicesData: DataType[] = servicesQuery.data.map((service) => ({
    key: service.id.toString(),
    name: service.name,
    price: service.callPrice.toLocaleString("en-US"),
    currency: service.callPriceCurrency,
    duration: service.callDuration + " minutes",
    status: service.statusName,
    sessions: service.no_of_sessions,
    description: service.description,
  }));

  const handleOnRow = (record: any) => {
    return {
      onClick: () => {
        if (record.sessions) {
          AnalyticsEvent(
            "Coach Services",
            `Clicked on ${record.name} bundle on service table`
          );
          navigate(`/dashboard/calls/bundle/edit/${record.key}`);
        } else {
          AnalyticsEvent(
            "Coach Services",
            `Clicked on ${record.name} service on service table`
          );
          navigate(`/dashboard/calls/edit/${record.key}`);
        }
      }, // click row
    };
  };

  const handleNewServiceBtnClick = () => {
    AnalyticsEvent("Coach calls", `Clicked on Add new service button`);
    showCallCategoryModal();
  };
  return (
    <>
      {/* {callState !== "empty" && ( */}
      <div style={{marginTop: "4rem"}}>
        <StyledPageHeaderWrapper>
          <StyledPageHeader>Services 💼</StyledPageHeader>
          <div>
            <Button
              onClick={handleNewServiceBtnClick}
              block
              size="large"
              shape="round"
              type="primary"
              htmlType="button"
            >
              Add new 1:1 service
            </Button>
          </div>
        </StyledPageHeaderWrapper>
        <Table
          onRow={handleOnRow}
          columns={columns}
          dataSource={servicesData}
          // pagination={tableParams.pagination}
          pagination={false}
          scroll={{x: 100}}
          // onChange={handleTableChange}
          loading={isFetchingServicesQuery}
        />
        <CustomPagination
          isPreviousData={isPreviousData}
          page={page}
          paginationMeta={servicesQuery.meta}
          setPage={setPage}
        />
      </div>
      {/* )} */}
    </>
  );
};

export {ServicesSection};
