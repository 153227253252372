import {Breadcrumb} from "antd";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {Link} from "react-router-dom";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";
import {ProductForm} from "../../../../components/products/product-form";

export function AddNewProductPage() {
  return (
    <DashboardWrapper pageTitle="Add Product">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/products">Digital product</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create new digital product</Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <ProductForm formType="new" />
        </div>
        <div className="widget-area"></div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
}
