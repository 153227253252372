import {Button, Space} from "antd";
import {DollarOutlined} from "@ant-design/icons";
import {
  StyledCustomerDashboardProductCard,
  StyledCustomerDashboardProductCardContent,
} from "./card.style";
import {useNavigate} from "react-router-dom";

type TCustomerDashboardProductCard = {
  product: {
    id: number;
    userId: number;
    title: string;
    slug: string;
    description: string;
    coverImage: string;
    hideFromProfile: boolean;
    is_deleted: boolean;
    statusId: number;
    reference: string;
    createdAt: string;
    updatedAt: string;
    amount: number;
    currency: string;
  };
};

export function CustomerDashboardProductCard({product}: TCustomerDashboardProductCard) {
  const navigate = useNavigate();
  let stripedHtml = product.description.replace(/<[^>]+>/g, "");
  const productDescription = stripedHtml;

  return (
    <StyledCustomerDashboardProductCard title={product.title}>
      <StyledCustomerDashboardProductCardContent>
        <div className="product-card-image-wrapper">
          <img className="product-card-image" src={product.coverImage} alt="product" />
        </div>
        <p className="product-card-info-text">{productDescription}</p>
        <div className="product-card-extra-info">
          <div>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              {product.amount < 1 ? (
                "Free"
              ) : (
                <span>{`${product.currency} ${product.amount}`}</span>
              )}
            </Space>
          </div>
        </div>
        <div className="product-card-start-btn">
          <Button
            shape="round"
            type="primary"
            size="large"
            onClick={() => navigate(`/dashboard/customer/products/details/${product.id}`)}
          >
            View details
          </Button>
        </div>
        {/* <div className="product-card-details-btn">
          <Button
            onClick={() => navigate(`/dashboard/customer/products/details/${product.id}`)}
            shape="round"
            block
            size="large"
          >
            View details
          </Button>
        </div> */}
      </StyledCustomerDashboardProductCardContent>
    </StyledCustomerDashboardProductCard>
  );
}
