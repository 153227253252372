import {Button, Space} from "antd";
import {
  StyledWidgetInfoBlock,
  StyledWidgetNewTag,
  StyledWidgetWrapper,
} from "../browse-events/browse-events.style";
import {DownloadOutlined} from "@ant-design/icons";

export function ProfileTemplateWidget() {
  return (
    <StyledWidgetWrapper>
      <h2 className="widget-heading">Free templates 🎨</h2>
      <div className="widget-card">
        <Space direction="vertical" size={12} style={{display: "flex"}}>
          <StyledWidgetInfoBlock style={{background: "#F6FFED"}}>
            <p>Download free Canva templates for your coaching journey</p>
          </StyledWidgetInfoBlock>
          <div style={{position: "relative"}}>
            <StyledWidgetNewTag>
              <p className="new-text">New</p>
            </StyledWidgetNewTag>
            <Button
              href="https://www.canva.com/design/DAF5Z9ooe10/qoeG520d4A2LXtXXvtUf0w/view?utm_content=DAF5Z9ooe10&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              block
              icon={<DownloadOutlined />}
              size="large"
              shape="round"
              target="_blank"
            >
              <span>2024 coaching planner</span>
            </Button>
          </div>
          <Button
            href="https://www.canva.com/design/DAFaHngVn98/tV-UrvNpsBErOvFQAH5DQQ/view?utm_content=DAFaHngVn98&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            block
            icon={<DownloadOutlined />}
            size="large"
            shape="round"
            target="_blank"
          >
            <span>Coachli profile templates</span>
          </Button>
        </Space>
      </div>
    </StyledWidgetWrapper>
  );
}
