import {useQueryClient} from "@tanstack/react-query";
import {Button} from "antd";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {userAccountActivationLogoutReset} from "../../../redux/auth/account-activation/account-activation-slice";
import {userResetPasswordLogoutReset} from "../../../redux/auth/forgot-password/forgot-password-slice";
import {
  logoutUser,
  userLoginLogoutReset,
} from "../../../redux/auth/login/user-login-slice";
import {userRegisterlogoutReset} from "../../../redux/auth/register/user-register-slice";
import {callLogoutReset} from "../../../redux/call/call-slice";
import {categoryLogoutReset} from "../../../redux/category/category-slice";
import {countryLogoutReset} from "../../../redux/country/countrySlice";
import {userProfileLogoutReset} from "../../../redux/profile/profile-slice";
import {AppDispatch} from "../../../redux/store";
import {userTimezoneLogoutReset} from "../../../redux/timezone/timezone-slice";
import {DashboardMenuData} from "../../../services/menu-data";
import {AnalyticsEvent} from "../../../utils/analytics";
import {NavLink} from "../nav-link/nav-link";
import {StyledLinksGroup} from "./nav-links-group.style";

const NavLinksGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.clear();
    dispatch(logoutUser());
    dispatch(userRegisterlogoutReset());
    dispatch(userResetPasswordLogoutReset());
    dispatch(userAccountActivationLogoutReset());
    dispatch(categoryLogoutReset());
    dispatch(countryLogoutReset());
    dispatch(userProfileLogoutReset());
    dispatch(userTimezoneLogoutReset());
    dispatch(callLogoutReset());
    dispatch(userLoginLogoutReset());
    AnalyticsEvent("Logout", "Coach clicked on logout button");
    navigate("/auth/login");
  };
  return (
    <StyledLinksGroup>
      {DashboardMenuData.map((menuItem, idx) => (
        <div className="nav-category" key={idx}>
          {menuItem?.title && <p className="nav-category-title">{menuItem?.title}</p>}
          {menuItem.navLink.map((link, idx) => (
            <NavLink
              key={idx}
              to={link.to}
              label={link.label}
              icon={link.icon}
              isComingSoon={link.isComingSoon}
              isBtnLink={link.isBtnLink}
            />
          ))}
        </div>
      ))}
      <div style={{margin: "5rem 0 10rem 0", padding: "0 1.4rem"}}>
        <Button
          size="large"
          block
          shape="round"
          type="default"
          htmlType="button"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </StyledLinksGroup>
  );
};

export {NavLinksGroup};
