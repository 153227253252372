import {
  ArrowLeftOutlined,
  UserOutlined,
  DollarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  CalendarOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {Select, Button, Avatar, Space} from "antd";
import moment from "moment";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useBookingData} from "../../../../container/booking/booking-context";
import {RootState} from "../../../../redux/store";
import {getTimeByTimeZone} from "../../../../services/helpers";
import {TIMEZONES} from "../../../../services/timezones";
import {
  StyledBookingLeftColumnMobile,
  StyledBookingLeftColumnMobileCoachInfoSection,
  StyledMobileCoachMainInfoSection,
  StyledMobileServiceInfoSection,
  StyledMobileServiceInfoDetailsSection,
  StyledCustomSelect,
} from "../left-column.style";

const {Option} = Select;

const ResheduleBookingLeftColumnMobile = ({currentStep}: {currentStep: number}) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const {setValues, data} = useBookingData();
  const {customerBooking} = useSelector((state: RootState) => state.booking);

  const tz = moment.tz.guess(true);

  const handleChange = (
    value: unknown
    // option: DefaultOptionType | BaseOptionType | (DefaultOptionType | BaseOptionType)[]
  ) => {
    // console.log(option["children"].split());
    setValues({...data, selectedTimeZone: value});
  };
  return (
    <StyledBookingLeftColumnMobile>
      {/* <div>
        <Button
          icon={<ArrowLeftOutlined />}
          className="back-link"
          onClick={() => navigate(-1)}
          type="link"
          size="large"
          style={{
            paddingLeft: 0,
            marginLeft: 0,
          }}
        >
          Back to profile
        </Button>
      </div> */}
      <StyledBookingLeftColumnMobileCoachInfoSection>
        <div>
          {customerBooking.mentor?.avatar ? (
            <Avatar size={96} src={customerBooking.mentor.avatar} />
          ) : (
            <Avatar size={96} icon={<UserOutlined />} />
          )}
        </div>
        <StyledMobileCoachMainInfoSection>
          <h2 className="coach-name">
            {customerBooking.mentor?.firstName} {customerBooking.mentor?.lastName}
          </h2>
          <p className="coach-role">{customerBooking.profile?.description}</p>
        </StyledMobileCoachMainInfoSection>
      </StyledBookingLeftColumnMobileCoachInfoSection>
      <StyledMobileServiceInfoSection>
        <h2 className="mobile-service-title">{customerBooking.service?.name}</h2>
        <div
          className="mobile-service-info"
          dangerouslySetInnerHTML={{
            __html: String(customerBooking.service?.description),
          }}
        />
        <StyledMobileServiceInfoDetailsSection>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="service-info-item-value">
                {customerBooking.service?.callPrice !== 0 &&
                  customerBooking.service?.callPriceCurrency}{" "}
                {customerBooking.service?.callPrice === 0
                  ? "Free"
                  : customerBooking.service?.callPrice?.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="service-info-item-value">
                {customerBooking.service?.callDuration} Minutes
              </span>
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              {customerBooking.service?.videoLocation ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="service-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="service-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
          {currentStep > 1 && (
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <CalendarOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {data.timeSelected}, {data.selectedDateString.toDateString()}
                </span>
              </Space>
            </div>
          )}

          <div className="service-card-extra-info-item">
            <Space size={10}>
              <GlobalOutlined style={{color: "#7939CC"}} />
              {/* <span className="service-info-item-value">
                {`(${getTimeZone("longOffset")})`} {customerBooking.timeZone} (
                {formatAMPM(new Date())})
              </span> */}
              {currentStep > 1 ? (
                <span className="service-info-item-value">
                  {data.selectedTimeZone ?? tz}
                </span>
              ) : (
                <StyledCustomSelect
                  bordered={false}
                  onChange={handleChange}
                  suffixIcon={
                    <DownOutlined
                      size={15}
                      style={{fontWeight: "bold", color: "#00171F"}}
                    />
                  }
                  size={"large"}
                  defaultValue={customerBooking.timeZone}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // console.log({option, input});
                    return (option!.children[3] as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {TIMEZONES.map((option, optionIdx: number | string) => (
                    <Option key={optionIdx} value={option.value}>
                      ({option.name}) {option.value} ({getTimeByTimeZone(option.value)})
                    </Option>
                  ))}
                </StyledCustomSelect>
              )}
            </Space>
          </div>
        </StyledMobileServiceInfoDetailsSection>
      </StyledMobileServiceInfoSection>
    </StyledBookingLeftColumnMobile>
  );
};

export {ResheduleBookingLeftColumnMobile};
