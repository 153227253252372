import styled from "styled-components";

export const StyledTestimonialItem = styled.div`
  background-color: #ffffff;
  border-radius: 1.6rem;
  padding: 3.2rem;
  margin-right: 3rem;
  height: 40.4rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    margin-right: 1rem;
  }
  @media (max-width: 500px) {
    padding: 3.2rem 2rem;
  }
`;
export const StyledTestimonialItemRatingWrapper = styled.div`
  display: flex;
  gap: 8px;
  color: #ffbf1c;
`;
export const StyledTestimonialItemReviewText = styled.div`
  margin-top: 2.7rem;

  .review-text {
    font-weight: 500;
    font-size: 2.4rem;
    color: #2f2d3b;
    height: 24rem;
    overflow-y: scroll;
    word-break: break-word;

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
`;
export const StyledTestimonialItemReviewerInfo = styled.div`
  margin-top: 1.4rem;
  display: grid;
  grid-template-columns: 4rem auto;
  // align-items: center;
  gap: 1.2rem;
  margin-top: auto;
  padding: 2rem 0;

  .reviewer-info-image-wrapper {
    border-radius: 36px;
    width: 4rem;
    height: 4rem;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 36px;
    }
  }

  .reviewer-info-personal-info {
    .reviewer-name {
      font-weight: 700;
      font-size: 1.4rem;
      color: #0f0049;
    }
    .reviewer-role {
      font-weight: 400;
      font-size: 1.4rem;
      color: #2f2d3b;
    }
  }
`;
