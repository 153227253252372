import {AnalyticsEvent} from "../../../utils/analytics";
import {AnimatedWords} from "../hero-section";
import {
  MotionLink,
  StyledGetStartedButton,
  StyledGetStartedSectionInfo,
  StyledGetStartedSectionWrapper,
} from "./get-started-section.style";

function GetStartedSection() {
  return (
    <StyledGetStartedSectionWrapper>
      <StyledGetStartedSectionInfo>
        <h2 className="main-title">
          <AnimatedWords title="Get started with Coachli today" />
        </h2>
        <p className="sub-title">
          <AnimatedWords title="Join 3,000+ coaches, entrepreneurs and creators monetising their knowledge efficiently with Coachli" />
        </p>
        <div>
          <MotionLink
            animate={{scale: 1.1}}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 1,
            }}
            onClick={() =>
              AnalyticsEvent("Waitlist section", `Clicked on Create a free account`)
            }
            to="/auth/register"
            style={{color: "#fff", display: "inline-block"}}
          >
            <StyledGetStartedButton>Create a free account</StyledGetStartedButton>
          </MotionLink>
        </div>
      </StyledGetStartedSectionInfo>
    </StyledGetStartedSectionWrapper>
  );
}

export {GetStartedSection};
