import moment from "moment";

type TUpdateSelectedTimezone = {
  type: "update-selected-timezone";
  payload: string;
};
type TUpdateSelectedDate = {
  type: "update-selected-date";
  payload: Date;
};
type TUpdateSelectedTime = {
  type: "update-selected-time";
  payload: string;
};
type TUpdateDateInfo = {
  type: "update-date-info";
  payload: {
    selectedDateString: Date;
    booking_date: number;
    booking_year: number;
    booking_month: number;
  };
};

type TResetTimeSelected = {
  type: "reset-time-selected";
};
type TResetState = {
  type: "reset-state";
};

export type TBookingActions =
  | TUpdateSelectedTimezone
  | TUpdateSelectedDate
  | TUpdateSelectedTime
  | TUpdateDateInfo
  | TResetTimeSelected
  | TResetState;

type TBookingState = {
  selectedTimeZone: string;
  booking_date: number;
  booking_year: number;
  booking_month: number;
  selectedDateString: Date;
  selectedCurrency?: string;
  timeSelected?: string;
};

export const initialBookingState: TBookingState = {
  selectedTimeZone: moment.tz.guess(true),
  booking_date: new Date().getDate(),
  booking_month: new Date().getMonth() + 1,
  booking_year: new Date().getFullYear(),
  selectedDateString: new Date(),
};

export const bookingReducer = (
  state: TBookingState = initialBookingState,
  action: TBookingActions
) => {
  if (action.type === "update-selected-timezone") {
    return {...state, selectedTimeZone: action.payload};
  }
  if (action.type === "update-selected-date") {
    return {...state, selectedDateString: action.payload};
  }
  if (action.type === "update-selected-time") {
    return {...state, timeSelected: action.payload};
  }
  if (action.type === "update-date-info") {
    return {
      ...state,
      selectedDateString: action.payload.selectedDateString,
      booking_date: action.payload.booking_date,
      booking_month: action.payload.booking_month,
      booking_year: action.payload.booking_year,
    };
  }
  if (action.type === "reset-time-selected") {
    return {
      ...state,
      timeSelected: undefined,
    };
  }
  if (action.type === "reset-state") {
    return {...initialBookingState};
  }

  return state;
};
