import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {IServiceData} from "./i-service";

// Get defualt user services
export const getDefaultUserServiceService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "services", config);

  return response.data;
};
// Get defualt user services
export const getAllUserServicesService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "services/all", config);

  return response.data;
};

// create service
export const createServiceService = async (serviceData: IServiceData, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // const payloadContainsNull = Object.values(serviceData).includes(null);

  const response = await axios.post(
    API_BASE_URL + "services/add/new-service",
    serviceData,
    config
  );

  return response.data;
};
// Update user service
export const updateUserServiceService = async (
  serviceData: IServiceData,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const payloadContainsNull = Object.values(serviceData).includes(null);

  const requestUrl = `${API_BASE_URL}services/${payloadContainsNull ? "1" : "0"}`;
  // console.log({requestUrl, payloadContainsNull, serviceData});
  const response = await axios.post(requestUrl, serviceData, config);

  return response.data;
};

// Get service by ID
export const getServiceByIdService = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + `service/id/${id}`, config);

  return response.data;
};
// Get service by name and slug
export const getServiceByMentorNameAndSlug = async (mentorName: string, slug: string) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  const response = await axios.get(
    API_BASE_URL + `booking/service/${mentorName}/${slug}`
  );

  return response.data;
};

// Update service by ID
export const updateServiceByIdService = async (
  serviceData: IServiceData,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    API_BASE_URL + `services/${serviceData.id}`,
    serviceData,
    config
  );

  return response.data;
};

// Delete service by ID
export const deleteServiceByIdService = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_BASE_URL + `service/${id}`, config);

  return response.data;
};
