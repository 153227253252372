import {Alert, Divider, Spin} from "antd";
import {Link} from "react-router-dom";
import {AuthFormWrapper} from "../../../layouts/auth-form-wrapper";
import {StyledInfoBlock} from "../../../layouts/auth-form-wrapper/auth-form-wrapper.style";
import {RegisterForm} from "./register-form";
import {GoogleButton} from "../../../components/google-button";
import {StyledFooterHref} from "../../../components/public-profile/footer/footer.style";
import {useGetCountries} from "../../../react-query-hooks/general";
import {FullPageLoader} from "../../../components/full-page-loader";
import {AnalyticsEvent} from "../../../utils/analytics";

const RegisterPage = () => {
  const getCountriesQuery = useGetCountries();
  return (
    <AuthFormWrapper>
      <div>
        <h2 className="form-main-heading">Create an account</h2>
        <p className="form-sub-heading">
          Already have an account?{" "}
          <Link
            onClick={() => AnalyticsEvent("Register page", `Clicked on Login link`)}
            to="/auth/login"
          >
            Log in
          </Link>
        </p>
      </div>
      <div>
        <GoogleButton type="register" />
      </div>

      <div>
        <Divider plain style={{margin: 0}}>
          <span style={{color: "#8C8C8C", fontWeight: 600, fontSize: "1.4rem"}}>Or</span>
        </Divider>
      </div>
      {getCountriesQuery.isLoading ? (
        <FullPageLoader />
      ) : getCountriesQuery.isError ? (
        <Alert
          message="Error"
          description={getCountriesQuery.error.message}
          type="error"
          showIcon
        />
      ) : (
        <Spin spinning={getCountriesQuery.isFetching}>
          <RegisterForm countries={getCountriesQuery.data} />
        </Spin>
      )}

      <StyledInfoBlock>
        <p className="info-block-text">
          By clicking create account, I acknowledge that I have read and do hereby accept
          the terms and conditions in the Coachli's{" "}
          <StyledFooterHref
            href="https://www.notion.so/coachli/TERMS-OF-SERVICES-12195aad0860444197b82e3c5b5e92f2"
            target="_blank"
            rel="noreferrer"
            onClick={() => AnalyticsEvent("Register page", `Clicked on Terms of service`)}
          >
            Terms of service
          </StyledFooterHref>
          , and{" "}
          <StyledFooterHref
            href="https://www.notion.so/coachli/PRIVACY-POLICY-6e6e3a3a65d141848e0053b62138d6bc"
            target="_blank"
            rel="noreferrer"
            onClick={() => AnalyticsEvent("Register page", `Clicked on Privacy policy`)}
          >
            Privacy policy
          </StyledFooterHref>
          .
        </p>
      </StyledInfoBlock>
    </AuthFormWrapper>
  );
};

export default RegisterPage;
