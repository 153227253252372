import {Button, Result, message} from "antd";
import {PublicProfileNavbar} from "../../../../../components/public-profile/navbar";
import {useLiveClassBookingContext} from "../../../../../context/live-class-booking-context";
import {StyledPublicProfileContainer} from "../../../../../styles/public-profile/public-profile";
import {Footer} from "../../../../../components/public-profile/footer";
import {NewSuccessStep} from "../../../../../container/live-classes/booking/new/success-step";
import {handleLiveClassLink} from "../../../../../utils/live-class";
import {Link, useNavigate} from "react-router-dom";
import {LiveClassSuccessDetailsCard} from "../../../../../components/public-profile/live-classes-section/details/success-details-card";
import {CalendarOutlined, ClockCircleOutlined, DollarOutlined} from "@ant-design/icons";
import {StyledSuccessBookingWrapper} from "../../../../../components/public-profile/live-classes-section/details/success-details-card/success-details-card.style";
import {useEffect} from "react";
import {
  handleFacebookLink,
  handleTwitterLink,
  handleMailLink,
} from "../../../../../utils/products";

export function LiveClassSuccessBooking() {
  const {state, dispatch} = useLiveClassBookingContext();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state.eventData?.event.url_redirect) {
      const redirectLink = state.eventData.event.url_redirect.startsWith("http")
        ? state.eventData.event.url_redirect
        : `//${state.eventData.event.url_redirect}`;
      message.info("Redirecting ...", 2, () =>
        setTimeout(() => {
          window.open(redirectLink, "_blank", "noopener noreferrer");
        }, 0)
      );
    }
  }, [state.eventData?.event.url_redirect]);

  if (!state.eventData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link to={`/${state.urlParams.username}`}>Back to profile</Link>
          </Button>,
        ]}
      />
    );
  }
  if (!state.liveClassBookingApiResponseData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some booking parameters!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link
              to={handleLiveClassLink({
                eventId: String(state.eventData.event.id),
                slug: state.eventData.event.slug,
                username: state.eventData.username,
              })}
            >
              Book event
            </Link>
          </Button>,
        ]}
      />
    );
  }
  function handleGotoDashboard() {
    dispatch({type: "reset-state"});
    navigate(`/auth/customer/login`);
  }
  function handleGotoProfile() {
    dispatch({type: "reset-state"});
    navigate(`/${state.eventData?.username}`);
  }
  const extraParams = {
    username: state.eventData.username,
    emailAddress: state.liveClassFormValues?.emailAddress,
    firstName: state.eventData.firstName,
    lastName: state.eventData.lastName,
    shareCopyLink: handleLiveClassLink({
      eventId: state.eventData.event.id,
      slug: state.eventData.event.slug,
      username: state.eventData.username,
      windowNav: true,
    }),
    handleGotoDashboard,
    handleGotoProfile,
  };
  const socialLinks = {
    facebookLink: handleFacebookLink({
      username: state.eventData.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just booked a live class by ${state.eventData.firstName} ${state.eventData.lastName} titled "${state.eventData.event.name}" on Coachli!`,
      username: state.eventData.username,
    }),
    mailLink: handleMailLink({
      body: `I just booked a live class by ${state.eventData.firstName} ${state.eventData.lastName} titled "${state.eventData.event.name}" on Coachli! ${window.location.origin}/${state.eventData.username}`,
      subject: "",
    }),
  };
  const successContent = {
    successTitle: "Booking successful",
    successMessage: (
      <span>
        We have sent a confirmation and a calendar invitation with information on how to
        attend the session to <strong>{state.liveClassFormValues?.emailAddress}</strong>.
      </span>
    ),
    successShareMessage: (
      <p className="info-text">
        Let people know you just booked a session with{" "}
        <strong>{state.eventData.firstName}</strong>{" "}
        <strong>{state.eventData.lastName}</strong>
      </p>
    ),
  };
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "96.6rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.eventData.avatar}
            firstName={state.eventData.firstName}
            lastName={state.eventData.lastName}
            username={state.eventData.username}
          />
          <StyledSuccessBookingWrapper>
            <div style={{width: "100%"}}>
              <LiveClassSuccessDetailsCard
                title={state.eventData.event.name}
                extraInfo={[
                  {
                    icon: <DollarOutlined style={{color: "#7939CC"}} />,
                    value: `${
                      state.eventData.event.callPrice === 0
                        ? "Free"
                        : `${
                            state.liveClassBookingApiResponseData?.currency
                          } ${state.liveClassBookingApiResponseData?.amount.toLocaleString(
                            "en-US"
                          )}`
                    }`,
                  },
                  {
                    icon: <CalendarOutlined style={{color: "#7939CC"}} />,
                    value: new Date(
                      state.eventData.event.activeSession.startDate
                    ).toDateString(),
                  },
                  {
                    icon: <ClockCircleOutlined style={{color: "#7939CC"}} />,
                    value: `${state.eventData.event.activeSession.startTime} -
                    ${state.eventData.event.activeSession.endTime}`,
                  },
                ]}
                imageSrc={state.eventData.event.cover_image}
              />
            </div>
            <div>
              <NewSuccessStep
                successContent={successContent}
                extraParams={extraParams}
                socialLinks={socialLinks}
              />
            </div>
          </StyledSuccessBookingWrapper>
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
}
