import {DollarOutlined, CalendarOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {Space, Button, Tag, Tooltip} from "antd";
import {StyledCard, StyledClassCardContent} from "./card.style";
import {useNavigate} from "react-router-dom";
import {
  TLiveClassApiResponse,
  useJoinLiveClass,
} from "../../../../react-query-hooks/live-events/live-classes";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {handleMeetingLink} from "../../../../utils/meeting";
import {LiveClassCardMenu} from "./card-menu";
import {handleLiveClassLink} from "../../../../utils/live-class";
import moment from "moment";

export type TLiveClassCardProps = Pick<
  TLiveClassApiResponse,
  | "name"
  | "description"
  | "callPrice"
  | "callPriceCurrency"
  | "activeSession"
  | "attendees"
  | "cover_image"
  | "statusName"
  | "slug"
> & {
  eventId: number;
  is_co_host?: boolean;
  hostUsername?: string;
  showDrawer?: (eventId: string | number) => void;
};

export function LiveClassCard({
  name,
  description,
  hostUsername,
  callPrice,
  callPriceCurrency,
  activeSession,
  attendees,
  cover_image,
  eventId,
  statusName,
  slug,
  is_co_host,
  showDrawer,
}: TLiveClassCardProps) {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const navigate = useNavigate();

  const joinLiveClassQueryMutation = useJoinLiveClass();

  function handleJoinLiveClass() {
    joinLiveClassQueryMutation.mutate(eventId, {
      onSuccess: (data) => {
        setTimeout(() => {
          if (data.data.videoPlatform.toLowerCase() === "dailyco") {
            window.open(`${data.data.dailyCoUrl}?t=${data.data.bookingToken}`);
          } else {
            window.open(
              handleMeetingLink("host", {
                token: data.data.bookingToken,
                roomId: data.data.event.roomId,
                firstName: userInfo?.user?.firstName,
                lastName: userInfo?.user?.lastName,
                meetingTitle: data.data.event.name,
              })
            );
          }
        }, 0);
      },
    });
  }

  // const disableButton = () => {
  //   if (statusName.toLowerCase() !== "in progress") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  let stripedHtml = description.replace(/<[^>]+>/g, "");
  const eventDescription = stripedHtml;

  const classDate = moment(activeSession?.startDate).format("ddd, MMM DD YYYY");

  return (
    <StyledCard
      title={name}
      extra={<LiveClassCardMenu eventId={eventId} showDrawer={showDrawer} />}
    >
      <StyledClassCardContent>
        <div className="class-card-image-wrapper">
          <img className="class-card-image" src={cover_image} alt={name} />
        </div>
        <p className="class-card-info-text">{eventDescription}</p>
        {/* <div
          className="class-card-info-text"
          dangerouslySetInnerHTML={{__html: description}}
        /> */}
        {/* <p>{name ?? "This is a draft name"}</p> */}
        <div className="class-card-extra-info">
          <div>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span>
                {callPrice === 0
                  ? "Free"
                  : callPriceCurrency + " " + callPrice.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div>
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span>
                {classDate}, {activeSession?.startTime}
              </span>
            </Space>
          </div>
          <div>
            <Space size={10}>
              <UsergroupAddOutlined style={{color: "#7939CC"}} />
              <span>{attendees === -1 ? "Unlimited" : attendees} attending</span>
            </Space>
          </div>
        </div>
        <div className="reg-tag">
          {is_co_host && (
            <div>
              <Tag color="green">co-host</Tag>
            </div>
          )}
          <div>
            {/* <Tag color="green">Co-host</Tag> */}
            {statusName === "Paused" && <Tag>Registration closed</Tag>}
          </div>
        </div>

        <Tooltip title={"Start class"}>
          <div className="class-card-start-btn">
            <Button
              onClick={handleJoinLiveClass}
              shape="round"
              type="primary"
              block
              size="large"
              loading={joinLiveClassQueryMutation.isLoading}
            >
              {is_co_host ? "Join class" : "Start class"}
            </Button>
          </div>
        </Tooltip>
        <div className="class-card-details-btn">
          {is_co_host && hostUsername ? (
            <Button
              shape="round"
              block
              size="large"
              href={handleLiveClassLink({
                eventId,
                slug,
                username: hostUsername,
              })}
              target="_blank"
            >
              View details
            </Button>
          ) : (
            <Button
              onClick={() => {
                navigate(`/dashboard/live-classes/edit/${eventId}?formType=edit`);
              }}
              shape="round"
              block
              size="large"
            >
              View details
            </Button>
          )}
        </div>
      </StyledClassCardContent>
    </StyledCard>
  );
}
