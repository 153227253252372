import styled from "styled-components";

export const StyledWhatMakesUsSpecialItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.4rem;

  .icon-wrapper {
    border-radius: 1.2rem;
    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 2.1rem;
    }
  }
  .item-content {
    .item-content-title {
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      color: #0f0049;
    }
    .item-content-text {
      margin-top: 8px;
      font-size: 1.6ren;
      text-align: center;
      color: #2f2d3b;
    }
  }
`;
