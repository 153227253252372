import {Avatar} from "antd";
import {useMessageContext} from "../../../../../context/message-context";
import {TCustomerMessageResObj} from "../../../../../react-query-hooks/messages";
import {capitalizeFirstLetter} from "../../../../../services/helpers";
import {StyledMessageCustomerListItem} from "../message-customer-list-item.style";

const CustomerMessageCustomerListItem: React.FC<{
  contact: TCustomerMessageResObj;
}> = ({contact}) => {
  const {state, dispatch} = useMessageContext();

  return (
    <StyledMessageCustomerListItem
      onClick={() =>
        dispatch({
          type: "set-customer-active-message",
          payload: {
            activeMessage: contact,
          },
        })
      }
      isActive={
        contact.mentorName + contact.mentorEmailAddress.toLowerCase() ===
        (((state.customerActiveMessage?.mentorName as string) +
          state.customerActiveMessage?.mentorEmailAddress.toLowerCase()) as string)
      }
    >
      {/* <StyledMessageCustomerListItem> */}
      <div className="avatar-name-container">
        <Avatar className="avatar-comp" size="large">
          {contact.mentorName.split(" ")[1].charAt(0).toUpperCase()}
          {contact.mentorName.split(" ")[0].charAt(0).toUpperCase()}
        </Avatar>
        <span className="message-customer-list-item-name">
          {capitalizeFirstLetter(contact.mentorName.split(" ")[1])}{" "}
          {capitalizeFirstLetter(contact.mentorName.split(" ")[0])}
          {/* lIBERY CHANG */}
        </span>
      </div>
      <span className="message-cutomer-list-item-time">
        {!contact.isReadCustomer && (
          <div
            style={{
              width: ".8rem",
              height: ".8rem",
              borderRadius: "50%",
              backgroundColor: "#9747ff",
              marginLeft: "1rem",
            }}
          />
        )}
      </span>
    </StyledMessageCustomerListItem>
  );
};

export {CustomerMessageCustomerListItem};
