import {Spin} from "antd";
import {SVG2} from "../icons/icons";

const FullPageLoader = () => {
  return (
    <Spin
      spinning
      indicator={
        // <div>
        <SVG2 />
        // </div>
      }
    >
      <div
        style={{
          height: "100vh",
        }}
      ></div>
    </Spin>
  );
};

export {FullPageLoader};
