import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {
  imageAnimation,
  riseWithFade,
} from "../../../utils/animations/home/home-animations";
import {StyledContainer} from "../features-section/features-section.style";
import {StyledWaitListBtnContainer} from "../hero-section/hero-section.style";
import {StyledWaitListButton} from "../waitlist-section/waitlist-section.style";
import {
  StyledBannerSection,
  StyledBannerSectionContent,
  StyledBannerSectionImageWrapper,
} from "./banner-section.style";
import {AnimatedWords} from "../hero-section";
import {motion} from "framer-motion";
import {Button} from "antd";

function BannerSection() {
  return (
    <StyledBannerSection>
      <StyledContainer>
        <StyledBannerSectionContent>
          <h1 className="banner-main-heading">
            <AnimatedWords title="Host your 1:1 sessions, live classes, and sell digital products" />
          </h1>
          <motion.p
            initial="initial"
            whileInView="animate"
            viewport={{once: false}}
            style={{overflow: "hidden"}}
            variants={riseWithFade}
            className="banner-sub-heading"
          >
            One place to monetise. With Coachli, you can host paid one-on-one sessions,
            offer live instructional sessions, and sell digital products to your target
            audience.
          </motion.p>
          <StyledWaitListBtnContainer
            initial="initial"
            whileInView="animate"
            viewport={{once: false, amount: 0.1}}
            variants={riseWithFade}
            style={{marginTop: "1.9rem", overflow: "hidden"}}
            onClick={() =>
              AnalyticsEvent("Home Banner", `Clicked on Create a free account`)
            }
          >
            <Link to="/auth/register" style={{color: "#fff"}}>
              <Button
                className="styled-waitlist-hero-btn"
                shape="round"
                size="large"
                type="primary"
                // style={{backgroundColor: "#9747ff", color: "#fff"}}
              >
                Create a free account
              </Button>
            </Link>

            <Button
              href="https://www.coachli.co/titilayo/meetings/one-to-one-with-coachli/740"
              target="_blank"
              shape="round"
              size="large"
              className="styled-demo-btn"
            >
              Book a free demo
            </Button>
          </StyledWaitListBtnContainer>
          <StyledBannerSectionImageWrapper
            // initial={{
            //   scale: 0,
            //   opacity: 0,
            // }}
            // animate={{
            //   scale: 1,
            //   opacity: 1,
            // }}
            // transition={{
            //   duration: 1,
            // }}

            variants={imageAnimation}
            initial="initial"
            whileInView="animate"
            viewport={{once: false}}
          >
            {/* <picture>
              <source srcSet="/images/banner-img.webp" /> */}
            <img
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "inline-block",
                // borderRadius: "1.7rem",
              }}
              src="/images/banner-image.png"
              alt="hero"
            />
            {/* </picture> */}
            {/* <picture>
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  display: "inline-block",
                  borderRadius: "1.7rem",
                }}
                src="/images/banner-img.webp"
                alt="hero"
              />
            </picture> */}
          </StyledBannerSectionImageWrapper>
        </StyledBannerSectionContent>
      </StyledContainer>
    </StyledBannerSection>
  );
}

export {BannerSection};
