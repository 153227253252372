import {Row, Col, Skeleton} from "antd";
import {StyledCustomersDataBox} from "../../../styles/customers/customers";

const CustomerMetricsSkeleton = () => {
  return (
    <Row gutter={[32, 20]}>
      <Col span={24} md={8}>
        <StyledCustomersDataBox>
          <Skeleton.Input block active />
          <br />

          <Skeleton.Button active style={{marginTop: "1rem"}} />
        </StyledCustomersDataBox>
      </Col>
      <Col span={24} md={8}>
        <StyledCustomersDataBox>
          <Skeleton.Input block active />
          <br />

          <Skeleton.Button active style={{marginTop: "1rem"}} />
        </StyledCustomersDataBox>
      </Col>
      <Col span={24} md={8}>
        <StyledCustomersDataBox>
          <Skeleton.Input block active />
          <br />

          <Skeleton.Button active style={{marginTop: "1rem"}} />
        </StyledCustomersDataBox>
      </Col>
    </Row>
  );
};

export {CustomerMetricsSkeleton};
