import {Skeleton} from "antd";
import {StyledUpcomingCard} from "../card/card.style";

export function UpcomingEventsSkeletonLoader() {
  return (
    <StyledUpcomingCard>
      <Skeleton.Image style={{width: "100%"}} active />
      <br />
      <Skeleton.Input style={{width: "100%"}} active size="small" />
      <br />
      <Skeleton.Input style={{width: "100%"}} active size="small" />
      <br />
      <Skeleton.Input style={{width: "100%"}} active size="small" />
      <br />
      <div className="hr-line" />
      <div style={{width: "50%", margin: "0 auto"}}>
        <Skeleton.Input active size="small" />
      </div>
    </StyledUpcomingCard>
  );
}
