import {CopyOutlined, MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu, MenuProps} from "antd";
import {useState} from "react";
import {CustomCheckboxInput} from "../../checkbox";
import {StyledMenuDropdown} from "./time-copy-filter.style";
import {TIME_COPY_FILTER_OPTIONS} from "./time-copy-filter-options";

const TimeCopyFilter = ({
  copyFilterControl,
  timePickerName,
  handleCopy,
}: {
  copyFilterControl: any;
  timePickerName: string;
  handleCopy: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "8") {
      setOpen(false);
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const menu = (
    <StyledMenuDropdown
      onClick={handleMenuClick}
      items={TIME_COPY_FILTER_OPTIONS.map((option, optionIdx) =>
        option.value === "copy-text"
          ? {
              label: <p>Copy times to...</p>,
              key: "0",
            }
          : timePickerName !== option.value && option.value !== "apply-btn"
          ? {
              label: (
                <div className="copy-filter-checkbox-wrapper">
                  <CustomCheckboxInput
                    control={copyFilterControl}
                    id={option.value}
                    name={option.value}
                    error={""}
                    label={option.label}
                  />
                </div>
              ),
              key: optionIdx,
            }
          : option.value === "apply-btn"
          ? {
              label: (
                <div className="copy-filter-checkbox-wrapper">
                  <Button
                    onClick={handleCopy}
                    block
                    shape="round"
                    type="default"
                    htmlType="submit"
                    size="large"
                  >
                    {option.label}
                  </Button>
                </div>
              ),
              key: optionIdx,
            }
          : null
      )}
    />
  );
  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={handleOpenChange}
        visible={open}
      >
        <Button
          onClick={(e) => e.preventDefault()}
          className="add-time-btn"
          icon={<CopyOutlined style={{fontSize: "1.6rem"}} />}
          type="primary"
          style={{backgroundColor: "#F5EDFF", border: "none", color: "#9747FF"}}
          shape="circle"
          size="large"
        />
      </Dropdown>
    </>
  );
};

export {TimeCopyFilter};
