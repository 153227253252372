import {CheckCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {
  StyledPricingBox,
  StyledPricingBoxBottom,
  StyledPricingBoxTop,
  StyledPricingOption,
} from "./pricing.style";
import {Button, Select} from "antd";
import {NigeriaFlagIcon} from "../../icons/icons";
import {
  PricingOptionValues,
  africanCurrencies,
  pricingOptions,
} from "../../../services/home/pricing-options";
import {ReactNode, useState} from "react";

const Pricing = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<PricingOptionValues>("naira");
  // const [selectedCurrencyOption, setSelectedCurrencyOption] = useState("naira");
  const handleChange = (value: {
    disabled: any;
    key: string;
    label: ReactNode;
    value: string;
  }) => {
    // console.log(`selected`, value);
    const isNaira = value.value === "naira";
    const isAfrican = africanCurrencies.includes(value.value);

    const valueToSet = isNaira ? "naira" : isAfrican ? "african" : "stripe";
    // setSelectedCurrencyOption(value.value);
    setSelectedCurrency(valueToSet);
  };

  const rates = {
    naira: {
      single: "5% + 50 NGN",
      liveClass: "5% + 50 NGN",
      products: "4% + 50 NGN",
    },
    stripe: {
      single: "9% + 30cents",
      liveClass: "9% + 30cents",
      products: "9% + 30cents",
    },
    african: {
      single: "6%",
      liveClass: "6%",
      products: "6%",
    },
  };
  return (
    <>
      <StyledPricingBox>
        <StyledPricingBoxTop>
          <div
            style={{
              backgroundColor: "#fff",
              padding: "2.4rem",
              width: "100%",
            }}
          >
            <Select
              defaultValue={{
                disabled: undefined,
                key: "Nigerian Naira",
                label: (
                  <StyledPricingOption>
                    <NigeriaFlagIcon className="pricing-icon-flag" />
                    <span className="pricing-option-label">Nigerian Naira</span>
                  </StyledPricingOption>
                ),
                value: "naira",
              }}
              size="large"
              style={{width: "100%"}}
              onChange={handleChange}
              // value={selectedCurrencyOption}
              labelInValue
              // options={pricingOptions}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              filterOption={(input, option) => {
                return option?.key.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {pricingOptions.map((item) => (
                <Select.Option
                  key={item.label}
                  value={item.value}
                  label={
                    <StyledPricingOption>
                      {item.flag}
                      <span className="pricing-option-label">{item.label}</span>
                    </StyledPricingOption>
                  }
                >
                  <StyledPricingOption>
                    {item.flag}
                    <span className="pricing-option-label">{item.label}</span>
                  </StyledPricingOption>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{backgroundColor: "#fff", padding: "2.4rem", width: "100%"}}>
            <div
              style={{
                display: "flex",
                gap: "5rem",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4rem",
                  alignItems: "center",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <p style={{fontSize: "1.8rem", fontWeight: "600"}}>1:1 Sessions</p>
                <p style={{fontSize: "2.4rem", fontWeight: "400"}}>
                  {rates[selectedCurrency].single}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4rem",
                  alignItems: "center",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <p style={{fontSize: "1.8rem", fontWeight: "600"}}>Live classes</p>
                <p style={{fontSize: "2.4rem", fontWeight: "400"}}>
                  {rates[selectedCurrency].liveClass}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4rem",
                  alignItems: "center",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <p style={{fontSize: "1.8rem", fontWeight: "600"}}>Digital products</p>
                <p style={{fontSize: "2.4rem", fontWeight: "400"}}>
                  {rates[selectedCurrency].products}
                </p>
              </div>
            </div>
          </div>
          <div style={{marginTop: "0"}}>
            <Link to="/auth/register">
              <Button
                onClick={() =>
                  AnalyticsEvent("Pricing page", `Clicked on Create a free account`)
                }
                type="primary"
                size="large"
                shape="round"
              >
                Become a coach today
              </Button>
            </Link>
          </div>
        </StyledPricingBoxTop>
      </StyledPricingBox>
      <StyledPricingBoxBottom>
        <ul className="pricing-box-bottom-list">
          <li className="pricing-box-bottom-list-item">
            <span style={{marginRight: "1.2rem"}}>
              <CheckCircleOutlined style={{fontSize: "2rem", color: "#46BEA3"}} />
            </span>
            Unlimited clients and services
          </li>
          <li className="pricing-box-bottom-list-item">
            <span style={{marginRight: "1.2rem"}}>
              <CheckCircleOutlined style={{fontSize: "2rem", color: "#46BEA3"}} />
            </span>
            Google Calendar integration
          </li>
          <li className="pricing-box-bottom-list-item">
            <span style={{marginRight: "1.2rem"}}>
              <CheckCircleOutlined style={{fontSize: "2rem", color: "#46BEA3"}} />
            </span>
            1:1 Video and phone calls
          </li>
          <li className="pricing-box-bottom-list-item">
            <span style={{marginRight: "1.2rem"}}>
              <CheckCircleOutlined style={{fontSize: "2rem", color: "#46BEA3"}} />
            </span>
            Email and chat support
          </li>
          <li className="pricing-box-bottom-list-item">
            <span style={{marginRight: "1.2rem"}}>
              <CheckCircleOutlined style={{fontSize: "2rem", color: "#46BEA3"}} />
            </span>
            No monthly or hidden fees
          </li>
        </ul>
      </StyledPricingBoxBottom>
    </>
  );
};

export {Pricing};
