import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {Button, message, Space} from "antd";
import {CustomInputComponent} from "../../../components/input/input-component";
import {AuthFormWrapper} from "../../../layouts/auth-form-wrapper";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {ResetSuccessComponent} from "./reset-success";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {AppDispatch, RootState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {
  forgotPassword,
  resetUserResetPasswordState,
} from "../../../redux/auth/forgot-password/forgot-password-slice";

const forgotPasswordchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
});
const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [resetSuccess, setResetSuccess] = useState(false);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<{email: string}>({
    resolver: yupResolver(forgotPasswordchema),
    mode: "onChange",
  });

  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    isSuccess,
    isError,
    message: userResetPasswordMessage,
  } = useSelector((state: RootState) => state.userResetPassword);

  useEffect(() => {
    dispatch(resetUserResetPasswordState());
  }, [dispatch]);
  useEffect(() => {
    if (isError) {
      message.error(userResetPasswordMessage);
    }
  }, [isError, userResetPasswordMessage]);

  const onSubmit = (values: {email: string}) => {
    dispatch(forgotPassword(values.email));
  };
  return (
    <AuthFormWrapper>
      {isSuccess ? (
        <ResetSuccessComponent />
      ) : (
        <>
          <div>
            <h2 className="form-main-heading">Reset password</h2>
            <p className="form-sub-heading">
              No worries, we will send you reset instructions to your email
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={29} style={{display: "flex"}}>
              <CustomInputComponent
                id="email"
                name="email"
                label="Email address"
                type="email"
                placeholder="Please enter your email address"
                error={errors.email?.message}
                control={control}
              />
              <div>
                <Button
                  disabled={
                    Object.keys(errors).length > 0 || Object.values(watch()).length < 1
                  }
                  loading={isLoading}
                  block
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Reset password
                </Button>
              </div>
              <div>
                <Button
                  icon={<ArrowLeftOutlined />}
                  type="link"
                  block
                  onClick={() => navigate("/auth/login")}
                >
                  Back to log in
                </Button>
              </div>
            </Space>
          </form>
        </>
      )}
    </AuthFormWrapper>
  );
};

export default ForgotPasswordPage;
