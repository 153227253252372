import {Spin, Divider, Space, message} from "antd";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RescheduleCustomCalendar} from "../../../../components/calendar/reshedule/calendar";
// import {RescheduleCustomCalendar} from "../../../../components/calendar/reschedule/calendar";
import {AppDispatch, RootState} from "../../../../redux/store";
import {
  StyledBookingRightColumnHeader,
  StyledBookingRightColumnWrapper,
  StyledBookingTimeComponent,
  StyledBookingTimeComponentItemWrapper,
  StyledBookingTimeComponentButton,
  StyledBookingTimeComponentConfirmButton,
} from "../../../../styles/booking/booking";
import {useBookingData} from "../../booking-context";
import {IBookingStepProps} from "../../i-step";
import {useParams} from "react-router-dom";
import {
  customerRescheduleBooking,
  resetUserBookingUpdatesState,
} from "../../../../redux/booking/booking-slice";
import moment from "moment";

const RescheduleBookingStep1: React.FC<IBookingStepProps> = ({updateStep}) => {
  const {setValues, data} = useBookingData();
  const [showConfirm, setShowConfirm] = useState("");
  const {timeSlots, timeSlotsFetchStatus} = useSelector(
    (state: RootState) => state.booking
  );
  // const loading = true;

  const {reference} = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const {
    customerBooking,
    customerRescheduleBookingStatus,
    message: createErrorMesage,
  } = useSelector((state: RootState) => state.booking);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (customerRescheduleBookingStatus === "rejected") {
      message.error(createErrorMesage);
    }
  }, [createErrorMesage, customerRescheduleBookingStatus]);

  useEffect(() => {
    if (customerRescheduleBookingStatus === "resolved") {
      message.success("Your Booking has been created successfully!");
      updateStep(3);
    }
  }, [customerRescheduleBookingStatus, updateStep]);

  useEffect(() => {
    return () => {
      dispatch(resetUserBookingUpdatesState());
    };
  }, [dispatch]);

  const tz = moment.tz.guess(true);

  const handleConfirm = () => {
    const payload = {
      bookingReference: reference,
      mentor_timezone: customerBooking.timeZone,
      user_timezone: data.selectedTimeZone ?? tz,
      booking_date: data.booking_date,
      booking_year: data.booking_year,
      booking_month: data.booking_month,
      timeSlot: showConfirm,
    };
    setValues({...data, ...payload});
    dispatch(customerRescheduleBooking(payload));
  };
  return (
    <div>
      <StyledBookingRightColumnHeader>
        Select date and time
      </StyledBookingRightColumnHeader>
      <StyledBookingRightColumnWrapper>
        <div>
          <RescheduleCustomCalendar />
        </div>
        {timeSlotsFetchStatus === "pending" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65vh",
              width: "100%",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {timeSlotsFetchStatus === "resolved" && (
          <StyledBookingTimeComponent id="time-component">
            <div className="time-component-heading">
              {data.selectedDateString.toDateString()}
            </div>
            <Divider style={{marginTop: "1.8rem", marginBottom: "2.4rem"}} />
            <Space size={8} direction="vertical" style={{display: "flex"}}>
              {timeSlots.map((timeSlot, idx) => (
                <StyledBookingTimeComponentItemWrapper key={idx}>
                  <StyledBookingTimeComponentButton
                    block
                    size="large"
                    onClick={() => setShowConfirm(timeSlot)}
                  >
                    {timeSlot}
                  </StyledBookingTimeComponentButton>
                  {showConfirm === timeSlot && (
                    <StyledBookingTimeComponentConfirmButton
                      block
                      size="large"
                      onClick={handleConfirm}
                      loading={customerRescheduleBookingStatus === "pending"}
                    >
                      Confirm
                    </StyledBookingTimeComponentConfirmButton>
                  )}
                </StyledBookingTimeComponentItemWrapper>
              ))}
            </Space>
          </StyledBookingTimeComponent>
        )}
      </StyledBookingRightColumnWrapper>
    </div>
  );
};

export {RescheduleBookingStep1};
