import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: #101a29;
  //   padding: 8rem 0 0 0;
  color: #fff;
`;
export const StyledFooterItem = styled.div`
  .footer-item-title {
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .footer-item-list {
    margin-top: 3rem;
    list-style-type: none;
    padding: 0;
    .footer-item-list-item {
      margin-bottom: 1rem;
      .footer-item-list-item-link {
        color: #ffffff78;
        font-size: 1.6rem;
        font-weight: 400;
        &:hover {
          color: #fff;
        }
      }
    }
  }
`;
