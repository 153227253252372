import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {HelmetProvider} from "react-helmet-async";
import {message} from "antd";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      // console.log({error, query});
      if (query.state.data !== undefined) {
        if (error instanceof Error) {
          message.error(`Something went wrong: ${error.message}`);
        } else {
          message.error(`Something went wrong!`);
        }
      }
    },
  }),
});
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Router>
            <ReactQueryDevtools initialIsOpen={false} />
            <App />
          </Router>
        </HelmetProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
