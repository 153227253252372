import {PropsWithChildren} from "react";
import {createContext} from "../create-context";
import {FullPageLoader} from "../../components/full-page-loader";
import {Button, Result} from "antd";
import {useGetProfile} from "../../react-query-hooks/profile";
import {TProfileApiResponse} from "../../redux/profile/i-profile-1";

type TGetProfileState = {
  profileData: TProfileApiResponse;
};

type TGetProfileContextState = {
  state: TGetProfileState;
};

const [useContext, ContextProvider] = createContext<TGetProfileContextState>();

export const useGetProfileContext = useContext;

export const GetProfileProvider = ({children}: PropsWithChildren) => {
  const getProfileQuery = useGetProfile();

  if (getProfileQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getProfileQuery.isError) {
    return (
      <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle="Error fetching profile"
        extra={
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => getProfileQuery.refetch()}
          >
            Try again
          </Button>
        }
      />
    );
  }

  const state: TGetProfileState = {
    profileData: getProfileQuery.data,
  };

  return <ContextProvider value={{state}}>{children}</ContextProvider>;
};
