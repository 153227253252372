import {Alert, Button, Col, Modal, Row, Space, Spin, Table, message} from "antd";
import {
  TLiveClassApiResponse,
  useGetPastLiveClasses,
  useLiveClassAttendeeExportCSV,
} from "../../../react-query-hooks/live-events/live-classes";
import {FullPageLoader} from "../../full-page-loader";
import {
  StyledDescriptionText,
  StyledNoUpcomingCallBox,
} from "../../calls/upcoming-calls/upcoming-calls.style";
import {ColumnsType} from "antd/lib/table";
import {useState} from "react";
import {
  StyledDescriptionTextValue,
  StyledInfoItem,
} from "../../customers/details-page/info-section/info-section.style";
import {StyledModalContentWrapper} from "../../calls/upcoming-calls/call-card/call-card.style";
import {LoadingSpinner} from "../../icons/icons";
import {DownloadOutlined} from "@ant-design/icons";
import {CustomPagination} from "../../pagination";

interface DataType {
  key: string;
  name: string;
  price: string;
  description: string;
}

const PastLiveClasses = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [modalContent, setModalContent] = useState<TLiveClassApiResponse>();

  const handleExportAsCSV = () => {
    if (modalContent) {
      exportCsvQueryMutation.mutate(`${modalContent.id}`, {
        onSuccess: (data) => {
          message.success("File ready to be downloaded!");
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${modalContent.name}.csv`);
          document.body.appendChild(link);
          link.click();
          setIsModalOpen(false);
        },
      });
    }
  };

  const handleOk = () => {
    handleExportAsCSV();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getLiveClassesQuery = useGetPastLiveClasses(page);
  const exportCsvQueryMutation = useLiveClassAttendeeExportCSV();
  if (getLiveClassesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getLiveClassesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getLiveClassesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const pastClassData: DataType[] = getLiveClassesQuery.data.data.map((pastClass) => ({
    key: pastClass.id.toString(),
    name: pastClass.name,
    description: pastClass.description,
    price:
      pastClass.callPrice === 0
        ? "Free"
        : pastClass.callPriceCurrency + " " + pastClass.callPrice.toLocaleString("en-US"),
  }));

  const showModal = (id: string) => {
    const modalContent = getLiveClassesQuery.data.data.find((data) => data.id === +id);
    setModalContent(modalContent);
    setIsModalOpen(true);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {text}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: "17.425rem",
      key: "price",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "17.425rem",
      key: "description",
      render: (text) => (
        <>
          <StyledDescriptionTextValue>
            {text.replace(/<[^>]+>/g, "")}
          </StyledDescriptionTextValue>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => showModal(record.key)}>
          View details
        </Button>
      ),
    },
  ];

  let stripedHtml = modalContent?.description.replace(/<[^>]+>/g, "");
  return (
    <Space direction="vertical" size={29} style={{display: "flex"}}>
      {getLiveClassesQuery.data.data.length > 0 ? (
        <>
          <Table
            columns={columns}
            dataSource={pastClassData}
            loading={getLiveClassesQuery.isFetching}
            scroll={{x: 100}}
            pagination={false}
          />
          <CustomPagination
            isPreviousData={getLiveClassesQuery.isPreviousData}
            page={page}
            paginationMeta={getLiveClassesQuery.data.meta}
            setPage={setPage}
          />
        </>
      ) : (
        <Spin
          spinning={getLiveClassesQuery.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <div style={{width: "100%"}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                You do not have any past live class at the moment.
              </p>
            </StyledNoUpcomingCallBox>
          </div>
        </Spin>
      )}
      <Modal
        title={modalContent?.name}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            onClick={handleOk}
            shape="round"
            type="primary"
            loading={exportCsvQueryMutation.isLoading}
          >
            Export CSV
          </Button>,
          <Button onClick={handleCancel} shape="round">
            Cancel
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          <Row gutter={[32, 20]}>
            <Col span={24}>
              <StyledInfoItem>
                <p className="info-title">Name</p>
                <p className="info-value">{modalContent?.name}</p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Price</p>
                <p className="info-value">
                  {modalContent?.callPrice === 0
                    ? "Free"
                    : modalContent?.callPriceCurrency +
                      " " +
                      modalContent?.callPrice.toLocaleString("en-US")}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={12}>
              <StyledInfoItem>
                <p className="info-title">Attendees</p>
                <p className="info-value">
                  {modalContent?.attendees === -1 ? "Unlimited" : modalContent?.attendees}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24}>
              <StyledInfoItem>
                <p className="info-title">Description</p>
                <p className="info-value info-desc">{stripedHtml}</p>
                {/* <div
                  className="info-value"
                  dangerouslySetInnerHTML={{
                    __html: modalContent?.description ?? "No description",
                  }}
                /> */}
              </StyledInfoItem>
            </Col>
          </Row>
        </StyledModalContentWrapper>
      </Modal>
    </Space>
  );
};

export {PastLiveClasses};
