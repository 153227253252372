import {Button, Result} from "antd";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../redux/store";

const ServiceBookingErrorPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const {message} = useSelector((state: RootState) => state.booking);
  return (
    <Result
      status="error"
      title="An Error Occured"
      subTitle={message}
      extra={[
        <Button onClick={() => navigate(-1)} shape="round" type="default" key="console">
          Go back
        </Button>,
        // <Button key="buy">Buy Again</Button>,
      ]}
    />
  );
};

export {ServiceBookingErrorPage};
