import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {TService} from "../service/i-service";
import {RootState} from "../store";
import {
  getPastCallsService,
  getUpcomingCallsService,
  mentorCancelCallService,
  mentorRescheduleCallService,
} from "./call-service";
import {TCoachReschedulePayload} from "./i-call";

interface IInitialState {
  upcomingCalls: {
    id: number;
    userId: number;
    serviceId: number;
    mentor_timezone: string;
    user_timezone: string;
    booking_year: string;
    booking_month: string;
    booking_date: string;
    timeslot: string;
    endTime: string;
    email_address: string;
    first_name: string;
    last_name: string;
    amount: number;
    roomId: string | null;
    notes: string | null;
    phone_number: string;
    booking_type: string;
    is_bundle: boolean;
    statusId: number;
    reference: string;
    is_paid: boolean;
    bookingQuestions: [] | null;
    statusName: string;
    service: TService;
  }[];
  pastCalls: {
    id: number;
    userId: number;
    serviceId: number;
    mentor_timezone: string;
    user_timezone: string;
    booking_year: string;
    booking_month: string;
    booking_date: string;
    timeslot: string;
    endTime: string;
    email_address: string;
    first_name: string;
    last_name: string;
    amount: number;
    roomId: string | null;
    notes: string | null;
    phone_number: string;
    booking_type: string;
    is_bundle: boolean;
    statusId: number;
    reference: string;
    is_paid: boolean;
    bookingQuestions: [] | null;
    statusName: string;
    service: TService;
  }[];
  fetchUpcomingCallsStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchPastCallsStatus: "idle" | "pending" | "resolved" | "rejected";
  mentorRescheduleCallStatus: "idle" | "pending" | "resolved" | "rejected";
  mentorCancelCallStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  upcomingCalls: [],
  pastCalls: [],
  fetchUpcomingCallsStatus: "idle",
  fetchPastCallsStatus: "idle",
  mentorRescheduleCallStatus: "idle",
  mentorCancelCallStatus: "idle",
  message: "",
};

//Get upcoming calls
export const getUpcomingCalls = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("call/getUpcomingCalls", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getUpcomingCallsService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get past calls
export const getPastCalls = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("call/getPastCalls", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getPastCallsService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//mentor reschedule call
export const mentorRescheduleCall = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  TCoachReschedulePayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("call/mentorRescheduleCall", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await mentorRescheduleCallService(payload, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//mentor cancel call
export const mentorCancelCall = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("call/mentorCancelCall", async (bookingReference, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await mentorCancelCallService(bookingReference, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    resetCallState: (state) => {
      // state.fetchUpcomingCallsStatus = "idle";
      state.mentorCancelCallStatus = "idle";
      state.mentorRescheduleCallStatus = "idle";
      state.message = "";
    },
    resetPastCallState: (state) => {
      // state.fetchUpcomingCallsStatus = "idle";
      state.fetchPastCallsStatus = "idle";
      state.pastCalls = [];
      state.message = "";
    },
    callLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpcomingCalls.pending, (state) => {
        state.fetchUpcomingCallsStatus = "pending";
      })
      .addCase(getUpcomingCalls.fulfilled, (state, action) => {
        state.fetchUpcomingCallsStatus = "resolved";
        state.upcomingCalls = action.payload.data.transactions;
      })
      .addCase(getUpcomingCalls.rejected, (state, action: any) => {
        state.fetchUpcomingCallsStatus = "rejected";
        state.message = action.payload;
        state.upcomingCalls = [];
      })
      .addCase(getPastCalls.pending, (state) => {
        state.fetchPastCallsStatus = "pending";
      })
      .addCase(getPastCalls.fulfilled, (state, action) => {
        state.fetchPastCallsStatus = "resolved";
        state.pastCalls = action.payload.data.bookings;
      })
      .addCase(getPastCalls.rejected, (state, action: any) => {
        state.fetchPastCallsStatus = "rejected";
        state.message = action.payload;
        state.pastCalls = [];
      })
      .addCase(mentorRescheduleCall.pending, (state) => {
        state.mentorRescheduleCallStatus = "pending";
      })
      .addCase(mentorRescheduleCall.fulfilled, (state, action) => {
        state.mentorRescheduleCallStatus = "resolved";
      })
      .addCase(mentorRescheduleCall.rejected, (state, action: any) => {
        state.mentorRescheduleCallStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(mentorCancelCall.pending, (state) => {
        state.mentorCancelCallStatus = "pending";
      })
      .addCase(mentorCancelCall.fulfilled, (state, action) => {
        state.mentorCancelCallStatus = "resolved";
      })
      .addCase(mentorCancelCall.rejected, (state, action: any) => {
        state.mentorCancelCallStatus = "rejected";
        state.message = action.payload;
      });
  },
});

export const {resetCallState, resetPastCallState, callLogoutReset} = callSlice.actions;

export default callSlice.reducer;
