import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {TProfileApiResponse, TProfilePayload} from "../redux/profile/i-profile-1";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

const useGetProfileStatus = () => {
  const client = useClient();

  return useQuery<
    {
      profileStatus: "Active" | null;
      bankAccount: "Active" | null;
      serviceStatus: "Active" | null;
      availabilityStatus: "Active" | null;
    },
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["dashboard_metrics"],
    queryFn: () => client("dashboard/metrics").then((data) => data.data),
    onError: (err) => {
      message.error(err.message);
      AnalyticsEvent("Dashboard Metrics", "Dashboard Metrics Fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Dashboard Metrics", "Dashboard Metrics Success");
    },
  });
};

const useGetProfile = () => {
  const client = useClient();

  return useQuery<TProfileApiResponse, AxiosError<{status: boolean; message: string}>>({
    queryKey: ["profile"],
    queryFn: () => client("profile").then((data) => data.data),
    // onError:(err: Error) => console.log({err})
    staleTime: Infinity,
  });
};

const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (profileData: TProfilePayload) =>
      client(`profile`, {
        method: "POST",
        data: profileData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["profile"]);
      const oldProfile: TProfileApiResponse | undefined = queryClient.getQueryData([
        "profile",
      ]);
      let newValues;
      if (oldProfile) {
        newValues = {
          avatar: oldProfile.avatar,
          behance: values.behance,
          country: values.country,
          cover_image: oldProfile.cover_image,
          createdAt: oldProfile.createdAt,
          description: values.shortDescription,
          discord: values.discord,
          dribble: values.dribble,
          email: oldProfile.email,
          facebook: values.facebook,
          firstName: values.firstName,
          github: values.github,
          id: oldProfile.id,
          instagram: values.instagram,
          isSocailAuth: oldProfile.isSocailAuth,
          lastName: values.lastName,
          linkedin: values.linkedin,
          shortBio: values.shortBio,
          statusId: oldProfile.statusId,
          statusName: oldProfile.statusName,
          tiktok: values.tiktok,
          twitch: values.twitch,
          twitter: values.twitter,
          updatedAt: oldProfile.updatedAt,
          userId: oldProfile.userId,
          username: values.username,
          website: values.website,
          youtube: values.youtube,
          buffer_time: oldProfile.buffer_time,
        };
      }

      queryClient.setQueryData(["profile"], newValues);
      return () => queryClient.setQueryData(["profile"], oldProfile);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Coach profile", "Coach profile update fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      AnalyticsEvent("Coach profile", "Coach profile update success");
      queryClient.setQueryData(["profile"], data);
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({queryKey: ["profile"]}),
  });
};

export {useGetProfileStatus, useGetProfile, useUpdateProfile};
