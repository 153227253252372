import {CloudUploadOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Button, message, Spin, Upload} from "antd";
import React from "react";
import {useSelector} from "react-redux";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import {RootState} from "../../../../redux/store";
import {API_BASE_URL} from "../../../../services/config";
import {StyledAvatarWrapper} from "./styles";

const AvatarUpload = ({username}: {username: string}) => {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const getProfileQuery = useGetProfile();

  const [imageUrl, setImageUrl] = React.useState(() => getProfileQuery.data?.avatar);
  const [uploading, setUploading] = React.useState(false);

  const handleChange = (info: any) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "error") {
      setUploading(false);
      message.error(info.file.response.message);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setUploading(false);
      //   setImageUrl(url);
      // });
      setUploading(false);
      const imgUrl = info.file.response.data.url;
      setImageUrl(imgUrl);
      message.success("Your profile photo has been uploaded successfully!");
      getProfileQuery.refetch();
      // console.log(info);
    }
  };

  return (
    <Spin spinning={uploading}>
      <StyledAvatarWrapper>
        {imageUrl ? (
          <Avatar size={100} src={imageUrl} />
        ) : (
          <Avatar size={100} icon={<UserOutlined />} />
        )}
        <div>
          <p className="username-text">
            {username && username.length > 0 && `Coachli.co/${username}`}{" "}
          </p>

          <Upload
            name="avatar"
            showUploadList={false}
            headers={{
              authorization: `Bearer ${userInfo.accessToken}`,
            }}
            action={`${API_BASE_URL}avatar`}
            onChange={handleChange}
          >
            <Button
              style={{paddingLeft: 0}}
              type="link"
              size="large"
              icon={<CloudUploadOutlined />}
            >
              Upload profile photo
            </Button>
          </Upload>
        </div>
      </StyledAvatarWrapper>
    </Spin>
  );
};

export {AvatarUpload};
