import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {IUnAvailabilityFormValues} from "./i-unavailability-form";

// create user unavailability
export const createUnavailabilityService = async (
  selections: IUnAvailabilityFormValues,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "unavailability", selections, config);

  return response.data;
};
// Get user unavailability
export const getUserUnavailabilityService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "unavailability", config);

  return response.data;
};
