import {Navigate, useSearchParams} from "react-router-dom";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {useCompleteCoachConnectCalendar} from "../../../../react-query-hooks/connect-calendar/connect-calendar";
import {useGetProfileContext} from "../../../../context/profile/profile-context";
import {Button, Result} from "antd";

export function GoogleVerify() {
  const {state: profileState} = useGetProfileContext();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const scope = searchParams.get("scope");

  const completeCoachConnectCalendarQuery = useCompleteCoachConnectCalendar(
    profileState.profileData.email,
    code,
    scope
  );
  if (completeCoachConnectCalendarQuery.isSuccess) {
    return <Navigate to={"/dashboard/settings?activeTab=calendar"} />;
  }
  if (completeCoachConnectCalendarQuery.isError) {
    return (
      <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle="Error sending request"
        extra={
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => completeCoachConnectCalendarQuery.refetch()}
          >
            Try again
          </Button>
        }
      />
    );
  }
  return (
    <DashboardWrapper pageTitle="Settings">
      <FullPageLoader />
    </DashboardWrapper>
  );
}
