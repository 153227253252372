import styled from "styled-components";

export const StyledBalanceSectionWrapper = styled.div`
  margin-top: 4rem;

  .balance-section-title {
    font-weight: 700;
    font-size: 2.4rem;
  }
`;
export const StyledBalanceInfoBoxWrapper = styled.div`
  margin-top: 4rem;
`;
export const StyledBalanceInfoBox = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  padding: 2.4rem;

  .balance-info-box-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 2.2rem;

    .payout-pending {
      font-weight: 600;
      font-size: 1.4rem;
      color: #8c8c8c;
    }
    .payout-pending-value {
      font-weight: 400;
      font-size: 3rem;
      color: #595959;
    }
    .payout-ready {
      font-weight: 600;
      font-size: 1.4rem;
      color: #52c41a;
    }
    .payout-ready-value {
      font-weight: 400;
      font-size: 3rem;
      color: #00171f;
    }
  }
`;
