import styled from "styled-components";

export const StyledMostOutOfCoachliSection = styled.div`
  max-width: 83.2rem;
  margin: 0 auto;
  padding: 2.7rem 0 5.6rem 0;
  @media (min-width: 425px) {
    padding: 7.1rem 0 7.7rem 0;
  }
`;

export const StyledMostOutOfCoachliSectionHeader = styled.div`
  max-width: 61rem;
  margin: 0 auto;
  text-align: center;

  .main-heading {
    max-width: 62rem;
    /* font-family: "Charis SIL"; */
    font-weight: 700;
    font-size: 3rem;
    line-height: 38px;
    margin: 0 auto;
  }

  .sub-heading {
    margin: 0 auto;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }

  @media (min-width: 425px) {
    .main-heading {
      font-size: 4.6rem;
      line-height: 54px;
    }

    .sub-heading {
      font-size: 2rem;
      margin-top: 2.4rem;
    }
  }
`;

export const StyledMostOutOfCoachliSectionImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 4.5rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const StyledMostOutOfCoachliDemoBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4.5rem;
`;
