import {Card} from "antd";
import styled from "styled-components";

export const StyledLiveClassesSectionWrapper = styled.section`
  margin-top: 5rem;

  .live-classes-section-heading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  @media (max-width: 500px) {
    .live-classes-section-heading {
      font-size: 2rem;
    }
  }
`;

export const StyledLiveClassesContentSectionWrapper = styled.section`
  margin-top: 2.5rem;

  .live-classes-section-heading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  @media (max-width: 500px) {
    .live-classes-section-heading {
      font-size: 2rem;
    }
  }
`;

export const StyledLiveClassesCardWrapper = styled(Card)`
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  .ant-card-head-title {
    font-weight: 600;
  }
`;

export const StyledLiveClassesCardContent = styled.div`
  display: grid;
  gap: 1.5rem;
  .class-card-image-wrapper {
    width: 100%;
    height: auto;
    border-radius: 5px;
    .class-card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .class-card-info-text {
    font-weight: 400;
    font-size: 1.4rem;
    display: -webkit-box;
    overflow: hidden;
    /* display 4 lines only */
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .class-card-extra-info {
    font-weight: 600;
    font-size: 1.2rem;
  }
  @media (min-width: 746px) {
    grid-template-columns: 32rem auto;
    grid-template-rows: auto;

    .class-card-image-wrapper {
      grid-column: 1/2;
      grid-row: 1/4;
      height: 18.5rem;
      .class-card-image {
        height: 100%;
      }
    }

    .class-card-info-text {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    .class-card-extra-info {
      grid-column: 2/3;
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
    }
    .class-card-reserve-btn {
      grid-column: 2/3;
      grid-row: 3/4;
      margin-top: 1rem;
    }
  }
`;
