import {BankOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Button, Col, Input, message, Row, Select, Space, Spin} from "antd";
import {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {StyledModalContentWrapper} from "../../../components/calls/upcoming-calls/call-card/call-card.style";
import {StyledInputLabelWrapper} from "../../../components/input/input.style";
import {CustomModal} from "../../../components/modal";
import {DisconnectBankComponent} from "../../../components/payout/disconnect-bank-component";
import {BalanceSection} from "../../../components/payouts/balance-section/balance-section";
import {PayoutHistorySection} from "../../../components/payouts/payout-history-section/payout-history-section";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledCustomersPageWrapper} from "../../../styles/customers/customers";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {
  StyledConnectBankBox,
  StyledConnectBankBoxWrapper,
} from "../../../styles/payouts/payouts";
import {connectBankFormSchema} from "./connect-bank-form-validation-schema";
import {IConnectBankFormValues} from "./i-connect-bank";
import {
  useGetPayoutCountries,
  useGetPayoutBanks,
  useConnectBank,
  useValidateBankAccount,
  TConnectBankPayload,
  TConnectToStripePayload,
  useConnectToStripe,
} from "../../../react-query-hooks/payout";
import {isNonEu} from "../../../utils/bank";
import {FullPageLoader} from "../../../components/full-page-loader";
import {useCurrencyContext} from "../../../context/currency-context";
import {PendingFxTransactions} from "../../../components/payouts/pending-fx";

const {Option} = Select;

const PayoutsPage = () => {
  const {state} = useCurrencyContext();
  const [openConnectBankModal, setOpenConnectBankModal] = useState<boolean>(false);
  const getPayoutCountriesQuery = useGetPayoutCountries();
  const getPayoutBanksQueryMutation = useGetPayoutBanks();
  const connectBankQueryMutation = useConnectBank();
  const connectToStripeQueryMutation = useConnectToStripe();
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    reset,
    setValue,
  } = useForm<IConnectBankFormValues>({
    resolver: yupResolver(connectBankFormSchema),
    mode: "onChange",
  });

  const accountNumberValue = watch("accountNumber");
  const countryCodeValue = watch("countryCode");
  const bankCodeValue = watch("bankCode");
  const bankName: {id: number; code: string; name: string} =
    getPayoutBanksQueryMutation.isSuccess &&
    getPayoutBanksQueryMutation.data.data.find(
      (bank: {id: number; code: string; name: string}) => bank.code === bankCodeValue
    );

  const validateBankAccountQueryMutation = useValidateBankAccount({
    accountNumber:
      accountNumberValue && accountNumberValue.length === 10
        ? accountNumberValue
        : undefined,
    bankCode: bankCodeValue,
    bankName: bankName?.name,
    countryCode: countryCodeValue,
  });
  if (getPayoutCountriesQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle="Payouts">
        <StyledPageHeader>Payouts 💸</StyledPageHeader>
        <StyledCustomersPageWrapper>
          <FullPageLoader />
        </StyledCustomersPageWrapper>
      </DashboardWrapper>
    );
  }

  if (getPayoutCountriesQuery.isError) {
    return (
      <DashboardWrapper pageTitle="Payouts">
        <StyledPageHeader>Payouts 💸</StyledPageHeader>
        <StyledCustomersPageWrapper>
          <Alert
            message="Error"
            description={getPayoutCountriesQuery.error.message}
            type="error"
            showIcon
          />
        </StyledCustomersPageWrapper>
      </DashboardWrapper>
    );
  }

  const hasConnectedBank = Object.keys(state.bankDetails || {}).length > 0;
  const showConnectBankModal = () => {
    setOpenConnectBankModal(true);
  };
  const handleCancel = () => {
    // dispatch(payoutLogoutReset());
    setValue("countryCode", "");
    reset();
    setOpenConnectBankModal(false);
  };
  const handleOk = () => {
    reset();
    setOpenConnectBankModal(false);
  };

  const handleCountrySelectChange = (e: any, onChange: any) => {
    onChange(e);
    setValue("accountNumber", "");
    if (isNonEu.includes(e)) {
      getPayoutBanksQueryMutation.mutate({countryCode: e});
      setValue("bankCode", "");
    }
  };

  const handleBankSelectChange = (e: any, onChange: any) => {
    onChange(e);
  };

  const onSubmit = async (values: IConnectBankFormValues) => {
    if (isNonEu.includes(countryCodeValue)) {
      //TODO: COMPLETE CONNECTION FOR NORMAL BANK
      const bankName: {id: number; code: string; name: string} =
        getPayoutBanksQueryMutation.data.data.find(
          (bank: {id: number; code: string; name: string}) =>
            bank.code === values.bankCode
        );

      const payload: TConnectBankPayload = {
        accountNumber: values.accountNumber,
        bankCode: values.bankCode,
        bankName: bankName.name,
        countryCode: values.countryCode,
        routingNumber: values.routingNumber,
      };
      connectBankQueryMutation.mutate(payload, {
        onSuccess: () => {
          message.success("Bank updated successfully!");
          handleOk();
        },
      });
    } else {
      const countryPayload = getPayoutCountriesQuery.data.data.countries.find(
        (country: {name: string; code: string; currency: string}) =>
          country.code === values.countryCode
      );
      //TODO: COMPLETE CONNECTION FOR STRIPE
      const stripePayload: TConnectToStripePayload = {
        countryCode: values.countryCode,
        countryName: countryPayload ? countryPayload.name : "",
        currencyCode: countryPayload ? countryPayload.currency : "",
      };
      connectToStripeQueryMutation.mutate(stripePayload, {
        onSuccess: (data) => {
          handleCancel();
          message.success(
            "Your bank account has been created successfully! Please, complete your registration!"
          );
          // window.open(data.data.LoginLink);
          // console.log({data});
        },
      });
    }
  };
  return (
    <DashboardWrapper pageTitle="Payouts">
      <StyledPageHeader>Payouts 💸</StyledPageHeader>
      <StyledCustomersPageWrapper>
        <>
          {/* <Spin
          spinning={
            fetchConnectedBankAccountStatus === "pending" ||
            connectBankAccountStatus === "pending" ||
            disconnectConnectedBankAccountStatus === "pending" ||
            getPayoutCountriesQuery.isLoading
          }
        > */}
          <StyledConnectBankBoxWrapper>
            <p>Set up your payout details to enable you receive your earnings</p>
            {hasConnectedBank ? (
              // <DisconnectBankComponent />
              <DisconnectBankComponent />
            ) : (
              <StyledConnectBankBox>
                <div className="bank-icon-container">
                  <BankOutlined style={{fontSize: "2.5rem", color: "#2F54EB"}} />
                </div>
                <div className="bank-connect-text">
                  <h3>Bank Account</h3>
                  <p>Connect your bank account</p>
                </div>
                <div className="bank-connect-btn">
                  <Button
                    onClick={showConnectBankModal}
                    type="primary"
                    block
                    shape="round"
                    size="large"
                  >
                    Connect account
                  </Button>
                </div>
              </StyledConnectBankBox>
            )}
          </StyledConnectBankBoxWrapper>
          {/* </Spin> */}
          <BalanceSection
            connectedBank={state.bankDetails}
            hasConnectedBank={hasConnectedBank}
          />
          <PendingFxTransactions />
          <PayoutHistorySection />
          <CustomModal
            visibleState={openConnectBankModal}
            title="Connect your bank account"
            okFunc={handleOk}
            cancelFunc={handleCancel}
            footerContent={[
              <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="save"
                type="primary"
                shape="round"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  Object.keys(errors).length > 0 ||
                  Object.values(watch()).length < 1 ||
                  validateBankAccountQueryMutation.isError
                }
                loading={
                  connectBankQueryMutation.isLoading ||
                  connectToStripeQueryMutation.isLoading
                }
              >
                Complete connection
              </Button>,
            ]}
          >
            <>
              <StyledModalContentWrapper>
                <p>Your earnings will be sent to this bank account </p>
                <div style={{margin: "3.3rem 0"}}>
                  <Space direction="vertical" size={25} style={{display: "flex"}}>
                    {/* <CustomSelectInput
                    control={control}
                    id="country"
                    label="Country"
                    name="country"
                    placeholder="Nigeria"
                    options={countries}
                    showSearch
                    error={errors.country?.message}
                    style={{width: "100%"}}
                    required
                  /> */}
                    <Controller
                      control={control}
                      name="countryCode"
                      render={({field: {onChange, value, ref}}) => (
                        <div>
                          <StyledInputLabelWrapper>
                            <label id="country-label" htmlFor="country">
                              <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                                *
                              </span>{" "}
                              Country
                            </label>
                          </StyledInputLabelWrapper>

                          <Select
                            onChange={(e) => handleCountrySelectChange(e, onChange)}
                            value={value}
                            ref={ref}
                            style={{width: "100%"}}
                            id="country"
                            size="large"
                            status={errors.countryCode?.message ? "error" : ""}
                            showSearch
                            placeholder="Select country"
                            loading={
                              getPayoutCountriesQuery.isFetching ||
                              getPayoutCountriesQuery.isLoading
                            }
                            disabled={
                              getPayoutCountriesQuery.isLoading ||
                              getPayoutCountriesQuery.isFetching ||
                              getPayoutCountriesQuery.isError
                            }
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            notFoundContent={
                              getPayoutCountriesQuery.isLoading ||
                              getPayoutCountriesQuery.isFetching ? (
                                <Spin />
                              ) : null
                            }
                          >
                            {getPayoutCountriesQuery.data.data.countries.map(
                              (option, optionIdx: number) => (
                                <Option key={optionIdx} value={option.code}>
                                  {option.name}
                                </Option>
                              )
                            )}
                          </Select>
                          {errors.countryCode?.message && (
                            <div role="alert" className="ant-form-item-explain-error">
                              {errors.countryCode?.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                    {isNonEu.includes(countryCodeValue) && (
                      <Controller
                        control={control}
                        name="bankCode"
                        render={({field: {onChange, value, ref}}) => (
                          <div>
                            <StyledInputLabelWrapper>
                              <label id="bank-label" htmlFor="bank">
                                <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                                  *
                                </span>
                                Bank
                              </label>
                            </StyledInputLabelWrapper>

                            <Select
                              onChange={(e) => handleBankSelectChange(e, onChange)}
                              value={value}
                              ref={ref}
                              style={{width: "100%"}}
                              id="bank"
                              size="large"
                              status={errors.bankCode?.message ? "error" : ""}
                              showSearch
                              placeholder="Select bank"
                              loading={getPayoutBanksQueryMutation.isLoading}
                              disabled={
                                getPayoutBanksQueryMutation.isLoading || !countryCodeValue
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              notFoundContent={
                                getPayoutBanksQueryMutation.isLoading ? <Spin /> : null
                              }
                            >
                              {getPayoutBanksQueryMutation.isSuccess
                                ? getPayoutBanksQueryMutation.data.data.map(
                                    (option: {
                                      id: number;
                                      code: string;
                                      name: string;
                                    }) => (
                                      <Option key={option.id} value={option.code}>
                                        {option.name}
                                      </Option>
                                    )
                                  )
                                : []}
                            </Select>
                            {errors.bankCode?.message && (
                              <div role="alert" className="ant-form-item-explain-error">
                                {errors.bankCode?.message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    )}
                    {isNonEu.includes(countryCodeValue) && (
                      <>
                        <Controller
                          control={control}
                          name="accountNumber"
                          render={({field: {onChange, value}}) => (
                            <>
                              <StyledInputLabelWrapper>
                                <label id="accountNumber" htmlFor="accountNumber">
                                  <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                                    *
                                  </span>{" "}
                                  <span>Account number</span>
                                </label>
                              </StyledInputLabelWrapper>
                              <Input
                                id="accountNumber"
                                status={errors.accountNumber?.message && "error"}
                                size="large"
                                placeholder="Enter account number"
                                disabled={
                                  !watch("countryCode") ||
                                  !watch("bankCode") ||
                                  (accountNumberValue?.length === 10 &&
                                    validateBankAccountQueryMutation.isSuccess)
                                }
                                onChange={onChange}
                                value={value}
                              />
                              {errors.accountNumber?.message && (
                                <div role="alert" className="ant-form-item-explain-error">
                                  {errors.accountNumber?.message}
                                </div>
                              )}
                            </>
                          )}
                        />
                        {validateBankAccountQueryMutation.isSuccess && (
                          <p style={{fontSize: "1.4rem", textTransform: "uppercase"}}>
                            {validateBankAccountQueryMutation.data.name}
                          </p>
                        )}
                      </>
                    )}
                  </Space>
                </div>
              </StyledModalContentWrapper>
            </>
          </CustomModal>
        </>
      </StyledCustomersPageWrapper>
    </DashboardWrapper>
  );
};

export {PayoutsPage};
