import {Space} from "antd";

export function PrivacyPolicySubHeading() {
  return (
    <div style={{fontSize: "1.6rem"}}>
      <Space size={20} direction="vertical">
        <p>
          Your privacy is important to us, so <strong>Coachli Technology LTD</strong>, a
          company based in the United States, has created the following Privacy Policy (
          <strong>"Policy"</strong>) to let you know what information we collect when you
          visit our Site{" "}
          <a
            style={{color: "#00171F", textDecoration: "underline"}}
            href="https://www.coachli.co/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.coachli.co/
          </a>{" "}
          (<strong>"Site"</strong>), why we collect it and how we use it.
        </p>
        <p>
          The terms <strong>"You"</strong>, <strong>"Your"</strong>,{" "}
          <strong>"Yours"</strong> and <strong>"User"</strong> refer to the
          entity/person/organisation using our Site. When this Policy mentions{" "}
          <strong>"We"</strong>, <strong>"Us”</strong>
          and <strong>"Our"</strong> it refers to <strong>Coachli Technology LTD</strong>{" "}
          and its subsidiaries and affiliates. This Privacy Policy is governed by our
          Terms of Services.{" "}
        </p>
        <p>
          For any questions regarding this Policy or any requests regarding the processing
          of personal data, please contact us at{" "}
          <a
            href="mailto:hello@coachli.co"
            style={{color: "#00171F", textDecoration: "underline"}}
          >
            hello@coachli.co
          </a>
          .
        </p>
      </Space>
    </div>
  );
}
