import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {IGoogleRegisterValues} from "../../../components/google-button/i-google-button";
import {IRegisterFormValues} from "../../../pages/auth/register/i-register";
import {AnalyticsEvent} from "../../../utils/analytics";
import {
  registerSocialUserService,
  registerStandardUserService,
} from "./user-register-service";

interface IInitialState {
  user: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Register standard user
export const registerStandardUser = createAsyncThunk(
  "userRegister/registerStandardUser",
  async (user: IRegisterFormValues, thunkAPI) => {
    try {
      return await registerStandardUserService(user);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userRegisterSlice = createSlice({
  name: "userRegister",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
    userRegisterlogoutReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerStandardUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerStandardUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.data;
        AnalyticsEvent("Standard Registration", "Standard Registration Success");
      })
      .addCase(registerStandardUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        AnalyticsEvent("Standard Registration", "Standard Registration Fail");
      });
  },
});

export const {reset, userRegisterlogoutReset} = userRegisterSlice.actions;

export default userRegisterSlice.reducer;
