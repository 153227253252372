import {Skeleton, Space} from "antd";

const AvailabilityWidgetSkeleton = () => {
  return (
    <>
      <div className="availability-widget-card">
        <Space direction="vertical" size={8} style={{display: "flex"}}>
          <Skeleton.Input block />
          <Skeleton.Button block />
          <Skeleton.Button block />
          <Skeleton.Button block />
          <Skeleton.Button block />
        </Space>
      </div>
    </>
  );
};

export {AvailabilityWidgetSkeleton};
