import axios from "axios";
import {API_BASE_URL} from "../../../services/config";

// const API_BASE_URL = "http://207.154.220.50:7890/api/v1/";

// Forgot password standard user
export const forgotPasswordService = async (email: string) => {
  const response = await axios.post(API_BASE_URL + "forgot-password", {
    email: email.toLowerCase(),
  });

  return response.data;
};
// Activate standard user
export const newPasswordService = async (payload: {
  password: string;
  confirmPassword: string;
  link: string;
}) => {
  const response = await axios.put(API_BASE_URL + "forgot-password", payload);

  return response.data;
};
