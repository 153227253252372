import {Skeleton, Space} from "antd";

const ProfileWidgetSkeleton = () => {
  return (
    <div>
      <Space size={16} direction="vertical" style={{display: "flex"}}>
        <div className="profile-widget-card-header">
          <Skeleton.Input />
          <Skeleton.Button shape="circle" />
        </div>
        <div>
          <Skeleton.Input block />
          <Skeleton.Input block />
        </div>
        <div>
          <Skeleton.Button shape="round" block />
        </div>
        <Skeleton.Input />
        <div>
          <Space direction="horizontal" size={16}>
            <div>
              <Skeleton.Button shape="circle" />
            </div>
            <div>
              <Skeleton.Button shape="circle" />
            </div>
            <div>
              <Skeleton.Button shape="circle" />
            </div>
            <div>
              <Skeleton.Button shape="circle" />
            </div>
          </Space>
        </div>
      </Space>
    </div>
  );
};

export {ProfileWidgetSkeleton};
