import {StyledContainer} from "../features-section/features-section.style";
import {TestimonialContent} from "./testimonial-content";
import {
  StyledTestimonialHeader,
  StyledTestimonialSection,
  StyledTestimonialSectionWrapper,
} from "./testimonial-section.style";

function TestimonialSection() {
  return (
    <StyledTestimonialSectionWrapper>
      <StyledContainer>
        <StyledTestimonialSection>
          <StyledTestimonialHeader>
            {/* <h2 className="section-title">Testimonial</h2> */}
            <h3 className="section-header">What people are saying</h3>
          </StyledTestimonialHeader>
          <TestimonialContent />
        </StyledTestimonialSection>
      </StyledContainer>
    </StyledTestimonialSectionWrapper>
  );
}

export {TestimonialSection};
