import {useNavigate, useSearchParams} from "react-router-dom";
import {UpcomingClasses} from "../../../components/live-classes/upcoming-classes";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {Alert, Button, Space, Spin, Tabs} from "antd";
import {PastLiveClasses} from "../../../components/live-classes/past-classes";
import {useGetLiveClasses} from "../../../react-query-hooks/live-events/live-classes";
import {FullPageLoader} from "../../../components/full-page-loader";
import {LoadingSpinner} from "../../../components/icons/icons";
import {StyledPageHeaderWrapper} from "../../../components/calls/upcoming-calls/upcoming-calls.style";
import {TourWidget} from "../../../components/widgets/tour-widget";
import {ProfileTemplateWidget} from "../../../components/widgets/template-widget";
import {VideoCameraOutlined} from "@ant-design/icons";
import {CohostedClasses} from "../../../components/live-classes/co-hosted-classes";
import {useGetCoHostedEvents} from "../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {
  StyledPublicProfileCountTag,
  StyledPublicProfileCountTagWrapper,
} from "../../../styles/public-profile/public-profile";
import {CustomLoadMoreButton} from "../../../components/load-more";

function LiveClassesPage() {
  const navigate = useNavigate();

  const getLiveClassesQuery = useGetLiveClasses();
  const getCoHostedEvent = useGetCoHostedEvents("upcoming");

  const pageTitle = "Live Classes";

  if (getLiveClassesQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (getLiveClassesQuery.isError) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
        <Alert
          message="Error"
          description={getLiveClassesQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }

  const handleTabClick = (key: string) => {
    if (key === "2") {
      getCoHostedEvent.refetch();
    }
  };
  // console.log({livegyy: getLiveClassesQuery.data});
  const flatMapPages = getLiveClassesQuery.data.pages.flatMap((page) => page.data);
  const flatMapCohostPages = getCoHostedEvent.data?.pages.flatMap((page) => page.data);
  return (
    <DashboardWrapper pageTitle={pageTitle}>
      <StyledPageWrapper>
        <div className="main-area" style={{maxWidth: "70rem"}}>
          <StyledPageHeaderWrapper>
            <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
            {flatMapPages.length > 0 && (
              <div style={{display: "flex", justifyContent: "end"}}>
                <Button
                  onClick={() => navigate("/dashboard/live-classes/new")}
                  shape="round"
                  type="primary"
                  size="large"
                >
                  Create new live class
                </Button>
              </div>
            )}
          </StyledPageHeaderWrapper>
          <Tabs defaultActiveKey="1" onTabClick={(key) => handleTabClick(key)}>
            <Tabs.TabPane tab="Upcoming classes" key="1">
              <Spin
                spinning={getLiveClassesQuery.isFetching}
                tip="Updating..."
                indicator={LoadingSpinner}
              >
                <UpcomingClasses pages={getLiveClassesQuery.data.pages} />
                {flatMapPages.length > 0 && (
                  <CustomLoadMoreButton
                    fetchNextPage={() => getLiveClassesQuery.fetchNextPage()}
                    hasNextPage={!!getLiveClassesQuery.hasNextPage}
                    isFetchingNextPage={getLiveClassesQuery.isFetchingNextPage}
                  />
                )}
              </Spin>
            </Tabs.TabPane>
            {/* "Co-hosted events" */}
            <Tabs.TabPane
              tab={
                <StyledPublicProfileCountTagWrapper>
                  Co-hosted classes{" "}
                  {flatMapCohostPages && flatMapCohostPages.length > 0 && (
                    <StyledPublicProfileCountTag>
                      {flatMapCohostPages.length}
                    </StyledPublicProfileCountTag>
                  )}
                </StyledPublicProfileCountTagWrapper>
              }
              key="2"
            >
              <CohostedClasses />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Past classes" key="3">
              <PastLiveClasses />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="Drafts" key="3">
              <DraftLiveClasses />
            </Tabs.TabPane> */}
          </Tabs>
        </div>
        <div className="widget-area">
          <Space direction="vertical" size={32} style={{display: "flex"}}>
            {/* <ProfileWidget /> */}
            {/* <BrowseEventsWidget /> */}
            <TourWidget
              buttons={[
                <Button
                  href="https://www.loom.com/share/42b9e6a7bedd41adabdf7f2e3e867613?sid=5be5070b-91c2-4b74-b7d9-4b75ace46420"
                  block
                  icon={<VideoCameraOutlined />}
                  size="large"
                  shape="round"
                  target="_blank"
                >
                  <span>Watch demo video</span>
                </Button>,
              ]}
            />
            <ProfileTemplateWidget />
          </Space>
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
}

export {LiveClassesPage};
