import {
  ArrowLeftOutlined,
  UserOutlined,
  DollarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  CalendarOutlined,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {Button, Avatar, Space} from "antd";
import moment from "moment";
import {tz} from "moment";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../../redux/store";
import {getTimeByTimeZone} from "../../../../services/helpers";
import {TIMEZONES} from "../../../../services/timezones";
import {
  StyledBookingLeftColumnMobile,
  StyledBookingLeftColumnMobileCoachInfoSection,
  StyledMobileCoachMainInfoSection,
  StyledMobileServiceInfoSection,
  StyledMobileServiceInfoDetailsSection,
  StyledCustomSelect,
} from "../left-column.style";

const CancelLeftColumnMobile = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const {
    customerBooking,
    fetchCustomerBookingStatus,
    message: customerBookingMessage,
  } = useSelector((state: RootState) => state.booking);

  const callDate = new Date(
    Number(customerBooking.booking?.booking_year),
    Number(customerBooking.booking?.booking_month) - 1,
    Number(customerBooking.booking?.booking_date)
  ).toDateString();

  const tz = moment.tz.guess(true);
  return (
    <StyledBookingLeftColumnMobile>
      {/* <div>
        <Button
          icon={<ArrowLeftOutlined />}
          className="back-link"
          onClick={() => navigate(-1)}
          type="link"
          size="large"
          style={{
            paddingLeft: 0,
            marginLeft: 0,
          }}
        >
          Back to profile
        </Button>
      </div> */}
      <StyledBookingLeftColumnMobileCoachInfoSection>
        <div>
          {customerBooking.mentor?.avatar ? (
            <Avatar size={96} src={customerBooking.mentor.avatar} />
          ) : (
            <Avatar size={96} icon={<UserOutlined />} />
          )}
        </div>
        <StyledMobileCoachMainInfoSection>
          <h2 className="coach-name">
            {customerBooking.mentor?.firstName} {customerBooking.mentor?.lastName}
          </h2>
          <p className="coach-role">{customerBooking.profile?.description}</p>
        </StyledMobileCoachMainInfoSection>
      </StyledBookingLeftColumnMobileCoachInfoSection>
      <StyledMobileServiceInfoSection>
        <h2 className="mobile-service-title">{customerBooking.service?.name}</h2>
        <div
          className="mobile-service-info"
          dangerouslySetInnerHTML={{
            __html: String(customerBooking.service?.description),
          }}
        />
        <StyledMobileServiceInfoDetailsSection>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="service-info-item-value">
                {customerBooking.service?.callPrice !== 0 &&
                  customerBooking.service?.callPriceCurrency}
                {customerBooking.service?.callPrice === 0
                  ? "Free"
                  : customerBooking.service?.callPrice?.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="service-info-item-value">
                {customerBooking.service?.callDuration} Minutes
              </span>
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              {customerBooking.service?.videoLocation ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="service-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="service-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="service-info-item-value">
                {customerBooking.booking?.timeslot}, {callDate}
              </span>
            </Space>
          </div>

          <div className="service-card-extra-info-item">
            <Space size={10}>
              <GlobalOutlined style={{color: "#7939CC"}} />
              {/* <span className="service-info-item-value">
            {`(${getTimeZone("longOffset")})`} {customerBooking.timeZone} (
            {formatAMPM(new Date())})
          </span> */}
              {/* {currentStep > 1 ? ( */}
              <span className="service-info-item-value">
                {customerBooking.booking?.user_timezone ?? tz}
              </span>
            </Space>
          </div>
        </StyledMobileServiceInfoDetailsSection>
      </StyledMobileServiceInfoSection>
    </StyledBookingLeftColumnMobile>
  );
};
export {CancelLeftColumnMobile};
