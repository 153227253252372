import {Alert, Spin} from "antd";
import {useGetCustomerUpcomingCalls} from "../../../../react-query-hooks/customer";
import {FullPageLoader} from "../../../full-page-loader";
import {LoadingSpinner} from "../../../icons/icons";
import {CustomerCallsSection} from "../calls-section/customer";
import {
  StyledUpcomingCallsWrapper,
  StyledNoUpcomingCallBox,
} from "../upcoming-calls.style";

const CustomerUpcomingCallsComponent = () => {
  const {data, isError, isLoading, isFetching, error} = useGetCustomerUpcomingCalls();

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (
    isError &&
    error.message !==
      "Sorry, You currently do not have any upcoming calls! Please, book a session and try again"
  ) {
    return <Alert message="Error" description={error.message} type="error" showIcon />;
  }

  return (
    <StyledUpcomingCallsWrapper>
      <>
        {data && data.data.session.length < 1 ? (
          <Spin spinning={isFetching} tip="Updating..." indicator={LoadingSpinner}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                {isFetching ? "" : "You do not have any upcoming 1:1 Calls"}
              </p>
            </StyledNoUpcomingCallBox>
          </Spin>
        ) : (
          <Spin spinning={isFetching} tip="Updating..." indicator={LoadingSpinner}>
            <CustomerCallsSection />
          </Spin>
        )}
      </>
    </StyledUpcomingCallsWrapper>
  );
};

export {CustomerUpcomingCallsComponent};
