export const TIME_COPY_FILTER_OPTIONS = [
  {
    label: "Copy times to...",
    value: "copy-text",
  },
  {
    label: "Sunday",
    value: "sun.timesAvailable",
  },
  {
    label: "Monday",
    value: "mon.timesAvailable",
  },
  {
    label: "Tuesday",
    value: "tue.timesAvailable",
  },
  {
    label: "Wednesday",
    value: "wed.timesAvailable",
  },
  {
    label: "Thursday",
    value: "thu.timesAvailable",
  },
  {
    label: "Friday",
    value: "fri.timesAvailable",
  },
  {
    label: "Saturday",
    value: "sat.timesAvailable",
  },
  {
    label: "Apply",
    value: "apply-btn",
  },
];
