import {useMutation, useQuery} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {closePaymentModal, useFlutterwave} from "flutterwave-react-v3";
import {TProfileApiResponse} from "../redux/profile/i-profile-1";
import {TServiceApiResponse} from "../redux/service/i-service";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";
import {PublicProfileApiResponseEvent} from "./live-events/live-classes";
import {TGetProductApiResponse} from "./products";

type TPublicProfileApiResponse = TProfileApiResponse & {
  services: TServiceApiResponse[];
  events?: PublicProfileApiResponseEvent[];
  products: TGetProductApiResponse["product"][];
  coachTimezone: {
    id: number;
    userId: number;
    coachli_timezone: string;
    createdAt: Date;
    updatedAt: Date;
  };
};
export type TPublicProfileBookingApiResponse = {
  service: TServiceApiResponse;
  profile: TProfileApiResponse;
  mentor: {
    avatar: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
  days: {
    sun: {from: string; to: string}[];
    mon: {from: string; to: string}[];
    tue: {from: string; to: string}[];
    wed: {from: string; to: string}[];
    thu: {from: string; to: string}[];
    fri: {from: string; to: string}[];
    sat: {from: string; to: string}[];
  };

  timeZone: string | null;
  unavailableDates: {
    from: string;
    to: string;
  }[];
};
export type TTimeslotsPayload = {
  mentorId?: number;
  serviceId?: number;
  date: number;
  year: number;
  month: number;
  day: string;
  mentorTimeZone: string;
  userTimeZone: string;
};

export type TBookingPayload = {
  mentorId?: string | number;
  serviceId?: string | number;
  mentor_timezone?: string | null;
  user_timezone: string;
  booking_date: string | number;
  booking_year: string | number;
  booking_month: string | number;
  timeSlot: string;
  currency: string;
  amount: number;
  email_address?: string;
  first_name?: string;
  last_name?: string;
  notes?: string;
  phone_number?: string | null;
  booking_type: string;
  bookingQuestions?: {question: string; answer: string}[];
};
export type TInitiateBundleBookingPayload = {
  mentorId: string | number;
  serviceId: string | number;
  mentor_timezone: string;
  user_timezone: string;
  booking_date: string | number;
  booking_year: string | number;
  booking_month: string | number;
  timeSlot: string;
  currency: string;
  amount: number;
  email_address: string;
  first_name?: string;
  last_name?: string;
  notes?: string;
  phone_number?: string | null;
  booking_type: "audio" | "video";
  bookingQuestions?: {question: string; answer: string}[];
};
export type ServiceBookingPayload = {
  mentorId: string | number;
  serviceId: string | number;
  mentor_timezone: string;
  user_timezone: string;
  booking_date: string | number;
  booking_year: string | number;
  booking_month: string | number;
  timeSlot: string;
  currency: string;
  amount: number;
  email_address: string;
  first_name: string;
  last_name: string;
  notes: string;
  phone_number?: string | null;
  booking_type: "audio" | "video";
  bookingQuestions?: {question: string; answer: string}[];
};
export type ServiceBookingApiResponse = {
  booking: {
    amount: number;
    bookingQuestions: {question: string; answer: string}[];
    booking_date: number;
    booking_month: number;
    booking_type: "audio" | "video";
    booking_year: number;
    coach_from_time: string;
    coach_to_time: string;
    currency: string;
    email_address: string;
    endTime: string;
    first_name: string;
    is_bundle: boolean;
    is_paid: boolean;
    last_name: string;
    mentor_timezone: string;
    notes: string;
    payment_provider: "flutterwave" | "paystack" | "stripe";
    phone_number: string | null;
    reference: string;
    serviceId: number;
    statusId: number;
    timeslot: string;
    userId: number;
    user_timezone: string;
    video_platform: "videoSdk" | "dailyCo";

    // bundle_reference: "47546447b1579e96073e4c8a81f95a2a10c37ae917d3e1ad7cb6f0afbff6a479";
    // bundle_session: 2;
    // id: 401;
    // roomId: "vzk2-wx9o-3fls";
  };
};
export type ServiceBundleBookingApiResponse = {
  bundle: {
    amount: number;
    bookingQuestions: {question: string; answer: string}[];
    booking_date: number;
    booking_month: number;
    booking_type: "audio" | "video";
    booking_year: number;
    bundle_reference: string;
    bundle_session: number;
    coach_from_time: string;
    coach_to_time: string;
    currency: string;
    email_address: string;
    endTime: string;
    first_name: string;
    id: number;
    is_bundle: boolean;
    is_paid: boolean;
    last_name: string;
    mentor_timezone: string;
    notes: string;
    payment_provider: "flutterwave" | "paystack" | "stripe";
    phone_number: string | null;
    reference: string;
    roomId: string;
    serviceId: number;
    statusId: number;
    timeslot: string;
    userId: number;
    user_timezone: string;
    video_platform: "videoSdk" | "dailyCo";
  };
};
export type ServiceBookingApiResponseState = {
  amount: number;
  bookingQuestions: {question: string; answer: string}[];
  booking_date: number;
  booking_month: number;
  booking_type: "audio" | "video";
  booking_year: number;
  bundle_reference?: string;
  bundle_session?: number;
  coach_from_time: string;
  coach_to_time: string;
  currency: string;
  email_address: string;
  endTime: string;
  first_name: string;
  id?: number;
  is_bundle: boolean;
  is_paid: boolean;
  last_name: string;
  mentor_timezone: string;
  notes: string;
  payment_provider: "flutterwave" | "paystack" | "stripe";
  phone_number: string | null;
  reference: string;
  roomId?: string;
  serviceId: number;
  statusId: number;
  timeslot: string;
  userId: number;
  user_timezone: string;
  video_platform: "videoSdk" | "dailyCo";
};

type TFlutterwaveConfig = {
  public_key: string;
  tx_ref: string;
  amount: number;
  currency?: "NGN" | string;
  customer: {
    email: string;
    phone_number: string;
    name: string;
  };
  customizations: {
    title: string;
    description: string;
    logo: string;
  };
  meta?: Record<string, unknown>;
  redirect_url?: string;
  payment_plan?: string;
  payment_options: "card, ussd, mobilemoney" | string;
  subaccounts?: Array<unknown>;
};
const useGetPublicProfile = (username?: string) => {
  const client = useClient();

  return useQuery<
    TPublicProfileApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["public_profile", username],
    queryFn: () => client(`booking/mentor/${username}`).then((data) => data.data),
    enabled: !!username,
    onError: () => {
      AnalyticsEvent("Public profile", "Public profile fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Public profile", "Public profile success");
    },
  });
};
const useGetPublicProfileBooking = (payload: {
  mentorUsername?: string | null;
  slug?: string | null;
  id?: string | null;
}) => {
  const client = useClient();

  return useQuery<
    TPublicProfileBookingApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["public_profile_booking", payload.slug],
    queryFn: () =>
      client(
        `booking/service/${payload.mentorUsername}/${payload.slug}/${payload.id}`
      ).then((data) => data.data),
    enabled: !!payload.slug && !!payload.id && !!payload.mentorUsername,
  });
};
// const useGetPublicProfileBookingTimeslots = (payload: TTimeslotsPayload) => {
//   const client = useClient();

//   return useQuery<TPublicProfileBookingApiResponse, AxiosError<{status: boolean; message: string}>>({
//     queryKey: ["public_profile_booking_timeslots", payload.serviceId],
//     queryFn: () => client(`booking/time-slots/`).then((data) => data.data),
//     enabled: false,
//   });
// };

const useGetPublicProfileBookingTimeslots = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TTimeslotsPayload) =>
      client(`booking/time-slots/`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
  });
};

const useCreateBooking = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (bookingData: ServiceBookingPayload) =>
      client(`booking`, {
        method: "POST",
        data: bookingData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Booking create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Booking create success");
    },
  });
};
const useCreateBundleBooking = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (bookingData: ServiceBookingPayload) =>
      client(`initiate/bundle`, {
        method: "POST",
        data: bookingData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Bundle Booking create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Bundle Booking create success");
    },
  });
};

const useHandleFlutterwavePayment = (
  config: TFlutterwaveConfig,
  updateStep: (step: number) => void
) => {
  const handleFlutterPayment = useFlutterwave(config);
  handleFlutterPayment({
    callback: (response) => {
      // console.log({response});
      closePaymentModal(); // this will close the modal programmatically
      if (response.status === "successful" || response.status === "completed") {
        AnalyticsEvent("Flutterwave payment", "Flutterwave payment success");
        updateStep(3);
      }
      AnalyticsEvent("Flutterwave payment", "Flutterwave payment fail");
    },
    onClose: () => {
      AnalyticsEvent("Flutterwave payment", "Closed payment modal");
    },
  });
};

export {
  useGetPublicProfile,
  useGetPublicProfileBooking,
  useGetPublicProfileBookingTimeslots,
  useCreateBooking,
  useCreateBundleBooking,
  useHandleFlutterwavePayment,
};
