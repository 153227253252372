import * as yup from "yup";

declare module "yup" {
  interface ArraySchema<T> {
    unique(
      message: string,
      mapper?: (value: T, index?: number, list?: T[]) => T[]
    ): ArraySchema<T>;
  }
}

yup.addMethod(yup.array, "unique", function (message, mapper = (val: unknown) => val) {
  return this.test("unique", message, (list = []) => {
    let set = new Set(list.map((s) => JSON.stringify(s)));
    let arr2 = Array.from(set).map((a2) => JSON.parse(a2));
    return list.length === arr2.length;
  });
});
const AvailabilityFormSchema = yup.object().shape({
  sun: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  mon: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  tue: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  wed: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  thu: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  fri: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
  sat: yup.object().shape({
    isAvailable: yup.bool(),
    timesAvailable: yup.array().of(
      yup.object().shape({
        from: yup.string(),
        to: yup.string(),
      })
    ),
    // .unique("unique", (val: any) => val.time),
  }),
});

const BufferTimeFormSchema = yup.object({
  bufferTime: yup.string().required("Select an interval"),
  customBufferTime: yup
    .number()
    .nullable()
    .when("bufferTime", {
      is: (bufferTime: string) => {
        // console.log({bufferTime});
        return bufferTime === "custom";
      },
      then: (schema) =>
        schema
          .transform((parsedValue, originalValue) =>
            originalValue === "" ? null : parsedValue
          )
          .typeError("Must be a number")
          .min(1, "Must be 1 hour and above")
          // .max(120, "Must be less than 120 minutes")
          .required("Input an interval in hours"),
      otherwise: (schema) =>
        schema.transform((parsedValue, originalValue) => {
          // console.log({parsedValue, originalValue});
          return originalValue === "" ? null : parsedValue;
        }),
    }),
});

export type BufferTimeFormValuesType = yup.InferType<typeof BufferTimeFormSchema>;

export {AvailabilityFormSchema, BufferTimeFormSchema};
