import styled from "styled-components";

export const StyledAvailabiltyFormWrapper = styled.div`
  max-width: 71.6rem;
  padding: 2.7rem 3.2rem;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .availability-form-heading {
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 3.3rem;
  }

  @media (max-width: 410px) {
    // &.unavailability-type {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    // }
  }
`;
export const StyledUnAvailabiltyFormWrapper = styled.div`
  margin-top: 3.2rem;
  .unavailability-form-heading {
    font-weight: 700;
    font-size: 2rem;
    color: #00171f;
  }
`;
export const StyledUnAvailabiltyInputWrapper = styled.div`
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
`;
