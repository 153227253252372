import {Alert, Button, Spin} from "antd";
import {CallsSection} from "./calls-section";
import {
  StyledNoUpcomingCallBox,
  StyledUpcomingCallsWrapper,
} from "./upcoming-calls.style";
import {useGetUpcomingCalls} from "../../../react-query-hooks/booking";
import {FullPageLoader} from "../../full-page-loader";
import {LoadingSpinner} from "../../icons/icons";
import {useGetServices} from "../../../react-query-hooks/service";
import {ServicesSection} from "./services-section";

const UpcomingCallsComponent = ({
  handleNewServiceBtnClick,
}: {
  handleNewServiceBtnClick: () => void;
}) => {
  const {
    data: upcomingCallsQuery,
    isLoading: isLoadingUpcomingCallsQuery,
    isFetching: isFetchingUpcomingCallsQuery,
    error: upcomingCallsQueryError,
  } = useGetUpcomingCalls();
  const {
    data: servicesQuery,
    isLoading: isLoadingServicesQuery,
    isFetching: isFetchingServicesQuery,
    error: servicesQueryErrorMessage,
  } = useGetServices(1, 1);

  if (isLoadingUpcomingCallsQuery) {
    return <FullPageLoader />;
  }
  if (isLoadingServicesQuery) {
    return <FullPageLoader />;
  }

  if (upcomingCallsQueryError) {
    return (
      <Alert
        message="Error"
        description={
          upcomingCallsQueryError && upcomingCallsQueryError.message
            ? upcomingCallsQueryError.message
            : "Error fetching upcoming calls"
        }
        type="error"
        showIcon
      />
    );
  }
  if (servicesQueryErrorMessage) {
    return (
      <Alert
        message="Error"
        description={
          servicesQueryErrorMessage && servicesQueryErrorMessage.message
            ? servicesQueryErrorMessage.message
            : "Error fetching services"
        }
        type="error"
        showIcon
      />
    );
  }

  return (
    <StyledUpcomingCallsWrapper>
      <>
        {servicesQuery.data.length > 0 && upcomingCallsQuery.transactions.length < 1 ? (
          <Spin
            spinning={isFetchingUpcomingCallsQuery}
            tip="Updating..."
            indicator={LoadingSpinner}
          >
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                {isFetchingUpcomingCallsQuery
                  ? ""
                  : "You do not have any upcoming 1:1 Calls"}
              </p>
            </StyledNoUpcomingCallBox>
          </Spin>
        ) : upcomingCallsQuery.transactions.length > 0 ? (
          <Spin
            spinning={isFetchingUpcomingCallsQuery}
            tip="Updating..."
            indicator={LoadingSpinner}
          >
            <CallsSection />
          </Spin>
        ) : (
          <StyledNoUpcomingCallBox>
            <p className="info-text">
              You do not have upcoming calls or services at the moment. Create a new
              service to get started
            </p>
            <div>
              <Button
                onClick={handleNewServiceBtnClick}
                // onClick={() => navigate("/dashboard/calls/new")}
                size="large"
                block
                shape="round"
                type="primary"
                htmlType="button"
              >
                Add new 1:1 service
              </Button>
            </div>
          </StyledNoUpcomingCallBox>
        )}
        {servicesQuery.data.length > 0 && (
          <Spin
            spinning={isFetchingServicesQuery}
            tip="Updating..."
            indicator={LoadingSpinner}
          >
            <ServicesSection showCallCategoryModal={handleNewServiceBtnClick} />
          </Spin>
        )}
      </>
    </StyledUpcomingCallsWrapper>
  );
};

export {UpcomingCallsComponent};
