import {
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  NumberOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Divider, message, Space} from "antd";
import {useForm} from "react-hook-form";
import {useCoachCancelBooking} from "../../../../../react-query-hooks/booking";
import {useCustomerCancelBooking} from "../../../../../react-query-hooks/customer";
import {TBooking} from "../../../../../redux/call/i-call";
import {
  StyledCallCardExtraInfo,
  StyledModalContentInfo,
  StyledModalContentWrapper,
} from "../call-card.style";
import {CustomInputComponent} from "../../../../input/input-component";
import {capitalizeFirstLetter} from "../../../../../services/helpers";

const CustomerCancelBookingFormSchema = yup.object().shape({
  description: yup.string().trim().required("Description is required"),
});

export type TCustomerCancelBookingFormValues = {
  description: string;
};

const CallDetailsModalContent = ({
  call,
  handleCancelModal,
  type,
}: {
  call: TBooking;
  handleCancelModal: () => void;
  type: "customer" | "coach";
}) => {
  const coachCancelQueryMutation = useCoachCancelBooking();
  const customerCancelQueryMutation = useCustomerCancelBooking();

  const callDate = new Date(
    Number(call.booking_year),
    Number(call.booking_month) - 1,
    Number(call.booking_date)
  ).toDateString();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<TCustomerCancelBookingFormValues>({
    resolver: yupResolver(CustomerCancelBookingFormSchema),
  });

  const onSubmit = async (values: TCustomerCancelBookingFormValues) => {
    customerCancelQueryMutation.mutate(
      {bookingRef: call.reference, description: values.description},
      {
        onSuccess() {
          handleCancelModal();
          message.success("The selected booking has been cancelled successfully!");
        },
      }
    );
  };
  return (
    <>
      <StyledModalContentWrapper>
        <StyledCallCardExtraInfo className="call-card-extra-info">
          {/* <div className="call-card-extra-info-item">
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">₦5,000</span>
              </Space>
            </div> */}
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">{callDate}</span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <ClockCircleOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.coach_from_time || call.timeslot}
                {/* {call.timeslot} - {call.endTime} */}
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.service.callDuration} minutes
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              {call.booking_type === "video" ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">
                    Video call{" "}
                    {call.is_bundle &&
                      `| ${call.bundle_session} of ${call.service.no_of_sessions} sessions`}
                  </span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">
                    Phone call{" "}
                    {call.is_bundle &&
                      `| ${call.bundle_session} of ${call.service.no_of_sessions} sessions`}
                  </span>
                </>
              )}
            </Space>
          </div>
          {call.phone_number && (
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <NumberOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.phone_number}
                </span>
              </Space>
            </div>
          )}
        </StyledCallCardExtraInfo>
      </StyledModalContentWrapper>
      <StyledModalContentWrapper>
        <p className="call-card-description">{capitalizeFirstLetter(call.notes)}</p>
      </StyledModalContentWrapper>
      {call.bookingQuestions &&
        JSON.parse(call.bookingQuestions as unknown as string).map(
          (question: {question: string; answer: string}, idx: any) => (
            <div key={idx}>
              <StyledModalContentWrapper>
                <StyledModalContentInfo>
                  <p className="content-text">
                    <strong>Question:</strong> {capitalizeFirstLetter(question?.question)}
                  </p>
                  <p className="content-text">
                    <strong>Answer:</strong> {capitalizeFirstLetter(question?.answer)}
                  </p>
                </StyledModalContentInfo>
              </StyledModalContentWrapper>
              <Divider style={{margin: 0}} />
            </div>
          )
        )}
      {type === "customer" ? (
        <StyledModalContentWrapper>
          <form style={{marginTop: "2.4rem"}} onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              <div style={{display: "flex", flexDirection: "column"}}>
                <CustomInputComponent
                  id="description"
                  type="textarea"
                  label="Reason for canceling"
                  placeholder="Let them know why you are cancelling the call"
                  error={errors.description?.message}
                  control={control}
                  name="description"
                />
              </div>
              <div>
                <Button
                  loading={customerCancelQueryMutation.isLoading}
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={
                    Object.keys(errors).length > 0 || Object.values(watch()).length < 1
                  }
                >
                  Cancel Call
                </Button>
              </div>
            </Space>
          </form>
        </StyledModalContentWrapper>
      ) : (
        <StyledModalContentWrapper>
          <Button
            onClick={() => {
              coachCancelQueryMutation.mutate(call.reference, {
                onSuccess() {
                  handleCancelModal();
                  message.success(
                    "The selected booking has been cancelled successfully!"
                  );
                },
              });
            }}
            type="link"
            style={{paddingLeft: 0, color: "#FF4D4F"}}
            loading={coachCancelQueryMutation.isLoading}
          >
            Cancel call
          </Button>
        </StyledModalContentWrapper>
      )}
    </>
  );
};

export {CallDetailsModalContent};
