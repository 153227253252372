import {Button} from "antd";
import {StyledContainer} from "../../features-section/features-section.style";
import {
  StyledUpcomingHeader,
  StyledUpcomingTabs,
  StyledUpcomingWrapper,
} from "./creators-upcoming-events.style";
import {useState} from "react";
import {UpcomingPaidEvents} from "./paid-events";
import {UpcomingFreeEvents} from "./free-events";

export function CreatorsUpcomingEvents() {
  const [activeTab, setActiveTab] = useState<"paid" | "free">("paid");

  return (
    <StyledContainer>
      <StyledUpcomingWrapper>
        <StyledUpcomingHeader>
          <h1 className="main-heading">
            Explore live events by our coaches and creators
          </h1>
          <p className="sub-heading">
            Explore live events by inspiring coaches and creators, designed to empower you
          </p>
        </StyledUpcomingHeader>
        <StyledUpcomingTabs>
          <div>
            <Button
              shape={activeTab === "paid" ? "round" : "default"}
              type={activeTab === "paid" ? "primary" : "text"}
              size="large"
              onClick={() => setActiveTab("paid")}
            >
              Paid events
            </Button>
          </div>
          <div>
            <Button
              shape={activeTab === "free" ? "round" : "default"}
              type={activeTab === "free" ? "primary" : "text"}
              size="large"
              onClick={() => setActiveTab("free")}
            >
              Free events
            </Button>
          </div>
        </StyledUpcomingTabs>
        {activeTab === "paid" && <UpcomingPaidEvents />}
        {activeTab === "free" && <UpcomingFreeEvents />}
      </StyledUpcomingWrapper>
    </StyledContainer>
  );
}
