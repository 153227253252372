import {CopyFilled, FacebookFilled, MailFilled, TwitterOutlined} from "@ant-design/icons";
import {Button, Space, Tooltip} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import {useGetService} from "../../../../react-query-hooks/service";
import {TServiceApiResponse} from "../../../../redux/service/i-service";
import {RootState} from "../../../../redux/store";
import {ProfileWidgetSkeleton} from "../../upcoming-calls/profile-widget/profile-widget-skeleton";
import {StyledShareServiceWidgetWrapper} from "./share-service-widget.style";
import {CopyIcon} from "../../../icons/icons";

const ShareServiceWidget = ({
  serviceId,
  serviceData,
}: {
  serviceId: string;
  serviceData: TServiceApiResponse;
}) => {
  const [showMore, setShowMore] = useState(false);
  const [toolTipText, setToolTipText] = useState<string>("copy service url");

  const getProfileQuery = useGetProfile();
  const serviceQueryInfo = useGetService(serviceId);
  const stripedHtml = serviceData.description.replace(/<[^>]+>/g, "");
  return (
    <StyledShareServiceWidgetWrapper>
      {serviceQueryInfo.isFetching ? (
        <ProfileWidgetSkeleton />
      ) : (
        <>
          <h2 className="profile-widget-heading">Share service link</h2>
          <div className="profile-widget-card">
            <Space size={16} direction="vertical" style={{display: "flex"}}>
              <div className="profile-widget-card-header">
                <h3 className="profile-widget-card-user-name">{serviceData.name}</h3>
                {/* <div>
                  <Button shape="circle" icon={<EditOutlined />} />
                </div> */}
              </div>
              <p className="profile-widget-card-bio">
                {showMore
                  ? stripedHtml
                  : stripedHtml && `${stripedHtml.substring(0, 100)}`}{" "}
                {stripedHtml && stripedHtml?.length > 100 && (
                  <Button
                    style={{paddingLeft: 0}}
                    size="small"
                    type="link"
                    shape="round"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : " ...Show more"}
                  </Button>
                )}
              </p>

              <div>
                <Button
                  size="large"
                  block
                  shape="round"
                  htmlType="button"
                  target="_blank"
                  href={`/${getProfileQuery.data?.username}/services/details?slug=${serviceData.slug}&serviceId=${serviceData.id}`}
                >
                  View service page
                </Button>
              </div>
              <p className="profile-widget-card-bio">Share service via</p>
              <div>
                <Space direction="horizontal" size={16}>
                  <div>
                    <Tooltip title={toolTipText}>
                      <Button
                        size="large"
                        shape="circle"
                        icon={<CopyIcon />}
                        style={{
                          backgroundColor: "#F4EEFC",
                          color: "#9254DE",
                          border: "none",
                        }}
                        onClick={() =>
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${getProfileQuery.data?.username}/meetings/${serviceData.slug}/${serviceData.id}`
                            )
                            .then(() => setToolTipText("copied"))
                        }
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<FacebookFilled />}
                      style={{
                        backgroundColor: "#EBEEF5",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${getProfileQuery.data?.username}/meetings/${serviceData.slug}/${serviceData.id}&amp`}
                      title="Share on twitter"
                    />
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<TwitterOutlined />}
                      target="_blank"
                      style={{
                        backgroundColor: "#E6F7FD",
                        color: "#00ACEE",
                        border: "none",
                      }}
                      href={`https://twitter.com/intent/tweet?text=I just created a service called "${serviceData.name}" on Coachli. Book a service with me today on Coachli!&url=${window.location.origin}/${getProfileQuery.data?.username}/meetings/${serviceData.slug}/${serviceData.id}&hashtags=coachli,useCoachli`}
                    />
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<MailFilled />}
                      style={{
                        backgroundColor: "#FBECEB",
                        color: "#DB4437",
                        border: "none",
                      }}
                      href={`mailto:?subject=I wanted you to see this site&amp;body=Book a service with me on Coachli ${window.location.origin}/${getProfileQuery.data?.username}/meetings/${serviceData.slug}/${serviceData.id}`}
                    />
                  </div>
                </Space>
              </div>
            </Space>
          </div>
        </>
      )}
    </StyledShareServiceWidgetWrapper>
  );
};

export {ShareServiceWidget};
