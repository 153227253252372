import styled from "styled-components";

export const StyledContactSection = styled.div`
  box-shadow: 0px 72px 64px 0px rgb(0 0 0 / 2%);
  padding: 3rem;
  @media (max-width: 992px) {
    padding: 0;
  }
`;
export const StyledContactDetailsBox = styled.div`
  background-color: #9747ff;
  box-shadow: 0px 24px 32px 0px rgb(0 0 0 / 8%);
  border-radius: 1.6rem;
  margin: 1rem;
  padding: 4rem;

  .contact-details-box-top {
    .contact-details-box-top-title {
      color: #ffffff;
      font-size: 3.2rem;
      font-weight: 700;
    }
    .contact-details-box-top-sub-title {
      margin-top: 2rem;
      color: #fff;
    }
  }
  .contact-details-box-bottom {
    margin-top: 16rem;
  }
  @media (max-width: 992px) {
    margin: 0;
  }
`;

export const StyledContactInfoList = styled.ul`
  list-style: none;
  padding: 0;

  .contact-info-list-item {
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: #fff;
      .link-text {
        font-size: 1.5rem;
        margin-left: 5px;
      }
    }
  }
`;
