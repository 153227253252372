import {Spin} from "antd";
import {useGetCustomerMessages} from "../../../../react-query-hooks/messages";
import {LoadingSpinner} from "../../../icons/icons";
import {StyledChatMenu} from "../../chat-list/chat-list.style";
import {CustomerMessageCustomerListItem} from "../message-customer-list-item/customer";

const CustomerMessageCustomerList = () => {
  const getCustomerMessagesQuery = useGetCustomerMessages();
  return (
    <StyledChatMenu>
      <div
        style={{
          borderRight: "1px solid #F0F0F0",
          // width: "30.4rem",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Spin
          spinning={getCustomerMessagesQuery.isFetching}
          // tip="Updating..."
          indicator={LoadingSpinner}
        >
          {getCustomerMessagesQuery.data &&
            getCustomerMessagesQuery.data.data.messages.map((contact) => (
              <CustomerMessageCustomerListItem key={contact.id} contact={contact} />
            ))}
        </Spin>
      </div>
    </StyledChatMenu>
  );
};

export {CustomerMessageCustomerList};
