import {ProfileWidget} from "../../../components/calls/upcoming-calls/profile-widget";
import {EditProfileFormComponent} from "../../../components/profile/edit-profile";
import {ProfileTemplateWidget} from "../../../components/widgets/template-widget";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageHeader, StyledPageWrapper} from "../../../styles/dashboard/calls/calls";

const EditProfilePage = () => {
  return (
    <DashboardWrapper pageTitle="Profile">
      <StyledPageHeader>Profile 🧑</StyledPageHeader>
      <StyledPageWrapper>
        <div className="main-area">
          <EditProfileFormComponent />
        </div>
        <div className="widget-area">
          {/* <ProfileWidget /> */}
          <ProfileTemplateWidget />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {EditProfilePage};
