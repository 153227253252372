import {configureStore} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import countryReducer from "./country/countrySlice";
import categoryReducer from "./category/category-slice";
import usernameReducer from "./username/username-slice";
import userRegisterReducer from "./auth/register/user-register-slice";
import userLoginReducer from "./auth/login/user-login-slice";
import userAccountActivationReducer from "./auth/account-activation/account-activation-slice";
import userResetPasswordReducer from "./auth/forgot-password/forgot-password-slice";
import userProfileReducer from "./profile/profile-slice";
import userServiceReducer from "./service/service-slice";
import userAvailability from "./availability/availability-slice";
import userUnavailability from "./unavailability/unavailability-slice";
import userTimezone from "./timezone/timezone-slice";
import publicProfileReducer from "./public-profile/public-profile-slice";
import bookingReducer from "./booking/booking-slice";
import customerReducer from "./customer/customer-slice";
import callReducer from "./call/call-slice";
import payoutReducer from "./payout/payout-slice";
import currencyReducer from "./currency/currency-slice";
import settingsReducer from "./settings/settings-slice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
const persistConfig = {
  key: "root",
  storage,
};
const profilePersistConfig = {
  key: "profile",
  storage,
};
const persistedLoginReducer = persistReducer(persistConfig, userLoginReducer);
const persistedProfileReducer = persistReducer(profilePersistConfig, userProfileReducer);

export const store = configureStore({
  reducer: {
    country: countryReducer,
    category: categoryReducer,
    currency: currencyReducer,
    username: usernameReducer,
    userLogin: persistedLoginReducer,
    userRegister: userRegisterReducer,
    userAccountActivation: userAccountActivationReducer,
    userResetPassword: userResetPasswordReducer,
    userProfile: persistedProfileReducer,
    userService: userServiceReducer,
    userAvailability: userAvailability,
    userUnavailability: userUnavailability,
    userTimezone: userTimezone,
    publicProfile: publicProfileReducer,
    booking: bookingReducer,
    userCustomer: customerReducer,
    userCall: callReducer,
    userPayout: payoutReducer,
    userSettings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
