import styled from "styled-components";

export const StyledProfileWidgetWrapper = styled.div`
  .profile-widget-heading {
    font-weight: 700;
    font-size: 2rem;
    color: #00171f;
  }

  .profile-widget-card {
    margin-top: 2.4rem;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 2.4rem;

    .profile-widget-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .profile-widget-card-user-name {
        font-weight: 600;
        font-size: 1.6rem;
        color: #000709;
        text-transform: capitalize;
        overflow: hidden;
        display: -webkit-box;
        /* display 1 lines only */
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .profile-widget-card-bio {
      font-weight: 400;
      font-size: 1.4rem;
      overflow: hidden;
      display: -webkit-box;
      /* display 1 lines only */
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
`;
