import {Button, Tag, Table} from "antd";
import type {ColumnsType} from "antd/lib/table";
import {StyledDescriptionText} from "../../messages/messages.style";
import {TRecording} from "../../../react-query-hooks/recordings";
import {DownloadOutlined} from "@ant-design/icons";
import {capitalizeFirstLetter} from "../../../services/helpers";
import moment from "moment";

interface DataType {
  key: string;
  name: string;
  date: string;
  // duration: string;
  deleteCount: string;
  link: string;
}

export const RecordingData = ({recordings}: {recordings: TRecording[]}) => {
  // console.log({sevenDays: moment([2023, 7, 30]).add(7, "days")});
  const recordingColumnData: DataType[] = recordings.map((record) => {
    const startDate = moment(new Date(record.createdAt));
    const deleteDate = startDate.add(7, "days");
    const deleteCount = deleteDate.fromNow();
    return {
      key: record.id.toString(),
      name: record.serviceName,
      date: new Date(record.createdAt).toDateString(),
      deleteCount,
      // duration: record.duration,
      // duration: Number(record.duration) / 60,
      link: record.recording,
    };
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText>
            {capitalizeFirstLetter(text ?? "")}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "17.425rem",
      key: "date",
      render: (text, record) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
          <Tag>Deletes {record.deleteCount}</Tag>
        </>
      ),
    },
    // {
    //   title: "Duration",
    //   dataIndex: "duration",
    //   width: "13.425rem",
    //   key: "duration",
    //   render: (text) => (
    //     <>
    //       <StyledDescriptionText>{text} minutes</StyledDescriptionText>
    //     </>
    //   ),
    // },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          href={record.link}
          download={record.name}
          //   onClick={() => handleDownload(record.link, record.name)}
        >
          Download recording
        </Button>
      ),
    },
  ];
  const handleDownload = (url: string, name: string) => {
    const modUrl = window.URL.createObjectURL(new Blob([url]));
    const link = document.createElement("a");
    link.href = modUrl;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div>
      <Table
        columns={columns}
        dataSource={recordingColumnData}
        pagination={{pageSize: 5}}
        //   loading={getCustomersQuery.isFetching}
        scroll={{x: 100}}
      />
    </div>
  );
};
