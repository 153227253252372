import {DownloadOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {StyledProfileWidgetWrapper} from "../../calls/upcoming-calls/profile-widget/profile-widget.style";

const LiveClassesTemplateWidget = () => {
  return (
    <div style={{marginTop: "2.6rem"}}>
      <StyledProfileWidgetWrapper>
        <h2 className="profile-widget-heading">Free live class cover templates</h2>
        <div className="profile-widget-card">
          <div style={{padding: "1.2rem", background: "#F6FFED", borderRadius: "5px"}}>
            <p>Download free live class cover Canva templates</p>
          </div>
          <div style={{marginTop: "1.6rem"}}>
            <Button
              href="https://www.canva.com/design/DAFhx6acEh8/ATqL3MGLFYe2LG4sw12tXw/view?utm_content=DAFhx6acEh8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
              block
              icon={<DownloadOutlined />}
              size="large"
              shape="round"
              target="_blank"
            >
              <span>Download here</span>
            </Button>
          </div>
        </div>
      </StyledProfileWidgetWrapper>
    </div>
  );
};

export {LiveClassesTemplateWidget};
