export type TExtraData = {
  tag: string;
  imgSrc: string;
  fallbackImgSrc: string;
  title: string;
  desc: string;
  extras: string[];
  isComingSoon?: boolean;
  isReverse?: boolean;
}[];
export type TExtraDataProps = {
  tag: string;
  imgSrc: string;
  fallbackImgSrc: string;
  title: string;
  desc: string;
  extras: string[];
  isComingSoon?: boolean;
  isReverse?: boolean;
};

export const EXTRAS_DATA: TExtraData = [
  {
    tag: "1:1 sessions",
    imgSrc: "/images/feature-1.webp",
    fallbackImgSrc: "/images/feature-1.jpg",
    title: "Host 1:1 sessions with your clients and audience",
    desc: "Effortlessly host paid or free 1:1 sessions with clients and audience using our streamlined platform. Seamless scheduling, and secure payment processing.",
    extras: [
      "Sell coaching bundles",
      "No need for Zoom or Google meet",
      "Automatic reminders",
      "Sell in multiple currencies",
      "Send messages to your clients",
    ],
  },
  {
    tag: "Create and host live  classes ",
    imgSrc: "/images/feature-2.webp",
    fallbackImgSrc: "/images/feature-2.jpg",
    title: "Create, host and manage live classes",
    desc: "Create and manage live classes effortlessly, with automated sales, reminders, and scheduling. Enhance engagement and focus on delivering an exceptional learning experience.",
    extras: [
      "Up to 100 participants",
      "Sell in multiple currencies",
      "No need for Zoom or Google Meet",
      "Record classes",
      "Automatic reminders",
    ],
    isReverse: true,
  },
  {
    tag: "Sell digital products (Coming soon)",
    imgSrc: "/images/feature-3.webp",
    fallbackImgSrc: "/images/feature-3.jpg",
    title: "Easily sell your digital products online",
    desc: "Simplify selling digital products with Coachli. Streamline the sales process, reach a wider audience, and monetise your digital creations with ease.",
    extras: [
      "Seamless setup",
      "Secure payment",
      "Beautiful sales page",
      "Sell in multiple currencies",
      "Sell unlimited products",
    ],
  },
];
