import styled from "styled-components";

export const StyledSidebarProfileWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2.4rem;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background: #fff;
`;
export const StyledSidebarProfileAvatarWrapper = styled.div`
  position: relative;
`;
export const StyledSidebarProfileAvatarEditIconWrapper = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  background-color: #fff;
  padding: 3px;
  border-radius: 50%;
`;
export const StyledSidebarProfileInfoWrapper = styled.div`
  .profile-name {
    font-size: 1.4rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    /* display 1 lines only */
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
export const StyledSidebarProfileShareWrapper = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  .share-text {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;
