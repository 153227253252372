import {UseFieldArrayAppend, UseFieldArrayRemove} from "react-hook-form";
import {
  Currency,
  IServiceFormValues,
} from "../../components/calls/service/service-form/i-service-form";
import {TBundleApiPayloadFormValues} from "../../components/calls/service/bundle-form/bundle-form";
import {TLiveClassesPayloadFormValues} from "../../components/live-classes/live-classes-form/live-classes-form";
import {TProductFormValues} from "../../components/products/product-form/form-values";
import {FlutterwaveIcon, PayPalIcon} from "../../components/icons/icons";

export const updateCurrencyOptions = (
  originalCurrencyList: string[],
  currencyArray: Currency[],
  defaultCurrency: string
) => {
  const alreadyPickedCurrency = [
    ...currencyArray.map((currency) => currency.callPriceCurrency.toLowerCase()),
    defaultCurrency.toLowerCase(),
  ];
  const newCurrencyOptions = originalCurrencyList.filter(
    (currencyOption) => !alreadyPickedCurrency.includes(currencyOption.toLowerCase())
  );

  return newCurrencyOptions;
};

export const updateCurrencyOptionsState = (
  originalCurrencyList: string[],
  currencyArray: Currency[],
  defaultCurrency: string,
  funcToSetState: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const newCurrencyOptions = updateCurrencyOptions(
    originalCurrencyList,
    currencyArray,
    defaultCurrency
  );
  funcToSetState(newCurrencyOptions);
};

export const handleAddNewCurrency = (
  originalCurrencyList: string[],
  currencyArray: Currency[],
  defaultCurrency: string,
  funcToSetState: React.Dispatch<React.SetStateAction<string[]>>,
  funcToAppendNew:
    | UseFieldArrayAppend<IServiceFormValues, "currencies">
    | UseFieldArrayAppend<TBundleApiPayloadFormValues, "currencies">
    | UseFieldArrayAppend<TLiveClassesPayloadFormValues, "currencies">
    | UseFieldArrayAppend<TProductFormValues, "currencies">
) => {
  const newCurrencyOptions = updateCurrencyOptions(
    originalCurrencyList,
    currencyArray,
    defaultCurrency
  );
  const copyNewCurrencyOptions = [...newCurrencyOptions];
  newCurrencyOptions.shift();
  funcToSetState(newCurrencyOptions);
  funcToAppendNew({callPriceCurrency: copyNewCurrencyOptions[0], callPrice: 1});
};

export const handleRemoveCurrency = (
  originalCurrencyList: string[],
  currencyArray: Currency[],
  defaultCurrency: string,
  funcToSetState: React.Dispatch<React.SetStateAction<string[]>>,
  funcToRemoveCurrency: UseFieldArrayRemove,
  index: number
) => {
  funcToRemoveCurrency(index);
  updateCurrencyOptionsState(
    originalCurrencyList,
    currencyArray,
    defaultCurrency,
    funcToSetState
  );
};

export const handleCurrencySelectOnChange = (
  originalCurrencyList: string[],
  currencyArray: Currency[],
  defaultCurrency: string,
  funcToSetState: React.Dispatch<React.SetStateAction<string[]>>,
  onChangeFunc: any,
  value: string
) => {
  onChangeFunc(value);
  const currencies = currencyArray;
  const alreadyPickedCurrencies = [
    ...currencies.map((currency) => currency.callPriceCurrency.toLowerCase()),
    defaultCurrency,
  ];
  const newCurrencyOptions = originalCurrencyList.filter(
    (currencyOption) =>
      !alreadyPickedCurrencies.includes(currencyOption.toLowerCase()) &&
      currencyOption.toLowerCase() !== value
  );

  funcToSetState(newCurrencyOptions);
};

export const paymentOptions = [
  {
    imageSrc: "/images/modal/modal-visa.png",
    alt: "visa",
  },
  {
    imageSrc: "/images/modal/modal-stripe.png",
    alt: "stripe",
  },
  {
    imageSrc: "/images/modal/modal-verve.png",
    alt: "verve",
  },
  {
    imageSrc: "/images/modal/modal-mc.png",
    alt: "mastercard",
  },
  {
    imageSrc: "/images/modal/modal-paypal.png",
    alt: "paypal",
  },
  {
    imageSrc: "/images/modal/modal-flutterwave.png",
    alt: "flutterwave",
  },
];
