// import {yupResolver} from "@hookform/resolvers/yup";
import {Button, DatePicker, message} from "antd";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {Space} from "antd";
// import {UnAvailabilityFormSchema} from "./unavailability-validation-schema";
import {IUnAvailabilityFormValues} from "./i-unavailability-form";
import {RangeValue} from "rc-picker/lib/interface";
import type {Moment} from "moment";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {StyledServiceButtonWrapper} from "../../../../styles/onboarding/onboarding";
import moment from "moment";
import {StyledUnAvailabiltyInputWrapper} from "../availability.style";
import {useUpdateUnavailability} from "../../../../react-query-hooks/availability";
import {TUnavailabilityApiResponse} from "../../../../redux/unavailability/i-unavailability-form";

const {RangePicker} = DatePicker;

interface IUnAvailabilityFormProps {
  unavailabilities: TUnavailabilityApiResponse[];
}

const UnavailabilityForm: React.FC<IUnAvailabilityFormProps> = ({unavailabilities}) => {
  const updateUnavailabilityQueryMutation = useUpdateUnavailability();

  const {
    handleSubmit,
    formState: {errors},
    reset,
    watch,
    control,
  } = useForm<IUnAvailabilityFormValues>({
    // resolver: yupResolver(UnAvailabilityFormSchema),
    mode: "onChange",
    defaultValues: {
      selections: unavailabilities.map(
        (entry: {from: moment.MomentInput; to: moment.MomentInput}) => [
          moment(entry.from),
          moment(entry.to),
        ]
      ),
    },
  });
  const {fields, append, remove} = useFieldArray({
    control,
    name: "selections",
  });

  const onSubmit = async (values: IUnAvailabilityFormValues) => {
    const selections = values.selections.map((selection) => ({
      from: moment(selection[0]).format("YYYY-MM-DD"),
      to: moment(selection[1]).format("YYYY-MM-DD"),
    }));
    updateUnavailabilityQueryMutation.mutate(
      {selections},
      {
        onSuccess: () => {
          window.scrollTo(0, 0);
          message.success("Your unavailable dates has been saved successfully!");
        },
      }
    );
  };
  return (
    <Space direction="vertical" size={24} style={{display: "flex"}}>
      <form style={{marginTop: "2.4rem"}} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <StyledUnAvailabiltyInputWrapper key={item.id}>
            <Controller
              control={control}
              name={`selections.${index}` as "selections.0"}
              render={({field: {onChange, value}}) => (
                <RangePicker
                  onChange={(values: RangeValue<Moment>, _) => onChange(values)}
                  value={value as any}
                />
              )}
            />
            <Button
              onClick={() => remove(index)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
              style={{border: 0, marginLeft: "1.6rem"}}
            />
          </StyledUnAvailabiltyInputWrapper>
        ))}
        <div>
          <Button
            onClick={() => append([["", ""]])}
            size="large"
            type="link"
            icon={<PlusCircleOutlined />}
            style={{paddingLeft: 0}}
          >
            Add date range
          </Button>
          {/* <Button>add</Button> */}
        </div>
        <StyledServiceButtonWrapper style={{marginTop: "2.4rem"}}>
          <div>
            <Button
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              loading={updateUnavailabilityQueryMutation.isLoading}
              block
              shape="round"
              type="primary"
              htmlType="submit"
              size="large"
            >
              Save changes
            </Button>
          </div>
          <div>
            <Button
              onClick={() => reset()}
              block
              shape="round"
              type="text"
              danger
              size="large"
            >
              Discard
            </Button>
          </div>
        </StyledServiceButtonWrapper>
      </form>
    </Space>
  );
};
export {UnavailabilityForm};
