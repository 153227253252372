import {Button} from "antd";
import styled from "styled-components";

export const StyledContainer = styled.div`
  max-width: 68rem;
  margin: 0 auto;
  padding: 6.5rem 2.5rem;
`;
export const StyledFormWrapper = styled.div`
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 4.1rem;
  padding: 3.2rem;

  @media only screen and (max-width: 450px) {
    padding: 1.6rem 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
`;
export const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.7rem;

  .username-text {
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
`;
export const StyledSocialInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;
export const StyledServiceButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`;
export const StyledLiveClassesButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  // @media only screen and (max-width: 450px) {
  //   flex-direction: column;
  //   gap: 1.6rem;
  // }
`;
export const StyledCreateServiceHeadingBox = styled.div`
  .main-heading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  .sub-heading {
    font-weight: 400;
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .info-block {
    background-color: #f5edff;
    border-radius: 5px;
    padding: 1rem;

    margin-top: 2rem;

    .info-block-text {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
`;
export const StyledCallLocationItem = styled.div<{active?: boolean}>`
  background-color: #ffffff;
  border: 1px solid ${({active}) => (active ? "#B67EFF" : "#f0f0f0")};
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;

  .icon-container {
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 1.7rem;
    align-self: stretch;
    // width: 6.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: ${({active}) => active && "#B67EFF"};
  }
  .loaction-info {
    color: #00171f;
    .title {
      font-weight: 600;
      font-size: 1.4rem;
    }
    .sub-info {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .icon-container {
      align-self: center;
    }
    .loaction-info {
      .sub-info {
        font-size: 1.2rem;
      }
    }
  }
`;
export const StyledQuestionInputContainer = styled.div`
  display: flex;

  &.topic-wrapper {
    gap: 5.4rem;
  }

  .input-wrapper {
    flex: 1;
  }
`;
export const StyledQuestionRequiredContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4rem;
  margin-top: 1.7rem;
  .required-text {
    font-weight: 400;
    font-size: 1.4rem;
    color: #000000;
  }
`;
export const StyledOnboardingTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
export const StyledOnboardingTag = styled.div<{active?: boolean}>`
  padding: 1.6rem;
  background: ${({active}) => (active ? "#F5EDFF" : "#ffffff")};
  border: 1px solid ${({active}) => (active ? "#9747FF" : "#d9d9d9")};
  border-radius: 5px;
  font-weight: 400;
  font-size: 1.4rem;
  color: #00171f;
  text-transform: capitalize;
  cursor: pointer;
`;
export const StyledDoThisLaterBtn = styled(Button)`
  @media (min-width: 576px) {
    text-align: right;
    padding-right: 0;
  }
`;
