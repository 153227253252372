import styled from "styled-components";

export const StyledChatListBox = styled.div`
  padding: 2.4rem;
  padding-bottom: 0;
  height: 60.8rem;
  overflow-y: scroll;
  //   flex: 1;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
`;
export const StyledNoChatBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // top: 10%;
`;

export const StyleSingleChatBox = styled.div<{type: "received" | "sent"}>`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: ${({type}) => (type === "received" ? "flex-start" : "flex-end")};
  .chat-time {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #828282;
  }
`;
export const StyledReceivedMessageBox = styled.div`
  background-color: #9747ff;
  border-radius: 14px 14px 14px 0px;
  padding: 1.4355rem 0.9rem;
  font-size: 1.2rem;
  color: #ffffff;
`;
export const StyledSentMessageBox = styled.div`
  background-color: #f5edff;
  border-radius: 14px 14px 0px 14px;
  padding: 1.4355rem 0.9rem;
  font-size: 1.2rem;
  color: #5b2b99;
`;
export const StyledChatMenu = styled.div`
  flex: 4;
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
export const StyledChatBox = styled.div`
  flex: 8;
  @media screen and (max-width: 768px) {
    flex: 10;
  }
`;
