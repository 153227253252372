import {Row, Col} from "antd";
import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {BrandImage} from "../../brand-image";
import {StyledContainer} from "../features-section/features-section.style";
import {StyledFooter, StyledFooterItem} from "./footer.style";

const Footer = () => {
  return (
    <StyledFooter>
      <div
        style={{
          margin: "0 1rem",
          padding: "8rem 0",
        }}
      >
        <StyledContainer>
          <Row gutter={[0, 30]}>
            <Col span={24} lg={6}>
              <div>
                <BrandImage variant="white" />
                <p style={{marginTop: "2rem", fontSize: "1.6rem"}}>
                  Monetise your time with Coachli
                </p>
              </div>
            </Col>
            <Col span={24} lg={6}>
              <StyledFooterItem>
                <h4 className="footer-item-title">Product</h4>
                <ul className="footer-item-list">
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on FAQs`)}
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://coachli.notion.site/Helpdesk-4039d986b45e4236a5ee76a5e4de45a5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs
                    </a>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Pricing`)}
                  >
                    <Link className="footer-item-list-item-link" to="/home/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Contact`)}
                  >
                    <Link className="footer-item-list-item-link" to="/home/contact">
                      Contact
                    </Link>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() =>
                      AnalyticsEvent("Home Footer", `Clicked on Join our community`)
                    }
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://join.slack.com/t/coachli-community/shared_invite/zt-1mkjrwhk4-dFmunoxUIr3Rfrn4D3MgBw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join our community
                    </a>
                  </li>
                </ul>
              </StyledFooterItem>
            </Col>
            <Col span={24} lg={6}>
              <StyledFooterItem>
                <h4 className="footer-item-title">Legal</h4>
                <ul className="footer-item-list">
                  <li
                    className="footer-item-list-item"
                    onClick={() =>
                      AnalyticsEvent("Home Footer", `Clicked on Terms & Conditions`)
                    }
                  >
                    <Link className="footer-item-list-item-link" to="/home/terms">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() =>
                      AnalyticsEvent("Home Footer", `Clicked on Privacy Policy`)
                    }
                  >
                    <Link
                      className="footer-item-list-item-link"
                      to="/home/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </StyledFooterItem>
            </Col>
            <Col span={24} lg={6}>
              <StyledFooterItem>
                <h4 className="footer-item-title">Follow Us</h4>
                <ul className="footer-item-list">
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Facebook`)}
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://www.facebook.com/useCoachli"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Twitter`)}
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://twitter.com/useCoachli"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Linkedin`)}
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://www.linkedin.com/company/coachli/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li
                    className="footer-item-list-item"
                    onClick={() => AnalyticsEvent("Home Footer", `Clicked on Instagram`)}
                  >
                    <a
                      className="footer-item-list-item-link"
                      href="https://www.instagram.com/usecoachli/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </StyledFooterItem>
            </Col>
          </Row>
        </StyledContainer>
      </div>
      <div
        style={{
          margin: "0 1rem",
          padding: "2.5rem 0",
          borderStyle: "solid",
          borderWidth: "1px 0px 0px 0px",
          borderColor: "#FFFFFF12",
          fontSize: "1.6rem",
        }}
      >
        <p>© 2023 All Rights Reserved.</p>
      </div>
    </StyledFooter>
  );
};

export {Footer};
