import {Avatar, Button, Modal, Space, Tooltip} from "antd";
import {StyledLiveClassesHostInfoWidget} from "../../../live-classes-section/details/main-details-section.style";
import {UserOutlined} from "@ant-design/icons";
import {useState} from "react";
import {StyledModalContentWrapper} from "../../../../calls/upcoming-calls/call-card/call-card.style";
import {PublicProfileLiveClassApiResponseEventCohost} from "../../../../../react-query-hooks/live-events/live-classes";
import {Link} from "react-router-dom";

type TOfferingCreatorDetails = {
  coHosts: PublicProfileLiveClassApiResponseEventCohost[];
  title: string;
};

export function OfferingCohostDetails({coHosts, title}: TOfferingCreatorDetails) {
  const [isCoHostsModalOpen, setIsCoHostsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsCoHostsModalOpen(false);
  };
  if (coHosts.length > 0) {
    return (
      <StyledLiveClassesHostInfoWidget>
        <h2 className="host-heading-text">{title}</h2>
        {coHosts.length > 1 ? (
          <div className="host-info-wrapper">
            <Avatar.Group
              maxCount={3}
              maxPopoverTrigger="hover"
              maxStyle={{
                color: "#9254de",
                backgroundColor: "#f4eefc",
                cursor: "pointer",
              }}
            >
              {coHosts.map((cohost) => (
                <Tooltip title={`${cohost.user.firstName} ${cohost.user.lastName}`}>
                  {cohost.user.avatar ? (
                    <Avatar size={50} src={cohost.user.avatar} />
                  ) : (
                    <Avatar size={50} icon={<UserOutlined />} />
                  )}
                </Tooltip>
              ))}
            </Avatar.Group>
            <div>
              <Button
                onClick={() => setIsCoHostsModalOpen(true)}
                type="text"
                size="large"
                style={{color: "#9747FF", paddingLeft: 0}}
              >
                View details
              </Button>
            </div>
          </div>
        ) : (
          <Link to={`/${coHosts[0].username}`}>
            <div className="host-info-wrapper">
              <div className="host-avatar-wrapper">
                {coHosts[0].user.avatar ? (
                  <Avatar size={87} src={coHosts[0].user.avatar} />
                ) : (
                  <Avatar size={87} icon={<UserOutlined />} />
                )}
              </div>
              <div className="host-name-info-wrapper">
                <p className="host-name">
                  {coHosts[0].user.firstName} {coHosts[0].user.lastName}
                </p>
                <p className="host-role">{coHosts[0].description}</p>
              </div>
            </div>
          </Link>
        )}
        <Modal
          title="Co-hosts"
          visible={isCoHostsModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <StyledModalContentWrapper>
            <Space direction="vertical" size={24}>
              {coHosts.map((cohost) => (
                <div key={cohost.id}>
                  <Space size={12}>
                    {cohost.user.avatar ? (
                      <Avatar size={87} src={cohost.user.avatar} />
                    ) : (
                      <Avatar size={87} icon={<UserOutlined />} />
                    )}
                    <Space direction="vertical" size={4}>
                      <p
                        style={{
                          color: "#000709",
                          fontWeight: 600,
                          fontSize: "1.6rem",
                        }}
                      >
                        {`${cohost.user.firstName} ${cohost.user.lastName}`}
                      </p>
                      {cohost.description && (
                        <p
                          style={{
                            color: "#000709",
                            fontWeight: 400,
                            fontSize: "1.4rem",
                          }}
                        >
                          {cohost.description}
                        </p>
                      )}

                      <Button
                        style={{
                          paddingLeft: 0,
                        }}
                        type="link"
                        size="large"
                        href={`/${cohost.username}`}
                        target="_blank"
                      >
                        View profile
                      </Button>
                    </Space>
                  </Space>
                </div>
              ))}
            </Space>
          </StyledModalContentWrapper>
        </Modal>
      </StyledLiveClassesHostInfoWidget>
    );
  } else {
    return null;
  }
}
