import {message} from "antd";
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {logoutReset} from "../react-query-hooks/logout-dispatch";
import {API_BASE_URL} from "../services/config";

export interface ICustomConfig extends AxiosRequestConfig {
  token?: string;
  data?: unknown;
}

// type TClientArgs = (url: string) => Promise<AxiosResponse<any, any>>;

export const client = async (
  url: string,
  {token, headers: customHeaders, ...customConfig}: ICustomConfig
) => {
  const config: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    url,
    headers: {
      ...(token && {Authorization: `Bearer ${token}`}),
      ...customHeaders,
    },
    ...customConfig,
  };
  // console.log({url});
  return axios
    .request(config)
    .then(async (response) => {
      // console.log({response: response.status});
      const data = response.data;
      return data;
    })
    .catch((err: AxiosError<{status: boolean; message: string}>) => {
      if (err.response?.status === 401) {
        // console.log({errAx: err});
        message.warn(err.response.data.message);
        logoutReset();
        // // refresh the page for them
        window.location.assign(`${window.location.origin}/auth/login`);
      }
      return Promise.reject(err.response?.data);
    });
};
