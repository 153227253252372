import * as yup from "yup";

const passwordUpdatechema = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .required("Please input your Password!")
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters"),
  currentPassword: yup
    .string()
    .trim()
    .required("New Password is a required field")
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters"),
});

export {passwordUpdatechema};
