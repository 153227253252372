import {Button, Space, message} from "antd";
import {
  // StyledCheckboxWrapper,
  StyledConnectCalendarBtnWrapper,
  StyledConnectCalendarWrapper,
  StyledHrLine,
} from "./connect-calendar.style";
import {CalendarColoredIcon} from "../../icons/icons";
import {CalendarOutlined, DeleteOutlined} from "@ant-design/icons";
import {
  useCoachConnectCalendarGoogleSignIn,
  useCoachDisconnectCalendar,
} from "../../../react-query-hooks/connect-calendar/connect-calendar";
import {useGetProfileContext} from "../../../context/profile/profile-context";
import {useGetCoachConnectCalendarStatusContext} from "../../../context/connect-calendar/connect-calendar-status.context";

export function ConnectCalendar() {
  const {state: profileState} = useGetProfileContext();
  const coachConnectCalendarGoogleSignInMutation = useCoachConnectCalendarGoogleSignIn();
  const coachDisconnectCalendarMutation = useCoachDisconnectCalendar();
  const {state: connectCalendarStatus} = useGetCoachConnectCalendarStatusContext();

  function handleConnectClick() {
    coachConnectCalendarGoogleSignInMutation.mutate(profileState.profileData.email, {
      onSuccess(data) {
        // console.log({data});
        setTimeout(() => {
          window.open(`${data.data.url}`);
        }, 0);
      },
    });
  }
  function handleDisconnectCalendar() {
    coachDisconnectCalendarMutation.mutate(profileState.profileData.email, {
      onSuccess(data) {
        message.success("Calendar disconnected successfully!");
      },
    });
  }
  return (
    <StyledConnectCalendarWrapper>
      <Space size={24} direction="vertical">
        <p style={{fontSize: "1.6rem"}}>
          Connect your calendar to let us know when you are available and update your
          calendar as you get bookings
        </p>
        <StyledConnectCalendarBtnWrapper>
          <Space size={16}>
            <div>
              <CalendarColoredIcon />
            </div>
            <span style={{fontSize: "1.6rem"}}>Google calendar</span>
          </Space>
          {connectCalendarStatus.connectCalendarStatus.needs_login && (
            <div>
              <Button
                className="connect-btn"
                type="primary"
                size="large"
                shape="round"
                onClick={handleConnectClick}
                loading={coachConnectCalendarGoogleSignInMutation.isLoading}
              >
                Connect
              </Button>
            </div>
          )}
        </StyledConnectCalendarBtnWrapper>
        {!connectCalendarStatus.connectCalendarStatus.needs_login && (
          <>
            <StyledHrLine />
            <StyledConnectCalendarBtnWrapper>
              <Space size={16}>
                <div>
                  <CalendarOutlined />
                </div>
                <span style={{fontSize: "1.6rem"}}>{profileState.profileData.email}</span>
              </Space>
              <div>
                <Button
                  style={{
                    backgroundColor: "#F5EDFF",
                    borderRadius: "2.5rem",
                    border: "none",
                  }}
                  size="large"
                  type="default"
                  icon={<DeleteOutlined />}
                  onClick={handleDisconnectCalendar}
                  loading={coachDisconnectCalendarMutation.isLoading}
                />
              </div>
            </StyledConnectCalendarBtnWrapper>
            {/* <StyledHrLine />
            <h3 style={{fontSize: "1.6rem", fontWeight: "600"}}>Configuration</h3>
            <StyledCheckboxWrapper>
              <p style={{fontSize: "1.6rem"}}>Add my events as they are booked</p>
              <div>
                <Checkbox />
              </div>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <p style={{fontSize: "1.6rem"}}>
                Check for conflict to prevent double bookings
              </p>
              <div>
                <Checkbox />
              </div>
            </StyledCheckboxWrapper> */}
          </>
        )}
      </Space>
    </StyledConnectCalendarWrapper>
  );
}
