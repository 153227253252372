import styled from "styled-components";

export const StyledCoachInfoBannerWrapper = styled.div`
  height: 20.6rem;
  width: 100%;

  .profile-banner-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const StyledCoachInfoSectionWrapper = styled.div`
  display: flex;
  // align-items: center;
  // gap: 3.7rem;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  max-width: 68.3rem;
  margin: 0 auto;
  align-items: center;

  .coach-main-info {
    .coach-main-info-name {
      font-weight: 700;
      font-size: 2.4rem;
      color: #00171f;
    }
    .coach-main-info-role {
      font-weight: 600;
      font-size: 1.6rem;
    }
    .coach-main-info-bio {
      font-weight: 400;
      font-size: 1.4rem;
      // word-break: break-all;
    }
    .coach-main-info-socials {
      display: flex;
      flex-wrap: wrap;
      gap: 0rem;
    }
  }

  @media (max-width: 500px) {
    // flex-direction: column;
    // gap: 1rem;
    // text-align: center;

    .coach-main-info {
      .coach-main-info-name {
        font-size: 2rem;
      }
      .coach-main-info-role {
        font-size: 1.4rem;
      }
      .coach-main-info-bio {
        font-weight: 400;
        font-size: 1.4rem;
      }
      .coach-main-info-socials {
        justify-content: center;
      }
    }
  }
`;

// export const StyleAvatar = styled(Avatar) `
// `
