import styled from "styled-components";

export const StyledSessionsSection = styled.section`
  grid-column: 1/3;
  grid-row: auto;
`;
export const StyledSessionsSectionBox = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
`;
export const StyledSessionsSectionBoxItem = styled.div`
  border: 1px solid #f0f0f0;
  padding: 3.3rem 1.7rem;

  .session-name {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .session-box-item-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 4.7rem;
    margin-top: 1.2rem;

    .session-box-item-content {
      span {
        font-size: 1.4rem;
        color: #00171f;
      }
    }
  }
`;
