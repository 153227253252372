import {yupResolver} from "@hookform/resolvers/yup";
import {useProductPurchaseContext} from "../../../../../../context/products/product-purchase-context";
import {useForm, Controller} from "react-hook-form";
import {useEffect} from "react";
import {productPurchasePhoneNumberRequiredSchema, productPurchaseSchema} from "./schema";
import {
  TPurchaseProductApiResponse,
  TPurchaseProductPayload,
} from "../../../../../../react-query-hooks/products";
import {Button, Col, Input, Row, Select, Space, message} from "antd";
import {CustomInputComponent} from "../../../../../input/input-component";
import {StyledInputLabelWrapper} from "../../../../../input/input.style";
import {AnalyticsEvent} from "../../../../../../utils/analytics";
import {UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {CurrencyResponse} from "../../../../../../redux/service/i-service";
import {PaymentOptions} from "../../../../../payment-options";
import {handleProductSuccessBookingLink} from "../../../../../../utils/products";
import {useNavigate} from "react-router-dom";
import {COUNTRY_CODES_WITH_FLAG} from "../../../../../../services/country-code";

export type TProductPurchaseFormValues = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  numberCode: string;
  phone_number?: string;
  currency: {
    label: string;
    value: string | number | null;
  } | null;
};

export type TProductPurchaseFormProps = {
  updateStep: (step: number) => void;
  productPurchaseMutation: UseMutationResult<
    {
      data: TPurchaseProductApiResponse;
    },
    AxiosError<unknown, any>,
    TPurchaseProductPayload,
    () => void
  >;
  productCurrencies: CurrencyResponse[];
};

export function ProductPurchaseForm({
  updateStep,
  productPurchaseMutation,
  productCurrencies,
}: TProductPurchaseFormProps) {
  const navigate = useNavigate();
  const {state, dispatch} = useProductPurchaseContext();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    setValue,
  } = useForm<TProductPurchaseFormValues>({
    resolver: yupResolver(
      state.productData?.product.request_phone_number
        ? productPurchasePhoneNumberRequiredSchema
        : productPurchaseSchema
    ),
    mode: "onChange",
    defaultValues: {
      numberCode: "+234",
      currency: state.selectedCurrency
        ? state.selectedCurrency
        : productCurrencies.length > 0
        ? {
            value: productCurrencies[0].id,
            label: `${
              productCurrencies[0].currency
            } ${productCurrencies[0].amount.toLocaleString("en-US")}`,
          }
        : null,
    },
  });

  useEffect(() => {
    if (state.selectedCurrency) {
      setValue("currency", state.selectedCurrency);
    }
  }, [state.selectedCurrency, setValue]);

  function handleCurrencyListOptions() {
    const mappedCurrencies = productCurrencies.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString("en-US")}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies) {
      return mappedCurrencies;
    } else {
      return [];
    }
  }

  const handleCurrencyChange = (value: any) => {
    const selectedValue = productCurrencies.find((x) => x.id === Number(value));
    const selectedCurrency = {
      label: `${selectedValue?.currency} ${selectedValue?.amount.toLocaleString(
        "en-US"
      )}`,
      value: selectedValue?.id as number,
    };
    dispatch({
      type: "update-selected-currency",
      payload: {
        selectedCurrency,
      },
    });
    // console.log({value, selectedValue, selectedCurrency});
  };

  // console.log({curenOpt: handleCurrencyListOptions()});

  const onSubmit = async (values: TProductPurchaseFormValues) => {
    // console.log({values});
    dispatch({
      type: "update-product-purchase-form-values",
      payload: values,
    });
    function handleCurrencyAndAmountPayload() {
      const selectedCurrencyId = values.currency?.value;
      const selectedCurrencyValue = productCurrencies.find(
        (x) => x.id === Number(selectedCurrencyId)
      );

      if (selectedCurrencyValue) {
        return {
          id: selectedCurrencyValue.id,
        };
      } else if (selectedCurrencyId && productCurrencies.length > 0) {
        return {
          id: productCurrencies[0].id,
        };
      } else {
        return {
          id: null,
        };
      }
    }
    const payload: TPurchaseProductPayload = {
      currencyId: handleCurrencyAndAmountPayload().id,
      emailAddress: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      phone_number: values.phone_number
        ? `${values.numberCode}${values.phone_number}`
        : undefined,
      productId: state.productData?.product.id as number,
    };
    productPurchaseMutation.mutate(payload, {
      onSuccess: (data) => {
        // console.log("productPurchaseResponse", data);
        dispatch({
          type: "update-product-purchase-api-response",
          payload: data.data,
        });
        if (data.data.amount === 0) {
          // message.success("Your Booking has been created successfully!");
          navigate(
            handleProductSuccessBookingLink({
              productId: String(state.productData?.product.id),
              slug: state.productData?.product.slug as string,
              username: state.productData?.profile.username as string,
            })
          ); //success page
        } else {
          if (data.data.paymentChannel === "flutterwave") {
            AnalyticsEvent(
              "Flutterwave payment",
              "Flutterwave payment init for products"
            );
            // console.log("flutterwave", data.data);
            updateStep(2); //flutterwave step
          }
          if (data.data.paymentChannel === "paystack") {
            AnalyticsEvent("Paystack payment", "Paystack payment init for products");
            // console.log("payastackk", data.data);
            updateStep(3); //paystack step
          }
          if (data.data.paymentChannel === "stripe") {
            AnalyticsEvent("Stripe payment", "Stripe payment init for products");
            setTimeout(() => {
              window.location.href = data.data.redirectUrl as string;
            }, 0);
          }
        }
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <Row gutter={[27, 24]}>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="firstName"
                label="First name"
                placeholder="Enter your first name"
                error={errors.firstName?.message}
                control={control}
                name="firstName"
              />
            </Col>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="last_name"
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                error={errors.lastName?.message}
                control={control}
              />
            </Col>
          </Row>
          <CustomInputComponent
            id="email"
            name="emailAddress"
            label="Enter your email address"
            type="email"
            placeholder="Please enter your email address"
            error={errors.emailAddress?.message}
            control={control}
          />
          {state.productData?.product.request_phone_number && (
            <div>
              <StyledInputLabelWrapper>
                <label id="phone_number_label" htmlFor="phone_number">
                  Enter phone number
                  {state.productData?.product.request_phone_number ? null : (
                    <span style={{color: "gray", marginLeft: "3px"}}>(optional)</span>
                  )}
                </label>
              </StyledInputLabelWrapper>
              <Controller
                control={control}
                name="phone_number"
                render={({field}) => (
                  <Input
                    {...field}
                    placeholder="Enter phone number"
                    size="large"
                    status={errors.phone_number?.message && "error"}
                    addonBefore={
                      <Controller
                        control={control}
                        name="numberCode"
                        render={({field}) => (
                          <>
                            <Select
                              {...field}
                              // defaultValue={COUNTRY_CODES[0].dial_code}
                              size="large"
                              optionFilterProp="children"
                              showSearch
                              filterOption={(input, option) => {
                                return option.value
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            >
                              {COUNTRY_CODES_WITH_FLAG.map((code) => (
                                <Select.Option key={code.dialCode} value={code.dialCode}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <img
                                      src={code.flag}
                                      width={30}
                                      height={20}
                                      alt={code.name}
                                    />
                                    <span>{code.dialCode}</span>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </>
                        )}
                      />
                    }
                  />
                )}
              />
              {errors.phone_number?.message && (
                <div role="alert" className="ant-form-item-explain-error">
                  {errors.phone_number?.message}
                </div>
              )}
            </div>
          )}

          {productCurrencies.length < 1 ? null : handleCurrencyListOptions().length >
            1 ? (
            <div>
              <StyledInputLabelWrapper>
                <label id="currency_label" htmlFor="currency">
                  Select currency
                </label>
              </StyledInputLabelWrapper>

              <Controller
                control={control}
                name="currency"
                render={({field: {onChange, value, ref}}) => (
                  <Select
                    // suffixIcon={
                    //   <DownOutlined size={15} style={{fontWeight: "bold", color: "#00171F"}} />
                    // }
                    labelInValue
                    onChange={(value) => {
                      // console.log({value});
                      // onChange({value: value.value, label: value.label});
                      handleCurrencyChange(value.value);
                    }}
                    value={value}
                    size={"large"}
                    defaultValue={value}
                    options={handleCurrencyListOptions()}
                  />
                )}
              />
            </div>
          ) : null}

          <div>
            <Button
              loading={productPurchaseMutation.isLoading}
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              shape="round"
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              {(productCurrencies.length === 1 && productCurrencies[0].amount === 0) ||
              productCurrencies.length === 0
                ? "Continue"
                : "Continue to payment"}
            </Button>
          </div>
          <PaymentOptions />
        </Space>
      </form>
    </div>
  );
}
