import {Space} from "antd";
import {StyledSuccessDetailsCard} from "./success-details-card.style";
import {ReactNode} from "react";

type TLiveClassSuccessDetailsCard = {
  title: string;
  imageSrc?: string | null;
  extraInfo: {
    icon: ReactNode;
    value: string;
  }[];
};

export function LiveClassSuccessDetailsCard({
  extraInfo,
  imageSrc,
  title,
}: TLiveClassSuccessDetailsCard) {
  return (
    <StyledSuccessDetailsCard>
      <Space size={12} direction="vertical" style={{display: "flex"}}>
        <p className="success-details-card-title">{title}</p>
        {imageSrc && (
          <div className="success-details-card-image-wrapper">
            <img src={imageSrc} alt={title} className="success-details-card-image" />
          </div>
        )}
        <div>
          <Space direction="horizontal" wrap size={12}>
            {extraInfo.map((info, index) => (
              <div key={info.value + index}>
                <Space size={8}>
                  {info.icon}
                  <span style={{fontWeight: "600", fontSize: "1.2rem"}}>
                    {info.value}
                  </span>
                </Space>
              </div>
            ))}
          </Space>
        </div>
      </Space>
    </StyledSuccessDetailsCard>
  );
}
