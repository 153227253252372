import {Alert, Spin} from "antd";
import {useState, useEffect} from "react";
import {BookingDataProvider} from "../../../../../../../container/booking/booking-context";
import {
  TCustomerPaidBundleApiResponse,
  useGetCustomerBundleBooking,
} from "../../../../../../../react-query-hooks/customer";
import {
  useCreateBundleBooking,
  useGetPublicProfileBooking,
  useGetPublicProfileBookingTimeslots,
} from "../../../../../../../react-query-hooks/public-profile";
import {StyledModalContentWrapper} from "../../../../call-card/call-card.style";
import {CalendarContent} from "./calendar-content";
import {SuccessContent} from "./success-content";

const CalendarModalContent = ({bundle}: {bundle: TCustomerPaidBundleApiResponse}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const updateStep = (step: number) => {
    if (step > 2) {
      return;
    }
    setCurrentStep(step);
  };
  const getPublicProfileBookingQuery = useGetPublicProfileBooking({
    mentorUsername: bundle.coach_username,
    id: String(bundle.service.id),
    slug: bundle.service.slug,
  });
  const getPublicProfileBookingTimeslotsMutation = useGetPublicProfileBookingTimeslots();
  const createBundleBookingQueryMutation = useCreateBundleBooking();

  if (getPublicProfileBookingQuery.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40vh",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (getPublicProfileBookingQuery.isError) {
    return (
      <StyledModalContentWrapper>
        <Alert
          message="Error"
          description={getPublicProfileBookingQuery.error.message}
          type="error"
          showIcon
        />
      </StyledModalContentWrapper>
    );
  }

  function handleView() {
    let content;
    switch (currentStep) {
      case 2:
        content = <SuccessContent />;
        break;
      default:
      case 1:
        content = (
          <CalendarContent
            timeslotsMutation={getPublicProfileBookingTimeslotsMutation}
            publicProfileData={getPublicProfileBookingQuery.data}
            bundle={bundle}
            bundleBookingMutation={createBundleBookingQueryMutation}
            updateStep={updateStep}
          />
        );
    }
    return content;
  }
  return (
    <StyledModalContentWrapper>
      <BookingDataProvider>{handleView()}</BookingDataProvider>
    </StyledModalContentWrapper>
  );
};

export {CalendarModalContent};
