import {
  StyledContainer,
  StyledSectionHeader,
} from "../../../components/home/features-section/features-section.style";
import {StyledHeroSection} from "../../../components/home/hero-section/hero-section.style";
import {PrivacyPolicyInfo} from "../../../components/home/privacy-policy";
import {HomeWrapper} from "../../../layouts/home-wrapper/home-wrapper";

export default function PrivacyPolicyPage() {
  return (
    <HomeWrapper>
      <StyledHeroSection style={{paddingTop: "15rem"}}>
        <StyledContainer style={{maxWidth: "80.7rem"}}>
          <StyledSectionHeader>
            <h2 className="section-header-main-title">Privacy policy</h2>
          </StyledSectionHeader>
          <PrivacyPolicyInfo />
        </StyledContainer>
      </StyledHeroSection>
    </HomeWrapper>
  );
}
