import {UpcomingEvents} from "../../../components/dashboard-home/upcoming-events";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledDashboardHomeMainSectionWrapper} from "../../../styles/dashboard-home/dashboard-home";
import {Button, Col, Row, Space} from "antd";
import {DashboardMetrics} from "../../../components/dashboard-metrics";
import {useState} from "react";
import {ProfileTemplateWidget} from "../../../components/widgets/template-widget";
import {TourWidget} from "../../../components/widgets/tour-widget";
import {AudioOutlined, PhoneOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {ProductIcon} from "../../../components/icons/icons";

const DashboardHomepage = () => {
  const [openCallCategoryModal, setOpenCallCategoryModal] = useState<boolean>(false);
  return (
    <DashboardWrapper pageTitle="Get started with Coachli">
      <DashboardMetrics />
      <StyledDashboardHomeMainSectionWrapper>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            <div className="dashboard-home-main-details-upcoming-content">
              <UpcomingEvents
                setOpenCallCategoryModal={setOpenCallCategoryModal}
                openCallCategoryModal={openCallCategoryModal}
              />
            </div>
          </Col>
          <Col span={24} lg={{span: 8}}>
            <div className="dashboard-home-main-details-widget-content">
              <Space direction="vertical" size={40} style={{display: "flex"}}>
                <TourWidget
                  textInfo="Watch a video guide on how to start creating services for your customers"
                  buttons={[
                    <Button
                      href="https://www.loom.com/share/62d5824c84d24ed7bb1c596c7c8a34d6?sid=bd23eb7c-e3b2-4d3b-8a56-e3ab5b583f2c"
                      block
                      icon={<PhoneOutlined />}
                      size="large"
                      shape="round"
                      target="_blank"
                    >
                      <span>1:1 calls</span>
                    </Button>,
                    <Button
                      href="https://www.loom.com/share/42b9e6a7bedd41adabdf7f2e3e867613?sid=5be5070b-91c2-4b74-b7d9-4b75ace46420"
                      block
                      icon={<AudioOutlined />}
                      size="large"
                      shape="round"
                      target="_blank"
                    >
                      <span>Live events</span>
                    </Button>,
                    <Button
                      href="https://www.loom.com/share/31b30bd926f64ac48bcacda15726f83b"
                      block
                      icon={<ProductIcon />}
                      size="large"
                      shape="round"
                      target="_blank"
                    >
                      <span>Products</span>
                    </Button>,
                  ]}
                />
                {/* <BrowseEventsWidget /> */}
                <ProfileTemplateWidget />
              </Space>
            </div>
          </Col>
        </Row>
      </StyledDashboardHomeMainSectionWrapper>
    </DashboardWrapper>
  );
};

export {DashboardHomepage};
