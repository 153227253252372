import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Button, message, Space} from "antd";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {CustomInputComponent} from "../../../../components/input/input-component";
import {AuthFormWrapper} from "../../../../layouts/auth-form-wrapper";
import {useCustomerLogin} from "../../../../react-query-hooks/auth";
import {customerLoginSchema} from "../login-form-validation-schema";

const CustomerLoginPage = () => {
  const customerLoginQueryMutation = useCustomerLogin();
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<{emailAddress: string}>({
    resolver: yupResolver(customerLoginSchema),
    mode: "onChange",
  });

  const onSubmit = async (values: {emailAddress: string}) => {
    // console.log("cutomer login", values);
    customerLoginQueryMutation.mutate(
      {emailAddress: values.emailAddress.toLowerCase()},
      {
        onSuccess: () =>
          message.success("A confirmation link has been sent to your email address!"),
      }
    );
  };

  return (
    <AuthFormWrapper>
      <div>
        <h2 className="form-main-heading">Log in to your customer account</h2>
      </div>
      {customerLoginQueryMutation.isSuccess && (
        <Alert
          message="Success"
          description="A confirmation link has been sent to your email address!"
          type="success"
          showIcon
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={29} style={{display: "flex"}}>
          <CustomInputComponent
            id="email"
            name="emailAddress"
            label="Email address"
            type="email"
            placeholder="Please enter your email address"
            error={errors.emailAddress?.message}
            control={control}
          />
          <div>
            <Button
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              block
              loading={customerLoginQueryMutation.isLoading}
              size="large"
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Log in
            </Button>
          </div>
          <p>
            Are you a Coach? <Link to="/auth/login">Log in to your account</Link>
          </p>
        </Space>
      </form>
    </AuthFormWrapper>
  );
};

export default CustomerLoginPage;
