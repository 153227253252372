import {Space} from "antd";
import {useGetUpcomingCalls} from "../../../../react-query-hooks/booking";
import {CallCard} from "../call-card";
import {motion, AnimatePresence} from "framer-motion";
import {SortBookingByTime} from "../../../../services/helpers";
import {TBooking} from "../../../../redux/call/i-call";

const CallsSection = () => {
  const {
    data: upcomingCallsQuery,
    isLoading: isLoadingupcomingCallsQuery,
    error: upcomingCallsQueryError,
  } = useGetUpcomingCalls();
  return (
    <motion.div>
      <AnimatePresence>
        <Space direction="vertical" size={42} style={{display: "flex"}}>
          {SortBookingByTime(upcomingCallsQuery?.transactions as TBooking[]).map(
            (call) => (
              <CallCard key={call.id} call={call} />
            )
          )}
        </Space>
      </AnimatePresence>
    </motion.div>
  );
};

export {CallsSection};
