import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {verifyUsernameService} from "./username-service";

interface IInitialState {
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

type Ctx = {
  [key: string]: any; // 👈️ variable key
};

const initialState: IInitialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Verify username
export const verifyUsername = createAsyncThunk<
  // Return type of the payload creator
  Ctx,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("username/verifyUsername", async (username, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await verifyUsernameService(username, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const usernameSlice = createSlice({
  name: "username",
  initialState,
  reducers: {
    resetUsernameState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUsername.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(verifyUsername.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(verifyUsername.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {resetUsernameState} = usernameSlice.actions;

export default usernameSlice.reducer;
