import {Alert, Space, Spin} from "antd";
import {useGetCustomerPaidBundles} from "../../../../../../react-query-hooks/customer";
import {StyledPageHeader} from "../../../../../../styles/dashboard/calls/calls";
import {FullPageLoader} from "../../../../../full-page-loader";
import {LoadingSpinner} from "../../../../../icons/icons";
import {StyledNoUpcomingCallBox} from "../../../upcoming-calls.style";
import {PurchasedBundleCard} from "./card";
import {CustomPagination} from "../../../../../pagination";
import {useState} from "react";
import {PaginationMeta} from "../../../../../../redux/service/i-service";

const PurchasedBundles = () => {
  const [page, setPage] = useState(1);
  const {data, isError, isLoading, isFetching, error, isPreviousData} =
    useGetCustomerPaidBundles(page);
  if (isLoading) {
    return <FullPageLoader />;
  }

  if (
    isError &&
    error.message !==
      "Sorry, You do not have any purchased bundle! Please, purchase a service and try again!"
  ) {
    return <Alert message="Error" description={error.message} type="error" showIcon />;
  }
  return (
    <div>
      <StyledPageHeader>Purchased bundles</StyledPageHeader>
      <div style={{marginTop: "4rem"}}>
        {data && data.data.bundles.length < 1 ? (
          <Spin spinning={isFetching} tip="Updating..." indicator={LoadingSpinner}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                You do not have purchased bundles at the moment.
              </p>
            </StyledNoUpcomingCallBox>
          </Spin>
        ) : (
          <Spin spinning={isFetching} tip="Updating..." indicator={LoadingSpinner}>
            <Space direction="vertical" size={42} style={{display: "flex"}}>
              {/* <pre>{JSON.stringify(data.bundles)}</pre> */}
              {data?.data.bundles.map((bundle) => (
                <div key={bundle.id}>
                  <PurchasedBundleCard call={bundle} />
                </div>
              ))}
              <CustomPagination
                isPreviousData={isPreviousData}
                page={page}
                paginationMeta={data?.meta as PaginationMeta}
                setPage={setPage}
              />
            </Space>
          </Spin>
        )}
      </div>
    </div>
  );
};

export {PurchasedBundles};
