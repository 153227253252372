import {Menu} from "antd";
import styled from "styled-components";

export const StyledMenuDropdown = styled(Menu)`
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 23rem;
  padding: 2rem;
  .ant-dropdown-menu-item {
    background: none;
  }
  .copy-filter-checkbox-wrapper {
    padding-top: 1.5rem;
    font-wieght: bold;
  }
`;
