import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledBannerSection = styled(motion.div)`
  margin-top: 8.1rem;
  background: linear-gradient(180deg, #0e0725 0%, #5c03bc 100%);
  min-height: 70.9rem;
  //   padding: 20rem 0 5rem 0;
  //   @media (max-width: 1024) {
  //     padding: 15rem 0 10rem 0;
  //   }
  //   @media (max-width: 767px) {
  //     padding: 10rem 0 7.5rem 0;
  //   }
  position: relative;
`;
export const StyledBannerSectionContent = styled(motion.div)`
  padding: 4.2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .banner-main-heading {
    font-weight: 700;
    font-size: 5.8rem;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    max-width: 112rem;
  }
  .banner-sub-heading {
    margin-top: 2.7rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    max-width: 83.9rem;
  }
  @media (max-width: 1024px) {
    .banner-main-heading {
      font-size: 4rem;
      line-height: 48px;
    }
    .banner-sub-heading {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    .banner-main-heading {
      font-size: 3.6rem;
      line-height: 38px;
    }
    .banner-sub-heading {
      font-size: 1.4rem;
    }
  }
`;

export const StyledBannerSectionImageWrapper = styled(motion.div)`
  margin-bottom: -29.3rem;
  margin-top: 4.8rem;
`;
