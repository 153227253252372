import {StyledHorizontalStepperItem} from "./step.style";

interface IStepProps {
  index: number;
  currentStep: number;
  title: string;
}

const Step: React.FC<IStepProps> = ({index, currentStep, title}) => {
  return (
    <StyledHorizontalStepperItem
      completed={index < currentStep}
      active={index === currentStep}
      index={index}
    >
      {/* <div className="stepper-content"> */}
      <p className="stepper-title">{title}</p>
      {/* </div> */}
    </StyledHorizontalStepperItem>
  );
};

export {Step};
