import styled from "styled-components";

export const StyledPublicProfileNavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  /* padding-top: 0.5rem; */
  padding-top: 2rem;
  padding-bottom: 3rem;
`;

export const StyledPublicProfileNav = styled.div`
  border-radius: 5rem;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 0.8rem 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  .coach_info {
    display: flex;
    align-items: center;
    gap: 1.4rem;

    .coach_info_details {
      display: none;
    }
  }

  @media (min-width: 425px) {
    padding: 1.6rem 2.4rem;
    .coach_info {
      .coach_info_details {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }
`;
