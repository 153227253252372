import {Dispatch, PropsWithChildren, useReducer} from "react";
import {
  TLiveClassActions,
  initialLiveClassState,
  liveClassBookingReducer,
  TLiveClassState,
} from "../reducers/live-class-reducer";
import {createContext} from "./create-context";
import {useParams, useSearchParams} from "react-router-dom";
import momentTimezone from "moment-timezone";
import {useGetPublicProfileLiveClass} from "../react-query-hooks/live-events/live-classes";
import {Alert, Button, Result} from "antd";
import {FullPageLoader} from "../components/full-page-loader";

type TBookingContextState = {
  state: typeof initialLiveClassState;
  dispatch: Dispatch<TLiveClassActions>;
};

const [useContext, ContextProvider] = createContext<TBookingContextState>();

export const useLiveClassBookingContext = useContext;

export const LiveClassBookingProvider = ({children}: PropsWithChildren) => {
  const [reducerState, dispatch] = useReducer(
    liveClassBookingReducer,
    initialLiveClassState
  );

  const [searchParams] = useSearchParams();
  const {username} = useParams();
  // const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const eventId = searchParams.get("eventId");
  const customerTimezone = momentTimezone.tz.guess(true);
  const currentUrlStep = searchParams.get("currentStep");
  const bookingRef = searchParams.get("reference");

  const params = {
    username,
    slug,
    eventId,
    customerTimezone,
  };

  const getPublicProfileLiveClassQuery = useGetPublicProfileLiveClass(params);

  if (!username || !slug || !eventId || !customerTimezone) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  if (getPublicProfileLiveClassQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getPublicProfileLiveClassQuery.isError) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={getPublicProfileLiveClassQuery.error.message}
        extra={[
          <Button
            onClick={() => getPublicProfileLiveClassQuery.refetch()}
            shape="round"
            type="default"
            key="console"
          >
            Try again
          </Button>,
        ]}
      />
    );
  }

  const eventData = getPublicProfileLiveClassQuery.data;
  const urlParams: TLiveClassState["urlParams"] = {
    ...params,
    bookingRef,
    currentUrlStep,
  };
  const newState: TLiveClassState = {
    ...reducerState,
    eventData,
    urlParams,
  };

  return (
    <ContextProvider value={{state: newState, dispatch}}>{children}</ContextProvider>
  );
};
