import {Divider, message} from "antd";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {GoogleButton} from "../../../components/google-button";
import {AuthFormWrapper} from "../../../layouts/auth-form-wrapper";
import {RootState} from "../../../redux/store";
import {AnalyticsEvent} from "../../../utils/analytics";
import {LoginForm} from "./login-form";

const LoginPage = () => {
  const {isError: isErrorUserProfile, message: userProfileMessage} = useSelector(
    (state: RootState) => state.userProfile
  );

  useEffect(() => {
    if (
      isErrorUserProfile &&
      userProfileMessage === "Your session has expired! Please, login and try again!"
    ) {
      message.warning(userProfileMessage);
    }
  }, [isErrorUserProfile, userProfileMessage]);

  return (
    <AuthFormWrapper>
      <div>
        <h2 className="form-main-heading">Log in to your account</h2>
        <p className="form-sub-heading">
          Don't have an account yet?{" "}
          <Link
            onClick={() =>
              AnalyticsEvent("Login page", `Clicked on Create new account link`)
            }
            to="/auth/register"
          >
            Create new account
          </Link>
        </p>
      </div>

      <div>
        <GoogleButton type="login" />
      </div>
      <div>
        <Divider plain style={{margin: 0}}>
          <span style={{color: "#8C8C8C", fontWeight: 600, fontSize: "1.4rem"}}>Or</span>
        </Divider>
      </div>
      <LoginForm />
    </AuthFormWrapper>
  );
};

export default LoginPage;
