import {Drawer} from "antd";
import styled from "styled-components";

export const StyledRescheduleDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 58.4rem !important;
  }
  @media (max-width: 600px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;
