import styled from "styled-components";

export const StyledPageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 4rem;
`;
export const StyledUpcomingCallsWrapper = styled.div`
  padding: 3.2rem 0;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const StyledNoUpcomingCallBox = styled.div`
  padding: 3rem 1rem;
  background: #f0f5ff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .info-text {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: #00171f;
    max-width: 37.4rem;
  }
`;
export const StyledDescriptionText = styled.p`
  text-overflow: ellipsis;
  width: 15rem;
  white-space: nowrap;
  overflow: hidden;
`;
