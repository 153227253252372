import {ContactSection} from "../../../components/home/contact";
import {
  StyledContainer,
  StyledSectionHeader,
} from "../../../components/home/features-section/features-section.style";
import {StyledHeroSection} from "../../../components/home/hero-section/hero-section.style";
import {HomeWrapper} from "../../../layouts/home-wrapper/home-wrapper";

const ContactPage = () => {
  return (
    <HomeWrapper>
      <StyledHeroSection style={{paddingTop: "15rem"}}>
        <StyledContainer style={{maxWidth: "106.2rem"}}>
          <StyledSectionHeader>
            <h2 className="section-header-main-title">Contact us</h2>
          </StyledSectionHeader>
          <ContactSection />
        </StyledContainer>
      </StyledHeroSection>
    </HomeWrapper>
  );
};

export default ContactPage;
