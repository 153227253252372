export function TermsLastRevised() {
  return (
    <div>
      <p
        style={{
          fontSize: "1.6rem",
        }}
      >
        Last revised: 2023-01-30
      </p>
    </div>
  );
}
