import {Button, Spin, Upload, UploadFile, UploadProps, message} from "antd";
import {StyledCoverImageWrapper} from "./cover-image-upload.style";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {CloudUploadOutlined, PictureOutlined} from "@ant-design/icons";
import {useState} from "react";
import {UploadChangeParam} from "antd/lib/upload";
import {API_BASE_URL} from "../../../../services/config";
import type {RcFile} from "antd/es/upload/interface";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import ImgCrop from "antd-img-crop";

function ProfileCoverImageUpload() {
  const getProfileQuery = useGetProfile();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [imageUrl, setImageUrl] = useState(() => getProfileQuery.data?.cover_image);
  const [uploading, setUploading] = useState(false);

  const beforeUpload = async (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isLt5M;
  };

  const handleImageChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "error") {
      setUploading(false);
      message.error(info.file.response.message);
      return;
    }

    if (info.file.status === "done") {
      setUploading(false);
      const imgUrl = info.file.response.data.url;
      setImageUrl(imgUrl);
      message.success("Your cover image has been uploaded successfully!");
      getProfileQuery.refetch();
    }
  };
  return (
    <Spin spinning={uploading}>
      <StyledCoverImageWrapper>
        <div>
          <PictureOutlined style={{fontSize: "3.6rem", color: "#BFBFBF"}} />
        </div>
        <p className="upload-text">Minimum width 480 pixels, 16:9 recommended</p>
        <div>
          <ImgCrop rotationSlider aspect={1.9}>
            <Upload
              name="coverImage"
              // showUploadList={false}
              headers={{
                authorization: `Bearer ${userInfo.accessToken}`,
              }}
              action={`${API_BASE_URL}cover-image`}
              onChange={handleImageChange}
              beforeUpload={beforeUpload}
              method="POST"
              listType="picture"
              maxCount={1}
              defaultFileList={
                imageUrl
                  ? [
                      {
                        uid: "1",
                        name: "cover_image",
                        status: "done",
                        url: `${imageUrl ?? ""}`,
                      },
                    ]
                  : undefined
              }
            >
              <Button
                style={{paddingLeft: 0}}
                type="link"
                size="large"
                icon={<CloudUploadOutlined />}
              >
                Upload cover photo
              </Button>
            </Upload>
          </ImgCrop>
        </div>
      </StyledCoverImageWrapper>
    </Spin>
  );
}

export {ProfileCoverImageUpload};
