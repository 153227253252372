import {Button, Divider, Space, Spin} from "antd";
import {useServiceBookingContext} from "../../../../../context/service-booking/service-booking.context";
import {
  StyledBookingTimeComponent,
  StyledBookingTimeComponentItemWrapper,
  StyledNewBookingTimeComponentConfirmButton,
} from "../../../../../styles/booking/booking";
import {StyledNoUpcomingCallBox} from "../../../../calls/upcoming-calls/upcoming-calls.style";

export function ServiceBookingTimeSlots() {
  const {state: serviceBookingState, dispatch} = useServiceBookingContext();
  if (!serviceBookingState.serviceData) {
    return <div></div>;
  }
  function handleShowBookingModal() {
    dispatch({
      type: "show-booking-modal",
      payload: true,
    });
  }

  return (
    <div>
      {serviceBookingState.timeSlotsMutation?.isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "65vh",
            width: "100%",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {serviceBookingState.timeSlotsMutation?.isSuccess && (
        <StyledBookingTimeComponent id="time-component">
          <div className="time-component-heading">
            {serviceBookingState.selectedDateString
              ? serviceBookingState.selectedDateString.toDateString()
              : new Date().toDateString()}
          </div>
          <Divider style={{marginTop: "1.8rem", marginBottom: "2.4rem"}} />
          <Space size={8} direction="vertical" style={{display: "flex"}}>
            {serviceBookingState.timeSlotsMutation?.data.data.timeSlots.length < 1 && (
              <StyledNoUpcomingCallBox>
                <p className="info-text">No time slot available for this date</p>
              </StyledNoUpcomingCallBox>
            )}
            {serviceBookingState.timeSlotsMutation?.data.data.timeSlots.map(
              (timeSlot: string) => (
                <StyledBookingTimeComponentItemWrapper key={timeSlot}>
                  <StyledNewBookingTimeComponentConfirmButton
                    block
                    size="large"
                    onClick={() =>
                      dispatch({
                        type: "update-selected-time",
                        payload: timeSlot,
                      })
                    }
                  >
                    {timeSlot}
                  </StyledNewBookingTimeComponentConfirmButton>
                  {serviceBookingState.timeSelected === timeSlot && (
                    <Button
                      onClick={handleShowBookingModal}
                      size="large"
                      type="primary"
                      shape="round"
                      block
                    >
                      Confirm
                    </Button>
                  )}
                </StyledBookingTimeComponentItemWrapper>
              )
            )}
          </Space>
        </StyledBookingTimeComponent>
      )}
    </div>
  );
}
