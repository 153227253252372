import {Dispatch, PropsWithChildren, useReducer} from "react";
import {createContext} from "../create-context";
import {useParams, useSearchParams} from "react-router-dom";
import {Alert, Button, Result} from "antd";
import {FullPageLoader} from "../../components/full-page-loader";
import {
  productPurchaseReducer,
  initialProductPurchaseState,
  TProductPurchaseActions,
  TProductPurchaseState,
} from "../../reducers/products/product-details-booking-reducer";
import {useGetPublicProfileProductDetails} from "../../react-query-hooks/products";

type TProductPurchaseContextState = {
  state: typeof initialProductPurchaseState;
  dispatch: Dispatch<TProductPurchaseActions>;
};

const [useContext, ContextProvider] = createContext<TProductPurchaseContextState>();

export const useProductPurchaseContext = useContext;

export const ProductPurchaseProvider = ({children}: PropsWithChildren) => {
  const [reducerState, dispatch] = useReducer(
    productPurchaseReducer,
    initialProductPurchaseState
  );

  const [searchParams] = useSearchParams();
  const {username} = useParams();
  // const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const productId = searchParams.get("productId");

  const params = {
    username,
    slug,
    productId,
  };

  const getPublicProfileProductDetailsQuery = useGetPublicProfileProductDetails(params);

  if (!username || !slug || !productId) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  if (getPublicProfileProductDetailsQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getPublicProfileProductDetailsQuery.isError) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={getPublicProfileProductDetailsQuery.error.message}
        extra={[
          <Button
            onClick={() => getPublicProfileProductDetailsQuery.refetch()}
            shape="round"
            type="default"
            key="console"
          >
            Try again
          </Button>,
        ]}
      />
    );
  }

  const productData = getPublicProfileProductDetailsQuery.data;
  const newState: TProductPurchaseState = {
    ...reducerState,
    productData,
    urlParams: params,
  };

  return (
    <ContextProvider value={{state: newState, dispatch}}>{children}</ContextProvider>
  );
};
