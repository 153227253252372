import styled from "styled-components";

export const StyledDayTimeWrapper = styled.div`
  position: relative;
  display: flex;
  // align-items: center;
  padding: 2.7rem 0;
  border-top: 1px solid #f0f0f0;
  // justify-content: space-between;
  gap: 4.8rem;

  .time-picker-extra-btns {
    position: absolute;
    right: 0;
    display: flex;
    // align-items: center;
    gap: 1.6rem;
  }

  @media only screen and (max-width: 700px) {
    display: block;
    .time-picker-extra-btns {
      top: 2rem;
    }
  }
`;
export const StyledTimeInputWrapper = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;

  .main-day-time-input {
    // margin-left: 4.8rem;
    // margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 8px;

    .line {
      height: 2px;
      background-color: #00171f;
      width: 1rem;
    }

    .availability-delete-btn {
      border: 0;
      margin-left: 1.6rem;
      background-color: #f5edff;
      color: #9747ff;
    }
  }

  .main-day-time-input:not(:first-child) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 3rem;

    .main-day-time-input {
      .availability-delete-btn {
        margin-left: auto;
      }
    }
  }

  // @media only screen and (max-width: 650px) {
  //   flex-direction: column;
  //   gap: 1.6rem;
  // }
`;
