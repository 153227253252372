import * as yup from "yup";
import {SearchOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Avatar, Button, Input, message, Radio} from "antd";
import {Controller, useForm} from "react-hook-form";
import {
  TMessageContact,
  TMessageResObj,
  TSendMessageCoachPayload,
  useGetCoachMessageContacts,
  useSendMessageCoach,
} from "../../../react-query-hooks/messages";
import {capitalizeFirstLetter} from "../../../services/helpers";
import {
  StyledModalContentInfo,
  StyledModalContentWrapper,
} from "../../calls/upcoming-calls/call-card/call-card.style";
import {FullPageLoader} from "../../full-page-loader";
import {
  StyledMessageModalInputWrap,
  StyledMessageModalInput,
  StyledMessageModalInputIcon,
  StyledMessagesListImage,
  StyledMessagesListImageH2,
  StyledMessageContactListItem,
} from "../messages.style";
import {CustomModal} from "../../modal";
import {useMessageContext} from "../../../context/message-context";
import {CustomInputComponent} from "../../input/input-component";
import {ChangeEvent, useEffect, useState} from "react";

const formSchema = yup.object().shape({
  selectedContact: yup.string().trim().required("Service name is required"),
});

const msgFormSchema = yup.object().shape({
  message: yup.string().trim().required("Service name is required"),
});

type TContactListProps = {
  showModalState: boolean;
  showModalStateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

const ContactList = ({showModalState, showModalStateUpdate}: TContactListProps) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const getCoachMessageContactsQuery = useGetCoachMessageContacts();

  const [searchResults, setSearchResults] = useState(
    getCoachMessageContactsQuery.data?.data.contacts
  );

  const sendMessageCoachQueryMutation = useSendMessageCoach();
  const {state, dispatch} = useMessageContext();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    let filteredResults;
    if (getCoachMessageContactsQuery.data) {
      filteredResults = [...getCoachMessageContactsQuery.data.data.contacts];
      filteredResults = filteredResults.filter((item) => {
        return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      });
      setSearchResults(filteredResults);
    }
  };

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    reset,
  } = useForm<{selectedContact: string}>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });
  const {
    handleSubmit: handleMessageSubmit,
    formState: {errors: messageErrors},
    control: messageControl,
    reset: messageReset,
    watch: messageWatch,
  } = useForm<{message: string}>({
    resolver: yupResolver(msgFormSchema),
    mode: "onChange",
  });

  const handleOk = () => {
    handleSubmit(onSubmit);
  };
  const handleMessageOk = () => {
    handleMessageSubmit(onMessageSubmit);
  };
  const handleCancel = () => {
    showModalStateUpdate(false);
    reset();
  };
  const handleMessageCancel = () => {
    setShowMessageModal(false);
    messageReset();
    reset();
  };

  useEffect(() => {
    setSearchResults(getCoachMessageContactsQuery.data?.data.contacts);
  }, [getCoachMessageContactsQuery.data?.data.contacts]);

  const onSubmit = async (values: {selectedContact: string}) => {
    // console.log("Selected contact::::", values.selectedContact);
    const contact = getCoachMessageContactsQuery.data?.data.contacts.find(
      (contact) => contact.name + contact.email_address === values.selectedContact
    );

    // const contactIndex = getCoachMessageContactsQuery.data?.data.contacts.indexOf(
    //   contact as TMessageContact
    // );

    const newContact: TMessageContact = {
      email_address: contact?.email_address.toLowerCase() as string,
      name: contact?.name as string,
    };
    dispatch({
      type: "add-to-coach-message-contact-list",
      payload: {
        contact: newContact,
      },
    });
    showModalStateUpdate(false);
    setShowMessageModal(true);
  };
  const onMessageSubmit = async (values: {message: string}) => {
    const payload: TSendMessageCoachPayload = {
      customerEmailAddress: state.coachContact?.email_address.toLowerCase() as string,
      customerName: state.coachContact?.name as string,
      message: values.message,
      userId: null,
    };
    sendMessageCoachQueryMutation.mutate(payload, {
      onSuccess: (data) => {
        message.success("Message delivered successfully!");
        dispatch({
          type: "set-coach-active-message",
          payload: {
            activeMessage: {
              createdAt: "",
              customerName: state.coachContact?.name as string,
              emailAddress: state.coachContact?.email_address.toLowerCase() as string,
              id: Math.random(),
              isReadCustomer: false,
              isReadMentor: false,
              updatedAt: "",
              userId: "",
            },
          },
        });
        showModalStateUpdate(false);
        setShowMessageModal(false);
        messageReset();
        reset();
      },
    });
  };

  return (
    <>
      <CustomModal
        visibleState={showModalState}
        title="Send a new message"
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button
            key="ok"
            type="primary"
            shape="round"
            onClick={handleSubmit(onSubmit)}
            disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
          >
            Select
          </Button>,
          <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          {getCoachMessageContactsQuery.isLoading ? (
            <FullPageLoader />
          ) : getCoachMessageContactsQuery.isError ? (
            <Alert
              message="Error"
              description={getCoachMessageContactsQuery.error.message}
              type="error"
              showIcon
            />
          ) : (
            <>
              <StyledMessageModalInputWrap>
                <Input.Group compact style={{display: "flex"}}>
                  <StyledMessageModalInput
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <StyledMessageModalInputIcon>
                    <Button
                      shape="default"
                      size="large"
                      icon={<SearchOutlined />}
                      style={{
                        backgroundColor: "#fff",
                        color: "#3B5998",
                        border: "1px solid #f5f5f5",
                      }}
                      type="link"
                      target="_blank"
                      title="Search message"
                    />
                  </StyledMessageModalInputIcon>
                </Input.Group>
              </StyledMessageModalInputWrap>
              <div
                style={{
                  height: "30rem",
                  overflowY: "scroll",
                }}
              >
                <Controller
                  control={control}
                  name="selectedContact"
                  render={({field: {onChange, value}}) => (
                    <Radio.Group
                      style={{width: "100%"}}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {searchResults?.map((contact, index) => (
                        <StyledMessageContactListItem
                          key={contact.name + contact.email_address}
                        >
                          <StyledMessagesListImage>
                            {/* <StyledMessagesListImageInitials>{contact.}</StyledMessagesListImageInitials> */}
                            <Avatar
                              size="large"
                              style={{color: "#5B2B99", backgroundColor: "#F5EDFF"}}
                            >
                              {contact.name.split(" ")[1].charAt(0).toUpperCase()}
                              {contact.name.split(" ")[0].charAt(0).toUpperCase()}
                              {/* LC */}
                            </Avatar>
                            <StyledMessagesListImageH2>
                              {capitalizeFirstLetter(contact.name.split(" ")[1])}{" "}
                              {capitalizeFirstLetter(contact.name.split(" ")[0])}
                            </StyledMessagesListImageH2>
                          </StyledMessagesListImage>
                          <div style={{marginLeft: "auto"}}>
                            <Radio value={contact.name + contact.email_address} />
                          </div>
                        </StyledMessageContactListItem>
                      ))}
                    </Radio.Group>
                  )}
                />
              </div>
            </>
          )}
        </StyledModalContentWrapper>
      </CustomModal>
      <CustomModal
        visibleState={showMessageModal}
        title={`Send a new message to ${state.coachContact?.name}`}
        okFunc={handleMessageOk}
        cancelFunc={handleMessageCancel}
        footerContent={[
          <Button
            key="msgOk"
            shape="round"
            type="primary"
            onClick={handleMessageSubmit(onMessageSubmit)}
            loading={sendMessageCoachQueryMutation.isLoading}
            disabled={
              Object.keys(messageErrors).length > 0 ||
              Object.values(messageWatch()).length < 1
            }
          >
            {sendMessageCoachQueryMutation.isLoading ? "Sending..." : "Send"}
          </Button>,
          <Button key="cancel" shape="round" type="default" onClick={handleMessageCancel}>
            Cancel
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          {/* <StyledModalContentInfo>
            <p className="content-text">
              Hey there! send your first message to {state.coachContact?.name}
            </p>
          </StyledModalContentInfo> */}
          <div>
            <CustomInputComponent
              id="message"
              name="message"
              type="textarea"
              label={`Send a personal message to ${state.coachContact?.name}`}
              placeholder="Type a message"
              error={messageErrors.message?.message}
              control={messageControl}
            />
          </div>
        </StyledModalContentWrapper>
      </CustomModal>
    </>
  );
};

export {ContactList};
