import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {getPublicProfileService} from "./public-profile-service";

interface IInitialState {
  publicProfileData: {
    id?: number;
    userId?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    country?: string;
    username?: string;
    description?: string;
    shortBio?: string;
    twitter?: string | null;
    facebook?: string | null;
    instagram?: string | null;
    tiktok?: string | null;
    discord?: string | null;
    youtube?: string | null;
    dribble?: string | null;
    behance?: string | null;
    twitch?: string | null;
    linkedin?: string | null;
    github?: string | null;
    website?: string | null;
    avatar?: string | null;
    services?: {
      id?: number;
      userId?: number;
      name?: string;
      description?: string;
      status_id?: number;
      callDuration?: number;
      callPriceCurrency?: string;
      callPrice?: number;
      hideServiceFromMenu?: boolean;
      videoLocation?: boolean;
      audioLocation?: boolean;
      statusName?: string;
    }[];
  };
  createStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  publicProfileData: {},
  createStatus: "idle",
  fetchStatus: "idle",
  message: "",
};

//get public profile by username
export const getPublicProfile = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("publicProfile/getPublicProfile", async (username, thunkAPI) => {
  try {
    return await getPublicProfileService(username);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const publicProfileSlice = createSlice({
  name: "publicProfile",
  initialState,
  reducers: {
    resetUserPublicProfileState: (state) => {
      state.createStatus = "idle";
      state.fetchStatus = "idle";
      state.message = "";
      state.publicProfileData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicProfile.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(getPublicProfile.fulfilled, (state, action) => {
        state.fetchStatus = "resolved";
        state.publicProfileData = action.payload.data;
      })
      .addCase(getPublicProfile.rejected, (state, action: any) => {
        state.fetchStatus = "rejected";
        state.message = action.payload;
        state.publicProfileData = {};
      });
  },
});

export const {resetUserPublicProfileState} = publicProfileSlice.actions;

export default publicProfileSlice.reducer;

// export {};
