import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getCurrenciesService} from "./currency-service";

interface IInitialState {
  currencies: string[];
  fetchCurrienciesStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  currencies: [],
  fetchCurrienciesStatus: "idle",
  message: "",
};

//Get available currencies
export const getCurrencies = createAsyncThunk(
  "currency/getCurrencies",
  async (_, thunkAPI) => {
    try {
      return await getCurrenciesService();
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    reset: (state) => {
      state.fetchCurrienciesStatus = "idle";
      state.message = "";
    },
    currenciesLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.fetchCurrienciesStatus = "pending";
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.fetchCurrienciesStatus = "resolved";
        state.currencies = action.payload.data;
      })
      .addCase(getCurrencies.rejected, (state, action: any) => {
        state.fetchCurrienciesStatus = "rejected";
        state.message = action.payload;
        state.currencies = [];
      });
  },
});

export const {reset, currenciesLogoutReset} = currencySlice.actions;

export default currencySlice.reducer;
