import {Button, Pagination, PaginationProps} from "antd";
import {Dispatch, SetStateAction} from "react";
import {PaginationMeta} from "../../redux/service/i-service";

type CustomPaginationProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  isPreviousData: boolean;
  paginationMeta?: PaginationMeta;
};

export function CustomPagination({
  page,
  setPage,
  isPreviousData,
  paginationMeta,
}: CustomPaginationProps) {
  const onChange: PaginationProps["onChange"] = (page) => {
    // console.log({page});
    setPage(page);
  };
  const totalCount =
    Number(paginationMeta?.per_page ?? 5) * Number(paginationMeta?.total_pages ?? 1);
  //   console.log({totalCount});
  return (
    <div>
      <Button
        onClick={() => setPage((old) => Math.max(old - 1, 1))}
        disabled={page === 1}
      >
        Prev
      </Button>
      <Button
        onClick={() => {
          if (!isPreviousData && Number(paginationMeta?.next_page ?? 0) !== 0) {
            setPage((old) => old + 1);
          }
        }}
        // Disable the Next Page button until we know a next page is available
        disabled={isPreviousData || Number(paginationMeta?.next_page ?? 0) === 0}
      >
        Next
      </Button>
      {/* <Pagination
        current={page}
        onChange={onChange}
        pageSize={paginationMeta?.per_page ?? 5}
        total={totalCount}
        showSizeChanger={false}
      /> */}
    </div>
  );
}
