import {Breadcrumb, Spin} from "antd";
import {Link, useParams} from "react-router-dom";
import {useGetCustomer} from "../../../react-query-hooks/customer";

const CustomerBreadcrumb = () => {
  const {id} = useParams();

  const getCustomerQuery = useGetCustomer(id);
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/dashboard/customers">Customers</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {getCustomerQuery.isFetching ? <Spin /> : getCustomerQuery.data?.emailAddress}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export {CustomerBreadcrumb};
