import {Alert, Button, Space} from "antd";
import {useGetProfile} from "../../../react-query-hooks/profile";
import {StyledShareServiceWidgetWrapper} from "../../calls/service/share-service-widget/share-service-widget.style";
import {ProfileWidgetSkeleton} from "../../calls/upcoming-calls/profile-widget/profile-widget-skeleton";
import {ShareOfferingWidget} from "../../public-profile/offering/share-offering";
import {
  handleMailLink,
  handleFacebookLink,
  handleProductLink,
  handleTwitterLink,
} from "../../../utils/products";
import {TGetProductApiResponse} from "../../../react-query-hooks/products";

const ShareProductWidget = ({
  productData,
  isFetching,
}: {
  isFetching: boolean;
  productData: TGetProductApiResponse;
}) => {
  const getProfileQuery = useGetProfile();
  if (getProfileQuery.isLoading) {
    return <ProfileWidgetSkeleton />;
  }

  if (getProfileQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getProfileQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  const extraParams = {
    facebookLink: handleFacebookLink({
      username: getProfileQuery.data.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just created a product titled ${productData.product.title} on Coachli!`,
      username: getProfileQuery.data.username,
    }),
    mailLink: handleMailLink({
      body: `I just created a product titled ${productData.product.title} on Coachli! ${window.location.origin}/${getProfileQuery.data.username}`,
      subject: "",
    }),
    copyLink: handleProductLink({
      productId: productData.product.id,
      slug: productData.product.slug,
      username: getProfileQuery.data.username,
      windowNav: true,
    }),
  };
  let stripedHtml = productData.product.description.replace(/<[^>]+>/g, "");
  const productDescription = stripedHtml;

  return (
    <StyledShareServiceWidgetWrapper>
      {isFetching ? (
        <ProfileWidgetSkeleton />
      ) : (
        <>
          <h2 className="profile-widget-heading">Share product link</h2>
          <div className="profile-widget-card">
            <Space size={16} direction="vertical" style={{display: "flex"}}>
              <div className="profile-widget-card-header">
                <h3 className="profile-widget-card-user-name">
                  {productData.product.title}
                </h3>
              </div>
              <p className="profile-widget-card-bio">{productDescription}</p>

              <div>
                <Button
                  size="large"
                  block
                  shape="round"
                  htmlType="button"
                  target="_blank"
                  href={handleProductLink({
                    productId: productData.product.id,
                    slug: productData.product.slug,
                    username: getProfileQuery.data.username,
                    windowNav: true,
                  })}
                >
                  View product page
                </Button>
              </div>
              <ShareOfferingWidget title="Share product via" extraParams={extraParams} />
            </Space>
          </div>
        </>
      )}
    </StyledShareServiceWidgetWrapper>
  );
};

export {ShareProductWidget};
