import {
  StyledBtnLink,
  StyledLinkContainer,
  StyledNavLink,
  StyledStaticNavLink,
} from "./nav-link.style";

interface INavLinkProps {
  children?: React.ReactNode;
  to: string;
  label: string;
  icon: React.ReactNode;
  isComingSoon?: boolean;
  isBtnLink?: boolean;
}

const NavLink: React.FC<INavLinkProps> = ({
  children,
  to,
  label,
  icon,
  isComingSoon,
  isBtnLink,
}) => {
  return (
    <StyledLinkContainer>
      {isComingSoon ? (
        <StyledStaticNavLink>
          <div className="nav-icon-container">{icon}</div>
          <span className="nav-label">{label}</span>
          <div className="coming-soon-bubble">Coming soon</div>
        </StyledStaticNavLink>
      ) : isBtnLink ? (
        <StyledBtnLink href={to} target="_blank">
          <div className="nav-icon-container">{icon}</div>
          <span className="nav-label">{label}</span>
        </StyledBtnLink>
      ) : (
        <StyledNavLink to={to}>
          <div className="nav-icon-container">{icon}</div>
          <span className="nav-label">{label}</span>
          {/* {isComingSoon && <div className="coming-soon-bubble">Coming soon</div>} */}
        </StyledNavLink>
      )}
    </StyledLinkContainer>
  );
};

export {NavLink};
