import {useEffect} from "react";
import {Footer} from "../../../components/public-profile/footer";
import {PublicProfileNavbar} from "../../../components/public-profile/navbar";
import {PublicProfileServiceDetails} from "../../../components/public-profile/offering/service-details";
import {useServiceBookingContext} from "../../../context/service-booking/service-booking.context";
import {StyledPublicProfileContainer} from "../../../styles/public-profile/public-profile";
import {Result} from "antd";
import {ServiceBookingModal} from "../../../components/public-profile/service/booking-modal";

export function ServiceBookingPage() {
  const {state} = useServiceBookingContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!state.serviceData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "120.2rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.serviceData.mentor.avatar}
            firstName={state.serviceData.mentor.firstName}
            lastName={state.serviceData.mentor.lastName}
            username={state.serviceData.profile.username}
          />
          <PublicProfileServiceDetails />
          <ServiceBookingModal />
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
}
