import {BarsOutlined, CloseOutlined, DownOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu} from "antd";
import {useState} from "react";
import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {BrandImage} from "../../brand-image";
import {MobileNav} from "./mobile-nav";
import {
  MenuIcon,
  StyledNavbarContainer,
  StyledNavbarMenu,
  StyledNavbarMenuList,
  StyledNavbarMenuListItem,
  StyledNavbarWrapper,
  StyledNavCTA,
  StyledNavLinkItem,
} from "./navbar.style";

export const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/auth/login">Coach Log in</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/auth/customer/login">Customer Log in</Link>
    </Menu.Item>
  </Menu>
);

const HomeNavbar = () => {
  const [navIconClicked, setNavIconClicked] = useState(false);

  const handleClick = () => {
    setNavIconClicked(!navIconClicked);
  };
  return (
    <>
      <StyledNavbarWrapper>
        <StyledNavbarContainer>
          <div style={{paddingRight: "2rem"}}>
            <BrandImage />
          </div>
          <StyledNavbarMenu>
            <StyledNavbarMenuList>
              <StyledNavbarMenuListItem
                onClick={() => AnalyticsEvent("Home Navbar", `Clicked on pricing`)}
              >
                <StyledNavLinkItem to="/home/pricing">Pricing</StyledNavLinkItem>
              </StyledNavbarMenuListItem>
              <StyledNavbarMenuListItem
                onClick={() => AnalyticsEvent("Home Navbar", `Clicked on contact`)}
              >
                <StyledNavLinkItem to="/home/contact">Contact</StyledNavLinkItem>
              </StyledNavbarMenuListItem>
              <StyledNavbarMenuListItem
                onClick={() => AnalyticsEvent("Home Navbar", `Clicked on blog`)}
              >
                <a
                  className="navbar-menu-list-item-link"
                  href="https://medium.com/coachli"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </StyledNavbarMenuListItem>
              <StyledNavbarMenuListItem
                onClick={() => AnalyticsEvent("Home Navbar", `Clicked on live events`)}
              >
                <StyledNavLinkItem to="/home/live-events">Live events</StyledNavLinkItem>
              </StyledNavbarMenuListItem>
              <StyledNavbarMenuListItem
                onClick={() =>
                  AnalyticsEvent("Home Navbar", `Clicked on Join Our Community`)
                }
              >
                <a
                  className="navbar-menu-list-item-link"
                  href="https://join.slack.com/t/coachli-community/shared_invite/zt-1mkjrwhk4-dFmunoxUIr3Rfrn4D3MgBw"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join our community
                </a>
              </StyledNavbarMenuListItem>
            </StyledNavbarMenuList>
          </StyledNavbarMenu>
          <StyledNavCTA>
            <Link
              onClick={() =>
                AnalyticsEvent("Home Navbar", `Clicked on Create a free account`)
              }
              to="/auth/register"
              style={{color: "#fff"}}
            >
              <Button shape="round" size="large">
                Create a free account
              </Button>
            </Link>
            <Dropdown overlay={menu} trigger={["click", "hover"]}>
              <Button
                // target="_blank"
                // rel="noreferrer"
                shape="round"
                type="primary"
                size="large"
                style={{marginLeft: "2rem"}}
              >
                Log in <DownOutlined />
              </Button>
            </Dropdown>

            {/* </div> */}
          </StyledNavCTA>

          <MenuIcon onClick={handleClick}>
            {navIconClicked ? <CloseOutlined /> : <BarsOutlined />}
          </MenuIcon>
        </StyledNavbarContainer>
      </StyledNavbarWrapper>
      <MobileNav menuActive={navIconClicked} setNavIconClicked={setNavIconClicked} />
    </>
  );
};

export {HomeNavbar};
