import styled from "styled-components";

export const StyledCalendarBody = styled.div`
  // padding-bottom: 10;
`;
export const StyledCalendarWeekDay = styled.div`
  // height: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 400;
  div {
    display: grid;
    place-items: center;
  }
`;
export const StyledCalendarDay = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  color: #8c8c8c;

  div {
    margin-bottom: 1rem;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 5px;
    position: relative;
    pointer-events: none;

    &.day-available {
      border-radius: 50%;
      font-weight: 700;
      cursor: pointer;
      pointer-events: all;
      background-color: #f0f0f0;
      transition: all 0.2s ease-in-out;
      color: #00171f;
      &:hover {
        background-color: #9747ff;
        color: #fff;
      }
      &.selected-date {
        background-color: #9747ff;
        color: #fff;
      }
    }
  }

  @media (max-width: 1050px) {
    place-items: center;
  }
`;

// .calendar-body {
//   padding: 10px;
// }

// .calendar-body .calendar-week-day {
//   height: 50px;
//   display: grid;
//   grid-template-columns: repeat(7, 1fr);
//   font-weight: 600;
// }

// .calendar-body .calendar-week-day div {
//   display: grid;
//   place-items: center;
//   color: var(--dark-second);
// }

// .calendar-body .calendar-days {
//   display: grid;
//   grid-template-columns: repeat(7, 1fr);
//   gap: 2px;
//   color: var(--color-txt);
// }

// .calendar-body .calendar-days div {
//   width: 50px;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 5px;
//   position: relative;
//   cursor: pointer;
//   animation: to-top 1s forwards;
//   transition: all 0.2s ease-in-out;
//   /* border-radius: 50%; */
// }

// .calendar-body .calendar-days div:hover {
//   background-color: var(--dark-text);
//   color: var(--dark-main);
//   font-weight: bold;
//   border-radius: 50%;
// }

// .calendar-body .calendar-days div.curr-date {
//   color: var(--red);
//   border-radius: 50%;
//   font-weight: bold;
// }
// .calendar-body .calendar-days div.curr-date.curr-selected,
// .calendar-body .calendar-days div.curr-date:hover {
//   background-color: var(--red);
//   color: var(--white);
//   border-radius: 50%;
//   font-weight: bold;
// }

// /*Current selected date */
// .calendar-body .calendar-days div.curr-selected {
//   background-color: var(--white);
//   color: var(--dark-main);
//   border-radius: 50%;
//   font-weight: bold;
// }

// @keyframes to-top {
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
