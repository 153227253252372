import {Button, Col, Drawer, Row, Space, Tooltip} from "antd";
import {StyledCustomersDataBox} from "../../../../styles/customers/customers";
import {CloseOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Fragment, useState} from "react";
import {StyledRevenueListItem} from "./revenue-section.style";
import {TGetProductApiResponse} from "../../../../react-query-hooks/products";
import {useCurrencyContext} from "../../../../context/currency-context";
// import {useGetConnectedBankAccount} from "../../../../react-query-hooks/payout";

type TRevenueSection = {
  productData: TGetProductApiResponse;
};

export function RevenueSection({productData}: TRevenueSection) {
  const [openRevenueBreakDown, setOpenRevenueBreakDown] = useState(false);
  const {state} = useCurrencyContext();
  // const getConnectedBankAccount = useGetConnectedBankAccount();

  const showRevenueBreakDown = () => {
    setOpenRevenueBreakDown(true);
  };

  const onCloseRevenueBreakDown = () => {
    setOpenRevenueBreakDown(false);
  };

  // function handleTotalRevenue() {
  //   return productData.revenue.reduce((total, num) => total + num.amount, 0);
  // }
  return (
    <div style={{marginTop: "2.4rem"}}>
      <Space size={16} direction="vertical" style={{display: "flex"}}>
        <Row gutter={[59, 20]}>
          <Col span={24} md={12}>
            <StyledCustomersDataBox>
              <p className="data-box-title">
                Total revenue
                <Tooltip
                  placement="left"
                  title="Your total revenue is calculated in your payout currency"
                >
                  <Button icon={<QuestionCircleOutlined />} size="small" type="text" />
                </Tooltip>
              </p>
              <h2 className="data-box-value">{`${
                productData.revenue[0]?.currency ?? state.bankDetails?.currency
              } ${productData.revenue[0]?.amount?.toLocaleString() ?? 0}`}</h2>
            </StyledCustomersDataBox>
          </Col>
          <Col span={24} md={12}>
            <StyledCustomersDataBox>
              <p className="data-box-title">
                Total sales
                <Tooltip placement="left" title="Total unit of products sold">
                  <Button icon={<QuestionCircleOutlined />} size="small" type="text" />
                </Tooltip>
              </p>
              <h2 className="data-box-value">{productData.totalSales}</h2>
            </StyledCustomersDataBox>
          </Col>
        </Row>
        {productData.revenue.length > 0 && (
          <p style={{color: "#595959"}}>
            Check your earnings in all your currencies,{" "}
            <Button
              onClick={showRevenueBreakDown}
              style={{padding: "0"}}
              type="link"
              size="small"
            >
              click here
            </Button>{" "}
            to view
          </p>
        )}
      </Space>
      <Drawer
        title="Revenue breakdown"
        placement="right"
        onClose={onCloseRevenueBreakDown}
        visible={openRevenueBreakDown}
        // mask={false}
        closable={false}
        width={320}
        extra={
          <Button
            type="ghost"
            size="small"
            icon={<CloseOutlined />}
            onClick={onCloseRevenueBreakDown}
          ></Button>
        }
      >
        <Row gutter={[20, 16]}>
          {productData.revenue.map((revenue) => (
            <Fragment key={revenue.currency}>
              <Col span={12}>
                <StyledRevenueListItem>
                  <div className="item-volume">
                    <p className="item-title">Total volume</p>
                    <p className="item-value">{`${
                      revenue.currency
                    } ${revenue.amount.toLocaleString("en-us")}`}</p>
                  </div>
                </StyledRevenueListItem>
              </Col>
              <Col span={12}>
                <StyledRevenueListItem>
                  <div className="item-unit">
                    <p className="item-title">Total unit</p>
                    <p className="item-value">{revenue.units}</p>
                  </div>
                </StyledRevenueListItem>
              </Col>
            </Fragment>
          ))}
        </Row>
      </Drawer>
    </div>
  );
}
