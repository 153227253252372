import {Button, Result, message} from "antd";
import {PublicProfileNavbar} from "../../../../components/public-profile/navbar";
import {StyledPublicProfileContainer} from "../../../../styles/public-profile/public-profile";
import {Footer} from "../../../../components/public-profile/footer";
import {NewSuccessStep} from "../../../../container/live-classes/booking/new/success-step";
import {Link, useNavigate} from "react-router-dom";
import {LiveClassSuccessDetailsCard} from "../../../../components/public-profile/live-classes-section/details/success-details-card";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  PhoneOutlined,
  RetweetOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {StyledSuccessBookingWrapper} from "../../../../components/public-profile/live-classes-section/details/success-details-card/success-details-card.style";
import {useEffect} from "react";
import {
  handleFacebookLink,
  handleTwitterLink,
  handleMailLink,
} from "../../../../utils/products";
import {useServiceBookingContext} from "../../../../context/service-booking/service-booking.context";
import {handleServiceBookingLink} from "../../../../utils/meeting";

export function ServiceSuccessBooking() {
  const {state, dispatch} = useServiceBookingContext();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state.serviceData?.service.url_redirect && state.serviceBookingApiResponseData) {
      const redirectLink = state.serviceData.service.url_redirect.startsWith("http")
        ? state.serviceData.service.url_redirect
        : `//${state.serviceData.service.url_redirect}`;
      message.info("Redirecting ...", 2, () =>
        setTimeout(() => {
          window.open(redirectLink, "_blank", "noopener noreferrer");
        }, 0)
      );
    }
  }, [state.serviceBookingApiResponseData, state.serviceData?.service.url_redirect]);
  // console.log({bookingState: state});

  if (!state.serviceData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link to={`/${state.urlParams.username}`}>Back to profile</Link>
          </Button>,
        ]}
      />
    );
  }
  if (!state.serviceBookingApiResponseData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You didn't book this service!"}
        extra={[
          <Button type="primary" shape="round" size="large">
            <Link
              to={handleServiceBookingLink({
                serviceId: String(state.serviceData.service.id),
                slug: state.serviceData.service.slug,
                username: state.serviceData.profile.username,
              })}
            >
              Book service
            </Link>
          </Button>,
        ]}
      />
    );
  }

  function handleGotoDashboard() {
    dispatch({type: "reset-state"});
    navigate(`/auth/customer/login`);
  }
  function handleGotoProfile() {
    dispatch({type: "reset-state"});
    navigate(`/${state.serviceData?.profile.username}`);
  }
  const extraParams = {
    username: state.serviceData.profile.username,
    emailAddress: state.serviceBookingFormValues?.email_address,
    firstName: state.serviceData.mentor.firstName,
    lastName: state.serviceData.mentor.lastName,
    shareCopyLink: handleServiceBookingLink({
      serviceId: state.serviceData.service.id,
      slug: state.serviceData.service.slug,
      username: state.serviceData.profile.username,
      windowNav: true,
    }),
    handleGotoDashboard,
    handleGotoProfile,
  };
  const socialLinks = {
    facebookLink: handleFacebookLink({
      username: state.serviceData.profile.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just booked a session with ${state.serviceData.mentor.firstName} ${state.serviceData.mentor.lastName} titled "${state.serviceData.service.name}" on Coachli!`,
      username: state.serviceData.profile.username,
    }),
    mailLink: handleMailLink({
      body: `I just booked a session with ${state.serviceData.mentor.firstName} ${state.serviceData.mentor.lastName} titled "${state.serviceData.service.name}" on Coachli! ${window.location.origin}/${state.serviceData.profile.username}`,
      subject: "",
    }),
  };
  const successContent = {
    successTitle: "Booking successful",
    successMessage: (
      <span>
        We have sent a confirmation and a calendar invitation with information on how to
        attend the session to{" "}
        <strong>{state.serviceBookingApiResponseData?.email_address}</strong>.
      </span>
    ),
    successShareMessage: (
      <p className="info-text">
        Let people know you just booked a session with{" "}
        <strong>{state.serviceData.mentor.firstName}</strong>{" "}
        <strong>{state.serviceData.mentor.lastName}</strong>
      </p>
    ),
  };
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "96.6rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.serviceData.mentor.avatar}
            firstName={state.serviceData.mentor.firstName}
            lastName={state.serviceData.mentor.lastName}
            username={state.serviceData.profile.username}
          />
          <StyledSuccessBookingWrapper>
            <div style={{width: "100%"}}>
              <LiveClassSuccessDetailsCard
                title={state.serviceData.service.name}
                extraInfo={[
                  {
                    icon: <DollarOutlined style={{color: "#7939CC"}} />,
                    value: `${
                      state.serviceData.service.callPrice === 0
                        ? "Free"
                        : `${
                            state.serviceBookingApiResponseData.currency
                          } ${state.serviceBookingApiResponseData.amount.toLocaleString(
                            "en-US"
                          )}`
                    }`,
                  },
                  {
                    icon: state.serviceData.service.videoLocation ? (
                      <VideoCameraOutlined style={{color: "#7939CC"}} />
                    ) : (
                      <PhoneOutlined style={{color: "#7939CC"}} />
                    ),
                    value: state.serviceData.service.videoLocation
                      ? "Video call"
                      : "Phone call",
                  },
                  {
                    icon: <CalendarOutlined style={{color: "#7939CC"}} />,
                    value: state.selectedDateString
                      ? state.selectedDateString.toDateString()
                      : new Date().toDateString(),
                  },
                  ...(state.serviceData.service.is_bundle
                    ? [
                        {
                          icon: <RetweetOutlined style={{color: "#7939CC"}} />,
                          value: `${state.serviceData.service.no_of_sessions} Sessions - ${state.serviceData.service.callDuration} Minutes each`,
                        },
                      ]
                    : []),
                  {
                    icon: <ClockCircleOutlined style={{color: "#7939CC"}} />,
                    value: `${state.serviceBookingApiResponseData.timeslot} (${state.serviceBookingApiResponseData.user_timezone})`,
                  },
                ]}
                imageSrc={state.serviceData.service.cover_picture}
              />
            </div>
            <div>
              <NewSuccessStep
                successContent={successContent}
                extraParams={extraParams}
                socialLinks={socialLinks}
              />
            </div>
          </StyledSuccessBookingWrapper>
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
}
