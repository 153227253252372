import {Button, Space} from "antd";
import {LiveClassCard} from "./card";
import {TLiveClassApiResponse} from "../../../react-query-hooks/live-events/live-classes";
import {StyledNoUpcomingCallBox} from "../../calls/upcoming-calls/upcoming-calls.style";
import {useNavigate} from "react-router";
import {SortLiveClassByTime} from "../../../services/helpers";
import {useState} from "react";
import {CallDurationOptions} from "../../../container/onboarding/step-1/step-1-utils";
import {LiveClassRescheduleModal} from "./reschedule/modal";
import {InfiniteData} from "@tanstack/react-query";
import {PaginationMeta} from "../../../redux/service/i-service";

export type TUpcomingClassesProps = {
  // events: TLiveClassApiResponse[];
  pages: {
    data: TLiveClassApiResponse[];
    meta: PaginationMeta;
  }[];
};

function UpcomingClasses({pages}: TUpcomingClassesProps) {
  const navigate = useNavigate();
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [rescheduleModalData, setRescheduleModalData] = useState<
    Partial<{
      session_info: {
        name: string;
        startDate: string;
        startTime: string;
        duration: string | number;
        eventId: string | number;
        customDuration: string | number;
      }[];
    }>
  >();

  // console.log({msgWatch: watch(), msgErrors: errors});
  const showDrawer = (eventId: string | number) => {
    // reset();
    // const foundEvent = pages.find((page) =>
    //   page.data.some((item) => item.id === eventId)
    // );
    const foundEvent = pages.flatMap((x) => x.data).find((page) => page.id === eventId);

    if (foundEvent) {
      setOpenRescheduleModal(true);
      setRescheduleModalData({
        session_info: foundEvent.session_info.map((x) => {
          // console.log({
          //   xdu: x.duration,
          //   callopts: CallDurationOptions,
          //   fnd: CallDurationOptions.find((ele) => Number(ele.value) === x.duration),
          // });
          return {
            eventId: eventId,
            customDuration: CallDurationOptions.find((ele) => ele.value === x.duration)
              ? 15
              : x.duration,
            duration:
              CallDurationOptions.find(
                (ele) => Number(ele.value) === Number(x.duration)
              ) !== undefined
                ? x.duration
                : "custom",
            name: x.name,
            startDate: x.startDate,
            startTime: x.startTime,
          };
        }),
      });
      // setRescheduleData({
      //   eventId: eventId,
      //   sessionInfo: foundEvent.session_info,
      // });
    }
  };

  const onClose = () => {
    setRescheduleModalData({});
    setOpenRescheduleModal(false);
  };
  // console.log({pagehhh: pages.flatMap((page) => page.data)});

  const flatMapPages = pages.flatMap((page) => page.data);

  return (
    <>
      <Space direction="vertical" size={29} style={{display: "flex"}}>
        {flatMapPages.length > 0 ? (
          pages.map((group) => (
            <div>
              {group.data.map((liveClass) => (
                <LiveClassCard
                  key={liveClass.id}
                  slug={liveClass.slug}
                  name={liveClass.name}
                  activeSession={liveClass.activeSession}
                  attendees={liveClass.attendees}
                  callPrice={liveClass.callPrice}
                  callPriceCurrency={liveClass.callPriceCurrency}
                  cover_image={liveClass.cover_image}
                  description={liveClass.description}
                  eventId={liveClass.id}
                  statusName={liveClass.statusName}
                  showDrawer={showDrawer}
                />
              ))}
            </div>
          ))
        ) : (
          <div style={{width: "100%"}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                You do not have any live class at the moment. Create a new live class to
                get started
              </p>
              <div>
                <Button
                  onClick={() => navigate("/dashboard/live-classes/new")}
                  size="large"
                  block
                  shape="round"
                  type="primary"
                  htmlType="button"
                >
                  Create new live class
                </Button>
              </div>
            </StyledNoUpcomingCallBox>
          </div>
        )}
      </Space>
      <LiveClassRescheduleModal
        modalData={rescheduleModalData}
        onClose={onClose}
        visible={openRescheduleModal}
      />
    </>
  );
}

export {UpcomingClasses};
