import {StyledWhatMakesUsSpecialItem} from "./what-makes-us-special-item.style";

type TWhatMakesUsSpecialItemProps = {
  title: string;
  text: string;
  bgColor: string;
  icon: React.ReactNode;
};

function WhatMakesUsSpecialItem({
  text,
  title,
  bgColor,
  icon,
}: TWhatMakesUsSpecialItemProps) {
  return (
    <StyledWhatMakesUsSpecialItem>
      <div style={{backgroundColor: bgColor}} className="icon-wrapper">
        {icon}
      </div>
      <div className="item-content">
        <h3 className="item-content-title">{title}</h3>
        <p className="item-content-text">{text}</p>
      </div>
    </StyledWhatMakesUsSpecialItem>
  );
}

export {WhatMakesUsSpecialItem};
