import styled from "styled-components";

export const StyledCallOfferingItemWrapper = styled.div`
  display: grid;
  gap: 2.1rem;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledCallOfferingItem = styled.div<{active?: boolean}>`
  background-color: #ffffff;
  border: 1.5px solid ${({active}) => (active ? "#9747FF" : "#BFBFBF")};
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  /* align-items: center; */
  gap: 1.4rem;
  /* flex-wrap: wrap; */

  .content-wrapper {
    .icon-container {
      background: #f5f5f5;
      border-radius: 4px;
      font-size: 3rem;
      padding: 1.7rem;
      /* align-self: stretch; */
      // width: 6.6rem;
      /* display: flex;
    align-items: center;
    justify-content: center; */
      /* align-self: center; */
      color: ${({active}) => active && "#B67EFF"};
      width: fit-content;
      /* height: 6.6rem; */
    }
    .location-info {
      color: #00171f;
      margin-top: 1.4rem;
      .title {
        font-weight: 600;
        font-size: 1.4rem;
      }
      .sub-info {
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .icon-container {
      align-self: center;
    }
    .location-info {
      .sub-info {
        font-size: 1.2rem;
      }
    }
  }
`;
