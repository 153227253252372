import {Alert, Col, Row, Space, Spin} from "antd";
import {StyledNoUpcomingCallBox} from "../../../../components/calls/upcoming-calls/upcoming-calls.style";
import {CustomerDashboardProductCard} from "../../../../components/products/customer-dashboard/card";
import {CustomerDashboardWrapper} from "../../../../layouts/customer-dashboard-wrapper";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";
import {useGetCustomerProducts} from "../../../../react-query-hooks/products";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {LoadingSpinner, ProductBagIcon} from "../../../../components/icons/icons";
import {CustomPagination} from "../../../../components/pagination";
import {useState} from "react";

export function CustomerProductsPage() {
  const pageTitle = "Products";
  const [page, setPage] = useState(1);
  const getCustomerProductsQuery = useGetCustomerProducts(page);

  if (getCustomerProductsQuery.isLoading) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>
          Digital products {"  "} <ProductBagIcon />
        </StyledPageHeader>
        <FullPageLoader />
      </CustomerDashboardWrapper>
    );
  }
  if (getCustomerProductsQuery.isError) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>
          Digital products {"  "} <ProductBagIcon />
        </StyledPageHeader>
        <Alert
          message="Error"
          description={getCustomerProductsQuery.error.message}
          type="error"
          showIcon
        />
      </CustomerDashboardWrapper>
    );
  }
  return (
    <CustomerDashboardWrapper pageTitle={pageTitle}>
      <StyledPageHeader>
        Digital products {"  "} <ProductBagIcon />
      </StyledPageHeader>
      <div style={{marginTop: "1.5rem"}}>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            {getCustomerProductsQuery?.data?.data?.products.length < 1 ? (
              <StyledNoUpcomingCallBox>
                <p className="info-text">
                  You do not have any digital products at the moment. Buy a new product to
                  get started.
                </p>
              </StyledNoUpcomingCallBox>
            ) : (
              <div>
                <Space direction="vertical" size={40} style={{display: "flex"}}>
                  <Spin
                    spinning={getCustomerProductsQuery.isFetching}
                    tip="Updating..."
                    indicator={LoadingSpinner}
                  >
                    {getCustomerProductsQuery.data.data.products.map((product) => (
                      <CustomerDashboardProductCard product={product} key={product.id} />
                    ))}
                  </Spin>
                  {getCustomerProductsQuery.data &&
                    getCustomerProductsQuery.data.data.products.length > 0 && (
                      <CustomPagination
                        isPreviousData={getCustomerProductsQuery.isPreviousData}
                        page={page}
                        paginationMeta={getCustomerProductsQuery.data.meta}
                        setPage={setPage}
                      />
                    )}
                </Space>
              </div>
            )}
          </Col>
          <Col span={24} lg={{span: 8}}></Col>
        </Row>
      </div>
    </CustomerDashboardWrapper>
  );
}
