import {Checkbox} from "antd";
import {Controller} from "react-hook-form";
interface ICustomCheckboxComponentProps {
  control: any;
  name: string;
  id: string;
  label: string;
  disabled?: boolean;
  error?: string;
  size?: "large" | "middle" | "small";
}

const CustomCheckboxInput: React.FC<ICustomCheckboxComponentProps> = ({
  control,
  name,
  id,
  label,
  disabled,
  error,
  size,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value, ref}}) => (
        <>
          <Checkbox
            checked={value}
            id={id}
            ref={ref}
            disabled={disabled}
            onChange={onChange}
          >
            {label}
          </Checkbox>
          {error && (
            <div role="alert" className="ant-form-item-explain-error">
              {error}
            </div>
          )}
        </>
      )}
    />
  );
};

export {CustomCheckboxInput};
