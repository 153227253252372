import {useNavigate} from "react-router-dom";
import {BrandImageStyledContainer, BrandImageStyledComponent} from "./brand-image.style";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

export const BrandImage = ({variant}: {variant?: "dark" | "primary" | "white"}) => {
  const navigate = useNavigate();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const isCustomer = userInfo && userInfo.isCustomer;
  return (
    <BrandImageStyledContainer
      onClick={() =>
        navigate(
          isCustomer
            ? "/dashboard/customer/home"
            : !isCustomer && variant === "dark"
            ? "/dashboard/home"
            : "/"
        )
      }
    >
      <BrandImageStyledComponent
        src={
          variant === "dark"
            ? "/images/logo-black.png"
            : variant === "white"
            ? "/images/logo-white.png"
            : "/images/logo-normal.png"
        }
        alt="Brand Logo"
      />
    </BrandImageStyledContainer>
  );
};
