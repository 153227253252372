import {
  HourglassOutlined,
  PhoneOutlined,
  RetweetOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {StyledServiceCardExtraInfo} from "../../../../services-section/services-section.style";
import {Space} from "antd";
import {useServiceBookingContext} from "../../../../../../context/service-booking/service-booking.context";

export function ServiceTimeInfo() {
  const {state} = useServiceBookingContext();

  if (!state.serviceData) {
    return <div></div>;
  }
  const isBundle = state.serviceData.service.is_bundle;
  const isVideo = state.serviceData.service.videoLocation;
  return (
    <StyledServiceCardExtraInfo>
      <div className="service-card-extra-info-item">
        <Space size={10}>
          {isBundle ? (
            <>
              <RetweetOutlined style={{color: "#7939CC"}} />
              <span
                className="service-card-extra-info-item-value"
                style={{fontSize: "1.6rem", fontWeight: 600}}
              >
                {state.serviceData.service.no_of_sessions} Sessions -{" "}
                {state.serviceData.service.callDuration} Minutes each
              </span>
            </>
          ) : (
            <>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span
                className="service-card-extra-info-item-value"
                style={{fontSize: "1.6rem", fontWeight: 600}}
              >
                {state.serviceData.service.callDuration} Minutes
              </span>
            </>
          )}
        </Space>
      </div>
      <div className="service-card-extra-info-item">
        <Space size={10}>
          {isVideo ? (
            <>
              <VideoCameraOutlined style={{color: "#7939CC"}} />
              <span
                className="service-card-extra-info-item-value"
                style={{fontSize: "1.6rem", fontWeight: 600}}
              >
                Video call
              </span>
            </>
          ) : (
            <>
              <PhoneOutlined style={{color: "#7939CC"}} />
              <span
                className="service-card-extra-info-item-value"
                style={{fontSize: "1.6rem", fontWeight: 600}}
              >
                Phone call
              </span>
            </>
          )}
        </Space>
      </div>
    </StyledServiceCardExtraInfo>
  );
}
