import {Alert, Col, Row, Spin, Tabs} from "antd";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";
import {useGetLiveClassesCustomer} from "../../../../react-query-hooks/live-events/live-classes";
import {UpcomingClassesCustomer} from "../../../../components/live-classes/upcoming-classes/customer/upcoming-classes";
import {CustomerPastLiveClasses} from "../../../../components/live-classes/past-classes/customer";
import {CustomerDashboardWrapper} from "../../../../layouts/customer-dashboard-wrapper";
import {LoadingSpinner} from "../../../../components/icons/icons";
import {useState} from "react";
import {CustomPagination} from "../../../../components/pagination";

function CustomerLiveClassesPage() {
  const [currentLiveClassPage, setCurrentLiveClassPage] = useState(1);
  const getLiveClassesQuery = useGetLiveClassesCustomer(currentLiveClassPage);
  const pageTitle = "Live Classes";

  if (getLiveClassesQuery.isLoading) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
        <FullPageLoader />
      </CustomerDashboardWrapper>
    );
  }

  if (
    getLiveClassesQuery.isError &&
    getLiveClassesQuery.error.message !==
      "Sorry, You currently do not have any live events!"
  ) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
        <Alert
          message="Error"
          description={getLiveClassesQuery.error.message}
          type="error"
          showIcon
        />
      </CustomerDashboardWrapper>
    );
  }
  return (
    <CustomerDashboardWrapper pageTitle={pageTitle}>
      <StyledPageHeader>Live classes 🧑‍🏫</StyledPageHeader>
      <div style={{marginTop: "1.5rem"}}>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Upcoming classes" key="1">
                <Spin
                  spinning={getLiveClassesQuery.isFetching}
                  tip="Updating..."
                  indicator={LoadingSpinner}
                >
                  <UpcomingClassesCustomer
                    events={getLiveClassesQuery.data?.data.events}
                  />
                  {getLiveClassesQuery.data &&
                    getLiveClassesQuery.data.data.events.length > 0 && (
                      <CustomPagination
                        isPreviousData={getLiveClassesQuery.isPreviousData}
                        page={currentLiveClassPage}
                        paginationMeta={getLiveClassesQuery.data?.meta}
                        setPage={setCurrentLiveClassPage}
                      />
                    )}
                </Spin>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Past classes" key="2">
                <CustomerPastLiveClasses />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col span={24} lg={{span: 8}}></Col>
        </Row>
      </div>
    </CustomerDashboardWrapper>
  );
}

export {CustomerLiveClassesPage};
