import {Row, Col, Button, Table, Alert, message, Drawer, Space} from "antd";
import {StyledCustomersDataBox} from "../../../styles/customers/customers";
import {
  TAttendee,
  useGetAttendeesInsight,
  useLiveClassAttendeeExportCSV,
} from "../../../react-query-hooks/live-events/live-classes";
import {useParams} from "react-router";
import {CloseOutlined, DownloadOutlined} from "@ant-design/icons";
import type {ColumnsType} from "antd/es/table";
import {
  StyledUpcomingCallsWrapper,
  StyledNoUpcomingCallBox,
} from "../../calls/upcoming-calls/upcoming-calls.style";
import {StyledDescriptionText, StyledPageHeader} from "../../messages/messages.style";
import {FullPageLoader} from "../../full-page-loader";
import {CustomerMetricsSkeleton} from "../../customers/customer-details-section/customer-metrics-skeleton";
import {AttendeesDetailsModal} from "./details/details-modal";
import {Fragment, useState} from "react";
import {StyledRevenueListItem} from "../../products/sales-and-insights/revenue-section/revenue-section.style";
import {CustomPagination} from "../../pagination";
interface DataType {
  key: string;
  name: string;
  email: string;
  reference: string;
}

function AttendeesComponent() {
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeeDetails, setAttendeeDetails] = useState<TAttendee>();
  const {id} = useParams();
  const {isLoading, isError, error, data, isFetching, isPreviousData} =
    useGetAttendeesInsight(id, page);
  const exportCsvQueryMutation = useLiveClassAttendeeExportCSV();

  const [openRevenueBreakDown, setOpenRevenueBreakDown] = useState(false);

  const showRevenueBreakDown = () => {
    setOpenRevenueBreakDown(true);
  };

  const onCloseRevenueBreakDown = () => {
    setOpenRevenueBreakDown(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <FullPageLoader />;
  }
  if (isError) {
    return <Alert message="Error" description={error.message} type="error" showIcon />;
  }

  const showModal = (id: string) => {
    const attendeeDetails = data.data.attendees.find((member) => member.id === +id);
    setAttendeeDetails(attendeeDetails);
    setIsModalOpen(true);
  };
  const attendeesData: DataType[] = data.data.attendees.map((attendee) => ({
    key: attendee.id.toString(),
    name: attendee.firstName + " " + attendee.lastName,
    email: attendee.emailAddress,
    reference: attendee.reference,
  }));

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {text}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "17.425rem",
      key: "email",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "17.425rem",
      key: "reference",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => showModal(record.key)}>
          View details
        </Button>
      ),
    },
  ];

  const handleExportAsCSV = () => {
    if (id) {
      exportCsvQueryMutation.mutate(id, {
        onSuccess: (data) => {
          message.success("File ready to be downloaded!");
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Live-class-attendee.csv");
          document.body.appendChild(link);
          link.click();
        },
      });
    }
  };
  return (
    <div>
      {isFetching ? (
        <CustomerMetricsSkeleton />
      ) : (
        <div>
          <Space size={16} direction="vertical" style={{display: "flex"}}>
            <Row gutter={[32, 20]}>
              <Col span={24} md={12}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total revenue</p>
                  <h2 className="data-box-value">
                    {data.data.totalRevenue[0].currency}{" "}
                    {data.data.totalRevenue[0].revenue}
                  </h2>
                </StyledCustomersDataBox>
              </Col>
              <Col span={24} md={12}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total attendees</p>
                  <h2 className="data-box-value">{data.data.totalAttendees}</h2>
                </StyledCustomersDataBox>
              </Col>
            </Row>
            {data.data.totalRevenue.length > 1 && (
              <p style={{color: "#595959"}}>
                Check your earnings in all your currencies,{" "}
                <Button
                  onClick={showRevenueBreakDown}
                  style={{padding: "0"}}
                  type="link"
                  size="small"
                >
                  click here
                </Button>{" "}
                to view
              </p>
            )}
          </Space>
        </div>
      )}

      <div style={{marginTop: "7rem"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "4rem",
          }}
        >
          <StyledPageHeader>Attendees</StyledPageHeader>
          <div>
            <Button
              onClick={handleExportAsCSV}
              disabled={attendeesData.length < 1}
              block
              icon={<DownloadOutlined />}
              size="large"
              shape="round"
              loading={exportCsvQueryMutation.isLoading}
            >
              Export CSV
            </Button>
          </div>
        </div>
        {data.data.attendees.length < 1 ? (
          <StyledUpcomingCallsWrapper style={{paddingTop: 0}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">Your attendees will appear here</p>
            </StyledNoUpcomingCallBox>
          </StyledUpcomingCallsWrapper>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={attendeesData}
              loading={isFetching}
              scroll={{x: 100}}
              pagination={false}
            />
            <CustomPagination
              isPreviousData={isPreviousData}
              page={page}
              paginationMeta={data.meta}
              setPage={setPage}
            />
          </>
        )}
      </div>
      <AttendeesDetailsModal
        visibleState={isModalOpen}
        attendeeId={attendeeDetails?.id}
        eventId={id}
        attendeeEmail={attendeeDetails?.emailAddress}
        cancelFunc={handleCancel}
        okFunc={handleOk}
      />
      <Drawer
        title="Revenue breakdown"
        placement="right"
        onClose={onCloseRevenueBreakDown}
        visible={openRevenueBreakDown}
        // mask={false}
        closable={false}
        width={320}
        extra={
          <Button
            type="ghost"
            size="small"
            icon={<CloseOutlined />}
            onClick={onCloseRevenueBreakDown}
          ></Button>
        }
      >
        <Row gutter={[20, 16]}>
          {data.data.totalRevenue.map((revenue) => (
            <Fragment key={revenue.currency}>
              <Col span={12}>
                <StyledRevenueListItem>
                  <div className="item-volume">
                    <p className="item-title">Currency</p>
                    <p className="item-value">{revenue.currency}</p>
                  </div>
                </StyledRevenueListItem>
              </Col>
              <Col span={12}>
                <StyledRevenueListItem>
                  <div className="item-unit">
                    <p className="item-title">Total amount</p>
                    <p className="item-value">
                      {Number(revenue.revenue).toLocaleString("en-us")}
                    </p>
                  </div>
                </StyledRevenueListItem>
              </Col>
            </Fragment>
          ))}
        </Row>
      </Drawer>
    </div>
  );
}

export {AttendeesComponent};
