import {Alert, Button} from "antd";
import {useState} from "react";
import {
  StyledNoUpcomingCallBox,
  StyledPageHeaderWrapper,
} from "../../../components/calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../../components/full-page-loader";
import {ContactList} from "../../../components/messages/contact-list/contact-list";
import {MessageList} from "../../../components/messages/message-list";
import {
  StyledPageHeader,
  StyledPageWrapper,
} from "../../../components/messages/messages.style";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {useGetCoachMessages} from "../../../react-query-hooks/messages";
import {AnalyticsEvent} from "../../../utils/analytics";

const MessagesPage = () => {
  const getCoachMessagesQuery = useGetCoachMessages();
  const [showNewMessageModal, setShowNewMessageModal] = useState<boolean>(false);

  const handleShowNewMessageModal = () => {
    AnalyticsEvent("Coach Messaging", "Clicked on new message button");
    setShowNewMessageModal(true);
  };

  if (getCoachMessagesQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle="Messages">
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (
    getCoachMessagesQuery.isError &&
    getCoachMessagesQuery.error.message !== "Sorry, You currently have no messages!"
  ) {
    return (
      <DashboardWrapper pageTitle="Messages">
        <Alert
          message="Error"
          description={getCoachMessagesQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper pageTitle="Messages">
      <ContactList
        showModalState={showNewMessageModal}
        showModalStateUpdate={setShowNewMessageModal}
      />
      <StyledPageHeaderWrapper>
        <StyledPageHeader>Messages ✉️</StyledPageHeader>
        <div>
          <Button
            onClick={handleShowNewMessageModal}
            shape="round"
            type="primary"
            size="large"
          >
            New message
          </Button>
        </div>
      </StyledPageHeaderWrapper>
      <StyledPageWrapper style={{display: "block", marginLeft: 0, marginRight: 0}}>
        {getCoachMessagesQuery.data &&
        getCoachMessagesQuery.data.data.messages.length > 0 ? (
          <MessageList />
        ) : (
          <div style={{width: "100%"}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">You do not have any messages</p>
            </StyledNoUpcomingCallBox>
          </div>
        )}
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {MessagesPage};
