import moment from "moment";
import {
  ServiceBookingApiResponseState,
  TPublicProfileBookingApiResponse,
  TTimeslotsPayload,
} from "../../react-query-hooks/public-profile";
import {AxiosError} from "axios";
import {UseMutationResult} from "@tanstack/react-query";
// import {TBookingFormValues} from "../../container/live-classes/booking/new/step-1";
import {ServiceBookingFormValues} from "../../components/public-profile/service/booking-form";
// export type ServiceBookingSteps = "serviceBookingForm" | "flutterwave" | "paystack";

type TUpdateSelectedTimezone = {
  type: "update-selected-timezone";
  payload: string;
};
type TUpdateSelectedDate = {
  type: "update-selected-date";
  payload: Date;
};
type TUpdateSelectedTime = {
  type: "update-selected-time";
  payload: string;
};
type TUpdateDateInfo = {
  type: "update-date-info";
  payload: {
    selectedDateString: Date;
    booking_date: number;
    booking_year: number;
    booking_month: number;
  };
};

type TResetTimeSelected = {
  type: "reset-time-selected";
};
type TUpdateSelectedCurrency = {
  type: "update-selected-currency";
  payload: {
    selectedCurrency: {
      label: string;
      value: number | string;
      amount: number;
      currency: string;
    };
  };
};
type TShowServiceBookingModal = {
  type: "show-booking-modal";
  payload: boolean;
};
type TUpdateServiceBookingStep = {
  type: "update-booking-step";
  payload: number;
};
type TUpdateServiceFormValues = {
  type: "update-service-form-values";
  payload: ServiceBookingFormValues;
};
type TUpdateServiceBookingApiResponse = {
  type: "update-service-booking-api-response";
  payload: ServiceBookingApiResponseState;
};
type TResetState = {
  type: "reset-state";
};

export type TServiceBookingActions =
  | TUpdateSelectedTimezone
  | TUpdateSelectedDate
  | TUpdateSelectedTime
  | TUpdateDateInfo
  | TResetTimeSelected
  | TUpdateSelectedCurrency
  | TShowServiceBookingModal
  | TUpdateServiceBookingStep
  | TUpdateServiceFormValues
  | TUpdateServiceBookingApiResponse
  | TResetState;

export type TServiceBookingState = {
  selectedTimeZone: string;
  booking_date: number;
  booking_year: number;
  booking_month: number;
  selectedDateString?: Date;
  timeSelected?: string;
  selectedCurrency?: {
    label: string;
    value: number | string;
    amount: number;
    currency: string;
  };
  isBookingModalOpen: boolean;
  urlParams: {
    username: string | undefined;
    slug: string | null;
    serviceId: string | null;
    currentUrlStep: string | null;
  };
  currentBookingStep: number;
  serviceData?: TPublicProfileBookingApiResponse;
  serviceBookingFormValues?: ServiceBookingFormValues;
  serviceBookingApiResponseData?: ServiceBookingApiResponseState;
  timeSlotsMutation?: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    TTimeslotsPayload,
    unknown
  >;
};

export const initialServiceBookingState: TServiceBookingState = {
  selectedTimeZone: moment.tz.guess(true),
  booking_date: new Date().getDate(),
  booking_month: new Date().getMonth() + 1,
  booking_year: new Date().getFullYear(),
  selectedDateString: undefined,
  selectedCurrency: undefined,
  isBookingModalOpen: false,
  urlParams: {
    serviceId: null,
    slug: null,
    username: undefined,
    currentUrlStep: null,
  },
  currentBookingStep: 1,
  serviceData: undefined,
  serviceBookingFormValues: undefined,
  serviceBookingApiResponseData: undefined,
};

export const serviceBookingReducer = (
  state: TServiceBookingState = initialServiceBookingState,
  action: TServiceBookingActions
) => {
  if (action.type === "update-selected-timezone") {
    return {...state, selectedTimeZone: action.payload};
  }
  if (action.type === "update-selected-date") {
    return {...state, selectedDateString: action.payload};
  }
  if (action.type === "update-selected-time") {
    return {...state, timeSelected: action.payload};
  }
  if (action.type === "update-date-info") {
    return {
      ...state,
      selectedDateString: action.payload.selectedDateString,
      booking_date: action.payload.booking_date,
      booking_month: action.payload.booking_month,
      booking_year: action.payload.booking_year,
    };
  }
  if (action.type === "update-selected-currency") {
    const {selectedCurrency} = action.payload;
    return {...state, selectedCurrency};
  }
  if (action.type === "show-booking-modal") {
    return {...state, isBookingModalOpen: action.payload};
  }
  if (action.type === "update-booking-step") {
    if (action.payload > 3) {
      return state;
    }
    return {...state, currentBookingStep: action.payload};
  }
  if (action.type === "update-service-form-values") {
    return {...state, serviceBookingFormValues: action.payload};
  }
  if (action.type === "update-service-booking-api-response") {
    return {...state, serviceBookingApiResponseData: action.payload};
  }
  if (action.type === "reset-time-selected") {
    return {
      ...state,
      timeSelected: undefined,
    };
  }
  if (action.type === "reset-state") {
    return {...initialServiceBookingState};
  }

  return state;
};
