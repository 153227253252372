import {Avatar, Tag} from "antd";
import {useMessageContext} from "../../../../context/message-context";
import {TMessageResObj} from "../../../../react-query-hooks/messages";
import {capitalizeFirstLetter} from "../../../../services/helpers";
import {StyledMessageCustomerListItem} from "./message-customer-list-item.style";

const MessageCustomerListItem: React.FC<{
  contact: TMessageResObj;
}> = ({contact}) => {
  const {state, dispatch} = useMessageContext();
  return (
    <StyledMessageCustomerListItem
      onClick={() =>
        dispatch({
          type: "set-coach-active-message",
          payload: {
            activeMessage: contact,
          },
        })
      }
      isActive={
        contact.customerName + contact.emailAddress.toLowerCase() ===
        (((state.coachActiveMessage?.customerName as string) +
          state.coachActiveMessage?.emailAddress.toLowerCase()) as string)
      }
    >
      {/* <StyledMessageCustomerListItem> */}
      <div className="avatar-name-container">
        <Avatar className="avatar-comp" size="large">
          {contact.customerName.split(" ")[1].charAt(0).toUpperCase()}
          {contact.customerName.split(" ")[0].charAt(0).toUpperCase()}
        </Avatar>
        <span className="message-customer-list-item-name">
          {capitalizeFirstLetter(contact.customerName.split(" ")[1])}{" "}
          {capitalizeFirstLetter(contact.customerName.split(" ")[0])}
          {/* lIBERY CHANG */}
        </span>
      </div>
      <span className="message-cutomer-list-item-time">
        {!contact.isReadMentor && (
          <div
            style={{
              width: ".8rem",
              height: ".8rem",
              borderRadius: "50%",
              backgroundColor: "#9747ff",
              marginLeft: "1rem",
            }}
          />
        )}
      </span>
    </StyledMessageCustomerListItem>
  );
};

export {MessageCustomerListItem};
