import {useGetPendingFx} from "../../../react-query-hooks/payout";
import type {ColumnsType} from "antd/es/table";
import {
  StyledDescriptionText,
  StyledNoUpcomingCallBox,
  StyledUpcomingCallsWrapper,
} from "../../calls/upcoming-calls/upcoming-calls.style";
import {Alert, Button, Space, Spin, Table, Tag, Tooltip} from "antd";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {QuestionCircleOutlined} from "@ant-design/icons";
import moment from "moment";

interface DataType {
  key: string;
  offering: string;
  customer: string;
  amount: string;
  currency: string;
  date: string;
  status: string;
  gateway: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Offering",
    dataIndex: "offering",
    fixed: "left",
    width: "17.425rem",
    key: "offering",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Customer",
    dataIndex: "customer",
    width: "17.425rem",
    key: "customer",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "17.425rem",
    key: "amount",
    render: (text, record) => (
      <>
        <StyledDescriptionText>{`${record.currency} ${text}`}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    width: "17.425rem",
    key: "date",
    render: (text) => (
      <>
        <StyledDescriptionText>
          {moment(text).format("ddd, MMM DD YYYY")}
        </StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "17.425rem",
    key: "status",
    render: (text: string) => (
      <>
        {text.toLowerCase() === "pending conversion" ? (
          <Tooltip
            placement="right"
            title="Pending conversions typically takes 2 to 3 days before they are added to your wallet"
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Tag color="warning">Pending conversion</Tag>

              <Button
                style={{color: "#FAAD14"}}
                icon={<QuestionCircleOutlined />}
                size="small"
                type="text"
              />
            </div>
          </Tooltip>
        ) : (
          <Tag color={"green"}>{text}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Gateway",
    dataIndex: "gateway",
    width: "17.425rem",
    key: "gateway",
  },
];

export function PendingFxTransactions() {
  const getPendingFxQuery = useGetPendingFx();

  if (getPendingFxQuery.isLoading) {
    return (
      <div>
        <Spin />
      </div>
    );
  }
  if (getPendingFxQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getPendingFxQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const fxTransactionData: DataType[] = getPendingFxQuery.data.transactions.map(
    (transaction) => ({
      key: transaction.customer_email + transaction.createdAt,
      amount: Number(transaction.serviceAmount).toLocaleString(),
      currency: transaction.from_currency,
      customer: transaction.customer_email,
      date: transaction.createdAt,
      gateway: transaction.initialPaymentChannel,
      offering: transaction.offering,
      status: transaction.status,
    })
  );

  return (
    <div style={{marginTop: "7rem"}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "4rem",
        }}
      >
        <StyledPageHeader style={{fontSize: "2.4rem"}}>Transactions 📜</StyledPageHeader>
      </div>
      <p>Payments from your customers</p>
      <div style={{marginTop: "1.6rem"}}>
        <Spin spinning={getPendingFxQuery.isFetching || getPendingFxQuery.isLoading}>
          {fxTransactionData.length < 1 ? (
            <StyledUpcomingCallsWrapper style={{paddingTop: 0}}>
              <StyledNoUpcomingCallBox>
                <p className="info-text">You do not have any transactions</p>
              </StyledNoUpcomingCallBox>
            </StyledUpcomingCallsWrapper>
          ) : (
            <Table
              //   onRow={handleOnRow}
              columns={columns}
              dataSource={fxTransactionData}
              scroll={{x: 100}}
              loading={getPendingFxQuery.isFetching || getPendingFxQuery.isLoading}
              pagination={{
                position: ["bottomCenter"],
              }}
            />
          )}
        </Spin>
      </div>
    </div>
  );
}
