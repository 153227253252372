import {Space} from "antd";
import {TermsSubheading} from "./sub-heading";
import {TermsLastRevised} from "./last-revised";
import {TermsList} from "./terms-list";

export function TermsInfo() {
  return (
    <div>
      <Space size={40} direction="vertical">
        <TermsSubheading />
        <TermsLastRevised />
        <TermsList />
      </Space>
    </div>
  );
}
