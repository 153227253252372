import {ExclamationCircleOutlined, MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu, Modal, message} from "antd";
import {Link} from "react-router-dom";
import {useDeleteProduct} from "../../../../../react-query-hooks/products";
import {handleProductLink} from "../../../../../utils/products";
import {useGetProfile} from "../../../../../react-query-hooks/profile";

type TProductListMenuOptionProps = {
  productId: number;
  slug: string;
};

export function ProductListMenuOption({productId, slug}: TProductListMenuOptionProps) {
  const deleteProductMutation = useDeleteProduct();
  const getProfileQuery = useGetProfile();

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Confirm product deletion`,
      content:
        "Are you sure you want to delete this product? This action cannot be undone.",
      icon: <ExclamationCircleOutlined style={{color: "#F5222D"}} />,
      okText: "Delete product",
      cancelText: "Exit",
      okType: "danger",
      okButtonProps: {
        shape: "round",
        type: "primary",
        loading: deleteProductMutation.isLoading,
      },
      cancelButtonProps: {
        shape: "round",
        type: "ghost",
      },
      onOk() {
        return new Promise((resolve, reject) => {
          if (productId) {
            resolve(
              deleteProductMutation.mutate(String(productId), {
                onSuccess: () => {
                  message.success("The selected product has been deleted successfully!");
                },
              })
            );
          }
        }).catch(() => message.error("Failed to delete"));
      },
    });
  };
  const menu = (
    <Menu
      items={[
        {
          label: (
            <Link to={`/dashboard/products/edit/${productId}?currentTab=insights`}>
              Sales and insights
            </Link>
          ),
          key: "0",
        },
        {
          label: <Link to={`/dashboard/products/edit/${productId}`}>Edit product</Link>,
          key: "1",
        },
        {
          label: (
            <div
              onClick={() =>
                navigator.clipboard
                  .writeText(
                    handleProductLink({
                      productId: productId,
                      slug: slug,
                      username: getProfileQuery.data?.username as string,
                      windowNav: true,
                    })
                  )
                  .then(() => message.success("Copied link"))
              }
            >
              Copy product link
            </div>
          ),
          key: "2",
        },
        {
          label: <div onClick={handleDeleteModal}>Delete product</div>,
          key: "3",
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Button
          shape="circle"
          size="middle"
          icon={<MoreOutlined />}
          style={{
            backgroundColor: "transparent",
            color: "#00171F",
            border: "none",
            boxShadow: "none",
          }}
        />
      </Dropdown>
    </>
  );
}
