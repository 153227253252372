import {message, Spin} from "antd";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {CustomerCancelBookingForm} from "../../../components/booking/cancel-booking/cancel-booking-form";
import {CustomerCancelBookingSuccessPage} from "../../../components/booking/cancel-booking/cancel-booking-success";
import {CancelLeftColumn} from "../../../components/booking/left-column/cancel-left-column/cancel-left-column";
import {ServiceBookingErrorPage} from "../../../components/booking/service-booking-error-page";
import {getCustomerBooking} from "../../../redux/booking/booking-slice";
import {TFetchCustomerBookingPayload} from "../../../redux/booking/i-time-slots-payload";
import {AppDispatch, RootState} from "../../../redux/store";
import {
  StyledBookingContainer,
  StyledBookingRightColumn,
  StyledBookingWrapper,
} from "../../../styles/booking/booking";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet-async";

const CustomerCancelBookingPage = () => {
  const {reference, userType} = useParams();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (reference) {
      const payload: TFetchCustomerBookingPayload = {
        reference,
        userType: "guest_user",
      };
      dispatch(getCustomerBooking(payload));
    }
  }, [dispatch, reference]);

  const {
    fetchCustomerBookingStatus,
    customerCancelBookingStatus,
    message: customerBookingMessage,
  } = useSelector((state: RootState) => state.booking);
  useEffect(() => {
    if (fetchCustomerBookingStatus === "rejected") {
      message.error(customerBookingMessage);
    }
  }, [customerBookingMessage, fetchCustomerBookingStatus]);
  return (
    <>
      <Helmet>
        <title>Coachli | Cancel a meeting</title>
      </Helmet>
      <StyledBookingContainer>
        {fetchCustomerBookingStatus === "rejected" ? (
          <ServiceBookingErrorPage />
        ) : fetchCustomerBookingStatus === "pending" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <StyledBookingWrapper>
            <CancelLeftColumn />
            <StyledBookingRightColumn>
              {customerCancelBookingStatus === "resolved" ? (
                <CustomerCancelBookingSuccessPage />
              ) : (
                <CustomerCancelBookingForm bookingReference={reference as string} />
              )}
            </StyledBookingRightColumn>
          </StyledBookingWrapper>
        )}
      </StyledBookingContainer>
    </>
  );
};

export default CustomerCancelBookingPage;
