import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {Button, message, Space} from "antd";
import {CustomInputComponent} from "../../../components/input/input-component";
import {StyledLink} from "../../../layouts/auth-form-wrapper/auth-form-wrapper.style";
import {ILoginFormValues} from "./i-login";
import {loginSchema} from "./login-form-validation-schema";
import {
  loginStandardUser,
  resetUserLoginState,
  updateLoginState,
} from "../../../redux/auth/login/user-login-slice";
import {AppDispatch, RootState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    userInfo,
    isLoading: isLoadingUserLogin,
    isSuccess: isSuccessUserLogin,
    isError: isErrorUserLogin,
    message: userLoginMessage,
  } = useSelector((state: RootState) => state.userLogin);

  useEffect(() => {
    if (isErrorUserLogin) {
      message.error(userLoginMessage);
    }

    if (isSuccessUserLogin) {
      // if (!userInfo?.user?.firstName) {
      // } else {
      //   message.success(`Welcome ${userInfo?.user?.firstName}!`);
      //   navigate("/dashboard/get-started");
      // }
      navigate("/dashboard/onboarding");
    }

    dispatch(resetUserLoginState());
  }, [
    isErrorUserLogin,
    isSuccessUserLogin,
    userLoginMessage,
    userInfo?.user?.firstName,
    dispatch,
    navigate,
  ]);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<ILoginFormValues>({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const onSubmit = async (values: ILoginFormValues) => {
    dispatch(
      loginStandardUser({...values, emailAddress: values.emailAddress.toLowerCase()})
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space direction="vertical" size={29} style={{display: "flex"}}>
        <CustomInputComponent
          id="email"
          name="emailAddress"
          label="Email address"
          type="email"
          placeholder="Please enter your email address"
          error={errors.emailAddress?.message}
          control={control}
        />
        <CustomInputComponent
          id="password"
          name="password"
          label="Password"
          type="password"
          placeholder="•••••••••••••••"
          error={errors.password?.message}
          control={control}
        />
        <StyledLink to="/auth/forgot-password">Forgot password</StyledLink>
        <div>
          <Button
            disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
            block
            loading={isLoadingUserLogin}
            size="large"
            shape="round"
            type="primary"
            htmlType="submit"
          >
            Log in
          </Button>
        </div>
        <p>
          Are you a returning customer?{" "}
          <Link to="/auth/customer/login">Log in to your account</Link>
        </p>
      </Space>
    </form>
  );
};

export {LoginForm};
