import axios from "axios";
import {IPasswordUpdateFormValues} from "../../components/settings/password-update-form/i-password-update-form";
import {API_BASE_URL} from "../../services/config";

export const updateEmailService = async (emailAddress: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_BASE_URL + "settings/email",
    {emailAddress},
    config
  );

  return response.data;
};

export const updatePasswordService = async (
  payload: IPasswordUpdateFormValues,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "settings/password", payload, config);

  return response.data;
};
