import {
  UserOutlined,
  DollarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  CalendarOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {Button, Avatar, Space} from "antd";
import moment from "moment";
import {useState} from "react";
import {useSelector} from "react-redux";
// import {useNavigate} from "react-router-dom";
import {RootState} from "../../../../redux/store";
import {
  StyledBookingLeftColumn,
  StyledHeading,
  StyledCoachInfoSection,
  StyledCoachMainInfoSection,
  StyledServiceInfoSection,
} from "../left-column.style";
import {CancelLeftColumnMobile} from "./cancel-left-column-mobile";

const CancelLeftColumn = () => {
  const [showMore, setShowMore] = useState(false);
  // const navigate = useNavigate();
  const {customerBooking} = useSelector((state: RootState) => state.booking);

  const callDate = new Date(
    Number(customerBooking.booking?.booking_year),
    Number(customerBooking.booking?.booking_month) - 1,
    Number(customerBooking.booking?.booking_date)
  ).toDateString();

  const tz = moment.tz.guess(true);

  return (
    <>
      <StyledBookingLeftColumn>
        {/* <div>
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-link"
            onClick={() => navigate(-1)}
            type="link"
            size="large"
            style={{
              paddingLeft: 0,
              marginLeft: 0,
            }}
          >
            Back to profile
          </Button>
        </div> */}
        <StyledHeading>{customerBooking.service?.name}</StyledHeading>
        <StyledCoachInfoSection>
          <div>
            {customerBooking.mentor?.avatar ? (
              <Avatar size={96} src={customerBooking.mentor.avatar} />
            ) : (
              <Avatar size={96} icon={<UserOutlined />} />
            )}
          </div>
          <StyledCoachMainInfoSection>
            <h2 className="coach-name">
              {customerBooking.mentor?.firstName} {customerBooking.mentor?.lastName}
            </h2>
            <p className="coach-role">{customerBooking.profile?.description}</p>
            <div
              className="coach-bio"
              dangerouslySetInnerHTML={{
                __html: String(customerBooking.service?.description),
              }}
            />
          </StyledCoachMainInfoSection>
        </StyledCoachInfoSection>
        <StyledServiceInfoSection>
          <Space direction="vertical" size={15}>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {customerBooking.service?.callPrice !== 0 &&
                    customerBooking.service?.callPriceCurrency}{" "}
                  {customerBooking.service?.callPrice === 0
                    ? "Free"
                    : customerBooking.service?.callPrice?.toLocaleString("en-US")}
                </span>
              </Space>
            </div>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <HourglassOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {customerBooking.service?.callDuration} Minutes
                </span>
              </Space>
            </div>
            <div className="service-card-extra-info-item">
              <Space size={10}>
                {customerBooking.service?.videoLocation ? (
                  <>
                    <VideoCameraOutlined style={{color: "#7939CC"}} />
                    <span className="service-info-item-value">Video call</span>
                  </>
                ) : (
                  <>
                    <PhoneOutlined style={{color: "#7939CC"}} />
                    <span className="service-info-item-value">Phone call</span>
                  </>
                )}
              </Space>
            </div>
            {/* {currentStep > 1 && ( */}
            <div className="service-card-extra-info-item">
              <Space size={10}>
                <CalendarOutlined style={{color: "#7939CC"}} />
                <span className="service-info-item-value">
                  {customerBooking.booking?.timeslot} - {customerBooking.booking?.endTime}
                  , {callDate}
                </span>
              </Space>
            </div>
            {/* )} */}

            <div className="service-card-extra-info-item">
              <Space size={10}>
                <GlobalOutlined style={{color: "#7939CC"}} />
                {/* <span className="service-info-item-value">
                {`(${getTimeZone("longOffset")})`} {bookingServiceData.timeZone} (
                {formatAMPM(new Date())})
              </span> */}

                <span className="service-info-item-value">
                  {customerBooking.booking?.user_timezone ?? tz}
                </span>
              </Space>
            </div>
          </Space>
        </StyledServiceInfoSection>
      </StyledBookingLeftColumn>
      <CancelLeftColumnMobile />
    </>
  );
};

export {CancelLeftColumn};
