import styled from "styled-components";

export const StyledConnectBankBoxWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2.4rem 1.4rem 2.4rem 2.4rem;
`;

export const StyledConnectBankBox = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  gap: 3.6rem;

  .bank-icon-container {
    width: 6.6rem;
    height: 6.6rem;
    background: #f5f5f5;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bank-connect-btn {
    margin-left: auto;
  }

  @media (max-width: 602px) {
    flex-direction: column;

    .bank-connect-text {
      text-align: center;
    }

    .bank-connect-btn {
      margin-left: 0;
    }
  }
`;
