import {ArrowRightOutlined, EditOutlined, UserOutlined} from "@ant-design/icons";
import {useGetProfileContext} from "../../../context/profile/profile-context";
import {
  StyledSidebarProfileAvatarEditIconWrapper,
  StyledSidebarProfileAvatarWrapper,
  StyledSidebarProfileInfoWrapper,
  StyledSidebarProfileShareWrapper,
  StyledSidebarProfileWrapper,
} from "./sidebar-profile.style";
import {Avatar, Button, Space, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {useState} from "react";
import {CopyIconSm, FacebookIconSm, MailIcon, TwitterIconSm} from "../../icons/icons";

export function SidebarProfile() {
  const [toolTipText, setToolTipText] = useState<string>("copy url");
  const {state} = useGetProfileContext();

  function handleViewProfile() {
    setTimeout(() => {
      window.open(`/${state.profileData.username}`);
    }, 0);
  }
  return (
    <StyledSidebarProfileWrapper>
      <div>
        <Space size={20}>
          <StyledSidebarProfileAvatarWrapper>
            {state.profileData.avatar ? (
              <Avatar size={50} src={state.profileData.avatar} />
            ) : (
              <Avatar size={50} icon={<UserOutlined />} />
            )}
            <StyledSidebarProfileAvatarEditIconWrapper>
              <Link to="/dashboard/profile">
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  type="primary"
                  size="small"
                  style={{minWidth: "2rem", width: "2rem", height: "2rem"}}
                />
              </Link>
            </StyledSidebarProfileAvatarEditIconWrapper>
          </StyledSidebarProfileAvatarWrapper>
          <StyledSidebarProfileInfoWrapper>
            <p className="profile-name">{`${state.profileData.firstName} ${state.profileData.lastName}`}</p>
            <Button
              type="link"
              href={`/${state.profileData.username}`}
              target="_blank"
              style={{paddingLeft: 0, paddingTop: 0}}
            >
              Public Profile <ArrowRightOutlined />
            </Button>
          </StyledSidebarProfileInfoWrapper>
        </Space>
      </div>
      <StyledSidebarProfileShareWrapper>
        {/* <ShareOfferingWidget title="Share profile via" extraParams={extraParams} /> */}
        <p className="share-text">Share profile via</p>
        <div>
          <Space direction="horizontal" size={16}>
            <div>
              <Tooltip title={toolTipText}>
                <Button
                  shape="circle"
                  size="small"
                  icon={<CopyIconSm />}
                  style={{
                    backgroundColor: "#F4EEFC",
                    color: "#9254DE",
                    border: "none",
                  }}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `${window.location.origin}/${state.profileData.username}`
                      )
                      .then(() => setToolTipText("copied"))
                  }
                />
              </Tooltip>
            </div>
            <div>
              <Button
                shape="circle"
                size="small"
                icon={<FacebookIconSm />}
                style={{backgroundColor: "#EBEEF5", color: "#3B5998", border: "none"}}
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${state.profileData.username}&amp`}
                title="Share on Facebook"
              />
            </div>
            <div>
              <Button
                title="Share on Twitter"
                shape="circle"
                size="small"
                icon={<TwitterIconSm />}
                target="_blank"
                style={{backgroundColor: "#E6F7FD", color: "#00ACEE", border: "none"}}
                href={`https://twitter.com/intent/tweet?text=Book a service with me on Coachli!&url=${window.location.origin}/${state.profileData.username}&hashtags=coachli,useCoachli`}
              />
            </div>
            <div>
              <Button
                shape="circle"
                size="small"
                icon={<MailIcon />}
                style={{backgroundColor: "#FBECEB", border: "none"}}
                href={`mailto:?subject=I wanted you to see this site&amp;body=Book a service with me on Coachli ${window.location.origin}/${state.profileData.username}`}
              />
            </div>
          </Space>
        </div>
      </StyledSidebarProfileShareWrapper>
    </StyledSidebarProfileWrapper>
  );
}
