import {MenuOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {BrandImage} from "../brand-image";
import {StyledHeader, StyledHeaderWrapper} from "./dashboard-header.style";

interface IDashboardHeaderProps {
  toggleNav: () => void;
}

const DashboardHeader: React.FC<IDashboardHeaderProps> = ({toggleNav}) => {
  return (
    <StyledHeaderWrapper>
      <StyledHeader>
        <BrandImage variant="dark" />
        <div className="header-icon-container" onClick={toggleNav}>
          <MenuOutlined />
        </div>
      </StyledHeader>
    </StyledHeaderWrapper>
  );
};

export {DashboardHeader};
