import {
  CheckCircleTwoTone,
  CopyFilled,
  FacebookFilled,
  MailFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import {Button, Space} from "antd";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../../redux/store";
import {StyledBookingRightColumnHeader} from "../../../../styles/booking/booking";
import {useBookingData} from "../../booking-context";
import {IBookingStepProps} from "../../i-step";
import {
  StyledSuccessBookingInfo,
  StyledSuccessBookingShareSection,
  StyledSuccessBookingShareSectionInfo,
  StyledSuccessBookingShareSectionInfoWrapper,
} from "./step-3.style";
import {CopyIcon} from "../../../../components/icons/icons";

const RescheduleBookingStep3: React.FC<IBookingStepProps> = ({updateStep}) => {
  const navigate = useNavigate();
  const {data} = useBookingData();
  const {customerBooking} = useSelector((state: RootState) => state.booking);
  return (
    <div>
      <Space direction="vertical" size={24}>
        <div>
          <CheckCircleTwoTone twoToneColor="#52C41A" style={{fontSize: "2.1rem"}} />
        </div>
        <StyledBookingRightColumnHeader>
          Reschedule successful
        </StyledBookingRightColumnHeader>
        <StyledSuccessBookingInfo>
          We sent a confirmation and a calendar invitation with information on how to
          attend the session to <strong>{customerBooking.booking?.email_address}</strong>
        </StyledSuccessBookingInfo>
        <div>
          <Button
            shape="round"
            onClick={() => navigate(`/${customerBooking.profile?.username}`)}
          >
            Return to profile
          </Button>
        </div>
        {/* <div>
          <Button shape="round" type="primary" onClick={() => updateStep(1)}>
            Step 1
          </Button>
        </div>
        <div>
          <Button shape="round" type="primary" onClick={() => updateStep(2)}>
            Step 2
          </Button>
        </div> */}
      </Space>
      <StyledSuccessBookingShareSection>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <StyledBookingRightColumnHeader>Spread the news</StyledBookingRightColumnHeader>
          {/* <StyledSuccessBookingShareSectionInfoWrapper> */}
          <StyledSuccessBookingShareSectionInfo>
            <p className="info-text">
              Let people know you just booked a session with{" "}
              <span className="mentor-name">
                {customerBooking.mentor?.firstName} {customerBooking.mentor?.lastName}
              </span>
            </p>
            {/* <div> */}
            <h3 className="share-heading">Share update via</h3>
            {/* </div> */}
            <div>
              <Space direction="horizontal" size={16}>
                <div>
                  <Button
                    title="Copy link"
                    shape="circle"
                    size="large"
                    icon={<CopyIcon />}
                    style={{backgroundColor: "#F4EEFC", color: "#9254DE", border: "none"}}
                  />
                </div>
                <div>
                  <Button
                    title="Share on Facebook"
                    shape="circle"
                    size="large"
                    icon={<FacebookFilled />}
                    style={{backgroundColor: "#EBEEF5", color: "#3B5998", border: "none"}}
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${customerBooking.profile?.username}&amp`}
                  />
                </div>
                <div>
                  <Button
                    title="Share on Twitter"
                    shape="circle"
                    size="large"
                    icon={<TwitterOutlined />}
                    style={{backgroundColor: "#E6F7FD", color: "#00ACEE", border: "none"}}
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?text=I just booked a session with ${customerBooking.mentor?.firstName} ${customerBooking.mentor?.lastName} on Coachli!&url=${window.location.origin}/${customerBooking.profile?.username}&hashtags=coachli,useCoachli`}
                  />
                </div>
                <div>
                  <Button
                    title="Send Mail"
                    shape="circle"
                    size="large"
                    icon={<MailFilled />}
                    style={{backgroundColor: "#FBECEB", color: "#DB4437", border: "none"}}
                    href={`mailto:?subject=I wanted you to see this site&amp;body=I just booked a session with ${customerBooking.mentor?.firstName} ${customerBooking.mentor?.lastName} on Coachli! ${window.location.origin}/${customerBooking.profile?.username}`}
                  />
                </div>
              </Space>
            </div>
          </StyledSuccessBookingShareSectionInfo>
          {/* </StyledSuccessBookingShareSectionInfoWrapper> */}
        </Space>
      </StyledSuccessBookingShareSection>
    </div>
  );
};

export {RescheduleBookingStep3};
