import styled from "styled-components";
import {Layout} from "antd";
import {Link} from "react-router-dom";

export const StyledLayout = styled(Layout)`
  background-color: #ffffff;
`;
export const StyledMainSection = styled.main`
  margin-top: 7.101rem;
`;
export const StyledChildrenAndImageWrapper = styled.div`
  display: flex;
`;
export const StyledChildrenWrapper = styled.div`
  flex: 1;
`;
export const StyledAuthFormWrapper = styled.div`
  max-width: 41.6rem;
  width: 100%;
  margin: 9.899rem auto;
  padding: 0 1rem;
  //   border: 1px solid black;

  .form-main-heading {
    font-weight: 700;
    font-size: 3rem;
  }
  .form-sub-heading {
    font-weight: 400;
    font-size: 1.6rem;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 500px) {
    margin: 4rem 0;
    max-width: 100%;
    padding: 0 2.5rem;
    .form-main-heading {
      font-size: 2.4rem;
    }
    .form-sub-heading {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
`;
export const StyledImageWrapper = styled.div`
  max-width: 58.4rem;
  @media only screen and (max-width: 1120px) {
    display: none;
  }
`;
export const StyledInfoBlock = styled.div`
  .info-block-text {
    font-weight: 400;
    font-size: 1.2rem;
  }
`;
export const StyledLink = styled(Link)`
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: -1.3rem;
  cursor: pointer;
  color: #00171f;
`;
