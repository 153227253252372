import {PropsWithChildren} from "react";
import {createContext} from "./create-context";
import {TBank, useGetConnectedBankAccount} from "../react-query-hooks/payout";
import {useGetCurrencies} from "../react-query-hooks/general";
import {FullPageLoader} from "../components/full-page-loader";
import {Alert} from "antd";
import {nonEuCurrencies} from "../utils/bank";

type TCurrencyState = {
  formCurrencies: string[];
  paymentProvider: string;
  connectedBankStatus: boolean;
  bankDetails: TBank | undefined;
};

type TCurrencyContextState = {
  state: TCurrencyState;
};

const [useContext, ContextProvider] = createContext<TCurrencyContextState>();

export const useCurrencyContext = useContext;

export const CurrencyProvider = ({children}: PropsWithChildren) => {
  const getCurrenciesQuery = useGetCurrencies();
  const getConnectedBankAccount = useGetConnectedBankAccount();

  if (getConnectedBankAccount.isLoading) {
    return <FullPageLoader />;
  }

  if (getCurrenciesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getCurrenciesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getCurrenciesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  if (
    getConnectedBankAccount.isError &&
    getConnectedBankAccount.error.message !==
      "Sorry, You do not have a bank account saved! Please, create one and try again!"
  ) {
    return (
      <Alert
        message="Error"
        description={getConnectedBankAccount.error.message}
        type="error"
        showIcon
      />
    );
  }

  const currenciesResult = getCurrenciesQuery.data;

  const bankPaymentProvider =
    getConnectedBankAccount.data?.bank.provider ?? "flutterwave";
  const bankDetails = getConnectedBankAccount.data?.bank;

  function handleCurrencies() {
    if (bankPaymentProvider === "stripe") {
      const filteredEUCurrencies = currenciesResult.filter(
        (currency) => !nonEuCurrencies.includes(currency)
      );

      return filteredEUCurrencies;
    } else {
      return currenciesResult;
    }
  }

  function handleConnectedBankStatus(): boolean {
    return !bankDetails ? false : true;
  }

  const state = {
    connectedBankStatus: handleConnectedBankStatus(),
    formCurrencies: handleCurrencies(),
    paymentProvider: bankPaymentProvider,
    bankDetails,
  };

  return <ContextProvider value={{state}}>{children}</ContextProvider>;
};
