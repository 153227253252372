import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {IAvailabilityFormValues} from "./i-availability-form";

// create user unavailability
export const createAvailabilityService = async (
  selections: IAvailabilityFormValues,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "availability", selections, config);

  return response.data;
};
// Get user unavailability
export const getUserAvailabilityService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "availability", config);

  return response.data;
};
