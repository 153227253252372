import {Avatar, Button, Dropdown, Menu} from "antd";
import {StyledPublicProfileNav, StyledPublicProfileNavWrapper} from "./navabr.style";
import {MenuOutlined, UserOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

export const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/auth/login">Log in</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/auth/register">Become a coach</Link>
    </Menu.Item>
  </Menu>
);

type TPublicProfileNavbarProps = {
  avatarUrl?: string | null;
  firstName?: string;
  lastName?: string;
  username?: string;
};

export function PublicProfileNavbar({
  avatarUrl,
  firstName,
  lastName,
  username,
}: TPublicProfileNavbarProps) {
  return (
    <StyledPublicProfileNavWrapper>
      <StyledPublicProfileNav>
        <div className="coach_info">
          <Link to={`/${username}`}>
            {avatarUrl ? (
              <Avatar
                size={{xs: 50, sm: 50, md: 55, lg: 55, xl: 55, xxl: 55}}
                src={avatarUrl}
              />
            ) : (
              <Avatar
                size={{xs: 50, sm: 50, md: 55, lg: 55, xl: 55, xxl: 55}}
                icon={<UserOutlined />}
              />
            )}
          </Link>
          <div className="coach_info_details">
            <p>{`${firstName} ${lastName}`}</p>
            {/* <p>{state.bookingNavData.shortBio}</p> */}
          </div>
        </div>
        <div>
          <Dropdown overlay={menu} trigger={["click", "hover"]}>
            <Button
              type="text"
              icon={<MenuOutlined style={{fontSize: "20px"}} />}
            ></Button>
          </Dropdown>
        </div>
      </StyledPublicProfileNav>
    </StyledPublicProfileNavWrapper>
  );
}
