import {Image, Space} from "antd";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Navbar} from "../../components/navbar";
import {RootState} from "../../redux/store";
import {
  StyledAuthFormWrapper,
  StyledChildrenAndImageWrapper,
  StyledChildrenWrapper,
  StyledImageWrapper,
  StyledLayout,
  StyledMainSection,
} from "./auth-form-wrapper.style";

import ReactGA from "react-ga";

interface IAuthFormWrapper {
  children: React.ReactNode;
}

const AuthFormWrapper: React.FC<IAuthFormWrapper> = ({children}) => {
  const navigate = useNavigate();
  const {userInfo, isSuccess: isSuccessUserLogin} = useSelector(
    (state: RootState) => state.userLogin
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo && userInfo.isCustomer) {
      navigate("/dashboard/customer/home");
    }
    if (userInfo && !userInfo.isCustomer) {
      navigate("/dashboard/onboarding");
    }
  }, [navigate, userInfo]);

  return (
    <StyledLayout>
      <Navbar />
      <StyledMainSection>
        <StyledChildrenAndImageWrapper>
          <StyledChildrenWrapper>
            <StyledAuthFormWrapper>
              <Space direction="vertical" size={29} style={{display: "flex"}}>
                {children}
              </Space>
            </StyledAuthFormWrapper>
          </StyledChildrenWrapper>
          <StyledImageWrapper>
            <Image
              preview={false}
              width={"100%"}
              src="/images/auth-image.svg"
              alt="Brand Logo"
            />
          </StyledImageWrapper>
        </StyledChildrenAndImageWrapper>
      </StyledMainSection>
    </StyledLayout>
  );
};

export {AuthFormWrapper};
