import styled from "styled-components";

export const StyledAvailabilityWidgetWrapper = styled.div`
  .availability-widget-heading {
    font-weight: 700;
    font-size: 2rem;
    color: #00171f;
  }

  .availability-widget-card {
    margin-top: 2.4rem;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 1.6rem;

    .availability-widget-card-bio {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
`;

export const StyledAvailabilityWidgetInfoBlock = styled.div`
  background-color: #fff1f0;
  border-radius: 5px;
  padding: 1.1rem 2rem;
  font-weight: 400;
  font-size: 1.4rem;
`;
