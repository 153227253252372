import {Alert, Breadcrumb, Spin, Tabs} from "antd";

import {Link, useParams, useSearchParams} from "react-router-dom";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";
import {LiveClassesTemplateWidget} from "../../../../components/live-classes/template-widget/template-widget";
import {useGetLiveClass} from "../../../../react-query-hooks/live-events/live-classes";
import {AttendeesComponent} from "../../../../components/live-classes/attendees";
import {ShareClassWidget} from "../../../../components/live-classes/share-class-widget";
import {useEffect, useState} from "react";
import {useFetchCoHosts} from "../../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {DashboardLiveClassDetails} from "../../../../components/live-classes/class-details";
import {CoHostList} from "../../../../components/live-classes/cohost-list";
import {LiveClassesForm} from "../../../../components/live-classes/live-classes-form/live-classes-form";

const ViewLiveClassPage = () => {
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  const formType = searchParams.get("formType");
  const currentUrlTab = searchParams.get("currentTab");
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>("1");
  const getLiveClassQueryInfo = useGetLiveClass(id as string);

  const fetchClassCoHostsQuery = useFetchCoHosts(id);

  useEffect(() => {
    if (currentUrlTab && currentUrlTab === "2") {
      setDefaultActiveKey(currentUrlTab);
    }
  }, [currentUrlTab]);

  const pageTitle = "View Live Class";

  if (getLiveClassQueryInfo.isLoading) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (getLiveClassQueryInfo.isError) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <Alert
          message="Error"
          description={getLiveClassQueryInfo.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }
  if (fetchClassCoHostsQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (fetchClassCoHostsQuery.isError) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <Alert
          message="Error"
          description={fetchClassCoHostsQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper pageTitle={getLiveClassQueryInfo.data.name}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/live-classes">Live Classes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span
            style={{
              opacity: getLiveClassQueryInfo.isFetching ? 0.2 : 1,
            }}
          >
            {" "}
            {getLiveClassQueryInfo.isLoading ? (
              <Spin />
            ) : getLiveClassQueryInfo.data.name ? (
              getLiveClassQueryInfo.data.name.substring(0, 20) + "..."
            ) : (
              "Draft name"
            )}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <Spin spinning={getLiveClassQueryInfo.isFetching}>
            <Tabs defaultActiveKey={defaultActiveKey}>
              <Tabs.TabPane tab="Class details" key="1">
                <LiveClassesForm
                  eventId={id as string}
                  liveClassData={getLiveClassQueryInfo.data}
                  coHosts={fetchClassCoHostsQuery.data}
                  formType="edit"
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Attendees and insights" key="2">
                <AttendeesComponent />
              </Tabs.TabPane>
              {/* <Tabs.TabPane tab="Co-hosts" key="3">
                <CoHostList />
              </Tabs.TabPane> */}
            </Tabs>
          </Spin>
        </div>
        <div className="widget-area">
          {formType === "edit" && (
            <ShareClassWidget
              isFetching={getLiveClassQueryInfo.isFetching}
              classData={getLiveClassQueryInfo.data}
            />
          )}
          <LiveClassesTemplateWidget />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {ViewLiveClassPage};
