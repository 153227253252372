import {Button, Drawer, Modal} from "antd";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {NewPayWithFlutterwaveStep} from "../../../../container/live-classes/booking/new/pay-with-flutterwave";
import {NewPayWithPaystack} from "../../../../container/live-classes/booking/new/pay-with-paystack/pay-with-paystack";
import moment from "moment";
import {useServiceBookingContext} from "../../../../context/service-booking/service-booking.context";
import {FlutterwaveConfig} from "flutterwave-react-v3/dist/types";
import {PaystackProps} from "react-paystack/dist/types";
import {handleServiceSuccessBookingLink} from "../../../../utils/meeting";
import {
  useCreateBooking,
  useCreateBundleBooking,
} from "../../../../react-query-hooks/public-profile";
import {ServiceBookingForm} from "../booking-form";
import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";

export const StyledPublicProfileOfferingDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 68.2rem !important;
    /* height: fit-content !important; */
  }

  @media (max-width: 768px) {
    .ant-drawer-content-wrapper {
      width: 40rem !important;
    }
  }

  @media (max-width: 425px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;
type Currency = "NGN" | "GHS" | "USD" | "ZAR";

export function ServiceBookingModal() {
  const customer_timezone = moment.tz.guess(true);
  const {state, dispatch} = useServiceBookingContext();
  const createBookingQueryMutation = useCreateBooking();
  const createBundleBookingQueryMutation = useCreateBundleBooking();

  function updateStep(step: number) {
    if (step > 3) {
      return;
    }
    dispatch({
      type: "update-booking-step",
      payload: step,
    });
  }

  function handleBookingModalOk() {
    dispatch({type: "show-booking-modal", payload: false});
  }

  function handleBookingModalCancel() {
    // if (state.currentBookingStep === 4) {
    //   navigate(`/${state.eventData?.username}`);
    //   dispatch({type: "reset-state"});
    // }
    Modal.destroyAll();
    dispatch({type: "show-booking-modal", payload: false});
    dispatch({
      type: "update-booking-step",
      payload: 1,
    });
  }

  const fwConfigParams: FlutterwaveConfig = {
    amount: Number(state.serviceBookingApiResponseData?.amount) || 0,
    customer: {
      email: `${state.serviceBookingApiResponseData?.email_address}`,
      name: `${state.serviceBookingApiResponseData?.first_name} ${state.serviceBookingApiResponseData?.last_name}`,
      phone_number: "",
    },
    customizations: {
      title: `Payment for ${state.serviceData?.service.name}`,
      description: "",
      logo: "https://pbs.twimg.com/profile_images/1594744754356604929/WHpvwR8w_400x400.jpg",
    },
    payment_options: "card,mobilemoney,ussd",
    public_key: process.env.REACT_APP_FLWPUBK_TEST as string,
    tx_ref: state.serviceData?.service.is_bundle
      ? (state.serviceBookingApiResponseData?.bundle_reference as string)
      : (state.serviceBookingApiResponseData?.reference as string),
    currency: state.serviceBookingApiResponseData?.currency as string,
    meta: {
      firstName: state.serviceBookingApiResponseData?.first_name,
      lastName: state.serviceBookingApiResponseData?.last_name,
      emailAddress: state.serviceBookingApiResponseData?.email_address,
      customer_timezone: customer_timezone,
    },
  };

  const psConfigParams: PaystackProps = {
    amount: (Number(state.serviceBookingApiResponseData?.amount) || 0) * 100,
    email: state.serviceBookingApiResponseData?.email_address as string,
    publicKey: process.env.REACT_APP_PSPUBK_TEST as string,
    currency: state.serviceBookingApiResponseData?.currency as Currency,
    firstname: state.serviceBookingApiResponseData?.first_name as string,
    lastname: state.serviceBookingApiResponseData?.last_name as string,
    metadata: {
      firstName: state.serviceBookingApiResponseData?.first_name,
      lastName: state.serviceBookingApiResponseData?.last_name,
      emailAddress: state.serviceBookingApiResponseData?.email_address,
      customer_timezone: customer_timezone,
      custom_fields: [
        {
          display_name: "firstName",
          variable_name: "firstName",
          value: state.serviceBookingApiResponseData?.first_name as string,
        },
        {
          display_name: "lastName",
          variable_name: "lastName",
          value: state.serviceBookingApiResponseData?.last_name as string,
        },
        {
          display_name: "emailAddress",
          variable_name: "emailAddress",
          value: state.serviceBookingApiResponseData?.email_address as string,
        },
      ],
    },
    reference: state.serviceData?.service.is_bundle
      ? (state.serviceBookingApiResponseData?.bundle_reference as string)
      : (state.serviceBookingApiResponseData?.reference as string),
  };

  const successRedirectLink = handleServiceSuccessBookingLink({
    serviceId: String(state.serviceData?.service.id),
    slug: state.urlParams.slug as string,
    username: state.urlParams.username as string,
  });

  function handleView() {
    let content;
    switch (state.currentBookingStep) {
      // case 4:
      //   content = <NewSuccessStep extraParams={extraParams} />;
      //   break;
      case 3:
        content = (
          <NewPayWithPaystack
            updateStep={updateStep}
            psConfigParams={psConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      case 2:
        content = (
          <NewPayWithFlutterwaveStep
            updateStep={updateStep}
            fwConfigParams={fwConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      default:
      case 1:
        content = (
          <ServiceBookingForm
            serviceBookingQueryMutation={
              state.serviceData?.service.is_bundle
                ? createBundleBookingQueryMutation
                : createBookingQueryMutation
            }
            updateStep={updateStep}
          />
        );
    }
    return content;
  }
  return (
    <StyledPublicProfileOfferingDrawer
      title={
        state.currentBookingStep === 1
          ? "Checkout"
          : state.currentBookingStep === 2 || state.currentBookingStep === 3
          ? "Payment"
          : null
      }
      placement="right"
      visible={state.isBookingModalOpen}
      // onOk={handleBookingModalOk}
      onClose={handleBookingModalCancel}
      maskClosable={false}
      closable={false}
      extra={
        <Button
          type="ghost"
          size="small"
          icon={<CloseOutlined />}
          onClick={handleBookingModalCancel}
          disabled={state.currentBookingStep !== 1}
        ></Button>
      }
    >
      {handleView()}
      {/* <StyledModalContentWrapper>{handleView()}</StyledModalContentWrapper> */}
    </StyledPublicProfileOfferingDrawer>
  );
}
