import {Select} from "antd";
import styled from "styled-components";

export const StyledBookingLeftColumn = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  padding: 6.7rem 3rem 2rem 3rem;
  width: 37.8rem;
  @media (max-width: 1050px) {
    display: none;
  }
`;
export const StyledBackLink = styled.div`
  font-weight: 400;
  color: #9747ff;
  .back-link {
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;
export const StyledHeading = styled.h1`
  font-weight: 700;
  font-size: 2.4rem;
  margin-top: 3rem;
  text-transform: capitalize;
`;

export const StyledCoachInfoSection = styled.div`
  margin-top: 4.9rem;
`;
export const StyledCoachMainInfoSection = styled.div`
  margin-top: 2.4rem;
  .coach-name {
    font-weight: 600;
    font-size: 2rem;
  }
  .coach-role {
    font-weight: 600;
    font-size: 1.6rem;
    margin-top: 1rem;
  }
  .coach-bio {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
  }
`;
export const StyledServiceInfoSection = styled.div`
  margin-top: 1.7rem;
  .service-info-item-value {
    font-weight: 600;
    font-size: 1.6rem;
    color: #00171f;
  }
`;
export const StyledCustomSelect = styled(Select)`
  /* width: 100%; */
  &.ant-select-open {
    width: 15rem;
  }
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    .ant-select-selection-item {
      font-weight: 600;
      font-size: 1.6rem;
      color: #00171f;
      /* max-width: 30rem; */
    }
  }
`;
export const StyledCustomCurrencySelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    /* background: transparent !important;
    border: none !important;
    box-shadow: none !important; */
    padding-left: 0 !important;
    .ant-select-selection-item {
      font-weight: 600;
      color: #00171f;
      max-width: 30rem;
    }
  }
`;

export const StyledBookingLeftColumnMobile = styled.div`
  display: none;
  @media (max-width: 1050px) {
    display: block;
    padding: 2rem 3rem;
  }
`;
export const StyledBookingLeftColumnMobileCoachInfoSection = styled.div`
  padding: 2.4rem 0 1.7rem 0;
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 1.6rem;
  border-bottom: 1px solid #e3e3e3;
`;
export const StyledMobileCoachMainInfoSection = styled.div`
  font-weight: 600;
  .coach-name {
    font-size: 1.6rem;
  }
  .coach-role {
    font-size: 1.4rem;
  }
`;
export const StyledMobileServiceInfoSection = styled.div`
  padding: 1.5rem 0 2.5rem 0;
  border-bottom: 1px solid #f0f0f0;
  .mobile-service-title {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .mobile-service-info {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #515151;
  }
`;

export const StyledMobileServiceInfoDetailsSection = styled.div`
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  // justify-content: space-between;
  flex-wrap: wrap;
  .service-info-item-value {
    font-weight: 600;
    font-size: 1.6rem;
    color: #00171f;
  }
  // @media (max-width: 650px) {
  //   flex-direction: column;
  //   align-items: initial;
  // }
`;
