import {useQuery} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useClient} from "../utils/hooks";

const useGetCountries = () => {
  const client = useClient();

  return useQuery<
    {
      name: string;
      code: string;
    }[],
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["countries"],
    queryFn: () => client("countries").then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
const useGetCategories = () => {
  const client = useClient();

  return useQuery<string[], AxiosError<{status: boolean; message: string}>>({
    queryKey: ["categories"],
    queryFn: () => client("categories").then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
const useGetCurrencies = () => {
  const client = useClient();
  return useQuery<string[], AxiosError<{status: boolean; message: string}>>({
    queryKey: ["currencies"],
    queryFn: () => client("currencies").then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export {useGetCountries, useGetCategories, useGetCurrencies};
