import {Row, Col, Alert} from "antd";
import {useParams} from "react-router";
import {useGetCustomer} from "../../../../react-query-hooks/customer";
import {FullPageLoader} from "../../../full-page-loader";
import {CustomerMetricsSkeleton} from "../../customer-details-section/customer-metrics-skeleton";
import {StyledInfoItem} from "./info-section.style";

const InfoSection = () => {
  const {id} = useParams();
  const getCustomerQuery = useGetCustomer(id);

  if (getCustomerQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getCustomerQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getCustomerQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  return (
    <>
      {getCustomerQuery.isFetching ? (
        <CustomerMetricsSkeleton />
      ) : (
        <Row gutter={[32, 20]}>
          <Col span={24} md={8}>
            <StyledInfoItem>
              <p className="info-title">Name</p>
              <p className="info-value">{getCustomerQuery.data.name}</p>
            </StyledInfoItem>
          </Col>
          <Col span={24} md={8}>
            <StyledInfoItem>
              <p className="info-title">Email address</p>
              <p className="info-value">{getCustomerQuery.data.emailAddress}</p>
            </StyledInfoItem>
          </Col>
          {getCustomerQuery.data.phoneNumber && (
            <Col span={24} md={8}>
              <StyledInfoItem>
                <p className="info-title">Phone number</p>
                <p className="info-value">{getCustomerQuery.data.phoneNumber}</p>
              </StyledInfoItem>
            </Col>
          )}

          <Col span={24} md={8}>
            <StyledInfoItem>
              <p className="info-title">Number of purchases</p>
              <p className="info-value">{getCustomerQuery.data.numberOfPurchases}</p>
            </StyledInfoItem>
          </Col>
          <Col span={24} md={8}>
            <StyledInfoItem>
              <p className="info-title">Total volume</p>
              <p className="info-value">{getCustomerQuery.data.totalVolume}</p>
            </StyledInfoItem>
          </Col>
        </Row>
      )}
    </>
  );
};

export {InfoSection};
