import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {TUserObjApiResponse} from "../redux/auth/i-auth";
import {TFetchCustomerBookingPayload} from "../redux/booking/i-time-slots-payload";
import {TBooking, TCoachReschedulePayload} from "../redux/call/i-call";
import {TProfileApiResponse} from "../redux/profile/i-profile-1";
import {PaginationMeta, TServiceApiResponse} from "../redux/service/i-service";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";
import {TLiveClassApiResponse} from "./live-events/live-classes";

type TLiveClassBooking = {
  id: number;
  userId: number;
  eventId: number;
  paymentProvider: string;
  roomId: string;
  current_session: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  bookingQuestions: string;
  is_series: boolean;
  is_paid: boolean;
  event_date: Date;
  reference: string;
  call_duration: number;
  coach_event_time: string;
  coach_end_time: string;
  customer_event_time: string;
  customer_end_time: string;
  customer_timezone: string;
  statusId: number;
  createdAt: Date;
  updatedAt: Date;
  startDate: Date;
  startTime: string;
  statusName: string;
  endTime: string;
};

type TLiveClassCoach = {
  firstName: string;
  lastName: string;
};

type TUpcomingcallsApiResponse = {
  mentor: Omit<TUserObjApiResponse, "password" | "emailAddress"> & {
    email: string;
  };
  mentorProfile: Omit<
    TProfileApiResponse,
    | "avatar"
    | "country"
    | "email"
    | "firstName"
    | "isSocailAuth"
    | "lastName"
    | "password"
    | "statusId"
    | "statusName"
  >;
  transactions: TBooking[];
};
export type TPastcallsApiResponse = {
  bookings: TBooking[];
};

export type TCustomerBookingResponse = {
  booking: TBooking;
  service: TServiceApiResponse;
  mentor: Omit<TUserObjApiResponse, "password" | "emailAddress"> & {
    email: string;
  };
  timeZone: string;
  unavailableDates: {
    from: string;
    to: string;
  }[];
  days: {
    sun: {from: string; to: string}[];
    mon: {from: string; to: string}[];
    tue: {from: string; to: string}[];
    wed: {from: string; to: string}[];
    thu: {from: string; to: string}[];
    fri: {from: string; to: string}[];
    sat: {from: string; to: string}[];
  };
  bookingTimeSlot: string;
  bookingToken: string | null;
  videoPlatform: "dailyCo" | "videoSdk";
  dailyCoUrl: string;
};
type TCustomerLiveClassBookingResponse = {
  eventBooking: TLiveClassBooking;
  event: TLiveClassApiResponse;
  bookingToken: string | null;
  coach: TLiveClassCoach;
  videoPlatform: "dailyCo" | "videoSdk";
  dailyCoUrl: string;
};

export type TCoachRescheduleCallPayload = {
  bookingReference: string;
  booking_year: string | number;
  booking_month: string | number;
  booking_date: string | number;
  timeSlot: string;
  description?: string;
};

const useGetUpcomingCalls = () => {
  const client = useClient();

  return useQuery<
    TUpcomingcallsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["upcoming_calls"],
    queryFn: () => client("booking/upcoming").then((data) => data.data),
    refetchInterval: 300000,
  });
};

const useGetPastCalls = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TPastcallsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["past_calls", page],
    queryFn: () =>
      client(`mentor-booking/past-calls?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    keepPreviousData: true,
  });
};

const useCoachCancelBooking = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (bookingRef: string) =>
      client(`booking/mentor/cancel/${bookingRef}`, {
        method: "PUT",
      }),
    onMutate: () => {
      const oldBookings = queryClient.getQueryData(["upcoming_calls"]);

      return () => queryClient.setQueryData(["upcoming_calls"], oldBookings);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Coach cancel booking", "Coach cancel booking fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Coach cancel booking", "Coach cancel booking success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["upcoming_calls"]}),
  });
};
const useCoachRescheduleBooking = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TCoachReschedulePayload) =>
      client(`booking/mentor`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach reschedule booking", "Coach reschedule booking fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach reschedule booking", "Coach reschedule booking success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["upcoming_calls"]}),
  });
};

const useGetCustomerBooking = (payload: TFetchCustomerBookingPayload) => {
  const client = useClient();

  return useQuery<
    TCustomerBookingResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_booking", payload.reference],
    queryFn: () =>
      client(`customer-booking/${payload.reference}/${payload.userType}`).then((data) => {
        if (data.data) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      }),
    enabled: !!payload.reference,
  });
};

const useGetCustomerBookingMutation = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TFetchCustomerBookingPayload) =>
      client(`customer-booking/${payload.reference}/${payload.userType}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
    },
  });
};

const useGetCustomerLiveClassBooking = (payload: {reference?: string}) => {
  const client = useClient();

  return useQuery<
    TCustomerLiveClassBookingResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_live_class_booking", payload.reference],
    queryFn: () =>
      client(`live-event/booking/join/${payload.reference}`).then((data) => {
        if (data.data) {
          return data.data;
        } else {
          throw new Error(data.message);
        }
      }),
    enabled: !!payload.reference,
  });
};

const useCoachRescheduleCall = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TCoachRescheduleCallPayload) =>
      client(`booking/mentor`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach reschedule booking", "Coach reschedule booking fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach reschedule booking", "Coach reschedule booking success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["upcoming_calls"]}),
  });
};

export {
  useGetUpcomingCalls,
  useGetPastCalls,
  useCoachCancelBooking,
  useCoachRescheduleBooking,
  useGetCustomerBooking,
  useGetCustomerBookingMutation,
  useGetCustomerLiveClassBooking,
  useCoachRescheduleCall,
};
