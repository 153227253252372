import {Button, Space} from "antd";
import {
  StyledMediaLogsWrapper,
  StyledPublicProfileOfferingDescriptionWrapper,
} from "../../../../public-profile/offering/product-details/description/description.style";
import {DownloadOutlined, FileOutlined} from "@ant-design/icons";
import {TMediaLogs} from "../../../../../react-query-hooks/products";
import {StyledContentListItem} from "../../../product-form/content-upload/content-upload.style";

type TMainProductDetails = {
  mediaLogs: TMediaLogs[];
  productDescription: string;
};

export function MainProductDetails({mediaLogs, productDescription}: TMainProductDetails) {
  function handleFileDownload(url: string, fileName: string) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
  return (
    <StyledPublicProfileOfferingDescriptionWrapper>
      <Space
        direction="vertical"
        size={24}
        style={{
          display: "flex",
        }}
      >
        <div>
          {/* <h2 className="content-title">About this product</h2> */}
          <div
            className="content-description"
            dangerouslySetInnerHTML={{__html: productDescription}}
          />
        </div>
        <div>
          <Space size={16} direction="vertical" style={{display: "flex"}}>
            <h2 className="content-title">Content</h2>
            <StyledMediaLogsWrapper>
              <Space direction="vertical" size={12} style={{display: "flex"}}>
                {mediaLogs.map((item) => (
                  <StyledContentListItem key={item.id}>
                    <div className="content-list-item-info">
                      <div>
                        <FileOutlined className="content-list-item-file-icon" />{" "}
                        <span
                          className="content-list-item-name"
                          style={{fontWeight: "600", color: "#00171F"}}
                        >
                          {`${item.file_name} ${item.file_type}. ${item.file_size}`}
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        onClick={() => handleFileDownload(item.media_url, item.file_name)}
                        size="large"
                        shape="circle"
                        type="link"
                      >
                        Download
                      </Button>
                    </div>
                  </StyledContentListItem>
                ))}
              </Space>
            </StyledMediaLogsWrapper>
          </Space>
        </div>
      </Space>
    </StyledPublicProfileOfferingDescriptionWrapper>
  );
}
