import ReactGA from "react-ga";

const AnalyticsEvent = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};

export {AnalyticsEvent};
