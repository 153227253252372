import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {client, ICustomConfig} from "./api-client";

const useClient = () => {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const token = userInfo?.accessToken;
  return React.useCallback(
    (endpoint: string, config?: Partial<ICustomConfig>) =>
      client(endpoint, {...config, token}),
    [token]
  );
};

export {useClient};
