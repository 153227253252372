import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {IProfileData} from "./i-profile-1";

// Get user profile
export const getUserProfileService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "profile", config);

  return response.data;
};

// Update user profile
export const updateUserProfileService = async (
  profileData: IProfileData,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "profile", profileData, config);

  return response.data;
};

export const changePassword = async (token: string, payload: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "settings/password", payload, config);

  return response.data;
};
