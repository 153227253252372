import * as yup from "yup";
import {SearchOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Alert, Avatar, Button, Input, message, Radio} from "antd";
import {Controller, useForm} from "react-hook-form";
import {
  TCustomerMessageContact,
  TCustomerMessageResObj,
  TMessageResObj,
  TSendMessageCustomerPayload,
  useGetCustomerMessageContacts,
  useSendMessageCustomer,
} from "../../../react-query-hooks/messages";
import {capitalizeFirstLetter} from "../../../services/helpers";
import {StyledModalContentWrapper} from "../../calls/upcoming-calls/call-card/call-card.style";
import {FullPageLoader} from "../../full-page-loader";
import {
  StyledMessageModalInputWrap,
  StyledMessageModalInput,
  StyledMessageModalInputIcon,
  StyledMessagesListImage,
  StyledMessagesListImageH2,
  StyledMessageContactListItem,
} from "../messages.style";
import {CustomModal} from "../../modal";
import {useMessageContext} from "../../../context/message-context";
import {useState} from "react";
import {CustomInputComponent} from "../../input/input-component";

const formSchema = yup.object().shape({
  selectedContact: yup.string().trim().required("Service name is required"),
});

const msgFormSchema = yup.object().shape({
  message: yup.string().trim().required("Service name is required"),
});

type TContactListProps = {
  showModalState: boolean;
  showModalStateUpdate: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomerContactList = ({
  showModalState,
  showModalStateUpdate,
}: TContactListProps) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const getCustomerMessageContactsQuery = useGetCustomerMessageContacts();
  const sendMessageCustomerQueryMutation = useSendMessageCustomer();
  const {state, dispatch} = useMessageContext();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    reset,
  } = useForm<{selectedContact: string}>({
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });
  const {
    handleSubmit: handleMessageSubmit,
    formState: {errors: messageErrors},
    control: messageControl,
    reset: messageReset,
    watch: messageWatch,
  } = useForm<{message: string}>({
    resolver: yupResolver(msgFormSchema),
    mode: "onChange",
  });

  const handleOk = () => {
    handleSubmit(onSubmit);
  };
  const handleMessageOk = () => {
    handleMessageSubmit(onMessageSubmit);
  };
  const handleCancel = () => {
    showModalStateUpdate(false);
    reset();
  };
  const handleMessageCancel = () => {
    setShowMessageModal(false);
    messageReset();
    reset();
  };

  const onSubmit = async (values: {selectedContact: string}) => {
    // console.log("Selected contact::::", values.selectedContact);
    const contact = getCustomerMessageContactsQuery.data?.data.contacts.find(
      (contact) =>
        contact.name + contact.email_address.toLowerCase() === values.selectedContact
    );

    const newContact: TCustomerMessageContact = {
      email_address: contact?.email_address.toLowerCase() as string,
      mentorId: contact?.mentorId as string,
      name: contact?.name as string,
    };
    dispatch({
      type: "add-to-customer-message-contact-list",
      payload: {
        contact: newContact,
      },
    });
    showModalStateUpdate(false);
    setShowMessageModal(true);
  };

  const onMessageSubmit = async (values: {message: string}) => {
    const payload: TSendMessageCustomerPayload = {
      message: values.message,
      userId: state.customerContact?.mentorId as string,
    };

    sendMessageCustomerQueryMutation.mutate(payload, {
      onSuccess: (data) => {
        message.success("Message delivered successfully!");
        dispatch({
          type: "set-customer-active-message",
          payload: {
            activeMessage: {
              createdAt: "",
              customerName: state.customerContact?.name as string,
              emailAddress: state.customerContact?.email_address.toLowerCase() as string,
              id: Math.random(),
              isReadCustomer: false,
              isReadMentor: false,
              updatedAt: "",
              userId: "",
              mentorEmailAddress:
                state.customerContact?.email_address.toLowerCase() as string,
              mentorName: state.customerContact?.name as string,
            },
          },
        });
        showModalStateUpdate(false);
        setShowMessageModal(false);
        messageReset();
        reset();
      },
    });
  };

  return (
    <>
      <CustomModal
        visibleState={showModalState}
        title="Send a new message"
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button
            key="ok"
            type="primary"
            shape="round"
            onClick={handleSubmit(onSubmit)}
            disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
          >
            Select
          </Button>,
          <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          {getCustomerMessageContactsQuery.isLoading ? (
            <FullPageLoader />
          ) : getCustomerMessageContactsQuery.isError ? (
            <Alert
              message="Error"
              description={getCustomerMessageContactsQuery.error.message}
              type="error"
              showIcon
            />
          ) : (
            <>
              <StyledMessageModalInputWrap>
                <Input.Group compact style={{display: "flex"}}>
                  <StyledMessageModalInput type="text" placeholder="Search" />
                  <StyledMessageModalInputIcon>
                    <Button
                      shape="default"
                      size="large"
                      icon={<SearchOutlined />}
                      style={{
                        backgroundColor: "#fff",
                        color: "#3B5998",
                        border: "1px solid #f5f5f5",
                      }}
                      type="link"
                      target="_blank"
                      title="Search message"
                    />
                  </StyledMessageModalInputIcon>
                </Input.Group>
              </StyledMessageModalInputWrap>
              <div>
                <Controller
                  control={control}
                  name="selectedContact"
                  render={({field: {onChange, value}}) => (
                    <Radio.Group
                      style={{width: "100%"}}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {getCustomerMessageContactsQuery.data.data.contacts.map(
                        (contact, index) => (
                          <StyledMessageContactListItem
                            key={contact.name + contact.email_address}
                          >
                            <StyledMessagesListImage>
                              {/* <StyledMessagesListImageInitials>{contact.}</StyledMessagesListImageInitials> */}
                              <Avatar
                                size="large"
                                style={{color: "#5B2B99", backgroundColor: "#F5EDFF"}}
                              >
                                {contact.name.split(" ")[0].charAt(0).toUpperCase()}
                                {contact.name.split(" ")[1].charAt(0).toUpperCase()}
                                {/* LC */}
                              </Avatar>
                              <StyledMessagesListImageH2>
                                {capitalizeFirstLetter(contact.name.split(" ")[0])}{" "}
                                {capitalizeFirstLetter(contact.name.split(" ")[1])}
                              </StyledMessagesListImageH2>
                            </StyledMessagesListImage>
                            <div style={{marginLeft: "auto"}}>
                              <Radio value={contact.name + contact.email_address} />
                            </div>
                          </StyledMessageContactListItem>
                        )
                      )}
                    </Radio.Group>
                  )}
                />
              </div>
            </>
          )}
        </StyledModalContentWrapper>
      </CustomModal>
      <CustomModal
        visibleState={showMessageModal}
        title={`Send a new message to ${state.customerContact?.name}`}
        okFunc={handleMessageOk}
        cancelFunc={handleMessageCancel}
        footerContent={[
          <Button
            key="msgOk"
            shape="round"
            type="primary"
            onClick={handleMessageSubmit(onMessageSubmit)}
            loading={sendMessageCustomerQueryMutation.isLoading}
            disabled={
              Object.keys(messageErrors).length > 0 ||
              Object.values(messageWatch()).length < 1
            }
          >
            {sendMessageCustomerQueryMutation.isLoading ? "Sending..." : "Send"}
          </Button>,
          <Button key="cancel" shape="round" type="default" onClick={handleMessageCancel}>
            Cancel
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          {/* <StyledModalContentInfo>
            <p className="content-text">
              Hey there! send your first message to {state.customerContact?.name}
            </p>
          </StyledModalContentInfo> */}
          <div>
            <CustomInputComponent
              id="message"
              name="message"
              type="textarea"
              label={`Send a personal message to ${state.customerContact?.name}`}
              placeholder="Type a message"
              error={messageErrors.message?.message}
              control={messageControl}
            />
          </div>
        </StyledModalContentWrapper>
      </CustomModal>
    </>
  );
};

export {CustomerContactList};
