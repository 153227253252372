import {Alert} from "antd";
import {useGetUnavailability} from "../../../../react-query-hooks/availability";
import {FullPageLoader} from "../../../full-page-loader";
import {
  StyledAvailabiltyFormWrapper,
  StyledUnAvailabiltyFormWrapper,
} from "../availability.style";
import {UnavailabilityForm} from "./unavailability-form";

const UnavailabilityTabContent = () => {
  const getUnavailabilityQuery = useGetUnavailability();

  return (
    <StyledUnAvailabiltyFormWrapper>
      <h2 className="unavailability-form-heading">Set your unavailable dates</h2>
      <StyledAvailabiltyFormWrapper
        className="unavailability-type"
        style={{marginTop: "2.4rem"}}
      >
        <p className="sub-heading">Block date ranges from your booking calendar</p>
        {getUnavailabilityQuery.isLoading || getUnavailabilityQuery.isFetching ? (
          <FullPageLoader />
        ) : getUnavailabilityQuery.isError ? (
          <Alert
            message="Error"
            description={getUnavailabilityQuery.error.message}
            type="error"
            showIcon
          />
        ) : (
          <UnavailabilityForm unavailabilities={getUnavailabilityQuery.data} />
        )}
      </StyledAvailabiltyFormWrapper>
    </StyledUnAvailabiltyFormWrapper>
  );
};

export {UnavailabilityTabContent};
