import {Button, Divider, Space, Spin, message} from "antd";
import {
  TTimeslotsPayload,
  useGetPublicProfileBookingTimeslots,
} from "../../../../../../../react-query-hooks/public-profile";
import {
  StyledBookingTimeComponent,
  StyledBookingTimeComponentItemWrapper,
  StyledNewBookingTimeComponentConfirmButton,
} from "../../../../../../../styles/booking/booking";
import {StyledNoUpcomingCallBox} from "../../../../upcoming-calls.style";
import {NewCustomCalendar} from "../../../../../../calendar/custom-calendar";
import {
  TCoachRescheduleCallPayload,
  TCustomerBookingResponse,
  useCoachRescheduleCall,
} from "../../../../../../../react-query-hooks/booking";
import {useBookingContext} from "../../../../../../../context/booking/booking-context";
import moment from "moment";
// import {useFormContext} from "react-hook-form";

export type UpdateStateDateInfoParams = {
  day: number;
  currentMonth: number;
  year: number;
  selectedDateString: Date;
};

export function CoachRescheduleCallCalendar({
  bookingData,
  onCloseRescheduleCallModalDrawer,
}: {
  bookingData: TCustomerBookingResponse;
  onCloseRescheduleCallModalDrawer: () => void;
}) {
  // const [showConfirm, setShowConfirm] = useState("");
  const getPublicProfileBookingTimeslotsMutation = useGetPublicProfileBookingTimeslots();
  const coachRescheduleCallMutation = useCoachRescheduleCall();
  const {state: bookingState, dispatch} = useBookingContext();
  // const {getValues, reset} = useFormContext();

  const handleConfirm = () => {
    const payload: TCoachRescheduleCallPayload = {
      bookingReference: bookingData.booking.reference,
      booking_year: bookingState.booking_year,
      booking_month: bookingState.booking_month,
      booking_date: bookingState.booking_date,
      timeSlot: bookingState.timeSelected as string,
      // description: getValues("personalMessage"),
    };

    coachRescheduleCallMutation.mutate(payload, {
      onSuccess: () => {
        message.success("Reschedule request sent successfully!");
        dispatch({
          type: "reset-state",
        });
        // reset();
      },
      onSettled: () => {
        onCloseRescheduleCallModalDrawer();
      },
    });
  };

  function fetchTimeSlots(payload: TTimeslotsPayload) {
    getPublicProfileBookingTimeslotsMutation.mutate(payload);
  }

  function resetStateTimeSelected() {
    dispatch({
      type: "reset-time-selected",
    });
  }
  function updateStateDateInfo({
    day,
    currentMonth,
    year,
    selectedDateString,
  }: UpdateStateDateInfoParams) {
    dispatch({
      type: "update-date-info",
      payload: {
        booking_date: day,
        booking_month: currentMonth + 1,
        booking_year: year,
        selectedDateString: selectedDateString,
      },
    });
  }
  return (
    <div>
      <h2 style={{fontWeight: 600, fontSize: "1.4rem", color: "#00171F"}}>
        Select date and time
      </h2>
      <div>
        <div>
          <NewCustomCalendar
            availableDays={bookingData.days}
            coachTimezone={bookingData.timeZone}
            customerTimezone={moment.tz.guess(true)}
            mentorId={bookingData.mentor.id}
            serviceId={bookingData.service.id}
            fetchTimeSlots={fetchTimeSlots}
            unavailableDates={bookingData.unavailableDates}
            resetStateTimeSelected={resetStateTimeSelected}
            updateStateDateInfo={updateStateDateInfo}
          />
        </div>
        {getPublicProfileBookingTimeslotsMutation.isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65vh",
              width: "100%",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {getPublicProfileBookingTimeslotsMutation.isSuccess && (
          <StyledBookingTimeComponent id="time-component">
            <div className="time-component-heading">
              {bookingState.selectedDateString.toDateString()}
            </div>
            <Divider style={{marginTop: "1.8rem", marginBottom: "2.4rem"}} />
            <Space size={8} direction="vertical" style={{display: "flex"}}>
              {getPublicProfileBookingTimeslotsMutation.data.data.timeSlots.length <
                1 && (
                <StyledNoUpcomingCallBox>
                  <p className="info-text">No time slot available for this date</p>
                </StyledNoUpcomingCallBox>
              )}
              {getPublicProfileBookingTimeslotsMutation.data.data.timeSlots.map(
                (timeSlot: string) => (
                  <StyledBookingTimeComponentItemWrapper key={timeSlot}>
                    <StyledNewBookingTimeComponentConfirmButton
                      block
                      size="large"
                      onClick={() =>
                        dispatch({
                          type: "update-selected-time",
                          payload: timeSlot,
                        })
                      }
                    >
                      {timeSlot}
                    </StyledNewBookingTimeComponentConfirmButton>
                    {bookingState.timeSelected === timeSlot && (
                      <Button
                        size="large"
                        onClick={handleConfirm}
                        loading={coachRescheduleCallMutation.isLoading}
                        type="primary"
                        shape="round"
                        block
                      >
                        Reschedule
                      </Button>
                    )}
                  </StyledBookingTimeComponentItemWrapper>
                )
              )}
            </Space>
          </StyledBookingTimeComponent>
        )}
      </div>
    </div>
  );
}
