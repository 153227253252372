import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledExtraItemWrapper = styled.div`
  padding: 10rem 0;
  overflow: hidden;

  @media (max-width: 1024) {
    padding: 7.5rem 0;
  }
  @media (max-width: 767px) {
    padding: 2.5rem 0 7.5rem 0;
  }
`;
export const StyledExtraItemInfo = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2.7rem;

  .extra-info-item-tag {
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #9747ff;
    border-radius: 1.6rem;
    border: none;
    background: #f5edff;
    /* font-family: "Plus Jakarta Sans"; */
    letter-spacing: 1px;
    padding: 7px 9px;
  }
  .extra-info-item-title {
    font-size: 5.2rem;
    font-weight: 700;
    color: #0f0049;

    letter-spacing: -0.722222px;
    // line-height: 62px;
  }
  .coming-soon-bubble {
    padding: 7px 9.5px;
    background-color: #e1caff;
    color: #9747ff;
    width: fit-content;
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 2rem;
  }
  .extra-info-item-desc {
    font-size: 1.8rem;
    color: #2f2d3b;
  }

  .extra-info-item-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .extra-info-item-list-item {
      display: flex;
      align-items: center;
      margin: 0;
      gap: 8px;

      .extra-icon {
        background: #f5edff;
        border-radius: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
      .extra-text {
        font-weight: 500;
        font-size: 1.6rem;
        color: #0f0049;
      }
    }
    .extra-info-item-list-item:nth-child(n + 2) {
      display: flex;
      align-items: center;
      margin: 1.6rem 0;
      gap: 8px;

      .extra-icon {
        background: #f5edff;
        border-radius: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
      .extra-text {
        font-weight: 500;
        font-size: 1.6rem;
        color: #0f0049;
      }
    }
  }

  @media (max-width: 1024px) {
    .extra-info-item-title {
      font-size: 4rem;
    }
  }
  @media (max-width: 767px) {
    .extra-info-item-title {
      font-size: 2.6rem;
    }
  }
  @media (max-width: 400px) {
    .extra-info-item-tag {
      font-size: 1.3rem;
    }
  }
`;
