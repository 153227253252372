import {Avatar} from "antd";
import {StyledLiveClassesHostInfoWidget} from "../../live-classes-section/details/main-details-section.style";
import {UserOutlined} from "@ant-design/icons";

type TOfferingCreatorDetails = {
  creatorInfo: {
    avatar: string | null;
    firstName: string;
    lastName: string;
    description: string;
  };
  title: string;
};

export function OfferingCreatorDetails({creatorInfo, title}: TOfferingCreatorDetails) {
  return (
    <StyledLiveClassesHostInfoWidget>
      <h2 className="host-heading-text">{title}</h2>
      <div className="host-info-wrapper">
        <div className="host-avatar-wrapper">
          {creatorInfo.avatar ? (
            <Avatar size={87} src={creatorInfo.avatar} />
          ) : (
            <Avatar size={87} icon={<UserOutlined />} />
          )}
        </div>
        <div className="host-name-info-wrapper">
          <p className="host-name">
            {creatorInfo.firstName} {creatorInfo.lastName}
          </p>
          <p className="host-role">{creatorInfo.description}</p>
        </div>
      </div>
    </StyledLiveClassesHostInfoWidget>
  );
}
