import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {TCoachReschedulePayload} from "./i-call";

// Get upcoming calls
export const getUpcomingCallsService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "booking/upcoming", config);

  return response.data;
};

// Get past calls
export const getPastCallsService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "mentor-booking/past-calls", config);

  return response.data;
};

// Reschedule call for Mentor
export const mentorRescheduleCallService = async (
  payload: TCoachReschedulePayload,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "booking/mentor/", payload, config);

  return response.data;
};

// Cancel call for Mentor
export const mentorCancelCallService = async (
  bookingReference: string,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    API_BASE_URL + "booking/mentor/cancel/" + bookingReference,
    {},
    config
  );

  return response.data;
};
