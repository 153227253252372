import styled from "styled-components";
import {motion} from "framer-motion";

import {Col, Row} from "antd";
import {HeartOutlined} from "@ant-design/icons";

export const StyledSection = styled(motion.div)`
  padding: 10rem 0;
  // background-color: #f5f5f5;
  @media (max-width: 767px) {
    padding: 5rem 0;
  }
`;
export const StyledSectionHeader = styled(motion.div)`
  text-align: center;
  margin-bottom: 3rem;
  overflow: hidden;

  .section-header-main-title {
    font-size: 4rem;
    font-weight: 700;
    color: #00171f;

    overflow: hidden;
  }
  .section-header-sub-title {
    margin-top: 2rem;
    font-size: 1.6rem;
    color: #737588;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    .section-header-main-title {
      font-size: 2.6rem;
    }
    .section-header-sub-title {
      font-size: 1.6rem !important;
    }
  }
`;
export const StyledContainer = styled.div`
  max-width: 128rem;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 1024px) {
    max-width: 102.4rem;
  }
  @media (max-width: 767px) {
    max-width: 76.7rem;
  }
`;

export const StyledRow = motion(Row);
export const StyledCol = motion(Col);
export const StyledHeartOutlined = motion(HeartOutlined);
