import styled from "styled-components";

export const StyledCalendarWrapper = styled.div`
  height: max-content;
  width: 35rem;
  /* border-radius: 30px; */
  /* padding: 20px; */
  position: relative;
  overflow: hidden;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

// .calendar {
//   height: max-content;
//   width: 35rem;
//   background-color: var(--dark-main);
//   /* border-radius: 30px; */
//   /* padding: 20px; */
//   position: relative;
//   overflow: hidden;
// }
