import {Col, Row, Tag} from "antd";
import {
  StyledTestimonialSection,
  StyledTestimonialHeader,
} from "../testimonial-section/testimonial-section.style";
import {
  StyledLine,
  StyledWhatMakesUsSpecialContentWrapper,
} from "./what-makes-us-special-section.style";
import {WhatMakesUsSpecialItem} from "./what-makes-us-special-item";
import {
  BankOutlined,
  BellOutlined,
  CreditCardOutlined,
  FileImageOutlined,
  GlobalOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {StyledContainer} from "../features-section/features-section.style";

function WhatMakesUsSpecialSection() {
  return (
    <StyledContainer>
      <StyledTestimonialSection>
        <StyledTestimonialHeader>
          <Tag
            className="section-title"
            style={{borderRadius: "1.6rem", border: "none"}}
            color="purple"
          >
            Features
          </Tag>
          <h3 className="section-header">What makes us special</h3>
          <p className="section-sub-text">Do more with less effort</p>
        </StyledTestimonialHeader>
        <StyledWhatMakesUsSpecialContentWrapper>
          <Row gutter={[32, 16]}>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<FileImageOutlined />}
                bgColor="#FFD6E7"
                title="Landing page"
                text="Get an automatic, stylish landing page created for you to sell"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<GlobalOutlined />}
                bgColor="#F4FFB8"
                title="Online payments"
                text="Receive payments online from multiple countries"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<BellOutlined />}
                bgColor="#F5EDFF"
                title="Automatic reminders"
                text="Get reminders of upcoming sessions and classes"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<SmileOutlined />}
                bgColor="#E6FFFB"
                title="Easy and Intuitive"
                text="Coachli is extremely easy to use and intuitive"
              />
            </Col>
          </Row>
          <StyledLine />
          <Row gutter={[32, 16]}>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<CreditCardOutlined />}
                bgColor="#FFD6E7"
                title="Multi currency support"
                text="Sell in over 100 currencies all over the world"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<MessageOutlined />}
                bgColor="#F4FFB8"
                title="Private messaging"
                text="Send private messages to your clients easily within your dashboard"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<PlusCircleOutlined />}
                bgColor="#F5EDFF"
                title="Unlimited offerings"
                text="Sell unlimited 1:1 services, digital products and live classes"
              />
            </Col>
            <Col span={24} md={12} lg={6}>
              <WhatMakesUsSpecialItem
                icon={<BankOutlined />}
                bgColor="#E6FFFB"
                title="Easy payout"
                text="Get your earnings paid directly to your bank account"
              />
            </Col>
          </Row>
        </StyledWhatMakesUsSpecialContentWrapper>
      </StyledTestimonialSection>
    </StyledContainer>
  );
}

export {WhatMakesUsSpecialSection};
