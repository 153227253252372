import {Button} from "antd";
import styled from "styled-components";

export const StyledBookingContainer = styled.div`
  max-width: 112.5rem;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  min-height: 100vh;
`;
export const StyledBookingWrapper = styled.div`
  display: flex;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const StyledBookingRightColumn = styled.div`
  background-color: #fff;
  min-height: 100vh;
  padding: 12.7rem 9.4rem 2rem 4rem;
  flex: 1;

  @media (max-width: 1050px) {
    padding: 2rem 3rem;
  }
`;
export const StyledBookingRightColumnHeader = styled.h2`
  font-weight: 600;
  font-size: 1.6rem;
`;
export const StyledCalendarModalWrapper = styled.div`
  width: 70rem;
  @media (max-width: 750px) {
    width: 100%;
  }
`;
export const StyledBookingRightColumnWrapper = styled.div`
  display: flex;
  gap: 5rem;

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 4.3rem;
  }
`;
export const StyledBookingTimeComponent = styled.div`
  flex: 1;
  .time-component-heading {
    font-weight: 600;
    font-size: 1.6rem;
    color: #00171f;
    padding-top: 1.2rem;
  }
`;
export const StyledBookingTimeComponentItemWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  max-width: 26rem;

  @media (min-width: 991px) {
    max-width: 100%;
  }
`;
export const StyledBookingTimeComponentButton = styled(Button)`
  background-color: #f0f5ff;
  border: 1px solid #2f54eb;
  border-radius: 8px;
  color: #2f54eb;
  font-weight: 600;
  font-size: 1.4rem;
  // padding: 2rem auto;
  // flex: 1;
`;
export const StyledBookingTimeComponentConfirmButton = styled(Button)`
  background-color: #1d39c4;
  border-radius: 8px;
  color: #ffffff;
  // padding: 2rem auto;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
  // flex: 1;
  // text-transform: capitalize;
`;
export const StyledNewBookingTimeComponentConfirmButton = styled(Button)`
  border-radius: 30px;
  border: 1px solid #597ef7;
  background: #f0f5ff;
  font-size: 1.6rem;
  font-weight: 600;
  color: #00171f;
`;
