import {Dispatch, PropsWithChildren, useReducer} from "react";
import {createContext} from "../../create-context";
import {
  CreateLiveClassFormActions,
  createLiveClassFormReducer,
  initialCreateLiveClassFormState,
} from "../../../reducers/live-class/create-live-class";

type CreateLiveClassFormContextState = {
  state: typeof initialCreateLiveClassFormState;
  dispatch: Dispatch<CreateLiveClassFormActions>;
};

const [useContext, ContextProvider] = createContext<CreateLiveClassFormContextState>();

export const useCreateLiveClassContext = useContext;

export const CreateLiveClassProvider = ({children}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    createLiveClassFormReducer,
    initialCreateLiveClassFormState
  );

  return <ContextProvider value={{state, dispatch}}>{children}</ContextProvider>;
};
