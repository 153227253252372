import moment from "moment";
import {useDispatch} from "react-redux";
import {
  loginSocialUser,
  registerSocialUser,
} from "../../redux/auth/login/user-login-slice";
import {AppDispatch} from "../../redux/store";
import jwtDecode from "jwt-decode";
import {useCallback, useEffect, useRef} from "react";

declare global {
  interface Window {
    handleCredentialResponse?: any;
    google: any;
  }
}

interface IGoogleButtonProps {
  type: "register" | "login";
}
type GoogleCredentialResponse = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

type DecodedGoogleCredentialUser = {
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  hd: string;
  iat: number;
  iss: string;
  jti: string;
  locale: string;
  name: string;
  nbf: number;
  picture: string;
  sub: string;
};

const GoogleButton: React.FC<IGoogleButtonProps> = ({type}) => {
  // window.handleCredentialResponse = handleCredentialResponse;
  const dispatch = useDispatch<AppDispatch>();

  const handleCredentialResponse = useCallback(
    (response: GoogleCredentialResponse) => {
      const token = response.credential;
      const user: DecodedGoogleCredentialUser = jwtDecode(token);
      if (type === "register") {
        const payload = {
          firstName: user.given_name.trim(),
          lastName: user.family_name.trim(),
          emailAddress: user.email.trim(),
          isSocialAuth: true,
          timeZone: moment.tz.guess(true),
        };
        dispatch(registerSocialUser(payload));
      } else {
        const payload = {
          emailAddress: user.email.toLowerCase(),
        };
        dispatch(loginSocialUser(payload));
      }
    },
    [dispatch, type]
  );

  const g_sso = useRef(null);

  useEffect(() => {
    if (g_sso.current) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        callback: (res: any, error: any) => {
          // This is the function that will be executed once the authentication with google is finished
          // console.log({res});
          handleCredentialResponse(res);
        },
      });
      window.google.accounts.id.renderButton(g_sso.current, {
        theme: "outline",
        size: "large",
        type: "standard",
        text: "continue_with",
        shape: "pill",
        logo_alignment: "left",
        width: "270",
      });
    }
  }, [handleCredentialResponse]);
  return <div ref={g_sso}></div>;
};
export {GoogleButton};
