import {Col, Row} from "antd";
import {CustomerUpcomingEvents} from "../../../../components/customer-dashboard-home";
import {CustomerDashboardWrapper} from "../../../../layouts/customer-dashboard-wrapper";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";

const CustomerDashboardHomepage = () => {
  return (
    <CustomerDashboardWrapper pageTitle="Customer upcoming events">
      <StyledPageHeader>Upcoming events 📅</StyledPageHeader>
      <div style={{marginTop: "1.5rem"}}>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            <CustomerUpcomingEvents />
          </Col>
          <Col span={24} lg={{span: 8}}></Col>
        </Row>
      </div>
      {/* <StyledPageWrapper>
        <div className="main-area"></div>
        <div className="widget-area"></div>
      </StyledPageWrapper> */}
    </CustomerDashboardWrapper>
  );
};

export {CustomerDashboardHomepage};
