import {Alert, Button, Table, message} from "antd";
import {FullPageLoader} from "../../../full-page-loader";
import {StyledDescriptionText} from "../../../messages/messages.style";
import type {ColumnsType} from "antd/es/table";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";
import {DownloadOutlined} from "@ant-design/icons";
import {
  StyledNoUpcomingCallBox,
  StyledUpcomingCallsWrapper,
} from "../../../calls/upcoming-calls/upcoming-calls.style";
import {
  useGetProductCustomers,
  useProductCustomerExportCSV,
} from "../../../../react-query-hooks/products";
import {useState} from "react";
import {CustomPagination} from "../../../pagination";

interface DataType {
  key: string;
  name: string;
  email: string;
  reference: string;
}

type TProductCustomerInsightsProps = {
  productId: number;
};

export function ProductCustomerInsights({productId}: TProductCustomerInsightsProps) {
  const [page, setPage] = useState(1);
  const getProductCustomersQuery = useGetProductCustomers(productId, page);
  const exportCsvQueryMutation = useProductCustomerExportCSV();

  if (getProductCustomersQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getProductCustomersQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getProductCustomersQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const customersData: DataType[] = getProductCustomersQuery.data.data.customers.map(
    (customer) => ({
      key: customer.id.toString(),
      name: `${customer.firstName} ${customer.lastName}`,
      email: customer.emailAddress.toLowerCase(),
      reference: customer.reference,
    })
  );

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {text}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "17.425rem",
      key: "email",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "17.425rem",
      key: "reference",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
  ];

  const handleExportAsCSV = () => {
    if (productId) {
      exportCsvQueryMutation.mutate(productId, {
        onSuccess: (data) => {
          message.success("File ready to be downloaded!");
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "product-customers.csv");
          document.body.appendChild(link);
          link.click();
        },
      });
    }
  };

  return (
    <>
      <div style={{marginTop: "7rem"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "4rem",
          }}
        >
          <StyledPageHeader>Customers 📇</StyledPageHeader>
          <div>
            <Button
              onClick={handleExportAsCSV}
              disabled={customersData.length < 1}
              block
              icon={<DownloadOutlined />}
              size="large"
              shape="round"
              type="primary"
              loading={exportCsvQueryMutation.isLoading}
            >
              Export CSV
            </Button>
          </div>
        </div>
        {customersData.length < 1 ? (
          <StyledUpcomingCallsWrapper style={{paddingTop: 0}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">Your customers will appear here</p>
            </StyledNoUpcomingCallBox>
          </StyledUpcomingCallsWrapper>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={customersData}
              loading={getProductCustomersQuery.isFetching}
              scroll={{x: 100}}
              pagination={false}
            />
            <CustomPagination
              isPreviousData={getProductCustomersQuery.isPreviousData}
              page={page}
              paginationMeta={getProductCustomersQuery.data.meta}
              setPage={setPage}
            />
          </>
        )}
      </div>
    </>
  );
}
