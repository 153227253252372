import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import {useClient} from "../utils/hooks";
import {message} from "antd";

export type TPayoutCountriesApiResponse = {
  countries: {
    name: string;
    code: string;
    currency: string;
  }[];
};

export type TGetPayoutBanksPayload = {
  countryCode: string;
};

export type TValidateBankAccountPayload = {
  accountNumber?: string;
  bankCode: string;
  bankName?: string;
  countryCode: string;
};

export type TValidateAccountNumberApiResponse = {
  name: string;
};
export type TConnectBankPayload = {
  accountNumber: string;
  bankCode: string;
  bankName: string;
  countryCode: string;
  routingNumber?: string;
};

export type TBank = {
  id: number;
  userId: number;
  country_name: string;
  country_code: string;
  currency: string;
  bank_name: string;
  bank_code: string;
  account_number: string;
  account_name: string;
  status_id: string;
  statusName: string;
  branch_code: string;
  provider: string;
  createdAt: string;
  updatedAt: string;
};

export type TConnectedBankApiResponse = {
  bank: TBank;
};

export type TConnectToStripePayload = {
  countryCode: string;
  countryName: string;
  currencyCode: string;
};

type FxTransaction = {
  amount: string;
  convertedServiceAmount: number;
  convertedServiceCharge: number;
  createdAt: string;
  customer_email: string;
  customer_name: string;
  destinationPaymentChannel: string;
  destination_currency: string;
  from_currency: string;
  gateway: string;
  id: number;
  initialPaymentChannel: string;
  offering: string;
  productType: string;
  reference: string;
  serviceAmount: number;
  serviceCharge: number;
  status: string;
  statusId: number;
  updatedAt: string;
  userId: number;
};

type GetPendingFxApiResponse = {
  has_pending_fx: boolean;
  transactions: FxTransaction[];
};

const useGetPayoutCountries = () => {
  const client = useClient();

  return useQuery<
    AxiosResponse<TPayoutCountriesApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["payout_countries"],
    queryFn: () => client("banks/countries").then((data) => data),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

const useGetPayoutBanks = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TGetPayoutBanksPayload) =>
      client(`banks/countries/${payload.countryCode}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
  });
};
const useGetBankBranches = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {bankCode: number}) =>
      client(`banks/branches/${payload.bankCode}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
  });
};
// const useValidateBankAccount = () => {
//   const client = useClient();
//   return useMutation({
//     mutationFn: (payload: TValidateBankAccountPayload) =>
//       client(`banks`, {
//         method: "POST",
//         data: payload,
//       }),
//     onError: (error: AxiosError<{message: string}>, values, rollback) => {
//       message.error(error.message);
//     },
//   });
// };

const useValidateBankAccount = (payload: TValidateBankAccountPayload) => {
  const client = useClient();

  return useQuery<
    TValidateAccountNumberApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["validate_account_number", payload.accountNumber],
    queryFn: () =>
      client(`banks`, {
        method: "POST",
        data: payload,
      }).then((data) => data.data),
    enabled: !!payload.accountNumber && !!payload.bankName,
    onError: (error) => {
      message.error(error.message);
    },
    refetchOnWindowFocus: false,
  });
};
const useConnectBank = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TConnectBankPayload) =>
      client(`banks/accounts`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["connected_bank"]}),
  });
};
const useGetConnectedBankAccount = () => {
  const client = useClient();

  return useQuery<
    TConnectedBankApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["connected_bank"],
    queryFn: () => client(`banks`).then((data) => data.data),
    onError: (error) => {
      if (
        error.message ===
        "Sorry, You do not have a bank account saved! Please, create one and try again!"
      ) {
        return;
      }
      message.error(error.message);
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
const useConnectToStripe = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TConnectToStripePayload) =>
      client(`banks/accounts/eu`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["connected_bank"]}),
  });
};

const useCompleteStripeRegistration = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (value: null) =>
      client(`banks/accounts/complete/eu`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
  });
};

const useLoginToStripe = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (value: null) =>
      client(`banks/accounts/login/eu`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["connected_bank"]}),
  });
};

const useGetPendingFx = () => {
  const client = useClient();

  return useQuery<
    GetPendingFxApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["pending_fx"],
    queryFn: () => client(`banks/pending-fx`).then((data) => data.data),
    onError: (error) => {
      message.error(error.message);
    },
    refetchOnWindowFocus: true,
    // staleTime: Infinity,
  });
};

export {
  useGetPayoutCountries,
  useGetPayoutBanks,
  useGetBankBranches,
  useValidateBankAccount,
  useConnectBank,
  useGetConnectedBankAccount,
  useConnectToStripe,
  useCompleteStripeRegistration,
  useLoginToStripe,
  useGetPendingFx,
};
