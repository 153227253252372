import styled from "styled-components";

export const StyledPublicProfileWrapper = styled.div`
  padding: 0 2rem 20.4rem 2rem;
  margin-top: -8rem;

  @media (max-width: 767px) {
    margin-top: -6rem;
  }
`;
export const StyledPublicProfileContainer = styled.div`
  max-width: 82.6rem;
  margin: 0 auto;
`;
export const StyledPublicProfileCountTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
export const StyledPublicProfileCountTag = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-color: #000;
  border-radius: 1.6rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`;
export const StyledPublicProfileLiveClassesDetailsHeading = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  margin-top: 4.3rem;

  @media (max-width: 425px) {
    font-size: 2rem;
    margin-top: 2rem;
    line-height: 1.3;
  }
`;
export const StyledPublicProfileLiveClassesDetailsImageWrapper = styled.div`
  // width: 100%;
  // height: 35rem;
  // border-radius: 1rem;
  // overflow: hidden;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;
  border-radius: 1rem;

  .banner-image {
    // width: 100%;
    // height: 35rem;
    // object-fit: cover;
    // // border-radius: 1rem;
    // // transition: all 1.5s ease;

    // &:hover {
    //   // object-fit: contain;
    //   // scale: -1.1;
    // }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
  .banner-image-mobile-wrapper {
    display: none;
  }

  // @media (max-width: 1024px) {
  //   height: auto;
  //   .banner-image-mobile-wrapper {
  //     display: block;
  //     .banner-image-mobile {
  //       width: 100%;
  //       height: auto;
  //       object-fit: contain;
  //       border-radius: 1rem;
  //     }
  //   }
  //   .banner-image-wrapper {
  //     display: none;
  //   }
  // }
`;
export const StyledNewPublicProfileContainer = styled.div`
  max-width: 120.2rem;
  margin: 0 auto;
  padding: 4.8rem 2.5rem 20.4rem 2.5rem;

  @media (max-width: 425px) {
    padding-top: 1rem;
    padding-bottom: 11.8rem;
  }
`;
