import {FacebookFilled, MailFilled, TwitterOutlined} from "@ant-design/icons";
import {Alert, Button, Space, Tooltip} from "antd";
import {useState} from "react";
import {TLiveClassApiResponse} from "../../../react-query-hooks/live-events/live-classes";
import {useGetProfile} from "../../../react-query-hooks/profile";
import {StyledShareServiceWidgetWrapper} from "../../calls/service/share-service-widget/share-service-widget.style";
import {ProfileWidgetSkeleton} from "../../calls/upcoming-calls/profile-widget/profile-widget-skeleton";
import {CopyIcon} from "../../icons/icons";
import {handleLiveClassLink} from "../../../utils/live-class";

const ShareClassWidget = ({
  classData,
  isFetching,
}: {
  isFetching: boolean;
  classData: TLiveClassApiResponse;
}) => {
  const [toolTipText, setToolTipText] = useState<string>("copy class url");

  const getProfileQuery = useGetProfile();
  if (getProfileQuery.isLoading) {
    return <ProfileWidgetSkeleton />;
  }

  if (getProfileQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getProfileQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  let stripedHtml = classData.description.replace(/<[^>]+>/g, "");
  const classDescription = stripedHtml;
  return (
    <StyledShareServiceWidgetWrapper>
      {isFetching ? (
        <ProfileWidgetSkeleton />
      ) : (
        <>
          <h2 className="profile-widget-heading">Share class link</h2>
          <div className="profile-widget-card">
            <Space size={16} direction="vertical" style={{display: "flex"}}>
              <div className="profile-widget-card-header">
                <h3 className="profile-widget-card-user-name">{classData.name}</h3>
                {/* <div>
                  <Button shape="circle" icon={<EditOutlined />} />
                </div> */}
              </div>
              <p className="profile-widget-card-bio">{classDescription}</p>

              <div>
                <Button
                  size="large"
                  block
                  shape="round"
                  htmlType="button"
                  target="_blank"
                  href={handleLiveClassLink({
                    eventId: classData.id,
                    slug: classData.slug,
                    username: getProfileQuery.data.username,
                  })}
                >
                  View class page
                </Button>
              </div>
              <p className="profile-widget-card-bio">Share class via</p>
              <div>
                <Space direction="horizontal" size={16}>
                  <div>
                    <Tooltip title={toolTipText}>
                      <Button
                        size="large"
                        shape="circle"
                        icon={<CopyIcon />}
                        style={{
                          backgroundColor: "#F4EEFC",
                          color: "#9254DE",
                          border: "none",
                        }}
                        onClick={() =>
                          navigator.clipboard
                            .writeText(
                              handleLiveClassLink({
                                eventId: classData.id,
                                slug: classData.slug,
                                username: getProfileQuery.data.username,
                                windowNav: true,
                              })
                            )
                            .then(() => setToolTipText("copied"))
                        }
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<FacebookFilled />}
                      style={{
                        backgroundColor: "#EBEEF5",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      target="_blank"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${getProfileQuery.data.username}/live-classes/details?slug=${classData.slug}&eventId=${classData.id}&amp`}
                      title="Share on facebook"
                    />
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<TwitterOutlined />}
                      target="_blank"
                      style={{
                        backgroundColor: "#E6F7FD",
                        color: "#00ACEE",
                        border: "none",
                      }}
                      href={`https://twitter.com/intent/tweet?text=I just created a live class called "${classData.name}" on Coachli. Book a live class with me today on Coachli!&url=${window.location.origin}/${getProfileQuery.data.username}/live-classes/details?slug=${classData.slug}%26eventId=${classData.id}&hashtags=coachli,useCoachli`}
                    />
                  </div>
                  <div>
                    <Button
                      shape="circle"
                      size="large"
                      icon={<MailFilled />}
                      style={{
                        backgroundColor: "#FBECEB",
                        color: "#DB4437",
                        border: "none",
                      }}
                      href={`mailto:?subject=I wanted you to see this site&amp;body=Book a live class with me on Coachli ${window.location.origin}/${getProfileQuery.data.username}/live-classes/details?slug=${classData.slug}&eventId=${classData.id}`}
                    />
                  </div>
                </Space>
              </div>
            </Space>
          </div>
        </>
      )}
    </StyledShareServiceWidgetWrapper>
  );
};

export {ShareClassWidget};
