import {Result} from "antd";
import {Navigate, useParams, useSearchParams} from "react-router-dom";

export function NavigateToNewLiveClass() {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const eventId = searchParams.get("eventId");

  if (!username || !slug || !eventId) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  return (
    <Navigate
      to={`/${username}/live-classes/details?slug=${slug}&eventId=${eventId}`}
      replace={true}
    />
  );
}
export function NavigateToNewServices() {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const serviceId = searchParams.get("serviceId");

  if (!username || !slug || !serviceId) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  return (
    <Navigate
      to={`/${username}/services/details?slug=${slug}&serviceId=${serviceId}`}
      replace={true}
    />
  );
}
export function NavigateToNewProducts() {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const productId = searchParams.get("productId");

  if (!username || !slug || !productId) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  return (
    <Navigate
      to={`/${username}/products/details?slug=${slug}&productId=${productId}`}
      replace={true}
    />
  );
}

export function NavigateToNewServices2() {
  const [searchParams] = useSearchParams();
  const currentUrlStep = searchParams.get("currentStep");
  const {username, slug, id} = useParams();

  if (!username || !slug || !id) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  return (
    <Navigate
      to={`/${username}/services/details?slug=${slug}&serviceId=${id}`}
      replace={true}
    />
  );
}
