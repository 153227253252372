import {Dispatch, PropsWithChildren, useReducer} from "react";
import {createContext} from "../create-context";
import {
  TCreateProductFormActions,
  createProductFormReducer,
  initialCreateProductFormState,
} from "../../reducers/products/create-product-reducer";

type TCreateProductFormContextState = {
  state: typeof initialCreateProductFormState;
  dispatch: Dispatch<TCreateProductFormActions>;
};

const [useContext, ContextProvider] = createContext<TCreateProductFormContextState>();

export const useCreateProductContext = useContext;

export const CreateProductProvider = ({children}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    createProductFormReducer,
    initialCreateProductFormState
  );

  return <ContextProvider value={{state, dispatch}}>{children}</ContextProvider>;
};
