import {CustomerBreadcrumb} from "../../../../components/customers/customer-breadcrumb/customer-breadcrumb";
import {InfoSection} from "../../../../components/customers/details-page/info-section";
import {PrevPurchasesSection} from "../../../../components/customers/details-page/prev-purchases-section/prev-purchases-section";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {StyledCustomersPageWrapper} from "../../../../styles/customers/customers";

const ViewCustomerDetailsPage = () => {
  return (
    <DashboardWrapper pageTitle="Customer Details">
      <CustomerBreadcrumb />
      <StyledCustomersPageWrapper>
        <>
          <InfoSection />
          <PrevPurchasesSection />
        </>
      </StyledCustomersPageWrapper>
    </DashboardWrapper>
  );
};

export {ViewCustomerDetailsPage};
