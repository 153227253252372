import {Alert, Button, Space, Tabs} from "antd";
import {useGetUpcomingCalls} from "../../../react-query-hooks/booking";
import {CallsSection} from "../../calls/upcoming-calls/calls-section";
import {useGetLiveClasses} from "../../../react-query-hooks/live-events/live-classes";
import {UpcomingClassesEvent} from "../upcoming-classes";
import {FullPageLoader} from "../../full-page-loader";
import {
  StyledNoUpcomingCallBox,
  StyledPageHeaderWrapper,
} from "../../calls/upcoming-calls/upcoming-calls.style";
import {CallCategoryModal} from "../../calls/upcoming-calls/call-category-modal";
import {useState} from "react";
import {AnalyticsEvent} from "../../../utils/analytics";
import {UpcomingClasses} from "../../live-classes/upcoming-classes";
import {CallOfferingModal} from "../call-offering-modal";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {Link, useNavigate} from "react-router-dom";
import {useGetCoachConnectCalendarStatusContext} from "../../../context/connect-calendar/connect-calendar-status.context";
import {useGetCoHostedEvents} from "../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {LiveClassCard} from "../../live-classes/upcoming-classes/card";

export type TCategoryModalProps = {
  openCallCategoryModal: boolean;
  setOpenCallCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TUpcomingEventsProps = TCategoryModalProps & {};

export const UpcomingEvents = ({
  openCallCategoryModal,
  setOpenCallCategoryModal,
}: TUpcomingEventsProps) => {
  const navigate = useNavigate();
  const [openOfferingCategoryModal, setOpenOfferingCategoryModal] =
    useState<boolean>(false);
  const getUpcomingCallsQuery = useGetUpcomingCalls();
  const getLiveClassesQuery = useGetLiveClasses();
  const getCoHostedEvent = useGetCoHostedEvents("upcoming");
  const {state: connectCalendarStatus} = useGetCoachConnectCalendarStatusContext();

  if (getCoHostedEvent.isLoading) {
    return <FullPageLoader />;
  }

  if (getCoHostedEvent.isError) {
    return (
      <Alert
        message="Error"
        description={getCoHostedEvent.error.message}
        type="error"
        showIcon
      />
    );
  }

  if (getLiveClassesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getLiveClassesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getLiveClassesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  if (getUpcomingCallsQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getUpcomingCallsQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getUpcomingCallsQuery.error.message}
        type="error"
        showIcon
      />
    );
  }
  const handleOk = () => {
    AnalyticsEvent("Service Option Modal", `Selected a service option`);
    setOpenCallCategoryModal(false);
  };
  const handleCancel = () => {
    AnalyticsEvent("Service Option Modal", `Closed service option modal`);
    setOpenCallCategoryModal(false);
  };
  const showCallCategoryModal = () => {
    setOpenCallCategoryModal(true);
  };
  const handleOfferingOk = () => {
    AnalyticsEvent("Service Option Modal", `Selected a service option`);
    setOpenOfferingCategoryModal(false);
    setOpenCallCategoryModal(true);
  };
  const handleOfferingCancel = () => {
    AnalyticsEvent("Service Option Modal", `Closed service option modal`);
    setOpenOfferingCategoryModal(false);
  };
  const showOfferingCategoryModal = () => {
    setOpenOfferingCategoryModal(true);
  };
  const flatMapLiveClassPages = getLiveClassesQuery.data.pages.flatMap(
    (page) => page.data
  );
  const flatMapCohostPages = getCoHostedEvent.data.pages.flatMap((page) => page.data);
  const flatMapUpcomingCallPages = getUpcomingCallsQuery.data.transactions;
  const liveClasses = [...flatMapCohostPages, ...flatMapLiveClassPages];
  const allEvents = [
    ...flatMapCohostPages,
    ...flatMapUpcomingCallPages,
    ...flatMapLiveClassPages,
  ];
  // console.log({
  //   cohost: getCoHostedEvent.data,
  //   liveClasses: getLiveClassesQuery.data,
  //   flatMapLiveClassPages,
  //   flatMapCohostPages,
  //   liveClassesGuy: liveClasses,
  //   flatMapUpcomingCallPages,
  // });
  return (
    <>
      <StyledPageHeaderWrapper>
        <StyledPageHeader>Upcoming events 📅</StyledPageHeader>
        {allEvents.length > 0 && (
          <div style={{display: "flex", justifyContent: "end"}}>
            <Button
              onClick={showOfferingCategoryModal}
              size="large"
              shape="round"
              htmlType="button"
              type="primary"
            >
              Create a new offering
            </Button>
          </div>
        )}
      </StyledPageHeaderWrapper>

      {/* {(getLiveClassesQuery.data.length > 0 ||
        getUpcomingCallsQuery.data.transactions.length > 0) && (
        <div style={{marginTop: "2rem", display: "flex", justifyContent: "end"}}>
          <Button
            onClick={showOfferingCategoryModal}
            size="large"
            shape="round"
            htmlType="button"
            type="primary"
          >
            Create a new offering
          </Button>
        </div>
      )} */}
      {connectCalendarStatus.connectCalendarStatus.needs_login && (
        <div style={{marginBottom: "2rem", maxWidth: "72rem"}}>
          <Alert
            message={
              <div style={{color: "#9747FF", fontSize: "1.4rem"}}>
                <Link
                  style={{fontWeight: 600, textDecoration: "underline"}}
                  to="/dashboard/settings?activeTab=calendar"
                >
                  Connect your calendar
                </Link>
                <span>
                  {" "}
                  to let us know when you are available and update your calendar as you
                  get bookings.
                </span>
              </div>
            }
            style={{background: "#F5EDFF", border: "none"}}
            // closable
            // afterClose={handleCloseConnectBankMessage}
          />
        </div>
      )}

      <div style={{marginTop: "2.4rem"}}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="All" key="1">
            {allEvents.length < 1 ? (
              <StyledNoUpcomingCallBox>
                <p className="info-text">
                  You do not have upcoming events at the moment.
                </p>
                <div>
                  <Button
                    onClick={showOfferingCategoryModal}
                    size="large"
                    block
                    shape="round"
                    type="primary"
                    htmlType="button"
                  >
                    Create a new offering
                  </Button>
                </div>
              </StyledNoUpcomingCallBox>
            ) : (
              <div>
                <div>
                  {getCoHostedEvent.data.pages.length > 0 && (
                    <Space direction="vertical" size={29} style={{display: "flex"}}>
                      {getCoHostedEvent.data.pages[0].data.map((liveClass) => (
                        <LiveClassCard
                          key={liveClass.id}
                          name={liveClass.name}
                          activeSession={liveClass.activeSession}
                          attendees={liveClass.attendees}
                          callPrice={liveClass.callPrice}
                          callPriceCurrency={liveClass.callPriceCurrency}
                          cover_image={liveClass.cover_image}
                          description={liveClass.description}
                          eventId={liveClass.id}
                          slug={liveClass.slug}
                          statusName={liveClass.statusName}
                          hostUsername={liveClass.hostProfile.username}
                          is_co_host
                        />
                      ))}
                    </Space>
                  )}
                </div>
                <div style={{marginTop: flatMapCohostPages.length > 0 ? 29 : 0}}>
                  <CallsSection />
                </div>
                {flatMapLiveClassPages.length > 0 && (
                  <div style={{marginTop: flatMapUpcomingCallPages.length > 0 ? 29 : 0}}>
                    <UpcomingClassesEvent events={flatMapLiveClassPages} />
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="1:1 Calls" key="2">
            {flatMapUpcomingCallPages.length > 0 ? (
              <CallsSection />
            ) : (
              <StyledNoUpcomingCallBox>
                <p className="info-text">
                  You do not have upcoming calls or services at the moment. Create a new
                  service to get started
                </p>
                <div>
                  <Button
                    onClick={showCallCategoryModal}
                    size="large"
                    block
                    shape="round"
                    type="primary"
                    htmlType="button"
                  >
                    Add new 1:1 service
                  </Button>
                </div>
              </StyledNoUpcomingCallBox>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Live classes" key="3">
            {liveClasses.length < 1 ? (
              <div style={{width: "100%"}}>
                <StyledNoUpcomingCallBox>
                  <p className="info-text">
                    You do not have any live class at the moment. Create a new live class
                    to get started
                  </p>
                  <div>
                    <Button
                      onClick={() => navigate("/dashboard/live-classes/new")}
                      size="large"
                      block
                      shape="round"
                      type="primary"
                      htmlType="button"
                    >
                      Create new live class
                    </Button>
                  </div>
                </StyledNoUpcomingCallBox>
              </div>
            ) : (
              <Space
                direction="vertical"
                size={flatMapCohostPages.length > 0 ? 29 : 0}
                style={{display: "flex"}}
              >
                <div>
                  <Space direction="vertical" size={29} style={{display: "flex"}}>
                    {getCoHostedEvent.data.pages[0].data.map((liveClass) => (
                      <LiveClassCard
                        key={liveClass.id}
                        name={liveClass.name}
                        activeSession={liveClass.activeSession}
                        attendees={liveClass.attendees}
                        callPrice={liveClass.callPrice}
                        callPriceCurrency={liveClass.callPriceCurrency}
                        cover_image={liveClass.cover_image}
                        description={liveClass.description}
                        eventId={liveClass.id}
                        slug={liveClass.slug}
                        statusName={liveClass.statusName}
                        hostUsername={liveClass.hostProfile.username}
                        is_co_host
                      />
                    ))}
                  </Space>
                </div>
                <UpcomingClassesEvent events={getLiveClassesQuery.data.pages[0].data} />
              </Space>
            )}
          </Tabs.TabPane>
        </Tabs>
        <CallCategoryModal
          okFunc={handleOk}
          cancelFunc={handleCancel}
          visibleState={openCallCategoryModal}
        />
        <CallOfferingModal
          okFunc={handleOfferingOk}
          cancelFunc={handleOfferingCancel}
          visibleState={openOfferingCategoryModal}
        />
      </div>
    </>
  );
};
