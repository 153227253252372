import {Col, Row} from "antd";
import {
  StyledAnalyticsSectionItem,
  StyledAnalyticsSectionWrapper,
} from "./analytics-section.style";
import {StyledContainer} from "../features-section/features-section.style";
import {Counter, AnimatedCounter} from "./counter";

function AnalyticsSection() {
  return (
    <StyledAnalyticsSectionWrapper>
      <StyledContainer>
        <Row gutter={[32, 16]}>
          <Col span={24} md={8}>
            <StyledAnalyticsSectionItem>
              <h2 className="main-text">
                <AnimatedCounter from={0} to={500} />+
              </h2>
              <p className="sub-text">Coaches, entrepreneurs and creators</p>
            </StyledAnalyticsSectionItem>
          </Col>
          <Col span={24} md={8}>
            <StyledAnalyticsSectionItem>
              <h2 className="main-text">
                <AnimatedCounter from={0} to={400} />+
              </h2>
              <p className="sub-text">Sessions and live events</p>
            </StyledAnalyticsSectionItem>
          </Col>
          <Col span={24} md={8}>
            <StyledAnalyticsSectionItem>
              <h2 className="main-text">
                <AnimatedCounter from={0} to={10} />+
              </h2>
              <p className="sub-text">Countries reached</p>
            </StyledAnalyticsSectionItem>
          </Col>
        </Row>
      </StyledContainer>
    </StyledAnalyticsSectionWrapper>
  );
}

export {AnalyticsSection};
