import {Button, Select, Space, Tag} from "antd";
import {StyledLiveClassesCallInfoWidget} from "../main-details-section.style";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  DownOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {useLiveClassBookingContext} from "../../../../../context/live-class-booking-context";
import momentTimezone from "moment-timezone";
import {useEffect, useState} from "react";
import {StyledInputLabelWrapper} from "../../../../input/input.style";
import {StyledCustomCurrencySelect} from "../../../../booking/left-column/left-column.style";
import {StyledMobileSelectCurrency} from "../../../offering/product-details/widget/widget-info.style";
import moment from "moment";

const {Option} = Select;

export function ClassInfoWidget() {
  const customerTimezone = momentTimezone.tz.guess(true);
  const {dispatch, state} = useLiveClassBookingContext();

  function handleCurrencyListOptions() {
    const defaultCurrencyObj = {
      label: `${
        state.eventData?.event.callPriceCurrency
      } ${state.eventData?.event.callPrice?.toLocaleString("en-US")}`,
      value: `default`,
    };
    const mappedCurrencies = state.eventData?.event.currencies.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString("en-US")}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies) {
      return [defaultCurrencyObj, ...mappedCurrencies];
    } else {
      return [];
    }
  }

  const [defaultCurrencyOption, setDefaultCurrencyOption] = useState<string | number>(
    () => handleCurrencyListOptions()[0].value
  );

  useEffect(() => {
    if (state.selectedCurrency) {
      setDefaultCurrencyOption(state.selectedCurrency.value);
    }
  }, [state.selectedCurrency]);

  if (!state.eventData) {
    return <div></div>;
  }

  const eventData = state.eventData;
  const classDate = moment(eventData.event.activeSession.startDate).format(
    "ddd, MMM DD YYYY"
  );

  function handleShowBookingModal() {
    dispatch({
      type: "show-booking-modal",
      payload: true,
    });
  }
  const handleCurrencyChange = (value: any) => {
    setDefaultCurrencyOption(value);
    const selectedValue = eventData.event.currencies.find((x) => x.id === Number(value));
    let selectedCurrency;

    if (value === "default") {
      selectedCurrency = {
        label: `${
          eventData.event.callPriceCurrency
        } ${eventData.event.callPrice.toLocaleString("en-US")}`,
        value: "default",
      };
    } else {
      selectedCurrency = {
        label: `${selectedValue?.currency} ${selectedValue?.amount.toLocaleString(
          "en-US"
        )}`,
        value: selectedValue?.id as number,
      };
    }
    dispatch({
      type: "update-selected-currency",
      payload: {
        selectedCurrency,
      },
    });
  };
  return (
    <div>
      <StyledLiveClassesCallInfoWidget>
        <div>
          <Space
            direction="vertical"
            size={17}
            style={{
              display: "flex",
            }}
          >
            <div className="call-content-item">
              <Space size={10}>
                <CalendarOutlined style={{color: "#7939CC"}} />
                <span>{classDate}</span>
              </Space>
            </div>
            <div className="call-content-item">
              <Space size={10}>
                <ClockCircleOutlined style={{color: "#7939CC"}} />
                <span>
                  {eventData.event.activeSession.startTime} -{" "}
                  {eventData.event.activeSession.endTime} ({customerTimezone})
                </span>
              </Space>
            </div>
            {handleCurrencyListOptions().length === 1 && (
              <div className="call-content-item">
                <Space size={10}>
                  <DollarOutlined style={{color: "#7939CC"}} />
                  <span>
                    {eventData.event.callPrice === 0
                      ? "Free"
                      : eventData.event.callPriceCurrency +
                        " " +
                        eventData.event.callPrice.toLocaleString("en-US")}
                  </span>
                </Space>
              </div>
            )}

            <div className="call-content-item">
              <Space size={10}>
                <TeamOutlined style={{color: "#7939CC"}} />
                <span>
                  {eventData.event.attendees === -1
                    ? "Unlimited"
                    : eventData.spotLeft === 0
                    ? "No spots left"
                    : `${eventData.spotLeft} spot${
                        eventData.spotLeft > 1 ? "s" : ""
                      } left`}{" "}
                </span>
              </Space>
            </div>
            {eventData.event.statusName === "Paused" && (
              <div className="reg-tag">
                <Tag>Registration closed</Tag>
              </div>
            )}
          </Space>
        </div>
        <div className="view-web">
          {handleCurrencyListOptions().length > 1 && (
            <div className="call-content-item" style={{marginTop: "1.6rem"}}>
              <StyledInputLabelWrapper style={{padding: 0}}>
                <label
                  style={{fontSize: "1.6rem"}}
                  id="currency_label"
                  htmlFor="currency"
                >
                  Select currency
                </label>
              </StyledInputLabelWrapper>
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <div>
                  <StyledCustomCurrencySelect
                    // labelInValue
                    bordered={false}
                    suffixIcon={
                      <DownOutlined
                        size={15}
                        style={{fontWeight: "bold", color: "#00171F"}}
                      />
                    }
                    onChange={handleCurrencyChange}
                    value={String(defaultCurrencyOption)}
                    size={"large"}
                    defaultValue={defaultCurrencyOption}
                  >
                    {handleCurrencyListOptions().map(
                      (option, optionIdx: number | string) => (
                        <Option key={optionIdx} value={option.value}>
                          {`${option.label}`}
                        </Option>
                      )
                    )}
                  </StyledCustomCurrencySelect>
                </div>
              </Space>
            </div>
          )}
          <div style={{marginTop: "1.7rem"}}>
            <Button
              onClick={() => {
                if (
                  eventData.event.statusName === "Active" ||
                  Math.abs(eventData.spotLeft) > 0
                ) {
                  handleShowBookingModal();
                } else {
                  return;
                }
              }}
              shape="round"
              type="primary"
              block
              size="large"
              disabled={
                eventData.event.statusName === "Paused" ||
                Math.abs(eventData.spotLeft) < 1
              }
            >
              Reserve your spot
            </Button>
          </div>
        </div>
      </StyledLiveClassesCallInfoWidget>
      <StyledMobileSelectCurrency>
        <div className="offering-content-item">
          <StyledInputLabelWrapper style={{padding: 0}}>
            <label
              style={{fontSize: "1.6rem", fontWeight: 600}}
              id="currency_label"
              htmlFor="currency"
            >
              {eventData.event.callPrice > 1 && handleCurrencyListOptions().length > 1
                ? "Select currency"
                : "Price"}
            </label>
          </StyledInputLabelWrapper>
          <Space size={10}>
            <DollarOutlined style={{color: "#7939CC"}} />
            {eventData.event.callPrice === 0 ? (
              <span className="booking-info-content-item-value">Free</span>
            ) : handleCurrencyListOptions().length > 1 ? (
              <div>
                <StyledCustomCurrencySelect
                  // labelInValue
                  bordered={false}
                  suffixIcon={
                    <DownOutlined
                      size={15}
                      style={{fontWeight: "bold", color: "#00171F"}}
                    />
                  }
                  onChange={handleCurrencyChange}
                  value={String(defaultCurrencyOption)}
                  size={"large"}
                  defaultValue={defaultCurrencyOption}
                  placement="topRight"
                >
                  {handleCurrencyListOptions().map(
                    (option, optionIdx: number | string) => (
                      <Option key={optionIdx} value={option.value}>
                        {`${option.label}`}
                      </Option>
                    )
                  )}
                </StyledCustomCurrencySelect>
              </div>
            ) : (
              <span className="booking-info-content-item-value">{`${
                eventData.event.callPriceCurrency
              } ${eventData.event.callPrice.toLocaleString("en-US")}`}</span>
            )}
          </Space>
        </div>
        <div style={{width: "100%", maxWidth: "18.2rem", marginLeft: "auto"}}>
          <Button
            onClick={handleShowBookingModal}
            shape="round"
            type="primary"
            block
            size="large"
            style={{height: "5.2rem"}}
          >
            Reserve a spot
          </Button>
        </div>
      </StyledMobileSelectCurrency>
    </div>
  );
}
