import styled from "styled-components";

export const StyledAnalyticsSectionWrapper = styled.div`
  margin-top: 42rem;

  @media (max-width: 767px) {
    margin-top: 20rem;
  }
`;
export const StyledAnalyticsSectionItem = styled.div`
  text-align: center;
  .main-text {
    font-weight: 700;
    font-size: 9.6rem;
    color: #0f0049;
  }

  .sub-text {
    color: #0f0049;
    font-weight: 500;
    font-size: 2.4rem;
  }

  @media (max-width: 500px) {
    .main-text {
      font-size: 5rem;
    }

    .sub-text {
      font-size: 2rem;
    }
  }
`;
