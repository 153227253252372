import * as yup from "yup";
import {Button, Col, DatePicker, Row, Space, message} from "antd";
import moment from "moment";
import {
  Controller,
  UseFormGetValues,
  UseFormReset,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import {CallDurationOptions} from "../../../../../container/onboarding/step-1/step-1-utils";
import {TIMEOPTIONS} from "../../../../../services/timezones";
import {CustomInputComponent} from "../../../../input/input-component";
import {StyledInputLabelWrapper} from "../../../../input/input.style";
import {CustomSelectInput} from "../../../../select";
import {yupResolver} from "@hookform/resolvers/yup";
import type {RangePickerProps} from "antd/es/date-picker";
import {useCoachScheduleClass} from "../../../../../react-query-hooks/live-events/live-classes";

const rescheduleSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  startDate: yup.string().trim().required("Start date is required"),
  startTime: yup.string().trim().required("Start time is required"),
  duration: yup.string().trim().required("Class duration is required"),
  customDuration: yup
    .number()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .typeError("Must be a number")
    .min(15, "Must be 15 minutes and above")
    .max(120, "Must be less than 120 minutes")
    .required("Custom duration is required"),
});

export function LiveClassTimeReschedule({
  duration,
  startDate,
  startTime,
  name,
  customDuration,
  scheduleId,
  eventId,
  msgValid,
  msgReset,
  msgGetValues,
  closeModal,
}: {
  name: string;
  startDate: string;
  startTime: string;
  duration: string | number;
  customDuration: number | string;
  scheduleId: number;
  eventId: string | number;
  msgValid: boolean;
  msgReset: UseFormReset<{
    message: string;
  }>;
  msgGetValues: UseFormGetValues<{
    message: string;
  }>;
  msgWatch: UseFormWatch<{
    message: string;
  }>;
  closeModal: () => void;
}) {
  const coachScheduleClassMutation = useCoachScheduleClass();
  const {
    formState: {errors, isDirty},
    watch,
    control,
    reset,
    handleSubmit,
  } = useForm<{
    name: string;
    startDate: string;
    startTime: string;
    duration: string | number;
    customDuration: number | string;
  }>({
    resolver: yupResolver(rescheduleSchema),
    mode: "onChange",
    defaultValues: {
      name: name,
      customDuration: customDuration,
      duration: duration,
      startDate: startDate,
      startTime: startTime,
    },
  });

  //   console.log({msgValid});

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  //   function handleReschedule() {
  //     const payload: {
  //       eventId: string | number;
  //       scheduleId: string | number;
  //       message: string;
  //       newDate: string;
  //       newTime: string;
  //       timeZone: string;
  //       duration: string | number;
  //     } = {
  //         duration:
  //     };
  //     if (isDirty) {
  //       coachScheduleClassMutation.mutate();
  //     }
  //   }
  const onSubmit = async (values: {
    name: string;
    startDate: string;
    startTime: string;
    duration: string | number;
    customDuration: number | string;
  }) => {
    const msgValue = msgGetValues("message");
    const payload: {
      eventId: string | number;
      scheduleId: string | number;
      message: string;
      newDate: string;
      newTime: string;
      // timeZone: string;
      duration: string | number;
    } = {
      duration:
        values.duration === "custom"
          ? Number(values.customDuration)
          : Number(values.duration),
      eventId: eventId,
      message: msgValue,
      newDate: values.startDate,
      newTime: values.startTime,
      scheduleId: scheduleId,
      // timeZone: moment.tz.guess(true),
    };
    // console.log({payload});
    if (isDirty) {
      coachScheduleClassMutation.mutate(payload, {
        onSuccess: () => {
          message.success("Schedule updated successfully!");
          msgReset();
          closeModal();
        },
      });
    } else {
      return message.info("Pick a new date and time");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space size={24} direction="vertical" style={{display: "flex"}}>
          <p style={{fontWeight: 600, fontSize: "1.4rem"}}>{name}</p>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              <StyledInputLabelWrapper>
                <label id="class-date-id" htmlFor="startDate">
                  Class date
                </label>
              </StyledInputLabelWrapper>
              <Controller
                control={control}
                name="startDate"
                render={({field: {onChange, value}}) => (
                  <DatePicker
                    disabledDate={disabledDate}
                    size="large"
                    style={{width: "100%"}}
                    onChange={(date, dateString) => {
                      onChange(dateString);
                    }}
                    status={errors.startDate?.message ? "error" : undefined}
                    value={!value ? undefined : moment(value)}
                  />
                )}
              />
              {errors.startDate?.message && (
                <div role="alert" className="ant-form-item-explain-error">
                  {errors.startDate.message}
                </div>
              )}
            </Col>
            <Col span={24} lg={12}>
              <CustomSelectInput
                control={control}
                id={`classStartTime`}
                label="Class time"
                name="startTime"
                showSearch
                options={TIMEOPTIONS}
                placeholder="9:00 AM"
                style={{width: "100%"}}
                error={errors.startTime?.message}
              />
            </Col>
            <Col span={24} lg={12}>
              <CustomSelectInput
                control={control}
                id="classDuration"
                label="Class duration"
                name="duration"
                placeholder="Please select"
                options={CallDurationOptions}
                error={errors.duration?.message}
                style={{width: "100%"}}
              />
            </Col>
            {watch("duration") === "custom" && (
              <Col span={24} sm={12}>
                <CustomInputComponent
                  id="customDuration"
                  name="customDuration"
                  type="number"
                  label="Class duration (In minutes)"
                  placeholder="Class duration (In minutes)"
                  error={errors.customDuration?.message}
                  control={control}
                />
              </Col>
            )}
          </Row>
          <div>
            <Row gutter={[24, 24]}>
              <Col span={24} md={8}>
                <Button
                  disabled={
                    Object.keys(errors).length > 0 ||
                    Object.values(watch()).length < 1 ||
                    isDirty === false
                  }
                  loading={coachScheduleClassMutation.isLoading}
                  size="large"
                  shape="round"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Reschedule
                </Button>
              </Col>
              <Col span={24} md={8}>
                <Button
                  onClick={() => reset()}
                  size="large"
                  shape="round"
                  type="default"
                  block
                >
                  Discard changes
                </Button>
              </Col>
            </Row>
          </div>
        </Space>
      </form>
    </div>
  );
}
