import styled from "styled-components";

export const StyledLiveClassInfoWidget = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1.6rem 1.8rem;

  &.view-web {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }

  .offering-content-item {
    span {
      font-weight: 600;
      font-size: 1.4rem;
      color: #00171f;
    }
  }
`;
export const StyledMobileSelectCurrency = styled.div`
  display: block;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  grid-template-columns: 12rem auto;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  /* display: flex; */
  gap: 2.4rem;
  align-items: center;
  /* justify-content: space-between; */

  .offering-content-item {
    .booking-info-content-item-value {
      font-weight: 600;
      font-size: 1.4rem;
      color: #00171f;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
