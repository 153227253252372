import styled from "styled-components";

export const StyledInputValue = styled.div`
  padding: 6.5px 11px 6.5px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  font-size: 1.6rem;
  color: grey;
  cursor: not-allowed;
`;
