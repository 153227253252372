import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {Button, Col, Input, message, Row, Select, Space} from "antd";
import {IRegisterFormValues} from "./i-register";
import {registerSchema} from "./register-form-validation-schema";
import {CustomInputComponent} from "../../../components/input/input-component";
import {CustomSelectInput} from "../../../components/select";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {AppDispatch, RootState} from "../../../redux/store";
import {
  registerStandardUser,
  reset,
} from "../../../redux/auth/register/user-register-slice";
import {useEffect, useLayoutEffect} from "react";
import moment from "moment";
import {COUNTRY_CODES_WITH_FLAG} from "../../../services/country-code";
import {StyledInputLabelWrapper} from "../../../components/input/input.style";

const RegisterForm = ({countries}: {countries: {name: string; code: string}[]}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    user,
    isLoading,
    isSuccess,
    isError,
    message: userRegisterMessage,
  } = useSelector((state: RootState) => state.userRegister);
  const {
    userInfo,
    isSuccess: isSuccessUserLogin,
    isError: isErrorUserLogin,
    message: userLoginMessage,
  } = useSelector((state: RootState) => state.userLogin);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<IRegisterFormValues>({
    resolver: yupResolver(registerSchema),
    mode: "onChange",
  });

  // const handleClose = () => {
  //   navigate("/email-verification");
  // };

  useLayoutEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (isError || isErrorUserLogin) {
      message.error(userRegisterMessage || userLoginMessage);
    }
    if (isSuccess) {
      message.success("Your account has been created successfully!", 1, () => {
        navigate("/auth/email-verification");
      });
      // navigate("/confirmation");
    }
    if (isSuccessUserLogin) {
      message.success(`Welcome ${userInfo.user.firstName}!`);
      navigate("/dashboard/onboarding");
    }
  }, [
    isError,
    isErrorUserLogin,
    isSuccess,
    isSuccessUserLogin,
    userRegisterMessage,
    userLoginMessage,
    userInfo,
    navigate,
    dispatch,
  ]);

  const onSubmit = async (values: IRegisterFormValues) => {
    const payload = {
      ...values,
      emailAddress: values.emailAddress.toLowerCase(),
      timeZone: moment.tz.guess(true),
    };
    // console.log(payload);
    dispatch(registerStandardUser(payload));
  };
  function handleCountriesWithFlag() {
    const cc: {
      name: string;
      code: string;
      flag: string;
    }[] = [];
    countries.forEach((country) => {
      COUNTRY_CODES_WITH_FLAG.forEach((countryFlag) => {
        if (countryFlag.isoCode === country.code) {
          cc.push({
            name: country.name,
            code: country.code,
            flag: countryFlag.flag,
          });
        }
      });
    });
    return cc;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space direction="vertical" size={29} style={{display: "flex"}}>
        <Row gutter={[16, 29]}>
          <Col span={24} md={12}>
            {" "}
            <CustomInputComponent
              id="firstName"
              label="First name"
              placeholder="Enter your first name"
              error={errors.firstName?.message}
              control={control}
              name="firstName"
            />
          </Col>
          <Col span={24} md={12}>
            <CustomInputComponent
              id="lastName"
              name="lastName"
              label="Last name"
              placeholder="Enter your last name"
              error={errors.lastName?.message}
              control={control}
            />
          </Col>
        </Row>
        <CustomInputComponent
          id="email"
          name="emailAddress"
          label="Email address"
          type="email"
          placeholder="Please enter your email address"
          error={errors.emailAddress?.message}
          control={control}
        />
        <div style={{width: "100%"}}>
          <StyledInputLabelWrapper>
            <label id="country" htmlFor="country">
              Country
            </label>
          </StyledInputLabelWrapper>
          <Controller
            control={control}
            name="country"
            render={({field}) => (
              <Select
                {...field}
                // defaultValue={COUNTRY_CODES[0].dial_code}
                status={errors.country?.message ? "error" : ""}
                size="large"
                style={{width: "100%"}}
                optionFilterProp="children"
                placeholder="Select country"
                showSearch
                filterOption={(input, option) => {
                  return option.value.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {handleCountriesWithFlag().map((code) => (
                  <Select.Option key={code.code} value={code.name}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <img src={code.flag} width={30} height={20} alt={code.name} />
                      <span>{code.name}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
          />
          {errors.country?.message && (
            <div role="alert" className="ant-form-item-explain-error">
              {errors.country?.message}
            </div>
          )}
        </div>

        <CustomInputComponent
          id="password"
          name="password"
          label="Password"
          type="password"
          placeholder="•••••••••••••••"
          error={errors.password?.message}
          control={control}
        />
        <div>
          <Button
            disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
            loading={isLoading}
            block
            size="large"
            shape="round"
            type="primary"
            htmlType="submit"
          >
            Create account
          </Button>
        </div>
      </Space>
    </form>
  );
};

export {RegisterForm};
