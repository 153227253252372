import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button, message, Space} from "antd";
import {CustomInputComponent} from "../../../components/input/input-component";
import {AuthFormWrapper} from "../../../layouts/auth-form-wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {AppDispatch, RootState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {
  resetPassword,
  resetUserResetPasswordState,
} from "../../../redux/auth/forgot-password/forgot-password-slice";
import {useEffect} from "react";

const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("Please input your Password!")
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters"),
  confirmPassword: yup
    .string()
    .trim()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const CreateNewPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {email, link} = useParams();

  const {
    userInfo,
    isLoading,
    isSuccess,
    isError,
    message: userResetPasswordMessage,
  } = useSelector((state: RootState) => state.userResetPassword);

  // useEffect(() => {
  //   dispatch(resetUserResetPasswordState());
  // }, [dispatch]);

  useEffect(() => {
    if (isError) {
      message.error(userResetPasswordMessage);
    }

    if (isSuccess) {
      // dispatch(updateLoginState(userInfo));
      message.success("Reset successful", 1, () => navigate("/auth/login"));
    }
    dispatch(resetUserResetPasswordState());
  }, [isError, isSuccess, userResetPasswordMessage, dispatch, navigate, userInfo]);

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<{password: string; confirmPassword: string}>({
    resolver: yupResolver(newPasswordSchema),
    mode: "onChange",
  });

  const onSubmit = (values: {password: string; confirmPassword: string}) => {
    if (link) {
      dispatch(resetPassword({...values, link}));
    }
  };
  return (
    <AuthFormWrapper>
      <div>
        <h2 className="form-main-heading">Set a new password</h2>
        <p className="form-sub-heading">Please enter a new password</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={29} style={{display: "flex"}}>
          <CustomInputComponent
            id="password"
            name="password"
            label="Password"
            type="password"
            placeholder="•••••••••••••••"
            error={errors.password?.message}
            control={control}
          />
          <CustomInputComponent
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm password"
            type="password"
            placeholder="•••••••••••••••"
            error={errors.confirmPassword?.message}
            control={control}
          />
          <div>
            <Button
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              block
              loading={isLoading}
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Reset password
            </Button>
          </div>
          <div>
            <Button
              icon={<ArrowLeftOutlined />}
              type="link"
              block
              onClick={() => navigate("/auth/login")}
            >
              Back to log in
            </Button>
          </div>
        </Space>
      </form>
    </AuthFormWrapper>
  );
};

export default CreateNewPassword;
