import {AxiosError} from "axios";
import {useClient} from "../../../utils/hooks";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {AnalyticsEvent} from "../../../utils/analytics";
import {message} from "antd";
import {
  PublicProfileLiveClassApiResponseEventCohost,
  TLiveClassApiResponse,
} from "../live-classes";
import {PaginationMeta} from "../../../redux/service/i-service";

export type ValidateCohostApiResponse = {
  profile_exists: boolean;
  profile_id: number;
};
export type GetCoHostsApiResponse = {
  coHosts: {
    // cohostExist: string;
    id: number;
    user_id: number;
    host_id: number | null;
    event_id: number;
    createdAt: string;
    updatedAt: string;
    first_name: string;
    last_name: string;
    email_address: string;
    status_id: number;
    status_name: string;
    host: {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      onboarding_reminder: number;
      country: null;
      password: null;
      statusId: number;
      isSocialAuth: boolean;
      avatar: string | null;
      cover_image: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  }[];
};

export type AddCoHostApiResponse = {
  coHost: {
    user_id: number;
    host_id: string;
    event_id: string;
    id: number;
    createdAt: string;
    updatedAt: string;
  };
};
export type GetCoHostedEvents = TLiveClassApiResponse & {
  hostProfile: PublicProfileLiveClassApiResponseEventCohost;
};
export const useAddCoHost = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (cohostData: {
      host_id: number | string | null;
      event_id: string;
      email_address: string;
      first_name: string;
      last_name: string;
    }) =>
      client(`co-host`, {
        method: "POST",
        data: cohostData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Co hosts", "Co host create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Co hosts", "Co host create success");
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["co_hosts", String(values.event_id)]}),
        // queryClient.invalidateQueries({
        //   queryKey: ["live_classes", String(values.event_id)],
        // }),
      ]),
  });
};
export const useValidateCohost = (cohostEmail: string, triggerFetch: boolean) => {
  const client = useClient();

  return useQuery<
    ValidateCohostApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["validate_co_host", cohostEmail],
    queryFn: () => client(`co-host/${cohostEmail}`).then((data) => data.data),
    refetchOnWindowFocus: false,
    enabled: !!cohostEmail && triggerFetch,
    staleTime: 5 * 60 * 1000,
  });
};
export const useFetchCoHosts = (event_id?: string) => {
  const client = useClient();

  return useQuery<GetCoHostsApiResponse, AxiosError<{status: boolean; message: string}>>({
    queryKey: ["co_hosts", event_id],
    queryFn: () => client(`co-hosts/${event_id}`).then((data) => data.data),
    refetchOnWindowFocus: false,
    enabled: !!event_id,
  });
};
export const useGetCoHostedEvents = (
  filter: "upcoming" | "past",
  per_page: number = 5
) => {
  const client = useClient();

  return useInfiniteQuery<
    {data: GetCoHostedEvents[]; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["co_hosted_live_classes"],
    queryFn: ({pageParam = 1}) =>
      client(
        `co-hosts/live-events/all/${filter}?page=${pageParam}&per_page=${per_page}`
      ).then((data) => data),
    refetchInterval: 300000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta) {
        return lastPage.meta.next_page === 0 ? undefined : lastPage.meta.next_page;
      } else {
        return undefined;
      }
    },
  });
};

export const useDeleteCoHost = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {event_id: string; model_id: string}) =>
      client(`co-host/${payload.model_id}/${payload.event_id}`, {
        method: "DELETE",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("CoHost", "CoHost delete fail");
    },
    onSuccess: (data) => {
      AnalyticsEvent("CoHost", "CoHost delete success");
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({
        queryKey: ["co_hosts", String(values.event_id)],
      }),
  });
};

export const useResendCoHostInvite = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (cohostId: string) =>
      client(`co-host/resend/${cohostId}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("CoHost", "CoHost resend invite fail");
    },
    onSuccess: () => {
      AnalyticsEvent("CoHost", "CoHost resend invite success");
    },
  });
};
