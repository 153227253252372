import {TBookingFormValues} from "../container/live-classes/booking/new/step-1";
import {
  TLiveClassBookingApiResponse,
  TPublicProfileLiveClassApiResponse,
} from "../react-query-hooks/live-events/live-classes";

type TUpdateSelectedCurrency = {
  type: "update-selected-currency";
  payload: {
    selectedCurrency: {
      label: string;
      value: number | string;
    };
  };
};
type TShowBookingModal = {
  type: "show-booking-modal";
  payload: boolean;
};
type TUpdateBookingStep = {
  type: "update-booking-step";
  payload: number;
};
type TUpdateLiveClassFormValues = {
  type: "update-live-class-form-values";
  payload: TBookingFormValues;
};
type TUpdateLiveClassBookingApiResponse = {
  type: "update-live-class-booking-api-response";
  payload: TLiveClassBookingApiResponse;
};

type TResetState = {
  type: "reset-state";
};

export type TLiveClassActions =
  | TUpdateSelectedCurrency
  | TShowBookingModal
  | TUpdateBookingStep
  | TUpdateLiveClassFormValues
  | TUpdateLiveClassBookingApiResponse
  | TResetState;

export type TLiveClassState = {
  selectedCurrency?: {
    label: string;
    value: number | string;
  };
  isBookingModalOpen: boolean;
  urlParams: {
    username: string | undefined;
    slug: string | null;
    eventId: string | null;
    customerTimezone: string | null;
    currentUrlStep: string | null;
    bookingRef: string | null;
  };
  currentBookingStep: number;
  eventData?: TPublicProfileLiveClassApiResponse;
  liveClassFormValues?: TBookingFormValues;
  liveClassBookingApiResponseData?: TLiveClassBookingApiResponse;
};

export const initialLiveClassState: TLiveClassState = {
  selectedCurrency: undefined,
  isBookingModalOpen: false,
  urlParams: {
    customerTimezone: null,
    eventId: null,
    slug: null,
    username: undefined,
    bookingRef: null,
    currentUrlStep: null,
  },
  currentBookingStep: 1,
  eventData: undefined,
  liveClassFormValues: undefined,
  liveClassBookingApiResponseData: undefined,
};

export const liveClassBookingReducer = (
  state: TLiveClassState = initialLiveClassState,
  action: TLiveClassActions
) => {
  if (action.type === "update-selected-currency") {
    const {selectedCurrency} = action.payload;
    return {...state, selectedCurrency};
  }
  if (action.type === "show-booking-modal") {
    return {...state, isBookingModalOpen: action.payload};
  }
  if (action.type === "update-booking-step") {
    if (action.payload > 4) {
      return state;
    }
    return {...state, currentBookingStep: action.payload};
  }
  if (action.type === "update-live-class-form-values") {
    return {...state, liveClassFormValues: action.payload};
  }
  if (action.type === "update-live-class-booking-api-response") {
    return {...state, liveClassBookingApiResponseData: action.payload};
  }
  if (action.type === "reset-state") {
    return {...initialLiveClassState};
  }

  return state;
};
