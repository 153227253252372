import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useClient} from "../../utils/hooks";

export type TRecording = {
  id: number;
  userId: number;
  eventId: number;
  duration: string;
  bookingType: string;
  serviceName: string;
  recording: string;
  createdAt: Date;
  updatedAt: Date;
};
export type TGetRecordingsApiResponse = {
  recordings: TRecording[];
};
const useGetRecordings = () => {
  const client = useClient();

  return useQuery<
    TGetRecordingsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["recordings"],
    queryFn: () => client(`recordings`).then((data) => data.data),
  });
};

export {useGetRecordings};
