import styled from "styled-components";

export const StyledInfoItem = styled.div`
  .info-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: #7939cc;
  }

  .info-value {
    font-weight: 400;
    font-size: 1.6rem;

    &.info-desc {
      display: -webkit-box;
      overflow: hidden;
      /* display 4 lines only */
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;
export const StyledDescriptionTextValue = styled.p`
  display: -webkit-box;
  overflow: hidden;
  /* display 4 lines only */
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;
