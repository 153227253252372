import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {TBooking} from "../redux/call/i-call";
import {TProfileApiResponse} from "../redux/profile/i-profile-1";
import {PaginationMeta, TServiceApiResponse} from "../redux/service/i-service";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";
import {TPastcallsApiResponse} from "./booking";

type TCustomerMetricsApiResponse = {
  totalUniqueCustomers: number;
  totalRevenue: {currency: string; amount: number}[];
  totalCalls: number;
  currency: string;
};

type TCustomerApiResponse = {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  numberOfPurchases: number;
  totalVolume: number;
};

type TCustomerUpcomingcallsApiResponse = {
  session: TBooking[];
};
export type TCustomerPaidBundleApiResponse = {
  bookedSessions: number;
  coach: {
    avatar: string | null;
    country: string;
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    isSocialAuth: boolean;
    lastName: string;
    statusId: number;
    updatedAt: string;
  };
  coach_username: string;
  createdAt: string;
  customerEmail: string;
  id: number;
  noOfInterval: string;
  noOfSessions: number;
  phoneNumber: string;
  price: number;
  reference: string;
  service: Omit<TServiceApiResponse, "statusName">;
  serviceId: number;
  statusId: number;
  timeZone: string;
  currency: string;
  updatedAt: string;
  userId: number;
};
type TCustomerPaidBundlesApiResponse = {
  bundles: TCustomerPaidBundleApiResponse[];
};

export type TCustomerBundleBookingApiResponse = {
  booking: TBooking;
  service: TServiceApiResponse;
  profile: TProfileApiResponse;
  mentor: {
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    password: null;
    statusId: number;
    isSocialAuth: boolean;
    avatar: string | null;
    createdAt: string;
    updatedAt: string;
  };
  bookingTimeSlot: string;
  days: {
    sun: {from: string; to: string}[];
    mon: {from: string; to: string}[];
    tue: {from: string; to: string}[];
    wed: {from: string; to: string}[];
    thu: {from: string; to: string}[];
    fri: {from: string; to: string}[];
    sat: {from: string; to: string}[];
  };

  timeZone: string | null;
  unavailableDates: {
    from: string;
    to: string;
  }[];
};

type GetCustomersApiResponse = {
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  reference: string;
  statusName: string;
}[];
type GetCustomersPrevPurchasesApiResponse = {
  service: {
    name: string;
    callPriceCurrency: string;
  };
  date: string;
  amount: 0;
  currency: string;
  reference: string;
}[];

const useGetCustomers = () => {
  const client = useClient();

  return useQuery<
    GetCustomersApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customers"],
    queryFn: () => client("customer/booking").then((data) => data.data.transactions),
  });
};
const useGetCustomerMetrics = () => {
  const client = useClient();

  return useQuery<
    TCustomerMetricsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_metrics"],
    queryFn: () => client("customer/metrics").then((data) => data.data),
  });
};

const useGetCustomer = (customerId?: string) => {
  const client = useClient();

  return useQuery<TCustomerApiResponse, AxiosError<{status: boolean; message: string}>>({
    queryKey: ["customer", customerId],
    queryFn: () =>
      client(`customer/booking/${customerId}`).then((data) => data.data.booking),
    enabled: !!customerId,
  });
};
const useGetCustomerPrevPurchases = (mentorId: string, customerEmail?: string) => {
  const client = useClient();

  return useQuery<
    GetCustomersPrevPurchasesApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_prev_purchases", customerEmail],
    queryFn: () =>
      client(
        `customer/booking-purchases?emailAddress=${customerEmail}&currentId=${mentorId}`
      ).then((data) => data.data.transactions),
    enabled: !!mentorId && !!customerEmail,
  });
};

const useExportCSV = () => {
  const client = useClient();
  return useMutation({
    mutationFn: () =>
      client(`customer/booking-csv`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach customer", "Coach customer export CSV fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach customer", "Coach customer export CSV success");
    },
  });
};

const useGetCustomerUpcomingCalls = (page: number = 1, per_page: number = 5) => {
  const client = useClient();

  return useQuery<
    {data: TCustomerUpcomingcallsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_upcoming_calls", page],
    queryFn: () =>
      client(`customer/upcoming-calls?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    // refetchInterval: 300000,
    keepPreviousData: true,
  });
};

const useGetCustomerPastCalls = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TPastcallsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_past_calls", page],
    queryFn: () =>
      client(`customer/past-calls?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    keepPreviousData: true,
  });
};

const useGetCustomerPaidBundles = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TCustomerPaidBundlesApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_paid_bundles", page],
    queryFn: () =>
      client(`customer/bundles?page=${page}&per_page=${per_page}`).then((data) => data),
    // refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

const useCustomerCancelBooking = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {bookingRef: string; description: string}) =>
      client(`customer/booking-cancel/${payload.bookingRef}`, {
        method: "POST",
        data: {description: payload.description},
      }),
    onMutate: () => {
      const oldBookings = queryClient.getQueryData(["customer_upcoming_calls"]);

      return () => queryClient.setQueryData(["customer_upcoming_calls"], oldBookings);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Customer cancel booking", "Customer cancel booking fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      message.success("The selected booking has been cancelled successfully!");
      AnalyticsEvent("Customer cancel booking", "Customer cancel booking success");
    },
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["customer_upcoming_calls"]}),
        queryClient.invalidateQueries({queryKey: ["customer_paid_bundles"]}),
      ]),
  });
};

const useGetCustomerBundleBooking = (bookingRef: string) => {
  const client = useClient();

  return useQuery<
    TCustomerBundleBookingApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_bundle_booking", bookingRef],
    queryFn: () =>
      client(`customer/booking-reference/${bookingRef}`).then((data) => data.data),
    enabled: !!bookingRef,
  });
};

export {
  useGetCustomers,
  useGetCustomerMetrics,
  useGetCustomer,
  useGetCustomerPrevPurchases,
  useExportCSV,
  useGetCustomerUpcomingCalls,
  useGetCustomerPastCalls,
  useGetCustomerPaidBundles,
  useCustomerCancelBooking,
  useGetCustomerBundleBooking,
};
