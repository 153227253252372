import {Alert, Spin} from "antd";
import {EditProfileForm} from "./edit-profile-form";
import {StyledFormWrapper} from "../../../styles/onboarding/onboarding";
import {useGetProfile} from "../../../react-query-hooks/profile";
import {FullPageLoader} from "../../full-page-loader";
import {LoadingSpinner} from "../../icons/icons";
import {useGetCountries} from "../../../react-query-hooks/general";

const EditProfileFormComponent = () => {
  const getProfileQuery = useGetProfile();
  const getCountriesQuery = useGetCountries();

  if (getProfileQuery.isLoading || getCountriesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getProfileQuery.isError || getCountriesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getProfileQuery.error?.message || getCountriesQuery.error?.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <StyledFormWrapper style={{marginTop: 0, marginBottom: "3rem"}}>
      <Spin
        spinning={getProfileQuery.isFetching}
        tip="Updating..."
        indicator={LoadingSpinner}
      >
        <EditProfileForm
          countries={getCountriesQuery.data}
          profileData={getProfileQuery.data}
        />
      </Spin>
    </StyledFormWrapper>
  );
};

export {EditProfileFormComponent};
