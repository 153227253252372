import {MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu} from "antd";
import {useNavigate} from "react-router-dom";

const LiveClassCardMenu = ({
  eventId,
  showDrawer,
}: {
  eventId: number;
  showDrawer?: (eventId: string | number) => void;
}) => {
  const navigate = useNavigate();
  const menu = (
    <Menu
      items={[
        {
          label: (
            <div
              onClick={() =>
                navigate(
                  `/dashboard/live-classes/edit/${eventId}?formType=edit&currentTab=2`
                )
              }
            >
              View attendees
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div
              onClick={() => {
                if (showDrawer) {
                  showDrawer(eventId);
                } else {
                  return;
                }
              }}
            >
              Reschedule class
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Button
          shape="circle"
          size="middle"
          icon={<MoreOutlined />}
          style={{
            backgroundColor: "transparent",
            color: "#00171F",
            border: "none",
            boxShadow: "none",
          }}
        />
      </Dropdown>
    </>
  );
};

export {LiveClassCardMenu};
