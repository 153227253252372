import styled from "styled-components";

export const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.7rem;

  .username-text {
    font-weight: 600;
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
`;
