import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IUnAvailabilityFormValues} from "./i-unavailability-form";
import {
  createUnavailabilityService,
  getUserUnavailabilityService,
} from "./unavailability-service";

interface IInitialState {
  // unavailabilities: {
  //   id: number;
  //   userId: number;
  //   from: string;
  //   to: string;
  //   createdAt: Date;
  //   updatedAt: Date;
  // }[];
  unavailabilities: null;
  isError: boolean;
  isSuccess: boolean;
  isSuccessCreate: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  unavailabilities: null,
  isError: false,
  isSuccess: false,
  isSuccessCreate: false,
  isLoading: false,
  message: "",
};

//create unavailability
export const createUnavailability = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IUnAvailabilityFormValues,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("unavailability/createUnavailability", async (selections, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await createUnavailabilityService(selections, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//get user unavailabilities
export const getUnavailabilities = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("unavailability/getUnavailabilities", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getUserUnavailabilityService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const unavailabilitySlice = createSlice({
  name: "unavailability",
  initialState,
  reducers: {
    resetUserUnavailabilityState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.message = "";
      state.unavailabilities = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUnavailability.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUnavailability.fulfilled, (state, _) => {
        state.isLoading = false;
        state.isSuccessCreate = true;
      })
      .addCase(createUnavailability.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUnavailabilities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnavailabilities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unavailabilities = action.payload.data;
      })
      .addCase(getUnavailabilities.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {resetUserUnavailabilityState} = unavailabilitySlice.actions;

export default unavailabilitySlice.reducer;
