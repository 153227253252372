import styled from "styled-components";

export const StyledPageHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPageHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  margin-left: 2rem;

  @media only screen and (max-width: 460px) {
    font-size: 2.4rem;
  }
`;

export const StyledPageWrapper = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
  border: 1px solid #f0f0f0;
  display: flex;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`;

export const StyledMessagesListWrap = styled.div`
  border: 1px solid #f0f0f0;
  width: 40%;
  height: 100vh;

  @media only screen and (max-width: 960px) {
    display: block;
  }
`;
export const StyledMessagesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
`;
export const StyledMessagesListImageInitials = styled.div`
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #f5edff;
  color: #5b2b99;
  width: 40px;
  height: 40px;
  weight: 600;
  margin-right: 10px;
`;
export const StyledMessagesListImage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const StyledMessagesListImageH2 = styled.span`
  font-weight: 600;
  font-size: 1.4rem;
  color: #00171f;
  text-transform: capitalize;
`;

export const StyledMessagesWrapper = styled.div`
  margin-left: 2rem;
  padding: 4rem 0;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
export const StyledNoMessageBox = styled.div`
  padding: 3rem 1rem;
  background: #f0f5ff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;

  .info-text {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: #00171f;
    max-width: 37.4rem;
  }
`;
export const StyledDescriptionText = styled.p`
  text-overflow: ellipsis;
  width: 15rem;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledMessageFieldWrap = styled.div`
  width: 100%;
`;
export const StyledNoMessageFieldBox = styled.div`
  padding: 3rem 1rem;
  background: #f0f5ff;
  border-radius: 5px;
  display: flex;
  margin-top: 15rem;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 20%;

  .info-text {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: #00171f;
    max-width: 40.4rem;
  }
`;
export const StyledMessageField = styled.div`
  display: flex;
  justify-content: center;
  height: 90%;
`;
export const StyledMessageInputDiv = styled.div`
  height: 10%;
  display: flex;
`;
export const StyledMessageInput = styled.input`
  border: 1px solid #f5f5f5;
  padding: 10px;
  width: 95%;
  border-radius: 10px;
  margin: 10px;
  align-items: center;
  background: #f5f5f5;
`;
export const StyledMessageModalInputWrap = styled.div`
  position: relative;
`;
export const StyledMessageModalInputIcon = styled.div`
  position: absolute;
  right: 0;
  top: 18%;
`;

export const StyledMessageModalInput = styled.input`
  border: 1px solid #f5f5f5;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 10px;
  align-items: center;
`;

export const StyledMessageContactListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
`;
