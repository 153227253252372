import styled from "styled-components";

export const StyledHorizontalStepperItem = styled.li<{
  completed: boolean;
  active: boolean;
  index: number;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    content: ${({completed, index}) => (completed ? '"\\2713"' : `"${index}"`)};
    display: block;
    // width: clamp(1.5rem, 5vw, 2.4rem);
    // height: clamp(1.5rem, 5vw, 2.4rem);
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid #00171f;
    border-radius: 24px;
    background: ${({completed}) => (completed ? "#FFFFFF" : "#00171F")};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({completed}) => (completed ? "#00171F" : "#fff")};
    // box-shadow: ${({active}) => (active ? "0px 0px 0px 4px #e1e1fe;" : "none")};
    // opacity: 0.5;
    margin: 0 auto 1rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      // top: calc(clamp(1.5rem, 5vw, 2.4rem) / 2);
      // width: calc(100% - clamp(1.5rem, 5vw, 2.4rem));
      // left: calc(50% + calc(clamp(1.5rem, 5vw, 2.4rem) / 2));
      top: calc(2.4rem / 2);
      // width: calc(100% - 2.4rem);
      width: 50%;
      // left: calc(50% + calc(2.4rem / 2));
      left: calc(40% + calc(2.4rem / 2));
      height: 1px;
      background: #00171f;
      order: -1;
      margin: 0 auto;
    }
  }

  &.stepper-done {
    &:before {
      content: "\\2713";
      background: #00171f;
      border: none;
      color: #fff;
    }
    &:not(:last-child) {
      &:after {
        background: #00171f;
      }
    }
  }

  &.stepper-active {
    &:before {
      content: "\\25CF";
      background: #fff;
      color: #ed9f00;
      box-shadow: 0px 0px 0px 4px #e1e1fe;
      border: 2px solid #ed9f00;
    }
  }

  .stepper-title {
    font-weight: 400;
    font-size: 1.4rem;
    color: #00171f;

    @media screen and (max-width: 468px) {
      font-size: 1.2rem;
    }
  }

  .stepper-sub-text {
    font-weight: 400;
    font-size: 1.4rem;
    color: #6b7280;
    @media screen and (max-width: 609px) {
      display: none;
    }
  }

  .stepper-content {
    width: 20.9rem;
  }
`;
