import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {forgotPasswordService, newPasswordService} from "./forgot-password-service";

interface IInitialState {
  userInfo: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  userInfo: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Reset password
export const forgotPassword = createAsyncThunk(
  "userResetPassword/forgotPassword",
  async (email: string, thunkAPI) => {
    try {
      return await forgotPasswordService(email.toLowerCase());
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reset password
export const resetPassword = createAsyncThunk(
  "userResetPassword/resetPassword",
  async (
    payload: {
      password: string;
      confirmPassword: string;
      link: string;
    },
    thunkAPI
  ) => {
    try {
      return await newPasswordService(payload);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPasswordSlice = createSlice({
  name: "userResetPassword",
  initialState,
  reducers: {
    resetUserResetPasswordState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
    userResetPasswordLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userInfo = action.payload.data;
      })
      .addCase(resetPassword.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userInfo = null;
      });
  },
});

export const {resetUserResetPasswordState, userResetPasswordLogoutReset} =
  forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
