import * as yup from "yup";

const step2Schema = yup.object().shape({
  email_address: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email"),
  first_name: yup.string().trim().required("First name is required"),
  // .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  last_name: yup.string().trim().required("Last name is required"),
  // .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  numberCode: yup.string().trim(),
  phone_number: yup
    .string()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim(),
  currency: yup.object({
    label: yup.string().required("Select a currency"),
    value: yup.string().required("Select a currency"),
  }),
  notes: yup.string().trim().required("Please share your expectations"),
  bookingQuestions: yup.array(
    yup.object({
      required: yup.boolean(),
      answer: yup.mixed().when("required", {
        is: true,
        then: yup.string().trim().required("Answer is required"),
      }),
    })
  ),
});
const step2AudioSchema = yup.object().shape({
  email_address: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
  first_name: yup.string().trim().required("First name is required"),
  // .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  last_name: yup.string().trim().required("Last name is required"), // .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  numberCode: yup.string().trim().required("Country code is a required"),
  phone_number: yup
    .string()
    // .typeError("That doesn't look like a phone number")
    .required("Phone number field is a required field")
    .matches(/^[0-9]+$/, "Must be only digits"),
  // .min(11, "That doesn't look like a valid phone number")
  // .max(11, "That doesn't look like a valid phone number"),
  notes: yup.string().trim().required("Type something!"),
  currency: yup.object({
    label: yup.string().required("Select a currency"),
    value: yup.string().required("Select a currency"),
  }),
  // .required("Short bio is required"),
  // bookingQuestions: yup.array().of(
  //   yup.object().shape({
  //     required: yup.bool(),
  //     answer: yup
  //       .string()
  //       .trim()
  //       .when("required", {
  //         is: true,
  //         then: yup.string().trim().required("This question is required"),
  //       }),
  //   })
  // ),
  bookingQuestions: yup.array(
    yup.object({
      required: yup.boolean(),
      answer: yup.mixed().when("required", {
        is: true,
        then: yup.string().trim().required("Answer is required"),
      }),
    })
  ),
});

export {step2Schema, step2AudioSchema};
