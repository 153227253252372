import styled from "styled-components";

export const StyledSuccessBookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 2rem;
  @media (min-width: 768px) {
    gap: 10rem;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4.3rem;
  }
`;

export const StyledSuccessDetailsCard = styled.div`
  padding: 2rem;
  border-radius: 5px;
  border: 1px #f5f5f5;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  max-width: 48.8rem;

  .success-details-card-title {
    font-size: 2rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    /* display 1 lines only */
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .success-details-card-image-wrapper {
    height: 15rem;

    .success-details-card-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .success-details-card-extra-info {
  }

  @media (min-width: 768px) {
    padding: 2.4rem 3.6rem 4.4rem 3.7rem;
  }
`;
