import styled from "styled-components";

export const StyledEmailUpdateFormContainer = styled.div`
  color: #00171f;
  .email-heading-text {
    font-weight: 600;
    font-size: 1.6rem;
  }
  .email-heading-sub-text {
    font-weight: 400;
    font-size: 1.4rem;
    margin-top: 8px;
  }
`;
