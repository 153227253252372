import {Button} from "antd";

type CustomLoadMoreButtonProps = {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
};
export function CustomLoadMoreButton({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: CustomLoadMoreButtonProps) {
  if (hasNextPage) {
    return (
      <div
        style={{
          marginTop: "2.4rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          type="primary"
          size="large"
          shape="round"
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "Nothing more to load"}
        </Button>
      </div>
    );
  } else return null;
}
