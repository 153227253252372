import styled from "styled-components";

export const StyledOfferingTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;

  @media (min-width: 425px) {
    font-size: 3rem;
    font-weight: 700;
  }
`;
