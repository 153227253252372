import styled from "styled-components";
import {motion} from "framer-motion";

export const StyledHeroSection = styled(motion.div)`
  padding: 20rem 0 5rem 0;
  @media (max-width: 1024) {
    padding: 15rem 0 10rem 0;
  }
  @media (max-width: 767px) {
    padding: 10rem 0 7.5rem 0;
  }
`;

export const StyledHeroSectionInfo = styled(motion.div)`
  .hero-section-info-main-title {
    font-size: 4rem;
    font-weight: 700;

    color: #00171f;
    line-height: 1.15;
  }
  .hero-section-info-sub-title {
    margin-top: 2rem;
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    .hero-section-info-main-title {
      font-size: 3rem;
    }
    .hero-section-info-sub-title {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 767px) {
    .hero-section-info-main-title {
      font-size: 2.6rem;
    }
    .hero-section-info-sub-title {
      font-size: 1.4rem;
    }
  }
`;

export const StyledWaitListBtnContainer = styled(motion.div)`
  display: flex;
  gap: 2rem;
  margin-top: 4.5rem;
  transform-origin: 25.4053% 31.2634% 0px;

  .styled-waitlist-hero-btn {
    display: inline-block;
  }
  .styled-demo-btn {
    display: inline-block;
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }

  @media (max-width: 1024px) {
    margin-top: 3.5rem;
    margin-bottom: 3rem;
  }
  @media (max-width: 767px) {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    .styled-waitlist-hero-btn {
      width: 100%;
      text-align: center;
    }
    .styled-demo-btn {
      width: 100%;
      text-align: center;
    }
  }
`;
