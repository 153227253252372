import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import {ServiceForm} from "../../../../components/calls/service/service-form";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";

const AddCallPage = () => {
  return (
    <DashboardWrapper pageTitle="Add new 1:1 service">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/calls">1:1 Calls</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add new 1:1 service</Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <ServiceForm />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {AddCallPage};
