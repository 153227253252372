import {CustomerDetailsSection} from "../../../components/customers/customer-details-section/customer-details-section";
import {CustomerMetricsSection} from "../../../components/customers/customer-metrics-section/customer-metrics-section";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledCustomersPageWrapper} from "../../../styles/customers/customers";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";

const CustomersPage = () => {
  return (
    <DashboardWrapper pageTitle="Customers" pageSlug="/dashboard/customers">
      <StyledPageHeader>Customers 👥</StyledPageHeader>
      <StyledCustomersPageWrapper>
        <CustomerMetricsSection />
        <CustomerDetailsSection />
      </StyledCustomersPageWrapper>
    </DashboardWrapper>
  );
};

export {CustomersPage};
