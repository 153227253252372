import styled from "styled-components";
import {Layout} from "antd";

const {Header} = Layout;

export const StyledHeaderWrapper = styled.div`
  background-color: #ffffff;
  height: 7.101rem;
  border: 1px solid #fafafa;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 10;
  width: 100%;
`;
export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  line-height: 7.101rem;
  max-width: 134.8rem;
  margin: 0 auto;
  padding: 0 2.5rem 0 1.5rem;
  height: 100%;
`;
