import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useClient} from "../../utils/hooks";
import {AxiosError} from "axios";
import {Currency} from "../../components/calls/service/service-form/i-service-form";
import {message} from "antd";
import {AnalyticsEvent} from "../../utils/analytics";
import {TProfileApiResponse} from "../../redux/profile/i-profile-1";
import {CurrencyResponse, PaginationMeta} from "../../redux/service/i-service";

const module_query_key = "digital_products";
const module_customer_query_key = "digital_products_customer";

type TGetProductRes = {
  id: number;
  userId: number;
  title: string;
  slug: string;
  description: string;
  coverImage: string;
  currency: CurrencyResponse;
  hideFromProfile: boolean;
  is_deleted: boolean;
  request_phone_number: boolean;
  url_redirect?: string;
  statusId: number;
  reference: string;
  createdAt: string;
  updatedAt: string;
};
type TGetProductResCustomer = {
  id: number;
  userId: number;
  title: string;
  slug: string;
  description: string;
  coverImage: string;
  hideFromProfile: boolean;
  is_deleted: boolean;
  statusId: number;
  reference: string;
  createdAt: string;
  updatedAt: string;
};
export type TMediaLogs = {
  id: number;
  userId: number;
  productId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  currency: string;
  amount: number;
  file_name: string;
  file_type: string;
  file_size: string;
  media_url: string;
  reference: string;
  statusId: number;
  createdAt: string;
  updatedAt: string;
};

export type TProductMedia = {
  id: number;
  userId: number;
  productId: number;
  file_name: string;
  file_type: string;
  file_size: string;
  media_url: string;
  createdAt: string;
  updatedAt: string;
};

export type TGetProductsApiResponse = {
  products: {
    id: number;
    userId: number;
    title: string;
    slug: string;
    description: string;
    coverImage: string;
    hideFromProfile: boolean;
    is_deleted: boolean;
    statusId: number;
    reference: string;
    createdAt: string;
    updatedAt: string;
    defaultPrice: {amount: number; currency: string} | null;
    noOfSales: number;
    revenue: number;
    productCurrencies: [];
  }[];
};
export type TGetCustomerProductsApiResponse = {
  products: {
    id: number;
    userId: number;
    title: string;
    slug: string;
    description: string;
    coverImage: string;
    hideFromProfile: boolean;
    is_deleted: boolean;
    statusId: number;
    reference: string;
    createdAt: string;
    updatedAt: string;
    amount: number;
    currency: string;
  }[];
};

export type TGetProductApiResponse = {
  product: TGetProductRes;
  totalSales: number;
  medias: TProductMedia[];
  currencies: CurrencyResponse[];
  revenue: {amount: number; currency: string; units: number}[];
};
export type TGetCustomerProductApiResponse = {
  product: TGetProductResCustomer;
  mediaLogs: TMediaLogs[];
  currencies: CurrencyResponse[];
  coach: {
    avatar: null;
    country: string;
    cover_image: string;
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    isSocialAuth: boolean;
    lastName: string;
    onboarding_reminder: number;
    password: null;
    profile: {
      behance: string;
      createdAt: string;
      description: string;
      discord: string | null;
      dribble: string | null;
      facebook: string | null;
      github: string | null;
      id: number;
      instagram: string | null;
      linkedin: string | null;
      shortBio: string;
      tiktok: string | null;
      twitch: string | null;
      twitter: string | null;
      updatedAt: string;
      userId: number;
      username: string;
      website: string | null;
      youtube: string | null;
    };
    statusId: number;
    updatedAt: string;
  };
};

export type TCreateProductPayload = {
  title: string;
  description: string;
  url_redirect?: string;
  coverImage: string;
  currencies: Currency[];
  hideProductFromProfile: boolean;
  request_phone_number: boolean;
};

export type TCreateProductApiResponse = {
  product: {
    userId: number;
    title: string;
    slug: string;
    description: string;
    coverImage: string;
    hideFromProfile: boolean;
    statusId: string;
    reference: string;
    id: number;
    is_deleted: boolean;
    createdAt: string;
    updatedAt: string;
  };
};

export type TUpdateProductPayload = {
  title: string;
  description: string;
  coverImage: string;
  currencies: Currency[];
  hideProductFromProfile: boolean;
  request_phone_number: boolean;
  productId: number;
};

export type TPublicProfileProductDetailsApiResponse = {
  product: TGetProductRes;
  profile: TProfileApiResponse;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    onboarding_reminder: number;
    country: string;
    password: null;
    statusId: number;
    isSocialAuth: boolean;
    avatar: string;
    cover_image: string;
    createdAt: string;
    updatedAt: string;
  };
  currencies: CurrencyResponse[];
  medias: TProductMedia[];
};

export type TPurchaseProductPayload = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  productId: number;
  phone_number?: string;
  currencyId: number | null;
};

export type TPurchaseProductApiResponse = {
  amount: number;
  currency: string;
  isPaid: boolean;
  isStripe: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  redirectUrl?: string;
  reference: string;
  paymentChannel: string;
};

export type TRenameContentPayload = {
  fileName: string;
  fileExtension: string;
  productId: number;
  contentId: number;
};
export type TUploadContentPayload = {
  fileName: string;
  fileType: string;
  fileExtension: string;
  productId: number;
  data: Blob;
};

export type TGetProductCustomersApiResponse = {
  customers: {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    reference: string;
    currency: string;
    phoneNumber: string;
    amount: number;
    createdAt: string;
  }[];
};

export const useGetProducts = (page: number = 1, per_page: number = 5) => {
  const client = useClient();

  return useQuery<
    {data: TGetProductsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: [module_query_key, page],
    queryFn: () =>
      client(`product?page=${page}&per_page=${per_page}`).then((data) => data),
    refetchInterval: 300000,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useGetProduct = (productId?: string) => {
  const client = useClient();

  return useQuery<TGetProductApiResponse, AxiosError<{status: boolean; message: string}>>(
    {
      queryKey: [module_query_key, String(productId)],
      queryFn: () => client(`product/${productId}`).then((data) => data.data),
      enabled: !!productId,
      // staleTime: Infinity,
      // refetchOnWindowFocus: true,
    }
  );
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  const client = useClient();
  return useMutation({
    mutationFn: (productData: TCreateProductPayload) =>
      client(`product`, {
        method: "POST",
        data: productData,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Digital Products", "Digital products create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Digital Products", "Digital products create success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: [module_query_key]}),
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  const client = useClient();
  return useMutation({
    mutationFn: (productData: TUpdateProductPayload) =>
      client(`product`, {
        method: "PUT",
        data: productData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries([module_query_key, String(values.productId)]);

      const oldProductData = queryClient.getQueryData<TGetProductApiResponse>([
        module_query_key,
        String(values.productId),
      ]);

      // console.log({oldProductData});
      queryClient.setQueryData([module_query_key, String(values.productId)], {
        product: {
          coverImage: values.coverImage,
          createdAt: oldProductData?.product.createdAt,
          description: values.description,
          hideFromProfile: values.description,
          id: oldProductData?.product.id,
          is_deleted: oldProductData?.product.is_deleted,
          reference: oldProductData?.product.reference,
          slug: oldProductData?.product.slug,
          statusId: oldProductData?.product.statusId,
          title: values.title,
          updatedAt: oldProductData?.product.updatedAt,
          userId: oldProductData?.product.userId,
        },
        currencies: values.currencies,
        revenue: oldProductData?.revenue,
        totalSales: oldProductData?.totalSales,
      });

      return () =>
        queryClient.setQueryData(
          [module_query_key, String(values.productId)],
          oldProductData
        );
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Digital Products", "Digital products create fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      // console.log({mutationQuery: data});
      // queryClient.setQueryData(["digital_products", String(values.productId)], {
      //   ...data.data,
      //   currencies: values.currencies,
      // });
      AnalyticsEvent("Digital Products", "Digital products create success");
    },
    onSettled: (d, e, values) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [module_query_key, String(values.productId)],
        }),
        queryClient.invalidateQueries({
          queryKey: [module_query_key],
        }),
      ]),
  });
};
export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (productId: string) =>
      client(`product/${productId}`, {
        method: "DELETE",
      }),
    onMutate: (productId) => {
      const oldProducts = queryClient.getQueryData<TGetProductsApiResponse>([
        module_query_key,
      ]);
      queryClient.setQueryData([module_query_key], () => {
        const filteredProducts = oldProducts?.products.filter(
          (product) => String(product.id) !== String(productId)
        );
        return {
          products: filteredProducts,
        };
      });

      return () => queryClient.setQueryData([module_query_key], oldProducts);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Digital Product", "Digital Product delete fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Digital Product", "Digital Product delete success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: [module_query_key]}),
  });
};
export const useUploadContent = () => {
  const queryClient = useQueryClient();

  const client = useClient();
  return useMutation({
    mutationFn: (payload: TUploadContentPayload) =>
      client(
        `products/contents/upload/${payload.productId}/${payload.fileName}/${payload.fileExtension}`,
        {
          method: "POST",
          data: payload.data,
          headers: {
            "Content-Type": payload.fileType, // Indicate binary data
          },
          // responseType: "arraybuffer", // Receive the response as binary data
        }
      ),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Digital Products", "Digital products content rename fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Digital Products", "Digital products content rename success");
    },
    onSettled: (d, e, values) =>
      setTimeout(
        () =>
          queryClient.invalidateQueries({
            queryKey: [module_query_key, String(values.productId)],
          }),
        10000
      ),
  });
};
export const useRenameContent = () => {
  const queryClient = useQueryClient();

  const client = useClient();
  return useMutation({
    mutationFn: (productData: TRenameContentPayload) =>
      client(`products/contents/rename`, {
        method: "PUT",
        data: productData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries([module_query_key, String(values.productId)]);

      const oldProductData = queryClient.getQueryData<TGetProductApiResponse>([
        module_query_key,
        String(values.productId),
      ]);

      const newProductContentMedia = oldProductData?.medias.map((content) => {
        if (content.id === values.contentId) {
          return {
            ...content,
            file_name: values.fileName,
          };
        }
        return content;
      });

      queryClient.setQueryData([module_query_key, String(values.productId)], {
        ...oldProductData,
        medias: newProductContentMedia,
      });

      return () =>
        queryClient.setQueryData(
          [module_query_key, String(values.productId)],
          oldProductData
        );
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Digital Products", "Digital products content rename fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      AnalyticsEvent("Digital Products", "Digital products content rename success");
    },
    onSettled: (d, e, values) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [module_query_key, String(values.productId)],
        }),
      ]),
  });
};
export const useDeleteContent = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {productId: string | number; contentId: string | number}) =>
      client(`products/content/delete/${payload.productId}/${payload.contentId}`, {
        method: "DELETE",
      }),
    onMutate: (values) => {
      const oldProduct = queryClient.getQueryData<TGetProductApiResponse>([
        module_query_key,
        String(values.productId),
      ]);
      // console.log({oldProduct});
      queryClient.setQueryData([module_query_key, String(values.productId)], () => {
        const filteredProductMedia = oldProduct?.medias.filter(
          (content) => String(content.id) !== String(values.contentId)
        );
        return {
          ...oldProduct,
          medias: filteredProductMedia,
        };
      });

      return () =>
        queryClient.setQueryData(
          [module_query_key, String(values.productId)],
          oldProduct
        );
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Digital Product", "Digital Product Content delete fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Digital Product", "Digital Product Content delete success");
    },
    onSettled: (data, error, variables) =>
      queryClient.invalidateQueries({
        queryKey: [module_query_key, String(variables.productId)],
      }),
  });
};

export const useGetPublicProfileProductDetails = (payload: {
  username: string | undefined;
  slug: string | null;
  productId: string | null;
}) => {
  const client = useClient();

  return useQuery<
    TPublicProfileProductDetailsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["public_profile_digital_products", payload.productId],
    queryFn: () =>
      client(
        `product/booking/${payload.slug}/${payload.productId}/${payload.username}`
      ).then((data) => data.data),
    enabled: !!payload.slug && !!payload.productId && !!payload.username,
  });
};

export const usePurchaseProduct = () => {
  const client = useClient();
  return useMutation<
    {data: TPurchaseProductApiResponse},
    AxiosError,
    TPurchaseProductPayload,
    () => void
  >({
    mutationFn: (bookingData) =>
      client(`product/purchase`, {
        method: "POST",
        data: {
          ...bookingData,
          emailAddress: bookingData.emailAddress.toLowerCase(),
        },
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Digital Product booking create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Digital Product booking create success");
    },
  });
};

export const useGetCustomerProducts = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TGetCustomerProductsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: [module_customer_query_key, page],
    queryFn: () =>
      client(`customer/products?page=${page}&per_page=${per_page}`).then((data) => data),
    refetchInterval: 300000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useGetProductCustomer = (productId?: string) => {
  const client = useClient();

  return useQuery<
    TGetCustomerProductApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: [module_customer_query_key, String(productId)],
    queryFn: () => client(`customer/products/${productId}`).then((data) => data.data),
    enabled: !!productId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
export const useGetProductMetrics = () => {
  const client = useClient();

  return useQuery<
    TGetCustomerProductApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["digital_products_metrics"],
    queryFn: () => client(`product/dashboard/metric/coach`).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });
};

export const useGetProductCustomers = (
  productId?: number,
  page?: number,
  per_page: number = 5
) => {
  const client = useClient();

  return useQuery<
    {data: TGetProductCustomersApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["digital_products_customers", String(productId), page],
    queryFn: () =>
      client(`product-customers/${productId}?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    // staleTime: Infinity,
    enabled: !!productId,
    refetchInterval: 300000,
    keepPreviousData: true,
  });
};

export const useProductCustomerExportCSV = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (productID: string | number) =>
      client(`product-csv/${productID}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach product customer", "Coach product customer export CSV fail");
    },
    onSuccess: () => {
      AnalyticsEvent(
        "Coach product customer",
        "Coach product customer export CSV success"
      );
    },
  });
};
