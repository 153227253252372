import {TGetProductsApiResponse} from "../../react-query-hooks/products";
import {PaginationMeta} from "../../redux/service/i-service";

type TUpdateCurrentPage = {
  type: "update-current-page";
  payload: number;
};
type TUpdatePerPage = {
  type: "update-per-page";
  payload: number;
};

export type TGetAllProductsActions = TUpdateCurrentPage | TUpdatePerPage;

export type TGetAllProductsState = {
  currentPage: number;
  per_page: number;
  products?: TGetProductsApiResponse["products"];
  productMeta?: PaginationMeta;
  isPreviousData: boolean;
  isLoading: boolean;
};

export const initialGetAllProductsState: TGetAllProductsState = {
  currentPage: 1,
  per_page: 5,
  products: undefined,
  productMeta: undefined,
  isPreviousData: false,
  isLoading: false,
};

export const createProductFormReducer = (
  state: TGetAllProductsState = initialGetAllProductsState,
  action: TGetAllProductsActions
) => {
  if (action.type === "update-current-page") {
    return {...state, currentPage: action.payload};
  }
  if (action.type === "update-per-page") {
    return {...state, per_page: action.payload};
  }
  //   if (action.type === "reset-state") {
  //     return {...initialCreateProductFormState};
  //   }

  return state;
};
