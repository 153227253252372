import {message, Spin} from "antd";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RescheduleBookingLeftColumn} from "../../../components/booking/left-column/reschedule-left-column/reschedule-left-column";
import {ServiceBookingErrorPage} from "../../../components/booking/service-booking-error-page";
import {BookingDataProvider} from "../../../container/booking/booking-context";
import {RescheduleBookingStep1} from "../../../container/booking/reschedule/step-1";
import {RescheduleBookingStep2} from "../../../container/booking/reschedule/step-2";
import {RescheduleBookingStep3} from "../../../container/booking/reschedule/step-3";
import {getCustomerBooking} from "../../../redux/booking/booking-slice";
import {TFetchCustomerBookingPayload} from "../../../redux/booking/i-time-slots-payload";
import {AppDispatch, RootState} from "../../../redux/store";
import {
  StyledBookingContainer,
  StyledBookingRightColumn,
  StyledBookingWrapper,
} from "../../../styles/booking/booking";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet-async";

const CustomerReschedulePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {reference} = useParams();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    fetchCustomerBookingStatus,
    customerBooking,
    message: customerBookingMessage,
  } = useSelector((state: RootState) => state.booking);

  // console.log(customerBooking);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (reference) {
      const payload: TFetchCustomerBookingPayload = {
        reference,
        userType: "guest_user",
      };
      dispatch(getCustomerBooking(payload));
    }
  }, [dispatch, reference]);

  useEffect(() => {
    if (fetchCustomerBookingStatus === "rejected") {
      message.error(customerBookingMessage);
    }
  }, [customerBookingMessage, fetchCustomerBookingStatus]);

  const updateStep = (step: number) => {
    if (step > 3) {
      return;
    }
    setCurrentStep(step);
  };

  function handleView() {
    let content;
    switch (currentStep) {
      case 3:
        content = (
          <RescheduleBookingStep3 updateStep={updateStep} currentStep={currentStep} />
        );
        break;
      // case 2:
      //   content = (
      //     <RescheduleBookingStep2 updateStep={updateStep} currentStep={currentStep} />
      //   );
      //   break;
      default:
      case 1:
        content = (
          <RescheduleBookingStep1 updateStep={updateStep} currentStep={currentStep} />
        );
    }
    return content;
  }

  return (
    <>
      <Helmet>
        <title>Coachli | Reschedule a meeting</title>
      </Helmet>

      <BookingDataProvider>
        <StyledBookingContainer>
          {fetchCustomerBookingStatus === "rejected" ? (
            <ServiceBookingErrorPage />
          ) : fetchCustomerBookingStatus === "pending" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <StyledBookingWrapper>
              <RescheduleBookingLeftColumn currentStep={currentStep} />
              {/* <BookingLeftColumn currentStep={currentStep} /> */}
              <StyledBookingRightColumn>{handleView()}</StyledBookingRightColumn>
            </StyledBookingWrapper>
          )}
        </StyledBookingContainer>
      </BookingDataProvider>
    </>
  );
};

export default CustomerReschedulePage;
