import {Select, Spin} from "antd";
import {Controller} from "react-hook-form";
import {StyledInputLabelWrapper} from "../input/input.style";

const {Option} = Select;

interface ICustomSelectInputProps {
  control: any;
  error?: string;
  id: string;
  name: string;
  label?: string;
  size?: "large" | "middle" | "small";
  placeholder: string;
  options: any[];
  showSearch?: boolean;
  isLoading?: boolean;
  defaultValue?: any;
  style?: any;
  required?: boolean;
}

const CustomSelectInput: React.FC<ICustomSelectInputProps> = ({
  control,
  name,
  options,
  placeholder,
  showSearch,
  size = "large",
  error,
  label,
  id,
  isLoading,
  defaultValue,
  style,
  required,
}) => {
  const labelId = label ? `${id}-label` : undefined;
  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <>
          {label && (
            <StyledInputLabelWrapper>
              <label id={labelId} htmlFor={id}>
                {required && <span style={{color: "#f5222d"}}>* </span>}
                {label}
              </label>
            </StyledInputLabelWrapper>
          )}

          <Select
            {...field}
            size={size}
            status={error ? "error" : ""}
            showSearch={showSearch}
            placeholder={placeholder}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={style}
            notFoundContent={isLoading ? <Spin /> : null}
            defaultValue={defaultValue}
          >
            {options.map((option, optionIdx: number | string) => (
              <Option
                key={optionIdx}
                value={option.value ? option.value : option.name ? option.name : option}
              >
                {option.name || option}
              </Option>
            ))}
          </Select>
          {error && (
            <div role="alert" className="ant-form-item-explain-error">
              {error}
            </div>
          )}
        </>
      )}
    />
  );
};

export {CustomSelectInput};
