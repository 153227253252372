import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {TService} from "../service/i-service";
import {RootState} from "../store";
import {
  customerCancelBookingService,
  createBookingService,
  getBookingServiceService,
  getBookingTimeSlotService,
  getCustomerBookingService,
  rescheduleBookingService,
} from "./booking-service";
import {
  IBookingPayload,
  IBookingServicePayload,
  IRescheduleBookingPayload,
  ITimeSlotsPayload,
  TCustomerCancelBookingPayload,
  TFetchCustomerBookingPayload,
} from "./i-time-slots-payload";

interface IInitialState {
  bookingServiceData: {
    profile?: {
      id: number;
      userId: number;
      firstName: string;
      lastName: string;
      emailAddress: string;
      country: string;
      username: string;
      description: string;
      shortBio: string;
      twitter: string | null;
      facebook: string | null;
      instagram: string | null;
      tiktok: string | null;
      discord: string | null;
    };
    service?: {
      id: number;
      userId: number;
      name: string;
      description: string;
      slug: string;
      status_id: number;
      callDuration: number;
      callPriceCurrency: string;
      callPrice: number;
      hideServiceFromMenu: boolean;
      videoLocation: boolean;
      audioLocation: boolean;
      bookingQuestions?: {question: string; required: boolean}[];
    };
    mentor?: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      avatar: string | null;
    };
    days?: {
      // sun: {from: string; to: string}[];
      // mon: {from: string; to: string}[];
      // tue: {from: string; to: string}[];
      // wed: {from: string; to: string}[];
      // thu: {from: string; to: string}[];
      // fri: {from: string; to: string}[];
      // sat: {from: string; to: string}[];
    };
    timeZone?: string;
    unavailableDates?: {
      from: string;
      to: string;
    }[];
  };
  successBookingData: {
    userId?: string;
    serviceId?: string;
    mentor_timezone?: string;
    user_timezone?: string;
    booking_year?: string;
    booking_month?: string;
    booking_date?: string;
    timeslot?: string;
    email_address?: string;
    first_name?: string;
    last_name?: string;
    notes?: string;
    phone_number?: string;
    booking_type?: string;
    is_bundle?: boolean;
    statusId?: number;
    reference?: string;
    is_paid?: boolean;
    bookingQuestions?: [{}];
    id?: string;
  };
  timeSlots: string[];
  customerBooking: {
    booking?: {
      id?: number;
      userId?: number;
      serviceId?: number;
      mentor_timezone?: string;
      user_timezone?: string;
      booking_year?: string;
      booking_month?: string;
      booking_date?: string;
      timeslot?: string;
      endTime?: string;
      email_address?: string;
      first_name?: string;
      last_name?: string;
      amount?: number;
      roomId?: string | null;
      notes?: string | null;
      phone_number?: string;
      booking_type?: string;
      is_bundle?: boolean;
      statusId?: number;
      reference?: string;
      is_paid?: boolean;
      bookingQuestions?: [] | null;
      statusName?: string;
    };
    mentor?: {
      firstName: string;
      lastName: string;
      emailAddress: string;
      avatar: string | null;
    };
    profile?: {
      id: number;
      userId: string;
      username: string;
      description: string;
      shortBio: string;
      twitter: string | null;
      facebook: string | null;
      instagram: string | null;
      linkedin: string | null;
      tiktok: string | null;
      discord: string | null;
      youtube: string | null;
      dribble: string | null;
      behance: string | null;
      twitch: string | null;
      github: string | null;
      website: string | null;
    };
    service?: TService;
    bookingTimeSlot?: string;
    bookingToken?: string;
    days?: {
      // sun: {from: string; to: string}[];
      // mon: {from: string; to: string}[];
      // tue: {from: string; to: string}[];
      // wed: {from: string; to: string}[];
      // thu: {from: string; to: string}[];
      // fri: {from: string; to: string}[];
      // sat: {from: string; to: string}[];
    };
    timeZone?: string;
    unavailableDates?: {
      from: string;
      to: string;
    }[];
  };
  timeSlotsFetchStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchStatus: "idle" | "pending" | "resolved" | "rejected";
  createStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchCustomerBookingStatus: "idle" | "pending" | "resolved" | "rejected";
  customerCancelBookingStatus: "idle" | "pending" | "resolved" | "rejected";
  customerRescheduleBookingStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  bookingServiceData: {},
  successBookingData: {},
  timeSlots: [],
  customerBooking: {},
  timeSlotsFetchStatus: "idle",
  fetchStatus: "idle",
  createStatus: "idle",
  fetchCustomerBookingStatus: "idle",
  customerCancelBookingStatus: "idle",
  customerRescheduleBookingStatus: "idle",
  message: "",
};

//get public profile by slug
export const getBookingService = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IBookingServicePayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/getBookingService", async (payload, thunkAPI) => {
  try {
    return await getBookingServiceService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// get customer booking by reference
export const getCustomerBooking = createAsyncThunk<
  // Return type of the payload creator
  any,
  TFetchCustomerBookingPayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/getCustomerBooking", async (payload, thunkAPI) => {
  try {
    return await getCustomerBookingService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//get booking time slot
export const getBookingTimeSlot = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  ITimeSlotsPayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/getBookingTimeSlot", async (payload, thunkAPI) => {
  try {
    return await getBookingTimeSlotService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//create booking
export const createBooking = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IBookingPayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/createBooking", async (payload, thunkAPI) => {
  try {
    return await createBookingService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//reschedule booking
export const customerRescheduleBooking = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IRescheduleBookingPayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/customerRescheduleBooking", async (payload, thunkAPI) => {
  try {
    return await rescheduleBookingService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Cancel customer booking
//mentor cancel call
export const customerCancelBooking = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  TCustomerCancelBookingPayload,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("booking/customerCancelCall", async (payload, thunkAPI) => {
  try {
    return await customerCancelBookingService(payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    resetUserBookingState: (state) => {
      state.timeSlotsFetchStatus = "idle";
      state.fetchStatus = "idle";
      state.createStatus = "idle";
      state.message = "";
      state.timeSlots = [];
      state.bookingServiceData = {};
    },
    resetUserBookingUpdatesState: (state) => {
      state.timeSlotsFetchStatus = "idle";
      state.fetchStatus = "idle";
      state.createStatus = "idle";
      state.customerRescheduleBookingStatus = "idle";
      state.message = "";
    },
    resetCustomerBookingState: (state) => {
      state.customerBooking = {};
      state.fetchCustomerBookingStatus = "idle";
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingService.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(getBookingService.fulfilled, (state, action) => {
        state.fetchStatus = "resolved";
        state.bookingServiceData = action.payload.data;
      })
      .addCase(getBookingService.rejected, (state, action: any) => {
        state.fetchStatus = "rejected";
        state.message = action.payload;
        state.bookingServiceData = {};
      })
      .addCase(getBookingTimeSlot.pending, (state) => {
        state.timeSlotsFetchStatus = "pending";
      })
      .addCase(getBookingTimeSlot.fulfilled, (state, action) => {
        state.timeSlotsFetchStatus = "resolved";
        state.timeSlots = action.payload.data.timeSlots;
      })
      .addCase(getBookingTimeSlot.rejected, (state, action: any) => {
        state.timeSlotsFetchStatus = "rejected";
        state.message = action.payload;
        state.timeSlots = [];
      })
      .addCase(getCustomerBooking.pending, (state) => {
        state.fetchCustomerBookingStatus = "pending";
      })
      .addCase(getCustomerBooking.fulfilled, (state, action) => {
        state.fetchCustomerBookingStatus = action.payload.data ? "resolved" : "rejected";
        state.message = !action.payload.data && action.payload.message;
        state.customerBooking = action.payload.data ?? {};
      })
      .addCase(getCustomerBooking.rejected, (state, action: any) => {
        state.fetchCustomerBookingStatus = "rejected";
        state.message = action.payload;
        state.customerBooking = {};
      })
      .addCase(customerCancelBooking.pending, (state) => {
        state.customerCancelBookingStatus = "pending";
      })
      .addCase(customerCancelBooking.fulfilled, (state, action) => {
        state.customerCancelBookingStatus = "resolved";
      })
      .addCase(customerCancelBooking.rejected, (state, action: any) => {
        state.customerCancelBookingStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(createBooking.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.createStatus = "resolved";
        state.successBookingData = action.payload.data.booking;
      })
      .addCase(createBooking.rejected, (state, action: any) => {
        state.createStatus = "rejected";
        state.message = action.payload;
        state.successBookingData = {};
      })
      .addCase(customerRescheduleBooking.pending, (state) => {
        state.customerRescheduleBookingStatus = "pending";
      })
      .addCase(customerRescheduleBooking.fulfilled, (state, action) => {
        state.customerRescheduleBookingStatus = "resolved";
        // state.successBookingData = action.payload.data.booking;
      })
      .addCase(customerRescheduleBooking.rejected, (state, action: any) => {
        state.customerRescheduleBookingStatus = "rejected";
        state.message = action.payload;
        // state.successBookingData = {};
      });
  },
});

export const {
  resetUserBookingState,
  resetUserBookingUpdatesState,
  resetCustomerBookingState,
} = bookingSlice.actions;

export default bookingSlice.reducer;

// export {};
