import {SendOutlined} from "@ant-design/icons";
import {Alert, Avatar, Input, message, Spin, Tooltip} from "antd";
import moment from "moment";
import {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useMessageContext} from "../../../context/message-context";
import {
  TSendMessageCoachPayload,
  useGetCoachMessageHistory,
  useSendMessageCoach,
} from "../../../react-query-hooks/messages";
import {RootState} from "../../../redux/store";
import {capitalizeFirstLetter} from "../../../services/helpers";
import {StyledNoUpcomingCallBox} from "../../calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../full-page-loader";
import {LoadingSpinner} from "../../icons/icons";
import {
  StyledChatBox,
  StyledNoChatBox,
  StyledReceivedMessageBox,
  StyledSentMessageBox,
  StyleSingleChatBox,
} from "./chat-list.style";

const ChatList = () => {
  const {state} = useMessageContext();
  const [typedMessage, setTypedMessage] = useState("");
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const contactToQuery = state.coachContacts.find(
    (contact) =>
      contact.customerName + contact.emailAddress ===
      (((state.coachActiveMessage?.customerName as string) +
        state.coachActiveMessage?.emailAddress) as string)
  );
  const getCoachMessageHistoryQuery = useGetCoachMessageHistory(contactToQuery?.id);
  const sendMessageCoachQueryMutation = useSendMessageCoach();

  const scrollRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    setTypedMessage(e.target.value);
  };

  const payload: TSendMessageCoachPayload = {
    customerEmailAddress: state.coachActiveMessage?.emailAddress as string,
    customerName: state.coachActiveMessage?.customerName as string,
    id: state.coachActiveMessage?.id as number,
    message: typedMessage,
    userId: null,
  };

  const handleEnter = (e: any) => {
    if (typedMessage) {
      sendMessageCoachQueryMutation.mutate(payload, {
        onSuccess: (data) => {
          scrollRef.current?.scrollIntoView({behavior: "smooth"});
          message.success("Message delivered successfully!");
        },
      });
      setTypedMessage("");
    } else {
      message.warning("No text!");
    }
  };
  const handleEnterKey = (e: any) => {
    e.preventDefault();
    if (e.keyCode === 13 && typedMessage) {
      sendMessageCoachQueryMutation.mutate(payload, {
        onSuccess: (data) => {
          scrollRef.current?.scrollIntoView({behavior: "smooth"});
          message.success("Message delivered successfully!");
        },
      });
      setTypedMessage("");
    }
  };
  return (
    <StyledChatBox>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          height: "100%",
        }}
      >
        {/* <StyledChatListBox> */}
        {!state.coachActiveMessage ? (
          <StyledNoChatBox>
            <StyledNoUpcomingCallBox>
              <p className="info-text">You don't have a selected message</p>
              <p className="info-text" style={{maxWidth: "50rem"}}>
                Choose one from your existing messages, or start a new one
              </p>
            </StyledNoUpcomingCallBox>
          </StyledNoChatBox>
        ) : (
          <>
            {getCoachMessageHistoryQuery.isLoading ? (
              <FullPageLoader />
            ) : getCoachMessageHistoryQuery.isError &&
              getCoachMessageHistoryQuery.error.message !==
                "Start by sending a message!" ? (
              <Alert
                message="Error"
                description={getCoachMessageHistoryQuery.error?.message}
                type="error"
                showIcon
              />
            ) : (
              <>
                <Spin
                  spinning={getCoachMessageHistoryQuery.isFetching}
                  // tip="Updating..."
                  indicator={LoadingSpinner}
                >
                  <div
                    style={{
                      height: "60.8rem",
                      overflowY: "scroll",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        backgroundColor: "#fff",
                        borderBottom: "1px solid #f5edff",
                        padding: "1rem 2.4rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Avatar
                          size="large"
                          style={{color: "#5B2B99", backgroundColor: "#F5EDFF"}}
                        >
                          {state.coachActiveMessage.customerName
                            .split(" ")[1]
                            .charAt(0)
                            .toUpperCase()}
                          {state.coachActiveMessage.customerName
                            .split(" ")[0]
                            .charAt(0)
                            .toUpperCase()}
                        </Avatar>
                        <div>
                          <h4>
                            {capitalizeFirstLetter(
                              state.coachActiveMessage.customerName.split(" ")[1]
                            )}{" "}
                            {capitalizeFirstLetter(
                              state.coachActiveMessage.customerName.split(" ")[0]
                            )}
                          </h4>
                          <p>{state.coachActiveMessage.emailAddress}</p>
                        </div>
                      </div>
                    </div>
                    <div style={{padding: "2.4rem", paddingTop: "6rem"}}>
                      {getCoachMessageHistoryQuery.data?.map((msgHistory) =>
                        userInfo.user.email === msgHistory.sender ? (
                          <div key={msgHistory.id} ref={scrollRef}>
                            <StyleSingleChatBox type={"sent"}>
                              <StyledSentMessageBox>
                                <p>{msgHistory.message}</p>
                              </StyledSentMessageBox>
                              <p className="chat-time">
                                {moment(msgHistory.createdAt).fromNow()}
                              </p>
                            </StyleSingleChatBox>
                          </div>
                        ) : (
                          <div key={msgHistory.id} ref={scrollRef}>
                            <StyleSingleChatBox type={"received"}>
                              <StyledReceivedMessageBox>
                                <p>{msgHistory.message}</p>
                              </StyledReceivedMessageBox>
                              <p className="chat-time">
                                {moment(msgHistory.createdAt).fromNow()}
                              </p>
                            </StyleSingleChatBox>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div style={{padding: "2.4rem"}}>
                    <Input
                      placeholder="Type message"
                      suffix={
                        <Tooltip title="Send">
                          <SendOutlined
                            onClick={handleEnter}
                            style={{color: "#9747ff", cursor: "pointer"}}
                          />
                        </Tooltip>
                      }
                      value={typedMessage}
                      onChange={handleChange}
                      onKeyUp={handleEnterKey}
                    />
                  </div>
                </Spin>
              </>
            )}
          </>
        )}

        {/* </StyledChatListBox> */}
        {/* {state.activeMessage && (
            <div style={{padding: "2.4rem"}}>
              <Input
                placeholder="Type message"
                suffix={
                  <Tooltip title="Send">
                    <SendOutlined
                      onClick={handleEnter}
                      style={{color: "#9747ff", cursor: "pointer"}}
                    />
                  </Tooltip>
                }
                value={typedMessage}
                onChange={handleChange}
                onKeyUp={handleEnterKey}
              />
            </div>
          )} */}
      </div>
    </StyledChatBox>
  );
};

export {ChatList};
