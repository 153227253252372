import {Button, Col, Row, Select, SelectProps, Space, message} from "antd";
import {StyledAvailabiltyFormWrapper} from "../availability.style";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  BufferTimeFormSchema,
  BufferTimeFormValuesType,
} from "../availability-validation-schema";
import {CustomInputComponent} from "../../../input/input-component";
import {StyledServiceButtonWrapper} from "../../../../styles/onboarding/onboarding";
import {useAddBufferTime} from "../../../../react-query-hooks/availability";
import {useGetProfileContext} from "../../../../context/profile/profile-context";

export function BufferTime() {
  const bookingIntervalOptions: SelectProps["options"] = [
    {
      value: "30",
      label: "None",
    },

    {
      value: "120",
      label: "2 hours",
    },
    {
      value: "240",
      label: "4 hours",
    },
    {
      value: "480",
      label: "8 hours",
    },
    {
      value: "720",
      label: "12 hours",
    },
    {
      value: "960",
      label: "16 hours",
    },
    {
      value: "1440",
      label: "24 hours",
    },
    {
      value: "custom",
      label: "Custom",
    },
  ];
  const {state: profileState} = useGetProfileContext();
  const addBufferTimeMutation = useAddBufferTime();

  const handleBufferTime = () => {
    const bufferTimeValue = bookingIntervalOptions.find(
      (option) =>
        option.value?.toString() === profileState.profileData.buffer_time?.toString()
    );

    if (bufferTimeValue) {
      return bufferTimeValue.value?.toString();
    } else {
      return "custom";
    }
  };
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    clearErrors,
    setValue,
  } = useForm<BufferTimeFormValuesType>({
    resolver: yupResolver(BufferTimeFormSchema),
    mode: "onChange",
    defaultValues: {
      bufferTime: handleBufferTime(),
      customBufferTime:
        handleBufferTime() === "custom"
          ? profileState.profileData.buffer_time
            ? profileState.profileData.buffer_time / 60
            : 0
          : undefined,
    },
  });
  const onSubmit = async (values: BufferTimeFormValuesType) => {
    // console.log({values});
    const payload = {
      bufferTime:
        values.bufferTime === "custom" && values.customBufferTime
          ? Number(values.customBufferTime) * 60
          : Number(values.bufferTime),
    };

    addBufferTimeMutation.mutate(payload, {
      onSuccess() {
        message.success("Your buffer time has been updated successfully!");
      },
    });
  };
  return (
    <StyledAvailabiltyFormWrapper>
      <div>
        <Space size={16} direction="vertical" style={{display: "flex"}}>
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: 600,
            }}
          >
            Booking notice period
          </p>
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: 400,
            }}
          >
            Set a minimum notice period for your bookings. Your customers can only book
            calls at least this far in advance.{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={[10, 24]}>
              <Col span={24} sm={12}>
                <Controller
                  control={control}
                  name="bufferTime"
                  render={({field: {onChange, value}}) => (
                    <>
                      <Select
                        size="large"
                        // defaultValue="lucy"
                        value={value}
                        style={{width: 282}}
                        onChange={(v) => {
                          clearErrors("customBufferTime");
                          setValue("customBufferTime", undefined);
                          onChange(v);
                        }}
                        options={bookingIntervalOptions}
                        placeholder="Select time"
                        status={errors.bufferTime && "error"}
                      />
                      {errors.bufferTime?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.bufferTime?.message}
                        </div>
                      )}
                    </>
                  )}
                />
              </Col>
              {watch("bufferTime") === "custom" && (
                <Col span={24} sm={12}>
                  <CustomInputComponent
                    id="customBufferTime"
                    name="customBufferTime"
                    type="number"
                    //   label="Call duration (In minutes)"
                    placeholder="Booking notice in hours"
                    error={errors.customBufferTime?.message}
                    control={control}
                  />
                </Col>
              )}
            </Row>
            <StyledServiceButtonWrapper style={{marginTop: "2.4rem"}}>
              <div>
                <Button
                  loading={addBufferTimeMutation.isLoading}
                  block
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={
                    Object.keys(errors).length > 0 || Object.values(watch()).length < 1
                  }
                >
                  Submit
                </Button>
              </div>
              {/* <div>
                <Button
                  onClick={() => reset()}
                  block
                  shape="round"
                  type="text"
                  danger
                  size="large"
                >
                  Discard
                </Button>
              </div> */}
            </StyledServiceButtonWrapper>
          </form>
        </Space>
      </div>
    </StyledAvailabiltyFormWrapper>
  );
}
