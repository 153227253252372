import {StyledPricingOption} from "../../components/home/pricing/pricing.style";
import {
  AustraliaFlagIcon,
  BrazilFlagIcon,
  BulgariaFlagIcon,
  CanadaFlagIcon,
  CroatiaFlagIcon,
  CzechFlagIcon,
  DenmarkFlagIcon,
  EuroFlagIcon,
  GbpFlagIcon,
  GhanaFlagIcon,
  GibraltarFlagIcon,
  HongKongFlagIcon,
  JapanFlagIcon,
  KenyaFlagIcon,
  MaltaFlagIcon,
  MexicoFlagIcon,
  NewZealandFlagIcon,
  NigeriaFlagIcon,
  NorwayFlagIcon,
  PolandFlagIcon,
  RomaniaFlagIcon,
  SingaporeFlagIcon,
  SouthAfricaFlagIcon,
  SwedenFlagIcon,
  SwitzerlandFlagIcon,
  TanzaniaFlagIcon,
  ThailandFlagIcon,
  UgandaFlagIcon,
  UsaFlagIcon,
} from "../../components/icons/icons";

export type PricingOptionValues = "naira" | "african" | "stripe";

export const africanCurrencies = ["kes", "ghc", "ugx", "tsz", "zar"];

export const pricingOptions = [
  {
    value: "naira",
    label: "Nigerian Naira",
    flag: <NigeriaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "usd",
    label: "United States Dollar",
    flag: <UsaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "gbp",
    label: "Pound Sterling",
    flag: <GbpFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "eur",
    label: "Euro",
    flag: <EuroFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "cad",
    label: "Canadian Dollar",
    flag: <CanadaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "kes",
    label: "Kenyan Shilling",
    flag: <KenyaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "ghc",
    label: "Ghanaian Cedis",
    flag: <GhanaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "ugx",
    label: "Ugandan Shilling",
    flag: <UgandaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "tsz",
    label: "Tanzanian Shilling",
    flag: <TanzaniaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "zar",
    label: "South African Rand",
    flag: <SouthAfricaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "aud",
    label: "Australian Dollar",
    flag: <AustraliaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "brl",
    label: "Brazilian Real",
    flag: <BrazilFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "bgn",
    label: "Bulgarian Lev",
    flag: <BulgariaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "hrk",
    label: "Croatian Kuna",
    flag: <CroatiaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "czk",
    label: "Czech Koruna",
    flag: <CzechFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "dkk",
    label: "Danish Krone",
    flag: <DenmarkFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "gip",
    label: "Gibraltar Pound",
    flag: <GibraltarFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "hkd",
    label: "Hong Kong Dollar",
    flag: <HongKongFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "jyp",
    label: "Japanese Yen",
    flag: <JapanFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "chf",
    label: "Swiss Franc",
    flag: <SwitzerlandFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "mtl",
    label: "Maltese Lira",
    flag: <MaltaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "mxn",
    label: "Mexican Peso",
    flag: <MexicoFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "nzd",
    label: "New Zealand Dollar",
    flag: <NewZealandFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "nok",
    label: "Norwegian Kronar",
    flag: <NorwayFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "pln",
    label: "Polish Zloty",
    flag: <PolandFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "rol",
    label: "Romanian Leu",
    flag: <RomaniaFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "sdg",
    label: "Singapore Dollar",
    flag: <SingaporeFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "sek",
    label: "Swedish Kronar",
    flag: <SwedenFlagIcon className="pricing-icon-flag" />,
  },
  {
    value: "thb",
    label: "Thai Baht",
    flag: <ThailandFlagIcon className="pricing-icon-flag" />,
  },
];
