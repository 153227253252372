import {Alert, Space, Spin} from "antd";
import {useGetPastCalls} from "../../../react-query-hooks/booking";
import {FullPageLoader} from "../../full-page-loader";
import {LoadingSpinner} from "../../icons/icons";
import {StyledNoUpcomingCallBox} from "../upcoming-calls/upcoming-calls.style";
import {PastCallCard} from "./past-call-card";
import {useState} from "react";
import {CustomPagination} from "../../pagination";

const PastCallsComponent = () => {
  const [page, setPage] = useState(1);
  const pastCallsQueryInfo = useGetPastCalls(page);

  if (pastCallsQueryInfo.isLoading) {
    return <FullPageLoader />;
  }

  if (
    pastCallsQueryInfo.isError &&
    pastCallsQueryInfo.error.message !==
      "Sorry, you currently do not have any past/completed bookings!"
  ) {
    return (
      <Alert
        message="Error"
        description={pastCallsQueryInfo.error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      {pastCallsQueryInfo.data && pastCallsQueryInfo.data.data.bookings.length > 0 ? (
        <Spin
          spinning={pastCallsQueryInfo.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <Space
            direction="vertical"
            size={42}
            style={{
              display: "flex",
            }}
          >
            {pastCallsQueryInfo.data.data.bookings.map((pastCall) => (
              <PastCallCard key={pastCall.id} call={pastCall} />
            ))}
            <CustomPagination
              isPreviousData={pastCallsQueryInfo.isPreviousData}
              page={page}
              paginationMeta={pastCallsQueryInfo.data.meta}
              setPage={setPage}
            />
          </Space>
        </Spin>
      ) : (
        <Spin
          spinning={pastCallsQueryInfo.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <StyledNoUpcomingCallBox>
            <p className="info-text">You do not have past calls at the moment.</p>
          </StyledNoUpcomingCallBox>
        </Spin>
      )}
    </>
  );
};

export {PastCallsComponent};
