import {Space} from "antd";
import {
  StyledLiveClassesContentSectionWrapper,
  StyledLiveClassesSectionWrapper,
} from "./live-classes-section.style";
import {PublicProfileLiveClassesSectionCard} from "./card";
import {PublicProfileApiResponseEvent} from "../../../react-query-hooks/live-events/live-classes";
import {SortPublicProfileLiveClassByTime} from "../../../services/helpers";

export type TLiveClassesSectionProps = {
  classes?: PublicProfileApiResponseEvent[];
  username: string;
  coachTimezone: string;
};

const LiveClassesSection = ({
  classes,
  coachTimezone,
  username,
}: TLiveClassesSectionProps) => {
  return (
    <StyledLiveClassesSectionWrapper>
      <h1 className="live-classes-section-heading">Live events</h1>
      {/* {!classes || classes.length < 1 ? (
        <StyledNoUpcomingCallBox>
          <p className="info-text">
            This profile does not have any live class at the moment. Check again soon!
          </p>
        </StyledNoUpcomingCallBox>
      ) : ( */}
      <StyledLiveClassesContentSectionWrapper>
        <Space
          direction="vertical"
          size={42}
          style={{
            display: "flex",
          }}
        >
          {SortPublicProfileLiveClassByTime(classes)?.map((liveClass) => (
            <PublicProfileLiveClassesSectionCard
              key={liveClass.id}
              currency={liveClass.callPriceCurrency}
              date={liveClass.session_info[0].startDate}
              time={liveClass.session_info[0].startTime}
              description={liveClass.description}
              image={liveClass.cover_image}
              name={liveClass.name}
              price={liveClass.callPrice}
              username={liveClass.hostProfile ? liveClass.hostProfile.username : username}
              coachTimezone={coachTimezone}
              slug={liveClass.slug}
              statusName={liveClass.statusName}
              id={liveClass.id}
              is_co_host={liveClass.is_co_host}
            />
          ))}
        </Space>
      </StyledLiveClassesContentSectionWrapper>
      {/* )} */}
    </StyledLiveClassesSectionWrapper>
  );
};

export {LiveClassesSection};
