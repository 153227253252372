import React from "react";
import {UseFieldArrayAppend, UseFieldArrayRemove} from "react-hook-form";
import {IEditProfileFormValues} from "../../components/profile/edit-profile/edit-profile";
import {Social, TProfileApiResponse} from "../../redux/profile/i-profile-1";

export const SocialOptions = [
  {
    name: "Twitter",
  },
  {
    name: "Facebook",
  },
  {
    name: "LinkedIn",
  },
  {
    name: "Instagram",
  },
  {
    name: "TikTok",
  },
  {
    name: "YouTube",
  },
  {
    name: "Dribbble",
  },
  {
    name: "Behance",
  },
  {
    name: "Discord",
  },
  {
    name: "Twitch",
  },
  {
    name: "Github",
  },
  {
    name: "Website",
  },
];

export const buildSocialsObject = (profileData: TProfileApiResponse) => {
  const socialData = [
    ...(profileData.twitter
      ? [
          {
            name: "Twitter",
            link: profileData.twitter,
          },
        ]
      : []),
    ...(profileData.facebook
      ? [
          {
            name: "Facebook",
            link: profileData.facebook,
          },
        ]
      : []),
    ...(profileData.instagram
      ? [
          {
            name: "Instagram",
            link: profileData.instagram,
          },
        ]
      : []),
    ...(profileData.tiktok
      ? [
          {
            name: "Tiktok",
            link: profileData.tiktok,
          },
        ]
      : []),
    ...(profileData.discord
      ? [
          {
            name: "Discord",
            link: profileData.discord,
          },
        ]
      : []),
    ...(profileData.youtube
      ? [
          {
            name: "Youtube",
            link: profileData.youtube,
          },
        ]
      : []),
    ...(profileData.dribble
      ? [
          {
            name: "Dribbble",
            link: profileData.dribble,
          },
        ]
      : []),
    ...(profileData.behance
      ? [
          {
            name: "Behance",
            link: profileData.behance,
          },
        ]
      : []),
    ...(profileData.twitch
      ? [
          {
            name: "Twitch",
            link: profileData.twitch,
          },
        ]
      : []),
    ...(profileData.github
      ? [
          {
            name: "Github",
            link: profileData.github,
          },
        ]
      : []),
    ...(profileData.website
      ? [
          {
            name: "Website",
            link: profileData.website,
          },
        ]
      : []),
    ...(profileData.linkedin
      ? [
          {
            name: "Linkedin",
            link: profileData.linkedin,
          },
        ]
      : []),
  ];

  return socialData;
};

export const randFunc = (
  funcToSetState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // console.log("clicked");
  funcToSetState(true);
};

export const updateSocialOptions = (socialArray: Social[]) => {
  const alreadyPickedSocials = socialArray.map((socialObj) =>
    socialObj.name.toLowerCase()
  );
  const newSocialOptions = SocialOptions.filter(
    (socialOption) => !alreadyPickedSocials.includes(socialOption.name.toLowerCase())
  );

  return newSocialOptions;
};

export const updateSocialOptionsState = (
  socialArray: Social[],
  funcToSetState: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >
) => {
  const newSocialOptions = updateSocialOptions(socialArray);
  funcToSetState(newSocialOptions);
};

export const handleAddNewSocialLink = (
  socialArray: Social[],
  funcToSetState: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >,
  funcToAppendNewLink: UseFieldArrayAppend<IEditProfileFormValues, "socials">
) => {
  const newSocialOptions = updateSocialOptions(socialArray);
  const copyNewSocialOptions = [...newSocialOptions];
  newSocialOptions.shift();
  funcToSetState(newSocialOptions);
  funcToAppendNewLink({name: copyNewSocialOptions[0].name, link: ""});
};

export const handleRemoveSocialLink = (
  socialArray: Social[],
  funcToSetState: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >,
  funcToRemoveSocialLink: UseFieldArrayRemove,
  index: number
) => {
  funcToRemoveSocialLink(index);
  updateSocialOptionsState(socialArray, funcToSetState);
};

export const handleSocialSelectOnChange = (
  socialArray: Social[],
  funcToSetState: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
      }[]
    >
  >,
  onChangeFunc: any,
  value: string
) => {
  onChangeFunc(value);
  const socials = socialArray;
  const alreadyPickedSocials = socials.map((social) => social.name.toLowerCase());
  const newSocialOptions = SocialOptions.filter(
    (socialOption) =>
      !alreadyPickedSocials.includes(socialOption.name.toLowerCase()) &&
      socialOption.name.toLowerCase() !== value
  );
  funcToSetState(newSocialOptions);
};
