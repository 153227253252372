import styled, {css} from "styled-components";
import {NavLink as Link} from "react-router-dom";

export const MobileNavContainer = styled.div<{
  menuActive: boolean;
}>`
  display: none;

  @media screen and (max-width: 1025px) {
    background-color: #fff;
    position: fixed;
    top: 8.1rem;
    height: 100vh;
    box-shadow: rgb(0 0 0 / 20%) -4px 20px 10px;
    // border: 1px solid rgba(101, 100, 100, 0.1);
    z-index: 10;
    overflow: hidden;
    transition: left 0.3s ease-in-out 0s, opacity 0.4s ease-in-out 0.1s;
    opacity: 0;
    left: -1000px;
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;

    ${({menuActive}) =>
      menuActive &&
      css`
        left: 0px;
        opacity: 1;
      `}
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const StyledMobileNavbarMenuList = styled.ul`
  list-style: none;
  margin: 1rem 0;
  padding: 0;
`;

export const StyledMobileNavbarMenuListItem = styled.li`
  display: block;
  .navbar-menu-list-item-link {
    text-decoration: none;
    padding: 0 3rem;
    color: #000;
    display: block;
    margin: 0 auto;
    font-weight: 700;
    font-size: 1.8rem;

    &.active {
      color: #9747ff;
      font-weight: 700;
    }
    &:hover {
      color: #9747ff;
    }

    @media (max-width: 1025px) {
      padding: 1.5rem 1rem !important;
      max-width: 90%;
    }
  }
`;

export const StyledMobileNavLinkItem = styled(Link)`
  text-decoration: none;
  padding: 0 3rem;
  color: #000;
  display: block;
  margin: 0 auto;
  font-weight: 700;
  font-size: 1.8rem;

  &.active {
    color: #9747ff;
    font-weight: 700;
  }

  @media (max-width: 1025px) {
    padding: 1.5rem 1rem !important;
    max-width: 90%;
  }
`;
export const StyledMobileNavLinkItemCTA = styled.div`
  // padding: 0 3rem;

  max-width: 128rem;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 1024px) {
    padding: 1.5rem 0 !important;
    max-width: 102.4rem;
  }
  @media (max-width: 767px) {
    max-width: 76.7rem;
  }
`;
