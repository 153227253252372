import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button shape="round" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      }
    />
  );
};

export default PageNotFound;
