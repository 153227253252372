type TLiveClassLinkPayload = {
  username: string;
  slug: string;
  eventId: string | number;
  windowNav?: boolean;
};
type TLiveClassBookingLinkPayload = {
  username: string;
  slug: string;
  eventId: string;
};

export function handleLiveClassLink({
  eventId,
  slug,
  username,
  windowNav,
}: TLiveClassLinkPayload) {
  const baseLink = `/${username}/live-classes/details?slug=${slug}&eventId=${eventId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}
export function handleLiveClassBookingLink({
  eventId,
  slug,
  username,
}: TLiveClassBookingLinkPayload) {
  const link = `/${username}/live-classes/details/booking?slug=${slug}&eventId=${eventId}`;

  return link;
}

export function handleLiveClassSuccessBookingLink({
  eventId,
  slug,
  username,
  windowNav,
}: TLiveClassLinkPayload) {
  const baseLink = `/${username}/live-classes/booking/success?slug=${slug}&eventId=${eventId}`;

  if (windowNav) {
    const link = `${window.location.origin}${baseLink}`;
    return link;
  } else {
    return baseLink;
  }
}
