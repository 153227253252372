import {
  DollarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import {Space} from "antd";
import {useNavigate} from "react-router-dom";
import {TServiceApiResponse} from "../../../redux/service/i-service";
import {
  StyledServiceCardExtraInfo,
  StyledServiceCardWrapper,
} from "./services-section.style";

const ServiceCard = ({
  service,
  username,
}: {
  service: TServiceApiResponse;
  username: string;
}) => {
  const navigate = useNavigate();
  let stripedHtml = service.description.replace(/<[^>]+>/g, "");
  return (
    <StyledServiceCardWrapper
      onClick={() =>
        navigate(
          `/${username}/services/details?slug=${service.slug}&serviceId=${service.id}`
        )
      }
      hoverable
      title={service.name}
    >
      <Space direction="vertical" size={16} style={{display: "flex"}}>
        {service.cover_picture && (
          <div className="service-image-wrapper">
            <img
              src={service.cover_picture}
              alt={service.name}
              className="service-image"
            />
          </div>
        )}

        <p className="service-desc">{stripedHtml}</p>
        <StyledServiceCardExtraInfo>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="service-card-extra-info-item-value">
                {service.callPrice !== 0 && service.callPriceCurrency}{" "}
                {service.callPrice === 0
                  ? "Free"
                  : service.callPrice.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              {service.is_bundle ? (
                <>
                  <RetweetOutlined style={{color: "#7939CC"}} />
                  <span className="service-card-extra-info-item-value">
                    {service.no_of_sessions} Sessions - {service.callDuration} Minutes
                    each
                  </span>
                </>
              ) : (
                <>
                  <HourglassOutlined style={{color: "#7939CC"}} />
                  <span className="service-card-extra-info-item-value">
                    {service.callDuration} Minutes
                  </span>
                </>
              )}
            </Space>
          </div>
          <div className="service-card-extra-info-item">
            <Space size={10}>
              {service.videoLocation ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="service-card-extra-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="service-card-extra-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
        </StyledServiceCardExtraInfo>
      </Space>
    </StyledServiceCardWrapper>
  );
};

export {ServiceCard};
