export function TermsSubheading() {
  return (
    <div>
      <p
        style={{
          fontSize: "1.6rem",
        }}
      >
        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT
        INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.
      </p>
    </div>
  );
}
