import {DollarOutlined, CalendarOutlined} from "@ant-design/icons";
import {Space, Button, Row, Col, Tag} from "antd";
import {
  StyledLiveClassesCardWrapper,
  StyledLiveClassesCardContent,
} from "../live-classes-section.style";
import {useNavigate} from "react-router-dom";
import momentTimezone from "moment-timezone";
import {handleLiveClassLink} from "../../../../utils/live-class";
import moment from "moment";

export type TPublicProfileLiveClassesSectionCard = {
  image: string;
  description: string;
  currency: string;
  price: string | number;
  date: string;
  time: string;
  name: string;
  coachTimezone: string;
  username: string;
  slug: string;
  statusName: "Paused" | "Active" | "In Progress";
  id: number;
  is_co_host: boolean;
};

const PublicProfileLiveClassesSectionCard = ({
  currency,
  description,
  date,
  image,
  price,
  time,
  name,
  coachTimezone,
  username,
  slug,
  statusName,
  id,
  is_co_host,
}: TPublicProfileLiveClassesSectionCard) => {
  const navigate = useNavigate();
  const customerTimezone = momentTimezone.tz.guess(true);
  let stripedHtml = description.replace(/<[^>]+>/g, "");
  const eventDescription = stripedHtml;
  const classDate = moment(date).format("ddd, MMM DD YYYY");
  return (
    <StyledLiveClassesCardWrapper
      hoverable
      title={name}
      onClick={() =>
        navigate(
          handleLiveClassLink({
            eventId: id,
            slug,
            username,
          })
        )
      }
    >
      <StyledLiveClassesCardContent>
        <div className="class-card-image-wrapper">
          <img className="class-card-image" src={image} alt={name} />
        </div>
        <p className="class-card-info-text">{eventDescription}</p>
        <div className="class-card-extra-info">
          <div>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span>
                {price === 0 ? "Free" : currency + " " + price.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div>
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span>
                {classDate}, {time}({coachTimezone}){" "}
              </span>
            </Space>
          </div>
          {/* <div>
                <Space size={10}>
                  <UsergroupAddOutlined style={{color: "#7939CC"}} />
                  <span>120 attending</span>
                </Space>
              </div> */}
        </div>
        {is_co_host && (
          <div>
            <Tag color="green">co-host</Tag>
          </div>
        )}
        <div className="class-card-reserve-btn">
          <Row>
            <Col span={24} md={12}>
              <Button
                onClick={() =>
                  navigate(
                    handleLiveClassLink({
                      eventId: id,
                      slug,
                      username,
                    })
                  )
                }
                shape="round"
                type="primary"
                size="large"
                block
              >
                Reserve your spot
              </Button>
            </Col>
          </Row>
        </div>
      </StyledLiveClassesCardContent>
    </StyledLiveClassesCardWrapper>
  );
};

export {PublicProfileLiveClassesSectionCard};
