import {Button, Space} from "antd";
import {StyledUpcomingCard} from "./card.style";
import {CalendarOutlined, DollarCircleOutlined} from "@ant-design/icons";
import {TLiveClassApiResponse} from "../../../../../react-query-hooks/live-events/live-classes";
import {Link} from "react-router-dom";
import {handleLiveClassLink} from "../../../../../utils/live-class";
import {TLiveEventsUser} from "../../../../../react-query-hooks/live-events/events";
import moment from "moment";

type TUpcomingEventCard = {
  event: TLiveClassApiResponse & TLiveEventsUser;
};
export function UpcomingEventCard({event}: TUpcomingEventCard) {
  let stripedHtml = event.description.replace(/<[^>]+>/g, "");
  const eventDescription = stripedHtml;
  const classDate = moment(event.activeSession.startDate).format("ddd, MMM DD YYYY");
  return (
    <StyledUpcomingCard>
      <div className="upcoming-card-image-wrapper">
        <img className="upcoming-card-image" src={event.cover_image} alt={event.name} />
      </div>
      <p className="upcoming-card-event-title">{event.name}</p>
      <p className="upcoming-card-event-description">{eventDescription}</p>
      <div className="upcoming-card-event-extra-info">
        <div>
          <Space size={10}>
            <CalendarOutlined style={{color: "#7939CC"}} />
            <span className="upcoming-card-event-extra-info-value">
              {classDate}, {event.activeSession.startTime}
            </span>
          </Space>
        </div>
        <div>
          <Space size={10}>
            <DollarCircleOutlined style={{color: "#7939CC"}} />
            <span className="upcoming-card-event-extra-info-value">
              {event.callPrice < 1
                ? "Free"
                : `${event.callPriceCurrency} ${event.callPrice.toLocaleString()}`}
            </span>
          </Space>
        </div>
      </div>
      <div style={{marginTop: "auto"}}>
        <div className="hr-line" />
        <Button
          target="_blank"
          href={handleLiveClassLink({
            eventId: event.id,
            slug: event.slug,
            username: event.profile.username,
            windowNav: true,
          })}
          type="link"
          size="large"
          block
        >
          Reserve a spot
        </Button>
      </div>
    </StyledUpcomingCard>
  );
}
