import * as yup from "yup";
import {isNonEu} from "../../../utils/bank";

const connectBankFormSchema = yup.object().shape({
  countryCode: yup.string().required("Please select your country!"),
  bankCode: yup.string().when("countryCode", (countryCode: any) => {
    if (isNonEu.includes(countryCode)) {
      return yup.string().required("Please select your Bank!");
    }
    return yup.string();
  }),

  // bankBranch: yup.string().when("countryCode", (countryCode: any) => {
  //   if (hasBranch.includes(countryCode)) {
  //     return yup
  //       .string()
  //       .transform((parsedValue, originalValue) =>
  //         originalValue === "" ? null : parsedValue
  //       )
  //       .required("Please select a branch!");
  //   }
  //   return yup.string();
  // }),
  // routingNumber: yup.string().when("countryCode", (countryCode: any) => {
  //   if (isNonEu.includes(countryCode) && isKenyanBank.includes(countryCode)) {
  //     return yup.string().required("Please input your routing number!");
  //   }
  //   return yup.string();
  // }),
  accountNumber: yup.string().when("countryCode", (countryCode: any) => {
    if (isNonEu.includes(countryCode)) {
      return yup.string().required("Please input your account number!");
    }
    return yup.string();
  }),
});

export {connectBankFormSchema};
