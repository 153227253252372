import {DollarOutlined, DownOutlined} from "@ant-design/icons";
import {Space, Button, Select} from "antd";
import {useEffect, useState} from "react";
import {StyledLiveClassInfoWidget, StyledMobileSelectCurrency} from "./widget-info.style";
import {StyledInputLabelWrapper} from "../../../../input/input.style";
import {StyledCustomCurrencySelect} from "../../../../booking/left-column/left-column.style";
import {useProductPurchaseContext} from "../../../../../context/products/product-purchase-context";
import {CurrencyResponse} from "../../../../../redux/service/i-service";
const {Option} = Select;

type TProductDetailsWidgetInfoProps = {
  productCurrencies: CurrencyResponse[];
};

export function ProductDetailsWidgetInfo({
  productCurrencies,
}: TProductDetailsWidgetInfoProps) {
  const {state, dispatch} = useProductPurchaseContext();

  function handleCurrencyListOptions() {
    const mappedCurrencies = productCurrencies.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString("en-US")}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies.length > 0) {
      return mappedCurrencies;
    } else {
      return [
        {
          label: "NGN 0",
          value: "default",
        },
      ];
    }
  }

  const [defaultCurrencyOption, setDefaultCurrencyOption] = useState<string | number>(
    () => handleCurrencyListOptions()[0].value
  );

  useEffect(() => {
    if (state.selectedCurrency) {
      setDefaultCurrencyOption(state.selectedCurrency.value);
    }
  }, [state.selectedCurrency]);

  function handleShowBookingModal() {
    dispatch({
      type: "show-purchase-modal",
      payload: true,
    });
  }

  const handleCurrencyChange = (value: any) => {
    setDefaultCurrencyOption(value);
    const selectedValue = productCurrencies.find((x) => x.id === Number(value));
    const selectedCurrency = {
      label: `${selectedValue?.currency} ${selectedValue?.amount.toLocaleString(
        "en-US"
      )}`,
      value: selectedValue?.id as number,
    };
    dispatch({
      type: "update-selected-currency",
      payload: {
        selectedCurrency,
      },
    });
  };

  return (
    <div>
      <StyledLiveClassInfoWidget className="view-web">
        <div>
          <div className="offering-content-item ">
            <StyledInputLabelWrapper style={{padding: 0}}>
              <label style={{fontSize: "1.6rem"}} id="currency_label" htmlFor="currency">
                {productCurrencies.length > 0 && handleCurrencyListOptions().length > 1
                  ? "Select currency"
                  : "Price"}
              </label>
            </StyledInputLabelWrapper>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              {productCurrencies.length < 1 ? (
                <span className="booking-info-content-item-value">Free</span>
              ) : handleCurrencyListOptions().length > 1 ? (
                <div>
                  <StyledCustomCurrencySelect
                    // labelInValue
                    bordered={false}
                    suffixIcon={
                      <DownOutlined
                        size={15}
                        style={{fontWeight: "bold", color: "#00171F"}}
                      />
                    }
                    onChange={handleCurrencyChange}
                    value={String(defaultCurrencyOption)}
                    size={"large"}
                    defaultValue={defaultCurrencyOption}
                  >
                    {handleCurrencyListOptions().map(
                      (option, optionIdx: number | string) => (
                        <Option key={optionIdx} value={option.value}>
                          {`${option.label}`}
                        </Option>
                      )
                    )}
                  </StyledCustomCurrencySelect>
                </div>
              ) : (
                <span className="booking-info-content-item-value">{`${
                  productCurrencies[0].currency
                } ${productCurrencies[0].amount.toLocaleString("en-US")}`}</span>
              )}
            </Space>
          </div>
        </div>
        <div style={{marginTop: "2rem"}}>
          <Button
            onClick={handleShowBookingModal}
            shape="round"
            type="primary"
            block
            size="large"
          >
            Buy now
          </Button>
        </div>
      </StyledLiveClassInfoWidget>
      <StyledMobileSelectCurrency>
        <div className="offering-content-item">
          <StyledInputLabelWrapper style={{padding: 0}}>
            <label
              style={{fontSize: "1.6rem", fontWeight: "600"}}
              id="currency_label"
              htmlFor="currency"
            >
              {productCurrencies.length > 0 && handleCurrencyListOptions().length > 1
                ? "Select currency"
                : "Price"}
            </label>
          </StyledInputLabelWrapper>
          <Space size={10}>
            <DollarOutlined style={{color: "#7939CC"}} />
            {productCurrencies.length < 1 ? (
              <span className="booking-info-content-item-value">Free</span>
            ) : handleCurrencyListOptions().length > 1 ? (
              <div>
                <StyledCustomCurrencySelect
                  // labelInValue
                  bordered={false}
                  suffixIcon={
                    <DownOutlined
                      size={15}
                      style={{fontWeight: "bold", color: "#00171F"}}
                    />
                  }
                  onChange={handleCurrencyChange}
                  value={String(defaultCurrencyOption)}
                  size={"large"}
                  defaultValue={defaultCurrencyOption}
                  placement="topRight"
                >
                  {handleCurrencyListOptions().map(
                    (option, optionIdx: number | string) => (
                      <Option key={optionIdx} value={option.value}>
                        {`${option.label}`}
                      </Option>
                    )
                  )}
                </StyledCustomCurrencySelect>
              </div>
            ) : (
              <span className="booking-info-content-item-value">{`${
                productCurrencies[0].currency
              } ${productCurrencies[0].amount.toLocaleString("en-US")}`}</span>
            )}
          </Space>
        </div>
        <div style={{width: "100%", maxWidth: "18.2rem", marginLeft: "auto"}}>
          <Button
            onClick={handleShowBookingModal}
            shape="round"
            type="primary"
            block
            size="large"
            style={{height: "5.2rem"}}
          >
            Buy now
          </Button>
        </div>
      </StyledMobileSelectCurrency>
    </div>
  );
}
