import * as yup from "yup";
// const re =
//   /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const re =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const userNameRegex = /^\S*$/;
const urlPattern =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9._~%!$&'()*+,;=:@-]*)*\/?$/;

const step1Schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(userNameRegex, "Spaces are not allowed")
    .required("First name is required"),
  lastName: yup
    .string()
    .trim()
    .matches(userNameRegex, "Spaces are not allowed")
    .required("Last name is required"),
  shortBio: yup.string().trim().required("Short bio is required"),
  country: yup.string().trim().required("Please select a country"),
  username: yup
    .string()
    .trim()
    .matches(userNameRegex, "Spaces are not allowed")
    .lowercase("Only lowercase letters")
    .required("Username is required"),
  shortDescription: yup.string().trim().required("Short description is required"),
  socials: yup.array().of(
    yup.object().shape({
      name: yup.string().trim(),
      // link: yup.string().trim("Spaces are not allowed"),
      link: yup.string().matches(urlPattern, "URL is not valid").trim(),
    })
  ),
});

export {step1Schema};
