import styled from "styled-components";

export const StyledSuccessServiceContent = styled.div`
  .success-text {
    font-weight: 400;
    font-size: 1.4rem;
    color: #00171f;
  }
  .success-share-info {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }
`;
