import {CheckOutlined} from "@ant-design/icons";
import {Row, Col, Tag} from "antd";
import {useScroll, useTransform, motion} from "framer-motion";
import {useRef} from "react";
import {TExtraDataProps} from "../../../../services/home/extras";
import {StyledExtraItemInfo, StyledExtraItemWrapper} from "./extra-item.style";
import {StyledLine} from "../../what-makes-us-special-section/what-makes-us-special-section.style";

const ExtraItem: React.FC<TExtraDataProps> = ({
  imgSrc,
  fallbackImgSrc,
  desc,
  extras,
  title,
  isComingSoon,
  isReverse,
  tag,
}) => {
  const imageTargetRef = useRef<HTMLDivElement>(null);
  const textTargetRef = useRef<HTMLDivElement>(null);
  // const {scrollYProgress} = useScroll({
  //   target: imageTargetRef,
  //   offset: ["start end", "end start"],
  // });
  // const {scrollYProgress: scrollYProgressText} = useScroll({
  //   target: textTargetRef,
  //   offset: ["start end", "end start"],
  // });
  // const imageDisplay = useTransform(scrollYProgress, (display) => {
  //   return display >= 1 ? "absolute" : "relative";
  // });
  // const imageOpacity = useTransform(scrollYProgress, [0, 0.5, 0.9, 1], [1, 1, 0, 0]);
  // const textOpacity = useTransform(scrollYProgressText, [0, 0.5, 0.9, 1], [1, 1, 0, 0]);
  // const targettLeft = useTransform(scrollYProgress, [0, 0.5, 0.9], [0, 0, -1000]);
  // const targettRight = useTransform(scrollYProgressText, [0, 0.5, 0.9], [0, 0, 1000]);
  return (
    <StyledExtraItemWrapper>
      <Row align="middle" gutter={[82, 24]}>
        <Col order={1} lg={{order: isReverse ? 1 : 0, span: 12}} span={24}>
          <motion.div
            ref={imageTargetRef}
            // style={{opacity: imageOpacity, x: targettLeft}}
          >
            {/* <picture>
              <source srcSet={imgSrc} /> */}
            <img
              style={{maxWidth: "100%", height: "auto", display: "inline-block"}}
              src={fallbackImgSrc}
              alt="1-1 calls"
            />
            {/* </picture> */}
            {/* <img
              style={{maxWidth: "100%", height: "auto", display: "inline-block"}}
              src={imgSrc}
              alt="1-1 calls"
            /> */}
          </motion.div>
        </Col>
        <Col order={0} lg={{order: isReverse ? 0 : 1, span: 12}} span={24}>
          <StyledExtraItemInfo
            ref={textTargetRef}
            // style={{opacity: textOpacity, x: targettRight, overflow: "hidden"}}
          >
            <div className="extra-info-item-tag">{tag}</div>
            <h2 className="extra-info-item-title">{title}</h2>

            <p className="extra-info-item-desc">{desc}</p>
            <StyledLine style={{width: "100%", margin: 0}} />
            <Row gutter={[16, 0]}>
              <Col span={24} md={12}>
                <ul className="extra-info-item-list">
                  {extras.slice(0, 3).map((extra) => (
                    <li key={extra} className="extra-info-item-list-item">
                      <span className="extra-icon" style={{marginRight: "1rem"}}>
                        <CheckOutlined style={{fontSize: "1.2rem", color: "#9747ff"}} />
                      </span>
                      <span className="extra-text">{extra}</span>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col span={24} md={12}>
                <ul className="extra-info-item-list">
                  {extras.slice(3, 6).map((extra) => (
                    <li key={extra} className="extra-info-item-list-item">
                      <span className="extra-icon" style={{marginRight: "1rem"}}>
                        <CheckOutlined style={{fontSize: "1.2rem", color: "#9747ff"}} />
                      </span>
                      <span className="extra-text">{extra}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </StyledExtraItemInfo>
        </Col>
      </Row>
    </StyledExtraItemWrapper>
  );
};

export {ExtraItem};
