import {Alert, Button, Col, Row} from "antd";
import {UpcomingEventCard} from "../../upcoming-events/card";
import {StyledUpcomingEventList} from "../creators-upcoming-events.style";
import {useGetUpcomingPaidEvents} from "../../../../../react-query-hooks/live-events/events";
import {UpcomingEventsSkeletonLoader} from "../../upcoming-events/skeleton-loader";
import {StyledNoUpcomingCallBox} from "../../../../calls/upcoming-calls/upcoming-calls.style";
import {StyledMostOutOfCoachliDemoBtn} from "../../most-out-of-coachli-section/most-out-of-coachli-section.style";
import {Fragment, useState} from "react";
import {CustomLoadMoreButton} from "../../../../load-more";

export function UpcomingPaidEvents() {
  const getUpcomingPaidEvents = useGetUpcomingPaidEvents();
  const eventsPerPage = 12;
  const [nextPage, setNextPage] = useState(eventsPerPage);

  if (getUpcomingPaidEvents.isLoading) {
    return (
      <StyledUpcomingEventList>
        <Row gutter={[56, 24]}>
          {Array.from({length: 4}).map((_, index) => (
            <Col span={24} sm={12} md={8} lg={6} key={index}>
              <UpcomingEventsSkeletonLoader />
            </Col>
          ))}
        </Row>
      </StyledUpcomingEventList>
    );
  }
  if (getUpcomingPaidEvents.isError) {
    return (
      <StyledUpcomingEventList>
        <Alert
          message="Error"
          description={getUpcomingPaidEvents.error.message}
          type="error"
          showIcon
        />
      </StyledUpcomingEventList>
    );
  }
  function handleMoreEvents() {
    setNextPage(nextPage + eventsPerPage);
  }

  return (
    <StyledUpcomingEventList>
      <Row gutter={[56, 24]}>
        {getUpcomingPaidEvents.data.pages.length > 0 ? (
          getUpcomingPaidEvents.data.pages.map((group, index) => (
            <Fragment key={index}>
              {group.data.events.map((event) => (
                <Col span={24} sm={12} md={8} lg={6} key={event.id}>
                  <UpcomingEventCard event={event} />
                </Col>
              ))}
            </Fragment>
          ))
        ) : (
          <div style={{width: "100%"}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">No live event at the moment. Check again soon!</p>
            </StyledNoUpcomingCallBox>
          </div>
        )}
      </Row>
      {/* {nextPage < getUpcomingPaidEvents.data.events.length && ( */}
      <StyledMostOutOfCoachliDemoBtn style={{marginTop: "2.1rem"}}>
        <CustomLoadMoreButton
          fetchNextPage={() => getUpcomingPaidEvents.fetchNextPage()}
          hasNextPage={!!getUpcomingPaidEvents.hasNextPage}
          isFetchingNextPage={getUpcomingPaidEvents.isFetchingNextPage}
        />
      </StyledMostOutOfCoachliDemoBtn>
      {/* )} */}
    </StyledUpcomingEventList>
  );
}
