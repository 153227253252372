import React, {createContext, useState, useContext} from "react";

interface IOnboardingDataProviderProps {
  children: any;
}

type Ctx = {
  [key: string]: any; // 👈️ variable key
};

const OnboardingDataContext = createContext<Ctx>({});

export const OnboardingDataProvider: React.FC<IOnboardingDataProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState({});

  const setValues = (values: any) => {
    setData((prevData) => ({
      ...prevData,
      ...values,
    }));
  };

  return (
    <OnboardingDataContext.Provider value={{data, setValues}}>
      {children}
    </OnboardingDataContext.Provider>
  );
};

export const useOnboardingData = () => useContext(OnboardingDataContext);
