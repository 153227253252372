import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageHeader, StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {NoProducts} from "../../../components/products/no-products";
import {ProductList} from "../../../components/products/product-list";
import {useProductsContext} from "../../../context/products/products-context";
import {StyledPageHeaderWrapper} from "../../../components/calls/upcoming-calls/upcoming-calls.style";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {ProductBagIcon, ProductIcon} from "../../../components/icons/icons";
import {ProfileWidget} from "../../../components/calls/upcoming-calls/profile-widget";
import {TourWidget} from "../../../components/widgets/tour-widget";

export function DigitalProductsPage() {
  const {state} = useProductsContext();
  if (!state.products) {
    return <div></div>;
  }
  return (
    <DashboardWrapper pageTitle="Products">
      <StyledPageWrapper>
        <div className="main-area">
          <StyledPageHeaderWrapper>
            <StyledPageHeader>
              Digital products {"  "} <ProductBagIcon />
            </StyledPageHeader>
            {state.products.length > 0 && (
              <div>
                <Link to="/dashboard/products/new">
                  <Button block type="primary" size="large" shape="round">
                    Add new product
                  </Button>
                </Link>
              </div>
            )}
          </StyledPageHeaderWrapper>
          <div style={{marginTop: "4.6rem"}}>
            {/* <ProductMetrics /> */}
            <div>{state.products.length > 0 ? <ProductList /> : <NoProducts />}</div>
          </div>
        </div>
        <div className="widget-area">
          <TourWidget
            textInfo="Watch a video guide on how to create and sell digital products for your audience and clients."
            buttons={[
              <Button
                href="https://www.loom.com/share/31b30bd926f64ac48bcacda15726f83b"
                block
                icon={<ProductIcon />}
                size="large"
                shape="round"
                target="_blank"
              >
                <span>Watch demo video</span>
              </Button>,
            ]}
          />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
}
