import {PropsWithChildren} from "react";
import {createContext} from "../create-context";
import {FullPageLoader} from "../../components/full-page-loader";
import {Button, Result} from "antd";
import {useGetCoachConnectCalendarStatus} from "../../react-query-hooks/connect-calendar/connect-calendar";
import {useGetProfileContext} from "../profile/profile-context";

type TGetCoachConnectCalendarStatusState = {
  connectCalendarStatus: {
    has_access_token: boolean;
    needs_login: boolean;
  };
};

type TGetCoachConnectCalendarStatusContextState = {
  state: TGetCoachConnectCalendarStatusState;
};

const [useContext, ContextProvider] =
  createContext<TGetCoachConnectCalendarStatusContextState>();

export const useGetCoachConnectCalendarStatusContext = useContext;

export const GetCoachConnectCalendarStatusProvider = ({children}: PropsWithChildren) => {
  const {state: profileState} = useGetProfileContext();
  const getConnectCalendarStatusQuery = useGetCoachConnectCalendarStatus(
    profileState.profileData.email
  );

  if (getConnectCalendarStatusQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getConnectCalendarStatusQuery.isError) {
    return (
      <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle="Error fetching profile"
        extra={
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => getConnectCalendarStatusQuery.refetch()}
          >
            Try again
          </Button>
        }
      />
    );
  }

  const state: TGetCoachConnectCalendarStatusState = {
    connectCalendarStatus: getConnectCalendarStatusQuery.data,
  };

  return <ContextProvider value={{state}}>{children}</ContextProvider>;
};
