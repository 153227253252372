import * as yup from "yup";
// const re =
//   /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const re =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const userNameRegex = /^\S*$/;

const step1Schema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required"),
  lastName: yup.string().trim().required("Last name is required"),
  shortBio: yup.string().trim(),
  country: yup.string().trim().required("Please select a country"),
  username: yup
    .string()
    .trim("Spaces are not allowed")
    .matches(userNameRegex, "Spaces are not allowed")
    .lowercase("Only lowercase letters")
    .required("Username is required"),
  shortDescription: yup.string().trim(),
  socials: yup.array().of(
    yup.object().shape({
      name: yup.string().trim(),
      // link: yup.string().trim(),
      link: yup.string().matches(re, "URL is not valid").trim(),
    })
  ),
});

export {step1Schema};
