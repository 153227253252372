import {CreatorsUpcomingEvents} from "../../../components/home/live-events/creators-upcoming-events";
import {LiveEventsHeroSection} from "../../../components/home/live-events/hero-section";
import {MostOutOfCoachliSection} from "../../../components/home/live-events/most-out-of-coachli-section";
import {UpcomingEvents} from "../../../components/home/live-events/upcoming-events";
import {HomeWrapper} from "../../../layouts/home-wrapper/home-wrapper";

export default function LiveEventsPage() {
  return (
    <HomeWrapper>
      <LiveEventsHeroSection />
      <UpcomingEvents />
      <CreatorsUpcomingEvents />
      <MostOutOfCoachliSection />
    </HomeWrapper>
  );
}
