import {Button, Col, message, Row} from "antd";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUpdateSavedCategories} from "../../../react-query-hooks/category";
import {
  StyledOnboardingTag,
  StyledOnboardingTagContainer,
  StyledServiceButtonWrapper,
} from "../../../styles/onboarding/onboarding";

interface IStep3FormProps {
  updateStep: (step: number) => void;
  categories: string[];
  savedCategories: string[];
}

const Step3Form: React.FC<IStep3FormProps> = ({
  updateStep,
  categories,
  savedCategories,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const updateUserSavedCategoriesQueryMutation = useUpdateSavedCategories();

  const navigate = useNavigate();
  useEffect(() => {
    setSelectedCategories([...savedCategories]);
  }, [savedCategories]);

  const toggleSelectedCategory = (categoryName: string) => {
    // console.log(selectedCategories);
    if (!selectedCategories.includes(categoryName) && selectedCategories.length >= 3) {
      message.warning("You can only select 3");
    }
    if (selectedCategories.includes(categoryName)) {
      const filteredCategories = selectedCategories.filter((x) => categoryName !== x);

      setSelectedCategories([...filteredCategories]);
    }
    if (!selectedCategories.includes(categoryName) && selectedCategories.length < 3) {
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  const handleSubmit = () => {
    updateUserSavedCategoriesQueryMutation.mutate([...selectedCategories].toString(), {
      onSuccess: () => {
        navigate("/dashboard/home");
      },
    });
  };
  return (
    // <form style={{marginTop: "2.4rem"}} onSubmit={handleSubmit(onSubmit)}>
    <div style={{marginTop: "2.4rem", marginBottom: "2rem"}}>
      <StyledOnboardingTagContainer>
        {categories.map((category, categoryIdx) => (
          <StyledOnboardingTag
            key={categoryIdx}
            active={selectedCategories.includes(category)}
            onClick={() => toggleSelectedCategory(category)}
          >
            {category}
          </StyledOnboardingTag>
        ))}
      </StyledOnboardingTagContainer>

      <Row gutter={[0, 16]} style={{marginTop: "3.5rem"}}>
        <Col span={24} sm={12}>
          <StyledServiceButtonWrapper>
            <Button
              block
              size="large"
              shape="round"
              type="default"
              htmlType="button"
              onClick={() => updateStep(2)}
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              block
              size="large"
              shape="round"
              type="primary"
              htmlType="button"
              disabled={selectedCategories.length < 1}
              loading={updateUserSavedCategoriesQueryMutation.isLoading}
            >
              Save and complete
            </Button>
          </StyledServiceButtonWrapper>
        </Col>
        {/* <Col span={24} sm={{span: 12}}>
          <Button block size="large" type="link" htmlType="button">
            I’ll do this later
          </Button>
        </Col> */}
      </Row>
    </div>
    // </form>
  );
};

export {Step3Form};
