import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import {AppDispatch, RootState} from "../../redux/store";
import {history} from "./history";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {logoutUser, userLoginLogoutReset} from "../../redux/auth/login/user-login-slice";
import {useEffect} from "react";
import {userAccountActivationLogoutReset} from "../../redux/auth/account-activation/account-activation-slice";
import {userResetPasswordLogoutReset} from "../../redux/auth/forgot-password/forgot-password-slice";
import {userRegisterlogoutReset} from "../../redux/auth/register/user-register-slice";
import {categoryLogoutReset} from "../../redux/category/category-slice";
import {countryLogoutReset} from "../../redux/country/countrySlice";
import {userProfileLogoutReset} from "../../redux/profile/profile-slice";
import {userTimezoneLogoutReset} from "../../redux/timezone/timezone-slice";
import {callLogoutReset} from "../../redux/call/call-slice";
import {message} from "antd";
import {useQueryClient} from "@tanstack/react-query";

const PrivateRoutes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const queryClient = useQueryClient();
  // const {exp} = jwtDecode<JwtPayload>(userInfo?.accessToken); // Returns with the JwtPayload type
  // const expiryDate = exp && new Date(exp * 1000);
  // const expirationTime = exp && exp * 1000 - 60000;
  // console.log(expiryDate);
  // console.log(expirationTime);
  useEffect(() => {
    if (userInfo) {
      const {exp} = jwtDecode<JwtPayload>(userInfo.accessToken); // Returns with the JwtPayload type
      const expirationTime = exp && exp * 1000 - 60000;
      if (expirationTime)
        if (Date.now() >= expirationTime) {
          message.warn("Your session timed-out. Login again");
          queryClient.clear();
          dispatch(userRegisterlogoutReset());
          dispatch(userResetPasswordLogoutReset());
          dispatch(userAccountActivationLogoutReset());
          dispatch(categoryLogoutReset());
          dispatch(countryLogoutReset());
          dispatch(userProfileLogoutReset());
          dispatch(userTimezoneLogoutReset());
          dispatch(callLogoutReset());
          dispatch(userLoginLogoutReset());
          dispatch(logoutUser());
          // return <Navigate to="/login" />;
        }
    }
  }, [userInfo, dispatch]);

  // const isTokenExpired = (token: string) => {
  //   if (token) {
  //     return jwt.verify(token, "SECRET", (err, decoded) => {
  //       return !Boolean(decoded);
  //     });
  //   } else {
  //     return true;
  //   }
  // };

  // if (isTokenExpired(userInfo?.accessToken)) {
  //   dispatch(logoutUser());
  //   return <Navigate to="/login" />;
  // }

  return userInfo?.accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{from: history.location}} />
  );
};

export {PrivateRoutes};
