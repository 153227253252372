import {
  DollarOutlined,
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  NumberOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {Space, Button} from "antd";
import {useState} from "react";
import {TBooking} from "../../../../redux/call/i-call";
import {capitalizeFirstLetter} from "../../../../services/helpers";
// import {CallCardMenu} from "../../upcoming-calls/call-card/call-card-menu";
import {
  StyledCallCard,
  StyledCallCardExtraInfo,
  StyledCardDetailsButtonWrapper,
} from "../../upcoming-calls/call-card/call-card.style";
import {CallDetailsModal} from "../../upcoming-calls/call-card/call-details-modal";
import {PastCallDetailsModal} from "../past-call-details-modal";

const CustomerPastCallCard = ({call}: {call: TBooking}) => {
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState<boolean>(false);

  const showViewDetailsModal = () => {
    setOpenViewDetailsModal(true);
  };

  const handleOk = () => {
    setOpenViewDetailsModal(false);
  };

  const handleCancel = () => {
    setOpenViewDetailsModal(false);
  };

  const callDate = new Date(
    Number(call.booking_year),
    Number(call.booking_month) - 1,
    Number(call.booking_date)
  ).toDateString();

  return (
    <StyledCallCard
      title={`${capitalizeFirstLetter(call.coach_first_name)} ${capitalizeFirstLetter(
        call.coach_last_name
      )} - ${capitalizeFirstLetter(call?.service?.name ?? "null")}`}
      //   extra={<CallCardMenu call={call} />}
    >
      <Space direction="vertical" size={18} style={{display: "flex"}}>
        <p className="call-card-description">{capitalizeFirstLetter(call.notes)}</p>
        <StyledCallCardExtraInfo>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.amount === 0
                  ? "Free"
                  : call.amount.toLocaleString("en-US") +
                    " " +
                    call?.service?.callPriceCurrency}
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">{callDate}</span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <ClockCircleOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">{call.timeslot}</span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call?.service?.callDuration} minutes
              </span>
            </Space>
          </div>
          <div className="phone-number-extra-details">
            <div className="call-card-extra-info-item">
              <Space size={10}>
                {call.booking_type === "video" ? (
                  <>
                    <VideoCameraOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      Video call{" "}
                      {call.is_bundle &&
                        `| ${call.bundle_session} of ${call?.service?.no_of_sessions} sessions`}
                    </span>
                  </>
                ) : (
                  <>
                    <PhoneOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      Phone call{" "}
                      {call.is_bundle &&
                        `| ${call.bundle_session} of ${call?.service?.no_of_sessions} sessions`}
                    </span>
                  </>
                )}
              </Space>
            </div>
            {call.booking_type === "audio" && (
              <div className="call-card-extra-info-item">
                <Space size={10}>
                  <NumberOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">
                    {call.phone_number}
                  </span>
                </Space>
              </div>
            )}
          </div>
        </StyledCallCardExtraInfo>

        <StyledCardDetailsButtonWrapper>
          <div>
            {/* <StyledServiceButtonWrapper> */}
            <Button
              size="large"
              block
              shape="round"
              type="default"
              onClick={showViewDetailsModal}
            >
              View details
            </Button>
          </div>

          {/* </StyledServiceButtonWrapper> */}
        </StyledCardDetailsButtonWrapper>
      </Space>

      <CallDetailsModal
        visibleState={openViewDetailsModal}
        title={`${capitalizeFirstLetter(
          call?.service?.name
        )} with ${capitalizeFirstLetter(call.coach_first_name)} ${capitalizeFirstLetter(
          call.coach_last_name
        )}`}
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button onClick={handleOk} key="okay" type="primary" shape="round">
            Ok
          </Button>,
        ]}
      >
        <PastCallDetailsModal call={call} />
      </CallDetailsModal>
    </StyledCallCard>
  );
};

export {CustomerPastCallCard};
