import {
  StyledHorizontalStepperList,
  StyledHorizontalStepperListContainer,
} from "./horizontal-stepper.style";
import {Step} from "./step";

interface IStepperProps {
  stepArray: {title: string}[];
  currentStep: number;
}

const HorizontalStepper: React.FC<IStepperProps> = ({stepArray, currentStep}) => {
  return (
    <StyledHorizontalStepperListContainer>
      <StyledHorizontalStepperList>
        {stepArray.map(({title}, idx) => (
          <Step key={idx} index={idx + 1} currentStep={currentStep} title={title} />
        ))}
      </StyledHorizontalStepperList>
    </StyledHorizontalStepperListContainer>
  );
};

export {HorizontalStepper};
