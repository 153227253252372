import styled from "styled-components";

export const StyledTestimonialSectionWrapper = styled.section`
  background-color: #f5edff;
`;

export const StyledTestimonialSection = styled.section`
  padding: 6.7rem 0 4.7rem 0;
`;

export const StyledContainer = styled.div`
  max-width: 114rem;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 1024px) {
    max-width: 102.4rem;
  }
  @media (max-width: 767px) {
    max-width: 76.7rem;
  }
`;

export const StyledTestimonialHeader = styled.div`
  text-align: center;
  .section-title {
    font-weight: 700;
    font-size: 1.4rem;
    color: #9747ff;
    text-transform: uppercase;
  }
  .section-header {
    font-weight: 700;
    font-size: 5.2rem;
    color: #0f0049;
    margin-top: 1.2rem;
  }
  .section-sub-text {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    color: #2f2d3b;
  }

  @media (max-width: 500px) {
    .section-header {
      font-size: 3rem;
    }
  }
`;
