import * as yup from "yup";

const urlPattern =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9._~%!$&'()*+,;=:@-]*)*\/?$/;

const serviceFormSchema = yup.object().shape({
  name: yup.string().trim().required("Service name is required"),
  slug: yup.string().trim().required("Service link is required"),
  description: yup.string().trim().required("Description is required"),
  cover_image: yup.string().trim().nullable(),
  callDuration: yup.string().trim().required("Call duration is required"),
  customCallDuration: yup
    .number()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .when("callDuration", (callDuration: any) => {
      if (callDuration === "custom") {
        return yup
          .number()
          .transform((parsedValue, originalValue) =>
            originalValue === "" ? null : parsedValue
          )
          .typeError("Must be a number")
          .min(15, "Must be 15 minutes and above")
          .max(120, "Must be less than 120 minutes")
          .required("Custom duration is required");
      }
      return yup
        .number()
        .transform((parsedValue, originalValue) =>
          originalValue === "" ? null : parsedValue
        )
        .typeError("Must be a number")
        .min(61, "Must be more than 60 minutes")
        .max(120, "Must be less than 120 minutes");
    })
    .min(15, "Must be more than 15 minutes")
    .max(120, "Must be less than 120 minutes")
    .required("Call duration is required")
    .typeError("Must be a number"),
  callPriceType: yup.string().required().oneOf(["paid", "free"], "Select a cost"),
  callPriceCurrency: yup.string().trim().required("Call currency is required"),
  callPrice: yup
    .number()
    .required("Call price is required")
    .min(1, "Can't be less than 1")
    .default(1)
    .typeError("Must be a number"),
  hideServiceFromMenu: yup.bool().default(false),
  request_phone_number: yup.bool().default(false),
  callLocation: yup.string().trim().required("Call location is required"),
  currencies: yup.array().of(
    yup.object().shape({
      callPriceCurrency: yup.string().trim().required("Call currency is required"),
      callPrice: yup
        .number()
        .required("Call price is required")
        .min(1, "Can't be less than 1")
        .default(1)
        .typeError("Must be a number"),
    })
  ),
  isUrlRedirect: yup.bool().default(false),
  url_redirect: yup
    .string()
    .nullable()
    .when("isUrlRedirect", {
      is: (isUrlRedirect: boolean) => isUrlRedirect,
      then: (schema) =>
        schema
          .required("Redirect URL is required")
          .matches(urlPattern, "URL is not valid")
          .trim(),
      otherwise: (schema) => schema.notRequired(),
    }),
  bookingQuestions: yup.array().of(
    yup.object().shape({
      question: yup.string().trim().required("Question is required"),
      required: yup.bool(),
    })
  ),
});

export {serviceFormSchema};
