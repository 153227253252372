import {Card} from "antd";
import styled from "styled-components";

export const StyledDigitalProductsSectionWrapper = styled.section`
  margin-top: 5rem;

  .digital-products-section-heading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  @media (max-width: 500px) {
    .digital-products-section-heading {
      font-size: 2rem;
    }
  }
`;

export const StyledDigitalProductsCardWrapper = styled(Card)`
  background-color: #ffffff;
  /* border: 1px solid #f0f0f0; */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 100%;
  .ant-card-head-title {
    font-weight: 600;
  }

  .product-image-wrapper {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 8px;
    .product-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .product-description {
    display: -webkit-box;
    overflow: hidden;
    /* display 4 lines only */
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .product-price {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;
