import styled from "styled-components";

export const StyledPricingBox = styled.div`
  background-color: rgb(243, 246, 255);
  padding: 5.4rem 0 7.2rem 0;
  border-radius: 8px;
`;
export const StyledPricingBoxTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  padding: 0 4rem;
  width: 100%;
  max-width: 75.1rem;
  margin: 0 auto;
`;
export const StyledPricingBoxTag = styled.div`
  padding: 4px 1.6rem;
  background-color: #e9edff;
  border-radius: 5rem;

  p {
    font-size: 1.4rem;
    color: #9747ff;
  }
`;

export const StyledPricingBoxH2 = styled.div`
  text-align: center;
  h2 {
    font-size: 3.2rem;
    color: #9747ff;
    font-weight: 700;
  }
`;

export const StyledPricingBoxBottom = styled.div`
  padding: 3.5rem 0;

  .pricing-box-bottom-list {
    list-style: none;
    // margin-top: 3.5rem;
    padding: 0;
    .pricing-box-bottom-list-item {
      margin: 1.4rem 0;
      font-size: 1.6rem;
      color: #101a29;
    }
  }
`;
export const StyledPricingOption = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .pricing-option-label {
    color: #1c1726;
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    .pricing-option-label {
      font-size: 1.4rem;
    }
    .pricing-icon-flag svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;
