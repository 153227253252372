import {TestimonialItem} from "../testimonial-item";
import {StyledTestimonialMain} from "./testimonial-content.style";
import Slider, {Settings} from "react-slick";

const settings: Settings = {
  dots: true,
  autoplay: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  speed: 1000,
  autoplaySpeed: 4000,
  arrows: false,
  initialSlide: 0,
  infinite: true,
  responsive: [
    {
      breakpoint: 900 || 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 700 || 600 || 500 || 400 || 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // centerMode: true,
      },
    },
  ],
};

const TESTIMONIAL_DATA = [
  {
    ratingCount: 5,
    review:
      "Automating my one-on-one counselling service with Coachli was the best decision I made this year.  The platform significantly increased my revenue and allowed me to reach more clients than before.",
    imgSrc: "/images/comfort-o.jpg",
    reviewerName: "Comfort O",
    reviewerRole: "Digital creator, brand coach & storyteller",
  },
  {
    ratingCount: 5,
    review:
      "Coachli has been a gem in my coaching career. With its automation feature and user-friendly interface, I am able to focus on coaching my clients without worrying about administrative tasks.",
    imgSrc: "/images/temilade-s.jpg",
    reviewerName: "Temilade S",
    reviewerRole: "Climate education and communications consultant",
  },
  {
    ratingCount: 5,
    review:
      "Coachli has to be one of the most user-friendly platforms I have used. I appreciate the ease of setting up my profile and services, as it simplifies the process, and the payment information setup is a breeze.",
    imgSrc: "/images/gloria-m.jpg",
    reviewerName: "Gloria M",
    reviewerRole: "Finance & creator coach",
  },
  {
    ratingCount: 5,
    review:
      "I have been searching for a cost-effective platform to facilitate consultation calls with my clients and was thrilled to discover Coachli. Coachli's approach eliminates the need for a monthly subscription, which greatly reduces my expenses as a startup entrepreneur.",
    imgSrc: "/images/faith-a.jpg",
    reviewerName: "Faith A",
    reviewerRole: "Content creator & Digital marketer",
  },
  {
    ratingCount: 5,
    review:
      "Before discovering Coachli, I had to rely on multiple platforms to manage my coaching sessions. This process was often time-consuming for both myself and my clients. But with Coachli, everything is streamlined and efficient.",
    imgSrc: "/images/bolanle-banwo.jpg",
    reviewerName: "Bolanle Banwo O",
    reviewerRole: "Brand designer & Design advocate coach",
  },
  {
    ratingCount: 5,
    review:
      "As soon as I started using it, I immediately fell in love with how seamless it is to navigate. The entire process was straightforward, and I was impressed with how quickly I was able to get set up and start earning money for my time",
    imgSrc: "/images/confidence-e.jpg",
    reviewerName: "Confidence E",
    reviewerRole: "Solution focused therapist & Happiness coach",
  },
  {
    ratingCount: 5,
    review:
      "I signed up on Coachli recently and I am absolutely loving it! The platform is incredibly user-friendly, and I have already found it to be a great tool for managing my coaching sessions.",
    imgSrc: "/images/adewale-o.jpg",
    reviewerName: "Adewale O",
    reviewerRole: "Service-based business consultant",
  },
  {
    ratingCount: 5,
    review:
      "I recently checked out the platform and I must say, it is absolutely awesome! The clean interfaces and user experience are top-notch, and it is evident that a lot of effort went into its development. Great work, Coachli!",
    imgSrc: "/images/moses-j.jpg",
    reviewerName: "Moses J",
    reviewerRole: "Product designer",
  },
];

function TestimonialContent() {
  return (
    <StyledTestimonialMain>
      <Slider draggable {...settings}>
        {TESTIMONIAL_DATA.map((testimony) => (
          <TestimonialItem
            key={testimony.review}
            ratingCount={testimony.ratingCount}
            review={testimony.review}
            imgSrc={testimony.imgSrc}
            reviewerName={testimony.reviewerName}
            reviewerRole={testimony.reviewerRole}
          />
        ))}
      </Slider>
    </StyledTestimonialMain>
  );
}

export {TestimonialContent};
