import {Alert} from "antd";
import {useEffect, useState} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import {FullPageLoader} from "../../components/full-page-loader";
import {HorizontalStepper} from "../../components/horizontal-stepper";
import {OnboardingBankAccountStep} from "../../container/onboarding/bank-account-step";
import {OnboardingStep1} from "../../container/onboarding/step-1";
// import {OnboardingStep2} from "../../container/onboarding/step-2";
import {OnboardingStep3} from "../../container/onboarding/step-3";
import {PublicLayout} from "../../layouts/public";
import {useGetSavedCategories} from "../../react-query-hooks/category";
import {useGetCategories} from "../../react-query-hooks/general";
import {StyledContainer, StyledFormWrapper} from "../../styles/onboarding/onboarding";
import ReactGA from "react-ga";
// const {Step} = Steps;

const steps = [
  {
    title: "Profile setup",
  },
  {
    title: "Connect bank account",
  },
  // {
  //   title: "Create a service",
  // },
  {
    title: "Your Interests",
  },
];

const OnboardingPage = () => {
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const getCategoriesQuery = useGetCategories();
  const getSavedCategoriesQuery = useGetSavedCategories();
  const currentUrlStep = searchParams.get("currentStep");

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(
      `${window.location.pathname}, ${
        currentStep === 1
          ? "Profile form"
          : currentStep === 2
          ? "Connect bank"
          : currentStep === 3
          ? "Category form"
          : "Anon step"
      }`
    );
  }, [currentStep]);
  useEffect(() => {
    if (currentUrlStep) {
      updateStep(Number(currentUrlStep));
    }
  }, [currentUrlStep]);
  const updateStep = (step: number) => {
    if (step > 4) {
      return;
    }
    setCurrentStep(step);
  };

  if (getCategoriesQuery.isLoading || getSavedCategoriesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (
    getSavedCategoriesQuery.isError &&
    getSavedCategoriesQuery.error.message !==
      "Sorry, You do not have any category saved yet! Please, select one to continue!"
  ) {
    return (
      <Alert
        message="Error"
        description={getSavedCategoriesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  if (getSavedCategoriesQuery.data && getSavedCategoriesQuery.data.length > 0) {
    return <Navigate to="/dashboard/home" />;
  }

  function handleView() {
    let content;
    switch (currentStep) {
      case 3:
        content = (
          <OnboardingStep3
            categories={getCategoriesQuery.data}
            savedCategories={getSavedCategoriesQuery.data}
            updateStep={updateStep}
            currentStep={currentStep}
          />
        );
        break;
      // case 3:
      //   content = <OnboardingStep2 updateStep={updateStep} currentStep={currentStep} />;
      //   break;
      case 2:
        content = (
          <OnboardingBankAccountStep updateStep={updateStep} currentStep={currentStep} />
        );
        break;
      default:
      case 1:
        content = <OnboardingStep1 updateStep={updateStep} currentStep={currentStep} />;
    }
    return content;
  }
  return (
    <PublicLayout>
      <StyledContainer>
        <HorizontalStepper stepArray={steps} currentStep={currentStep} />
        <StyledFormWrapper>{handleView()}</StyledFormWrapper>
      </StyledContainer>
    </PublicLayout>
  );
};

export default OnboardingPage;
