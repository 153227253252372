import {Controller, FieldError, FieldErrors, Merge} from "react-hook-form";
import {CustomInput} from "./input";

interface ICustomInputComponentProps {
  control?: any;
  error?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  id: string;
  name: string;
  label?: string;
  size?: "large" | "middle" | "small";
  placeholder: string;
  type?: string;
  addonBefore?: any;
}

const CustomInputComponent: React.FC<ICustomInputComponentProps> = ({
  control,
  name,
  id,
  label,
  placeholder,
  error,
  type,
  size,
  addonBefore,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value, ref}}) => (
        <CustomInput
          id={id}
          label={label}
          placeholder={placeholder}
          error={error}
          onChange={onChange}
          size={size}
          type={type}
          addonBefore={addonBefore}
          value={value}
        />
      )}
    />
  );
};

export {CustomInputComponent};
