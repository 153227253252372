import {yupResolver} from "@hookform/resolvers/yup";
import {useFieldArray, useForm, Controller} from "react-hook-form";
import {
  bookingSchema,
  liveClassBookingPhoneNumberRequiredSchema,
} from "./form-validation-schema";
import moment from "moment-timezone";
import {StyledBookingRightColumnHeader} from "../../../../../styles/booking/booking";
import {Space, Row, Col, Button, message, Select, Input} from "antd";
import {CustomInputComponent} from "../../../../../components/input/input-component";
import {CustomCheckboxInput} from "../../../../../components/checkbox";
import {
  TLiveClassBookingApiResponse,
  TLiveClassBookingPayload,
  TPublicProfileLiveClassApiResponse,
} from "../../../../../react-query-hooks/live-events/live-classes";
import {AnalyticsEvent} from "../../../../../utils/analytics";
import {UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {StyledInputLabelWrapper} from "../../../../../components/input/input.style";
import {useEffect} from "react";
import {useLiveClassBookingContext} from "../../../../../context/live-class-booking-context";
import {PaymentOptions} from "../../../../../components/payment-options";
import {handleLiveClassSuccessBookingLink} from "../../../../../utils/live-class";
import {useNavigate} from "react-router-dom";
import {COUNTRY_CODES_WITH_FLAG} from "../../../../../services/country-code";

export type TBookingFormValues = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  numberCode: string;
  phone_number?: string;
  currency: {
    label: string;
    value: string | number;
  };
  bookingQuestions: {question: string; answer: string; required: boolean}[];
};

type TLiveClassBookingStep1Props = {
  eventData: TPublicProfileLiveClassApiResponse;
  createLiveClassBookingQueryMutation: UseMutationResult<
    {
      data: TLiveClassBookingApiResponse;
    },
    AxiosError<unknown, any>,
    TLiveClassBookingPayload,
    () => void
  >;
  updateStep: (step: number) => void;
};

const NewLiveClassBookingStep1 = ({
  eventData,
  createLiveClassBookingQueryMutation,
  updateStep,
}: TLiveClassBookingStep1Props) => {
  const navigate = useNavigate();
  const {state, dispatch} = useLiveClassBookingContext();

  const defaultCallPriceCurrency = eventData.event.callPriceCurrency;
  const defaultCallPrice = eventData.event.callPrice;
  const defaultCallCurrencies = eventData.event.currencies;

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    setValue,
  } = useForm<TBookingFormValues>({
    resolver: yupResolver(
      state.eventData?.event.request_phone_number
        ? liveClassBookingPhoneNumberRequiredSchema
        : bookingSchema
    ),
    mode: "onChange",
    defaultValues: {
      numberCode: "+234",
      currency: state.selectedCurrency
        ? state.selectedCurrency
        : {
            value: "default",
            label: `${defaultCallPriceCurrency} ${defaultCallPrice}`,
          },
    },
  });
  const {fields} = useFieldArray({
    control,
    name: "bookingQuestions",
  });

  function handleCurrencyListOptions() {
    const defaultCurrencyObj = {
      label: `${defaultCallPriceCurrency} ${defaultCallPrice.toLocaleString("en-US")}`,
      value: `default`,
    };
    const mappedCurrencies = defaultCallCurrencies?.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString("en-US")}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies) {
      return [defaultCurrencyObj, ...mappedCurrencies];
    } else {
      return [];
    }
  }

  const handleCurrencyChange = (value: any) => {
    const selectedValue = defaultCallCurrencies?.find((x) => x.id === Number(value));
    let selectedCurrency;

    if (value === "default") {
      selectedCurrency = {
        label: `${defaultCallPriceCurrency} ${defaultCallPrice}`,
        value: "default",
      };
    } else {
      selectedCurrency = {
        label: `${selectedValue?.currency} ${selectedValue?.amount}`,
        value: selectedValue?.id as number,
      };
    }
    dispatch({
      type: "update-selected-currency",
      payload: {
        selectedCurrency,
      },
    });
    // console.log({value, selectedValue, selectedCurrency});
  };

  // console.log({curenOpt: handleCurrencyListOptions()});

  useEffect(() => {
    if (state.selectedCurrency) {
      setValue("currency", state.selectedCurrency);
    }
  }, [state.selectedCurrency, setValue]);

  const onSubmit = async (values: TBookingFormValues) => {
    const customer_timezone = moment.tz.guess(true);
    dispatch({
      type: "update-live-class-form-values",
      payload: values,
    });
    function handleCurrencyAndAmountPayload() {
      const selectedCurrencyId = values.currency.value;
      const selectedCurrencyValue = defaultCallCurrencies?.find(
        (x) => x.id === Number(selectedCurrencyId)
      );

      if (selectedCurrencyValue) {
        return {
          currency: selectedCurrencyValue.currency,
          amount: selectedCurrencyValue.amount,
        };
      } else {
        return {
          currency: defaultCallPriceCurrency,
          amount: defaultCallPrice,
        };
      }
    }
    const payload: TLiveClassBookingPayload = {
      coach_timezone: eventData.timeZone.coachli_timezone,
      customer_timezone: customer_timezone,
      emailAddress: values.emailAddress.toLowerCase(),
      eventId: eventData.event.id,
      firstName: values.firstName,
      lastName: values.lastName,
      slug: eventData.event.slug,
      phone_number: values.phone_number
        ? `${values.numberCode}${values.phone_number}`
        : undefined,
      currency:
        defaultCallPrice === 0
          ? defaultCallPriceCurrency
          : (handleCurrencyAndAmountPayload().currency as string),
      amount:
        defaultCallPrice === 0 ? 0 : Number(handleCurrencyAndAmountPayload().amount),
      reference: state.urlParams.bookingRef,
    };
    createLiveClassBookingQueryMutation.mutate(payload, {
      onSuccess: (data) => {
        // console.log("createBookingMutationData", data);
        dispatch({
          type: "update-live-class-booking-api-response",
          payload: data.data,
        });
        if (state.urlParams.bookingRef || data.data.amount === 0) {
          // message.success("Your Booking has been created successfully!");
          navigate(
            handleLiveClassSuccessBookingLink({
              eventId: String(state.eventData?.event.id),
              slug: state.eventData?.event.slug as string,
              username: state.eventData?.username as string,
            })
          ); //success step
        } else {
          if (data.data.paymentProvider === "flutterwave") {
            AnalyticsEvent("Flutterwave payment", "Flutterwave payment init");
            // console.log("flutterwave", data.data);
            updateStep(2); //flutterwave step
          }
          if (data.data.paymentProvider === "paystack") {
            AnalyticsEvent("Paystack payment", "Paystack payment init");
            // console.log("payastackk", data.data);
            updateStep(3); //paystack step
          }
          if (data.data.paymentProvider === "stripe") {
            AnalyticsEvent("Stripe payment", "Stripe payment init");
            // console.log("stripeguyyy", data);
            // if (data.data.redirectUrl) {
            setTimeout(() => {
              window.location.href = data.data.redirectUrl as string;
            }, 0);
            // }
          }
        }
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <Row gutter={[27, 24]}>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="firstName"
                label="First name"
                placeholder="Enter your first name"
                error={errors.firstName?.message}
                control={control}
                name="firstName"
              />
            </Col>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="last_name"
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                error={errors.lastName?.message}
                control={control}
              />
            </Col>
          </Row>
          <CustomInputComponent
            id="email"
            name="emailAddress"
            label="Enter your email address"
            type="email"
            placeholder="Please enter your email address"
            error={errors.emailAddress?.message}
            control={control}
          />
          {state.eventData?.event.request_phone_number && (
            <div>
              <StyledInputLabelWrapper>
                <label id="phone_number_label" htmlFor="phone_number">
                  Enter phone number
                  {state.eventData?.event.request_phone_number ? null : (
                    <span style={{color: "gray", marginLeft: "3px"}}>(optional)</span>
                  )}
                </label>
              </StyledInputLabelWrapper>
              <Controller
                control={control}
                name="phone_number"
                render={({field}) => (
                  <Input
                    {...field}
                    placeholder="Enter phone number"
                    size="large"
                    status={errors.phone_number?.message && "error"}
                    addonBefore={
                      <Controller
                        control={control}
                        name="numberCode"
                        render={({field}) => (
                          <>
                            <Select
                              {...field}
                              // defaultValue={COUNTRY_CODES[0].dial_code}
                              size="large"
                              optionFilterProp="children"
                              showSearch
                              filterOption={(input, option) => {
                                return option.value
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            >
                              {COUNTRY_CODES_WITH_FLAG.map((code) => (
                                <Select.Option key={code.dialCode} value={code.dialCode}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <img
                                      src={code.flag}
                                      width={30}
                                      height={20}
                                      alt={code.name}
                                    />
                                    <span>{code.dialCode}</span>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </>
                        )}
                      />
                    }
                  />
                )}
              />
              {errors.phone_number?.message && (
                <div role="alert" className="ant-form-item-explain-error">
                  {errors.phone_number?.message}
                </div>
              )}
            </div>
          )}

          {eventData.event.callPrice === 0 ? null : handleCurrencyListOptions().length >
            1 ? (
            <div>
              <StyledInputLabelWrapper>
                <label id="currency_label" htmlFor="currency">
                  Select currency
                </label>
              </StyledInputLabelWrapper>

              <Controller
                control={control}
                name="currency"
                render={({field: {onChange, value, ref}}) => (
                  <Select
                    // suffixIcon={
                    //   <DownOutlined size={15} style={{fontWeight: "bold", color: "#00171F"}} />
                    // }
                    labelInValue
                    onChange={(value) => {
                      // console.log({value});
                      // onChange({value: value.value, label: value.label});
                      handleCurrencyChange(value.value);
                    }}
                    value={value}
                    size={"large"}
                    defaultValue={value}
                    options={handleCurrencyListOptions()}
                  />
                )}
              />
            </div>
          ) : null}

          {fields.length > 0 && (
            <StyledBookingRightColumnHeader>Questions</StyledBookingRightColumnHeader>
          )}

          {fields.map((question, index) => (
            <div key={question.id}>
              <CustomInputComponent
                id={`bookingQuestions[${index}].value`}
                label={question.question}
                name={`bookingQuestions[${index}].answer`}
                placeholder="Enter your answer"
                error={errors.bookingQuestions?.[index]?.answer?.message}
                control={control}
              />
              <div style={{display: "none"}}>
                <CustomCheckboxInput
                  control={control}
                  id={`bookingQuestions[${index}].value`}
                  name={`bookingQuestions[${index}].required`}
                  // error={errors.hideServiceFromMenu?.message}
                  label={`${question.required}`}
                />
              </div>
            </div>
          ))}
          <div>
            <Button
              loading={createLiveClassBookingQueryMutation.isLoading}
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              shape="round"
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              {state.urlParams.bookingRef || eventData.event.callPrice === 0
                ? "Continue"
                : "Continue to payment"}
            </Button>
          </div>
          <PaymentOptions />
        </Space>
      </form>
    </div>
  );
};

export {NewLiveClassBookingStep1};
