import styled from "styled-components";

export const StyledPageHeader = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;

  @media only screen and (max-width: 460px) {
    font-size: 2.4rem;
  }
`;

export const StyledPageWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 3.9rem;
  flex-wrap: wrap;

  .main-area {
    flex: 2 1 0;
    // max-width: 65%;
  }

  .widget-area {
    flex: 1 1 0;
  }

  @media only screen and (max-width: 960px) {
    display: block;

    .widget-area {
      margin-top: 5rem;
    }
  }
`;

export const StyledCallsMain = styled.main`
  position: relative;
  z-index: 2;
  display: grid;
  align-items: start;
  grid-template: "calls widget" auto;
  // "calls availability" 1fr / 2fr 1fr;
  gap: 4rem 3.9rem;
  padding-top: 1.5rem;

  @media only screen and (max-width: 1285px) {
    // grid-template-columns: 1fr;
    // grid-template-rows: auto;
    // grid-template-areas:
    //   "calls"
    //   "widget";
    // // "popular";
    display: block;
  }
`;
export const StyledWidgetArea = styled.section`
  grid-area: widget;
  position: sticky;
  top: 3.2rem;

  .flex-guy {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media only screen and (max-width: 1285px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 650px) {
      display: grid;
      grid-template-columns: auto;
    }
  }
`;
