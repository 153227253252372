import {Image} from "antd";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PublicLayout} from "../../../layouts/public";
import {RootState} from "../../../redux/store";
import {
  StyledFlexContainer,
  StyledVerificationBox,
} from "../../../styles/email-verification/email-verification";

const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const {user} = useSelector((state: RootState) => state.userRegister);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userInfo && userInfo.isCustomer) {
      navigate("/dashboard/customer/calls");
    }
    if (userInfo && !userInfo.isCustomer) {
      navigate("/dashboard/onboarding");
    }
  }, [navigate, userInfo]);
  return (
    <PublicLayout>
      <StyledFlexContainer>
        <StyledVerificationBox>
          {/* <Space direction="vertical" size={16}> */}
          <Image preview={false} src="/images/email-verification.svg" />
          <div className="email-verification-text">
            <h1 className="main-text">Verify your email address</h1>
            <p className="sub-text">
              An email has been sent to <strong>{user?.email}</strong>
            </p>
            <p className="sub-text">
              Please click on the link that has just been sent to your email account to
              verify your email and complete the registration process
            </p>
          </div>
          {/* </Space> */}
        </StyledVerificationBox>
      </StyledFlexContainer>
    </PublicLayout>
  );
};

export default EmailVerificationPage;
