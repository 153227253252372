import * as yup from "yup";

const validLinkRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const urlPattern =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9._~%!$&'()*+,;=:@-]*)*\/?$/;
const productFormSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("Product title is required")
    .typeError("Must be a valid string"),
  description: yup
    .string()
    .trim()
    .required("Description is required")
    .typeError("Must be a valid string"),
  coverImage: yup
    .string()
    .trim()
    .required("Cover image is required")
    .typeError("Cover image is required"),
  callPriceType: yup.string().required().oneOf(["paid", "free"], "Select a cost"),
  isUrlRedirect: yup.bool().default(false),
  url_redirect: yup
    .string()
    .nullable()
    .when("isUrlRedirect", {
      is: (isUrlRedirect: boolean) => isUrlRedirect,
      then: (schema) =>
        schema
          .required("Redirect URL is required")
          .matches(urlPattern, "URL is not valid")
          .trim(),
      otherwise: (schema) => schema.notRequired(),
    }),
  currencies: yup
    .array()
    .of(
      yup.object().shape({
        callPriceCurrency: yup.string().trim().required("Product currency is required"),
        callPrice: yup
          .number()
          .required("Product price is required")
          .min(1, "Can't be less than 1")
          .default(1)
          .typeError("Must be a number"),
      })
    )
    .when("callPriceType", {
      is: (callPriceType: "paid" | "free") => callPriceType === "free",
      then: (schema) => schema.min(0),
      otherwise: (schema) => schema.min(1, "Add a price"),
    }),
});

export {productFormSchema};
