import styled from "styled-components";

export const StyledUpcomingTag = styled.div`
  border-radius: 0px 10rem 10rem 0px;
  background: #3c1c66;

  color: #fff;
  /* font-family: "Charis SIL"; */
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  width: fit-content;
  padding: 0.8rem;

  @media (min-width: 425px) {
    font-size: 2.4rem;
    padding: 1.8rem 2.3rem 1.9rem 3.6rem;
  }
`;

export const StyledUpcomingEventList = styled.div`
  max-width: 92rem;
  margin: 0 auto;
  padding: 2.7rem 0 5.6rem 0;
  @media (min-width: 425px) {
    padding: 2.2rem 0 9.8rem 0;
  }
`;
