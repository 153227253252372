import styled from "styled-components";

export const DashboardContainer = styled.section`
  display: grid;
  grid-template-columns: 23.1rem 1fr;
  grid-template-rows: minmax(0, 100vh) auto;
  width: 100%;

  @media only screen and (max-width: 960px) {
    display: block;
  }
`;

export const DashboardSideBarContainer = styled.div`
  /* grid-area: nav; */
  z-index: 90;
  background-color: #f9fafa;
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
`;

export const DashboardHeaderContainer = styled.header`
  /* grid-area: header; */
  position: sticky;
  top: 0;
  z-index: 91;

  @media only screen and (min-width: 960px) {
    display: none;
  }
`;
export const StyledMainSectionContainer = styled.div`
  padding: 6.4rem 2rem;
  height: 100vh;
  overflow: scroll;

  @media only screen and (max-width: 960px) {
    height: calc(100vh - 58.4px);
    padding-top: 2.4rem;
  }
`;
export const StyledMainSectionInnerContainer = styled.div`
  margin: 0 auto;
  max-width: 110.3rem;
`;

export const DashboardMainContainer = styled.main`
  /* grid-area: main; */
  // background-color: #f9f9f9;
  height: 100vh;
  position: relative;
`;
export const StyledConnectBankMessage = styled.div`
  background-color: #fff1f0;
  padding: 1.6rem;
  margin-bottom: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;

  .message-heading {
    font-size: 1.4rem;
    font-weight: 600;
    color: #00171f;
  }

  .message-text {
    font-size: 1.4rem;
    font-weight: 400;
    color: #00171f;
  }
`;
