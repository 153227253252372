import {Dispatch, PropsWithChildren, useReducer} from "react";
import {createContext} from "../create-context";
import {FullPageLoader} from "../../components/full-page-loader";
import {Alert} from "antd";
import {useGetProducts} from "../../react-query-hooks/products";
import {DashboardWrapper} from "../../layouts/dashboard-wrapper";
import {StyledPageHeader} from "../../styles/dashboard/calls/calls";
import {ProductBagIcon} from "../../components/icons/icons";
import {
  TGetAllProductsState,
  initialGetAllProductsState,
  createProductFormReducer,
  TGetAllProductsActions,
} from "../../reducers/products/get-all-products-reducer";

type TProductsContextState = {
  state: TGetAllProductsState;
  dispatch: Dispatch<TGetAllProductsActions>;
};

const [useContext, ContextProvider] = createContext<TProductsContextState>();

export const useProductsContext = useContext;

export const ProductsProvider = ({children}: PropsWithChildren) => {
  const [reducerState, dispatch] = useReducer(
    createProductFormReducer,
    initialGetAllProductsState
  );
  const getProductsQuery = useGetProducts(reducerState.currentPage);

  if (getProductsQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle="Products">
        <StyledPageHeader>
          Digital products <ProductBagIcon />
        </StyledPageHeader>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (getProductsQuery.isError) {
    return (
      <DashboardWrapper pageTitle="Products">
        <StyledPageHeader>
          Digital products <ProductBagIcon />
        </StyledPageHeader>
        <Alert
          message="Error"
          description={getProductsQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }

  // console.log({getProductsQuery});

  const state: TGetAllProductsState = {
    ...reducerState,
    products: getProductsQuery.data.data.products,
    productMeta: getProductsQuery.data.meta,
    isPreviousData: getProductsQuery.isPreviousData,
    isLoading: getProductsQuery.isFetching,
  };

  return <ContextProvider value={{state, dispatch}}>{children}</ContextProvider>;
};
