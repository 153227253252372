import {
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {Button, Image, Result, Space, Spin} from "antd";
import {useParams} from "react-router-dom";
import {useGetCustomerLiveClassBooking} from "../../../../react-query-hooks/booking";
import {PublicLayout} from "../../../../layouts/public";
import {StyledFlexContainer} from "../../../../styles/email-verification/email-verification";
import {
  StyledJoinCallBox,
  StyledJoinCallExtraInfo,
} from "../../../../styles/meetings/meetings";
import {LoadingSpinner} from "../../../../components/icons/icons";
import {handleMeetingLink} from "../../../../utils/meeting";
import moment from "moment";
import momentTimeZone from "moment-timezone";

const LiveClassJoinMeetingPage = () => {
  const {reference} = useParams();

  const getCustomerLiveClassBookingQuery = useGetCustomerLiveClassBooking({
    reference,
  });
  const tz = momentTimeZone.tz.guess(true);

  if (getCustomerLiveClassBookingQuery.isLoading) {
    return (
      <PublicLayout>
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      </PublicLayout>
    );
  }
  if (getCustomerLiveClassBookingQuery.isError) {
    return (
      <PublicLayout>
        <Result
          status="error"
          title="An Error Occurred"
          subTitle={getCustomerLiveClassBookingQuery.error.message}
          extra={[
            <Button
              onClick={() => getCustomerLiveClassBookingQuery.refetch}
              shape="round"
              type="default"
              key="console"
            >
              Try again
            </Button>,
          ]}
        />
      </PublicLayout>
    );
  }

  const disableButton = (eventDate: Date, eventTime: string) => {
    const classStartTime = moment(`${eventDate} ${eventTime}`, "YYYY-MM-DD h:mm A", true);
    const dateToday = moment(new Date());
    const timeDiff = classStartTime.diff(dateToday, "minutes");

    // console.log({classStartTime, timeDiff});

    if (!getCustomerLiveClassBookingQuery.data?.bookingToken) {
      return true;
    } else {
      return false;
    }
  };

  const handleMeetingPlatform = (meetingPlatform: "dailyCo" | "videoSdk") => {
    if (meetingPlatform.toLowerCase() === "dailyco") {
      return `${getCustomerLiveClassBookingQuery.data.dailyCoUrl}?t=${getCustomerLiveClassBookingQuery.data.bookingToken}`;
    } else {
      return handleMeetingLink("guest", {
        token: getCustomerLiveClassBookingQuery.data.bookingToken,
        roomId: getCustomerLiveClassBookingQuery.data.eventBooking.roomId,
        firstName: getCustomerLiveClassBookingQuery.data.eventBooking.firstName,
        lastName: getCustomerLiveClassBookingQuery.data.eventBooking.lastName,
        meetingTitle: getCustomerLiveClassBookingQuery.data.event.name,
      });
    }
  };
  const classDate = moment(
    getCustomerLiveClassBookingQuery.data?.eventBooking.event_date
  ).format("ddd, MMM DD YYYY");
  return (
    <PublicLayout>
      <StyledFlexContainer>
        <Spin
          spinning={getCustomerLiveClassBookingQuery.isFetching}
          indicator={LoadingSpinner}
          tip="Updating..."
        >
          <StyledJoinCallBox>
            <div>
              <Image
                preview={false}
                //   width={"100%"}
                src="/images/join-meeting-image.svg"
                alt="meeting"
              />
            </div>
            <p className="join-meeting-title">
              "{getCustomerLiveClassBookingQuery.data?.event.name}" with{" "}
              {getCustomerLiveClassBookingQuery.data?.coach.firstName}{" "}
              {getCustomerLiveClassBookingQuery.data?.coach.lastName}
            </p>
            <div>
              <Button
                size="large"
                block
                disabled={disableButton(
                  getCustomerLiveClassBookingQuery.data.eventBooking.event_date,
                  getCustomerLiveClassBookingQuery.data.eventBooking.customer_event_time
                )}
                shape="round"
                type="primary"
                htmlType="button"
                target="_blank"
                href={
                  disableButton(
                    getCustomerLiveClassBookingQuery.data.eventBooking.event_date,
                    getCustomerLiveClassBookingQuery.data.eventBooking.customer_event_time
                  )
                    ? undefined
                    : handleMeetingPlatform(
                        getCustomerLiveClassBookingQuery.data.videoPlatform
                      )
                }
              >
                Join video call now
              </Button>
            </div>
            {getCustomerLiveClassBookingQuery.data.eventBooking.statusName.toLowerCase() ===
            "completed" ? (
              <p>This class has ended</p>
            ) : disableButton(
                getCustomerLiveClassBookingQuery.data.eventBooking.event_date,
                getCustomerLiveClassBookingQuery.data.eventBooking.customer_event_time
              ) ? (
              <p>
                You will be able to join this video call 10 minutes before the start time
              </p>
            ) : null}
            <div>
              <StyledJoinCallExtraInfo>
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <CalendarOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {
                        getCustomerLiveClassBookingQuery.data?.eventBooking
                          .customer_event_time
                      }
                      , {classDate}
                    </span>
                  </Space>
                </div>
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <HourglassOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {getCustomerLiveClassBookingQuery.data?.eventBooking.call_duration}{" "}
                      minutes
                    </span>
                  </Space>
                </div>
              </StyledJoinCallExtraInfo>
              <StyledJoinCallExtraInfo style={{marginTop: "2.5rem"}}>
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <GlobalOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {getCustomerLiveClassBookingQuery.data?.eventBooking
                        .customer_timezone ?? tz}
                    </span>
                  </Space>
                </div>
                <div className="phone-number-extra-details">
                  <div className="call-card-extra-info-item">
                    <Space size={10}>
                      <>
                        <VideoCameraOutlined style={{color: "#7939CC"}} />
                        <span className="call-card-extra-info-item-value">
                          Video call
                        </span>
                      </>
                    </Space>
                  </div>
                </div>
              </StyledJoinCallExtraInfo>
            </div>
          </StyledJoinCallBox>
        </Spin>
      </StyledFlexContainer>
    </PublicLayout>
  );
};

export default LiveClassJoinMeetingPage;
