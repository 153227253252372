import {
  Alert,
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Upload,
  Select,
  message,
  Checkbox,
} from "antd";
import type {RangePickerProps} from "antd/es/date-picker";
import ImgCrop from "antd-img-crop";
import {
  StyledFormWrapper,
  StyledCreateServiceHeadingBox,
  StyledServiceButtonWrapper,
  StyledCallLocationItem,
} from "../../../styles/onboarding/onboarding";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
  useForm,
  useWatch,
  UseFormGetValues,
} from "react-hook-form";
import {liveClassesFormSchema} from "./live-classes-form-validation-schema";
import {yupResolver} from "@hookform/resolvers/yup";
import {CallDurationOptions} from "../../../container/onboarding/step-1/step-1-utils";
import {
  StyledCoverImageWrapper,
  StyledEditorWrapper,
  StyledInputLabelWrapper,
} from "../../input/input.style";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  DeleteFilled,
  DeleteOutlined,
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {CustomInputComponent} from "../../input/input-component";
import {CustomSelectInput} from "../../select";
import moment from "moment";
import {TIMEOPTIONS} from "../../../services/timezones";
import {useEffect, useState} from "react";
import {
  GetLiveClassApiResponse,
  TCreateLiveClassApiPayload,
  TLiveClassApiResponse,
  useCreateLiveClass,
  useDeleteLiveClass,
  useSaveLiveClassAsDraft,
  useUpdateLiveClass,
} from "../../../react-query-hooks/live-events/live-classes";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {API_BASE_URL} from "../../../services/config";
import type {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import type {UploadChangeParam} from "antd/es/upload";
import {CustomCheckboxInput} from "../../checkbox";
import {Currency} from "../../calls/service/service-form/i-service-form";
import {
  handleAddNewCurrency,
  handleCurrencySelectOnChange,
  handleRemoveCurrency,
  updateCurrencyOptionsState,
} from "../../../utils/currency";
import {
  TCurrencyDeletePayload,
  useDeleteCurrency,
} from "../../../react-query-hooks/currency";
import {StyledInputValue} from "./live-classes-form.style";
import {useCurrencyContext} from "../../../context/currency-context";
import {useCreateLiveClassContext} from "../../../context/live-class/create-live-class";
import {GetCoHostsApiResponse} from "../../../react-query-hooks/live-events/co-hosts/co-hosts";
import {CohostList} from "../class-details/add-cohost/cohost-list";
import {LiveClassRescheduleModal} from "../upcoming-classes/reschedule/modal";
const {Option} = Select;

type TLiveClassesFormProps = {
  eventId?: string;
  liveClassData?: GetLiveClassApiResponse;
  formType: "new" | "edit";
  coHosts?: GetCoHostsApiResponse;
};
export type TLiveClassesPayloadFormValues = {
  name: string;
  description: string;
  // topics: {title: string}[];
  topic_type: "single" | "series";
  startDate: string;
  startTime: string;
  coverImage: string;
  duration: string | number;
  customDuration: number | string;
  currencies: Currency[];
  classSeries: {
    name: string;
    startDate: string;
    startTime: string;
    duration: string | number;
    customDuration: number | string;
  }[];
  // co_hosts: CoHost[];
  callPrice: string | number;
  callPriceType: "paid" | "free";
  callPriceCurrency: string;
  attendeesType: "unlimited" | "limited";
  attendees: string;
  isUrlRedirect: boolean;
  url_redirect: string;
  isPauseable: boolean;
  hideFromMenu: boolean;
  request_phone_number: boolean;
};

const LiveClassesForm = ({
  formType,
  eventId,
  liveClassData,
  coHosts,
}: TLiveClassesFormProps) => {
  const navigate = useNavigate();
  const {state} = useCurrencyContext();
  const {state: createLiveClassState, dispatch} = useCreateLiveClassContext();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [currencyOptions, setCurrencyOptions] = useState(() => state.formCurrencies);
  const [uploading, setUploading] = useState(false);
  const createLiveClassMutation = useCreateLiveClass();
  const saveLiveClassAsDraftQueryMutation = useSaveLiveClassAsDraft();
  const updateLiveClassQueryMutation = useUpdateLiveClass();
  const deleteLiveClassQueryMutation = useDeleteLiveClass();
  const deleteCurrencyMutation = useDeleteCurrency();
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [rescheduleModalData, setRescheduleModalData] = useState<
    Partial<{
      session_info: {
        name: string;
        startDate: string;
        startTime: string;
        duration: string | number;
        eventId: string | number;
        customDuration: string | number;
      }[];
    }>
  >();

  function getDefaultSessionInfoValue() {
    if (liveClassData?.topic_type === "single") {
      const foundDurationOption = CallDurationOptions.find(
        (ele) => Number(ele.value) === Number(liveClassData?.session_info[0].duration)
      );
      return {
        startDate: liveClassData?.session_info[0].startDate ?? undefined,
        startTime: liveClassData?.session_info[0].startTime ?? undefined,
        duration:
          foundDurationOption !== undefined ? foundDurationOption.value : "custom",
        customDuration:
          foundDurationOption !== undefined
            ? foundDurationOption.value
            : liveClassData
            ? liveClassData.session_info[0].duration
            : undefined,
      };
    }

    return {
      startDate: undefined,
      startTime: undefined,
      duration: undefined,
      customDuration: undefined,
    };
  }

  function getDefaultClassSeriesValue() {
    if (liveClassData?.topic_type === "series") {
      const classSeriesDefaultValue = liveClassData.session_info.map((session) => ({
        name: session?.name ?? "A session on Coachli",
        startDate: session.startDate,
        startTime: session.startTime,
        duration:
          CallDurationOptions.find((ele) => ele.value === session.duration) !== undefined
            ? session.duration
            : !session.duration
            ? session.duration
            : "custom",
        customDuration: CallDurationOptions.find((ele) => ele.value === session.duration)
          ? 15
          : session.duration,
      }));
      return classSeriesDefaultValue;
    }
  }

  function handleCallPriceType() {
    if (liveClassData && liveClassData.callPrice > 0 && state.connectedBankStatus) {
      return "paid";
    } else if (!liveClassData && state.connectedBankStatus) {
      return "paid";
    } else {
      return "free";
    }
  }

  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
    reset,
  } = useForm<TLiveClassesPayloadFormValues>({
    resolver: yupResolver(liveClassesFormSchema),
    mode: "onChange",
    defaultValues: {
      topic_type: liveClassData?.topic_type ?? "single",
      attendees: liveClassData?.attendees?.toString(),
      callPriceCurrency: liveClassData?.callPriceCurrency || state.formCurrencies[0],
      callPrice: liveClassData?.callPrice === 0 ? 1 : liveClassData?.callPrice,
      callPriceType: handleCallPriceType(),
      coverImage: liveClassData?.cover_image,
      customDuration: getDefaultSessionInfoValue()?.customDuration,
      description: liveClassData?.description,
      duration: getDefaultSessionInfoValue()?.duration,
      name: liveClassData?.name,
      startDate: getDefaultSessionInfoValue()?.startDate,
      startTime: getDefaultSessionInfoValue()?.startTime,
      // topics: getDefaultTopicsValue(),
      // .length < 1 ? undefined : getDefaultTopicsValue(),
      classSeries: getDefaultClassSeriesValue(),
      attendeesType: liveClassData?.attendees === -1 ? "unlimited" : "limited",
      hideFromMenu: liveClassData?.hide_from_menu,
      isPauseable: liveClassData?.statusName === "Paused",
      currencies: liveClassData
        ? liveClassData.currencies.map((currency) => ({
            callPrice: currency.amount,
            callPriceCurrency: currency.currency,
          }))
        : [],
      isUrlRedirect: !!liveClassData?.url_redirect,
      url_redirect: liveClassData?.url_redirect,
    },
  });
  // console.log({getval: getValues()});

  const {
    fields: currencyFields,
    append: appendCurrency,
    remove: removeCurrency,
  } = useFieldArray({
    control,
    name: "currencies",
  });

  const currencyArrayValue = watch("currencies");
  const defaultCallCurrency = watch("callPriceCurrency");

  // TO ALWAYS KEEP THE SOCIAL OPTIONS UP TO DATE WITH NEW VALUES
  useEffect(() => {
    updateCurrencyOptionsState(
      state.formCurrencies,
      currencyArrayValue,
      defaultCallCurrency,
      setCurrencyOptions
    );
  }, [state.formCurrencies, currencyArrayValue, defaultCallCurrency]);

  function handleDeleteCurrency(currency: string, currencyIndex: number) {
    if (liveClassData) {
      const currencyExist = liveClassData.currencies.find((x) => x.currency === currency);
      if (currencyExist) {
        const payload: TCurrencyDeletePayload = {
          currencyId: String(currencyExist.id),
          eventId: String(liveClassData.id),
          module: "live_classes",
        };
        deleteCurrencyMutation.mutate(payload, {
          onSuccess: (data) => {
            const newCurrencyList = currencyArrayValue.filter(
              (x) => x.callPriceCurrency !== data.data.currency
            );
            setValue("currencies", newCurrencyList);
            message.success("Currency deleted successfully");
          },
          onError: () => {
            message.error("Error deleting currency");
          },
        });
      } else {
        handleRemoveCurrency(
          state.formCurrencies,
          currencyArrayValue,
          defaultCallCurrency,
          setCurrencyOptions,
          removeCurrency,
          currencyIndex
        );
      }
    } else {
      handleRemoveCurrency(
        state.formCurrencies,
        currencyArrayValue,
        defaultCallCurrency,
        setCurrencyOptions,
        removeCurrency,
        currencyIndex
      );
    }
  }

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  function handleClassTypeSwitch(topicType: string) {
    if (topicType === "single") {
      setValue("classSeries", []);
      setValue("topic_type", "single");
      clearErrors("classSeries");
    }
    if (topicType === "series") {
      clearErrors(["startDate", "startTime", "duration", "customDuration"]);
      setValue("topic_type", "series");
    }
  }

  const beforeUpload = async (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isLt5M;
  };
  const handleImageChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "error") {
      setUploading(false);
      setError("coverImage", info.file.response.message);
      message.error(info.file.response.message);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setUploading(false);
      //   setImageUrl(url);
      // });
      setUploading(false);
      clearErrors("coverImage");
      const imgUrl = info.file.response.data.url;
      setValue("coverImage", imgUrl);
      message.success("Your cover image has been uploaded successfully!");
      // getProfileQuery.refetch();
      // console.log(info);
    }
  };
  const onImageRemove = (file: UploadFile) => {
    setError("coverImage", {message: "Cover image is required"});
  };
  function getPayloadSessionInfo(values: TLiveClassesPayloadFormValues) {
    const sessionInfo =
      values.topic_type === "series"
        ? values.classSeries.map((item) => ({
            name: item.name,
            startDate: item.startDate,
            startTime: item.startTime,
            duration:
              item.duration === "custom"
                ? Number(item.customDuration)
                : Number(item.duration),
          }))
        : [
            {
              name: values.name,
              // startDate: values.startDate,
              startDate: values.startDate,
              startTime: values.startTime,
              duration:
                values.duration === "custom"
                  ? Number(values.customDuration)
                  : Number(values.duration),
            },
          ];
    return sessionInfo;
  }

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Confirm event deletion`,
      content: (
        <>
          <p>
            Are you sure you want to delete this live event? If there are registered
            attendees, they will be notified of the cancellation and will receive a full
            refund.
            <br />
            <br />
            This action cannot be undone.
          </p>
        </>
      ),
      okText: "Delete & cancel event",
      cancelText: "Exit",
      okType: "danger",
      okButtonProps: {
        shape: "round",
      },
      cancelButtonProps: {
        shape: "round",
      },
      onOk() {
        // if (serviceData.id) {
        // dispatch(deleteServiceById(serviceData.id))
        return new Promise((resolve, reject) => {
          if (liveClassData?.id) {
            // resolve(console.log(`Trying to delete ${liveClassData.id}`));
            resolve(
              deleteLiveClassQueryMutation.mutate(String(liveClassData?.id), {
                onSuccess: () => {
                  message.success("The selected event has been deleted successfully!");
                  navigate("/dashboard/live-classes");
                },
              })
            );
          }
        }).catch(() => message.error("Failed to delete"));
        // };
      },
    });
  };

  const {
    fields: classSeriesFields,
    append: appendClassSeries,
    remove: removeClassSeries,
  } = useFieldArray({
    control,
    name: "classSeries",
  });
  // console.log({errors, watch: watch()});
  // console.log({
  //   moment: moment(new Date(), "YYYY-MM-DD"),
  //   momentwo: moment(Date.now()),
  // });
  const onSubmit = async (values: TLiveClassesPayloadFormValues) => {
    if (values.classSeries.length < 1 && values.topic_type === "series") {
      return message.warn("Add a session");
    }

    const payload: TCreateLiveClassApiPayload = {
      attendees: values.attendeesType === "unlimited" ? -1 : Number(values.attendees),
      callPrice: values.callPriceType === "paid" ? Number(values.callPrice) : 0,
      callPriceCurrency: values.callPriceCurrency,
      coverImage: values.coverImage,
      description: values.description,
      name: values.name,
      session_info: getPayloadSessionInfo(values),
      topic_type: values.topic_type,
      hideFromMenu: values.hideFromMenu,
      isPauseable: values.isPauseable,
      currencies: values.callPriceType === "free" ? [] : values.currencies,
      url_redirect: values.isUrlRedirect ? values.url_redirect : undefined,
      request_phone_number: values.request_phone_number,
      // topics: getPayloadTopics(values),
    };
    // console.log({liveClassFormValues: values, payload});
    if (formType === "new") {
      createLiveClassMutation.mutate(payload, {
        onSuccess: (data) => {
          // console.log({dataforcreate: data});
          if (data && data.data) {
            dispatch({
              type: "update-create-live-class-api-response",
              payload: data.data as TLiveClassApiResponse,
            });
            dispatch({
              type: "update-create-live-class-form-step",
              payload: "cohost",
            });
          } else {
            message.success("Live class created successfully!");
            navigate("/dashboard/live-classes");
          }

          // setOpenSuccessfulModal(true);
        },
      });
    }
    if (formType === "edit") {
      // console.log("live class edit values", values);

      updateLiveClassQueryMutation.mutate(
        {...payload, id: eventId as string},
        {
          onSuccess: () => {
            message.success("Live class created successfully!");
            navigate("/dashboard/live-classes");
          },
        }
      );
    }
  };

  const onCloseRescheduleModal = () => {
    setRescheduleModalData({});
    setOpenRescheduleModal(false);
  };

  const showLiveClassRescheduleModal = () => {
    if (liveClassData?.session_info && eventId) {
      setOpenRescheduleModal(true);
      setRescheduleModalData({
        session_info: liveClassData.session_info.map((x) => {
          // console.log({
          //   xdu: x.duration,
          //   callopts: CallDurationOptions,
          //   fnd: CallDurationOptions.find((ele) => Number(ele.value) === x.duration),
          // });
          return {
            eventId: eventId,
            customDuration: CallDurationOptions.find((ele) => ele.value === x.duration)
              ? 15
              : x.duration,
            duration:
              CallDurationOptions.find(
                (ele) => Number(ele.value) === Number(x.duration)
              ) !== undefined
                ? x.duration
                : "custom",
            name: x.name,
            startDate: x.startDate,
            startTime: x.startTime,
          };
        }),
      });
      // setRescheduleData({
      //   eventId: eventId,
      //   sessionInfo: foundEvent.session_info,
      // });
    }
  };

  const hasBookings = liveClassData && liveClassData.bookedCounts > 0;

  return (
    <div style={{marginTop: "1.8rem"}}>
      <StyledFormWrapper style={{marginTop: 0}}>
        <Space direction="vertical" size={20} style={{display: "flex"}}>
          <StyledCreateServiceHeadingBox>
            <h1 className="main-heading">Live class information</h1>
          </StyledCreateServiceHeadingBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              {/* ************** NAME START **************** */}
              <div>
                <StyledInputLabelWrapper>
                  <label id="name-id" htmlFor="name">
                    Live class name
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="name"
                  render={({field: {onChange, value, ref}}) => (
                    <Input
                      id="serviceName"
                      status={errors.name?.message ? "error" : ""}
                      onChange={onChange}
                      size="large"
                      placeholder=" e.g Marketing 101"
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                {errors.name?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.name.message}
                  </div>
                )}
              </div>
              {/* ************** NAME END **************** */}
              {/* ************** DESCRIPTION START **************** */}
              <div>
                <StyledInputLabelWrapper>
                  <label id="description-id" htmlFor="description">
                    Description
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="description"
                  render={({field: {onChange, value}}) => (
                    <StyledEditorWrapper>
                      <ReactQuill
                        theme="snow"
                        onChange={(description) => {
                          onChange(description);
                        }}
                        value={value}
                        placeholder="What is your live class about?"
                        modules={{
                          toolbar: {
                            container: [
                              [{header: [1, 2, 3, 4, 5, 6, false]}],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "color",
                                "link",
                                "size",
                              ],
                              [{color: []}, {background: []}],
                              [{script: "sub"}, {script: "super"}],
                              ["blockquote", "code-block"],
                              [{list: "ordered"}, {list: "bullet"}],
                              [{indent: "-1"}, {indent: "+1"}, {align: []}],
                              ["link"],
                              ["clean"],
                            ],
                          },
                        }}
                      />
                    </StyledEditorWrapper>
                  )}
                />
                {errors.description?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.description?.message}
                  </div>
                )}
              </div>
              {/* ************** DESCRIPTION END **************** */}
              {/* ************** IMAGE UPLOAD START **************** */}
              <div>
                <StyledInputLabelWrapper>
                  <label id="cover-image-id" htmlFor="cover-image">
                    Cover image
                  </label>
                </StyledInputLabelWrapper>
                <Spin spinning={uploading}>
                  <StyledCoverImageWrapper>
                    <ImgCrop rotationSlider aspect={1.9}>
                      <Upload
                        name="cover_image"
                        // showUploadList={false}
                        headers={{
                          authorization: `Bearer ${userInfo.accessToken}`,
                        }}
                        action={`${API_BASE_URL}live-event/banner`}
                        onChange={handleImageChange}
                        beforeUpload={beforeUpload}
                        method="PUT"
                        listType="picture"
                        maxCount={1}
                        onRemove={onImageRemove}
                        defaultFileList={
                          liveClassData?.cover_image
                            ? [
                                {
                                  uid: "1",
                                  name: "cover_image",
                                  status: "done",
                                  url: `${liveClassData.cover_image ?? ""}`,
                                },
                              ]
                            : undefined
                        }
                      >
                        <Button
                          // onClick={handleUplpoadButtonClick}
                          icon={<UploadOutlined />}
                          size="large"
                          type="primary"
                        >
                          Upload image
                        </Button>
                      </Upload>
                    </ImgCrop>
                    {/* <p>{imageInputRef.current?.value.split("C:\\fakepath\\")[1]}</p>
                    <input
                      style={{opacity: 0}}
                      ref={imageInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    /> */}

                    <p style={{textAlign: "center"}}>
                      Images should be horizontal, at least 1280x720px
                    </p>
                  </StyledCoverImageWrapper>
                </Spin>
                {errors.coverImage?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.coverImage?.message}
                  </div>
                )}

                {/* <div style={{width: "10rem", height: "10rem"}}>
                  <img
                    style={{width: "100%", height: "100%"}}
                    ref={imagRef}
                    src=""
                    alt=""
                  />
                </div> */}
              </div>
              {/* ************** IMAGE UPLOAD END **************** */}
              {/* ************** CLASS SERIES START **************** */}
              {/* {(formType !== "edit" ||
                (liveClassData && liveClassData.bookedCounts < 1)) && ( */}
              <div>
                <Tabs
                  onTabClick={handleClassTypeSwitch}
                  defaultActiveKey={liveClassData?.topic_type}
                >
                  <Tabs.TabPane
                    disabled={formType === "edit"}
                    tab="Single class"
                    key="single"
                  >
                    <div>
                      <Row gutter={[16, 16]}>
                        <Col span={24} lg={12}>
                          <StyledInputLabelWrapper>
                            <label id="class-date-id" htmlFor="startDate">
                              Class date
                            </label>
                          </StyledInputLabelWrapper>
                          {formType === "edit" || liveClassData ? (
                            <StyledInputValue>
                              <p>{liveClassData?.session_info[0].startDate}</p>
                            </StyledInputValue>
                          ) : (
                            <>
                              <Controller
                                control={control}
                                name="startDate"
                                render={({field: {onChange, value}}) => (
                                  <DatePicker
                                    disabledDate={disabledDate}
                                    size="large"
                                    style={{width: "100%"}}
                                    onChange={(date, dateString) => {
                                      onChange(dateString);
                                    }}
                                    status={
                                      errors.startDate?.message ? "error" : undefined
                                    }
                                    value={!value ? undefined : moment(value)}
                                  />
                                )}
                              />
                              {errors.startDate?.message && (
                                <div role="alert" className="ant-form-item-explain-error">
                                  {errors.startDate.message}
                                </div>
                              )}
                            </>
                          )}
                        </Col>
                        <Col span={24} lg={12}>
                          {formType === "edit" || liveClassData ? (
                            <>
                              <StyledInputLabelWrapper>
                                <label id="classStartTime" htmlFor="classStartTime">
                                  Class time
                                </label>
                              </StyledInputLabelWrapper>
                              <StyledInputValue>
                                <p>{liveClassData?.session_info[0].startTime}</p>
                              </StyledInputValue>
                            </>
                          ) : (
                            <CustomSelectInput
                              control={control}
                              id={`classStartTime`}
                              label="Class time"
                              name="startTime"
                              showSearch
                              options={TIMEOPTIONS}
                              placeholder="9:00 AM"
                              style={{width: "100%"}}
                              error={errors.startTime?.message}
                            />
                          )}
                        </Col>
                        <Col span={24} lg={12}>
                          {liveClassData ? (
                            <>
                              <StyledInputLabelWrapper>
                                <label id="classDuration" htmlFor="classDuration">
                                  Class duration
                                </label>
                              </StyledInputLabelWrapper>
                              <StyledInputValue>
                                <p>{liveClassData.session_info[0].duration} minutes</p>
                              </StyledInputValue>
                            </>
                          ) : (
                            <CustomSelectInput
                              control={control}
                              id="classDuration"
                              label="Class duration"
                              name="duration"
                              placeholder="Please select"
                              options={CallDurationOptions}
                              error={errors.duration?.message}
                              style={{width: "100%"}}
                            />
                          )}
                        </Col>
                        {
                          <Col span={24} sm={12}>
                            {formType === "edit"
                              ? null
                              : // <div style={{marginTop: "3rem"}}>
                                //   <Button
                                //     onClick={showLiveClassRescheduleModal}
                                //     size="large"
                                //     shape="round"
                                //     type="default"
                                //     block
                                //   >
                                //     Reschedule session
                                //   </Button>
                                // </div>
                                watch("duration") === "custom" && (
                                  <CustomInputComponent
                                    id="customDuration"
                                    name="customDuration"
                                    type="number"
                                    label="Call duration (In minutes)"
                                    placeholder="Call duration (In minutes)"
                                    error={errors.customDuration?.message}
                                    control={control}
                                  />
                                )}
                          </Col>
                        }
                      </Row>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    disabled={formType === "edit"}
                    tab="Class series"
                    key="series"
                  >
                    <Space direction="vertical" size={24} style={{display: "flex"}}>
                      {errors.classSeries?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.classSeries.message}
                        </div>
                      )}
                      {classSeriesFields.slice(0, 14).map((item, index) => (
                        <div key={item.id}>
                          <Row gutter={[16, 16]} align="middle">
                            <Col span={24}>
                              <StyledInputLabelWrapper>
                                <label id="name-id" htmlFor="name">
                                  Name of the session
                                </label>
                              </StyledInputLabelWrapper>
                              {formType === "edit" ? (
                                <StyledInputValue>
                                  <p>
                                    {getValues(
                                      `classSeries[${index}].name` as "classSeries.0.name"
                                    )}
                                  </p>
                                </StyledInputValue>
                              ) : (
                                <>
                                  <Controller
                                    control={control}
                                    name={
                                      `classSeries[${index}].name` as "classSeries.0.name"
                                    }
                                    render={({field: {onChange, value, ref}}) => (
                                      <Input
                                        id="sessionName"
                                        status={
                                          errors.classSeries?.[index]?.name?.message
                                            ? "error"
                                            : ""
                                        }
                                        onChange={onChange}
                                        size="large"
                                        placeholder="What is the name of this session?"
                                        value={value}
                                        ref={ref}
                                      />
                                    )}
                                  />
                                  {errors.classSeries?.[index]?.name?.message && (
                                    <div
                                      role="alert"
                                      className="ant-form-item-explain-error"
                                    >
                                      {errors.classSeries?.[index]?.name?.message}
                                    </div>
                                  )}
                                </>
                              )}
                            </Col>
                            <>
                              <Col span={24} lg={12}>
                                <StyledInputLabelWrapper>
                                  <label id="name-id" htmlFor="name">
                                    Class date
                                  </label>
                                </StyledInputLabelWrapper>
                                {formType === "edit" || liveClassData ? (
                                  <StyledInputValue>
                                    <p>{liveClassData?.session_info[index].startDate}</p>
                                  </StyledInputValue>
                                ) : (
                                  <>
                                    <Controller
                                      control={control}
                                      name={
                                        `classSeries[${index}].startDate` as "classSeries.0.startDate"
                                      }
                                      render={({field: {onChange, value}}) => (
                                        <DatePicker
                                          disabledDate={disabledDate}
                                          size="large"
                                          style={{width: "100%"}}
                                          onChange={(date, dateString) =>
                                            onChange(dateString)
                                          }
                                          status={
                                            errors.classSeries?.[index]?.startDate
                                              ?.message
                                              ? "error"
                                              : undefined
                                          }
                                          value={!value ? undefined : moment(value)}
                                        />
                                      )}
                                    />
                                    {errors.classSeries?.[index]?.startDate?.message && (
                                      <div
                                        role="alert"
                                        className="ant-form-item-explain-error"
                                      >
                                        {errors.classSeries?.[index]?.startDate?.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              </Col>
                              <Col span={24} lg={12}>
                                {formType === "edit" || liveClassData ? (
                                  <>
                                    <StyledInputLabelWrapper>
                                      <label id="classStartTime" htmlFor="classStartTime">
                                        Class time
                                      </label>
                                    </StyledInputLabelWrapper>
                                    <StyledInputValue>
                                      <p>
                                        {liveClassData?.session_info[index].startTime}
                                      </p>
                                    </StyledInputValue>
                                  </>
                                ) : (
                                  <>
                                    <CustomSelectInput
                                      control={control}
                                      id={`classStartTime`}
                                      label="Class time"
                                      name={
                                        `classSeries[${index}].startTime` as "classSeries.0.startTime"
                                      }
                                      showSearch
                                      options={TIMEOPTIONS}
                                      placeholder="9:00 AM"
                                      style={{width: "100%"}}
                                      error={
                                        errors.classSeries?.[index]?.startTime?.message
                                      }
                                    />
                                  </>
                                )}
                              </Col>
                              <Col span={24} lg={12}>
                                {formType === "edit" || liveClassData ? (
                                  <>
                                    <StyledInputLabelWrapper>
                                      <label id="classDuration" htmlFor="classDuration">
                                        Class duration
                                      </label>
                                    </StyledInputLabelWrapper>
                                    <StyledInputValue>
                                      <p>
                                        {liveClassData?.session_info[index].duration}{" "}
                                        minutes
                                      </p>
                                    </StyledInputValue>
                                  </>
                                ) : (
                                  <>
                                    <CustomSelectInput
                                      control={control}
                                      id="classDuration"
                                      label="Class duration"
                                      name={
                                        `classSeries.${index}.duration` as "classSeries.0.duration"
                                      }
                                      placeholder="Please select"
                                      options={CallDurationOptions}
                                      error={
                                        errors.classSeries?.[index]?.duration?.message
                                      }
                                      style={{width: "100%"}}
                                    />
                                  </>
                                )}
                              </Col>
                            </>
                            <ClassSeriesFormComponent
                              index={index}
                              control={control}
                              errors={errors}
                              formType={formType}
                              getValues={getValues}
                              hasBookings={formType === "edit"}
                              showLiveClassRescheduleModal={showLiveClassRescheduleModal}
                            />
                          </Row>
                          {/* <Button
                          style={{marginTop: "3rem"}}
                          onClick={() => removeClassSeries(index)}
                          shape="circle"
                          icon={<DeleteFilled />}
                          size="large"
                        /> */}
                          {!hasBookings && (
                            <Button
                              style={{marginTop: "3rem"}}
                              icon={<DeleteFilled />}
                              onClick={() => removeClassSeries(index)}
                              shape="round"
                              size="large"
                              block
                            >
                              Remove session
                            </Button>
                          )}
                        </div>
                      ))}

                      {formType === "edit" && (
                        <div>
                          <Button
                            onClick={showLiveClassRescheduleModal}
                            size="large"
                            shape="round"
                            type="primary"
                            // block
                          >
                            Reschedule class
                          </Button>
                        </div>
                      )}

                      {formType !== "edit" && classSeriesFields.length < 14 && (
                        <Button
                          icon={<PlusCircleFilled />}
                          onClick={
                            // formType === "edit"
                            //   ? () => {
                            //       message.warn(
                            //         "Can't add new sessions after class has been created"
                            //       );
                            //       return;
                            //     }
                            //   :
                            () =>
                              appendClassSeries({
                                name: "",
                                duration: "15",
                                customDuration: 15,
                                startDate: moment(new Date())
                                  .add(1, "day")
                                  .format("YYYY-MM-DD"),
                                startTime: "09:00 AM",
                              })
                          }
                          disabled={hasBookings}
                          shape="round"
                          size="large"
                        >
                          Add a session
                        </Button>
                      )}
                    </Space>
                  </Tabs.TabPane>
                </Tabs>
              </div>
              {/* // )} */}

              {/* ************** CLASS SERIES END **************** */}

              {/* ************** CO HOST START **************** */}
              {/* <CoHostInput
                liveClassFormControl={control}
                formType={formType}
                watchLiveClassForm={watch}
                setValueLiveClassForm={setValue}
                coHosts={coHosts}
                eventId={eventId}
              /> */}
              {formType === "edit" && <CohostList coHosts={coHosts} eventId={eventId} />}
              {/* ************** CO HOST END **************** */}

              <div>
                <Space direction="vertical" size={24} style={{display: "flex"}}>
                  <StyledCreateServiceHeadingBox>
                    <h1 className="main-heading">Price</h1>
                  </StyledCreateServiceHeadingBox>
                  <div>
                    <StyledInputLabelWrapper>
                      <label id="slug-id" htmlFor="slug">
                        Cost
                      </label>
                    </StyledInputLabelWrapper>
                    <Controller
                      control={control}
                      name="callPriceType"
                      render={({field: {onChange, value}}) => (
                        <Radio.Group
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          <Tooltip
                            title={
                              !state.connectedBankStatus
                                ? "Please add your bank account details in Payouts to enable paid offerings"
                                : null
                            }
                          >
                            <Radio value={"paid"} disabled={!state.connectedBankStatus}>
                              Paid
                            </Radio>
                          </Tooltip>

                          <Radio value={"free"}>Free</Radio>
                        </Radio.Group>
                      )}
                    />
                  </div>
                  {watch("callPriceType") === "paid" && state.connectedBankStatus && (
                    <Row gutter={[10, 24]}>
                      <Col span={24} sm={12}>
                        <CustomInputComponent
                          id="price"
                          name="callPrice"
                          type="text"
                          label="Class price"
                          placeholder="100"
                          error={
                            errors.callPriceCurrency
                              ? errors.callPriceCurrency.message
                              : errors.callPrice?.message
                          }
                          control={control}
                          addonBefore={
                            <CustomSelectInput
                              control={control}
                              id="currency"
                              name="callPriceCurrency"
                              placeholder="Please select"
                              options={currencyOptions}
                            />
                          }
                        />
                      </Col>
                      {currencyFields.slice(0, 4).map((item, index) => (
                        <Col key={item.id} span={24} sm={12}>
                          <div style={{display: "flex", gap: "1.6rem"}}>
                            <div style={{flex: "1"}}>
                              <CustomInputComponent
                                id="callPrice"
                                name={`currencies.${index}.callPrice`}
                                type="text"
                                label="Class price"
                                placeholder="100"
                                error={
                                  errors.currencies?.[index]?.callPriceCurrency
                                    ? errors.currencies?.[index]?.callPriceCurrency
                                        ?.message
                                    : errors.currencies?.[index]?.callPrice?.message
                                }
                                control={control}
                                addonBefore={
                                  <Controller
                                    control={control}
                                    name={`currencies.${index}.callPriceCurrency`}
                                    render={({field: {onChange, value, ref}}) => (
                                      <>
                                        <Select
                                          onChange={(val) =>
                                            handleCurrencySelectOnChange(
                                              state.formCurrencies,
                                              currencyArrayValue,
                                              defaultCallCurrency,
                                              setCurrencyOptions,
                                              onChange,
                                              val
                                            )
                                          }
                                          value={value}
                                          ref={ref}
                                          size="large"
                                          // loading={getCurrenciesQuery.isFetching}
                                          status={
                                            errors.currencies?.[index]?.callPriceCurrency
                                              ?.message
                                              ? "error"
                                              : ""
                                          }
                                          showSearch={false}
                                          notFoundContent={null}
                                          placeholder={""}
                                          optionFilterProp="children"
                                          filterOption={(input, option) =>
                                            (option!.children as unknown as string)
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                        >
                                          {currencyOptions.map(
                                            (option, optionIdx: number | string) => (
                                              <Option key={optionIdx} value={option}>
                                                {option}
                                              </Option>
                                            )
                                          )}
                                        </Select>
                                        {errors.currencies?.[index]?.callPriceCurrency
                                          ?.message && (
                                          <div
                                            role="alert"
                                            className="ant-form-item-explain-error"
                                          >
                                            {
                                              errors.currencies?.[index]
                                                ?.callPriceCurrency?.message
                                            }
                                          </div>
                                        )}
                                      </>
                                    )}
                                  />
                                }
                              />
                            </div>
                            <div style={{marginTop: "auto"}}>
                              <Button
                                onClick={() =>
                                  handleDeleteCurrency(item.callPriceCurrency, index)
                                }
                                type="link"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                size="large"
                                loading={deleteCurrencyMutation.isLoading}
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                  {watch("callPriceType") === "paid" && currencyFields.length < 4 && (
                    <div>
                      <Button
                        icon={<PlusCircleFilled />}
                        onClick={() =>
                          handleAddNewCurrency(
                            state.formCurrencies,
                            currencyArrayValue,
                            defaultCallCurrency,
                            setCurrencyOptions,
                            appendCurrency
                          )
                        }
                        size="large"
                        shape="round"
                      >
                        Add currency
                      </Button>
                    </div>
                  )}
                </Space>
              </div>
              <div>
                <Space direction="vertical" size={24} style={{display: "flex"}}>
                  <StyledCreateServiceHeadingBox>
                    <h1 className="main-heading">Attendees</h1>
                  </StyledCreateServiceHeadingBox>
                  <div>
                    <StyledInputLabelWrapper>
                      <label id="attendees-type" htmlFor="attendeesType">
                        Number of attendees
                      </label>
                    </StyledInputLabelWrapper>
                    <Controller
                      control={control}
                      name="attendeesType"
                      render={({field: {onChange, value}}) => (
                        <Radio.Group
                          value={value}
                          onChange={(e) => {
                            if (e.target.value === "unlimited") {
                              clearErrors("attendees");
                            }
                            onChange(e.target.value);
                          }}
                        >
                          <Radio value={"limited"}>Limited</Radio>
                          <Radio value={"unlimited"}>Unlimited</Radio>
                        </Radio.Group>
                      )}
                    />
                  </div>
                  {watch("attendeesType") === "limited" && (
                    <div>
                      <CustomInputComponent
                        id="attendees"
                        name="attendees"
                        type="text"
                        label="Attendees"
                        placeholder="100"
                        error={errors.attendees?.message}
                        control={control}
                      />
                    </div>
                  )}
                </Space>
              </div>
              {/* ************** PRICE START **************** */}

              <div>
                <Row gutter={[10, 24]}>
                  <Col span={24} sm={12}>
                    {/* <CustomInputComponent
                      id="attendees"
                      name="attendees"
                      type="text"
                      label="Number of attendees"
                      placeholder="Unlimited"
                      error={errors.attendees?.message}
                      control={control}
                      addonBefore={
                        <CustomSelectInput
                          control={control}
                          id="attendees"
                          name="attendees"
                          placeholder="Please select"
                          options={["Unlimited"]}
                        />
                      }
                    /> */}
                    {/* <StyledInputLabelWrapper>
                      <label id="attendees-id" htmlFor="attendees">
                        Number of attendees
                      </label>
                    </StyledInputLabelWrapper>
                    <Controller
                      control={control}
                      name="attendees"
                      render={({field: {onChange, value, ref}}) => (
                        <Select
                          defaultValue={attendeesOptions.find(
                            (option) => option.value === Number(value)
                          )}
                          style={{width: "100%"}}
                          onChange={(value) => onChange(value)}
                          options={attendeesOptions}
                          size="large"
                        />
                      )}
                    />
                    {errors.attendees?.message && (
                      <div role="alert" className="ant-form-item-explain-error">
                        {errors.attendees.message}
                      </div>
                    )} */}
                    {/* <CustomSelectInput
                      control={control}
                      id="attendees"
                      name="attendees"
                      placeholder="Please select"
                      label="Number of attendees"
                      options={attendeesOptions}
                      error={errors.attendees?.message}
                      style={{width: "100%"}}
                    /> */}
                  </Col>
                </Row>
              </div>
              {/* ************** PRICE END **************** */}
              {/* </Space> */}
              <StyledCreateServiceHeadingBox>
                <h1 className="main-heading">Location</h1>
              </StyledCreateServiceHeadingBox>
              <Radio.Group value={"video"}>
                <StyledCallLocationItem active>
                  <div className="icon-container">
                    <img src="/images/video.svg" alt="video icon" />
                  </div>
                  <div className="loaction-info">
                    <h3 className="title">Coachli Video</h3>
                    <p className="sub-info">
                      You and your customer will be sent a link to join the video call. No
                      need for Zoom or Google Meet
                    </p>
                  </div>
                  <div style={{marginLeft: "auto"}}>
                    <Radio value={"video"} />
                  </div>
                </StyledCallLocationItem>
              </Radio.Group>
              <div>
                <StyledCreateServiceHeadingBox>
                  <h1 className="main-heading">Settings</h1>
                </StyledCreateServiceHeadingBox>
              </div>
              <CustomCheckboxInput
                control={control}
                id="hideFromMenu"
                name="hideFromMenu"
                // error={errors.hideServiceFromMenu?.message}
                label="Hide from your profile page"
              />
              <CustomCheckboxInput
                control={control}
                id="request_phone_number"
                name="request_phone_number"
                error={errors.request_phone_number?.message}
                label="Request customer's phone number"
              />
              <CustomCheckboxInput
                control={control}
                id="closeReg"
                name="isPauseable"
                // error={errors.hideServiceFromMenu?.message}
                label="Close registrations"
              />
              <div>
                <Space size={16} direction="vertical" style={{display: "flex"}}>
                  <div>
                    <Controller
                      control={control}
                      name="isUrlRedirect"
                      render={({field: {onChange, value}}) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            clearErrors("url_redirect");
                            onChange(e.target.checked);
                          }}
                        >
                          Redirect customers to URL after purchase
                        </Checkbox>
                      )}
                    />
                  </div>
                  <p>
                    *This could be a link to a whatsapp group chat, telegram group etc.
                  </p>
                  {watch("isUrlRedirect") && (
                    <div>
                      <Controller
                        control={control}
                        name="url_redirect"
                        render={({field: {onChange, value, ref}}) => (
                          <Input
                            id="serviceName"
                            status={errors.url_redirect?.message ? "error" : ""}
                            onChange={onChange}
                            size="large"
                            placeholder="Enter Url"
                            value={value}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.url_redirect?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.url_redirect.message}
                        </div>
                      )}
                    </div>
                  )}
                </Space>
              </div>
              <Row gutter={[0, 16]}>
                <Col span={24} md={12}>
                  <StyledServiceButtonWrapper>
                    <Button
                      block
                      size="large"
                      shape="round"
                      type="primary"
                      htmlType="submit"
                      disabled={
                        Object.keys(errors).length > 0 ||
                        Object.values(watch()).length < 1 ||
                        saveLiveClassAsDraftQueryMutation.isLoading ||
                        deleteCurrencyMutation.isLoading
                      }
                      loading={
                        createLiveClassMutation.isLoading ||
                        updateLiveClassQueryMutation.isLoading
                      }
                    >
                      {formType === "new" ? "Continue" : "Save Changes"}
                    </Button>
                    <Button
                      onClick={() => reset()}
                      block
                      size="large"
                      shape="round"
                      type="default"
                    >
                      Discard changes
                    </Button>
                  </StyledServiceButtonWrapper>
                </Col>
                {formType === "edit" && (
                  <Col span={24} md={{span: 12}}>
                    <Button
                      block
                      danger
                      icon={<DeleteOutlined />}
                      size="large"
                      type="text"
                      htmlType="button"
                      onClick={handleDeleteModal}
                      disabled={deleteCurrencyMutation.isLoading}
                    >
                      Delete class
                    </Button>
                  </Col>
                )}
              </Row>
            </Space>
          </form>
        </Space>
      </StyledFormWrapper>
      <LiveClassRescheduleModal
        modalData={rescheduleModalData}
        onClose={onCloseRescheduleModal}
        visible={openRescheduleModal}
      />
    </div>
  );
};

export {LiveClassesForm};

const ClassSeriesFormComponent = ({
  index,
  control,
  errors,
  formType,
  getValues,
  hasBookings,
  showLiveClassRescheduleModal,
}: {
  index: number;
  control: Control<TLiveClassesPayloadFormValues>;
  errors: FieldErrorsImpl<TLiveClassesPayloadFormValues>;
  formType: "new" | "edit";
  getValues: UseFormGetValues<TLiveClassesPayloadFormValues>;
  hasBookings?: boolean;
  showLiveClassRescheduleModal: () => void;
}) => {
  const classSeriesValues = useWatch({
    name: `classSeries.${index}`,
    control,
  });
  return (
    <>
      {formType === "edit"
        ? null
        : // <Col span={24} lg={12}>
          //   <div style={{marginTop: "3rem"}}>
          //     <Button
          //       onClick={showLiveClassRescheduleModal}
          //       size="large"
          //       shape="round"
          //       type="default"
          //       block
          //     >
          //       Reschedule session
          //     </Button>
          //   </div>
          // </Col>
          classSeriesValues.duration === "custom" && (
            <Col span={24} lg={12}>
              <CustomInputComponent
                id="customDuration"
                name={
                  `classSeries.${index}.customDuration` as "classSeries.0.customDuration"
                }
                type="number"
                label="Class duration (In minutes)"
                placeholder="Class duration (In minutes)"
                error={errors.classSeries?.[index]?.customDuration?.message}
                control={control}
              />
            </Col>
          )}
    </>
  );
};
