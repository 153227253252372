import {
  CopyOutlined,
  FacebookFilled,
  TwitterOutlined,
  MailFilled,
} from "@ant-design/icons";
import {Button, Spin, Alert, Input, Tooltip, Space} from "antd";
import {handleLiveClassLink} from "../../../../utils/live-class";
import {StyledSuccessServiceContent} from "../../../calls/service/service-form/service-form.style";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {CustomModal} from "../../../modal";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export function ProductCreateSuccessModal() {
  const navigate = useNavigate();

  const [toolTipText, setToolTipText] = useState<string>("copy product url");
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState<boolean>(false);

  const getProfileQuery = useGetProfile();

  const handleOk = () => {
    setOpenSuccessfulModal(false);
    // navigate("/dashboard/products");
  };
  return (
    <CustomModal
      visibleState={openSuccessfulModal}
      title="Digital product created!"
      okFunc={handleOk}
      cancelFunc={handleOk}
      footerContent={[
        <Button
          key="publicProfile"
          type="primary"
          shape="round"
          // onClick={() => navigate(`/${getProfileQuery.data?.username}`)}
          href={`/${getProfileQuery.data?.username}`}
          target="_blank"
        >
          View my public profile
        </Button>,
        <Button key="gotIt" shape="round" type="default" onClick={handleOk}>
          Got it!
        </Button>,
      ]}
    >
      <StyledModalContentWrapper>
        {getProfileQuery.isLoading ? (
          <div
            style={{
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: " center",
            }}
          >
            <Spin size="default" />
          </div>
        ) : getProfileQuery.isError ? (
          <Alert
            message="Error"
            description={getProfileQuery.error.message}
            type="error"
            showIcon
          />
        ) : (
          <StyledSuccessServiceContent>
            <p className="success-text">
              Your digital product has been successfully created! Share your product link
              to start making sales.
            </p>
            <div className="success-share-info">
              <Input.Group compact style={{display: "flex"}}>
                <Input
                  size="large"
                  readOnly
                  defaultValue={"www.coachli.co/sharononyinye/meetings.."}
                />
                <Tooltip title={toolTipText}>
                  <Button
                    size="large"
                    icon={<CopyOutlined />}
                    onClick={() =>
                      navigator.clipboard
                        .writeText("www.coachli.co/sharononyinye/meetings..")
                        .then(() => setToolTipText("copied"))
                    }
                  />
                </Tooltip>
              </Input.Group>
              <p>or</p>
              <Space direction="horizontal" size={16}>
                <div>
                  <Button
                    shape="circle"
                    size="large"
                    icon={<FacebookFilled />}
                    style={{
                      backgroundColor: "#EBEEF5",
                      color: "#3B5998",
                      border: "none",
                    }}
                    type="link"
                    target="_blank"
                    title="Share on facebook"
                  />
                </div>
                <div>
                  <Button
                    title="Share on twitter"
                    shape="circle"
                    size="large"
                    icon={<TwitterOutlined />}
                    target="_blank"
                    style={{
                      backgroundColor: "#E6F7FD",
                      color: "#00ACEE",
                      border: "none",
                    }}
                  />
                </div>
                <div>
                  <Button
                    title="Send mail"
                    shape="circle"
                    size="large"
                    icon={<MailFilled />}
                    style={{
                      backgroundColor: "#FBECEB",
                      color: "#DB4437",
                      border: "none",
                    }}
                  />
                </div>
              </Space>
            </div>
          </StyledSuccessServiceContent>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
}
