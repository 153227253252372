import {Alert, Button, Space} from "antd";
import {useGetCustomerBooking} from "../../../../../../react-query-hooks/booking";
import {CloseOutlined} from "@ant-design/icons";
import {FullPageLoader} from "../../../../../full-page-loader";
// import {ReschedulePersonMessageForm} from "./personal-message-form";
import {CoachRescheduleCallCalendar} from "./calendar";
import {BookingProvider} from "../../../../../../context/booking/booking-context";
import {StyledRescheduleDrawer} from "./reschedule-call-modal.style";
import {useForm, FormProvider} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

export interface IRescheduleCallFormValues {
  personalMessage: string;
}

const rescheduleCallSchema = yup.object().shape({
  personalMessage: yup.string().trim(),
});

export function RescheduleCallModal({
  onCloseRescheduleCallModalDrawer,
  openRescheduleCallModalDrawer,
  bookingRef,
  customerName,
}: {
  onCloseRescheduleCallModalDrawer: () => void;
  openRescheduleCallModalDrawer: boolean;
  bookingRef: string;
  customerName: string;
}) {
  const getCustomerBookingQuery = useGetCustomerBooking({
    reference: bookingRef,
    userType: "host_user",
  });

  const methods = useForm<IRescheduleCallFormValues>({
    resolver: yupResolver(rescheduleCallSchema),
    mode: "onChange",
  });
  return (
    <BookingProvider>
      <FormProvider {...methods}>
        <StyledRescheduleDrawer
          title={`Reschedule call with ${customerName}`}
          placement="right"
          onClose={onCloseRescheduleCallModalDrawer}
          visible={openRescheduleCallModalDrawer}
          closable={false}
          // width={584}
          extra={
            <Button
              type="ghost"
              size="small"
              icon={<CloseOutlined />}
              onClick={onCloseRescheduleCallModalDrawer}
            ></Button>
          }
        >
          {getCustomerBookingQuery.isLoading ? (
            <FullPageLoader />
          ) : getCustomerBookingQuery.isError ? (
            <Alert />
          ) : (
            <div>
              <Space size={32} direction="vertical" style={{display: "flex"}}>
                <p>
                  We will notify{" "}
                  <strong>{`${getCustomerBookingQuery.data.booking.first_name} ${getCustomerBookingQuery.data.booking.last_name}`}</strong>{" "}
                  to inform them that this call has been rescheduled.
                </p>
                {/* <ReschedulePersonMessageForm
                  customerName={`${getCustomerBookingQuery.data.booking.first_name} ${getCustomerBookingQuery.data.booking.last_name}`}
                /> */}
                <CoachRescheduleCallCalendar
                  bookingData={getCustomerBookingQuery.data}
                  onCloseRescheduleCallModalDrawer={onCloseRescheduleCallModalDrawer}
                />
              </Space>
            </div>
          )}
        </StyledRescheduleDrawer>
      </FormProvider>
    </BookingProvider>
  );
}
