import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button, Result} from "antd";
import {Link, useParams} from "react-router-dom";
import {
  StyledProductDetailsWrapper,
  StyledProductDetailsPageContainer,
  StyledProductDetailsMainSectionContainer,
} from "../../../../../components/products/product-details/product-details.style";
import {Footer} from "../../../../../components/public-profile/footer";
import {CustomerProductDetails} from "../../../../../components/products/customer-dashboard/product-details";
import {useGetProductCustomer} from "../../../../../react-query-hooks/products";
import {FullPageLoader} from "../../../../../components/full-page-loader";

export function CustomerProductDetailsPage() {
  const {id} = useParams();
  const getProductCustomer = useGetProductCustomer(id);
  if (getProductCustomer.isLoading) {
    return <FullPageLoader />;
  }
  if (getProductCustomer.isError) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={getProductCustomer.error.message}
        extra={[
          <Button
            onClick={() => getProductCustomer.refetch()}
            shape="round"
            type="primary"
            key="console"
          >
            Try again
          </Button>,
          <Button shape="round" type="default" key="console">
            <Link to="/dashboard/customer/products">Go back</Link>
          </Button>,
        ]}
      />
    );
  }
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <StyledProductDetailsWrapper>
        <StyledProductDetailsPageContainer>
          <Link to="/dashboard/customer/products">
            <Button icon={<ArrowLeftOutlined />} type="link" size="large">
              Back
            </Button>
          </Link>
          <StyledProductDetailsMainSectionContainer>
            <CustomerProductDetails productData={getProductCustomer.data} />
          </StyledProductDetailsMainSectionContainer>
        </StyledProductDetailsPageContainer>
      </StyledProductDetailsWrapper>
      <Footer />
    </div>
  );
}
