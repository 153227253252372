import styled from "styled-components";

export const PaymentOptionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  .payment-option-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
  }

  @media (min-width: 520px) {
    .payment-option-text {
      font-size: 1.6rem;
    }
  }
`;

export const PaymentOption = styled.div`
  width: 3rem;
  height: 1.6rem;

  .payment-option-image {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 520px) {
    width: 6.8rem;
    height: 3.8rem;
  }
`;
