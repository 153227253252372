import * as yup from "yup";

const productPurchaseSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email"),
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  numberCode: yup.string().trim(),
  phone_number: yup
    .string()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim(),
});
const productPurchasePhoneNumberRequiredSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email"),
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  numberCode: yup.string().trim(),
  phone_number: yup
    .string()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required("Phone number field is a required field")
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim(),
});
export {productPurchaseSchema, productPurchasePhoneNumberRequiredSchema};
