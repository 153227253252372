import {useServiceBookingContext} from "../../../../../context/service-booking/service-booking.context";
import {TTimeslotsPayload} from "../../../../../react-query-hooks/public-profile";
import {NewCustomCalendar} from "../../../../calendar/custom-calendar";
import {UpdateStateDateInfoParams} from "../../../../calls/upcoming-calls/call-card/call-card-menu/reschedule-call-modal/calendar";

export function ServiceBookingCalendar() {
  const {state: serviceBookingState, dispatch} = useServiceBookingContext();

  if (!serviceBookingState.serviceData) {
    return <div></div>;
  }

  function resetStateTimeSelected() {
    dispatch({
      type: "reset-time-selected",
    });
  }
  function updateStateDateInfo({
    day,
    currentMonth,
    year,
    selectedDateString,
  }: UpdateStateDateInfoParams) {
    dispatch({
      type: "update-date-info",
      payload: {
        booking_date: day,
        booking_month: currentMonth + 1,
        booking_year: year,
        selectedDateString: selectedDateString,
      },
    });
  }
  function fetchTimeSlots(payload: TTimeslotsPayload) {
    serviceBookingState.timeSlotsMutation?.mutate(payload);
  }

  return (
    <div>
      <p style={{fontSize: "1.6rem", color: "#00171F"}}>Select date and time</p>
      <div>
        <NewCustomCalendar
          availableDays={serviceBookingState.serviceData.days}
          coachTimezone={serviceBookingState.serviceData.timeZone}
          customerTimezone={serviceBookingState.selectedTimeZone}
          mentorId={serviceBookingState.serviceData.profile.userId}
          serviceId={serviceBookingState.serviceData.service.id}
          fetchTimeSlots={fetchTimeSlots}
          unavailableDates={serviceBookingState.serviceData.unavailableDates}
          resetStateTimeSelected={resetStateTimeSelected}
          updateStateDateInfo={updateStateDateInfo}
        />
      </div>
    </div>
  );
}
