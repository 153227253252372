import {useBookingData} from "../../../../../../../container/booking/booking-context";

const SuccessContent = () => {
  const {data} = useBookingData();
  return (
    <div>
      <p>
        Your call has been scheduled for{" "}
        <strong>{data.selectedDateString.toDateString()}</strong> at{" "}
        <strong>{data.timeSelected}</strong>
      </p>
    </div>
  );
};

export {SuccessContent};
