import {
  CalendarOutlined,
  HourglassOutlined,
  DollarOutlined,
  NumberOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {Button, Space, Tooltip} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useGetCustomerBookingMutation} from "../../../../react-query-hooks/booking";
import {TFetchCustomerBookingPayload} from "../../../../redux/booking/i-time-slots-payload";
import {TBooking} from "../../../../redux/call/i-call";
import {RootState} from "../../../../redux/store";
import {capitalizeFirstLetter} from "../../../../services/helpers";
import {CallCardMenu} from "./call-card-menu";
import {
  StyledCallCard,
  StyledCallCardExtraInfo,
  StyledCardDetailsButtonWrapper,
} from "./call-card.style";
import {CallDetailsModal, CallDetailsModalContent} from "./call-details-modal";
import {motion} from "framer-motion";
import {handleMeetingLink} from "../../../../utils/meeting";
import {RescheduleCallModal} from "./call-card-menu/reschedule-call-modal";

const BookingVariants = {
  hidden: {opacity: 0},
  visible: {opacity: 1, transition: {duration: 1}},
};

const CallCard = ({call}: {call: TBooking}) => {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState<boolean>(false);
  const [openRescheduleCallModalDrawer, setOpenRescheduleCallModalDrawer] =
    useState(false);
  const [phoneCallToolTipText, setPhoneCallToolTipText] =
    useState<string>("Copy phone number");
  // const windowReference = window.open();

  const getCustomerBookingQueryMutation = useGetCustomerBookingMutation();

  const showViewDetailsModal = () => {
    setOpenViewDetailsModal(true);
  };

  const handleOk = () => {
    setOpenViewDetailsModal(false);
  };

  const handleCancel = () => {
    setOpenViewDetailsModal(false);
  };
  const showRescheduleCallModalDrawer = () => {
    setOpenViewDetailsModal(false);
    setOpenRescheduleCallModalDrawer(true);
  };

  const onCloseRescheduleCallModalDrawer = () => {
    setOpenRescheduleCallModalDrawer(false);
  };

  const callDate = new Date(
    Number(call.booking_year),
    Number(call.booking_month) - 1,
    Number(call.booking_date)
  ).toDateString();
  // const selectedDateString = new Date(year, currentMonth, day);
  function handleJoinLiveClass() {
    const payload: TFetchCustomerBookingPayload = {
      reference: call.reference,
      userType: "host_user",
    };
    getCustomerBookingQueryMutation.mutate(payload, {
      onSuccess: (data) => {
        // console.log({dataguy: data});
        setTimeout(() => {
          if (data.data.videoPlatform.toLowerCase() === "dailyco") {
            window.open(`${data.data.dailyCoUrl}?t=${data.data.bookingToken}`);
          } else {
            window.open(
              handleMeetingLink("host", {
                token: data.data.bookingToken,
                roomId: call.roomId,
                firstName: userInfo?.user?.firstName,
                lastName: userInfo?.user?.lastName,
                meetingTitle: call.service.name,
              })
            );
          }
        }, 0);
      },
    });
  }
  // const disableButton = () => {
  //   if (call.statusName.toLowerCase() !== "in progress") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  return (
    <motion.div
      variants={BookingVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <StyledCallCard
        title={`${capitalizeFirstLetter(call?.first_name)} ${capitalizeFirstLetter(
          call?.last_name
        )} - ${capitalizeFirstLetter(call?.service.name)}`}
        extra={
          <CallCardMenu
            call={call}
            getCustomerBookingQueryMutation={getCustomerBookingQueryMutation}
            showRescheduleCallModalDrawer={showRescheduleCallModalDrawer}
          />
        }
      >
        <Space direction="vertical" size={18} style={{display: "flex"}}>
          <p className="call-card-description">{capitalizeFirstLetter(call.notes)}</p>
          <StyledCallCardExtraInfo>
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.amount === 0
                    ? "Free"
                    : call.amount.toLocaleString("en-US") + " " + call.currency}
                </span>
              </Space>
            </div>
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <CalendarOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">{callDate}</span>
              </Space>
            </div>
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <ClockCircleOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.coach_from_time || call.timeslot}
                </span>
              </Space>
            </div>
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <HourglassOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.service.callDuration} minutes
                </span>
              </Space>
            </div>
            <div className="phone-number-extra-details">
              <div className="call-card-extra-info-item">
                <Space size={10}>
                  {call.booking_type === "video" ? (
                    <>
                      <VideoCameraOutlined style={{color: "#7939CC"}} />
                      <span className="call-card-extra-info-item-value">
                        Video call{" "}
                        {call.is_bundle &&
                          `| ${call.bundle_session} of ${call.service.no_of_sessions} sessions`}
                      </span>
                    </>
                  ) : (
                    <>
                      <PhoneOutlined style={{color: "#7939CC"}} />
                      <span className="call-card-extra-info-item-value">
                        Phone call{" "}
                        {call.is_bundle &&
                          `| ${call.bundle_session} of ${call.service.no_of_sessions} sessions`}
                      </span>
                    </>
                  )}
                </Space>
              </div>
              {call.booking_type === "audio" && (
                <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <NumberOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">
                      {call.phone_number}
                    </span>
                  </Space>
                </div>
              )}
            </div>
          </StyledCallCardExtraInfo>

          <StyledCardDetailsButtonWrapper>
            <div>
              {/* <StyledServiceButtonWrapper> */}
              {/* <Tooltip title="You will be able to join the video call 10 minutes before the call start time"> */}
              {call.booking_type === "video" ? (
                <Tooltip title={"Join call"}>
                  <Button
                    size="large"
                    block
                    shape="round"
                    type="primary"
                    htmlType="button"
                    onClick={handleJoinLiveClass}
                    loading={getCustomerBookingQueryMutation.isLoading}
                  >
                    Join video call
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title={phoneCallToolTipText}>
                  <Button
                    size="large"
                    block
                    shape="round"
                    type="primary"
                    htmlType="button"
                    onClick={() =>
                      navigator.clipboard
                        .writeText(`${call.phone_number}`)
                        .then(() => setPhoneCallToolTipText("Copied!"))
                    }
                  >
                    Copy phone number
                  </Button>
                </Tooltip>
              )}
              {/* </Tooltip> */}
            </div>
            <div>
              {/* <StyledServiceButtonWrapper> */}
              <Button
                size="large"
                block
                shape="round"
                type="default"
                onClick={showViewDetailsModal}
              >
                View details
              </Button>
            </div>

            {/* </StyledServiceButtonWrapper> */}
          </StyledCardDetailsButtonWrapper>
        </Space>

        <CallDetailsModal
          visibleState={openViewDetailsModal}
          title={`${capitalizeFirstLetter(
            call.service.name
          )} with ${capitalizeFirstLetter(call.first_name)} ${capitalizeFirstLetter(
            call.last_name
          )}`}
          okFunc={handleOk}
          cancelFunc={handleCancel}
          footerContent={[
            <Button
              onClick={showRescheduleCallModalDrawer}
              key="reschedule"
              shape="round"
              type="default"
            >
              Reschedule call
            </Button>,
            // <Button key="message" type="primary" shape="round">
            //   Message
            // </Button>,
          ]}
        >
          <CallDetailsModalContent
            type="coach"
            call={call}
            handleCancelModal={handleCancel}
          />
        </CallDetailsModal>
        {/* <CallRescheduleModal
          visibleState={openRescheduleCallModal}
          okFunc={handleRescheduleModalOk}
          cancelFunc={handleRescheduleModalCancel}
          call={call}
        /> */}
        <RescheduleCallModal
          onCloseRescheduleCallModalDrawer={onCloseRescheduleCallModalDrawer}
          openRescheduleCallModalDrawer={openRescheduleCallModalDrawer}
          bookingRef={call.reference}
          customerName={`${call.first_name} ${call.last_name}`}
        />
      </StyledCallCard>
    </motion.div>
  );
};

export {CallCard};
