import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  UserOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {Avatar, Button, Space, Spin} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router";
import {useGetPublicProfile} from "../../../react-query-hooks/public-profile";
import {RootState} from "../../../redux/store";
import {
  BehanceIcon,
  DiscordIcon,
  DribbleIcon,
  FacebookIcon,
  GithubIcon,
  GlobalIcon,
  InstagramIcon,
  LinkedinIcon,
  LoadingSpinner,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../icons/icons";
import {
  StyledCoachInfoBannerWrapper,
  StyledCoachInfoSectionWrapper,
} from "./coach-info-section.style";

const CoachInfoSection = () => {
  const [showMore, setShowMore] = useState(false);
  const {username} = useParams();
  const getPublicProfileQuery = useGetPublicProfile(username);
  return (
    <div>
      <Spin
        spinning={getPublicProfileQuery.isFetching}
        tip="Updating"
        indicator={LoadingSpinner}
      >
        <StyledCoachInfoSectionWrapper>
          <div>
            {getPublicProfileQuery.data?.avatar ? (
              <Avatar
                style={{border: "5px solid #FFF"}}
                size={{xs: 100, sm: 100, md: 150, lg: 150, xl: 150, xxl: 150}}
                src={getPublicProfileQuery.data.avatar}
              />
            ) : (
              <Avatar
                style={{border: "5px solid #FFF"}}
                size={{xs: 100, sm: 100, md: 150, lg: 150, xl: 150, xxl: 150}}
                icon={<UserOutlined />}
              />
            )}
            {/* <Avatar size={150} src="https://joeschmoe.io/api/v1/random" /> */}
          </div>
          <div className="coach-main-info">
            <Space direction="vertical" size={10} align="center">
              <h2 className="coach-main-info-name">
                {getPublicProfileQuery.data?.firstName}{" "}
                {getPublicProfileQuery.data?.lastName}
              </h2>
              <p className="coach-main-info-role">
                {getPublicProfileQuery.data?.description}
              </p>
              <div>
                <span className="coach-main-info-bio">
                  {showMore
                    ? getPublicProfileQuery.data?.shortBio
                    : getPublicProfileQuery.data &&
                      getPublicProfileQuery.data.shortBio.substring(0, 100)}
                </span>

                {getPublicProfileQuery.data &&
                  getPublicProfileQuery.data.shortBio.length > 100 && (
                    <Button
                      style={{paddingLeft: 0, marginLeft: ".2rem"}}
                      size="small"
                      type="link"
                      shape="round"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Show less" : " ...Show more"}
                    </Button>
                  )}
              </div>

              <div className="coach-main-info-socials">
                {getPublicProfileQuery.data?.twitter && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<TwitterIcon />}
                      // style={{
                      //   backgroundColor: "transparent",
                      //   color: "#597EF7",
                      //   border: "none",
                      // }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.twitter.includes("http")
                          ? `${getPublicProfileQuery.data.twitter}`
                          : `https://${getPublicProfileQuery.data.twitter}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.instagram && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<InstagramIcon />}
                      // style={{
                      //   backgroundColor: "transparent",
                      //   color: "#00171F",
                      //   border: "none",
                      // }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.instagram.includes("http")
                          ? `${getPublicProfileQuery.data.instagram}`
                          : `https://${getPublicProfileQuery.data.instagram}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.facebook && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<FacebookIcon style={{color: "#2F54EB"}} />}
                      // style={{
                      //   backgroundColor: "transparent",
                      //   color: "#3B5998",
                      //   border: "none",
                      // }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.facebook.includes("http")
                          ? `${getPublicProfileQuery.data.facebook}`
                          : `https://${getPublicProfileQuery.data.facebook}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.linkedin && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<LinkedinIcon />}
                      type="link"
                      href={
                        getPublicProfileQuery.data.linkedin.includes("http")
                          ? `${getPublicProfileQuery.data.linkedin}`
                          : `https://${getPublicProfileQuery.data.linkedin}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.youtube && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<YoutubeIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.youtube.includes("http")
                          ? `${getPublicProfileQuery.data.youtube}`
                          : `https://${getPublicProfileQuery.data.youtube}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.dribble && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<DribbleIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.dribble.includes("http")
                          ? `${getPublicProfileQuery.data.dribble}`
                          : `https://${getPublicProfileQuery.data.dribble}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.behance && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<BehanceIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.behance.includes("http")
                          ? `${getPublicProfileQuery.data.behance}`
                          : `https://${getPublicProfileQuery.data.behance}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.github && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<GithubIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.github.includes("http")
                          ? `${getPublicProfileQuery.data.github}`
                          : `https://${getPublicProfileQuery.data.github}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.website && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<GlobalIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.website.includes("http")
                          ? `${getPublicProfileQuery.data.website}`
                          : `https://${getPublicProfileQuery.data.website}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.twitch && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<TwitchIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.twitch.includes("http")
                          ? `${getPublicProfileQuery.data.twitch}`
                          : `https://${getPublicProfileQuery.data.twitch}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.discord && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<DiscordIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.discord.includes("http")
                          ? `${getPublicProfileQuery.data.discord}`
                          : `https://${getPublicProfileQuery.data.discord}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
                {getPublicProfileQuery.data?.tiktok && (
                  <div>
                    <Button
                      shape="circle"
                      icon={<TiktokIcon />}
                      style={{
                        backgroundColor: "transparent",
                        color: "#3B5998",
                        border: "none",
                      }}
                      type="link"
                      href={
                        getPublicProfileQuery.data.tiktok.includes("http")
                          ? `${getPublicProfileQuery.data.tiktok}`
                          : `https://${getPublicProfileQuery.data.tiktok}`
                      }
                      target="_blank"
                    />
                  </div>
                )}
              </div>
            </Space>
          </div>
        </StyledCoachInfoSectionWrapper>
      </Spin>
    </div>
  );
};

export {CoachInfoSection};
