import axios from "axios";
import {API_BASE_URL} from "../../services/config";
import {
  IBookingPayload,
  IBookingServicePayload,
  IRescheduleBookingPayload,
  ITimeSlotsPayload,
  TCustomerCancelBookingPayload,
  TFetchCustomerBookingPayload,
} from "./i-time-slots-payload";

// get booking service data
export const getBookingServiceService = async (payload: IBookingServicePayload) => {
  const response = await axios.get(
    API_BASE_URL +
      "booking/service/" +
      payload.mentorUsername +
      "/" +
      payload.slug +
      "/" +
      payload.id
  );

  return response.data;
};
// get booking time slots
export const getBookingTimeSlotService = async (payload: ITimeSlotsPayload) => {
  const response = await axios.post(API_BASE_URL + "booking/time-slots/", payload);

  return response.data;
};
// create booking
export const createBookingService = async (payload: IBookingPayload) => {
  const response = await axios.post(API_BASE_URL + "booking", payload);

  return response.data;
};
// reschedule booking
export const rescheduleBookingService = async (payload: IRescheduleBookingPayload) => {
  const response = await axios.put(API_BASE_URL + "booking-update", payload);

  return response.data;
};
// customer cancel booking
export const customerCancelBookingService = async (
  payload: TCustomerCancelBookingPayload
) => {
  const response = await axios.put(API_BASE_URL + "booking/cancel/" + payload.reference, {
    description: payload.description,
  });

  return response.data;
};
// fetch customer booking
export const getCustomerBookingService = async (
  payload: TFetchCustomerBookingPayload
) => {
  const response = await axios.get(
    `${API_BASE_URL}customer-booking/${payload.reference}/${payload.userType}`
  );
  // console.log("dyta", response);
  return response.data;
};
