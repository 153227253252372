import {Space} from "antd";
import {TCustomerEvent} from "../../../../react-query-hooks/live-events/live-classes";
import {StyledNoUpcomingCallBox} from "../../../calls/upcoming-calls/upcoming-calls.style";
import {CustomerLiveClassCard} from "../card/customer/card";

export type TUpcomingClassesProps = {
  events?: TCustomerEvent[];
};

function UpcomingClassesCustomer({events}: TUpcomingClassesProps) {
  return (
    <Space direction="vertical" size={29} style={{display: "flex"}}>
      {events && events.length > 0 ? (
        events.map((liveClass) => (
          <CustomerLiveClassCard key={liveClass.id} eventData={liveClass} />
        ))
      ) : (
        <div style={{width: "100%"}}>
          <StyledNoUpcomingCallBox>
            <p className="info-text">
              You do not have any live class at the moment. Book a new live class to get
              started
            </p>
          </StyledNoUpcomingCallBox>
        </div>
      )}
    </Space>
  );
}

export {UpcomingClassesCustomer};
