import {
  StyledContainer,
  StyledSectionHeader,
} from "../../../components/home/features-section/features-section.style";
import {StyledHeroSection} from "../../../components/home/hero-section/hero-section.style";
import {Pricing} from "../../../components/home/pricing";
import {HomeWrapper} from "../../../layouts/home-wrapper/home-wrapper";

const PricingPage = () => {
  return (
    <HomeWrapper>
      <StyledHeroSection style={{paddingTop: "15rem"}}>
        <StyledContainer>
          <StyledSectionHeader>
            <h2 className="section-header-main-title">Our transaction fee</h2>
            <p
              className="section-header-sub-title"
              style={{
                maxWidth: "79.2rem",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Using Coachli is free. We only make money when you start making money. We
              charge a little transaction fee after every successful sale. This fee vary
              by currency of your sale. Don’t worry about any monthly fees.
            </p>
          </StyledSectionHeader>
          <Pricing />
        </StyledContainer>
      </StyledHeroSection>
    </HomeWrapper>
  );
};

export default PricingPage;
