import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  PictureOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {Spin, Upload, Button, UploadFile, UploadProps, message} from "antd";
import ImgCrop from "antd-img-crop";
import {useState} from "react";
import {API_BASE_URL} from "../../../../services/config";
import {
  StyledInputLabelWrapper,
  StyledCoverImageWrapper,
} from "../../../input/input.style";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {RcFile, UploadChangeParam} from "antd/lib/upload";
import {TProductInputProps} from "../form-values";
import {StyledCoverImageContent} from "./cover-image-upload.style";

type TProductCoverImageUploadProps = TProductInputProps & {};

export function ProductCoverImageUpload({
  formState,
  clearErrors,
  setError,
  setValue,
  watch,
}: TProductCoverImageUploadProps) {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);

  const [uploading, setUploading] = useState(false);

  const coverImageValue = watch("coverImage");

  const beforeUpload = async (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isLt5M;
  };
  const handleImageChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setUploading(true);
      return;
    }
    if (info.file.status === "error") {
      setUploading(false);
      setError("coverImage", info.file.response.message);
      message.error(info.file.response.message);
      return;
    }

    if (info.file.status === "done") {
      setUploading(false);
      clearErrors("coverImage");
      const imgUrl = info.file.response.data.url;
      setValue("coverImage", imgUrl);
      message.success("Your cover image has been uploaded successfully!");
    }
  };
  const onImageRemove = () => {
    setError("coverImage", {message: "Cover image is required"});
    setValue("coverImage", "");
  };
  return (
    <div>
      <StyledInputLabelWrapper>
        <label id="cover-image-id" htmlFor="cover-image">
          Cover image
        </label>
      </StyledInputLabelWrapper>
      {coverImageValue ? (
        <Spin spinning={uploading}>
          <StyledCoverImageContent>
            <div className="cover-image-overlay" />
            <div className="content-controls">
              <div>
                <ImgCrop rotationSlider aspect={1.9}>
                  <Upload
                    name="cover_image"
                    showUploadList={false}
                    headers={{
                      authorization: `Bearer ${userInfo.accessToken}`,
                    }}
                    action={`${API_BASE_URL}product/banner-upload`}
                    onChange={handleImageChange}
                    beforeUpload={beforeUpload}
                    method="POST"
                    maxCount={1}
                  >
                    <EditOutlined
                      style={{fontSize: "2.4rem", cursor: "pointer", color: "#fff"}}
                      title="Edit image"
                    />
                  </Upload>
                </ImgCrop>
              </div>
              <div>
                <DeleteOutlined
                  style={{fontSize: "2.4rem", cursor: "pointer", color: "#fff"}}
                  title="Delete image"
                  onClick={onImageRemove}
                />
              </div>
            </div>
            <img
              className="cover-image-content"
              src={coverImageValue}
              alt="Product cover"
            />
          </StyledCoverImageContent>
        </Spin>
      ) : (
        <Spin spinning={uploading}>
          <StyledCoverImageWrapper>
            <div>
              <PictureOutlined style={{fontSize: "3.6rem", color: "#BFBFBF"}} />
            </div>
            <p className="upload-text">Minimum width 480 pixels, 16:9 recommended</p>
            <ImgCrop rotationSlider aspect={1.9}>
              <Upload
                name="cover_image"
                showUploadList={false}
                headers={{
                  authorization: `Bearer ${userInfo.accessToken}`,
                }}
                action={`${API_BASE_URL}live-event/banner`}
                onChange={handleImageChange}
                beforeUpload={beforeUpload}
                method="PUT"
                listType="picture"
                maxCount={1}
                onRemove={onImageRemove}
              >
                <Button
                  style={{paddingLeft: 0}}
                  type="link"
                  size="large"
                  icon={<CloudUploadOutlined />}
                >
                  Upload cover photo
                </Button>
              </Upload>
            </ImgCrop>
          </StyledCoverImageWrapper>
        </Spin>
      )}

      {formState.errors.coverImage?.message && (
        <div role="alert" className="ant-form-item-explain-error">
          {formState.errors.coverImage?.message}
        </div>
      )}
    </div>
  );
}
