import {Button, message, Space, Spin} from "antd";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {AuthFormWrapper} from "../../../../../layouts/auth-form-wrapper";
import {
  activateCustomerUser,
  resetUserAccountActivationState,
} from "../../../../../redux/auth/account-activation/account-activation-slice";
import {updateLoginState} from "../../../../../redux/auth/login/user-login-slice";
import {AppDispatch, RootState} from "../../../../../redux/store";

const CustomerEmailVerificationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    userInfo: userInfoAccountActivation,
    activateCustomerStatus,
    message: userAccountActivationMessage,
  } = useSelector((state: RootState) => state.userAccountActivation);

  const {email, link} = useParams();

  useEffect(() => {
    if (link) {
      dispatch(activateCustomerUser(link));
    }
  }, [link, navigate, dispatch]);

  useEffect(() => {
    if (activateCustomerStatus === "resolved") {
      message.success("Redirecting in 5secs", 5, () => {
        dispatch(updateLoginState({...userInfoAccountActivation, isCustomer: true}));
        navigate("/dashboard/customer/home");
      });
    }
  }, [activateCustomerStatus, dispatch, navigate, userInfoAccountActivation]);

  useEffect(() => {
    return () => {
      dispatch(resetUserAccountActivationState());
    };
  }, [dispatch]);

  // const handleResendActivationLink = () => {
  //   if (email) {
  //     dispatch(resendActivationLink(email));
  //   } else {
  //     message.error("Wrong credentials. Try again!");
  //   }
  // };

  return (
    <AuthFormWrapper>
      <Space direction="vertical" size={29} align={"center"} style={{display: "flex"}}>
        {activateCustomerStatus === "rejected" ? (
          <Space direction="vertical" size={29} style={{display: "flex"}}>
            <div>
              <h2 className="form-main-heading">Account Activation Fail</h2>
              <p className="form-sub-heading">{userAccountActivationMessage}</p>
            </div>
            <div>
              <Button
                block
                size="large"
                shape="round"
                type="primary"
                onClick={() => navigate("/auth/customer/login")}
              >
                Resend
              </Button>
            </div>
          </Space>
        ) : activateCustomerStatus === "resolved" ? (
          <Space direction="vertical" size={29} style={{display: "flex"}}>
            <div>
              <h2 className="form-main-heading">Account Activation Success</h2>
              <p className="form-sub-heading">
                Your account has been verified successfully!
              </p>
            </div>
          </Space>
        ) : (
          <Spin size="large" />
        )}
      </Space>
    </AuthFormWrapper>
  );
};

export {CustomerEmailVerificationPage};
