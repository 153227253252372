import {Dispatch, PropsWithChildren, useReducer} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Alert, Button, Result} from "antd";
import {
  TServiceBookingActions,
  TServiceBookingState,
  initialServiceBookingState,
  serviceBookingReducer,
} from "../../reducers/service-booking/service-booking.reducer";
import {createContext} from "../create-context";
import {
  useGetPublicProfileBooking,
  useGetPublicProfileBookingTimeslots,
} from "../../react-query-hooks/public-profile";
import {FullPageLoader} from "../../components/full-page-loader";

type TBookingContextState = {
  state: typeof initialServiceBookingState;
  dispatch: Dispatch<TServiceBookingActions>;
};

const [useContext, ContextProvider] = createContext<TBookingContextState>();

export const useServiceBookingContext = useContext;

export const ServiceBookingProvider = ({children}: PropsWithChildren) => {
  const [reducerState, dispatch] = useReducer(
    serviceBookingReducer,
    initialServiceBookingState
  );

  const [searchParams] = useSearchParams();
  const {username} = useParams();
  // const username = searchParams.get("username");
  const slug = searchParams.get("slug");
  const serviceId = searchParams.get("serviceId");
  const currentUrlStep = searchParams.get("currentStep");

  const params = {
    username,
    slug,
    serviceId,
  };
  const getPublicProfileBookingTimeslotsMutation = useGetPublicProfileBookingTimeslots();
  const getPublicProfileServiceBookingQuery = useGetPublicProfileBooking({
    mentorUsername: username,
    id: serviceId,
    slug: slug,
  });

  if (!username || !slug || !serviceId) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }

  if (getPublicProfileServiceBookingQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getPublicProfileServiceBookingQuery.isError) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={getPublicProfileServiceBookingQuery.error.message}
        extra={[
          <Button
            onClick={() => getPublicProfileServiceBookingQuery.refetch()}
            shape="round"
            type="default"
            key="console"
          >
            Try again
          </Button>,
        ]}
      />
    );
  }

  const serviceData = getPublicProfileServiceBookingQuery.data;
  const urlParams: TServiceBookingState["urlParams"] = {
    ...params,
    currentUrlStep,
  };
  const newState: TServiceBookingState = {
    ...reducerState,
    serviceData,
    urlParams,
    timeSlotsMutation: getPublicProfileBookingTimeslotsMutation,
  };

  return (
    <ContextProvider value={{state: newState, dispatch}}>{children}</ContextProvider>
  );
};
