import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

const useGetTimezone = () => {
  const client = useClient();

  return useQuery<string, AxiosError<{status: boolean; message: string}>>({
    queryKey: ["timezone"],
    queryFn: () => client(`timezone`).then((data) => data.data.timeZone),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

const useUpdateTimezone = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {timeZone: string}) =>
      client(`timezone`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Timezone update", "Timezone update fail");
    },
    onSuccess: () => {
      message.success("Your timezone has been updated successfully!");
      AnalyticsEvent("Timezone update", "Timezone update success");
    },
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["timezone"]}),
        queryClient.invalidateQueries({queryKey: ["upcoming_calls"]}),
      ]),
  });
};

export {useGetTimezone, useUpdateTimezone};
