import styled from "styled-components";

export const StyledMessageListBox = styled.div`
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 70.4rem;
  display: flex;
  //   overflow-y: scroll;
`;
