import {GoogleOAuthProvider} from "@react-oauth/google";
import ReactGA from "react-ga";
import {Route, Routes, useNavigate, useLocation, Navigate} from "react-router-dom";
import {PrivateRoutes} from "./components/private-routes";
import {history} from "./components/private-routes/history";
import {OnboardingDataProvider} from "./container/onboarding/onboarding-context";
import PageNotFound from "./pages/404";
import EmailVerificationPage from "./pages/auth/email-verification";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import LoginPage from "./pages/auth/login";
import CreateNewPassword from "./pages/auth/new-password";
import RegisterPage from "./pages/auth/register";
import {EmailVerificationSuccessPage} from "./pages/auth/verify-success";
import {BookingPage} from "./pages/booking";
import OnboardingPage from "./pages/onboarding";
import {PublicProfile} from "./pages/public-profile";
import {ErrorBoundary} from "react-error-boundary";
// import {GoogleOAuthProvider} from "@react-oauth/google";
import "./App.css";
import JoinMeetingPage from "./pages/meeting/join";
import CustomerCancelBookingPage from "./pages/booking/cancel-booking";
import CustomerReschedulePage from "./pages/booking/reschedule";
import HomePage from "./pages/home";
import PricingPage from "./pages/home/pricing";
import ContactPage from "./pages/home/contact";
import CustomerLoginPage from "./pages/auth/login/customer";
import {CustomerCallsPage} from "./pages/dashboard/customer/calls";
import {CustomerEmailVerificationPage} from "./pages/auth/login/customer/verify-link";
import {Button, Result} from "antd";
import {MessageProvider} from "./context/message-context";
import {CustomerMessagesPage} from "./pages/dashboard/customer/messages";
import {LiveClassesBookingPage} from "./pages/public-profile/live-classes/details/booking";
import {StripeSuccessPage} from "./pages/stripe-success";
import {CustomerLiveClassesPage} from "./pages/dashboard/customer/live-classes";
import LiveClassJoinMeetingPage from "./pages/meeting/join/live-classes";
import {CustomerDashboardHomepage} from "./pages/dashboard/customer/home";
import LiveEventsPage from "./pages/home/live-events";
import {CustomerProductsPage} from "./pages/dashboard/customer/products";
import {CustomerProductDetailsPage} from "./pages/dashboard/customer/products/details";
import {DashboardRoutes} from "./utils/routes/routes";
import {CurrencyProvider} from "./context/currency-context";
import {ProductPurchaseProvider} from "./context/products/product-purchase-context";
import {NewBookingPage} from "./pages/booking/new";
import {LiveClassSuccessBooking} from "./pages/public-profile/live-classes/details/success";
import {ProductSuccessBooking} from "./pages/public-profile/products/details/success";
import {LiveClassBookingProvider} from "./context/live-class-booking-context";
import {NewPublicProfileLiveClassesDetailsPage} from "./pages/public-profile/live-classes/details/new";
import {AnotherPublicProfileLiveClassesDetailsPage} from "./pages/public-profile/live-classes/details";
import {AnotherPublicProfileProductsDetailsPage} from "./pages/public-profile/products/details";
import {GetProfileProvider} from "./context/profile/profile-context";
import TermsPage from "./pages/home/terms";
import PrivacyPolicyPage from "./pages/home/privacy-policy";
import {ServiceBookingPage} from "./pages/public-profile/services";
import {ServiceBookingProvider} from "./context/service-booking/service-booking.context";
import {GetCoachConnectCalendarStatusProvider} from "./context/connect-calendar/connect-calendar-status.context";
import {ServiceSuccessBooking} from "./pages/public-profile/services/success";
import {
  NavigateToNewLiveClass,
  NavigateToNewProducts,
  NavigateToNewServices,
  NavigateToNewServices2,
} from "./pages/public-profile/navigate";

// console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);

const App = () => {
  //init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  const navigate = useNavigate();
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <ErrorBoundary
        FallbackComponent={({error, resetErrorBoundary}) => (
          <div>
            <Result
              status="500"
              title="Sorry, something went wrong."
              subTitle={error.message}
              extra={
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => {
                    resetErrorBoundary();
                  }}
                >
                  Go back
                </Button>
              }
            />
          </div>
        )}
        onError={(error, info) => console.log({error, info})}
        onReset={() => navigate(-1)}
      >
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/home/pricing" element={<PricingPage />} />
          <Route path="/home/contact" element={<ContactPage />} />
          <Route path="/home/live-events" element={<LiveEventsPage />} />
          <Route path="/home/terms" element={<TermsPage />} />
          <Route path="/home/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/auth/register" element={<RegisterPage />} />
          <Route path="/:username" element={<PublicProfile />} />
          <Route
            path="/:username/services/details"
            element={
              <ServiceBookingProvider>
                <ServiceBookingPage />
              </ServiceBookingProvider>
            }
          />
          <Route
            path="/:username/services/booking/success"
            element={
              <ServiceBookingProvider>
                <ServiceSuccessBooking />
              </ServiceBookingProvider>
            }
          />
          <Route path="/services/details" element={<NavigateToNewServices />} />
          <Route
            path="/:username/live-classes/details"
            element={
              <LiveClassBookingProvider>
                <AnotherPublicProfileLiveClassesDetailsPage />
              </LiveClassBookingProvider>
            }
          />
          <Route
            path="/:username/live-classes/booking/success"
            element={
              <LiveClassBookingProvider>
                <LiveClassSuccessBooking />
              </LiveClassBookingProvider>
            }
          />
          <Route path="/live-classes/details" element={<NavigateToNewLiveClass />} />
          <Route path="/booking/stripe-success" element={<StripeSuccessPage />} />
          {/* <Route
            path="/products/details"
            element={
              <ProductPurchaseProvider>
                <PublicProfileProductsDetailsPage />
              </ProductPurchaseProvider>
            }
          /> */}
          <Route
            path="/:username/products/details"
            element={
              <ProductPurchaseProvider>
                <AnotherPublicProfileProductsDetailsPage />
              </ProductPurchaseProvider>
            }
          />
          <Route
            path="/:username/products/booking/success"
            element={
              <ProductPurchaseProvider>
                <ProductSuccessBooking />
              </ProductPurchaseProvider>
            }
          />
          <Route path="/products/details" element={<NavigateToNewProducts />} />
          {/* <Route path="/products/details/booking" element={<ProductsBookingPage />} /> */}
          {/* <Route
            path="/live-classes/details/new"
            element={
              <LiveClassBookingProvider>
                <NewPublicProfileLiveClassesDetailsPage />
              </LiveClassBookingProvider>
            }
          /> */}

          {/* <Route
            path="/live-classes/details"
            element={<PublicProfileLiveClassesDetailsPage />}
          /> */}
          {/* <Route
            path="/:username/live-classes/details/booking"
            element={<LiveClassesBookingPage />}
          /> */}
          <Route
            path="/:username/meetings/:slug/:id"
            element={<NavigateToNewServices2 />}
          />
          {/* <Route path="/new/:username/meetings/:slug/:id" element={<NewBookingPage />} /> */}
          <Route path="/meetings/call/join/:reference" element={<JoinMeetingPage />} />
          <Route
            path="/meetings/call/live-class/join/:reference"
            element={<LiveClassJoinMeetingPage />}
          />
          <Route
            path="/booking/cancel/:reference"
            element={<CustomerCancelBookingPage />}
          />
          <Route
            path="/booking/reschedule/:reference"
            element={<CustomerReschedulePage />}
          />
          <Route path="/auth/login" element={<LoginPage />} />
          <Route path="/auth/customer/login" element={<CustomerLoginPage />} />
          <Route path="/auth/email-verification" element={<EmailVerificationPage />} />
          <Route
            path="/auth/verify/:email/:link"
            element={<EmailVerificationSuccessPage />}
          />
          <Route
            path="/auth/customer/verify/:email/:link"
            element={<CustomerEmailVerificationPage />}
          />
          <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/auth/recover-account/:email/:link"
            element={<CreateNewPassword />}
          />
          <Route element={<PrivateRoutes />}>
            <Route
              path="/dashboard/onboarding"
              element={
                <OnboardingDataProvider>
                  <OnboardingPage />
                </OnboardingDataProvider>
              }
            />

            {/* COACH PAGES */}
            {DashboardRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <GetProfileProvider>
                    <GetCoachConnectCalendarStatusProvider>
                      <CurrencyProvider>{route.element}</CurrencyProvider>
                    </GetCoachConnectCalendarStatusProvider>
                  </GetProfileProvider>
                }
              />
            ))}
            {/* CUSTOMER PAGES */}
            <Route
              path="/dashboard/customer/home"
              element={<CustomerDashboardHomepage />}
            />
            <Route path="/dashboard/customer/calls" element={<CustomerCallsPage />} />
            <Route
              path="/dashboard/customer/products"
              element={<CustomerProductsPage />}
            />
            <Route
              path="/dashboard/customer/products/details/:id"
              element={<CustomerProductDetailsPage />}
            />
            <Route
              path="/dashboard/customer/live-classes"
              element={<CustomerLiveClassesPage />}
            />
            <Route
              path="/customer/reschedule/:reference"
              element={<CustomerReschedulePage />}
            />
            <Route
              path="/dashboard/customer/messages"
              element={
                <MessageProvider>
                  <CustomerMessagesPage />
                </MessageProvider>
              }
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  );
};

export default App;
