import {Input} from "antd";
import styled from "styled-components";

export const StyledInputLabelWrapper = styled.div`
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    font-size: 1.4rem;
    font-weight: 400;
    // text-transoform: capitalize;
  }
`;
export const StyledInputComponent = styled(Input)`
  // font-family: Red Hat Display;
  // /* Chrome, Safari, Edge, Opera */
  // &::-webkit-outer-spin-button,
  // &::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }

  // /* Firefox */
  // &[type="number"] {
  //   -moz-appearance: textfield;
  // }
`;

export const StyledEditorWrapper = styled.div`
  .ql-editor {
    height: 300px;
    font-size: 1.6rem;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #eaeaea !important;
    background-color: #fbfbfb;
  }
`;

export const StyledCoverImageWrapper = styled.div`
  background: #fafafa;
  border: 1px dashed #bfbfbf;
  border-radius: 5px;
  padding: 2.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledUploadInput = styled.input`
  padding: 8px 16px;
  gap: 8px;
  background: #9747ff;
  border-radius: 2px;
`;
