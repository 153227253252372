import {ChatList} from "../chat-list";
import {MessageCustomerList} from "../message-customer-list";
import {StyledMessageListBox} from "./message-list.style";

const MessageList = () => {
  return (
    <StyledMessageListBox>
      <MessageCustomerList />
      <ChatList />
    </StyledMessageListBox>
  );
};

export {MessageList};
