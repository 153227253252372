import {Col, Row} from "antd";
import {StyledPublicProfileOfferingDetailsWrapper} from "../offering.style";
import {ServiceInfo} from "./service-info";
import {ServiceBookingCalendar} from "./calendar";
import {ServiceBookingTimeSlots} from "./timeslots";

export function PublicProfileServiceDetails() {
  return (
    <StyledPublicProfileOfferingDetailsWrapper style={{maxWidth: "100%"}}>
      <Row gutter={[40, 24]}>
        <Col span={24} lg={8}>
          <ServiceInfo />
        </Col>
        <Col span={24} lg={8}>
          <ServiceBookingCalendar />
        </Col>
        <Col span={24} lg={8}>
          <ServiceBookingTimeSlots />
        </Col>
      </Row>
    </StyledPublicProfileOfferingDetailsWrapper>
  );
}
