import {useEffect, useState} from "react";
import {getFebDays, MONTHS} from "../services/helpers";
// import {getFebDays, MONTHS} from "../utils";

export const useCalendar = (
  month: number,
  year: number,
  availableDays: any,
  unavailableDates: string[]
) => {
  // state for number of days for a month e.g( 1, 2, 3 .... 31)
  const [days, setDays] = useState<any>([]);

  // state for current selected date. Default is today's date
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date>();

  // state for current month. Default is today's month
  const [currentMonth, setCurrentMonth] = useState<string>(`${MONTHS[month]}`);

  // state for current year. Default is today's current year
  const [currentYear, setCurrentYear] = useState<number>(year);

  useEffect(() => {
    // to always keep the state of the current month updated to the month changed to
    setCurrentMonth(`${MONTHS[month]}`);

    // to always keep the state of the current month updated to the month changed to
    setCurrentYear(year);

    // to store the number of days in a month. Calculates the number of days in Feb to determine if it's a leap year or not
    const daysOfMonth = [31, getFebDays(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // the first day of which ever month is selected by the user
    const firstDayOfMonth = new Date(year, month, 1);

    // const availableDays = [2, 5, 6];

    // a new days array variable
    const daysArr = [];

    // console.log({availableDays});
    // console.log({month});
    // console.log({currentMonth});

    // loop over the length of the days in a particular month plus the day of the week for the first day minus 1
    // e.g( for september this equals 33 i.e 30 + 4 - 1 )
    // this gives us how to offset the days of the calendar to start from the right day
    for (let i = 0; i <= daysOfMonth[month] + firstDayOfMonth.getDay() - 1; i++) {
      // creates a date string for each day e.g(2022-9-20)
      const dateString = `${year}-${month + 1}-${i - firstDayOfMonth.getDay() + 1}`;
      // console.log({dateString, currentSelectedDate: currentSelectedDate.toDateString()});
      const dateStringDateFormat = new Date(
        year,
        month,
        i - firstDayOfMonth.getDay() + 1
      );
      // console.log("dateString", dateString);
      // console.log("dateStringDate", new Date(dateString));
      // console.log("dateStringDateTry", new Date("2023-01-09"));
      // console.log(
      //   "dateStringDateTry2",
      //   new Date(year, month + 1, i - firstDayOfMonth.getDay() + 1)
      // );

      // console.log("dateStringDateFormat", dateStringDateFormat);
      // console.log("dateStringDateFormat.getDay()", dateStringDateFormat.getDay());

      // start of the calendar
      if (i >= firstDayOfMonth.getDay()) {
        daysArr.push({
          value: i - firstDayOfMonth.getDay() + 1,
          isAvailable:
            ((availableDays.includes(dateStringDateFormat.getDay()) &&
              i - firstDayOfMonth.getDay() + 1 >= new Date().getDate()) ||
              (month !== new Date().getMonth() &&
                availableDays.includes(dateStringDateFormat.getDay())) ||
              (year !== new Date().getFullYear() &&
                availableDays.includes(dateStringDateFormat.getDay()))) &&
            !unavailableDates.includes(dateString),
          isSelected:
            dateStringDateFormat.toDateString() === currentSelectedDate?.toDateString(),
          // isAvailable:
          //   i - firstDayOfMonth.getDay() + 1 === currentSelectedDate.getDate() &&
          //   year === currentSelectedDate.getFullYear() &&
          //   month === currentSelectedDate.getMonth()
          //     ? true
          //     : false,
          date: dateString,
        });
      } else {
        // the offset which is just an empty div in this implementation
        daysArr.push({
          value: null,
          isAvailable: false,
          date: "",
        });
      }
    }

    // updates the state with the new days for the month selected
    setDays(daysArr);
  }, [month, year, currentMonth, currentSelectedDate]);

  return {
    days,
    month,
    currentMonth,
    currentYear,
    currentSelectedDate,
    setCurrentSelectedDate,
  };
};
