import axios from "axios";
import {API_BASE_URL} from "../../../services/config";

// const API_BASE_URL = "http://207.154.220.50:7890/api/v1/";

// Activate standard user
export const activateStandardUserService = async (link: string) => {
  const response = await axios.put(API_BASE_URL + "activation/link", {link});

  return response.data;
};
// Resend Activation link
export const resendActivationLinkService = async (email: string) => {
  const response = await axios.post(API_BASE_URL + "activation/link", {
    email: email.toLowerCase(),
  });

  return response.data;
};

export const activateCustomerService = async (link: string) => {
  const response = await axios.post(API_BASE_URL + "customer/activation-link", {link});

  return response.data;
};
