import moment from "moment";
import React, {createContext, useState, useContext} from "react";

interface IBookingDataProviderProps {
  children: any;
}

type Ctx = {
  [key: string]: any; // 👈️ variable key
};

const BookingDataContext = createContext<Ctx>({});

export const BookingDataProvider: React.FC<IBookingDataProviderProps> = ({children}) => {
  const [data, setData] = useState({
    selectedTimeZone: moment.tz.guess(true),
  });

  const setValues = (values: any) => {
    setData((prevData) => ({
      ...prevData,
      ...values,
    }));
  };

  return (
    <BookingDataContext.Provider value={{data, setValues}}>
      {children}
    </BookingDataContext.Provider>
  );
};

export const useBookingData = () => useContext(BookingDataContext);
