import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useClient} from "../../utils/hooks";
import {AxiosError} from "axios";
import {message} from "antd";

export const useCoachConnectCalendarGoogleSignIn = () => {
  const client = useClient();
  return useMutation<{data: {url: string}}, AxiosError, string, () => void>({
    mutationFn: (email) =>
      client(`google/login?email=${email}`, {
        method: "GET",
      }),

    onError: (error) => {
      message.error(error.message);
    },
  });
};

export const useGetCoachConnectCalendarStatus = (email: string) => {
  const client = useClient();

  return useQuery<
    {
      has_access_token: boolean;
      needs_login: boolean;
    },
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["connect_calendar_status", email],
    queryFn: () => client(`google/status/${email}`).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useCompleteCoachConnectCalendar = (
  email: string,
  code?: string | null,
  scope?: string | null
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useQuery<
    {
      tokens_generated: boolean;
    },
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["complete_connect_calendar_status", code],
    queryFn: () =>
      client(
        `google/xx-ccc-coachli-google-callback-google/callback?code=${code}&scope=${scope}&email=${email}`
      ).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!code && !!scope && !!email,
    onSuccess: () => message.success("Calendar connected successfully"),
    onSettled: () =>
      queryClient.invalidateQueries({queryKey: ["connect_calendar_status", email]}),
  });
};
export const useCoachDisconnectCalendar = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation<{data: unknown}, AxiosError, string, () => void>({
    mutationFn: (email) =>
      client(`google/disconnect`, {
        method: "GET",
      }),

    onError: (error) => {
      message.error(error.message);
    },
    onSettled: (d, e, v) =>
      queryClient.invalidateQueries({queryKey: ["connect_calendar_status", v]}),
  });
};
