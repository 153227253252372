import {Button, Space} from "antd";
import {StyledDigitalProductsCardWrapper} from "../digital-products-section.style";
import {DollarOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {TGetProductApiResponse} from "../../../../react-query-hooks/products";
import {handleProductLink} from "../../../../utils/products";

type TPublicProfileDigitalProductsSectionCardProps = {
  product: TGetProductApiResponse["product"];
  username: string;
};

export function PublicProfileDigitalProductsSectionCard({
  product,
  username,
}: TPublicProfileDigitalProductsSectionCardProps) {
  const navigate = useNavigate();
  const productDesc = product.description.replace(/<[^>]+>/g, "");
  return (
    <StyledDigitalProductsCardWrapper
      onClick={() =>
        navigate(
          handleProductLink({
            productId: product.id,
            slug: product.slug,
            username: username,
          })
        )
      }
      hoverable
      title={product.title}
    >
      <div className="product-image-wrapper">
        <img src={product.coverImage} alt={product.title} className="product-image" />
      </div>
      <div>
        <Space direction="vertical" size={16} style={{display: "flex"}}>
          <p className="product-description">{productDesc}</p>
          {/* {Object.keys(product.currency).length > 0 && ( */}
          <div>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="product-price">
                {Object.keys(product.currency).length > 0
                  ? `${
                      product.currency.currency
                    } ${product.currency.amount.toLocaleString()}`
                  : "Free"}
              </span>
            </Space>
          </div>
          {/* )} */}

          <div>
            <Button
              onClick={() =>
                navigate(
                  handleProductLink({
                    productId: product.id,
                    slug: product.slug,
                    username: username,
                  })
                )
              }
              block
              shape="round"
              type="primary"
              size="large"
            >
              Buy now
            </Button>
          </div>
        </Space>
      </div>
    </StyledDigitalProductsCardWrapper>
  );
}
