import {StyledPublicProfileOfferingImageWrapper} from "./offering-image.style";

type TPublicProfileOfferingImage = Partial<HTMLImageElement> & {
  src: string;
  alt: string;
};

export function PublicProfileOfferingImage({src, alt}: TPublicProfileOfferingImage) {
  return (
    <StyledPublicProfileOfferingImageWrapper>
      <img src={src} alt={alt} className="banner-image" />
    </StyledPublicProfileOfferingImageWrapper>
  );
}
