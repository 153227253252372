import styled from "styled-components";

export const StyledPublicProfileOfferingDetailsWrapper = styled.div`
  max-width: 95.3rem;
  margin: 2rem auto 6rem auto;

  @media (min-width: 425px) {
    margin: 5rem auto 6rem auto;
  }
`;

export const StyledPublicProfileOfferingMainSectionWrapper = styled.main`
  position: relative;
  display: grid;
  align-items: start;
  gap: 1.6rem;

  .offering-widget-info-content {
    .widget-flex {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      &.product-widget-flex {
        gap: 0;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: auto 35.7rem;
    grid-template-rows: auto;

    .offering-main-details-info {
      grid-column: 1/2;
      grid-row: auto;
    }
    .offering-widget-info-content {
      grid-column: 2/3;
      grid-row: 1 / auto;
      position: sticky;
      top: 10rem;
      .widget-flex {
        &.product-widget-flex {
          gap: 2.5rem;
        }
      }
    }
  }
`;
