import {Button, Col, Result, Row, Space} from "antd";
import {StyledContainer} from "../../styles/onboarding/onboarding";
import {CheckCircleTwoTone} from "@ant-design/icons";
import {StyledSuccessBookingInfo} from "../../container/booking/step-3/step-3.style";
import {StyledBookingRightColumnHeader} from "../../styles/booking/booking";
import {useNavigate, useSearchParams} from "react-router-dom";

const StripeSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const customerEmail = searchParams.get("customerEmail");
  const coachUsername = searchParams.get("coachUsername");
  const isSuccess = searchParams.get("isSuccess");

  return (
    <StyledContainer>
      {isSuccess === "true" ? (
        <div>
          <Space direction="vertical" size={24}>
            <div>
              <CheckCircleTwoTone twoToneColor="#52C41A" style={{fontSize: "2.1rem"}} />
            </div>
            <StyledBookingRightColumnHeader>
              Booking successful
            </StyledBookingRightColumnHeader>
            <StyledSuccessBookingInfo>
              We sent a confirmation and a calendar invitation with information on how to
              attend the session to <strong>{customerEmail ?? "test@gmail.com"}</strong>
            </StyledSuccessBookingInfo>
            <Row gutter={[10, 24]}>
              <Col span={24} sm={12}>
                <div>
                  <Button shape="round" onClick={() => navigate(`/${coachUsername}`)}>
                    Return to profile
                  </Button>
                </div>
              </Col>
            </Row>
          </Space>
        </div>
      ) : (
        <Result
          status="error"
          title="An Error Occured"
          subTitle="Payment could not be completed. Try again later"
          extra={[
            <Button
              onClick={() => navigate(`/${coachUsername}`)}
              shape="round"
              type="default"
              key="console"
            >
              Return to profile
            </Button>,
          ]}
        />
      )}
    </StyledContainer>
  );
};

export {StripeSuccessPage};
