import {Input} from "antd";
import {ChangeEventHandler, useCallback} from "react";
import {StyledInputComponent, StyledInputLabelWrapper} from "./input.style";

interface ICustomInputProps {
  id: string;
  label?: string;
  size?: "large" | "middle" | "small";
  placeholder: string;
  type?: string;
  addonBefore?: string;
  error?: any;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: any;
}

const CustomInput: React.FC<ICustomInputProps> = ({
  id,
  label,
  size = "large",
  placeholder,
  type = "text",
  error,
  onChange,
  addonBefore,
  value,
}) => {
  const labelId = label ? `${id}-label` : undefined;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <>
      {label && (
        <StyledInputLabelWrapper>
          <label id={labelId} htmlFor={id}>
            {label}
          </label>
        </StyledInputLabelWrapper>
      )}
      {type === "password" ? (
        <StyledInputComponent.Password
          status={error ? "error" : ""}
          size={size}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
      ) : type === "textarea" ? (
        <StyledInputComponent.TextArea
          status={error ? "error" : ""}
          size={size}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
      ) : (
        <StyledInputComponent
          status={error ? "error" : ""}
          type={type}
          size={size}
          placeholder={placeholder}
          onChange={handleChange}
          addonBefore={addonBefore}
          value={value}
          min={0}
        />
      )}
      {error && (
        <div role="alert" className="ant-form-item-explain-error">
          {error}
        </div>
      )}
    </>
  );
};

export {CustomInput};
