import {Button, message, Space, Spin} from "antd";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {AuthFormWrapper} from "../../../layouts/auth-form-wrapper";
import {
  activateStandardUser,
  resendActivationLink,
  resetUserAccountActivationState,
} from "../../../redux/auth/account-activation/account-activation-slice";
import {updateLoginState} from "../../../redux/auth/login/user-login-slice";
import {AppDispatch, RootState} from "../../../redux/store";

const EmailVerificationSuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    userInfo: userInfoAccountActivation,
    isLoading: isLoadingUserAccountActivation,
    isSuccess: isSuccessUserAccountActivation,
    isSuccessResendActivationLink,
    isError: isErrorUserAccountActivation,
    message: userAccountActivationMessage,
  } = useSelector((state: RootState) => state.userAccountActivation);

  const {email, link} = useParams();

  useEffect(() => {
    if (link) {
      dispatch(activateStandardUser(link));
    }
  }, [link, navigate, dispatch]);

  useEffect(() => {
    if (isSuccessUserAccountActivation) {
      message.success("Redirecting in 5secs", 5, () => {
        dispatch(updateLoginState(userInfoAccountActivation));
        navigate("/dashboard/onboarding");
      });
    }
    if (isSuccessResendActivationLink) {
      message.success("Your activation link has been resent to your email address!");
    }
  }, [
    userInfoAccountActivation,
    isSuccessResendActivationLink,
    isSuccessUserAccountActivation,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUserAccountActivationState());
    };
  }, [dispatch]);

  const handleResendActivationLink = () => {
    if (email) {
      dispatch(resendActivationLink(email.toLowerCase()));
    } else {
      message.error("Wrong credentials. Try again!");
    }
  };

  return (
    <AuthFormWrapper>
      <Space direction="vertical" size={29} align={"center"} style={{display: "flex"}}>
        {isErrorUserAccountActivation ? (
          <Space direction="vertical" size={29} style={{display: "flex"}}>
            <div>
              <h2 className="form-main-heading">Account Activation Fail</h2>
              <p className="form-sub-heading">{userAccountActivationMessage}</p>
            </div>
            {!userAccountActivationMessage.includes("Activation link does not exist") && (
              <div>
                <Button
                  block
                  size="large"
                  shape="round"
                  type="primary"
                  loading={isLoadingUserAccountActivation}
                  onClick={handleResendActivationLink}
                >
                  Resend
                </Button>
              </div>
            )}
          </Space>
        ) : isSuccessUserAccountActivation ? (
          <Space direction="vertical" size={29} style={{display: "flex"}}>
            <div>
              <h2 className="form-main-heading">Account Activation Success</h2>
              <p className="form-sub-heading">
                Your account has been verified successfully!
              </p>
            </div>
          </Space>
        ) : (
          <Spin size="large" />
        )}
      </Space>
    </AuthFormWrapper>
  );
};

export {EmailVerificationSuccessPage};
