import {ColumnsType} from "antd/es/table";
import {StyledDescriptionText} from "../../../calls/upcoming-calls/upcoming-calls.style";
import {ProductListMenuOption} from "./menu-options";
import {Button, Input, PaginationProps, Space, Table} from "antd";
import {useProductsContext} from "../../../../context/products/products-context";
import {ChangeEvent, useEffect, useState} from "react";

interface DataType {
  key: number;
  id: number;
  image: string;
  name: string;
  price: number;
  currency: string;
  slug: string;
  no_of_sales: number;
  revenue: number;
}

export function ProductListTable() {
  const {state, dispatch} = useProductsContext();

  const [productList, setProductList] = useState(() => state.products ?? []);

  useEffect(() => {
    setProductList(state.products ?? []);
  }, [state.products]);

  if (!state.products) {
    return <div></div>;
  }
  // const onChange: PaginationProps["onChange"] = (page) => {
  //   // console.log(page);
  //   dispatch({
  //     payload: page,
  //     type: "update-current-page",
  //   });
  // };

  const productData: DataType[] = productList.map((product) => ({
    key: product.id,
    id: product.id,
    currency: product.defaultPrice ? product.defaultPrice.currency : "NGN",
    image: product.coverImage,
    name: product.title,
    no_of_sales: product.noOfSales,
    price: product.defaultPrice ? product.defaultPrice.amount : 0,
    revenue: product.revenue,
    slug: product.slug,
  }));

  const columns: ColumnsType<DataType> = [
    {
      title: "Product name",
      dataIndex: "name",
      fixed: "left",
      width: "180px",
      key: "name",
      render: (value, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div
            style={{
              width: "4.4rem",
              height: "2.2rem",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "2px",
              }}
              src={record.image}
              alt={value}
            />
          </div>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {value}
          </StyledDescriptionText>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: "150px",
      key: "price",
      render: (value: number, record) => (
        <>
          {value === 0 ? (
            <StyledDescriptionText>Free</StyledDescriptionText>
          ) : (
            <StyledDescriptionText>{`${
              record.currency
            } ${value.toLocaleString()}`}</StyledDescriptionText>
          )}
        </>
      ),
    },
    {
      title: "No of sales",
      dataIndex: "no_of_sales",
      width: "130px",
      key: "no_of_sales",
      render: (value) => (
        <>
          <StyledDescriptionText>{value}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      width: "150px",
      key: "revenue",
      render: (value: number, record) => (
        <>
          <StyledDescriptionText>{`${
            record.currency
          } ${value.toLocaleString()}`}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "",
      key: "action",
      width: "90px",
      render: (_, record) => (
        // <div style={{display: "flex", justifyContent: "end"}}>
        <ProductListMenuOption productId={record.id} slug={record.slug} />
        // </div>
      ),
    },
  ];

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    if (searchTerm.length > 0 && state.products) {
      const filteredProducts = state.products.filter((x) =>
        x.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setProductList(filteredProducts);
    } else {
      setProductList(state.products ?? []);
    }
  };
  return (
    <div>
      <Space direction="vertical" size={20} style={{display: "flex"}}>
        <Input size="large" placeholder="Search Product" onChange={onSearch} />
        <Table
          columns={columns}
          dataSource={productData}
          scroll={{x: 100}}
          pagination={false}
          loading={state.isLoading}
        />
        <div>
          <Button
            onClick={() =>
              dispatch({
                payload: Math.max(state.currentPage - 1, 1),
                type: "update-current-page",
              })
            }
            disabled={state.currentPage === 1}
          >
            Prev
          </Button>
          <Button
            onClick={() => {
              if (!state.isPreviousData && Number(state.productMeta?.next_page) !== 0) {
                dispatch({
                  payload: state.currentPage + 1,
                  type: "update-current-page",
                });
              }
            }}
            // Disable the Next Page button until we know a next page is available
            disabled={state.isPreviousData || Number(state.productMeta?.next_page) === 0}
          >
            Next
          </Button>
        </div>
      </Space>
    </div>
  );
}
