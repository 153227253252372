import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

const useGetSavedCategories = () => {
  const client = useClient();
  return useQuery<string[], AxiosError<{status: boolean; message: string}>>({
    queryKey: ["saved_categories"],
    queryFn: () => client("saved-categories").then((data) => data.data.categories),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    // initialData: [],
  });
};

const useUpdateSavedCategories = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (category: string) =>
      client(`saved-categories`, {
        method: "POST",
        data: {category},
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach profile category", "Coach profile category fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach profile category", "Coach profile category success");
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({queryKey: ["saved_categories"]}),
  });
};
export {useGetSavedCategories, useUpdateSavedCategories};
