import {Button, Modal} from "antd";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {
  useCreateLiveClassBooking,
  useCreatePremiumLiveClassBooking,
  TPublicProfileLiveClassApiResponse,
} from "../../../../react-query-hooks/live-events/live-classes";
import {NewLiveClassBookingStep1} from "../../../../container/live-classes/booking/new/step-1";
import {handleLiveClassSuccessBookingLink} from "../../../../utils/live-class";
import {NewPayWithFlutterwaveStep} from "../../../../container/live-classes/booking/new/pay-with-flutterwave";
import {FlutterwaveConfig} from "flutterwave-react-v3/dist/types";
import moment from "moment";
import {NewPayWithPaystack} from "../../../../container/live-classes/booking/new/pay-with-paystack/pay-with-paystack";
import {PaystackProps} from "react-paystack/dist/types";
import {useLiveClassBookingContext} from "../../../../context/live-class-booking-context";
import {StyledPublicProfileOfferingDrawer} from "../../service/booking-modal";
import {CloseOutlined} from "@ant-design/icons";

type Currency = "NGN" | "GHS" | "USD" | "ZAR";

export function OfferingBookingModal() {
  const customer_timezone = moment.tz.guess(true);
  const {state, dispatch} = useLiveClassBookingContext();
  const createLiveClassBookingQueryMutation = useCreateLiveClassBooking();
  const createPremiumLiveClassBookingQueryMutation = useCreatePremiumLiveClassBooking();

  function updateStep(step: number) {
    if (step > 3) {
      return;
    }
    dispatch({
      type: "update-booking-step",
      payload: step,
    });
  }

  function handleBookingModalOk() {
    dispatch({type: "show-booking-modal", payload: false});
  }

  function handleBookingModalCancel() {
    // if (state.currentBookingStep === 4) {
    //   navigate(`/${state.eventData?.username}`);
    //   dispatch({type: "reset-state"});
    // }
    Modal.destroyAll();
    dispatch({type: "show-booking-modal", payload: false});
    dispatch({
      type: "update-booking-step",
      payload: 1,
    });
  }

  const fwConfigParams: FlutterwaveConfig = {
    amount: Number(state.liveClassBookingApiResponseData?.amount) || 0,
    customer: {
      email: `${state.liveClassFormValues?.emailAddress}`,
      name: `${state.liveClassFormValues?.firstName} ${state.liveClassFormValues?.lastName}`,
      phone_number: "",
    },
    customizations: {
      title: `Payment for ${state.eventData?.event.name}`,
      description: "",
      logo: "https://pbs.twimg.com/profile_images/1594744754356604929/WHpvwR8w_400x400.jpg",
    },
    payment_options: "card,mobilemoney,ussd",
    public_key: process.env.REACT_APP_FLWPUBK_TEST as string,
    tx_ref: state.liveClassBookingApiResponseData?.reference as string,
    currency: state.liveClassBookingApiResponseData?.currency as string,
    meta: {
      firstName: state.liveClassFormValues?.firstName,
      lastName: state.liveClassFormValues?.lastName,
      emailAddress: state.liveClassFormValues?.emailAddress,
      customer_timezone: customer_timezone,
    },
  };

  const psConfigParams: PaystackProps = {
    amount: (Number(state.liveClassBookingApiResponseData?.amount) || 0) * 100,
    email: state.liveClassBookingApiResponseData?.emailAddress as string,
    publicKey: process.env.REACT_APP_PSPUBK_TEST as string,
    currency: state.liveClassBookingApiResponseData?.currency as Currency,
    firstname: state.liveClassBookingApiResponseData?.firstName as string,
    lastname: state.liveClassBookingApiResponseData?.lastName as string,
    metadata: {
      firstName: state.liveClassBookingApiResponseData?.firstName,
      lastName: state.liveClassBookingApiResponseData?.lastName,
      emailAddress: state.liveClassBookingApiResponseData?.emailAddress,
      customer_timezone: customer_timezone,
      custom_fields: [
        {
          display_name: "firstName",
          variable_name: "firstName",
          value: state.liveClassBookingApiResponseData?.firstName as string,
        },
        {
          display_name: "lastName",
          variable_name: "lastName",
          value: state.liveClassBookingApiResponseData?.lastName as string,
        },
        {
          display_name: "emailAddress",
          variable_name: "emailAddress",
          value: state.liveClassBookingApiResponseData?.emailAddress as string,
        },
      ],
    },
    reference: state.liveClassBookingApiResponseData?.reference as string,
  };

  const successRedirectLink = handleLiveClassSuccessBookingLink({
    eventId: String(state.eventData?.event.id),
    slug: state.urlParams.slug as string,
    username: state.urlParams.username as string,
  });

  function handleView() {
    let content;
    switch (state.currentBookingStep) {
      // case 4:
      //   content = <NewSuccessStep extraParams={extraParams} />;
      //   break;
      case 3:
        content = (
          <NewPayWithPaystack
            updateStep={updateStep}
            psConfigParams={psConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      case 2:
        content = (
          <NewPayWithFlutterwaveStep
            updateStep={updateStep}
            fwConfigParams={fwConfigParams}
            successRedirectLink={successRedirectLink}
            handleCloseModal={handleBookingModalCancel}
          />
        );
        break;
      default:
      case 1:
        content = (
          <NewLiveClassBookingStep1
            eventData={state.eventData as TPublicProfileLiveClassApiResponse}
            createLiveClassBookingQueryMutation={
              state.urlParams.bookingRef
                ? createPremiumLiveClassBookingQueryMutation
                : createLiveClassBookingQueryMutation
            }
            updateStep={updateStep}
          />
        );
    }
    return content;
  }
  return (
    <StyledPublicProfileOfferingDrawer
      title={
        state.currentBookingStep === 1
          ? "Checkout"
          : state.currentBookingStep === 2 || state.currentBookingStep === 3
          ? "Payment"
          : null
      }
      footer={null}
      visible={state.isBookingModalOpen}
      // onOk={handleBookingModalOk}
      onClose={handleBookingModalCancel}
      maskClosable={false}
      closable={false}
      extra={
        <Button
          type="ghost"
          size="small"
          icon={<CloseOutlined />}
          onClick={handleBookingModalCancel}
          disabled={state.currentBookingStep !== 1}
        ></Button>
      }
    >
      {handleView()}
    </StyledPublicProfileOfferingDrawer>
  );
}
