import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {TWithdrawFormValues} from "../../components/payouts/balance-section/i-balance";
import {
  IConnectBankFormValues,
  IVerifyAccountNumber,
} from "../../pages/dashboard/payouts/i-connect-bank";
import {RootState} from "../store";
import {
  getPayoutCountriesService,
  getPayoutBanksService,
  validateBankAccountService,
  connectBankAccountService,
  fetchConnectedBankAccountService,
  disconnectConnectedBankAccountService,
  fetchAccountBalanceService,
  fetchPayoutsService,
  withdrawRequestService,
  fetchBankBranchesService,
  updateBankAccountService,
  fetchPendingPayoutService,
} from "./payout-service";

type TInitialState = {
  payoutCountries: {
    name: string;
    code: string;
  }[];
  payoutBanks: {
    id: number;
    code: string;
    name: string;
  }[];
  accountName: {
    name?: string;
  };
  accountBalance: {
    balance?: number;
  };
  pendingPayout: number;
  connectedBank: {
    userId?: number;
    country_name?: string;
    country_code?: string;
    bank_name?: string;
    bank_code?: string;
    account_number?: string;
    account_name?: string;
    provider?: string;
    currency?: string;
    id?: number;
  };
  bankBranches: {
    id: number;
    branch_code: string;
    branch_name: string;
    swift_code: string;
    bic: string;
    bank_id: number;
  }[];
  payoutRequests: {
    id: number;
    createdAt: Date;
    amount: number;
    statusName: string;
    reference: string;
  }[];
  fetchPayoutCountriesStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchPayoutBanksStatus: "idle" | "pending" | "resolved" | "rejected";
  validateAccountNumberStatus: "idle" | "pending" | "resolved" | "rejected";
  connectBankAccountStatus: "idle" | "pending" | "resolved" | "rejected";
  updateBankAccountStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchBankBranchesStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchConnectedBankAccountStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchAccountBalanceStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchPayoutsStatus: "idle" | "pending" | "resolved" | "rejected";
  disconnectConnectedBankAccountStatus: "idle" | "pending" | "resolved" | "rejected";
  withdrawRequestStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchPendingPayoutRequestStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
};

const initialState: TInitialState = {
  payoutCountries: [],
  payoutBanks: [],
  accountName: {},
  connectedBank: {},
  accountBalance: {},
  payoutRequests: [],
  pendingPayout: 0,
  bankBranches: [],
  fetchPayoutCountriesStatus: "idle",
  fetchPayoutBanksStatus: "idle",
  fetchBankBranchesStatus: "idle",
  validateAccountNumberStatus: "idle",
  connectBankAccountStatus: "idle",
  updateBankAccountStatus: "idle",
  fetchConnectedBankAccountStatus: "idle",
  disconnectConnectedBankAccountStatus: "idle",
  fetchAccountBalanceStatus: "idle",
  fetchPayoutsStatus: "idle",
  withdrawRequestStatus: "idle",
  fetchPendingPayoutRequestStatus: "idle",
  message: "",
};

//Get payout countries
export const getPayoutCountries = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/getPayoutCountries", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getPayoutCountriesService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get payout banks
export const getPayoutBanks = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/getPayoutBanks", async (countryCode, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getPayoutBanksService(token, countryCode);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Verify Account number
export const validateBankAccount = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IVerifyAccountNumber,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/validateBankAccount", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await validateBankAccountService(token, payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//connect Account
export const connectBankAccount = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IVerifyAccountNumber,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/connectBankAccount", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await connectBankAccountService(token, payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//update bank Account
export const updateBankAccount = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IVerifyAccountNumber,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/updateBankAccount", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateBankAccountService(token, payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//fetch connected Account
export const fetchConnectedBankAccount = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/fetchConnectedBankAccount", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await fetchConnectedBankAccountService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//disconnect connected Account
export const disconnectConnectedBankAccount = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/disconnectConnectedBankAccount", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await disconnectConnectedBankAccountService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//fetch bank branch
export const fetchBankBranches = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  number,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/fetchBankBranches", async (branchCode, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await fetchBankBranchesService(token, branchCode);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//fetch account balance
export const fetchAccountBalance = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/fetchAccountBalance", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await fetchAccountBalanceService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//fetch previous payouts
export const fetchPayouts = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/fetchPayouts", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await fetchPayoutsService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//fetch pending payouts
export const fetchPendingPayout = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/fetchPendingPayout", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await fetchPendingPayoutService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//initiate withdraw request
export const withdrawRequest = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  TWithdrawFormValues,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("payout/withdrawRequest", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await withdrawRequestService(token, payload);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    resetUserPayoutUpadatesState: (state) => {
      state.fetchPayoutCountriesStatus = "idle";
      state.message = "";
    },
    resetPayoutAccountName: (state) => {
      state.accountName = {};
    },
    payoutLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayoutCountries.pending, (state) => {
        state.fetchPayoutCountriesStatus = "pending";
      })
      .addCase(getPayoutCountries.fulfilled, (state, action) => {
        state.fetchPayoutCountriesStatus = "resolved";
        state.payoutCountries = action.payload.data.countries;
      })
      .addCase(getPayoutCountries.rejected, (state, action: any) => {
        state.fetchPayoutCountriesStatus = "rejected";
        state.message = action.payload;
        state.payoutCountries = [];
      })
      .addCase(getPayoutBanks.pending, (state) => {
        state.fetchPayoutBanksStatus = "pending";
      })
      .addCase(getPayoutBanks.fulfilled, (state, action) => {
        state.fetchPayoutBanksStatus = "resolved";
        state.payoutBanks = action.payload.data.data;
      })
      .addCase(getPayoutBanks.rejected, (state, action: any) => {
        state.fetchPayoutBanksStatus = "rejected";
        state.message = action.payload;
        state.payoutBanks = [];
      })
      .addCase(validateBankAccount.pending, (state) => {
        state.validateAccountNumberStatus = "pending";
      })
      .addCase(validateBankAccount.fulfilled, (state, action) => {
        state.validateAccountNumberStatus = "resolved";
        state.accountName = action.payload.data;
      })
      .addCase(validateBankAccount.rejected, (state, action: any) => {
        state.validateAccountNumberStatus = "rejected";
        state.message = action.payload;
        state.accountName = {};
      })
      .addCase(connectBankAccount.pending, (state) => {
        state.connectBankAccountStatus = "pending";
      })
      .addCase(connectBankAccount.fulfilled, (state, action) => {
        state.connectBankAccountStatus = "resolved";
        state.connectedBank = action.payload.data.bank;
        state.disconnectConnectedBankAccountStatus = "idle";
        state.validateAccountNumberStatus = "idle";
      })
      .addCase(updateBankAccount.rejected, (state, action: any) => {
        state.updateBankAccountStatus = "rejected";
        state.message = action.payload;
        // state.connectedBank = {};
      })
      .addCase(updateBankAccount.pending, (state) => {
        state.updateBankAccountStatus = "pending";
      })
      .addCase(updateBankAccount.fulfilled, (state, action) => {
        state.updateBankAccountStatus = "resolved";
        state.connectedBank = action.payload.data.bank;
        state.disconnectConnectedBankAccountStatus = "idle";
        state.validateAccountNumberStatus = "idle";
        state.connectBankAccountStatus = "idle";
      })
      .addCase(connectBankAccount.rejected, (state, action: any) => {
        state.connectBankAccountStatus = "rejected";
        state.message = action.payload;
        state.connectedBank = {};
      })
      .addCase(fetchConnectedBankAccount.pending, (state) => {
        state.fetchConnectedBankAccountStatus = "pending";
      })
      .addCase(fetchConnectedBankAccount.fulfilled, (state, action) => {
        state.fetchConnectedBankAccountStatus = "resolved";
        state.connectedBank = action.payload.data.bank;
      })
      .addCase(fetchConnectedBankAccount.rejected, (state, action: any) => {
        state.fetchConnectedBankAccountStatus = "rejected";
        state.message = action.payload;
        state.connectedBank = {};
      })
      .addCase(fetchBankBranches.pending, (state) => {
        state.fetchBankBranchesStatus = "pending";
      })
      .addCase(fetchBankBranches.fulfilled, (state, action) => {
        state.fetchBankBranchesStatus = "resolved";
        state.bankBranches =
          action.payload.data.branches === null ? [] : action.payload.data.branches.data;
      })
      .addCase(fetchBankBranches.rejected, (state, action: any) => {
        state.fetchBankBranchesStatus = "rejected";
        state.message = action.payload;
        state.bankBranches = [];
      })
      .addCase(disconnectConnectedBankAccount.pending, (state) => {
        state.disconnectConnectedBankAccountStatus = "pending";
      })
      .addCase(disconnectConnectedBankAccount.fulfilled, (state, action) => {
        state.disconnectConnectedBankAccountStatus = "resolved";
        state.connectedBank = {};
        state.connectBankAccountStatus = "idle";
        state.validateAccountNumberStatus = "idle";
      })
      .addCase(disconnectConnectedBankAccount.rejected, (state, action: any) => {
        state.disconnectConnectedBankAccountStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(fetchAccountBalance.pending, (state) => {
        state.fetchAccountBalanceStatus = "pending";
      })
      .addCase(fetchAccountBalance.fulfilled, (state, action) => {
        state.fetchAccountBalanceStatus = "resolved";
        state.accountBalance = action.payload.data;
      })
      .addCase(fetchAccountBalance.rejected, (state, action: any) => {
        state.fetchAccountBalanceStatus = "rejected";
        state.message = action.payload;
        state.accountBalance = {};
      })
      .addCase(fetchPendingPayout.pending, (state) => {
        state.fetchPendingPayoutRequestStatus = "pending";
      })
      .addCase(fetchPendingPayout.fulfilled, (state, action) => {
        state.fetchPendingPayoutRequestStatus = "resolved";
        state.pendingPayout = action.payload.data.pendingPayouts;
      })
      .addCase(fetchPendingPayout.rejected, (state, action: any) => {
        state.fetchPendingPayoutRequestStatus = "rejected";
        state.message = action.payload;
        // state.payoutRequests = [];
      })
      .addCase(fetchPayouts.pending, (state) => {
        state.fetchPayoutsStatus = "pending";
      })
      .addCase(fetchPayouts.fulfilled, (state, action) => {
        state.fetchPayoutsStatus = "resolved";
        state.payoutRequests = action.payload.data.requests;
      })
      .addCase(fetchPayouts.rejected, (state, action: any) => {
        state.fetchPayoutsStatus = "rejected";
        state.message = action.payload;
        // state.payoutRequests = [];
      })
      .addCase(withdrawRequest.pending, (state) => {
        state.withdrawRequestStatus = "pending";
      })
      .addCase(withdrawRequest.fulfilled, (state, action) => {
        state.withdrawRequestStatus = "resolved";
        // state.payoutRequests = action.payload.data.requests;
      })
      .addCase(withdrawRequest.rejected, (state, action: any) => {
        state.withdrawRequestStatus = "rejected";
        state.message = action.payload;
        // state.payoutRequests = [];
      });
  },
});

export const {resetUserPayoutUpadatesState, resetPayoutAccountName, payoutLogoutReset} =
  payoutSlice.actions;

export default payoutSlice.reducer;
