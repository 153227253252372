import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";

const ResetSuccessComponent = () => {
  const navigate = useNavigate();
  return (
    <Space direction="vertical" size={29} style={{display: "flex"}}>
      <div>
        <h2 className="form-main-heading">Email sent</h2>
        <p className="form-sub-heading">You'll get an email with further instructions</p>
      </div>
      <div>
        <Button
          icon={<ArrowLeftOutlined />}
          type="link"
          block
          onClick={() => navigate("/auth/login")}
        >
          Back to log in
        </Button>
      </div>
    </Space>
  );
};

export {ResetSuccessComponent};
