import * as yup from "yup";

const emailUpdateSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
});

export {emailUpdateSchema};
