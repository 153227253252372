import {Space} from "antd";
import {useGetProfileContext} from "../../../context/profile/profile-context";
import {StyledFormWrapper} from "../../../styles/onboarding/onboarding";
import {EmailUpdateForm} from "../email-update-form";
import {PasswordUpdateForm} from "../password-update-form";

export function AccountSettings() {
  const {state: profileState} = useGetProfileContext();
  return (
    <StyledFormWrapper style={{marginTop: 0, marginBottom: "3rem"}}>
      <Space direction="vertical" size={24} style={{display: "flex"}}>
        <EmailUpdateForm email={profileState.profileData.email.toLowerCase()} />
        <PasswordUpdateForm />
      </Space>
    </StyledFormWrapper>
  );
}
