import {MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu, message, Tooltip} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useCoachCancelBooking} from "../../../../../react-query-hooks/booking";
import {TBooking} from "../../../../../redux/call/i-call";
import {RootState} from "../../../../../redux/store";
import {CallRescheduleModal} from "./call-reschedule-modal";
import {UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {TFetchCustomerBookingPayload} from "../../../../../redux/booking/i-time-slots-payload";
import {handleMeetingLink} from "../../../../../utils/meeting";

type TCallCardMenuProps = {
  call: TBooking;
  showRescheduleCallModalDrawer: () => void;
  getCustomerBookingQueryMutation: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    TFetchCustomerBookingPayload,
    unknown
  >;
};

const CallCardMenu = ({
  call,
  getCustomerBookingQueryMutation,
  showRescheduleCallModalDrawer,
}: TCallCardMenuProps) => {
  const coachCancelQueryMutation = useCoachCancelBooking();
  const [toolTipText, setToolTipText] = useState<string>("Copy Url");
  const [openRescheduleCallModal, setOpenRescheduleCallModal] = useState<boolean>(false);
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const bookingData = getCustomerBookingQueryMutation?.data?.data;

  // const showRescheduleCallModal = () => {
  //   setOpenRescheduleCallModal(true);
  // };

  const handleOk = () => {
    setOpenRescheduleCallModal(false);
  };

  const handleCancel = () => {
    setOpenRescheduleCallModal(false);
  };

  function handleMeetingLinkString() {
    if (call.booking_type === "video") {
      if (bookingData.videoPlatform.toLowerCase() === "dailyco") {
        return `${bookingData.dailyCoUrl}?t=${bookingData.bookingToken}`;
      } else {
        return handleMeetingLink("host", {
          token: getCustomerBookingQueryMutation.data.data.bookingToken,
          roomId: call.roomId,
          firstName: userInfo?.user?.firstName,
          lastName: userInfo?.user?.lastName,
          meetingTitle: call.service.name,
        });
      }
    } else {
      return String(call.phone_number);
    }
  }

  // const disableButton = () => {
  //   if (call.statusName.toLowerCase() !== "in progress") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const menu = (
    <Menu
      items={[
        // {
        //   label: <div onClick={showRescheduleCallModal}>Ask to reschedule</div>,
        //   key: "0",
        // },
        {
          label: <div onClick={showRescheduleCallModalDrawer}>Reschedule call</div>,
          key: "0",
        },
        {
          label: (
            <div
              onClick={() =>
                coachCancelQueryMutation.mutate(call.reference, {
                  onSuccess() {
                    message.success(
                      "The selected booking has been cancelled successfully!"
                    );
                  },
                })
              }
            >
              {coachCancelQueryMutation.isLoading ? "Cancelling..." : "Cancel call"}
            </div>
          ),
          key: "2",
        },
      ].concat(
        getCustomerBookingQueryMutation.isSuccess
          ? [
              {
                label: (
                  <Tooltip title={toolTipText}>
                    <div
                      onClick={() =>
                        navigator.clipboard
                          .writeText(
                            call.booking_type === "video"
                              ? handleMeetingLinkString()
                              : String(call.phone_number)
                          )
                          .then(() => setToolTipText("Copied!"))
                      }
                    >
                      {call.booking_type === "video"
                        ? "Copy video call link"
                        : "Copy phone number"}
                    </div>
                  </Tooltip>
                ),
                key: "1",
              },
            ]
          : []
      )}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Button
          shape="circle"
          size="middle"
          icon={<MoreOutlined />}
          style={{
            backgroundColor: "transparent",
            color: "#00171F",
            border: "none",
            boxShadow: "none",
          }}
        />
      </Dropdown>
      <CallRescheduleModal
        visibleState={openRescheduleCallModal}
        okFunc={handleOk}
        cancelFunc={handleCancel}
        call={call}
      />
    </>
  );
};

export {CallCardMenu};
