import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {getTimezoneService, updateTimezoneService} from "./timezone-service";

interface IInitialState {
  timezone: null;
  status: "idle" | "pending" | "resolved" | "rejected";
  updateStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  timezone: null,
  status: "idle",
  updateStatus: "idle",
  message: "",
};

//create timezone
export const updateTimezone = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("timezone/updateTimezone", async (selections, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateTimezoneService(selections, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//get user timezone
export const getTimezone = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("timezone/getTimezone", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getTimezoneService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const timezoneSlice = createSlice({
  name: "timezone",
  initialState,
  reducers: {
    resetUsertimezoneState: (state) => {
      state.status = "idle";
      state.updateStatus = "idle";
      state.message = "";
    },
    userTimezoneLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTimezone.pending, (state) => {
        state.updateStatus = "pending";
      })
      .addCase(updateTimezone.fulfilled, (state, action) => {
        state.updateStatus = "resolved";
        state.timezone = action.payload.data.timeZone;
      })
      .addCase(updateTimezone.rejected, (state, action: any) => {
        state.updateStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(getTimezone.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getTimezone.fulfilled, (state, action) => {
        state.status = "resolved";
        state.timezone = action.payload.data.timeZone;
      })
      .addCase(getTimezone.rejected, (state, action: any) => {
        state.status = "rejected";
        state.message = action.payload;
      });
  },
});

export const {resetUsertimezoneState, userTimezoneLogoutReset} = timezoneSlice.actions;

export default timezoneSlice.reducer;
