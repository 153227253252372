import {Button, Modal, message} from "antd";
import {Footer} from "../../public-profile/footer";
import {
  StyledProductDetailsMainSectionActionButtons,
  StyledProductDetailsMainSectionContainer,
  StyledProductDetailsPageContainer,
  StyledProductDetailsWrapper,
} from "./product-details.style";
import {ArrowLeftOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {ProductDetailsMainSection} from "./main-details";
import {useViewProductContext} from "../../../context/products/view-product-context";
import {useDeleteProduct} from "../../../react-query-hooks/products";

export function ProductDetails() {
  const navigate = useNavigate();
  const {state} = useViewProductContext();
  const deleteProductMutation = useDeleteProduct();

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Confirm product deletion`,
      content:
        "Are you sure you want to delete this product? This action cannot be undone.",
      icon: <ExclamationCircleOutlined style={{color: "#F5222D"}} />,
      okText: "Delete product",
      cancelText: "Exit",
      okType: "danger",
      okButtonProps: {
        shape: "round",
        type: "primary",
      },
      cancelButtonProps: {
        shape: "round",
        type: "ghost",
      },
      onOk() {
        return new Promise((resolve, reject) => {
          if (state.productData.product.id) {
            resolve(
              deleteProductMutation.mutate(String(state.productData.product.id), {
                onSuccess: () => {
                  message.success("The selected product has been deleted successfully!");
                  navigate("/dashboard/products");
                },
              })
            );
          }
        }).catch(() => message.error("Failed to delete"));
      },
    });
  };

  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <StyledProductDetailsWrapper>
        <StyledProductDetailsPageContainer>
          <Link to="/dashboard/products">
            <Button icon={<ArrowLeftOutlined />} type="link" size="large">
              Back
            </Button>
          </Link>
          <StyledProductDetailsMainSectionContainer>
            <ProductDetailsMainSection />
            <StyledProductDetailsMainSectionActionButtons>
              <Link to={`/dashboard/products/edit/${state.productData.product.id}`}>
                <Button
                  className="product-main-details-action-btn"
                  size="large"
                  shape="round"
                >
                  Edit product
                </Button>
              </Link>
              <Button
                className="product-main-details-action-btn"
                onClick={handleDeleteModal}
                danger
                size="large"
                shape="round"
                type="text"
              >
                Delete product
              </Button>
            </StyledProductDetailsMainSectionActionButtons>
          </StyledProductDetailsMainSectionContainer>
        </StyledProductDetailsPageContainer>
      </StyledProductDetailsWrapper>
      <Footer />
    </div>
  );
}
