import {TGetProductApiResponse} from "../../../react-query-hooks/products";
import {ProductCustomerInsights} from "./customers-section";
import {RevenueSection} from "./revenue-section";

type TSalesAndInsights = {
  productData: TGetProductApiResponse;
};

export function SalesAndInsights({productData}: TSalesAndInsights) {
  return (
    <div>
      <RevenueSection productData={productData} />
      <ProductCustomerInsights productId={productData.product.id} />
    </div>
  );
}
