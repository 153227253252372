import {Alert, Col, Row} from "antd";
import {StyledContainer} from "../../features-section/features-section.style";
import {StyledUpcomingEventList, StyledUpcomingTag} from "./upcoming-events.style";
import {UpcomingEventCard} from "./card";
import {useGetCoachliEvents} from "../../../../react-query-hooks/live-events/events";
import {UpcomingEventsSkeletonLoader} from "./skeleton-loader";
import {StyledNoUpcomingCallBox} from "../../../calls/upcoming-calls/upcoming-calls.style";

export function UpcomingEvents() {
  const getCoachliEventsQuery = useGetCoachliEvents();

  if (getCoachliEventsQuery.isLoading) {
    return (
      <div>
        <StyledUpcomingTag>
          <p>Upcoming Events</p>
        </StyledUpcomingTag>
        <StyledContainer>
          <StyledUpcomingEventList>
            <Row gutter={[45, 24]}>
              {Array.from({length: 2}).map((_, index) => (
                <Col span={24} sm={12} key={index}>
                  <UpcomingEventsSkeletonLoader />
                </Col>
              ))}
            </Row>
          </StyledUpcomingEventList>
        </StyledContainer>
      </div>
    );
  }
  if (
    getCoachliEventsQuery.isError &&
    getCoachliEventsQuery.error.message !== "You cannot retrieve this event at this time!"
  ) {
    return (
      <div>
        <StyledUpcomingTag>
          <p>Upcoming Events</p>
        </StyledUpcomingTag>
        <StyledContainer>
          <StyledUpcomingEventList>
            <Alert
              message="Error"
              description={getCoachliEventsQuery.error.message}
              type="error"
              showIcon
            />
          </StyledUpcomingEventList>
        </StyledContainer>
      </div>
    );
  }

  return (
    <div>
      <StyledUpcomingTag>
        <p>Upcoming Events</p>
      </StyledUpcomingTag>
      <StyledContainer>
        <StyledUpcomingEventList>
          <Row gutter={[45, 24]}>
            {getCoachliEventsQuery.data &&
            getCoachliEventsQuery.data.events.length > 0 ? (
              getCoachliEventsQuery.data.events.map((event) => (
                <Col span={24} sm={12} key={event.id}>
                  <UpcomingEventCard event={event} />
                </Col>
              ))
            ) : (
              <div style={{width: "100%"}}>
                <StyledNoUpcomingCallBox>
                  <p className="info-text" style={{maxWidth: "56.2rem"}}>
                    We're curating some amazing experiences at Coachli! Be the first to
                    know about our upcoming events. Drop your email above, and we'll keep
                    you in the loop!
                  </p>
                </StyledNoUpcomingCallBox>
              </div>
            )}
          </Row>
        </StyledUpcomingEventList>
      </StyledContainer>
    </div>
  );
}
