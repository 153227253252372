import * as yup from "yup";

const bookingSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email"),
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  phone_number: yup
    .string()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim(),
  bookingQuestions: yup.array(
    yup.object({
      required: yup.boolean(),
      answer: yup.mixed().when("required", {
        is: true,
        then: yup.string().trim().required("Answer is required"),
      }),
    })
  ),
});
const liveClassBookingPhoneNumberRequiredSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email"),
  firstName: yup
    .string()
    .trim()
    .required("First name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  lastName: yup
    .string()
    .trim()
    .required("Last name is required")
    .matches(/^[A-Z]+$/i, "Must be only alphabets"),
  phone_number: yup
    .string()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .required("Phone number field is a required field")
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim(),
  bookingQuestions: yup.array(
    yup.object({
      required: yup.boolean(),
      answer: yup.mixed().when("required", {
        is: true,
        then: yup.string().trim().required("Answer is required"),
      }),
    })
  ),
});
export {bookingSchema, liveClassBookingPhoneNumberRequiredSchema};
