import {BrandImage} from "../../brand-image";
import {
  StyledFooter,
  StyledFooterHref,
  StyledFooterLink,
  StyledFooterWrapper,
} from "./footer.style";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterWrapper>
        <BrandImage variant="primary" />
        <div className="footer-links">
          <StyledFooterLink to={"/"} target="_blank" rel="noreferrer">
            About
          </StyledFooterLink>
          <StyledFooterLink to={"/home/contact"} target="_blank" rel="noreferrer">
            Contact
          </StyledFooterLink>
          <StyledFooterHref
            href="https://coachli.notion.site/Helpdesk-4039d986b45e4236a5ee76a5e4de45a5"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </StyledFooterHref>
        </div>

        <div className="footer-extra-links">
          <span>© 2023 Coachli</span>
          <StyledFooterLink to={"/home/terms"} target="_blank" rel="noreferrer">
            Terms of service
          </StyledFooterLink>
          <StyledFooterHref
            href="https://www.notion.so/coachli/PRIVACY-POLICY-6e6e3a3a65d141848e0053b62138d6bc"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </StyledFooterHref>
        </div>
      </StyledFooterWrapper>
    </StyledFooter>
  );
};

export {Footer};
