import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, message, Radio} from "antd";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {AnalyticsEvent} from "../../../utils/analytics";
import {
  StyledModalContentWrapper,
  StyledModalContentInfo,
} from "../../calls/upcoming-calls/call-card/call-card.style";
import {CustomModal} from "../../modal";
import {
  StyledCallOfferingItem,
  StyledCallOfferingItemWrapper,
} from "./call-offering-modal.style";
import {ProductBagIcon} from "../../icons/icons";

interface ICallOfferingModalProps {
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
}

const schema = yup.object().shape({
  callOffering: yup
    .string()
    .required()
    .oneOf(["oneOff", "liveClass", "products"], "Select a category"),
});

const CallOfferingModal: React.FC<ICallOfferingModalProps> = ({
  cancelFunc,
  okFunc,
  visibleState,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<{callOffering: "oneOff" | "liveClass" | "products"}>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      callOffering: "oneOff",
    },
  });

  const onSubmit = (values: {callOffering: "oneOff" | "liveClass" | "products"}) => {
    const {callOffering} = values;
    if (callOffering === "oneOff") {
      AnalyticsEvent("Service Option Modal", `Selected one off call category`);
      okFunc();
      return;
    }
    if (callOffering === "liveClass") {
      AnalyticsEvent("Service Option Modal", `Selected liveClass call category`);
      return navigate("/dashboard/live-classes/new");
    }
    if (callOffering === "products") {
      AnalyticsEvent("Service Option Modal", `Selected products call category`);
      return navigate("/dashboard/products/new");
    }
    message.warn("Select a valid category");
  };
  return (
    <CustomModal
      visibleState={visibleState}
      title="Create a new offering"
      okFunc={okFunc}
      cancelFunc={cancelFunc}
      footerContent={[
        <Button
          disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
          key="continue"
          shape="round"
          type="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>,
        <Button key="cancel" type="default" shape="round" onClick={() => cancelFunc()}>
          Cancel
        </Button>,
      ]}
    >
      <StyledModalContentWrapper>
        {" "}
        <StyledModalContentInfo>
          {/* <p className="content-text">
            Create a new 1:1 call service, choose between a one off call or a liveClass of
            calls with your customers.
          </p> */}
          <div
            style={{
              marginTop: "2.4rem",
            }}
          >
            <Controller
              control={control}
              name="callOffering"
              render={({field: {onChange, value}}) => (
                <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                  <StyledCallOfferingItemWrapper>
                    <StyledCallOfferingItem active={watch("callOffering") === "oneOff"}>
                      <div className="content-wrapper">
                        <div className="icon-container">
                          {/* <img src="/images/video.svg" alt="video icon" /> */}
                          🤙
                        </div>
                        <div className="location-info">
                          <h3 className="title">1:1 call service </h3>
                          <p className="sub-info">
                            Start hosting 1:1 sessions with your audience and clients
                          </p>
                        </div>
                      </div>

                      <div>
                        <Radio value={"oneOff"} />
                      </div>
                    </StyledCallOfferingItem>
                    <StyledCallOfferingItem
                      active={watch("callOffering") === "liveClass"}
                    >
                      <div className="content-wrapper">
                        <div className="icon-container">🧑‍🏫</div>
                        <div className="location-info">
                          <h3 className="title">Live class</h3>
                          <p className="sub-info">
                            Start hosting webinars, group sessions and classes online
                          </p>
                        </div>
                      </div>
                      <div>
                        <Radio value={"liveClass"} />
                      </div>
                    </StyledCallOfferingItem>
                    <StyledCallOfferingItem active={watch("callOffering") === "products"}>
                      <div className="content-wrapper">
                        <div className="icon-container">
                          <ProductBagIcon />
                        </div>
                        <div className="location-info">
                          <h3 className="title">Product</h3>
                          <p className="sub-info">
                            Create and sell digital products to your audience and clients
                          </p>
                        </div>
                      </div>
                      <div>
                        <Radio value={"products"} />
                      </div>
                    </StyledCallOfferingItem>
                  </StyledCallOfferingItemWrapper>
                </Radio.Group>
              )}
            />
          </div>
        </StyledModalContentInfo>
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

export {CallOfferingModal};
