import {DollarOutlined, CalendarOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {Space, Button, Tooltip} from "antd";
// @ts-ignore
import Truncate from "react-truncate-html";
import moment from "moment";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  TCustomerEvent,
  useCustomerJoinLiveClass,
} from "../../../../../react-query-hooks/live-events/live-classes";
import {RootState} from "../../../../../redux/store";
import {StyledCard, StyledClassCardContent} from "../card.style";
import {handleMeetingLink} from "../../../../../utils/meeting";

export type TCustomerLiveClassCardProps = {
  eventData: TCustomerEvent;
};

export function CustomerLiveClassCard({eventData}: TCustomerLiveClassCardProps) {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const joinLiveClassQueryMutation = useCustomerJoinLiveClass();
  // videoSdk
  function handleJoinLiveClass() {
    joinLiveClassQueryMutation.mutate(eventData.reference, {
      onSuccess: (data) => {
        setTimeout(() => {
          if (data.data.videoPlatform.toLowerCase() === "dailyco") {
            window.open(`${data.data.dailyCoUrl}?t=${data.data.bookingToken}`);
          } else {
            window.open(
              handleMeetingLink("guest", {
                token: data.data.bookingToken,
                roomId: data.data.event.roomId,
                firstName: userInfo?.user?.firstName,
                lastName: userInfo?.user?.lastName,
                meetingTitle: data.data.event.name,
              })
            );
          }
        }, 0);
      },
    });
  }

  const disableButton = () => {
    const classStartTime = moment(
      `${eventData.event_date} ${eventData.customer_event_time}`,
      "YYYY-MM-DD h:mm A",
      true
    );
    const dateToday = moment(new Date());
    const timeDiff = classStartTime.diff(dateToday, "minutes");

    // console.log({classStartTime, timeDiff});

    // if (eventData.statusName.toLowerCase() !== "in progress") {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  let stripedHtml = eventData.liveEvent.description.replace(/<[^>]+>/g, "");
  const eventDescription = stripedHtml;

  return (
    <StyledCard title={eventData.liveEvent.name}>
      <StyledClassCardContent>
        <div className="class-card-image-wrapper">
          <img
            className="class-card-image"
            src={eventData.liveEvent.cover_image}
            alt={eventData.liveEvent.name}
          />
        </div>
        <p className="class-card-info-text">{eventDescription}</p>
        {/* <div
          className="class-card-info-text"
          dangerouslySetInnerHTML={{__html: description}}
        /> */}
        {/* <p>{name ?? "This is a draft name"}</p> */}
        <div className="class-card-extra-info">
          <div>
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span>
                {eventData.liveEvent.callPrice === 0
                  ? "Free"
                  : eventData.liveEvent.callPriceCurrency +
                    " " +
                    eventData.liveEvent.callPrice.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div>
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span>
                {new Date(eventData.event_date).toDateString()},{" "}
                {eventData.customer_event_time}
              </span>
            </Space>
          </div>
          <div>
            <Space size={10}>
              <UsergroupAddOutlined style={{color: "#7939CC"}} />
              <span>
                {eventData.liveEvent.attendees === -1
                  ? "Unlimited"
                  : eventData.liveEvent.attendees}{" "}
                attending
              </span>
            </Space>
          </div>
        </div>
        <div className="class-card-start-btn">
          <Tooltip title={"Join class"}>
            <Button
              onClick={handleJoinLiveClass}
              shape="round"
              type="primary"
              block
              size="large"
              loading={joinLiveClassQueryMutation.isLoading}
            >
              Join class
            </Button>
          </Tooltip>
        </div>
      </StyledClassCardContent>
    </StyledCard>
  );
}
