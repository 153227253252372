import {Input} from "antd";
import styled from "styled-components";

export const StyledLiveEventsHeroSectionInput = styled(Input)`
  border-radius: 2.5rem 0 0 2.5rem !important;
  width: 36.9rem !important;

  @media (max-width: 520px) {
    width: 100% !important;
  }
`;
export const StyledLiveEventsHeroSection = styled.div`
  margin-top: 8.1rem;
  background: linear-gradient(180deg, #f5edff 1.87%, #f0f5ff 50.05%, #fff 100%);
`;

export const StyledLiveEventsHeroSectionContent = styled.div`
  padding-top: 8.5rem;
  padding-bottom: 9.6rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  .live-events-hero-main-heading {
    font-weight: 700;
    font-size: 3.8rem;
    line-height: 46px;
    text-align: center;
    max-width: 81rem;
    /* font-family: "Charis SIL"; */
    /* max-width: 112rem; */
  }
  .live-events-hero-sub-heading {
    font-weight: 400;
    font-size: 2rem;
    line-height: 28px;
    text-align: center;
    max-width: 65.8rem;
  }
  .live-events-hero-form {
    margin-top: 2.4rem;
    max-width: 50rem;
  }

  @media (min-width: 425px) {
    gap: 4rem;
    padding-top: 11.5rem;
    .live-events-hero-main-heading {
      font-size: 5.6rem;
      line-height: 63px;
    }
    .live-events-hero-sub-heading {
      font-size: 3rem;
      line-height: 38px;
    }
    .live-events-hero-form {
      margin-top: 0;
    }
  }
`;
