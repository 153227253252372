import {useInfiniteQuery, useMutation, useQuery} from "@tanstack/react-query";
import {useClient} from "../../utils/hooks";
import {AxiosError} from "axios";
import {TLiveClassApiResponse} from "./live-classes";
import {message} from "antd";
import {AnalyticsEvent} from "../../utils/analytics";
import {PaginationMeta} from "../../redux/service/i-service";

export type TLiveEventsUser = {
  profile: {
    id: number;
    userId: number;
    username: string;
    description: string;
    shortBio: string;
    twitter?: string;
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    discord?: string;
    youtube?: string;
    dribble?: string;
    behance?: string;
    twitch?: string;
    github?: string;
    website?: string;
    linkedin?: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type TCoachliEventsApiResponse = {
  events: Array<TLiveClassApiResponse & TLiveEventsUser>;
};

const useGetCoachliEvents = () => {
  const client = useClient();

  return useQuery<
    TCoachliEventsApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["coachli_events"],
    queryFn: () => client(`live-event-coachli-events`).then((data) => data.data),
    refetchInterval: 300000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
const useGetUpcomingPaidEvents = () => {
  const client = useClient();

  return useInfiniteQuery<
    {data: TCoachliEventsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["upcoming_events_paid"],
    queryFn: ({pageParam = 1}) =>
      client(`live-event-paid-events?page=${pageParam}&per_page=12`).then((data) => data),
    refetchInterval: 300000,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      // console.log({lastPage, pages});
      if (lastPage.meta) {
        return lastPage.meta.next_page === 0 ? null : lastPage.meta.next_page;
      } else {
        return null;
      }
    },
  });
};
const useGetUpcomingFreeEvents = () => {
  const client = useClient();

  return useInfiniteQuery<
    {data: TCoachliEventsApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["upcoming_events_free"],
    queryFn: ({pageParam = 1}) =>
      client(`live-event-free-events?page=${pageParam}&per_page=12`).then((data) => data),
    refetchInterval: 300000,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      // console.log({lastPage, pages});
      if (lastPage.meta) {
        return lastPage.meta.next_page === 0 ? null : lastPage.meta.next_page;
      } else {
        return null;
      }
    },
  });
};

const useSubscribeToLiveEvent = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {emailAddress: string}) =>
      client(`live-event-subscribe`, {
        method: "POST",
        data: payload,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Live events", "Subscription fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Live events", "Subscription success");
    },
  });
};

export {
  useGetCoachliEvents,
  useGetUpcomingPaidEvents,
  useGetUpcomingFreeEvents,
  useSubscribeToLiveEvent,
};
