import {BankOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Space, Select, Spin, Input, message, Button, Col, Row, Alert} from "antd";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {StyledModalContentWrapper} from "../../../components/calls/upcoming-calls/call-card/call-card.style";
import {StyledInputLabelWrapper} from "../../../components/input/input.style";
import {CustomModal} from "../../../components/modal";
import {DisconnectBankComponent} from "../../../components/payout/disconnect-bank-component";
import {connectBankFormSchema} from "../../../pages/dashboard/payouts/connect-bank-form-validation-schema";
import {IConnectBankFormValues} from "../../../pages/dashboard/payouts/i-connect-bank";
import {StyledServiceButtonWrapper} from "../../../styles/onboarding/onboarding";
import {
  StyledConnectBankBoxWrapper,
  StyledConnectBankBox,
} from "../../../styles/payouts/payouts";
import {IOnboardingStepProps} from "../i-step";
import {
  useGetPayoutCountries,
  useGetConnectedBankAccount,
  useGetPayoutBanks,
  useConnectBank,
  useValidateBankAccount,
  TConnectBankPayload,
  useConnectToStripe,
  TConnectToStripePayload,
} from "../../../react-query-hooks/payout";
import {isNonEu} from "../../../utils/bank";

const {Option} = Select;

const OnboardingBankAccountStep: React.FC<IOnboardingStepProps> = ({updateStep}) => {
  const [openConnectBankModal, setOpenConnectBankModal] = useState<boolean>(false);
  const getPayoutCountriesQuery = useGetPayoutCountries();
  const getConnectedBankAccount = useGetConnectedBankAccount();
  const getPayoutBanksQueryMutation = useGetPayoutBanks();
  const connectBankQueryMutation = useConnectBank();
  const connectToStripeQueryMutation = useConnectToStripe();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    reset,
    setValue,
  } = useForm<IConnectBankFormValues>({
    resolver: yupResolver(connectBankFormSchema),
    mode: "onChange",
  });

  const accountNumberValue = watch("accountNumber");
  const countryCodeValue = watch("countryCode");
  const bankCodeValue = watch("bankCode");
  const bankName: {id: number; code: string; name: string} =
    getPayoutBanksQueryMutation.isSuccess &&
    getPayoutBanksQueryMutation.data.data.find(
      (bank: {id: number; code: string; name: string}) => bank.code === bankCodeValue
    );

  const validateBankAccountQueryMutation = useValidateBankAccount({
    accountNumber:
      accountNumberValue && accountNumberValue.length === 10
        ? accountNumberValue
        : undefined,
    bankCode: bankCodeValue,
    bankName: bankName?.name,
    countryCode: countryCodeValue,
  });

  if (getPayoutCountriesQuery.isLoading || getConnectedBankAccount.isLoading) {
    return (
      <div
        style={{
          height: "200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (
    getPayoutCountriesQuery.isError ||
    (getConnectedBankAccount.isError &&
      getConnectedBankAccount.error.message !==
        "Sorry, You do not have a bank account saved! Please, create one and try again!")
  ) {
    return (
      <Alert
        message="Error"
        description={
          getPayoutCountriesQuery.error?.message || getConnectedBankAccount.error?.message
        }
        type="error"
        showIcon
      />
    );
  }
  const hasConnectedBank =
    Object.keys(getConnectedBankAccount.data?.bank || {}).length > 0;
  const showConnectBankModal = () => {
    setOpenConnectBankModal(true);
  };
  const handleCancel = () => {
    // dispatch(payoutLogoutReset());
    setValue("countryCode", "");
    reset();
    setOpenConnectBankModal(false);
  };
  const handleOk = () => {
    reset();
    setOpenConnectBankModal(false);
  };

  const handleCountrySelectChange = (e: any, onChange: any) => {
    onChange(e);
    setValue("accountNumber", "");
    if (isNonEu.includes(e)) {
      getPayoutBanksQueryMutation.mutate({countryCode: e});
      setValue("bankCode", "");
    }
  };

  const handleBankSelectChange = (e: any, onChange: any) => {
    onChange(e);
  };

  const onSubmit = async (values: IConnectBankFormValues) => {
    if (isNonEu.includes(countryCodeValue)) {
      //TODO: COMPLETE CONNECTION FOR NORMAL BANK
      const bankName: {id: number; code: string; name: string} =
        getPayoutBanksQueryMutation.data.data.find(
          (bank: {id: number; code: string; name: string}) =>
            bank.code === values.bankCode
        );

      const payload: TConnectBankPayload = {
        accountNumber: values.accountNumber,
        bankCode: values.bankCode,
        bankName: bankName.name,
        countryCode: values.countryCode,
        routingNumber: values.routingNumber,
      };
      connectBankQueryMutation.mutate(payload, {
        onSuccess: () => {
          message.success("Bank updated successfully!");
          handleOk();
        },
      });
    } else {
      //TODO: COMPLETE CONNECTION FOR STRIPE
      const countryPayload = getPayoutCountriesQuery.data.data.countries.find(
        (country: {name: string; code: string; currency: string}) =>
          country.code === values.countryCode
      );
      const stripePayload: TConnectToStripePayload = {
        countryCode: values.countryCode,
        countryName: countryPayload ? countryPayload.name : "",
        currencyCode: countryPayload ? countryPayload.currency : "",
      };
      connectToStripeQueryMutation.mutate(stripePayload, {
        onSuccess: (data) => {
          handleCancel();
          message.success(
            "Your bank account has been created successfully! Please, complete your registration!"
          );
          // window.open(data.data.LoginLink);
          // console.log({data});
        },
      });
    }
  };
  return (
    <div>
      <Spin
        spinning={
          getConnectedBankAccount.isLoading ||
          getConnectedBankAccount.isFetching ||
          connectBankQueryMutation.isLoading
        }
      >
        <StyledConnectBankBoxWrapper>
          <p>Set up your payout details to enable you recieve your earnings</p>
          {hasConnectedBank ? (
            <DisconnectBankComponent />
          ) : (
            <StyledConnectBankBox>
              <div className="bank-icon-container">
                <BankOutlined style={{fontSize: "2.5rem", color: "#2F54EB"}} />
              </div>
              <div className="bank-connect-text">
                <h3>Bank Account</h3>
                <p>Connect your bank account</p>
              </div>
              <div className="bank-connect-btn">
                <Button
                  onClick={showConnectBankModal}
                  type="primary"
                  block
                  shape="round"
                  size="large"
                >
                  Connect account
                </Button>
              </div>
            </StyledConnectBankBox>
          )}
        </StyledConnectBankBoxWrapper>
        <Row gutter={[0, 16]} style={{marginTop: "3.5rem"}}>
          <Col span={24} sm={12}>
            <StyledServiceButtonWrapper>
              <Button
                block
                size="large"
                shape="round"
                type="default"
                htmlType="button"
                onClick={() => updateStep(1)}
              >
                Back
              </Button>
              <Button
                onClick={() => updateStep(3)}
                block
                size="large"
                shape="round"
                type="primary"
                htmlType="submit"
                // disabled={
                //   Object.keys(errors).length > 0 ||
                //   validateBankAccountQueryMutation.isError ||
                //   !hasConnectedBank
                // }
                loading={
                  connectBankQueryMutation.isLoading ||
                  connectToStripeQueryMutation.isLoading
                }
              >
                {hasConnectedBank ? "Next" : "Skip"}
              </Button>
            </StyledServiceButtonWrapper>
          </Col>
          {/* <Col span={24} sm={{span: 12}}>
          <Button block size="large" type="link" htmlType="button">
            I’ll do this later
          </Button>
        </Col> */}
        </Row>
      </Spin>
      <CustomModal
        visibleState={openConnectBankModal}
        title="Connect your bank account"
        okFunc={handleOk}
        cancelFunc={handleCancel}
        footerContent={[
          <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            shape="round"
            onClick={handleSubmit(onSubmit)}
            disabled={
              Object.keys(errors).length > 0 ||
              Object.values(watch()).length < 1 ||
              validateBankAccountQueryMutation.isError
            }
            loading={connectBankQueryMutation.isLoading}
          >
            Complete connection
          </Button>,
        ]}
      >
        <>
          <StyledModalContentWrapper>
            <p>Your earnings will be sent to this bank account </p>
            <div style={{margin: "3.3rem 0"}}>
              <Space direction="vertical" size={25} style={{display: "flex"}}>
                <Controller
                  control={control}
                  name="countryCode"
                  render={({field: {onChange, value, ref}}) => (
                    <div>
                      <StyledInputLabelWrapper>
                        <label id="country-label" htmlFor="country">
                          <span style={{color: "#f5222d", marginRight: ".8rem"}}>*</span>{" "}
                          Country
                        </label>
                      </StyledInputLabelWrapper>

                      <Select
                        onChange={(e) => handleCountrySelectChange(e, onChange)}
                        value={value}
                        ref={ref}
                        style={{width: "100%"}}
                        id="country"
                        size="large"
                        status={errors.countryCode?.message ? "error" : ""}
                        showSearch
                        placeholder="Select country"
                        loading={
                          getPayoutCountriesQuery.isFetching ||
                          getPayoutCountriesQuery.isLoading
                        }
                        disabled={
                          getPayoutCountriesQuery.isLoading ||
                          getPayoutCountriesQuery.isFetching ||
                          getPayoutCountriesQuery.isError
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        notFoundContent={
                          getPayoutCountriesQuery.isLoading ||
                          getPayoutCountriesQuery.isFetching ? (
                            <Spin />
                          ) : null
                        }
                      >
                        {getPayoutCountriesQuery.data.data.countries.map(
                          (option, optionIdx: number) => (
                            <Option key={optionIdx} value={option.code}>
                              {option.name}
                            </Option>
                          )
                        )}
                      </Select>
                      {errors.countryCode?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.countryCode?.message}
                        </div>
                      )}
                    </div>
                  )}
                />
                {isNonEu.includes(countryCodeValue) && (
                  <Controller
                    control={control}
                    name="bankCode"
                    render={({field: {onChange, value, ref}}) => (
                      <div>
                        <StyledInputLabelWrapper>
                          <label id="bank-label" htmlFor="bank">
                            <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                              *
                            </span>
                            Bank
                          </label>
                        </StyledInputLabelWrapper>

                        <Select
                          onChange={(e) => handleBankSelectChange(e, onChange)}
                          value={value}
                          ref={ref}
                          style={{width: "100%"}}
                          id="bank"
                          size="large"
                          status={errors.bankCode?.message ? "error" : ""}
                          showSearch
                          placeholder="Select bank"
                          loading={getPayoutBanksQueryMutation.isLoading}
                          disabled={
                            getPayoutBanksQueryMutation.isLoading || !countryCodeValue
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          notFoundContent={
                            getPayoutBanksQueryMutation.isLoading ? <Spin /> : null
                          }
                        >
                          {getPayoutBanksQueryMutation.isSuccess
                            ? getPayoutBanksQueryMutation.data.data.map(
                                (option: {id: number; code: string; name: string}) => (
                                  <Option key={option.id} value={option.code}>
                                    {option.name}
                                  </Option>
                                )
                              )
                            : []}
                        </Select>
                        {errors.bankCode?.message && (
                          <div role="alert" className="ant-form-item-explain-error">
                            {errors.bankCode?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
                {isNonEu.includes(countryCodeValue) && (
                  <>
                    <Controller
                      control={control}
                      name="accountNumber"
                      render={({field: {onChange, value}}) => (
                        <>
                          <StyledInputLabelWrapper>
                            <label id="accountNumber" htmlFor="accountNumber">
                              <span style={{color: "#f5222d", marginRight: ".8rem"}}>
                                *
                              </span>{" "}
                              <span>Account number</span>
                            </label>
                          </StyledInputLabelWrapper>
                          <Input
                            id="accountNumber"
                            status={errors.accountNumber?.message && "error"}
                            size="large"
                            placeholder="Enter account number"
                            disabled={
                              !watch("countryCode") ||
                              !watch("bankCode") ||
                              (accountNumberValue?.length === 10 &&
                                validateBankAccountQueryMutation.isSuccess)
                            }
                            onChange={onChange}
                            value={value}
                          />
                          {errors.accountNumber?.message && (
                            <div role="alert" className="ant-form-item-explain-error">
                              {errors.accountNumber?.message}
                            </div>
                          )}
                        </>
                      )}
                    />
                    {validateBankAccountQueryMutation.isSuccess && (
                      <p style={{fontSize: "1.4rem", textTransform: "uppercase"}}>
                        {validateBankAccountQueryMutation.data.name}
                      </p>
                    )}
                  </>
                )}
              </Space>
            </div>
          </StyledModalContentWrapper>
        </>
      </CustomModal>
    </div>
  );
};

export {OnboardingBankAccountStep};
