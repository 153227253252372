import {TLiveClassApiResponse} from "../../../react-query-hooks/live-events/live-classes";

export type CreateLiveClassFormSteps = "basic" | "cohost";
type UpdateCreateLiveClassFormStep = {
  type: "update-create-live-class-form-step";
  payload: CreateLiveClassFormSteps;
};
type UpdateCreateLiveClassApiResponse = {
  type: "update-create-live-class-api-response";
  payload: TLiveClassApiResponse;
};

type ResetCreateLiveClassFormState = {
  type: "reset-state";
};

export type CreateLiveClassFormActions =
  | UpdateCreateLiveClassFormStep
  | UpdateCreateLiveClassApiResponse
  | ResetCreateLiveClassFormState;

export type CreateLiveClassFormState = {
  currentCreateLiveClassFormStep: CreateLiveClassFormSteps;
  liveClassData?: TLiveClassApiResponse;
};

export const initialCreateLiveClassFormState: CreateLiveClassFormState = {
  currentCreateLiveClassFormStep: "basic",
};

export const createLiveClassFormReducer = (
  state: CreateLiveClassFormState = initialCreateLiveClassFormState,
  action: CreateLiveClassFormActions
) => {
  if (action.type === "update-create-live-class-form-step") {
    return {...state, currentCreateLiveClassFormStep: action.payload};
  }
  if (action.type === "update-create-live-class-api-response") {
    return {...state, liveClassData: action.payload};
  }
  if (action.type === "reset-state") {
    return {...initialCreateLiveClassFormState};
  }

  return state;
};
