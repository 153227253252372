import styled from "styled-components";

export const StyledPublicProfileOfferingImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;
  border-radius: 1rem;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }
`;
