import {Breadcrumb, Space, Steps} from "antd";
import {Link} from "react-router-dom";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {LiveClassesForm} from "../../../components/live-classes/live-classes-form/live-classes-form";
import {LiveClassesTemplateWidget} from "../../../components/live-classes/template-widget/template-widget";
import {useCreateLiveClassContext} from "../../../context/live-class/create-live-class";
import {AddCoHost} from "../../../components/live-classes/class-details/add-cohost";

function LiveClassCreatePage() {
  const {state, dispatch} = useCreateLiveClassContext();

  const mapStepToNumber = {
    basic: 0,
    cohost: 1,
  };

  function onStepChange(value: number) {
    dispatch({
      type: "update-create-live-class-form-step",
      payload: value === 0 ? "basic" : "cohost",
    });
  }

  function handleView() {
    let content;
    switch (state.currentCreateLiveClassFormStep) {
      case "basic":
        content = <LiveClassesForm formType="new" />;
        break;
      default:
      case "cohost":
        content = <AddCoHost />;
    }
    return content;
  }
  return (
    <DashboardWrapper pageTitle="Create new live class">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/live-classes">Live classes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create new live class</Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <div>
            <Space size={18} direction="vertical" style={{display: "flex"}}>
              <p
                style={{
                  color: "#595959",
                  fontSize: "1.6rem",
                }}
              >
                Host and sell tickets to a live class, no need for zoom or Google meet
              </p>
              {/* <HorizontalStepper stepArray={steps} currentStep={currentStep} /> */}
              <Steps
                onChange={onStepChange}
                current={mapStepToNumber[state.currentCreateLiveClassFormStep]}
                size="small"
              >
                <Steps.Step title="Class details" />
                <Steps.Step
                  title="Add co-host"
                  disabled={state.currentCreateLiveClassFormStep === "basic"}
                />
              </Steps>
            </Space>
          </div>

          {handleView()}
        </div>
        <div className="widget-area">
          <LiveClassesTemplateWidget />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
}

export {LiveClassCreatePage};
