import * as yup from "yup";

const contactSchema = yup.object().shape({
  name: yup.string().trim().required("Please input your Name!"),
  email: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
  message: yup.string().trim().required("Please input a Message!"),
});

export {contactSchema};
