import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IServiceData} from "./i-service";
import {
  createServiceService,
  deleteServiceByIdService,
  getAllUserServicesService,
  getDefaultUserServiceService,
  getServiceByIdService,
  updateServiceByIdService,
  updateUserServiceService,
} from "./service-service";

interface IInitialState {
  services: {
    id: number;
    userId: number;
    name: string;
    description: string;
    status_id: number;
    callDuration: number;
    callPriceCurrency: string;
    callPrice: number;
    hideServiceFromMenu: boolean;
    videoLocation: boolean;
    audioLocation: boolean;
    bookingQuestions: [
      {
        question: string;
        required: boolean;
      }
    ];
    createdAt: Date;
    updatedAt: Date;
    statusName: string;
  }[];
  serviceData: {
    id?: string;
    name?: string;
    slug?: string;
    description?: string;
    callDuration?: string | number;
    callPriceCurrency?: string;
    callPrice?: string | number;
    hideServiceFromMenu?: boolean;
    videoLocation?: boolean;
    audioLocation?: boolean;
    isPaused?: boolean;
    bookingQuestions?: any;
    statusName?: string;
  };
  isError: boolean;
  isSuccess: boolean;
  isSuccessCreate: boolean;
  isSuccessUpdate: boolean;
  isLoading: boolean;
  fetchStatus: "idle" | "pending" | "resolved" | "rejected";
  updateByIdStatus: "idle" | "pending" | "resolved" | "rejected";
  deleteStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
  errorStatusCode: null;
}

const initialState: IInitialState = {
  services: [],
  serviceData: {},
  isError: false,
  isSuccess: false,
  isSuccessCreate: false,
  isSuccessUpdate: false,
  isLoading: false,
  fetchStatus: "idle",
  updateByIdStatus: "idle",
  deleteStatus: "idle",
  message: "",
  errorStatusCode: null,
};

//Get default user service
export const getDefaultUserService = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/getUserServices", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getDefaultUserServiceService(token);
  } catch (error: any) {
    // console.log("profile-error", error);
    const errorStatusCode = error.response.status;
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue({message, errorStatusCode});
  }
});

//Get all user services
export const getAllUserServices = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/getAllUserServices", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getAllUserServicesService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Create user service
export const createService = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IServiceData,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/createService[id]", async (serviceData, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await createServiceService(serviceData, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get service by id
export const getServiceById = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/getServiceById", async (id, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getServiceByIdService(id, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Update user service
export const updateUserService = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IServiceData,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/updateUserService[id]", async (serviceData, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateUserServiceService(serviceData, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
//Update user service not default
export const updateUserServiceById = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IServiceData,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/updateUserServiceBy[id]", async (serviceData, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateServiceByIdService(serviceData, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Delete service by id
export const deleteServiceById = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("service/deleteServiceById", async (id, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await deleteServiceByIdService(id, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    resetUserServiceUpadatesState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.isSuccessUpdate = false;
      state.fetchStatus = "idle";
      state.updateByIdStatus = "idle";
      state.deleteStatus = "idle";
      state.message = "";
      state.errorStatusCode = null;
      state.services = [];
    },
    resetUserServiceState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.isSuccessUpdate = false;
      state.message = "";
      state.errorStatusCode = null;
      state.fetchStatus = "idle";
      state.updateByIdStatus = "idle";
      state.serviceData = {};
      state.services = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultUserService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDefaultUserService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.errorStatusCode = null;
        state.serviceData = action.payload.data;
      })
      .addCase(getDefaultUserService.rejected, (state, action: any) => {
        // console.log("payload", action.payload);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.errorStatusCode = action.payload.errorStatusCode;
        state.serviceData = {};
      })
      .addCase(getAllUserServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUserServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = action.payload.data;
      })
      .addCase(getAllUserServices.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.services = [];
      })
      .addCase(createService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessCreate = true;
        state.serviceData = action.payload.data;
      })
      .addCase(createService.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUserService.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessUpdate = true;
        state.services = action.payload.data;
      })
      .addCase(updateUserService.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.errorStatusCode = null;
        state.services = [];
      })
      .addCase(getServiceById.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(getServiceById.fulfilled, (state, action) => {
        state.fetchStatus = "resolved";
        state.serviceData = action.payload.data;
      })
      .addCase(getServiceById.rejected, (state, action: any) => {
        state.fetchStatus = "rejected";
        state.message = action.payload;
        state.serviceData = {};
      })
      .addCase(updateUserServiceById.pending, (state) => {
        state.updateByIdStatus = "pending";
      })
      .addCase(updateUserServiceById.fulfilled, (state, action) => {
        state.updateByIdStatus = "resolved";
        state.serviceData = action.payload.data;
      })
      .addCase(updateUserServiceById.rejected, (state, action: any) => {
        state.updateByIdStatus = "rejected";
        state.message = action.payload;
      })
      .addCase(deleteServiceById.pending, (state) => {
        state.deleteStatus = "pending";
      })
      .addCase(deleteServiceById.fulfilled, (state, action) => {
        state.deleteStatus = "resolved";
        state.serviceData = {};
      })
      .addCase(deleteServiceById.rejected, (state, action: any) => {
        state.deleteStatus = "rejected";
        state.message = action.payload;
      });
  },
});

export const {resetUserServiceUpadatesState, resetUserServiceState} =
  serviceSlice.actions;

export default serviceSlice.reducer;
