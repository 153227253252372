import {Alert, Space, Spin} from "antd";
import {useGetCustomerPastCalls} from "../../../../react-query-hooks/customer";
import {FullPageLoader} from "../../../full-page-loader";
import {LoadingSpinner} from "../../../icons/icons";
import {StyledNoUpcomingCallBox} from "../../upcoming-calls/upcoming-calls.style";
import {CustomerPastCallCard} from "./customer-past-calls-card";
import {CustomPagination} from "../../../pagination";
import {useState} from "react";

const CustomerPastCallsComponent = () => {
  const [page, setPage] = useState(1);
  const customerPastCallsQueryInfo = useGetCustomerPastCalls(page);

  if (customerPastCallsQueryInfo.isLoading) {
    return <FullPageLoader />;
  }

  if (
    customerPastCallsQueryInfo.isError &&
    customerPastCallsQueryInfo.error.message !==
      "Sorry, you currently do not have any past/completed bookings!"
  ) {
    return (
      <Alert
        message="Error"
        description={customerPastCallsQueryInfo.error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      {customerPastCallsQueryInfo.data &&
      customerPastCallsQueryInfo.data.data.bookings.length > 0 ? (
        <Spin
          spinning={customerPastCallsQueryInfo.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <Space
            direction="vertical"
            size={42}
            style={{
              display: "flex",
            }}
          >
            {customerPastCallsQueryInfo.data.data.bookings.map((pastCall) => (
              <CustomerPastCallCard key={pastCall.id} call={pastCall} />
            ))}
            <CustomPagination
              isPreviousData={customerPastCallsQueryInfo.isPreviousData}
              page={page}
              paginationMeta={customerPastCallsQueryInfo.data.meta}
              setPage={setPage}
            />
          </Space>
        </Spin>
      ) : (
        <Spin
          spinning={customerPastCallsQueryInfo.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <StyledNoUpcomingCallBox>
            <p className="info-text">You do not have past calls at the moment.</p>
          </StyledNoUpcomingCallBox>
        </Spin>
      )}
    </>
  );
};

export {CustomerPastCallsComponent};
