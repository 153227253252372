import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {DashboardHeader} from "../../components/dashboard-header";
import {CustomerSideNav} from "../../components/dashboard-sidebar copy";
import {RootState} from "../../redux/store";
import {
  DashboardContainer,
  DashboardHeaderContainer,
  DashboardMainContainer,
  DashboardSideBarContainer,
  StyledMainSectionContainer,
  StyledMainSectionInnerContainer,
} from "./dashboard-wrapper.style";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router-dom";

declare const window: Window &
  typeof globalThis & {
    Intercom: any;
    heap: any;
  };

interface IDashboardWrapper {
  children: React.ReactNode;
  pageTitle: string;
  pageDesc?: string;
  pageSlug?: string;
}

const CustomerDashboardWrapper: React.FC<IDashboardWrapper> = ({
  children,
  pageTitle,
  pageDesc,
  pageSlug,
}) => {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setShowNav(!showNav);
  };

  useEffect(() => {
    if (userInfo && !userInfo.isCustomer) {
      navigate("/dashboard/home");
    }
  }, [navigate, userInfo]);
  window.heap.identify(
    `${userInfo.user.emailAddress.toLowerCase()}: ${userInfo.user.firstName} ${
      userInfo.user.lastName
    }`
  );
  window.heap.addUserProperties({
    name: `${userInfo.user.firstName} ${userInfo.user.lastName}`, // Full name
    email: `${userInfo.user.emailAddress.toLowerCase()}`, // Email address
    user_id: `${userInfo.user.emailAddress.toLowerCase()}`,
  });

  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "lhv6fa0p",
    name: `${userInfo.user.firstName} ${userInfo.user.lastName}`, // Full name
    email: `${userInfo.user.emailAddress.toLowerCase()}`, // Email address
    user_id: `${userInfo.user.emailAddress.toLowerCase()}`,
    created_at: `${userInfo.user.createdAt}`, // Signup date as a Unix timestamp
  });

  return (
    <>
      <Helmet>
        <title>Coachli | {pageTitle ?? "Do more with Coachli"} </title>
        <meta
          name="description"
          content={
            pageDesc ||
            "The simplest way to host paid 1:1 calls with your clients, mentees and audience."
          }
        />
        <link rel="canonical" href={pageSlug} />
        <meta property="og:url" content={pageSlug} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />
        {/* <meta property="og:image" content={image} />
            <meta name="og:image" content={image} /> */}
        <meta property="og:type" content="website" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDesc} />
        {/* <meta property="twitter:image" content={image} /> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:alt" content="Coachli Logo" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={pageSlug} />
      </Helmet>

      <DashboardContainer>
        {/* <DashboardSideBarContainer> */}
        {/* <DashboardSidebar toggleNav={toggle} visible={showNav} close={toggle} /> */}
        <CustomerSideNav toggleNav={toggle} visible={showNav} />
        {/* </DashboardSideBarContainer> */}
        <DashboardMainContainer>
          <DashboardHeaderContainer>
            <DashboardHeader toggleNav={toggle} />
          </DashboardHeaderContainer>
          <StyledMainSectionContainer>
            <StyledMainSectionInnerContainer>{children}</StyledMainSectionInnerContainer>
          </StyledMainSectionContainer>
        </DashboardMainContainer>
      </DashboardContainer>
    </>
  );
};

export {CustomerDashboardWrapper};
