import {Button, Space, Tooltip} from "antd";
import {CopyIcon} from "../../../icons/icons";
import {FacebookFilled, MailFilled, TwitterOutlined} from "@ant-design/icons";
import {useState} from "react";

type TShareOfferingWidgetProps = {
  extraParams: {
    facebookLink: string;
    twitterLink: string;
    mailLink: string;
    copyLink: string;
  };
  title: string;
};

export function ShareOfferingWidget({extraParams, title}: TShareOfferingWidgetProps) {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div>
      <Space
        direction="vertical"
        size={17}
        style={{
          display: "flex",
        }}
      >
        <p
          style={{
            color: "#000709",
            fontWeight: "600",
          }}
        >
          {title}
        </p>
        <div>
          <Space direction="horizontal" size={16}>
            <div>
              <Tooltip title={isCopied ? "Copied" : "Copy link"}>
                <Button
                  shape="circle"
                  size="large"
                  icon={<CopyIcon />}
                  style={{
                    backgroundColor: "#F4EEFC",
                    color: "#9254DE",
                    border: "none",
                  }}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(extraParams.copyLink)
                      .then(() => setIsCopied(true))
                  }
                />
              </Tooltip>
            </div>
            <div>
              <Button
                shape="circle"
                size="large"
                icon={<FacebookFilled />}
                style={{
                  backgroundColor: "#EBEEF5",
                  color: "#3B5998",
                  border: "none",
                }}
                target="_blank"
                href={`${extraParams.facebookLink}`}
                title="Share on Facebook"
              />
            </div>
            <div>
              <Button
                title="Share on Twitter"
                shape="circle"
                size="large"
                icon={<TwitterOutlined />}
                target="_blank"
                style={{
                  backgroundColor: "#E6F7FD",
                  color: "#00ACEE",
                  border: "none",
                }}
                href={`${extraParams.twitterLink}`}
              />
            </div>
            <div>
              <Button
                shape="circle"
                size="large"
                icon={<MailFilled />}
                style={{
                  backgroundColor: "#FBECEB",
                  color: "#DB4437",
                  border: "none",
                }}
                href={`${extraParams.mailLink}`}
                title="Share through mail"
              />
            </div>
          </Space>
        </div>
      </Space>
    </div>
  );
}
