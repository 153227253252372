import styled from "styled-components";

export const StyledLinksGroup = styled.div<{
  visible?: boolean;
}>`
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-right: 2px;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(250, 250, 250, 0.1);
  transition: flex-grow 0.3s cubic-bezier(0.4, 0, 1, 1);

  .nav-category {
    .nav-category-title {
      margin: 3rem 1.7rem 2.5rem 1.7rem;
      font-weight: 600;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: rgba(255, 255, 255, 0.4);
    background: grey;
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 1140px) {
    flex-grow: 1;
  }
`;
