import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
  getCategoriesService,
  getUserSavedCategoriesService,
  updateUserSavedCategoriesService,
} from "./category-service";

interface IInitialState {
  categories: null;
  savedCategories: [];
  isError: boolean;
  isSuccess: boolean;
  isSuccessUpdate: boolean;
  isLoading: boolean;
  fetchSavedCategoriesStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  categories: null,
  savedCategories: [],
  isError: false,
  isSuccess: false,
  isSuccessUpdate: false,
  isLoading: false,
  fetchSavedCategoriesStatus: "idle",
  message: "",
};

//Get categories
export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, thunkAPI) => {
    try {
      return await getCategoriesService();
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user saved categories
export const getUserSavedCategories = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("category/getUserSavedCategories", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getUserSavedCategoriesService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Update user saved categories
export const updateUserSavedCategories = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  {category: string},
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("category/updateUserSavedCategories", async (categoryData, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await updateUserSavedCategoriesService(categoryData, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetServiceState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessUpdate = false;
      state.message = "";
      state.categories = null;
      state.savedCategories = [];
    },
    categoryLogoutReset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload.data;
      })
      .addCase(getCategories.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = null;
      })
      .addCase(getUserSavedCategories.pending, (state) => {
        state.fetchSavedCategoriesStatus = "pending";
      })
      .addCase(getUserSavedCategories.fulfilled, (state, action) => {
        state.fetchSavedCategoriesStatus = "resolved";
        state.savedCategories = action.payload.data.categories;
      })
      .addCase(getUserSavedCategories.rejected, (state, action: any) => {
        state.fetchSavedCategoriesStatus = "rejected";
        state.message = action.payload;
        state.savedCategories = [];
      })
      .addCase(updateUserSavedCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserSavedCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessUpdate = true;
        // state.savedCategories = action.payload.data.categories;
      })
      .addCase(updateUserSavedCategories.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.savedCategories = null;
      });
  },
});

export const {resetServiceState, categoryLogoutReset} = categorySlice.actions;

export default categorySlice.reducer;
