import {
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  NumberOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {Space} from "antd";
import {FieldError, FieldErrors, Merge} from "react-hook-form";
import {TBooking} from "../../../../../redux/call/i-call";
import {CustomInputComponent} from "../../../../input/input-component";
import {StyledAvailabilityWidgetInfoBlock} from "../../availability-widget/availability-widget.style";
import {
  StyledCallCardExtraInfo,
  StyledModalContentInfo,
  StyledModalContentWrapper,
} from "../call-card.style";

const CallRescheduleModalContent = ({
  isVideo,
  control,
  error,
  call,
}: {
  call: TBooking;
  isVideo?: boolean;
  control: any;
  error: string | FieldError | Merge<FieldError, FieldErrors<any>> | undefined;
}) => {
  const callDate = new Date(
    Number(call.booking_year),
    Number(call.booking_month) - 1,
    Number(call.booking_date)
  ).toDateString();
  return (
    <div>
      <StyledModalContentWrapper>
        <StyledModalContentInfo>
          <p className="content-text">
            We will message {call.first_name} {call.last_name} asking to reschedule this
            call. To make scheduling easier, make sure your availability is up to date.
          </p>
        </StyledModalContentInfo>
      </StyledModalContentWrapper>
      <StyledModalContentWrapper>
        <StyledCallCardExtraInfo className="call-card-extra-info">
          {/* <div className="call-card-extra-info-item">
              <Space size={10}>
                <DollarOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">₦5,000</span>
              </Space>
            </div> */}
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">{callDate}</span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <ClockCircleOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {/* {call.timeslot} - {call.endTime} */}
                {call.coach_from_time || call.timeslot}
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.service.callDuration} minutes
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              {call.booking_type === "video" ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
          {call.booking_type === "audio" && (
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <NumberOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.phone_number}
                </span>
              </Space>
            </div>
          )}
        </StyledCallCardExtraInfo>
      </StyledModalContentWrapper>
      <StyledModalContentWrapper>
        <CustomInputComponent
          id="personalMessage"
          name="personalMessage"
          type="textarea"
          label={`Include a personal message to ${call.first_name} ${call.last_name} (optional)`}
          placeholder="Type a message"
          error={error}
          control={control}
        />
      </StyledModalContentWrapper>
      <StyledModalContentWrapper>
        <StyledAvailabilityWidgetInfoBlock>
          <p>Keep your availability updated to avoid scheduling conflicts</p>
        </StyledAvailabilityWidgetInfoBlock>
      </StyledModalContentWrapper>
    </div>
  );
};

export {CallRescheduleModalContent};
