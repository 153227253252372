import {
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  NumberOutlined,
  ClockCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {Space, Divider} from "antd";
import {TCustomerPaidBundleApiResponse} from "../../../../../../../react-query-hooks/customer";
import {capitalizeFirstLetter} from "../../../../../../../services/helpers";
import {
  StyledModalContentWrapper,
  StyledCallCardExtraInfo,
  StyledModalContentInfo,
} from "../../../../call-card/call-card.style";

const PaidBundlesDetailsModal = ({call}: {call: TCustomerPaidBundleApiResponse}) => {
  //   const callDate = new Date(
  //     Number(call.service.),
  //     Number(call.booking_month) - 1,
  //     Number(call.booking_date)
  //   ).toDateString();
  return (
    <>
      <StyledModalContentWrapper>
        <StyledCallCardExtraInfo className="call-card-extra-info">
          {/* <div className="call-card-extra-info-item">
                    <Space size={10}>
                      <DollarOutlined style={{color: "#7939CC"}} />
                      <span className="call-card-extra-info-item-value">₦5,000</span>
                    </Space>
                  </div> */}
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.bookedSessions} out of {call.noOfSessions} sessions
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <DollarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.price === 0
                  ? "Free"
                  : call.service.callPriceCurrency +
                    " " +
                    call.price.toLocaleString("en-US")}
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.service.callDuration} minutes
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              {call.service.videoLocation ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
          {call.service.audioLocation && (
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <NumberOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.phoneNumber}
                </span>
              </Space>
            </div>
          )}
        </StyledCallCardExtraInfo>
      </StyledModalContentWrapper>
      {call.service.bookingQuestions &&
        JSON.parse(call.service.bookingQuestions as unknown as string).map(
          (question: {question: string; answer: string}, idx: any) => (
            <div key={idx}>
              <StyledModalContentWrapper>
                <StyledModalContentInfo>
                  {/* <h3 className="content-heading">Question</h3> */}
                  <p className="content-text">
                    <strong>Question:</strong> {capitalizeFirstLetter(question.question)}
                  </p>
                  {/* <p className="content-text">
                    <strong>Answer:</strong> {question.answer}
                  </p> */}
                </StyledModalContentInfo>
              </StyledModalContentWrapper>
              <Divider style={{margin: 0}} />
            </div>
          )
        )}
    </>
  );
};

export {PaidBundlesDetailsModal};
