import * as yup from "yup";

const withdrawFormSchema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required!")
    .min(100, "Must be greater than 99")
    .typeError("Must be a number"),
});

export {withdrawFormSchema};
