import styled from "styled-components";

export const StyledLiveClassesMainSectionWrapper = styled.main`
  position: relative;
  display: grid;
  align-items: start;
  gap: 0.7rem 4rem;

  .live-classes-main-details-about-content {
    /* order: 1; */
  }
  .live-classes-main-details-call-content {
    /* order: 0; */
  }

  .live-classes-main-details-section {
    .content-heading {
      font-weight: 700;
      font-size: 20px;
    }
    .content-info-text {
      padding: 1.6rem 0;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;

      img {
        width: 100%;
      }
    }

    .view-widget-mobile {
      display: block;
    }
    .view-widget-web {
      display: none;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: auto 32.2rem;
    grid-template-rows: auto;

    .live-classes-main-details-about-content {
      grid-column: 1/2;
      grid-row: auto;
    }
    .live-classes-main-details-section {
      .view-widget-mobile {
        display: none;
      }
      .view-widget-web {
        display: block;
      }
    }
    .live-classes-main-details-sessions-content {
      grid-column: 1/2;
      grid-row: auto;
    }
    .live-classes-main-details-call-content {
      grid-column: 2/3;
      grid-row: 1 / auto;
      position: sticky;
      top: 14rem;
      /* .call-content-item {
        span {
          font-weight: 600;
          font-size: 1.4rem;
          color: #00171f;
        }
      } */
    }
    // .live-classes-main-details-host-content {
    //   grid-column: 2/3;
    //   grid-row: 2/3;
    // }

    .class-card-image-wrapper {
      grid-column: 1/2;
      grid-row: 1/5;
    }
    .class-card-info-text {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    .class-card-extra-info {
      grid-column: 2/3;
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
    }
    .class-card-reserve-btn {
      grid-column: 2/3;
      grid-row: 4/5;
    }
  }
`;

export const StyledLiveClassesCallInfoWidget = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2rem;

  .call-content-item {
    span {
      font-weight: 600;
      font-size: 1.4rem;
      color: #00171f;
    }
  }

  .view-web {
    display: none;
  }
  @media (min-width: 768px) {
    .view-web {
      display: block;
    }
  }
`;
export const StyledLiveClassesHostInfoWidget = styled.div`
  .host-heading-text {
    font-weight: 700;
    font-size: 2rem;
    color: #00171f;
  }
  .host-info-wrapper {
    margin-top: 2.4rem;
    display: grid;
    grid-template-columns: 9rem auto;
    grid-template-rows: auto;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 2rem;
    align-items: center;
    gap: 1.6rem;
    .host-avatar-wrapper {
      grid-column: 1/2;
    }
    .host-name-info-wrapper {
      .host-name {
        font-weight: 600;
        font-size: 1.6rem;
        color: #000709;
        word-break: break-word;
      }
      .host-role {
        font-size: 1.4rem;
        color: #000709;
        /* word-break: break-word; */
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  @media (max-width: 425px) {
    // padding: 2rem 3rem;
    .host-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: initial;
      gap: 1.6rem;
    }
  }
`;

export const StyledLiveClassBookingWrapper = styled.div`
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: 38rem auto;
    grid-template-rows: auto;
    min-height: 100vh;
    .live-class-booking-left-column {
      grid-column: 1/2;
      grid-row: auto;
      height: 100%;
    }
    .live-class-booking-right-column {
      grid-column: 2/3;
      grid-row: auto;
    }
  }
`;
export const StyledLiveClassBookingRightColumnWrapper = styled.div`
  padding: 2rem;
  // background-color: #fff;
  // min-height: 100vh;
  // padding: 12.7rem 9.4rem 2rem 4rem;
  // flex: 1;

  // @media (max-width: 1050px) {
  //   padding: 2rem 3rem;
  // }
  @media (min-width: 768px) {
    // padding: 6.8rem 20.4rem 2rem 8.4rem;
    padding: 6.8rem 9.4rem 2rem 4rem;
  }
`;
