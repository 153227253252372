import {Alert, Space} from "antd";
import {StyledNoUpcomingCallBox} from "../../../components/calls/upcoming-calls/upcoming-calls.style";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {useGetRecordings} from "../../../react-query-hooks/recordings";
import {StyledPageHeader, StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {FullPageLoader} from "../../../components/full-page-loader";
import {RecordingData} from "../../../components/recordings/recording-data";
import {ProfileWidget} from "../../../components/calls/upcoming-calls/profile-widget";

export function RecordingsPage() {
  const getRecordingsQuery = useGetRecordings();
  // console.log({res: getRecordingsQuery.data});
  if (getRecordingsQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle="Recordings">
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (getRecordingsQuery.isError) {
    return (
      <DashboardWrapper pageTitle="Recordings">
        <Alert
          message="Error"
          description={getRecordingsQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper pageTitle="Recordings">
      <StyledPageHeader>Recordings ⏺️</StyledPageHeader>
      <StyledPageWrapper>
        <div className="main-area" style={{marginBottom: "3rem"}}>
          {getRecordingsQuery.data.recordings.length < 1 ? (
            <div style={{width: "100%"}}>
              <StyledNoUpcomingCallBox>
                <p className="info-text">
                  You do not have any recordings of your video sessions or events yet
                </p>
              </StyledNoUpcomingCallBox>
            </div>
          ) : (
            <RecordingData recordings={getRecordingsQuery.data.recordings} />
          )}
        </div>
        <div className="widget-area">
          {/* <Space direction="vertical" size={40} style={{display: "flex"}}>
            <ProfileWidget />
          </Space> */}
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
}
