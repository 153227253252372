import {DownloadOutlined} from "@ant-design/icons";
import {Button, Space, Spin, Table, Tag} from "antd";
import type {ColumnsType} from "antd/es/table";
import {useEffect} from "react";
// import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fetchPayouts} from "../../../redux/payout/payout-slice";
import {AppDispatch, RootState} from "../../../redux/store";
import {StyledPageHeader} from "../../../styles/dashboard/calls/calls";
import {
  StyledDescriptionText,
  StyledNoUpcomingCallBox,
  StyledUpcomingCallsWrapper,
} from "../../calls/upcoming-calls/upcoming-calls.style";

interface DataType {
  key: string;
  date: string;
  amount: string;
  status: string;
  reference: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Date",
    dataIndex: "date",
    fixed: "left",
    width: "17.425rem",
    key: "date",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "17.425rem",
    key: "amount",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "17.425rem",
    key: "status",
    render: (text, record, index) => (
      <>
        <Tag
          color={text === "In Progress" ? "yellow" : text === "Failed" ? "red" : "green"}
        >
          {text}
        </Tag>
      </>
    ),
  },
  {
    title: "Reference no.",
    dataIndex: "reference",
    width: "17.425rem",
    key: "reference",
  },
];

const PayoutHistorySection = ({callState}: {callState?: string}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {payoutRequests, fetchPayoutsStatus} = useSelector(
    (state: RootState) => state.userPayout
  );

  useEffect(() => {
    dispatch(fetchPayouts(null));
  }, [dispatch]);

  const payoutData: DataType[] = payoutRequests?.map((request) => ({
    key: request.id.toString(),
    date: new Date(request.createdAt).toLocaleDateString(),
    amount: request.amount.toLocaleString("en-US"),
    status: request.statusName,
    reference: request.reference,
  }));

  // [
  //   {
  //     key: "1",
  //     date: "09.12.2022",
  //     amount: "400",
  //     status: "Completed",
  //     reference: "RTU776855489",
  //   },
  //   {
  //     key: "2",
  //     date: "09.12.2022",
  //     amount: "800",
  //     status: "Completed",
  //     reference: "RTU776855489",
  //   },
  // ];

  const handleOnRow = (record: any) => {
    return {
      onClick: () => navigate(`/dashboard/customers/view/${record.key}`), // click row
    };
  };
  return (
    <>
      {/* {callState !== "empty" && ( */}
      <div style={{marginTop: "7rem"}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "4rem",
          }}
        >
          <StyledPageHeader style={{fontSize: "2.4rem"}}>
            Payout history 📜
          </StyledPageHeader>
        </div>
        <div>
          <Spin spinning={fetchPayoutsStatus === "pending"}>
            {payoutData?.length < 1 ? (
              <StyledUpcomingCallsWrapper style={{paddingTop: 0}}>
                <StyledNoUpcomingCallBox>
                  <p className="info-text">You do not have any past payouts</p>
                </StyledNoUpcomingCallBox>
              </StyledUpcomingCallsWrapper>
            ) : (
              <Table
                //   onRow={handleOnRow}
                columns={columns}
                dataSource={payoutData}
                scroll={{x: 100}}
                loading={fetchPayoutsStatus === "pending"}
              />
            )}
          </Spin>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export {PayoutHistorySection};
