import * as yup from "yup";

const registerSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Please input your First name!")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  lastName: yup
    .string()
    .trim()
    .required("Please input your Last name!")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
  country: yup.string().required("Please select your country!"),
  password: yup
    .string()
    .trim()
    .required("Please input your Password!")
    .matches(/^(?=.{8,})/, "Must Contain 8 Characters"),
});

export {registerSchema};
