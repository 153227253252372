import styled from "styled-components";

export const StyledUpcomingCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 5px;
  border-radius: 5px;
  height: 100%;

  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);

  .upcoming-card-image-wrapper {
    width: 100%;
    height: 11.6rem;
    .upcoming-card-image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .upcoming-card-event-title {
    margin-top: 8px;
    font-size: 1.4rem;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    /* display 1 line only */
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .upcoming-card-event-description {
    overflow: hidden;
    display: -webkit-box;
    /* display 1 lines only */
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .upcoming-card-event-extra-info {
    margin-top: 8px;
    display: flex;
    /* align-items: center; */
    gap: 1rem;
    flex-wrap: wrap;

    .upcoming-card-event-extra-info-value {
      color: #595959;
      font-size: 1.2rem;
    }
  }

  .hr-line {
    background: #d9d9d9;
    height: 1px;
    margin: 8px 0;
  }

  @media (min-width: 425px) {
    .upcoming-card-event-title {
      font-size: 2rem;
    }
    .upcoming-card-event-desc {
      font-size: 1.2rem;
    }

    .upcoming-card-event-extra-info {
      .upcoming-card-event-extra-info-value {
        font-size: 1.4rem;
      }
    }
  }
`;
