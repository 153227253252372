import {useFlutterwave, closePaymentModal} from "flutterwave-react-v3";
import {useCallback, useEffect} from "react";
import {AnalyticsEvent} from "../../../../../utils/analytics";
import {FullPageLoader} from "../../../../../components/full-page-loader";
import {FlutterwaveConfig} from "flutterwave-react-v3/dist/types";
import {useNavigate} from "react-router-dom";

export type TFwPaymentStepProps = {
  updateStep: (step: number) => void;
  fwConfigParams: FlutterwaveConfig;
  successRedirectLink: string;
  handleCloseModal: () => void;
};

const NewPayWithFlutterwaveStep = ({
  updateStep,
  fwConfigParams,
  successRedirectLink,
  handleCloseModal,
}: TFwPaymentStepProps) => {
  const handleFlutterPayment = useFlutterwave(fwConfigParams);
  const navigate = useNavigate();

  const payWithFW = useCallback(() => {
    handleFlutterPayment({
      callback: (response) => {
        // console.log({response});
        closePaymentModal(); // this will close the modal programmatically
        if (response.status === "successful" || response.status === "completed") {
          AnalyticsEvent("Flutterwave payment", "Flutterwave payment success");
          navigate(successRedirectLink);
          handleCloseModal();
        }
        AnalyticsEvent("Flutterwave payment", "Flutterwave payment fail");
      },
      onClose: () => {
        AnalyticsEvent("Flutterwave payment", "Closed flutterwave payment modal");
        updateStep(1);
      },
    });
  }, [handleFlutterPayment, navigate, successRedirectLink, updateStep]);

  useEffect(() => {
    payWithFW();
  }, [payWithFW]);

  return (
    <div style={{height: "50vh"}}>
      <FullPageLoader />
    </div>
  );
};

export {NewPayWithFlutterwaveStep};
