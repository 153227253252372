import {Alert, Table} from "antd";
import type {ColumnsType} from "antd/es/table";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
  useGetCustomer,
  useGetCustomerPrevPurchases,
} from "../../../../react-query-hooks/customer";
import {RootState} from "../../../../redux/store";
import {StyledPageHeader} from "../../../../styles/dashboard/calls/calls";
import {StyledDescriptionText} from "../../../calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../../full-page-loader";

interface DataType {
  key: string | number;
  service: string | number;
  reference: string;
  currency: string;
  date: string | Date;
  amount: string | number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Service",
    dataIndex: "service",
    fixed: "left",
    width: "17.425rem",
    key: "service",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    width: "17.425rem",
    key: "reference",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    width: "17.425rem",
    key: "date",
    render: (text) => (
      <>
        <StyledDescriptionText>{text}</StyledDescriptionText>
      </>
    ),
  },

  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "17.425rem",
    render: (text, record) => (
      <>
        <StyledDescriptionText>
          {record.amount !== "0" && record.currency}{" "}
          {record.amount === "0" ? "Free" : record.amount.toLocaleString()}{" "}
        </StyledDescriptionText>
      </>
    ),
  },
];

const PrevPurchasesSection = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const getCustomerQuery = useGetCustomer(id);
  const getCustomerPrevPurchasesQuery = useGetCustomerPrevPurchases(
    userInfo.user?.id,
    getCustomerQuery.data?.emailAddress
  );

  if (getCustomerPrevPurchasesQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getCustomerPrevPurchasesQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getCustomerPrevPurchasesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  const prevPurchasesData: DataType[] = getCustomerPrevPurchasesQuery.data.map(
    (purchase) => {
      // if (Array.isArray(purchase)) {
      //   return {
      //     key: "",
      //     amount: "",
      //     currency: "",
      //     date: "",
      //     reference: "",
      //     service: "",
      //   };
      // } else {
      return {
        key: purchase.reference,
        service: purchase.service.name,
        currency: purchase.service.callPriceCurrency,
        reference: purchase.reference,
        date: new Date(purchase.date).toLocaleDateString(),
        amount: purchase.amount,
      };
    }
    // }
  );

  const handleOnRow = (record: any) => {
    return {
      onClick: () => navigate(`/dashboard/customers/view/${record.key}`), // click row
    };
  };

  return (
    <>
      <div style={{marginTop: "7rem"}}>
        <StyledPageHeader style={{fontSize: "2.4rem", marginBottom: "4.2rem"}}>
          Previous purchases
        </StyledPageHeader>

        <Table
          columns={columns}
          dataSource={prevPurchasesData}
          scroll={{x: 100}}
          loading={getCustomerPrevPurchasesQuery.isFetching}
          // pagination={false}
        />
      </div>
    </>
  );
};

export {PrevPurchasesSection};
