import {Controller, useFieldArray, useForm} from "react-hook-form";
import {
  step2AudioSchema,
  step2Schema,
} from "../../../../container/booking/step-2/step-2-form-validation-schema";
import {useServiceBookingContext} from "../../../../context/service-booking/service-booking.context";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect} from "react";
import {Button, Col, Input, Row, Select, Space, message} from "antd";
import {PaymentOptions} from "../../../payment-options";
import {CustomCheckboxInput} from "../../../checkbox";
import {StyledBookingRightColumnHeader} from "../../../../styles/booking/booking";
import {CustomInputComponent} from "../../../input/input-component";
import {StyledInputLabelWrapper} from "../../../input/input.style";
import {COUNTRY_CODES_WITH_FLAG} from "../../../../services/country-code";
import {
  ServiceBookingApiResponse,
  ServiceBookingPayload,
  ServiceBundleBookingApiResponse,
} from "../../../../react-query-hooks/public-profile";
import {UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import moment from "moment";
import {AnalyticsEvent} from "../../../../utils/analytics";
import {handleServiceSuccessBookingLink} from "../../../../utils/meeting";
import {useNavigate} from "react-router-dom";

type ServiceBookingFormProps = {
  serviceBookingQueryMutation: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    ServiceBookingPayload,
    unknown
  >;

  updateStep: (step: number) => void;
};

export type ServiceBookingFormValues = {
  email_address: string;
  first_name: string;
  last_name: string;
  numberCode: string;
  phone_number?: string;
  currency: {
    label: string;
    value: string | number;
  };
  bookingQuestions: {question: string; answer: string; required: boolean}[];
  notes: string;
};

export function ServiceBookingForm({
  serviceBookingQueryMutation,
  updateStep,
}: ServiceBookingFormProps) {
  const {state, dispatch} = useServiceBookingContext();
  const navigate = useNavigate();
  const defaultCallPriceCurrency = state.serviceData?.service.callPriceCurrency;
  const defaultCallPrice = state.serviceData?.service.callPrice;
  const defaultCallCurrencies = state.serviceData?.service.currencies;

  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    control,
  } = useForm<ServiceBookingFormValues>({
    resolver: yupResolver(
      state.serviceData?.service.audioLocation ||
        state.serviceData?.service.request_phone_number
        ? step2AudioSchema
        : step2Schema
    ),
    mode: "onChange",
    defaultValues: {
      numberCode: "+234",
      currency: state.selectedCurrency
        ? state.selectedCurrency
        : {
            value: "default",
            label: `${defaultCallPriceCurrency} ${defaultCallPrice}`,
          },
      bookingQuestions: state.serviceData?.service.bookingQuestions
        ? [...state.serviceData?.service.bookingQuestions]
        : [],
    },
  });
  // console.log({watch: watch(), errors});
  const {fields} = useFieldArray({
    control,
    name: "bookingQuestions",
  });
  useEffect(() => {
    if (state.selectedCurrency) {
      setValue("currency", state.selectedCurrency);
    }
  }, [state.selectedCurrency, setValue]);
  function handleCurrencyListOptions() {
    const defaultCurrencyObj = {
      label: `${defaultCallPriceCurrency} ${defaultCallPrice?.toLocaleString()}`,
      value: `default`,
    };
    const mappedCurrencies = defaultCallCurrencies?.map((currency) => {
      return {
        label: `${currency.currency} ${currency.amount.toLocaleString()}`,
        value: `${currency.id}`,
      };
    });

    if (mappedCurrencies) {
      return [defaultCurrencyObj, ...mappedCurrencies];
    } else {
      return [];
    }
  }

  const handleCurrencyChange = (value: any) => {
    const selectedValue = defaultCallCurrencies?.find((x) => x.id === Number(value));
    let selectedCurrency;
    // console.log({selectedValue, value, defaultCallCurrencies});

    if (value === "default") {
      selectedCurrency = {
        label: `${defaultCallPriceCurrency} ${defaultCallPrice}`,
        value: "default",
        amount: defaultCallPrice as number,
        currency: defaultCallPriceCurrency as string,
      };
    } else {
      if (selectedValue) {
        selectedCurrency = {
          label: `${selectedValue.currency} ${selectedValue.amount}`,
          value: selectedValue.id,
          amount: selectedValue.amount,
          currency: selectedValue.currency,
        };
      } else {
        return message.info("Currency not accepted");
      }
    }
    dispatch({
      type: "update-selected-currency",
      payload: {
        selectedCurrency,
      },
    });
  };
  // console.log({errors});
  const onSubmit = async (values: ServiceBookingFormValues) => {
    dispatch({
      type: "update-service-form-values",
      payload: values,
    });
    const {
      bookingQuestions,
      currency,
      email_address,
      first_name,
      last_name,
      notes,
      numberCode,
      phone_number,
    } = values;
    const tz = moment.tz.guess(true);
    function handleCurrencyAndAmountPayload() {
      const selectedCurrencyId = currency.value;
      const selectedCurrencyValue = defaultCallCurrencies?.find(
        (x) => x.id === Number(selectedCurrencyId)
      );
      if (selectedCurrencyValue) {
        return {
          currency: selectedCurrencyValue.currency,
          amount: selectedCurrencyValue.amount,
        };
      } else {
        return {
          currency: defaultCallPriceCurrency,
          amount: defaultCallPrice,
        };
      }
    }
    const payload: ServiceBookingPayload = {
      mentorId: Number(state.serviceData?.profile.userId),
      serviceId: Number(state.serviceData?.service.id),
      mentor_timezone: String(state.serviceData?.timeZone),
      user_timezone: state.selectedTimeZone ?? tz,
      booking_date: state.booking_date,
      booking_year: state.booking_year,
      booking_month: state.booking_month,
      timeSlot: state.timeSelected ?? "10:30 AM",
      currency: handleCurrencyAndAmountPayload().currency as string,
      amount: Number(handleCurrencyAndAmountPayload().amount),
      booking_type: state.serviceData?.service.audioLocation ? "audio" : "video",
      email_address: email_address.toLowerCase(),
      first_name,
      last_name,
      notes,
      phone_number: phone_number ? (numberCode as string) + phone_number : undefined,
      bookingQuestions: bookingQuestions.map((question) => ({
        question: question.question,
        answer: question.answer ?? "null",
      })),
    };

    serviceBookingQueryMutation.mutate(payload, {
      onSuccess(data) {
        // console.log({serviceBookingApiResponse: data});
        if (state.serviceData?.service.is_bundle) {
          // console.log("Bundle rann before");
          const bundleData: ServiceBundleBookingApiResponse = data.data;
          dispatch({
            type: "update-service-booking-api-response",
            payload: bundleData.bundle,
          });
          // console.log("Bundle rann after");
        } else {
          // console.log("service rann before");
          const serviceData: ServiceBookingApiResponse = data.data;
          dispatch({
            type: "update-service-booking-api-response",
            payload: serviceData.booking,
          });
          // console.log("service rann after");
        }
        if (
          state.serviceData?.service.callPrice === 0 ||
          (data.data && data.data.bundle && data.data.bundle.is_paid)
        ) {
          navigate(
            handleServiceSuccessBookingLink({
              serviceId: String(state.serviceData?.service.id),
              slug: state.serviceData?.service.slug as string,
              username: state.serviceData?.profile.username as string,
            })
          ); //success step
        } else {
          if (
            (data.data &&
              data.data.bundle &&
              data.data.bundle.payment_provider === "flutterwave") ||
            (data.data &&
              data.data.booking &&
              data.data.booking.payment_provider === "flutterwave")
          ) {
            AnalyticsEvent("Flutterwave payment", "Flutterwave payment init");
            updateStep(2); //flutterwave step
          }
          if (
            (data.data &&
              data.data.bundle &&
              data.data.bundle.payment_provider === "paystack") ||
            (data.data &&
              data.data.booking &&
              data.data.booking.payment_provider === "paystack")
          ) {
            AnalyticsEvent("Paystack payment", "Paystack payment init");
            updateStep(3); //paystack step
          }
          if (
            (data.data &&
              data.data.bundle &&
              data.data.bundle.payment_provider === "stripe") ||
            (data.data &&
              data.data.booking &&
              data.data.booking.payment_provider === "stripe")
          ) {
            AnalyticsEvent("Stripe payment", "Stripe payment init");
            setTimeout(() => {
              window.location.href = data.data.redirectUrl;
            }, 0);
          }
        }
      },
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <Row gutter={[27, 24]}>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="first_name"
                label="First name"
                placeholder="Enter your first name"
                error={errors.first_name?.message}
                control={control}
                name="first_name"
              />
            </Col>
            <Col span={24} md={12}>
              <CustomInputComponent
                id="last_name"
                name="last_name"
                label="Last name"
                placeholder="Enter your last name"
                error={errors.last_name?.message}
                control={control}
              />
            </Col>
          </Row>
          <CustomInputComponent
            id="email"
            name="email_address"
            label="Enter your email address"
            type="email"
            placeholder="Please enter your email address"
            error={errors.email_address?.message}
            control={control}
          />
          {(state.serviceData?.service.request_phone_number ||
            state.serviceData?.service.audioLocation) && (
            <div>
              <StyledInputLabelWrapper>
                <label id="phone_number_label" htmlFor="phone_number">
                  Enter phone number
                  {state.serviceData?.service.request_phone_number ||
                  state.serviceData?.service.audioLocation ? null : (
                    <span style={{color: "gray", marginLeft: "3px"}}>(optional)</span>
                  )}
                </label>
              </StyledInputLabelWrapper>
              <Controller
                control={control}
                name="phone_number"
                render={({field}) => (
                  <Input
                    {...field}
                    placeholder="Enter phone number"
                    size="large"
                    status={errors.phone_number?.message && "error"}
                    addonBefore={
                      <Controller
                        control={control}
                        name="numberCode"
                        render={({field}) => (
                          <>
                            <Select
                              {...field}
                              // defaultValue={COUNTRY_CODES[0].dial_code}
                              size="large"
                              optionFilterProp="children"
                              showSearch
                              filterOption={(input, option) => {
                                return option.value
                                  .toLowerCase()
                                  .includes(input.toLowerCase());
                              }}
                            >
                              {COUNTRY_CODES_WITH_FLAG.map((code) => (
                                <Select.Option key={code.dialCode} value={code.dialCode}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <img
                                      src={code.flag}
                                      width={30}
                                      height={20}
                                      alt={code.name}
                                    />
                                    <span>{code.dialCode}</span>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </>
                        )}
                      />
                    }
                  />
                )}
              />
              {errors.phone_number?.message && (
                <div role="alert" className="ant-form-item-explain-error">
                  {errors.phone_number?.message}
                </div>
              )}
            </div>
          )}

          {state.serviceData?.service.callPrice === 0 ? null : handleCurrencyListOptions()
              .length > 1 ? (
            <div>
              <StyledInputLabelWrapper>
                <label id="currency_label" htmlFor="currency">
                  Select currency
                </label>
              </StyledInputLabelWrapper>

              <Controller
                control={control}
                name="currency"
                render={({field: {onChange, value, ref}}) => (
                  <Select
                    // suffixIcon={
                    //   <DownOutlined size={15} style={{fontWeight: "bold", color: "#00171F"}} />
                    // }
                    labelInValue
                    onChange={(value) => {
                      // console.log({value});
                      // onChange({value: value.value, label: value.label});
                      handleCurrencyChange(value.value);
                    }}
                    value={value}
                    size={"large"}
                    defaultValue={value}
                    options={handleCurrencyListOptions()}
                  />
                )}
              />
            </div>
          ) : null}

          <CustomInputComponent
            id="notes"
            name="notes"
            type="textarea"
            label="Please share anything that will help prepare for our call"
            placeholder="What are your expectations?"
            error={errors.notes?.message}
            control={control}
          />

          {fields.length > 0 && (
            <StyledBookingRightColumnHeader>Questions</StyledBookingRightColumnHeader>
          )}

          {fields.map((question, index) => (
            <div key={question.id}>
              <CustomInputComponent
                id={`bookingQuestions[${index}].value`}
                label={question.question}
                name={`bookingQuestions[${index}].answer`}
                placeholder="Enter your answer"
                error={errors.bookingQuestions?.[index]?.answer?.message}
                control={control}
              />
              <div style={{display: "none"}}>
                <CustomCheckboxInput
                  control={control}
                  id={`bookingQuestions[${index}].value`}
                  name={`bookingQuestions[${index}].required`}
                  // error={errors.hideServiceFromMenu?.message}
                  label={`${question.required}`}
                />
              </div>
            </div>
          ))}
          <div>
            <Button
              loading={serviceBookingQueryMutation.isLoading}
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              shape="round"
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              {state.serviceData?.service.callPrice === 0
                ? "Continue"
                : "Continue to payment"}
            </Button>
          </div>
          <PaymentOptions />
        </Space>
      </form>
    </div>
  );
}
