import {
  CalendarOutlined,
  HourglassOutlined,
  VideoCameraOutlined,
  PhoneOutlined,
  NumberOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {Space, Divider} from "antd";
import {TBooking} from "../../../../redux/call/i-call";
import {capitalizeFirstLetter} from "../../../../services/helpers";
import {
  StyledModalContentWrapper,
  StyledCallCardExtraInfo,
  StyledModalContentInfo,
} from "../../upcoming-calls/call-card/call-card.style";

const PastCallDetailsModal = ({call}: {call: TBooking}) => {
  const callDate = new Date(
    Number(call.booking_year),
    Number(call.booking_month) - 1,
    Number(call.booking_date)
  ).toDateString();
  return (
    <>
      <StyledModalContentWrapper>
        <StyledCallCardExtraInfo className="call-card-extra-info">
          {/* <div className="call-card-extra-info-item">
                  <Space size={10}>
                    <DollarOutlined style={{color: "#7939CC"}} />
                    <span className="call-card-extra-info-item-value">₦5,000</span>
                  </Space>
                </div> */}
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <CalendarOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">{callDate}</span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <ClockCircleOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call.coach_from_time || call.timeslot}
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              <HourglassOutlined style={{color: "#7939CC"}} />
              <span className="call-card-extra-info-item-value">
                {call?.service?.callDuration} minutes
              </span>
            </Space>
          </div>
          <div className="call-card-extra-info-item">
            <Space size={10}>
              {call.booking_type === "video" ? (
                <>
                  <VideoCameraOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Video call</span>
                </>
              ) : (
                <>
                  <PhoneOutlined style={{color: "#7939CC"}} />
                  <span className="call-card-extra-info-item-value">Phone call</span>
                </>
              )}
            </Space>
          </div>
          {call.booking_type === "audio" && (
            <div className="call-card-extra-info-item">
              <Space size={10}>
                <NumberOutlined style={{color: "#7939CC"}} />
                <span className="call-card-extra-info-item-value">
                  {call.phone_number}
                </span>
              </Space>
            </div>
          )}
        </StyledCallCardExtraInfo>
      </StyledModalContentWrapper>
      {call.bookingQuestions &&
        JSON.parse(call.bookingQuestions as unknown as string).map(
          (question: {question: string; answer: string}, idx: any) => (
            <div key={idx}>
              <StyledModalContentWrapper>
                <StyledModalContentInfo>
                  {/* <h3 className="content-heading">Question</h3> */}
                  <p className="content-text">
                    <strong>Question:</strong> {capitalizeFirstLetter(question?.question)}
                  </p>
                  <p className="content-text">
                    <strong>Answer:</strong> {capitalizeFirstLetter(question?.answer)}
                  </p>
                </StyledModalContentInfo>
              </StyledModalContentWrapper>
              <Divider style={{margin: 0}} />
            </div>
          )
        )}
    </>
  );
};

export {PastCallDetailsModal};
