export const SocialOptions = [
  {
    name: "Twitter",
  },
  {
    name: "Facebook",
  },
  {
    name: "LinkedIn",
  },
  {
    name: "Instagram",
  },
  {
    name: "TikTok",
  },
  {
    name: "YouTube",
  },
  {
    name: "Dribbble",
  },
  {
    name: "Behance",
  },
  {
    name: "Discord",
  },
  {
    name: "Twitch",
  },
  {
    name: "Github",
  },
  {
    name: "Website",
  },
];
export const CallDurationOptions = [
  {
    name: "15 Minutes",
    value: 15,
  },
  {
    name: "30 Minutes",
    value: 30,
  },
  {
    name: "45 Minutes",
    value: 45,
  },
  {
    name: "60 Minutes",
    value: 60,
  },
  {
    name: "Custom",
    value: "custom",
  },
];
export const CustomCallDurationOptions = [
  {
    name: "65 Minutes",
    value: "65",
  },
  {
    name: "70 Minutes",
    value: "70",
  },
  {
    name: "75 Minutes",
    value: "75",
  },
  {
    name: "80 Minutes",
    value: "80",
  },
  {
    name: "85 Minutes",
    value: "85",
  },
  {
    name: "90 Minutes",
    value: "90",
  },
  {
    name: "95 Minutes",
    value: "95",
  },
];
export const CurrencyOptions = [
  {
    name: "USD",
    value: "usd",
  },
  {
    name: "Naira",
    value: "naira",
  },
];
export const bundleIntervalOptions = [
  {
    name: "2 days",
    value: "2days",
  },
  {
    name: "3 days",
    value: "3days",
  },
  {
    name: "5 days",
    value: "5days",
  },
  {
    name: "1 week",
    value: "1week",
  },
  {
    name: "2 weeks",
    value: "2weeks",
  },
];
export const bundleSessionOptions = [
  {
    name: "2 sessions",
    value: 2,
  },
  {
    name: "3 sessions",
    value: 3,
  },
  {
    name: "4 sessions",
    value: 4,
  },
  {
    name: "5 sessions",
    value: 5,
  },
  {
    name: "6 sessions",
    value: 6,
  },
  {
    name: "7 sessions",
    value: 7,
  },
  {
    name: "8 sessions",
    value: 8,
  },
  {
    name: "9 sessions",
    value: 9,
  },
  {
    name: "10 sessions",
    value: 10,
  },
];
export const attendeesOptions = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "Unlimited",
    value: -1,
  },
];
