import styled from "styled-components";

export const StyledCustomersPageWrapper = styled.div`
  max-width: 82rem;
  margin: 4rem 0;
`;
export const StyledCustomersDataBox = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 1.6rem;

  .data-box-title {
  }
  .data-box-value {
    font-weight: 700;
    font-size: 3.2rem;
  }
`;
