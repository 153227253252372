import {Space} from "antd";
import {TLiveClassApiResponse} from "../../../react-query-hooks/live-events/live-classes";
import {LiveClassCard} from "../../live-classes/upcoming-classes/card";
import {SortLiveClassByTime} from "../../../services/helpers";

export type TUpcomingClassesProps = {
  events: TLiveClassApiResponse[];
};

export const UpcomingClassesEvent = ({events}: TUpcomingClassesProps) => {
  return (
    <Space direction="vertical" size={29} style={{display: "flex"}}>
      {SortLiveClassByTime(events).map((liveClass) => (
        <LiveClassCard
          key={liveClass.id}
          slug={liveClass.slug}
          name={liveClass.name}
          activeSession={liveClass.activeSession}
          attendees={liveClass.attendees}
          callPrice={liveClass.callPrice}
          callPriceCurrency={liveClass.callPriceCurrency}
          cover_image={liveClass.cover_image}
          description={liveClass.description}
          eventId={liveClass.id}
          statusName={liveClass.statusName}
        />
      ))}
    </Space>
  );
};
