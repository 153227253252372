import styled from "styled-components";

export const StyledCalendarHeader = styled.div`
  padding-top: 1rem;
`;
export const StyledCalendarHeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: 1.6rem;
  font-weight: 600;

  .selected-month {
    font-size: 1.6rem;
  }

  .year-change {
    cursor: pointer;
  }

  /* @media (min-width: 425px) {
    .selected-month {
      font-size: 1.6rem;
    }
  } */
`;

// .calendar-header {
//   color: var(--color-txt);
//   padding: 10px;
// }

// .calendar-header .calendar-header-top {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 30px;
//   font-size: 25px;
//   font-weight: 600;
// }

// .calendar-header .calendar-header-current-date {
//   font-size: 14px;
//   text-align: center;
//   color: var(--dark-second);
// }

// .calendar-header .calendar-header-top .year-change {
//   height: 40px;
//   width: 40px;
//   border-radius: 50%;
//   display: grid;
//   place-items: center;
//   /* margin: 0 10px; */
//   cursor: pointer;
// }

// .calendar-header .calendar-header-top .year-change:hover {
//   background-color: var(--color-hover);
// }
