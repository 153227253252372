import {Button, Checkbox, Col, Input, Row, Space} from "antd";
import {
  StyledFormWrapper,
  StyledCreateServiceHeadingBox,
  StyledServiceButtonWrapper,
} from "../../../styles/onboarding/onboarding";
import {ProductNameInput} from "./name-input";
import {ProductDescriptionInput} from "./description-input";
import {useForm, Controller} from "react-hook-form";
import {TProductFormValues} from "./form-values";
import {ProductHideFromProfileInput} from "./hide-from-profile-input";
import {ProductPriceInput} from "./price-input";
import {productFormSchema} from "./schema";
import {yupResolver} from "@hookform/resolvers/yup";
import {ProductCoverImageUpload} from "./cover-image-upload";
import {ProductCreateSuccessModal} from "./success-modal";
import {useCurrencyContext} from "../../../context/currency-context";
import {
  TCreateProductPayload,
  TGetProductApiResponse,
  TUpdateProductPayload,
  useCreateProduct,
  useUpdateProduct,
} from "../../../react-query-hooks/products";
import {useCreateProductContext} from "../../../context/products/add-product-context";
import {useNavigate} from "react-router-dom";
import {CustomCheckboxInput} from "../../checkbox";

type TProductFormProps = {
  productData?: TGetProductApiResponse;
  formType: "new" | "edit";
};

export function ProductForm({formType, productData}: TProductFormProps) {
  const navigate = useNavigate();
  const {state} = useCurrencyContext();
  const {dispatch} = useCreateProductContext();
  const createProductMutation = useCreateProduct();
  const updateProductMutation = useUpdateProduct();

  // console.log({productData});

  // function handleCallPriceType() {
  //   if (productData && productData.product. > 0 && state.connectedBankStatus) {
  //     return "paid";
  //   } else if (!productData && state.connectedBankStatus) {
  //     return "paid";
  //   } else {
  //     return "free";
  //   }
  // }
  const {
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm<TProductFormValues>({
    resolver: yupResolver(productFormSchema),
    mode: "onChange",
    defaultValues: {
      coverImage: productData?.product.coverImage || undefined,
      description: productData?.product.description || undefined,
      title: productData?.product.title || undefined,
      currencies:
        productData && productData.currencies.length > 0
          ? productData.currencies.map((currency) => ({
              callPrice: currency.amount,
              callPriceCurrency: currency.currency,
            }))
          : [
              {
                callPrice: 10,
                callPriceCurrency: state.formCurrencies[0],
              },
            ],
      callPriceType:
        state.connectedBankStatus && productData && productData.currencies.length > 0
          ? "paid"
          : state.connectedBankStatus && formType === "new"
          ? "paid"
          : "free",
      hideProductFromProfile: productData?.product.hideFromProfile,
      isUrlRedirect: !!productData?.product.url_redirect,
      url_redirect: productData?.product.url_redirect,
      request_phone_number: productData?.product.request_phone_number,
    },
  });

  const onSubmit = async (values: TProductFormValues) => {
    const payload: TCreateProductPayload = {
      coverImage: values.coverImage,
      currencies: values.callPriceType === "free" ? [] : values.currencies,
      description: values.description,
      hideProductFromProfile: values.hideProductFromProfile,
      title: values.title,
      url_redirect: values.isUrlRedirect ? values.url_redirect : undefined,
      request_phone_number: values.request_phone_number,
    };
    if (formType === "new") {
      createProductMutation.mutate(payload, {
        onSuccess: (data) => {
          navigate(`/dashboard/products/edit/${data.data.product.id}?currentTab=content`);
          dispatch({type: "update-edit-form-step", payload: "content"});
        },
      });
    }
    if (formType === "edit") {
      const editPayload: TUpdateProductPayload = {
        ...payload,
        productId: productData?.product.id as number,
      };
      updateProductMutation.mutate(editPayload, {
        onSuccess: (data) => {
          navigate(`/dashboard/products`);
        },
      });
    }
  };

  return (
    <div style={{marginTop: "1.8rem"}}>
      <StyledFormWrapper style={{marginTop: 0}}>
        <Space direction="vertical" size={20} style={{display: "flex"}}>
          <StyledCreateServiceHeadingBox>
            <h1 className="main-heading">Digital product information</h1>
          </StyledCreateServiceHeadingBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              <ProductNameInput
                control={control}
                watch={watch}
                formState={formState}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />
              <ProductDescriptionInput
                control={control}
                watch={watch}
                formState={formState}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />

              <ProductCoverImageUpload
                control={control}
                watch={watch}
                formState={formState}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />

              {/* <ProductContentUpload /> */}

              <ProductPriceInput
                control={control}
                watch={watch}
                formState={formState}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />

              <ProductHideFromProfileInput
                control={control}
                watch={watch}
                formState={formState}
                clearErrors={clearErrors}
                setError={setError}
                setValue={setValue}
              />
              <CustomCheckboxInput
                control={control}
                id="request_phone_number"
                name="request_phone_number"
                error={formState.errors.request_phone_number?.message}
                label="Request customer's phone number"
              />
              <div>
                <Space size={16} direction="vertical" style={{display: "flex"}}>
                  <div>
                    <Controller
                      control={control}
                      name="isUrlRedirect"
                      render={({field: {onChange, value}}) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            clearErrors("url_redirect");
                            onChange(e.target.checked);
                          }}
                        >
                          Redirect customers to URL after purchase
                        </Checkbox>
                      )}
                    />
                  </div>
                  <p>
                    *This could be a link to a whatsapp group chat, telegram group etc.
                  </p>
                  {watch("isUrlRedirect") && (
                    <div>
                      <Controller
                        control={control}
                        name="url_redirect"
                        render={({field: {onChange, value, ref}}) => (
                          <Input
                            id="serviceName"
                            status={formState.errors.url_redirect?.message ? "error" : ""}
                            onChange={onChange}
                            size="large"
                            placeholder="Enter Url"
                            value={value}
                            ref={ref}
                          />
                        )}
                      />
                      {formState.errors.url_redirect?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {formState.errors.url_redirect.message}
                        </div>
                      )}
                    </div>
                  )}
                </Space>
              </div>
              <Row gutter={[0, 16]}>
                <Col span={24} sm={12}>
                  <StyledServiceButtonWrapper>
                    <Button
                      block
                      size="large"
                      shape="round"
                      type="primary"
                      htmlType="submit"
                      disabled={
                        Object.keys(formState.errors).length > 0 ||
                        Object.values(watch()).length < 1
                      }
                      loading={
                        createProductMutation.isLoading || updateProductMutation.isLoading
                      }
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => reset()}
                      block
                      size="large"
                      shape="round"
                      type="default"
                    >
                      Discard changes
                    </Button>
                  </StyledServiceButtonWrapper>
                </Col>
              </Row>
            </Space>
          </form>
        </Space>
      </StyledFormWrapper>
      <ProductCreateSuccessModal />
    </div>
  );
}
