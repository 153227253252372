import {Alert, Spin} from "antd";
import {FullPageLoader} from "../../../components/full-page-loader";
import {useGetSavedCategories} from "../../../react-query-hooks/category";
import {useGetCategories} from "../../../react-query-hooks/general";
import {StyledCreateServiceHeadingBox} from "../../../styles/onboarding/onboarding";
import {IOnboardingStepProps} from "../i-step";
import {Step3Form} from "./step-3-form";

interface IStep3Props extends IOnboardingStepProps {
  savedCategories?: string[];
  categories?: string[];
}

const OnboardingStep3: React.FC<IStep3Props> = ({
  updateStep,
  categories,
  savedCategories,
}) => {
  return (
    <div>
      <StyledCreateServiceHeadingBox>
        <h1 className="main-heading">We want to know you better</h1>
        <p className="sub-heading">
          Select up to three categories that best describe you
        </p>
      </StyledCreateServiceHeadingBox>
      {/* <Spin spinning={getCategoriesQuery.isFetching}> */}
      <Step3Form
        updateStep={updateStep}
        categories={categories || []}
        savedCategories={savedCategories || []}
      />
      {/* </Spin> */}
    </div>
  );
};

export {OnboardingStep3};
