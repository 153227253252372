import {Button} from "antd";
import {StyledContainer} from "../../features-section/features-section.style";
import {
  StyledMostOutOfCoachliDemoBtn,
  StyledMostOutOfCoachliSection,
  StyledMostOutOfCoachliSectionHeader,
  StyledMostOutOfCoachliSectionImages,
} from "./most-out-of-coachli-section.style";

export function MostOutOfCoachliSection() {
  return (
    <StyledContainer>
      <StyledMostOutOfCoachliSection>
        <StyledMostOutOfCoachliSectionHeader>
          <h1 className="main-heading">Get the most out of Coachli</h1>
          <p className="sub-heading">
            Join our 'Coachli Demo Tour' to explore the platform firsthand. Let's uncover
            how Coachli empowers your growth journey
          </p>
        </StyledMostOutOfCoachliSectionHeader>
        <StyledMostOutOfCoachliSectionImages>
          <div>
            <img
              style={{maxWidth: "100%", height: "auto", display: "inline-block"}}
              src="/images/most-out-of-coachli.png"
              alt="1-1 calls"
            />
          </div>
        </StyledMostOutOfCoachliSectionImages>
        <StyledMostOutOfCoachliDemoBtn>
          <Button
            href="https://www.coachli.co/titilayo/meetings/one-to-one-with-coachli/740"
            target="_blank"
            type="primary"
            shape="round"
            size="large"
          >
            Book a free demo
          </Button>
        </StyledMostOutOfCoachliDemoBtn>
      </StyledMostOutOfCoachliSection>
    </StyledContainer>
  );
}
