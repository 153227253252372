import {Row, Col} from "antd";
import {StyledLiveClassesContentSectionWrapper} from "../live-classes-section/live-classes-section.style";
import {StyledDigitalProductsSectionWrapper} from "./digital-products-section.style";
import {PublicProfileDigitalProductsSectionCard} from "./card";
import {TGetProductApiResponse} from "../../../react-query-hooks/products";

type TDigitalProductsSectionProps = {
  products: TGetProductApiResponse["product"][];
  username: string;
};

export function DigitalProductsSection({
  products,
  username,
}: TDigitalProductsSectionProps) {
  return (
    <StyledDigitalProductsSectionWrapper>
      <h1 className="digital-products-section-heading">Store</h1>
      <StyledLiveClassesContentSectionWrapper>
        <Row gutter={[32, 20]}>
          {products.map((product) => (
            <Col key={product.id} span={24} md={12}>
              <PublicProfileDigitalProductsSectionCard
                product={product}
                username={username}
              />
            </Col>
          ))}
        </Row>
      </StyledLiveClassesContentSectionWrapper>
    </StyledDigitalProductsSectionWrapper>
  );
}
