import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Space} from "antd";
import {useForm} from "react-hook-form";
import {DayTimePicker} from "../../../components/day-time-picker";
import {StyledServiceButtonWrapper} from "../../../styles/onboarding/onboarding";
import {IAvailabilityFormValues} from "./i-availability-form";
import {AvailabilityFormSchema} from "./availability-validation-schema";
import {StyledAvailabiltyFormWrapper} from "./availability.style";
import {
  TAvailabilityApiResponse,
  useUpdateAvailability,
} from "../../../react-query-hooks/availability";
import moment from "moment";
import {BufferTime} from "./booking-notice";

const AvailabilityForm = ({
  availabilities,
}: {
  availabilities: TAvailabilityApiResponse;
}) => {
  const updateAvailabilityQueryMutation = useUpdateAvailability();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    reset,
    control,
  } = useForm<IAvailabilityFormValues>({
    resolver: yupResolver(AvailabilityFormSchema),
    mode: "onChange",
    defaultValues: {
      sun: {
        isAvailable: availabilities.sun.length > 0,
        timesAvailable:
          availabilities.sun.length > 0
            ? availabilities.sun
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      mon: {
        isAvailable: availabilities.mon.length > 0,
        timesAvailable:
          availabilities.mon.length > 0
            ? availabilities.mon
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      tue: {
        isAvailable: availabilities.tue.length > 0,
        // isAvailable: true,
        timesAvailable:
          availabilities.tue.length > 0
            ? availabilities.tue
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      wed: {
        isAvailable: availabilities.wed.length > 0,
        // isAvailable: true,
        timesAvailable:
          availabilities.wed.length > 0
            ? availabilities.wed
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      thu: {
        isAvailable: availabilities.thu.length > 0,
        timesAvailable:
          availabilities.thu.length > 0
            ? availabilities.thu
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      fri: {
        isAvailable: availabilities.fri.length > 0,
        // isAvailable: true,
        timesAvailable:
          availabilities.fri.length > 0
            ? availabilities.fri
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
      sat: {
        isAvailable: availabilities.sat.length > 0,
        // isAvailable: true,
        timesAvailable:
          availabilities.sat.length > 0
            ? availabilities.sat
            : [{from: "09:00 AM", to: "05:00 PM"}],
      },
    },
  });

  const sortTimeSlot = (
    arr: {from: string; to: string}[]
  ): {from: string; to: string}[] => {
    const mapResult = arr.map((entry) => {
      const fromEntry = moment(`11-18-2013 ${entry.from}`, "MM-DD-YYYY hh:mm A", true);
      return {
        from: fromEntry,
        to: entry.to,
      };
    });
    const sortResult = mapResult.sort((a, b) => a.from.diff(b.from));
    const finalResult = sortResult.map((res) => ({
      from: res.from.format("hh:mm A"),
      to: res.to,
    }));
    // console.log({finalResult});
    return finalResult;
  };

  const onSubmit = async (values: IAvailabilityFormValues) => {
    const timeEntries = Object.entries(values);
    const payload: any = {};
    // console.log({timeEntries});
    timeEntries.forEach((entry) => {
      if (entry[0] === "mon") {
        if (entry[1].isAvailable) {
          payload.mon = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.mon = [];
        }
      }
      if (entry[0] === "tue") {
        if (entry[1].isAvailable) {
          payload.tue = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.tue = [];
        }
      }
      if (entry[0] === "wed") {
        if (entry[1].isAvailable) {
          payload.wed = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.wed = [];
        }
      }
      if (entry[0] === "thu") {
        if (entry[1].isAvailable) {
          payload.thu = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.thu = [];
        }
      }
      if (entry[0] === "fri") {
        if (entry[1].isAvailable) {
          payload.fri = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.fri = [];
        }
      }
      if (entry[0] === "sat") {
        if (entry[1].isAvailable) {
          payload.sat = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.sat = [];
        }
      }
      if (entry[0] === "sun") {
        if (entry[1].isAvailable) {
          payload.sun = sortTimeSlot(
            entry[1].timesAvailable.map((time: any) => ({
              from: time.from,
              to: time.to,
            }))
          );
        } else {
          payload.sun = [];
        }
      }
    });
    // dispatch(createAvailability(payload));
    // console.log(moment("Mon Oct 03 2022 17:00:00 GMT+0100", "hh:mm a").format("hh:mm a"));
    // console.log({payload});
    updateAvailabilityQueryMutation.mutate(payload, {
      onSuccess: () => {
        window.scrollTo(0, 0);
      },
    });
  };

  return (
    <div>
      <Space size={24} direction="vertical" style={{display: "Flex"}}>
        <StyledAvailabiltyFormWrapper>
          <p className="availability-form-heading">
            Set your availability and weekly work hours
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{marginBottom: "2rem"}}>
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="SUN"
                checkboxId="SUN"
                checkboxName="sun.isAvailable"
                // checkboxError={errors.sunday?.isAvailable?.message}
                control={control}
                timePickerId="sun"
                timePickerName="sun.timesAvailable"
                timePickerError={errors.sun}
              />

              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="MON"
                checkboxId="mon"
                checkboxName="mon.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="mon"
                timePickerName="mon.timesAvailable"
                timePickerError={errors.mon}
              />
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="TUE"
                checkboxId="tue"
                checkboxName="tue.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="tue"
                timePickerName="tue.timesAvailable"
                timePickerError={errors.tue}
              />
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="WED"
                checkboxId="wed"
                checkboxName="wed.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="wed"
                timePickerName="wed.timesAvailable"
                timePickerError={errors.wed}
              />
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="THU"
                checkboxId="thu"
                checkboxName="thu.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="thu"
                timePickerName="thu.timesAvailable"
                timePickerError={errors.thu}
              />
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="FRI"
                checkboxId="fri"
                checkboxName="fri.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="fri"
                timePickerName="fri.timesAvailable"
                timePickerError={errors.fri}
              />
              <DayTimePicker
                watch={watch}
                setValue={setValue}
                checkboxLabel="SAT"
                checkboxId="SAT"
                checkboxName="sat.isAvailable"
                // checkboxError={errors.monday?.isAvailable?.message}
                control={control}
                timePickerId="sat"
                timePickerName="sat.timesAvailable"
                timePickerError={errors.sat}
              />

              <StyledServiceButtonWrapper style={{marginTop: "2.4rem"}}>
                <div>
                  <Button
                    loading={updateAvailabilityQueryMutation.isLoading}
                    block
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Save changes
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => reset()}
                    block
                    shape="round"
                    type="text"
                    danger
                    size="large"
                  >
                    Discard
                  </Button>
                </div>
              </StyledServiceButtonWrapper>
            </div>
          </form>
        </StyledAvailabiltyFormWrapper>
        <BufferTime />
      </Space>
    </div>
  );
};

export {AvailabilityForm};
