import axios from "axios";
import {API_BASE_URL} from "../../services/config";

// const API_BASE_URL = "http://207.154.220.50:7890/api/v1/";

// Get countries
export const getCountriesService = async () => {
  const response = await axios.get(API_BASE_URL + "countries");

  return response.data;
};
