import {Col, Row, Tabs} from "antd";
import {CustomerPastCallsComponent} from "../../../../components/calls/past-calls/customer/customer-past-calls";
import {PurchasedBundles} from "../../../../components/calls/upcoming-calls/calls-section/customer/purchased-bundles";
import {CustomerUpcomingCallsComponent} from "../../../../components/calls/upcoming-calls/customer";
import {StyledUpcomingCallsWrapper} from "../../../../components/calls/upcoming-calls/upcoming-calls.style";
import {CustomerDashboardWrapper} from "../../../../layouts/customer-dashboard-wrapper";
import {
  StyledPageHeader,
  StyledPageWrapper,
} from "../../../../styles/dashboard/calls/calls";

const CustomerCallsPage = () => {
  // const pastCallsQueryInfo = useGetPastCalls();
  // const getUpcomingCallsQuery = useGetUpcomingCalls();
  const pageTitle = "1:1 Calls";

  const handleTabClick = (key: string) => {
    // if (key === "1") getUpcomingCallsQuery.refetch();
    // if (key === "2") pastCallsQueryInfo.refetch();
  };
  return (
    <CustomerDashboardWrapper pageTitle={pageTitle}>
      <StyledPageHeader>1:1 Calls 🤙</StyledPageHeader>
      <div style={{marginTop: "1.5rem"}}>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            <Tabs defaultActiveKey="1" onTabClick={(key) => handleTabClick(key)}>
              <Tabs.TabPane tab="Upcoming calls" key="1">
                <CustomerUpcomingCallsComponent />
                <PurchasedBundles />
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Past calls"} key="2">
                <StyledUpcomingCallsWrapper>
                  <CustomerPastCallsComponent />
                </StyledUpcomingCallsWrapper>
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col span={24} lg={{span: 8}}></Col>
        </Row>
      </div>
    </CustomerDashboardWrapper>
  );
};

export {CustomerCallsPage};
