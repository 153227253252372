import {Alert, Row, Col, Button, Space, Tooltip} from "antd";
import {StyledCustomersDataBox} from "../../styles/customers/customers";
import {CustomerMetricsSkeleton} from "../customers/customer-details-section/customer-metrics-skeleton";
import {FullPageLoader} from "../full-page-loader";
import {useGetDashboardMetrics} from "../../react-query-hooks/dashboard";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const DashboardMetrics = () => {
  const getDashboardMetricsQuery = useGetDashboardMetrics();

  if (getDashboardMetricsQuery.isLoading) {
    return <FullPageLoader />;
  }
  if (getDashboardMetricsQuery.isError) {
    return (
      <Alert
        message="Error"
        description={getDashboardMetricsQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  // const handleTotalRevenue = () => {
  //   return getDashboardMetricsQuery.data.totalRevenue.reduce(
  //     (total, num) => total + Number(num.amount),
  //     0
  //   );
  // };
  return (
    <>
      {getDashboardMetricsQuery.isFetching ? (
        <CustomerMetricsSkeleton />
      ) : (
        <>
          <Space size={16} direction="vertical" style={{display: "flex"}}>
            <Row gutter={[32, 20]}>
              <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">
                    Total revenue{" "}
                    <Tooltip
                      placement="left"
                      title="Your total revenue is calculated in your payout currency"
                    >
                      <Button
                        icon={<QuestionCircleOutlined />}
                        size="small"
                        type="text"
                      />
                    </Tooltip>
                  </p>
                  <h2 className="data-box-value">
                    {`${
                      getDashboardMetricsQuery.data.totalRevenue[0]
                        ? getDashboardMetricsQuery.data.totalRevenue[0].currency
                        : getDashboardMetricsQuery.data.currency
                        ? getDashboardMetricsQuery.data.currency
                        : ""
                    } ${
                      getDashboardMetricsQuery.data.totalRevenue[0]?.amount?.toLocaleString() ??
                      0
                    }`}
                  </h2>
                </StyledCustomersDataBox>
              </Col>

              <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total Customers</p>
                  <h2 className="data-box-value">
                    {getDashboardMetricsQuery.data.totalCustomers ?? 0}
                  </h2>
                </StyledCustomersDataBox>
              </Col>
              <Col span={24} md={8}>
                <StyledCustomersDataBox>
                  <p className="data-box-title">Total Purchases</p>
                  <h2 className="data-box-value">
                    {getDashboardMetricsQuery.data.totalPurchases ?? 0}
                  </h2>
                </StyledCustomersDataBox>
              </Col>
            </Row>

            {/* {getDashboardMetricsQuery.data.totalRevenue.length > 0 && (
              <p style={{color: "#595959"}}>
                Check your earnings in all your currencies,{" "}
                <Button
                  onClick={showRevenueBreakDown}
                  style={{padding: "0"}}
                  type="link"
                  size="small"
                >
                  click here
                </Button>{" "}
                to view
              </p>
            )} */}
          </Space>
          {/* <Drawer
            title="Revenue breakdown"
            placement="right"
            onClose={onCloseRevenueBreakDown}
            visible={openRevenueBreakDown}
            // mask={false}
            closable={false}
            width={320}
            extra={
              <Button
                type="ghost"
                size="small"
                icon={<CloseOutlined />}
                onClick={onCloseRevenueBreakDown}
              ></Button>
            }
          >
            <Row gutter={[20, 16]}>
              {getDashboardMetricsQuery.data.totalRevenue.map((revenue) => (
                <Fragment key={revenue.currency}>
                  <Col span={12}>
                    <StyledRevenueListItem>
                      <div className="item-volume">
                        <p className="item-title">Currency</p>
                        <p className="item-value">{revenue.currency}</p>
                      </div>
                    </StyledRevenueListItem>
                  </Col>
                  <Col span={12}>
                    <StyledRevenueListItem>
                      <div className="item-unit">
                        <p className="item-title">Total amount</p>
                        <p className="item-value">
                          {Number(revenue.amount).toLocaleString("en-us")}
                        </p>
                      </div>
                    </StyledRevenueListItem>
                  </Col>
                </Fragment>
              ))}
            </Row>
          </Drawer> */}
        </>
      )}
    </>
  );
};
