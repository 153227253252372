import {Button, Col, Row, Space} from "antd";
import {StyledPublicProfileLiveClassesDetailsImageWrapper} from "../../../../styles/public-profile/public-profile";
import {
  StyledProductDetailsContentSection,
  StyledProductDetailsMainContentHeading,
  StyledProductDetailsMainContentWrapper,
  StyledProductDetailsRevenueSection,
} from "../product-details.style";
import {FileOutlined, LinkOutlined} from "@ant-design/icons";
import {useViewProductContext} from "../../../../context/products/view-product-context";

export function ProductDetailsMainSection() {
  const {state} = useViewProductContext();

  return (
    <div style={{backgroundColor: "#fafafa"}}>
      <StyledPublicProfileLiveClassesDetailsImageWrapper style={{borderRadius: "5px"}}>
        <img
          src={state.productData.product.coverImage}
          alt="product"
          className="banner-image"
        />
      </StyledPublicProfileLiveClassesDetailsImageWrapper>
      <StyledProductDetailsMainContentWrapper>
        <Space
          direction="vertical"
          size={32}
          style={{
            display: "flex",
          }}
        >
          <StyledProductDetailsMainContentHeading>
            <h1 className="product-title">{state.productData.product.title}</h1>
            <div
              className="product-description"
              dangerouslySetInnerHTML={{__html: state.productData.product.description}}
            />
          </StyledProductDetailsMainContentHeading>
          <StyledProductDetailsContentSection>
            <h2 className="content-heading">Contents</h2>
            {state.productData.medias.length > 0 ? (
              <ol className="content-list">
                <Space
                  direction="vertical"
                  size={26}
                  style={{
                    display: "flex",
                  }}
                >
                  {state.productData.medias.map((content) => (
                    <li key={content.id} className="content-list-item">
                      <p className="content-list-item-title">{content.file_name}</p>
                      <div className="content-list-item-file">
                        <FileOutlined className="content-list-item-file-icon" />{" "}
                        <span className="content-list-item-file-size">
                          {content.file_size}
                        </span>
                      </div>
                    </li>
                  ))}
                </Space>
              </ol>
            ) : (
              <p>No contents uploaded yet</p>
            )}
          </StyledProductDetailsContentSection>
          <StyledProductDetailsRevenueSection>
            <Row gutter={[20, 24]}>
              <Col span={24} md={8}>
                <div className="product-revenue-sub-section">
                  <h2>Available currencies</h2>
                  <div className="product-revenue-sub-section-content">
                    {state.productData.currencies.length > 0 ? (
                      <Row gutter={[24, 16]}>
                        {state.productData.currencies.map((currency) => (
                          <Col span={12} key={currency.id}>
                            <Space size={8}>
                              <div
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                  borderRadius: "50%",
                                  backgroundColor: "#CBA3FF",
                                }}
                              />
                              <p>{`${
                                currency.currency
                              } ${currency.amount.toLocaleString()}`}</p>
                            </Space>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      "Free"
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24} sm={12} md={8}>
                <div className="product-revenue-sub-section">
                  <h2>Number of sale</h2>
                  <div className="product-revenue-sub-section-content">
                    {state.productData.totalSales}
                  </div>
                </div>
              </Col>
              <Col span={24} sm={12} md={8}>
                <div className="product-revenue-sub-section">
                  <h2>Revenue</h2>
                  <div className="product-revenue-sub-section-content">
                    {state.productData.revenue.length > 0
                      ? state.productData.revenue.map((revenue) => (
                          <p
                            key={revenue.currency}
                          >{`${revenue.currency} ${revenue.amount}`}</p>
                        ))
                      : "Free"}
                  </div>
                </div>
              </Col>
            </Row>
          </StyledProductDetailsRevenueSection>
          <div>
            <Button
              style={{
                paddingLeft: 0,
                marginLeft: 0,
              }}
              icon={<LinkOutlined />}
              type="link"
              size="large"
            >
              Copy product link
            </Button>
          </div>
        </Space>
      </StyledProductDetailsMainContentWrapper>
    </div>
  );
}
