import {Navbar} from "../../components/navbar";
import {
  StyledLayout,
  StyledMainSection,
} from "../auth-form-wrapper/auth-form-wrapper.style";

interface IPublicLayout {
  children: React.ReactNode;
}

const PublicLayout: React.FC<IPublicLayout> = ({children}) => {
  return (
    <StyledLayout>
      <Navbar />
      <StyledMainSection>{children}</StyledMainSection>
    </StyledLayout>
  );
};

export {PublicLayout};
