import {Button, Alert} from "antd";
import {useState} from "react";
import {StyledNoUpcomingCallBox} from "../../../../components/calls/upcoming-calls/upcoming-calls.style";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {CustomerContactList} from "../../../../components/messages/contact-list/customer-contact-list";
import {CustomerMessageList} from "../../../../components/messages/message-list/customer";
import {
  StyledPageHeader,
  StyledPageWrapper,
} from "../../../../components/messages/messages.style";
import {CustomerDashboardWrapper} from "../../../../layouts/customer-dashboard-wrapper";
import {useGetCustomerMessages} from "../../../../react-query-hooks/messages";
import {AnalyticsEvent} from "../../../../utils/analytics";

const CustomerMessagesPage = () => {
  const getCustomerMessagesQuery = useGetCustomerMessages();
  const [showNewMessageModal, setShowNewMessageModal] = useState<boolean>(false);
  const pageTitle = "Messages";

  const handleShowNewMessageModal = () => {
    AnalyticsEvent("Customer Messaging", "Clicked on new message button");
    setShowNewMessageModal(true);
  };

  if (getCustomerMessagesQuery.isLoading) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <FullPageLoader />
      </CustomerDashboardWrapper>
    );
  }

  if (
    getCustomerMessagesQuery.isError &&
    getCustomerMessagesQuery.error.message !== "Sorry, You currently have no messages!"
  ) {
    return (
      <CustomerDashboardWrapper pageTitle={pageTitle}>
        <Alert
          message="Error"
          description={getCustomerMessagesQuery.error.message}
          type="error"
          showIcon
        />
      </CustomerDashboardWrapper>
    );
  }

  return (
    <CustomerDashboardWrapper pageTitle={pageTitle}>
      <CustomerContactList
        showModalState={showNewMessageModal}
        showModalStateUpdate={setShowNewMessageModal}
      />
      <StyledPageHeader>Messages ✉️</StyledPageHeader>
      <div style={{display: "flex", justifyContent: "end"}}>
        <Button onClick={handleShowNewMessageModal} shape="round" type="primary">
          New message
        </Button>
      </div>
      {getCustomerMessagesQuery.isLoading ? (
        <FullPageLoader />
      ) : getCustomerMessagesQuery.isError &&
        getCustomerMessagesQuery.error.message !==
          "Sorry, You currently have no messages!" ? (
        <Alert
          message="Error"
          description={getCustomerMessagesQuery.error.message}
          type="error"
          showIcon
        />
      ) : (
        <StyledPageWrapper style={{display: "block", marginLeft: 0, marginRight: 0}}>
          {getCustomerMessagesQuery.data &&
          getCustomerMessagesQuery.data.data.messages.length > 0 ? (
            <CustomerMessageList />
          ) : (
            <div style={{width: "100%"}}>
              <StyledNoUpcomingCallBox>
                <p className="info-text">You do not have any messages</p>
              </StyledNoUpcomingCallBox>
            </div>
          )}
        </StyledPageWrapper>
      )}
    </CustomerDashboardWrapper>
  );
};

export {CustomerMessagesPage};
