import {Tabs} from "antd";
import {DashboardWrapper} from "../../../layouts/dashboard-wrapper";
import {StyledPageHeader, StyledPageWrapper} from "../../../styles/dashboard/calls/calls";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AccountSettings} from "../../../components/settings/account-settings";
import {ConnectCalendar} from "../../../components/settings/connect-calendar";

const SettingPage = () => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("activeTab");
  const [defaultActiveKey, setDefaultActiveKey] = useState<"account" | "calendar">(
    "account"
  );

  useEffect(() => {
    if (activeTab && activeTab === "calendar") {
      setDefaultActiveKey(activeTab);
    }
  }, [activeTab]);

  function handleTabClick(key: string) {
    if (key === "account") {
      setDefaultActiveKey("account");
    }
    if (key === "calendar") {
      setDefaultActiveKey("calendar");
    }
  }
  return (
    <DashboardWrapper pageTitle="Settings">
      <StyledPageHeader>Settings ⚙️</StyledPageHeader>
      <StyledPageWrapper>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          activeKey={defaultActiveKey}
          onTabClick={(key) => handleTabClick(key)}
        >
          <Tabs.TabPane tab="Account settings" key="account">
            <AccountSettings />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Calendar" key="calendar">
            <ConnectCalendar />
          </Tabs.TabPane>
        </Tabs>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {SettingPage};
