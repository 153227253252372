import {Alert, Breadcrumb, Col, Row, Tabs} from "antd";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";
import {ProductForm} from "../../../../components/products/product-form";
import {useCreateProductContext} from "../../../../context/products/add-product-context";
import {useGetProduct} from "../../../../react-query-hooks/products";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {ProductContentUpload} from "../../../../components/products/product-form/content-upload";
import {ShareProductWidget} from "../../../../components/products/share-product-widget";
import {useEffect} from "react";
import {TCreateProductFormSteps} from "../../../../reducers/products/create-product-reducer";
import {SalesAndInsights} from "../../../../components/products/sales-and-insights";

export function EditProductPage() {
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("currentTab");

  const {state, dispatch} = useCreateProductContext();
  const getProductQuery = useGetProduct(id);

  const pageTitle = "View Product";

  useEffect(() => {
    if (currentTab) {
      dispatch({
        type: "update-edit-form-step",
        payload: currentTab as TCreateProductFormSteps,
      });
    }
  }, [currentTab, dispatch]);

  if (getProductQuery.isLoading) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (getProductQuery.isError) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <Alert
          message="Error"
          description={getProductQuery.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper pageTitle={`Edit Product`}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/products">Digital product</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {getProductQuery.data.product.title.length > 20
            ? `${getProductQuery.data.product.title.substring(0, 20)}...`
            : getProductQuery.data.product.title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{marginTop: "1.5rem"}}>
        <Row gutter={[36, 30]}>
          <Col span={24} lg={{span: 16}}>
            <div>
              <Tabs
                defaultActiveKey={state.currentEditProductFormStep}
                activeKey={state.currentEditProductFormStep}
                onTabClick={(key) =>
                  dispatch({
                    type: "update-edit-form-step",
                    payload: key as TCreateProductFormSteps,
                  })
                }
              >
                <Tabs.TabPane tab="Product info" key="basic">
                  <ProductForm formType="edit" productData={getProductQuery.data} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Content upload" key="content">
                  <ProductContentUpload productData={getProductQuery.data} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Sales and insights" key="insights">
                  <SalesAndInsights productData={getProductQuery.data} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
          <Col span={24} lg={{span: 8}}>
            <ShareProductWidget
              isFetching={getProductQuery.isFetching}
              productData={getProductQuery.data}
            />
          </Col>
        </Row>
      </div>
    </DashboardWrapper>
  );
}
