import {Result, Space} from "antd";
import {Footer} from "../../../../components/public-profile/footer";
import {MainDetailsSection} from "../../../../components/public-profile/live-classes-section/details/main-details-section";
import {
  StyledPublicProfileContainer,
  StyledPublicProfileLiveClassesDetailsHeading,
  StyledPublicProfileLiveClassesDetailsImageWrapper,
} from "../../../../styles/public-profile/public-profile";
import {useEffect} from "react";
import {PublicProfileNavbar} from "../../../../components/public-profile/navbar";
import {useLiveClassBookingContext} from "../../../../context/live-class-booking-context";
import {OfferingBookingModal} from "../../../../components/public-profile/offering/offering-booking-modal";

const AnotherPublicProfileLiveClassesDetailsPage = () => {
  const {state} = useLiveClassBookingContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!state.eventData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "120.2rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.eventData.avatar}
            firstName={state.eventData.firstName}
            lastName={state.eventData.lastName}
            username={state.eventData.username}
          />
          <Space
            direction="vertical"
            size={35}
            style={{
              display: "flex",
            }}
          >
            <StyledPublicProfileLiveClassesDetailsHeading>
              {state.eventData.event.name}
            </StyledPublicProfileLiveClassesDetailsHeading>
            <StyledPublicProfileLiveClassesDetailsImageWrapper>
              <img
                src={state.eventData.event.cover_image}
                alt={state.eventData.event.name}
                className="banner-image"
              />
            </StyledPublicProfileLiveClassesDetailsImageWrapper>
            <MainDetailsSection params={state.urlParams} eventData={state.eventData} />
          </Space>
          <OfferingBookingModal />
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
};

export {AnotherPublicProfileLiveClassesDetailsPage};
