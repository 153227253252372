import {Space} from "antd";
import {useGetCustomerUpcomingCalls} from "../../../../../react-query-hooks/customer";
import {CustomerCallCard} from "../../call-card/customer";
import {CustomPagination} from "../../../../pagination";
import {useState} from "react";
import {PaginationMeta} from "../../../../../redux/service/i-service";

const CustomerCallsSection = () => {
  const [page, setPage] = useState(1);
  const getCustomerUpcomingCalls = useGetCustomerUpcomingCalls(page);
  return (
    <Space direction="vertical" size={42} style={{display: "flex"}}>
      {getCustomerUpcomingCalls.data?.data.session.map((call) => (
        <CustomerCallCard key={call.id} call={call} />
      ))}
      <CustomPagination
        isPreviousData={getCustomerUpcomingCalls.isPreviousData}
        page={page}
        paginationMeta={getCustomerUpcomingCalls.data?.meta as PaginationMeta}
        setPage={setPage}
      />
    </Space>
  );
};

export {CustomerCallsSection};
