import {Controller} from "react-hook-form";
import {StyledInputLabelWrapper} from "../../../input/input.style";
import {Input} from "antd";
import {TProductInputProps} from "../form-values";

type TProductNameInputProps = TProductInputProps & {};

export function ProductNameInput({control, formState}: TProductNameInputProps) {
  return (
    <div>
      <StyledInputLabelWrapper>
        <label id="product_name" htmlFor="product_name">
          Title
        </label>
      </StyledInputLabelWrapper>
      <Controller
        name="title"
        control={control}
        render={({field: {onChange, value, ref}}) => (
          <Input
            id="product_name"
            onChange={onChange}
            size="large"
            placeholder="e.g One month mentorship"
            value={value}
            ref={ref}
          />
        )}
      />
      {formState.errors.title?.message && (
        <div role="alert" className="ant-form-item-explain-error">
          {formState.errors.title.message}
        </div>
      )}
    </div>
  );
}
