import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, message} from "antd";
import {useForm} from "react-hook-form";
import {CustomModal} from "../../../../modal";
import {CallRescheduleModalContent} from "./call-reschedule-modal-content";
import {TBooking, TCoachReschedulePayload} from "../../../../../redux/call/i-call";
import {useCallback} from "react";
import {useCoachRescheduleBooking} from "../../../../../react-query-hooks/booking";

interface ICallRescheduleModalProps {
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
  isVideo?: boolean;
  call: TBooking;
}

interface IRescheduleCallFormValues {
  personalMessage: string;
}

const rescheduleCallSchema = yup.object().shape({
  personalMessage: yup.string().trim(),
});

const CallRescheduleModal: React.FC<ICallRescheduleModalProps> = ({
  visibleState,
  okFunc,
  cancelFunc,
  isVideo,
  call,
}) => {
  const coachRescheduleQueryMutation = useCoachRescheduleBooking();
  const {
    handleSubmit,
    formState: {errors},
    reset,
    control,
  } = useForm<IRescheduleCallFormValues>({
    resolver: yupResolver(rescheduleCallSchema),
    mode: "onChange",
  });

  const handleCloseModal = useCallback(() => {
    reset({
      personalMessage: "",
    });
    cancelFunc();
  }, [cancelFunc, reset]);

  const onSubmit = async (values: IRescheduleCallFormValues) => {
    // console.log(values);
    // mentorRescheduleCall
    const payload: TCoachReschedulePayload = {
      bookingReference: call.reference,
      user_timezone: call.user_timezone,
      description: values.personalMessage,
    };
    coachRescheduleQueryMutation.mutate(payload, {
      onSuccess: () => {
        message.success("Reschedule request sent successfully!");
      },
      onSettled: () => {
        handleCloseModal();
      },
    });
  };

  return (
    // <form onSubmit={handleSubmit(onSubmit)}>
    <CustomModal
      visibleState={visibleState}
      title={`Reschedule call with ${call.first_name} ${call.last_name}`}
      okFunc={okFunc}
      cancelFunc={cancelFunc}
      footerContent={[
        <Button
          key="reschedule"
          shape="round"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          loading={coachRescheduleQueryMutation.isLoading}
          // htmlType="submit"
        >
          Request reschedule
        </Button>,
        <Button key="cancel" type="default" shape="round" onClick={handleCloseModal}>
          Cancel
        </Button>,
      ]}
    >
      <CallRescheduleModalContent
        isVideo={isVideo}
        control={control}
        error={errors.personalMessage?.message}
        call={call}
      />
    </CustomModal>
    // </form>
  );
};

export {CallRescheduleModal};
