import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError, AxiosResponse} from "axios";
import React from "react";
import {useMessageContext} from "../context/message-context";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

export type TMessageResObj = {
  id: number;
  userId: string;
  emailAddress: string;
  isReadMentor: boolean;
  isReadCustomer: boolean;
  customerName: string;
  createdAt: string;
  updatedAt: string;
};
export type TCustomerMessageResObj = {
  id: number;
  userId: string;
  emailAddress: string;
  isReadMentor: boolean;
  isReadCustomer: boolean;
  customerName: string;
  createdAt: string;
  updatedAt: string;
  mentorEmailAddress: string;
  mentorName: string;
};

export type TMessagesApiResponse = {
  messages: TMessageResObj[];
};
export type TCustomerMessagesApiResponse = {
  messages: TCustomerMessageResObj[];
};
export type TMessageContact = {
  email_address: string;
  name: string;
};
export type TCustomerMessageContact = {
  email_address: string;
  name: string;
  mentorId: number | string;
};
export type TMessageContactsApiResponse = {
  contacts: TMessageContact[];
};
export type TCustomerMessageContactsApiResponse = {
  contacts: TCustomerMessageContact[];
};

export type TMessageHistoryApiResponse = {
  messageHistory: {
    id: number;
    messageId: number;
    sender: string;
    receiver: string;
    message: string;
    createdAt: string;
    updatedAt: string;
  }[];
};

export type TSendMessageCoachPayload = {
  message: string;
  customerEmailAddress: string;
  customerName: string;
  userId: null;
  id?: string | number;
};

export type TSendMessageCustomerPayload = {
  message: string;
  userId: string | number | null;
};

const useGetCoachMessages = () => {
  const client = useClient();
  const {dispatch} = useMessageContext();

  return useQuery<
    AxiosResponse<TMessagesApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["coach_messages"],
    queryFn: () => client("messages/coach").then((data) => data),
    refetchInterval: 300000,
    // refetchOnWindowFocus: false,
    onSuccess: (data) =>
      dispatch({
        type: "update-coach-message-contact-list",
        payload: {
          coachContacts: data.data.messages,
        },
      }),
  });
};
const useGetCoachMessageContacts = () => {
  const client = useClient();

  return useQuery<
    AxiosResponse<TMessageContactsApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["coach_message_contacts"],
    queryFn: () => client("messages/coach/contacts").then((data) => data),
    refetchInterval: 300000,
  });
};

const useGetCoachMessageHistory = (id?: string | number) => {
  const client = useClient();

  const queryInfo = useQuery<
    AxiosResponse<TMessageHistoryApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["coach_message_history", id],
    queryFn: () => client(`messages/coach/history/${id}`).then((data) => data),
    refetchInterval: 300000,
    // select: React.useCallback(
    //   (data: AxiosResponse<TMessageHistoryApiResponse, any>) =>
    //     data.data.messageHistory.reverse(),
    //   []
    // ),
    enabled: !!id,
  });
  return {
    ...queryInfo,
    data: React.useMemo(
      () =>
        queryInfo.data?.data.messageHistory?.map(
          (item, idx) =>
            queryInfo.data?.data.messageHistory[
              queryInfo.data?.data.messageHistory.length - 1 - idx
            ]
        ),
      [queryInfo.data?.data.messageHistory]
    ),
  };
};

const useSendMessageCoach = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TSendMessageCoachPayload) =>
      client(`messages/coach/send`, {
        method: "POST",
        data: {
          message: payload.message,
          customerEmailAddress: payload.customerEmailAddress.toLowerCase(),
          customerName: payload.customerName,
          userId: null,
        },
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Coach send message", "Coach send message fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach send message", "Coach send message success");
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["coach_messages"]}),
        queryClient.invalidateQueries({queryKey: ["coach_message_history", values.id]}),
      ]),
  });
};

const useGetCustomerMessages = () => {
  const client = useClient();
  const {dispatch} = useMessageContext();

  return useQuery<
    AxiosResponse<TCustomerMessagesApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_messages"],
    queryFn: () => client("messages/customer").then((data) => data),
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    onSuccess: (data) =>
      dispatch({
        type: "update-customer-message-contact-list",
        payload: {
          customerContacts: data.data.messages,
        },
      }),
  });
};

const useGetCustomerMessageContacts = () => {
  const client = useClient();

  return useQuery<
    AxiosResponse<TCustomerMessageContactsApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_message_contacts"],
    queryFn: () => client("messages/customer/contacts").then((data) => data),
    refetchInterval: 300000,
  });
};

const useGetCustomerMessageHistory = (
  id?: string | number,
  mentorId?: string | number
) => {
  const client = useClient();

  const queryInfo = useQuery<
    AxiosResponse<TMessageHistoryApiResponse, any>,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["customer_message_history", mentorId],
    queryFn: () => client(`messages/customer/history/${id}`).then((data) => data),
    refetchInterval: 300000,
    // select: React.useCallback(
    //   (data: AxiosResponse<TMessageHistoryApiResponse, any>) =>
    //     data.data.messageHistory.reverse(),
    //   []
    // ),
    enabled: !!mentorId && !!id,
  });
  return {
    ...queryInfo,
    data: React.useMemo(
      () =>
        queryInfo.data?.data.messageHistory?.map(
          (item, idx) =>
            queryInfo.data?.data.messageHistory[
              queryInfo.data?.data.messageHistory.length - 1 - idx
            ]
        ),
      [queryInfo.data?.data.messageHistory]
    ),
  };
};

const useSendMessageCustomer = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TSendMessageCustomerPayload) =>
      client(`messages/customer/send`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Customer send message", "Customer send message fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Customer send message", "Customer send message success");
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["customer_messages"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["customer_message_history", values.userId],
        }),
      ]),
  });
};

export {
  useGetCoachMessages,
  useGetCoachMessageContacts,
  useGetCoachMessageHistory,
  useSendMessageCoach,
  useGetCustomerMessages,
  useGetCustomerMessageContacts,
  useGetCustomerMessageHistory,
  useSendMessageCustomer,
};
