import styled from "styled-components";

export const StyledUpcomingWrapper = styled.div`
  padding: 6.8rem 0 6.4rem 0;

  @media (min-width: 425px) {
    padding: 9.9rem 0 15.6rem 0;
  }
`;
export const StyledUpcomingHeader = styled.div`
  max-width: 77.4rem;
  margin: 0 auto;
  text-align: center;

  .main-heading {
    /* font-family: "Charis SIL"; */
    font-weight: 700;
    font-size: 3rem;
    line-height: 38px;
  }

  .sub-heading {
    max-width: 65.8rem;
    margin: 0 auto;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }

  @media (min-width: 425px) {
    .main-heading {
      font-size: 4.6rem;
      line-height: 54px;
    }

    .sub-heading {
      font-size: 2rem;
      margin-top: 2.4rem;
    }
  }
`;

export const StyledUpcomingEventList = styled.div`
  margin-top: 4rem;
  @media (min-width: 425px) {
    margin-top: 5.6rem;
  }
`;
export const StyledUpcomingTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding: 1.2rem 1.6rem;
  border-radius: 4rem;
  background-color: #f5edff;
  margin: 5.6rem auto 0 auto;
  width: fit-content;
`;
