import {Link} from "react-router-dom";
import styled from "styled-components";

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  //   height: 14.1rem;
  background: #fafafa;
`;
export const StyledFooterWrapper = styled.div`
  //   max-width: 25.8rem;
  //   margin: 0 auto;
  padding: 2.13rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-links {
    display: flex;
    gap: 2rem;
    margin: 1.6rem 0;
  }

  .footer-extra-links {
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
export const StyledFooterLink = styled(Link)`
  font-weight: 600;
  font-size: 1.2rem;
  color: #00171f;
`;
export const StyledFooterHref = styled.a`
  font-weight: 600;
  font-size: 1.2rem;
  color: #00171f;
`;
