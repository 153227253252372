import {useMutation} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";

const useCustomerLogin = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {emailAddress: string}) =>
      client(`customer/login`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Customer Login", "Customer Login Fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Customer Login", "Customer Login Success");
    },
  });
};
const useCustomerVerifyLink = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {link?: string}) =>
      client(`customer/activation-link`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Customer Link verification", "Customer Link verification Fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Customer Link verification", "Customer Link verification Success");
    },
  });
};

export {useCustomerLogin, useCustomerVerifyLink};
