import {useEffect, useRef} from "react";
import {
  motion,
  animate,
  useInView,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";

/**
 *
 * @param root0
 * @param root0.value
 */
export function Counter({
  value,
  direction = "up",
}: {
  value: number;
  direction?: "up" | "down";
}) {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 100,
    stiffness: 100,
  });
  const isInView = useInView(ref, {once: false, margin: "-100px"});

  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === "down" ? 0 : value);
    } else {
      motionValue.set(direction === "up" ? 0 : value);
    }
  }, [motionValue, isInView, direction, value]);

  useEffect(
    () =>
      springValue.on("change", (latest) => {
        if (ref.current) {
          ref.current.textContent = Intl.NumberFormat("en-US").format(latest.toFixed(0));
        }
      }),
    [springValue]
  );

  return <span ref={ref} />;
}

type AnimatedCounterProps = {
  from: number;
  to: number;
};

export function AnimatedCounter({from, to}: AnimatedCounterProps) {
  const count = useMotionValue(from);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const ref = useRef(null);
  const inView = useInView(ref);
  useEffect(() => {
    if (inView) {
      animate(count, to, {duration: 1});
    } else {
      animate(count, from, {duration: 1});
    }
  }, [count, from, inView, to]);
  return <motion.span ref={ref}>{rounded}</motion.span>;
}
