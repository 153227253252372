import * as yup from "yup";

const validLinkRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
const urlPattern =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9._~%!$&'()*+,;=:@-]*)*\/?$/;
const liveClassesFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Class name is required")
    .typeError("Must be a valid string"),
  description: yup
    .string()
    .trim()
    .required("Description is required")
    .typeError("Must be a valid string"),
  coverImage: yup
    .string()
    .trim()
    .required("Cover image is required")
    .typeError("Cover image is required"),
  // topics: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       title: yup.string().trim().required("Title is required"),
  //     })
  //   )
  //   .min(1, "At least one topic is required"),
  topic_type: yup.string().oneOf(["single", "series"], "Select a class type"),
  startDate: yup
    .string()
    .trim()
    .when("topic_type", (topic_type: "single" | "series") => {
      if (topic_type === "series") {
        return yup.string().trim().nullable(true);
      }

      return yup.string().trim().required("Start date is required").nullable(true);
    }),
  startTime: yup
    .string()
    .trim()
    .when("topic_type", (topic_type: "single" | "series") => {
      if (topic_type === "series") {
        return yup.string().trim();
      }

      return yup.string().trim().required("Start time is required");
    }),
  duration: yup
    .string()
    .trim()
    .when("topic_type", (topic_type: "single" | "series") => {
      if (topic_type === "series") {
        return yup.string().trim();
      }

      return yup.string().trim().required("Class duration is required");
    }),
  customDuration: yup
    .number()
    .transform((parsedValue, originalValue) =>
      originalValue === "" ? null : parsedValue
    )
    .when(["duration", "topic_type"], {
      is: (duration: any, topic_type: "single" | "series") =>
        duration === "custom" && topic_type === "single",
      then: (schema) =>
        schema
          .transform((parsedValue, originalValue) =>
            originalValue === "" ? null : parsedValue
          )
          .typeError("Must be a number")
          .min(15, "Must be 15 minutes and above")
          .max(180, "Can't be more than 180 minutes")
          .required("Custom duration is required"),
      otherwise: (schema) =>
        schema.transform((parsedValue, originalValue) =>
          originalValue === "" ? null : parsedValue
        ),
    }),
  classSeries: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim().required("Name is required"),
        startDate: yup.string().trim().required("Start date is required"),
        startTime: yup.string().trim().required("Start time is required"),
        duration: yup.string().trim().required("Class duration is required"),
        customDuration: yup
          .number()
          .transform((parsedValue, originalValue) =>
            originalValue === "" ? null : parsedValue
          )
          .typeError("Must be a number")
          .min(15, "Must be 15 minutes and above")
          .max(180, "Can't be more than 180 minutes")
          .required("Custom duration is required"),
      })
    )
    .when("topic_type", {
      is: (topic_type: "single" | "series") => topic_type === "single",
      then: (schema) => schema.min(0),
      otherwise: (schema) => schema.min(1, "Add a session"),
    }),
  callPrice: yup
    .number()
    .default(1)
    .typeError("Must be a number")
    .when("callPriceType", {
      is: (callPriceType: "paid" | "free") => callPriceType === "free",
      then: (schema) => schema,
      otherwise: (schema) =>
        schema.required("Price is required").min(1, "Can't be less than 1"),
    }),

  callPriceCurrency: yup.string().trim().required("Currency is required"),
  callPriceType: yup.string().required().oneOf(["paid", "free"], "Select a cost"),
  currencies: yup.array().of(
    yup.object().shape({
      callPriceCurrency: yup.string().trim().required("Call currency is required"),
      callPrice: yup
        .number()
        .required("Call price is required")
        .min(1, "Can't be less than 1")
        .default(1)
        .typeError("Must be a number"),
    })
  ),
  attendeesType: yup
    .string()
    .required()
    .oneOf(["unlimited", "limited"], "Select attendee limit"),
  attendees: yup.number().when("attendeesType", {
    is: (attendeesType: "unlimited" | "limited") => attendeesType === "unlimited",
    then: (schema) => schema.default(-1),
    otherwise: (schema) =>
      schema
        .min(1, "Can't be less than 1")
        .required("Number of attendees is required")
        .typeError("Must be a number"),
  }),
  isUrlRedirect: yup.bool().default(false),
  url_redirect: yup
    .string()
    .nullable()
    .when("isUrlRedirect", {
      is: (isUrlRedirect: boolean) => isUrlRedirect,
      then: (schema) =>
        schema
          .required("Redirect URL is required")
          .matches(urlPattern, "URL is not valid")
          .trim(),
      otherwise: (schema) => schema.notRequired(),
    }),
  // bookingQuestions: yup.array().of(
  //   yup.object().shape({
  //     question: yup.string().trim().required("Question is required"),
  //     required: yup.bool(),
  //   })
  // ),
});

export {liveClassesFormSchema};
