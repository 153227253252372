import {
  CheckCircleTwoTone,
  FacebookFilled,
  MailFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import {Button, Space} from "antd";
import {useState} from "react";
import {
  StyledSuccessBookingInfo,
  StyledSuccessBookingShareSection,
  StyledSuccessBookingShareSectionInfo,
} from "./step-3.style";
import {StyledBookingRightColumnHeader} from "../../../../../styles/booking/booking";
import {CopyIcon} from "../../../../../components/icons/icons";

type TSuccessStepProps = {
  extraParams: {
    username: string | undefined;
    emailAddress: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    shareCopyLink: string;
    handleGotoDashboard: () => void;
    handleGotoProfile: () => void;
  };
  socialLinks: {
    facebookLink: string;
    twitterLink: string;
    mailLink: string;
  };
  successContent: {
    successTitle: string;
    successMessage: React.ReactNode;
    successShareMessage: React.ReactNode;
  };
};

const NewSuccessStep = ({
  extraParams,
  socialLinks,
  successContent,
}: TSuccessStepProps) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <div>
      <Space direction="vertical" size={24}>
        <div>
          <CheckCircleTwoTone twoToneColor="#52C41A" style={{fontSize: "2.1rem"}} />
        </div>
        <StyledBookingRightColumnHeader>
          {successContent.successTitle}
        </StyledBookingRightColumnHeader>
        <StyledSuccessBookingInfo>
          {successContent.successMessage}
        </StyledSuccessBookingInfo>
        <Space size={20} wrap>
          <div>
            <Button
              shape="round"
              type="primary"
              size="large"
              onClick={() => extraParams.handleGotoDashboard()}
            >
              Go to my dashboard
            </Button>
          </div>
          <div>
            <Button
              onClick={() => extraParams.handleGotoProfile()}
              shape="round"
              size="large"
            >
              Return to profile
            </Button>
          </div>
        </Space>
      </Space>
      <StyledSuccessBookingShareSection>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <StyledBookingRightColumnHeader>Spread the news</StyledBookingRightColumnHeader>
          {/* <StyledSuccessBookingShareSectionInfoWrapper> */}
          <StyledSuccessBookingShareSectionInfo>
            {successContent.successShareMessage}
            {/* <p className="info-text">
              Let people know you just booked a session with{" "}
              <span className="mentor-name">
                {extraParams.firstName} {extraParams.lastName}
              </span>
            </p> */}
            {/* <div> */}
            <h3 className="share-heading">Share update via</h3>
            {/* </div> */}
            <div>
              <Space direction="horizontal" size={16}>
                <div>
                  <Button
                    title={isCopied ? "Copied" : "Copy link"}
                    shape="circle"
                    size="large"
                    icon={<CopyIcon />}
                    style={{backgroundColor: "#F4EEFC", color: "#9254DE", border: "none"}}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(extraParams.shareCopyLink)
                        .then(() => setIsCopied(true))
                    }
                  />
                </div>
                <div>
                  <Button
                    title="Share on Facebook"
                    shape="circle"
                    size="large"
                    icon={<FacebookFilled />}
                    style={{backgroundColor: "#EBEEF5", color: "#3B5998", border: "none"}}
                    target="_blank"
                    href={socialLinks.facebookLink}
                  />
                </div>
                <div>
                  <Button
                    title="Share on Twitter"
                    shape="circle"
                    size="large"
                    icon={<TwitterOutlined />}
                    style={{backgroundColor: "#E6F7FD", color: "#00ACEE", border: "none"}}
                    target="_blank"
                    href={socialLinks.twitterLink}
                  />
                </div>
                <div>
                  <Button
                    title="Send Mail"
                    shape="circle"
                    size="large"
                    icon={<MailFilled />}
                    style={{backgroundColor: "#FBECEB", color: "#DB4437", border: "none"}}
                    href={socialLinks.mailLink}
                  />
                </div>
              </Space>
            </div>
          </StyledSuccessBookingShareSectionInfo>
          {/* </StyledSuccessBookingShareSectionInfoWrapper> */}
        </Space>
      </StyledSuccessBookingShareSection>
    </div>
  );
};

export {NewSuccessStep};
