import * as yup from "yup";

const loginSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
  password: yup.string().trim().required("Please input your Password!"),
});
const customerLoginSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .email("That doesn't look like an email")
    .required("Please input your Email!"),
});

export {loginSchema, customerLoginSchema};
