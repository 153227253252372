import {Alert} from "antd";
import {useGetAvailability} from "../../../react-query-hooks/availability";
import {FullPageLoader} from "../../full-page-loader";
import {AvailabilityForm} from "./availability-form";

const Availability = () => {
  const getAvailabilityQuery = useGetAvailability();

  return (
    <>
      {getAvailabilityQuery.isLoading || getAvailabilityQuery.isFetching ? (
        <FullPageLoader />
      ) : getAvailabilityQuery.isError ? (
        <Alert
          message="Error"
          description={getAvailabilityQuery.error.message}
          type="error"
          showIcon
        />
      ) : (
        <AvailabilityForm availabilities={getAvailabilityQuery.data} />
      )}
    </>
  );
};

export {Availability};
