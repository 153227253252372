import {TProductPurchaseFormValues} from "../../components/public-profile/offering/product-details/purchase-modal/purchase-form/index.";
import {
  TPublicProfileProductDetailsApiResponse,
  TPurchaseProductApiResponse,
} from "../../react-query-hooks/products";

export const purchaseProductSteps = [
  "form",
  "pay_with_paystatck",
  "pay_with_flutterwave",
  "success",
] as const;

export type TProductPurchaseSteps = typeof purchaseProductSteps[number];

type TUpdateSelectedCurrency = {
  type: "update-selected-currency";
  payload: {
    selectedCurrency: {
      label: string;
      value: number | string;
    };
  };
};
type TShowPurchaseModal = {
  type: "show-purchase-modal";
  payload: boolean;
};
type TUpdatePurchaseStep = {
  type: "update-purchase-step";
  payload: number;
};
type TUpdateProductPurchaseFormValues = {
  type: "update-product-purchase-form-values";
  payload: TProductPurchaseFormValues;
};
type TUpdateProductPurchaseApiResponse = {
  type: "update-product-purchase-api-response";
  payload: TPurchaseProductApiResponse;
};
type TResetState = {
  type: "reset-state";
};

export type TProductPurchaseActions =
  | TUpdateSelectedCurrency
  | TShowPurchaseModal
  | TUpdatePurchaseStep
  | TUpdateProductPurchaseFormValues
  | TUpdateProductPurchaseApiResponse
  | TResetState;

export type TProductPurchaseState = {
  selectedCurrency?: {
    label: string;
    value: number | string;
  };
  isPurchaseModalOpen: boolean;
  urlParams: {
    username: string | undefined;
    slug: string | null;
    productId: string | null;
  };
  currentPurchaseStep: number;
  productData?: TPublicProfileProductDetailsApiResponse;
  productPurchaseFormValues?: TProductPurchaseFormValues;
  productPurchaseApiResponseData?: TPurchaseProductApiResponse;
};

export const initialProductPurchaseState: TProductPurchaseState = {
  selectedCurrency: undefined,
  isPurchaseModalOpen: false,
  urlParams: {
    productId: null,
    slug: null,
    username: undefined,
  },
  currentPurchaseStep: 1,
  productData: undefined,
  productPurchaseFormValues: undefined,
  productPurchaseApiResponseData: undefined,
};

export const productPurchaseReducer = (
  state: TProductPurchaseState = initialProductPurchaseState,
  action: TProductPurchaseActions
) => {
  if (action.type === "update-selected-currency") {
    const {selectedCurrency} = action.payload;
    return {...state, selectedCurrency};
  }
  if (action.type === "show-purchase-modal") {
    return {...state, isPurchaseModalOpen: action.payload};
  }
  if (action.type === "update-purchase-step") {
    if (action.payload > 4) {
      return state;
    }
    return {...state, currentPurchaseStep: action.payload};
  }
  if (action.type === "update-product-purchase-form-values") {
    return {...state, productPurchaseFormValues: action.payload};
  }
  if (action.type === "update-product-purchase-api-response") {
    return {...state, productPurchaseApiResponseData: action.payload};
  }
  if (action.type === "reset-state") {
    return {...initialProductPurchaseState};
  }

  return state;
};
