import axios from "axios";
import {API_BASE_URL} from "../../services/config";

// Get categories
export const getCategoriesService = async () => {
  const response = await axios.get(API_BASE_URL + "categories");

  return response.data;
};
// Get user saved categories
export const getUserSavedCategoriesService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_BASE_URL + "saved-categories", config);

  return response.data;
};
// Update user saved categories
export const updateUserSavedCategoriesService = async (
  categoryData: {category: string},
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_BASE_URL + "saved-categories",
    categoryData,
    config
  );

  return response.data;
};
