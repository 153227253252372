import moment from "moment";
import momentTimeZone from "moment-timezone";
import {TBooking} from "../redux/call/i-call";
import {
  PublicProfileApiResponseEvent,
  TCustomerEvent,
  TLiveClassApiResponse,
} from "../react-query-hooks/live-events/live-classes";
import {GetCoHostedEvents} from "../react-query-hooks/live-events/co-hosts/co-hosts";
// export const getTimeZone = (
//   zoneName:
//     | "short"
//     | "long"
//     | "shortOffset"
//     | "longOffset"
//     | "shortGeneric"
//     | "longGeneric",
//   timeZone?: string
// ) => {
//   // set up formatter
//   let formatter = new Intl.DateTimeFormat(undefined, {
//     timeZone,
//     timeZoneName: zoneName,
//   });
//   // run formatter on current date
//   const timeZoneObj = formatter
//     .formatToParts(Date.now())
//     .find((formatted) => formatted.type === "timeZoneName");
//   console.log({timeZoneObj});
//   return timeZoneObj?.value;

//   // return formatter;
// };
export const getTimeZone = (timeZone: string) => {
  return `GMT ${momentTimeZone.utc().tz(timeZone).format("Z")}`;
};
export const formatAMPM = (date: Date) => {
  var hours = date.getHours();
  var minutes: string | number = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
// export const getTimeByTimeZone = (timezone: string) => {
//   const formatter = new Intl.DateTimeFormat(undefined, {
//     timeZone: timezone,
//     hour: "numeric",
//     minute: "numeric",
//   });
//   // console.log(formatter.format(setInterval(() => return new Date(), 30000)));
//   return formatter.format(new Date());
// };
export const getTimeByTimeZone = (timezone: string) => {
  return momentTimeZone.utc().tz(timezone).format("HH:mm a").toUpperCase();
};

export const getCurrentTimeSlots = (time: any, zone: any, mentorsTimezone: any) => {
  // first convert to mentors timezone
  // const mentorsTimezone = toTimeZone(time, bookingServiceData.timeZone);
  // const finalValue = toTimeZone(mentorsTimezone, zone);
  const momentTime = moment(`2013-11-18 ${time}`).format("HH:mm:ss");
  const timeZoneOffset = momentTimeZone(`2013-11-18 ${time}`)
    .tz(mentorsTimezone as string)
    .format("Z");
  const convertedTimeSlotDate = new Date(
    new Date(`2013-11-18T${momentTime}${timeZoneOffset}`).toLocaleString("en-US", {
      timeZone: zone,
    })
  );
  const convertedTimeSlotTime = moment(convertedTimeSlotDate).format("hh:mm A");

  // console.log({
  //   momentTime,
  //   timeZoneOffset,
  //   convertedTimeSlotDate,
  //   ts: `2013-11-18T${momentTime}${timeZoneOffset}`,
  //   convertedTimeSlotTime,
  // });

  return convertedTimeSlotTime;
  // var m = momentTimezone
  //   .tz(`2013-11-18 ${time}`, bookingServiceData.timeZone as string)
  //   .format();
  // .format("hh:mm A");
  // const rr = moment(`T18:00:00+08:00`)/
  // console.log({m, newM, time, mentorsTimezone, finalValue});
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// A year is a leap year if the following conditions are satisfied:

// The year is a multiple of 400.
// The year is a multiple of 4 and not a multiple of 100.

export const isLeapYear = (year: number) => {
  return (
    (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
    (year % 100 === 0 && year % 400 === 0)
  );
};

export const getFebDays = (year: number) => {
  return isLeapYear(year) ? 29 : 28;
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalizeFirstLetter(string: string) {
  const newStr = string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  return newStr;
}

export function SortBooking(booking: TBooking) {
  const callStartTime = booking.coach_from_time
    ? booking.coach_from_time.split(":")
    : booking.timeslot.split(":");
  const hours = Number(callStartTime[0]);
  const minutes = callStartTime[1].split(" ")[0];
  const AMPM = callStartTime[1].split(" ")[1];

  const mapTimeToNumber = {
    1: AMPM === "PM" ? "13" : "01",
    2: AMPM === "PM" ? "14" : "02",
    3: AMPM === "PM" ? "15" : "03",
    4: AMPM === "PM" ? "16" : "04",
    5: AMPM === "PM" ? "17" : "05",
    6: AMPM === "PM" ? "18" : "06",
    7: AMPM === "PM" ? "19" : "07",
    8: AMPM === "PM" ? "20" : "08",
    9: AMPM === "PM" ? "21" : "09",
    10: AMPM === "PM" ? "22" : "10",
    11: AMPM === "PM" ? "23" : "11",
    12: AMPM === "PM" ? "00" : "12",
  };

  const mapMonthToNumber = {
    1: "01",
    2: "02",
    3: "03",
    4: "04",
    5: "05",
    6: "06",
    7: "07",
    8: "08",
    9: "09",
    10: "10",
    11: "11",
    12: "12",
  };

  const bookingStartTime = moment(
    new Date(
      `${booking.booking_year}-${
        mapMonthToNumber[
          booking.booking_month as unknown as keyof typeof mapMonthToNumber
        ]
      }-${
        Number(booking.booking_date) < 10
          ? mapMonthToNumber[
              booking.booking_date as unknown as keyof typeof mapMonthToNumber
            ]
          : booking.booking_date
      }T${
        Number(hours) < 10
          ? mapTimeToNumber[hours as unknown as keyof typeof mapTimeToNumber]
          : hours
      }:${minutes}:00`
    )
  );
  const bookingStartTime2 =
    // new Date(
    `${booking.booking_year}-${
      mapMonthToNumber[booking.booking_month as unknown as keyof typeof mapMonthToNumber]
    }-${
      Number(booking.booking_date) < 10
        ? mapMonthToNumber[
            booking.booking_date as unknown as keyof typeof mapMonthToNumber
          ]
        : booking.booking_date
    }T${
      Number(hours) < 10
        ? mapTimeToNumber[hours as unknown as keyof typeof mapTimeToNumber]
        : hours
    }:${minutes}:00`;
  // );

  const dateToday = moment(new Date());
  const timeDiff = bookingStartTime.diff(dateToday, "minutes");

  return {
    ...booking,
    timeDiff,
  };
}

export function SortBookingByTime(bookings?: TBooking[]) {
  if (bookings) {
    const bookingsWithTimeDiff = bookings.map((booking) => SortBooking(booking));
    const sortedbookingsWithTimeDiff = bookingsWithTimeDiff.sort((a, b) => {
      if (a.timeDiff < b.timeDiff) {
        return -1;
      }
      if (a.timeDiff > b.timeDiff) {
        return 1;
      }
      return 0;
    });

    return sortedbookingsWithTimeDiff;
  } else {
    return [];
  }
}

export function SortLiveClass(
  liveClass: TLiveClassApiResponse | PublicProfileApiResponseEvent
) {
  const startDate = liveClass.activeSession.startDate;
  const startTime = liveClass.activeSession.startTime;

  const classStartTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD h:mm A", true);
  const dateToday = moment(new Date());
  const timeDiff = classStartTime.diff(dateToday, "minutes");

  return {
    ...liveClass,
    timeDiff,
  };
}
export function SortCoHostedLiveClasses(liveClass: GetCoHostedEvents) {
  const startDate = liveClass.activeSession.startDate;
  const startTime = liveClass.activeSession.startTime;

  const classStartTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD h:mm A", true);
  const dateToday = moment(new Date());
  const timeDiff = classStartTime.diff(dateToday, "minutes");

  return {
    ...liveClass,
    timeDiff,
  };
}

export function SortCoHostedLiveClassesByTime(liveClasses?: GetCoHostedEvents[]) {
  if (liveClasses) {
    const liveClassesWithTimeDiff = liveClasses.map((booking) =>
      SortCoHostedLiveClasses(booking)
    );
    const sortedliveClassesWithTimeDiff = liveClassesWithTimeDiff.sort((a, b) => {
      if (a.timeDiff < b.timeDiff) {
        return -1;
      }
      if (a.timeDiff > b.timeDiff) {
        return 1;
      }
      return 0;
    });

    return sortedliveClassesWithTimeDiff;
  } else {
    return [];
  }
}
export function SortLiveClassByTime(
  liveClasses?:
    | TLiveClassApiResponse[]
    | PublicProfileApiResponseEvent[]
    | GetCoHostedEvents[]
) {
  if (liveClasses) {
    const liveClassesWithTimeDiff = liveClasses.map((booking) => SortLiveClass(booking));
    const sortedliveClassesWithTimeDiff = liveClassesWithTimeDiff.sort((a, b) => {
      if (a.timeDiff < b.timeDiff) {
        return -1;
      }
      if (a.timeDiff > b.timeDiff) {
        return 1;
      }
      return 0;
    });

    return sortedliveClassesWithTimeDiff;
  } else {
    return [];
  }
}
export function SortPublicProfileLiveClass(liveClass: PublicProfileApiResponseEvent) {
  const startDate = liveClass.activeSession.startDate;
  const startTime = liveClass.activeSession.startTime;

  const classStartTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD h:mm A", true);
  const dateToday = moment(new Date());
  const timeDiff = classStartTime.diff(dateToday, "minutes");

  return {
    ...liveClass,
    timeDiff,
  };
}

export function SortPublicProfileLiveClassByTime(
  liveClasses?: PublicProfileApiResponseEvent[]
) {
  if (liveClasses) {
    const liveClassesWithTimeDiff = liveClasses.map((booking) =>
      SortPublicProfileLiveClass(booking)
    );
    const sortedliveClassesWithTimeDiff = liveClassesWithTimeDiff.sort((a, b) => {
      if (a.timeDiff < b.timeDiff) {
        return -1;
      }
      if (a.timeDiff > b.timeDiff) {
        return 1;
      }
      return 0;
    });

    return sortedliveClassesWithTimeDiff;
  } else {
    return [];
  }
}
export function SortCustomerLiveClass(liveClass: TCustomerEvent) {
  const startDate = liveClass.event_date;
  const startTime = liveClass.customer_event_time;

  const classStartTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD h:mm A", true);
  const dateToday = moment(new Date());
  const timeDiff = classStartTime.diff(dateToday, "minutes");

  return {
    ...liveClass,
    timeDiff,
  };
}

export function SortCustomerLiveClassByTime(liveClasses?: TCustomerEvent[]) {
  if (liveClasses) {
    const liveClassesWithTimeDiff = liveClasses.map((booking) =>
      SortCustomerLiveClass(booking)
    );
    const sortedliveClassesWithTimeDiff = liveClassesWithTimeDiff.sort((a, b) => {
      if (a.timeDiff < b.timeDiff) {
        return -1;
      }
      if (a.timeDiff > b.timeDiff) {
        return 1;
      }
      return 0;
    });

    return sortedliveClassesWithTimeDiff;
  } else {
    return [];
  }
}
