import {NavLink as Link} from "react-router-dom";
import styled from "styled-components";

export const StyledNavbarWrapper = styled.nav`
  width: 100%;
  background-color: #fff;
  // backdrop-filter: blur(100px);
  position: fixed;
  top: 0;
  z-index: 150;
  box-shadow: -2px 3px 90px -20px rgb(0 0 0 / 25%);
`;

export const StyledNavbarContainer = styled.div`
  max-width: 128rem;
  margin: 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8.1rem;
  @media (max-width: 1024px) {
    max-width: 102.4rem;
  }
  @media (max-width: 767px) {
    max-width: 76.7rem;
  }
`;

export const StyledNavbarMenu = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  @media (max-width: 1025px) {
    display: none !important;
  }
`;
export const StyledNavbarMenuList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const StyledNavbarMenuListItem = styled.li`
  display: inline-block;

  .navbar-menu-list-item-link {
    text-decoration: none;
    padding: 0 3rem;
    color: #000;
    &:hover {
      color: #9747ff;
    }
  }
`;

export const StyledNavLinkItem = styled(Link)`
  text-decoration: none;
  padding: 0 3rem;
  color: #000;

  &.active {
    color: #9747ff;
    font-weight: 700;
  }
`;

export const StyledNavCTA = styled.div`
  @media (max-width: 1025px) {
    display: none !important;
  }
`;
export const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 3rem;

  @media screen and (max-width: 1025px) {
    display: block;
  }
`;
