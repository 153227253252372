import styled from "styled-components";

export const StyledTermsOrderList = styled.ol`
  padding: 0;
  padding-left: 1.4rem;
`;
export const StyledTermsOrderListItem = styled.li`
  font-size: 1.8rem;
  margin-bottom: 4rem;
  &::marker {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .text-uppercase {
    text-transform: uppercase;
  }
`;
