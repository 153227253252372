import {Card} from "antd";
import styled from "styled-components";

export const StyledServicesSectionWrapper = styled.div`
  margin-top: 2.5rem;

  .services-section-heading {
    font-weight: 700;
    font-size: 2.4rem;
  }

  @media (max-width: 500px) {
    .services-section-heading {
      font-size: 2rem;
    }
  }
`;
export const StyledServiceListGrid = styled.div`
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(39.7rem, 1fr));
  grid-column-gap: 3.2rem;
  grid-row-gap: 2.4rem;
`;
export const StyledServiceCardWrapper = styled(Card)`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  /* height: 100%; */
  .service-image-wrapper {
    width: 100%;
    height: auto;
    border-radius: 5px;
    /* margin-bottom: 8px; */
    .service-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .service-desc {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* height: 5rem; */
  }
`;

export const StyledServiceCardExtraInfo = styled.div`
  /* margin-top: 2.7rem; */
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex-wrap: wrap;

  .phone-number-extra-details {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .service-card-extra-info-item {
    .service-card-extra-info-item-value {
      font-weight: 500;
      font-size: 1.4rem;
      color: #00171f;
    }
  }

  // @media (max-width: 600px) {
  //   flex-direction: column;
  //   align-items: initial;
  // }
`;
