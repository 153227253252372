import {userAccountActivationLogoutReset} from "../redux/auth/account-activation/account-activation-slice";
import {userResetPasswordLogoutReset} from "../redux/auth/forgot-password/forgot-password-slice";
import {logoutUser, userLoginLogoutReset} from "../redux/auth/login/user-login-slice";
import {userRegisterlogoutReset} from "../redux/auth/register/user-register-slice";
import {callLogoutReset} from "../redux/call/call-slice";
import {categoryLogoutReset} from "../redux/category/category-slice";
import {countryLogoutReset} from "../redux/country/countrySlice";
import {userProfileLogoutReset} from "../redux/profile/profile-slice";
import {store} from "../redux/store";
import {userTimezoneLogoutReset} from "../redux/timezone/timezone-slice";

const logoutReset = () => {
  store.dispatch(countryLogoutReset());
  store.dispatch(logoutUser());
  store.dispatch(userRegisterlogoutReset());
  store.dispatch(userResetPasswordLogoutReset());
  store.dispatch(userAccountActivationLogoutReset());
  store.dispatch(categoryLogoutReset());
  store.dispatch(countryLogoutReset());
  store.dispatch(userProfileLogoutReset());
  store.dispatch(userTimezoneLogoutReset());
  store.dispatch(callLogoutReset());
  store.dispatch(userLoginLogoutReset());
};

export {logoutReset};
// const finalFunc = useLogoutMutation()
