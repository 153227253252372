import styled from "styled-components";

export const StyledProductDetailsWrapper = styled.div`
  padding: 6.8rem 2rem 20.4rem 2rem;
`;
export const StyledProductDetailsPageContainer = styled.div`
  max-width: 123.2rem;
  margin: 0 auto;
`;
export const StyledProductDetailsMainSectionContainer = styled.div`
  max-width: 83.6rem;
  margin: 4.6rem auto 0 auto;
`;
export const StyledProductDetailsMainSectionActionButtons = styled.div`
  margin-top: 12.8rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;

  @media (max-width: 426px) {
    flex-direction: column;
    align-items: initial;
    justify-content: initial;

    .product-main-details-action-btn {
      width: 100%;
    }
  }
`;
export const StyledProductDetailsMainContentWrapper = styled.div`
  padding: 1.6rem;
`;

export const StyledProductDetailsMainContentHeading = styled.section`
  .product-title {
    font-weight: 700;
    font-size: 2.4rem;

    @media (max-width: 425px) {
      font-size: 2rem;
      // line-height: 1.3;
    }
  }

  .product-description {
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }
`;
export const StyledProductDetailsContentSection = styled.section`
  .content-heading {
    font-size: 2rem;
    font-weight: 600;
  }

  .content-list {
    margin-top: 1.6rem;
    padding-left: 1.5rem;

    .content-list-item {
      font-size: 1.6rem;

      .content-list-item-title {
      }
      .content-list-item-file {
        margin-top: 1.4rem;

        .content-list-item-file-icon {
          color: #595959;
          font-size: 2rem;
          margin-right: 8px;
        }
        .content-list-item-file-size {
          color: #8c8c8c;
        }
      }
    }
  }
`;
export const StyledProductDetailsRevenueSection = styled.section`
  .product-revenue-sub-section {
    .product-revenue-sub-section-content {
      margin-top: 1.6rem;
      font-size: 1.6rem;
    }
  }
`;
