import styled from "styled-components";

export const StyledDashboardHomeMainSectionWrapper = styled.main`
  /* position: relative; */
  /* display: grid;
  align-items: start;
  gap: 3rem 5.7rem; */
  margin-top: 4.7rem;

  /* @media (min-width: 768px) {
    grid-template-columns: 71.5rem auto;
    grid-template-rows: auto;

    .dashboard-home-main-details-upcoming-content {
      grid-column: 1/2;
      grid-row: auto;
    }
    .dashboard-home-main-details-widget-content {
      grid-column: 2/3;
      grid-row: 1 / auto;
      position: sticky;
      top: 2rem;
    }
  } */
`;
