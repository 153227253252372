import moment from "moment";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useBookingData} from "../../../container/booking/booking-context";
import {useCalendar} from "../../../hooks/use-calendar";
import {getBookingTimeSlot} from "../../../redux/booking/booking-slice";
import {AppDispatch, RootState} from "../../../redux/store";
import {CalendarBody} from "../calendar-body";
import {CalendarHeader} from "../calendar-header";
import {StyledCalendarWrapper} from "../calendar.style";

const RescheduleCustomCalendar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const {setValues, data} = useBookingData();

  const {customerBooking} = useSelector((state: RootState) => state.booking);

  function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      const dateToAdd = new Date(date);
      dates.push(
        `${dateToAdd.getFullYear()}-${dateToAdd.getMonth() + 1}-${dateToAdd.getDate()}`
      );
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const unavailableDateRanges = customerBooking.unavailableDates?.map((date) =>
    getDatesInRange(new Date(date.from), new Date(date.to))
  );
  let unavailableDateRangesState: string[] = [];

  if (unavailableDateRanges) {
    const newUD = [].concat(...(unavailableDateRanges as any));
    const uniqueArryDateRange = [...(new Set(newUD) as any)];
    unavailableDateRangesState = [...uniqueArryDateRange];
  }
  // console.log({unavailableDateRanges, unavailableDateRangesState});

  const arrDays = [];
  for (const key in customerBooking.days) {
    arrDays.push({
      name: key,
      value: (customerBooking.days as any)[key].length > 0,
    });
  }
  const arrDaysNum = arrDays
    .filter((day) => day.value)
    .map((day) =>
      day.name === "sun"
        ? 0
        : day.name === "mon"
        ? 1
        : day.name === "tue"
        ? 2
        : day.name === "wed"
        ? 3
        : day.name === "thu"
        ? 4
        : day.name === "fri"
        ? 5
        : day.name === "sat"
        ? 6
        : null
    );
  const {
    days,
    currentMonth: newCurrentMonth,
    currentYear: year,
    currentSelectedDate,
    setCurrentSelectedDate,
  } = useCalendar(currentMonth, currentYear, arrDaysNum, unavailableDateRangesState);

  const handlePrev = () => {
    setCurrentMonth((prevState) => prevState - 1);
    if (currentMonth < 1) {
      setCurrentMonth(11);
      setCurrentYear((prevState) => prevState - 1);
    }
  };

  const handleNext = () => {
    setCurrentMonth((prevState) => prevState + 1);
    if (currentMonth > 10) {
      setCurrentMonth(0);
      setCurrentYear((prevState) => prevState + 1);
    }
  };
  const handleSetCurrentDate = (day: number | null) => {
    if (!day) {
      return;
    }
    // const str = `${year}-${currentMonth + 1}-${day}`;
    // const selectedDateString = new Date(str);
    const selectedDateString = new Date(year, currentMonth, day);
    setValues({
      ...data,
      selectedDateString,
      booking_date: day,
      booking_year: year,
      booking_month: currentMonth + 1,
    });
    setCurrentSelectedDate(selectedDateString);

    const payload = {
      mentorId: customerBooking.profile?.userId,
      serviceId: customerBooking.service?.id,
      date: day,
      year,
      month: currentMonth + 1,
      day:
        selectedDateString.getDay() === 0
          ? "sun"
          : selectedDateString.getDay() === 1
          ? "mon"
          : selectedDateString.getDay() === 2
          ? "tue"
          : selectedDateString.getDay() === 3
          ? "wed"
          : selectedDateString.getDay() === 4
          ? "thu"
          : selectedDateString.getDay() === 5
          ? "fri"
          : "sat",
      mentorTimeZone: customerBooking.timeZone as string,
      userTimeZone: data.selectedTimeZone as string,
    };
    // console.log(payload);
    dispatch(getBookingTimeSlot(payload));
  };
  return (
    <StyledCalendarWrapper>
      <CalendarHeader
        currentMonth={newCurrentMonth}
        year={year}
        month={currentMonth}
        currentSelectedDate={currentSelectedDate}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <CalendarBody
        days={days}
        setCurrentDate={handleSetCurrentDate}
        currentSelectedDate={currentSelectedDate}
      />
    </StyledCalendarWrapper>
  );
};

export {RescheduleCustomCalendar};
