import styled from "styled-components";

export const StyledConnectCalendarWrapper = styled.div`
  padding: 3.2rem;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  background: #fff;

  /* Card elevation */
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

export const StyledConnectCalendarBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .connect-btn {
    width: 100%;
  }

  @media (min-width: 425px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .connect-btn {
      width: auto;
    }
  }
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
`;
export const StyledHrLine = styled.div`
  height: 1px;
  background-color: #f0f0f0;
`;
