import {TCreateProductApiResponse} from "../../react-query-hooks/products";

export type TCreateProductFormSteps = "basic" | "content" | "insights";

type TUpdateCreateProductFormStep = {
  type: "update-create-form-step";
  payload: TCreateProductFormSteps;
};

type TUpdateEditProductFormStep = {
  type: "update-edit-form-step";
  payload: TCreateProductFormSteps;
};

type TUpdateCreateProductApiResponse = {
  type: "update-create-product-api-response";
  payload: TCreateProductApiResponse;
};

type TResetCreateProductFormState = {
  type: "reset-state";
};

export type TCreateProductFormActions =
  | TUpdateCreateProductFormStep
  | TUpdateEditProductFormStep
  | TUpdateCreateProductApiResponse
  | TResetCreateProductFormState;

export type TCreateProductFormState = {
  currentCreateProductFormStep: TCreateProductFormSteps;
  currentEditProductFormStep: TCreateProductFormSteps;
  productData?: TCreateProductApiResponse;
};

export const initialCreateProductFormState: TCreateProductFormState = {
  currentCreateProductFormStep: "basic",
  currentEditProductFormStep: "basic",
};

export const createProductFormReducer = (
  state: TCreateProductFormState = initialCreateProductFormState,
  action: TCreateProductFormActions
) => {
  if (action.type === "update-create-form-step") {
    return {...state, currentCreateProductFormStep: action.payload};
  }
  if (action.type === "update-edit-form-step") {
    return {...state, currentEditProductFormStep: action.payload};
  }
  if (action.type === "update-create-product-api-response") {
    return {...state, productData: action.payload};
  }
  if (action.type === "reset-state") {
    return {...initialCreateProductFormState};
  }

  return state;
};
