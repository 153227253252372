import {
  CloseOutlined,
  PlusCircleOutlined,
  CopyOutlined,
  FacebookFilled,
  TwitterOutlined,
  MailFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  Space,
  Input,
  Row,
  Col,
  Radio,
  Button,
  Switch,
  Tooltip,
  message,
  Modal,
  Select,
  Checkbox,
} from "antd";
import {ChangeEvent, useEffect, useState} from "react";
import {Controller, useForm, useFieldArray} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import slug from "slug";
import {
  bundleIntervalOptions,
  bundleSessionOptions,
  CallDurationOptions,
} from "../../../../container/onboarding/step-1/step-1-utils";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import {
  TBundleApiPayloadReq,
  TUpdateBundleApiPayloadReq,
  useAddBundle,
  useDeleteBundle,
  useUpdateBundle,
} from "../../../../react-query-hooks/service";
import {
  TBundleApiPayload,
  TServiceApiResponse,
} from "../../../../redux/service/i-service";
import {
  StyledFormWrapper,
  StyledCreateServiceHeadingBox,
  StyledCallLocationItem,
  StyledQuestionInputContainer,
  StyledQuestionRequiredContainer,
  StyledServiceButtonWrapper,
  StyledDoThisLaterBtn,
} from "../../../../styles/onboarding/onboarding";
import {CustomCheckboxInput} from "../../../checkbox";
import {CustomInputComponent} from "../../../input/input-component";
import {StyledEditorWrapper, StyledInputLabelWrapper} from "../../../input/input.style";
import {CustomModal} from "../../../modal";
import {CustomSelectInput} from "../../../select";
import {StyledModalContentWrapper} from "../../upcoming-calls/call-card/call-card.style";
import {StyledSuccessServiceContent} from "../service-form/service-form.style";
import {bundleFormSchema} from "./bundle-form-validation-schema";
import {
  handleAddNewCurrency,
  handleCurrencySelectOnChange,
  handleRemoveCurrency,
  updateCurrencyOptionsState,
} from "../../../../utils/currency";
import {Currency} from "../service-form/i-service-form";
import {
  TCurrencyDeletePayload,
  useDeleteCurrency,
} from "../../../../react-query-hooks/currency";
import {useCurrencyContext} from "../../../../context/currency-context";
import ReactQuill from "react-quill";
import {ServiceCoverImageUpload} from "./cover-image-upload";
const {Option} = Select;

type TBundleFormProps = {
  bundle?: TServiceApiResponse;
  type: "new" | "edit";
  serviceId?: string;
};

export type TBundleApiPayloadFormValues = Omit<
  TBundleApiPayload,
  "audioLocation" | "videoLocation"
> & {
  callLocation: string;
  customCallDuration: string | number;
  callPriceType: "paid" | "free";
  isUrlRedirect: boolean;
  request_phone_number: boolean;
  currencies: Currency[];
};

const BundleForm = ({bundle, type, serviceId}: TBundleFormProps) => {
  const {state} = useCurrencyContext();
  const [currencyOptions, setCurrencyOptions] = useState(() => state.formCurrencies);
  const createBundleQueryMutation = useAddBundle();
  const updateBundleQueryMutation = useUpdateBundle();
  const deleteBundleQueryMutation = useDeleteBundle();

  const navigate = useNavigate();
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState<boolean>(false);
  const [toolTipText, setToolTipText] = useState<string>("copy service url");
  const getProfileQuery = useGetProfile();
  const deleteCurrencyMutation = useDeleteCurrency();

  // const countries = profile

  const handleShowSuccessModal = (data: {id: string; slug: string; name: string}) => {
    setOpenSuccessfulModal(true);
  };

  const handleOk = () => {
    setOpenSuccessfulModal(false);
    navigate("/dashboard/calls");
  };
  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    control,
    clearErrors,
    reset,
    setError,
  } = useForm<TBundleApiPayloadFormValues>({
    resolver: yupResolver(bundleFormSchema),
    mode: "onChange",
    defaultValues: {
      name: bundle?.name,
      description: bundle?.description,
      slug: bundle?.slug,
      numberOfSessions: bundle?.no_of_sessions,
      bookingInterval: bundle?.booking_interval,
      callPriceCurrency: bundle?.callPriceCurrency || state.formCurrencies[0],
      callPrice: bundle?.callPrice === 0 ? 1 : bundle?.callPrice,
      callDuration:
        CallDurationOptions.find((ele) => ele.value === bundle?.callDuration) !==
        undefined
          ? bundle?.callDuration
          : !bundle?.callDuration
          ? bundle?.callDuration
          : "custom",
      customCallDuration: CallDurationOptions.find(
        (ele) => ele.value === bundle?.callDuration
      )
        ? undefined
        : bundle?.callDuration,
      hideServiceFromMenu: bundle?.hideServiceFromMenu,
      callLocation: bundle?.audioLocation ? "phone" : "video",
      ...(type === "edit" && {isPaused: bundle?.statusName === "Paused"}),
      bookingQuestions: bundle?.bookingQuestions || [],
      callPriceType:
        (type === "new" && state.connectedBankStatus) ||
        (bundle && bundle.callPrice > 0 && state.connectedBankStatus)
          ? "paid"
          : "free",
      isUrlRedirect: !!bundle?.url_redirect,
      url_redirect: bundle?.url_redirect,
      currencies: bundle
        ? bundle.currencies.map((currency) => ({
            callPrice: currency.amount,
            callPriceCurrency: currency.currency,
          }))
        : [],
      cover_image: bundle?.cover_picture,
      request_phone_number: !!bundle?.request_phone_number,
    },
  });

  const {fields, append, remove} = useFieldArray({
    control,
    name: "bookingQuestions",
  });

  const {
    fields: currencyFields,
    append: appendCurrency,
    remove: removeCurrency,
  } = useFieldArray({
    control,
    name: "currencies",
  });
  const currencyArrayValue = watch("currencies");
  const defaultCallCurrency = watch("callPriceCurrency");

  // TO ALWAYS KEEP THE SOCIAL OPTIONS UP TO DATE WITH NEW VALUES
  useEffect(() => {
    updateCurrencyOptionsState(
      state.formCurrencies,
      currencyArrayValue,
      defaultCallCurrency,
      setCurrencyOptions
    );
  }, [state.formCurrencies, currencyArrayValue, defaultCallCurrency]);

  const handleServiceLinkChange = (e: ChangeEvent<HTMLInputElement>, onChange: any) => {
    onChange(e);
    setValue("slug", slug(watch("name"), {remove: /[*+~.()'"!:@]/g}));
  };

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${bundle?.name} ?`,
      content: "This action cannot be undone",
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      okButtonProps: {
        shape: "round",
      },
      cancelButtonProps: {
        shape: "round",
      },
      onOk() {
        // if (serviceData.id) {
        // dispatch(deleteServiceById(serviceData.id))
        return new Promise((resolve, reject) => {
          if (bundle?.id) {
            resolve(
              deleteBundleQueryMutation.mutate(String(bundle.id), {
                onSuccess: () => {
                  message.success("Your bundle has been deleted successfully!");
                  navigate("/dashboard/calls");
                },
              })
            );
          }
        }).catch(() => message.error("Failed to delete"));
        // };
      },
    });
  };

  function handleDeleteCurrency(currency: string, currencyIndex: number) {
    if (bundle) {
      const currencyExist = bundle.currencies.find((x) => x.currency === currency);
      if (currencyExist) {
        const payload: TCurrencyDeletePayload = {
          currencyId: String(currencyExist.id),
          eventId: String(bundle.id),
          module: "service",
        };
        deleteCurrencyMutation.mutate(payload, {
          onSuccess: (data) => {
            const newCurrencyList = currencyArrayValue.filter(
              (x) => x.callPriceCurrency !== data.data.currency
            );
            setValue("currencies", newCurrencyList);
            message.success("Currency deleted successfully");
          },
          onError: () => {
            message.error("Error deleting currency");
          },
        });
      } else {
        handleRemoveCurrency(
          state.formCurrencies,
          currencyArrayValue,
          defaultCallCurrency,
          setCurrencyOptions,
          removeCurrency,
          currencyIndex
        );
      }
    } else {
      handleRemoveCurrency(
        state.formCurrencies,
        currencyArrayValue,
        defaultCallCurrency,
        setCurrencyOptions,
        removeCurrency,
        currencyIndex
      );
    }
  }

  const onSubmit = async (values: TBundleApiPayloadFormValues) => {
    const {
      name,
      description,
      slug,
      callPriceCurrency,
      callPrice,
      hideServiceFromMenu,
      callLocation,
      bookingQuestions,
      bookingInterval,
      numberOfSessions,
      callDuration,
      customCallDuration,
      isPaused,
      currencies,
      callPriceType,
      cover_image,
      request_phone_number,
    } = values;
    const basePayload: TBundleApiPayloadReq = {
      name,
      description,
      slug,
      callPriceCurrency,
      callPrice: callPriceType === "free" ? 0 : callPrice,
      hideServiceFromMenu,
      url_redirect: values.isUrlRedirect ? values.url_redirect : undefined,
      currencies: callPriceType === "free" ? [] : currencies,
      callDuration:
        callDuration === "custom" ? Number(customCallDuration) : Number(callDuration),
      videoLocation: callLocation === "video" ? true : false,
      audioLocation: callLocation === "phone" ? true : false,
      ...(bookingQuestions && bookingQuestions.length > 0 && {bookingQuestions}),
      bookingInterval,
      numberOfSessions: Number(numberOfSessions),
      isBundle: true,
      cover_image,
      request_phone_number,
    };

    const updatePayload: TUpdateBundleApiPayloadReq = {
      ...basePayload,
      id: bundle ? String(bundle.id) : (serviceId as string),
      isPaused,
    };
    if (type === "new") {
      // console.log("bundle new values", values);
      createBundleQueryMutation.mutate(basePayload, {
        onSuccess: (data) => handleShowSuccessModal(data),
      });
    }
    if (type === "edit") {
      // console.log("bundle edit values", values);

      updateBundleQueryMutation.mutate(updatePayload, {
        onSuccess: () => {
          message.success("Your bundle has been updated successfully!");
          navigate("/dashboard/calls");
        },
      });
    }
  };
  return (
    <div style={{maxWidth: "71.6rem"}}>
      <StyledFormWrapper style={{marginTop: 0}}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <StyledCreateServiceHeadingBox>
            <h1 className="main-heading">Bundle information</h1>
          </StyledCreateServiceHeadingBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="vertical" size={24} style={{display: "flex"}}>
              <div>
                <StyledInputLabelWrapper>
                  <label id="name-id" htmlFor="name">
                    Bundle name
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="name"
                  render={({field: {onChange, value, ref}}) => (
                    <Input
                      id="serviceName"
                      status={errors.name?.message ? "error" : ""}
                      onChange={(e) => {
                        handleServiceLinkChange(e, onChange);
                      }}
                      size="large"
                      placeholder="e.g One month mentorship"
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                {errors.name?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.name?.message}
                  </div>
                )}
              </div>
              <div>
                <StyledInputLabelWrapper>
                  <label id="product_description" htmlFor="product_description">
                    Description
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  name="description"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <StyledEditorWrapper>
                      <ReactQuill
                        theme="snow"
                        onChange={(description) => {
                          onChange(description);
                        }}
                        value={value}
                        placeholder="What is your service about?"
                        modules={{
                          toolbar: {
                            container: [
                              [{header: [1, 2, 3, 4, 5, 6, false]}],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "color",
                                "link",
                                "size",
                              ],
                              [{color: []}, {background: []}],
                              [{script: "sub"}, {script: "super"}],
                              ["blockquote", "code-block"],
                              [{list: "ordered"}, {list: "bullet"}],
                              [{indent: "-1"}, {indent: "+1"}, {align: []}],
                              ["link"],
                              ["clean"],
                            ],
                          },
                        }}
                      />
                    </StyledEditorWrapper>
                  )}
                />
                {errors.description?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.description.message}
                  </div>
                )}
              </div>
              {/* <CustomInputComponent
                id="description"
                name="description"
                type="textarea"
                label="Description"
                placeholder="What is your bundle about?"
                error={errors.description?.message}
                control={control}
              /> */}
              <div>
                <StyledInputLabelWrapper>
                  <label id="slug-id" htmlFor="slug">
                    Bundle Link
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="slug"
                  render={({field: {onChange, value, ref}}) => (
                    <Input
                      id="serviceLink"
                      status={errors.slug?.message ? "error" : ""}
                      onChange={onChange}
                      size="large"
                      placeholder="30-min"
                      value={value}
                      ref={ref}
                    />
                  )}
                />
                {errors.slug?.message && (
                  <div role="alert" className="ant-form-item-explain-error">
                    {errors.slug?.message}
                  </div>
                )}
                {/* {watch("slug") && (
                      <p>{`${window.location.origin}/${profileData2.username}/${slug(
                        watch("slug"),
                        {remove: /[*+~.()'"!:@]/g}
                      )}`}</p>
                    )} */}
              </div>

              <Row gutter={[10, 24]}>
                <Col span={24} sm={12}>
                  <CustomSelectInput
                    control={control}
                    id="numberOfSessions"
                    label="Number of sessions"
                    name="numberOfSessions"
                    placeholder="Please select"
                    options={bundleSessionOptions}
                    error={errors.numberOfSessions?.message}
                    style={{width: "100%"}}
                  />
                </Col>
              </Row>
              <Row gutter={[10, 24]}>
                <Col span={24} sm={12}>
                  <CustomSelectInput
                    control={control}
                    id="callDuration"
                    label="Booking interval"
                    name="bookingInterval"
                    placeholder="Please select"
                    options={bundleIntervalOptions}
                    error={errors.bookingInterval?.message}
                    style={{width: "100%"}}
                  />
                </Col>
              </Row>
              <ServiceCoverImageUpload
                clearErrors={clearErrors}
                control={control}
                cover_image_url={bundle?.cover_picture}
                errors={errors}
                setError={setError}
                setValue={setValue}
                watch={watch}
              />
              <Row gutter={[10, 24]}>
                <Col span={24} sm={12}>
                  <CustomSelectInput
                    control={control}
                    id="callDuration"
                    label="Call Duration"
                    name="callDuration"
                    placeholder="Please select"
                    options={CallDurationOptions}
                    error={errors.callDuration?.message}
                    style={{width: "100%"}}
                  />
                </Col>
                {watch("callDuration") === "custom" && (
                  <Col span={24} sm={12}>
                    <CustomInputComponent
                      id="customCallDuration"
                      name="customCallDuration"
                      type="number"
                      label="Call duration (In minutes)"
                      placeholder="Call duration (In minutes)"
                      error={errors.customCallDuration?.message}
                      control={control}
                    />
                  </Col>
                )}
              </Row>
              <div>
                <StyledInputLabelWrapper>
                  <label id="slug-id" htmlFor="slug">
                    Cost
                  </label>
                </StyledInputLabelWrapper>
                <Controller
                  control={control}
                  name="callPriceType"
                  render={({field: {onChange, value}}) => (
                    <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                      <Tooltip
                        title={
                          !state.connectedBankStatus
                            ? "Please add your bank account details in Payouts to enable paid offerings"
                            : null
                        }
                      >
                        <Radio value={"paid"} disabled={!state.connectedBankStatus}>
                          Paid
                        </Radio>
                      </Tooltip>

                      <Radio value={"free"}>Free</Radio>
                    </Radio.Group>
                  )}
                />
              </div>
              {watch("callPriceType") === "paid" && state.connectedBankStatus && (
                <>
                  <Row gutter={[10, 24]}>
                    <Col span={24} sm={12}>
                      <CustomInputComponent
                        id="callPrice"
                        name="callPrice"
                        type="text"
                        label="Call Price"
                        placeholder="Enter call price"
                        error={
                          errors.callPriceCurrency
                            ? errors.callPriceCurrency.message
                            : errors.callPrice?.message
                        }
                        control={control}
                        addonBefore={
                          <CustomSelectInput
                            control={control}
                            id="serviceCurrency"
                            name="callPriceCurrency"
                            placeholder="Please select"
                            options={currencyOptions}
                          />
                        }
                      />
                    </Col>
                    {currencyFields.slice(0, 4).map((item, index) => (
                      <Col key={item.id} span={24} sm={12}>
                        <div style={{display: "flex", gap: "1.6rem"}}>
                          <div style={{flex: "1"}}>
                            <CustomInputComponent
                              id="callPrice"
                              name={`currencies.${index}.callPrice`}
                              type="text"
                              label="Call Price"
                              placeholder="Enter call price"
                              error={
                                errors.currencies?.[index]?.callPriceCurrency
                                  ? errors.currencies?.[index]?.callPriceCurrency?.message
                                  : errors.currencies?.[index]?.callPrice?.message
                              }
                              control={control}
                              addonBefore={
                                <Controller
                                  control={control}
                                  name={`currencies.${index}.callPriceCurrency`}
                                  render={({field: {onChange, value, ref}}) => (
                                    <>
                                      <Select
                                        onChange={(val) =>
                                          handleCurrencySelectOnChange(
                                            state.formCurrencies,
                                            currencyArrayValue,
                                            defaultCallCurrency,
                                            setCurrencyOptions,
                                            onChange,
                                            val
                                          )
                                        }
                                        value={value}
                                        ref={ref}
                                        size="large"
                                        // loading={getCurrenciesQuery.isFetching}
                                        status={
                                          errors.currencies?.[index]?.callPriceCurrency
                                            ?.message
                                            ? "error"
                                            : ""
                                        }
                                        showSearch={false}
                                        notFoundContent={null}
                                        placeholder={""}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option!.children as unknown as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                      >
                                        {currencyOptions.map(
                                          (option, optionIdx: number | string) => (
                                            <Option key={optionIdx} value={option}>
                                              {option}
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                      {errors.currencies?.[index]?.callPriceCurrency
                                        ?.message && (
                                        <div
                                          role="alert"
                                          className="ant-form-item-explain-error"
                                        >
                                          {
                                            errors.currencies?.[index]?.callPriceCurrency
                                              ?.message
                                          }
                                        </div>
                                      )}
                                    </>
                                  )}
                                />
                              }
                            />
                          </div>
                          <div style={{marginTop: "auto"}}>
                            <Button
                              onClick={() =>
                                handleDeleteCurrency(item.callPriceCurrency, index)
                              }
                              type="link"
                              shape="circle"
                              icon={<DeleteOutlined />}
                              size="large"
                              loading={deleteCurrencyMutation.isLoading}
                            />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  {watch("callPriceType") === "paid" && currencyFields.length < 4 && (
                    <div>
                      <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                          handleAddNewCurrency(
                            state.formCurrencies,
                            currencyArrayValue,
                            defaultCallCurrency,
                            setCurrencyOptions,
                            appendCurrency
                          )
                        }
                        size="large"
                        shape="round"
                      >
                        Add currency
                      </Button>
                    </div>
                  )}
                </>
              )}

              <CustomCheckboxInput
                control={control}
                id="hideServiceFromMenu"
                name="hideServiceFromMenu"
                error={errors.hideServiceFromMenu?.message}
                label="Hide bundle from your profile page "
              />
              <CustomCheckboxInput
                control={control}
                id="request_phone_number"
                name="request_phone_number"
                error={errors.request_phone_number?.message}
                label="Request customer's phone number"
              />
              <div>
                <Space size={16} direction="vertical" style={{display: "flex"}}>
                  <div>
                    <Controller
                      control={control}
                      name="isUrlRedirect"
                      render={({field: {onChange, value}}) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            clearErrors("url_redirect");
                            onChange(e.target.checked);
                          }}
                        >
                          Redirect customers to URL after purchase
                        </Checkbox>
                      )}
                    />
                  </div>
                  <p>
                    *This could be a link to a whatsapp group chat, telegram group etc.
                  </p>
                  {watch("isUrlRedirect") && (
                    <div>
                      <Controller
                        control={control}
                        name="url_redirect"
                        render={({field: {onChange, value, ref}}) => (
                          <Input
                            id="serviceName"
                            status={errors.url_redirect?.message ? "error" : ""}
                            onChange={onChange}
                            size="large"
                            placeholder="Enter Url"
                            value={value}
                            ref={ref}
                          />
                        )}
                      />
                      {errors.url_redirect?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.url_redirect.message}
                        </div>
                      )}
                    </div>
                  )}
                </Space>
              </div>
              <StyledCreateServiceHeadingBox>
                <h1 className="main-heading">Location</h1>
                <p className="sub-heading">Choose how attendees will join your meeting</p>
              </StyledCreateServiceHeadingBox>
              <Controller
                control={control}
                name="callLocation"
                render={({field: {onChange, value}}) => (
                  <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                    <StyledCallLocationItem active={watch("callLocation") === "video"}>
                      <div className="icon-container">
                        <img src="/images/video.svg" alt="video icon" />
                      </div>
                      <div className="loaction-info">
                        <h3 className="title">Coachli Video</h3>
                        <p className="sub-info">
                          You and your customer will be sent a link to join the video
                          call. No need for Zoom or Google Meet
                        </p>
                      </div>
                      <div style={{marginLeft: "auto"}}>
                        <Radio value={"video"} />
                      </div>
                    </StyledCallLocationItem>
                    <StyledCallLocationItem
                      style={{marginTop: "2.4rem"}}
                      active={watch("callLocation") === "phone"}
                    >
                      <div className="icon-container">
                        <img src="/images/phone.svg" alt="phone icon" />
                      </div>
                      <div className="loaction-info">
                        <h3 className="title">Phone Call</h3>
                        <p className="sub-info">
                          Your customer will be required to provide their phone number at
                          checkout
                        </p>
                      </div>
                      <div style={{marginLeft: "auto"}}>
                        <Radio value={"phone"} />
                      </div>
                    </StyledCallLocationItem>
                  </Radio.Group>
                )}
              />
              {type === "edit" && (
                <StyledCreateServiceHeadingBox>
                  <h1 className="main-heading">Settings</h1>
                  <Controller
                    control={control}
                    name="isPaused"
                    render={({field: {value, onChange}}) => (
                      <StyledQuestionRequiredContainer>
                        <span className="required-text">
                          Need a break? Pause this service
                        </span>
                        <Switch onChange={onChange} checked={value} />{" "}
                      </StyledQuestionRequiredContainer>
                    )}
                  />
                </StyledCreateServiceHeadingBox>
              )}
              <StyledCreateServiceHeadingBox>
                <h1 className="main-heading">Custom booking questions</h1>
                <p className="sub-heading">
                  Here you can add questions that you would like to ask the person
                  registering for this bundle
                </p>
              </StyledCreateServiceHeadingBox>

              {fields.slice(0, 9).map((item, index) => (
                <div key={item.id}>
                  <StyledQuestionInputContainer>
                    <div className="input-wrapper">
                      <CustomInputComponent
                        id={`bookingQuestions[${index}].value`}
                        label={`Question ${index + 1}`}
                        name={`bookingQuestions[${index}].question`}
                        placeholder="E.g. What is your email address?"
                        error={errors.bookingQuestions?.[index]?.question?.message}
                        control={control}
                      />
                    </div>

                    <Button
                      style={{marginTop: "3rem"}}
                      onClick={() => remove(index)}
                      type="link"
                      shape="circle"
                      icon={<CloseOutlined />}
                      size="large"
                    />
                  </StyledQuestionInputContainer>
                  <Controller
                    control={control}
                    name={`bookingQuestions.${index}.required`}
                    render={({field: {value, onChange}}) => (
                      <StyledQuestionRequiredContainer>
                        <Switch onChange={onChange} checked={value} />{" "}
                        <span className="required-text">Set question as required</span>
                      </StyledQuestionRequiredContainer>
                    )}
                  />
                </div>
              ))}

              {fields.length < 9 && (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  onClick={() => append({question: "", required: false})}
                  style={{paddingLeft: 0}}
                >
                  Add custom booking question
                </Button>
              )}

              <Row gutter={[0, 16]}>
                <Col span={24} sm={12}>
                  <StyledServiceButtonWrapper>
                    <Button
                      block
                      size="large"
                      shape="round"
                      type="primary"
                      htmlType="submit"
                      loading={
                        type === "new"
                          ? createBundleQueryMutation.isLoading
                          : updateBundleQueryMutation.isLoading
                      }
                      disabled={
                        Object.keys(errors).length > 0 ||
                        Object.values(watch()).length < 1 ||
                        deleteCurrencyMutation.isLoading
                      }
                    >
                      {type === "new" ? "Create bundle" : "Update bundle"}
                    </Button>
                    <Button
                      onClick={() => reset()}
                      block
                      size="large"
                      shape="round"
                      type="default"
                    >
                      Discard changes
                    </Button>
                  </StyledServiceButtonWrapper>
                </Col>
                {type === "edit" && (
                  <Col span={24} sm={{span: 12}}>
                    <StyledDoThisLaterBtn
                      block
                      danger
                      icon={<DeleteOutlined />}
                      size="large"
                      type="text"
                      htmlType="button"
                      onClick={handleDeleteModal}
                      disabled={deleteCurrencyMutation.isLoading}
                    >
                      Delete bundle
                    </StyledDoThisLaterBtn>
                  </Col>
                )}
              </Row>
            </Space>
          </form>
        </Space>
      </StyledFormWrapper>
      <CustomModal
        visibleState={openSuccessfulModal}
        title="Bundle created!"
        okFunc={handleOk}
        cancelFunc={handleOk}
        footerContent={[
          <Button
            key="publicProfile"
            type="primary"
            shape="round"
            // onClick={() => navigate(`/${getProfileQuery.data?.username}`)}
            href={`/${getProfileQuery.data?.username}`}
            target="_blank"
          >
            View my public profile
          </Button>,
          <Button key="gotIt" shape="round" type="default" onClick={handleOk}>
            Got it!
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          <StyledSuccessServiceContent>
            <p className="success-text">
              Your bundle has been successfully created! Share your service link to start
              getting bookings
            </p>
            <div className="success-share-info">
              <Input.Group compact style={{display: "flex"}}>
                <Input
                  size="large"
                  readOnly
                  defaultValue={`${window.location.origin}/${getProfileQuery.data?.username}/meetings/${createBundleQueryMutation?.data?.data?.slug}/${createBundleQueryMutation?.data?.data?.id}`}
                />
                <Tooltip title={toolTipText}>
                  <Button
                    size="large"
                    icon={<CopyOutlined />}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(
                          `${window.location.origin}/${getProfileQuery.data?.username}/meetings/${createBundleQueryMutation?.data?.data?.slug}/${createBundleQueryMutation?.data?.data?.id}`
                        )
                        .then(() => setToolTipText("copied"))
                    }
                  />
                </Tooltip>
              </Input.Group>
              <p>or</p>
              <Space direction="horizontal" size={16}>
                <div>
                  <Button
                    shape="circle"
                    size="large"
                    icon={<FacebookFilled />}
                    style={{backgroundColor: "#EBEEF5", color: "#3B5998", border: "none"}}
                    type="link"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${getProfileQuery.data?.username}/meetings/${createBundleQueryMutation?.data?.data?.slug}/${createBundleQueryMutation?.data?.data?.id}&amp`}
                    title="Share on facebook"
                  />
                </div>
                <div>
                  <Button
                    title="Share on twitter"
                    shape="circle"
                    size="large"
                    icon={<TwitterOutlined />}
                    target="_blank"
                    style={{backgroundColor: "#E6F7FD", color: "#00ACEE", border: "none"}}
                    href={`https://twitter.com/intent/tweet?text=I just created a service called "${createBundleQueryMutation.data?.data?.name}" on Coachli. Book a service with me today on Coachli!&url=${window.location.origin}/${getProfileQuery.data?.username}/meetings/${createBundleQueryMutation?.data?.data?.slug}/${createBundleQueryMutation?.data?.data?.id}}&hashtags=coachli,useCoachli`}
                  />
                </div>
                <div>
                  <Button
                    title="Send mail"
                    shape="circle"
                    size="large"
                    icon={<MailFilled />}
                    style={{backgroundColor: "#FBECEB", color: "#DB4437", border: "none"}}
                    href={`mailto:?subject=I wanted you to see this site&amp;body=Book a service with me on Coachli ${window.location.origin}/${getProfileQuery.data?.username}/meetings/${createBundleQueryMutation?.data?.data?.slug}/${createBundleQueryMutation?.data?.data?.id}`}
                  />
                </div>
              </Space>
            </div>
          </StyledSuccessServiceContent>
        </StyledModalContentWrapper>
      </CustomModal>
    </div>
  );
};

export {BundleForm};
