import {Alert, Breadcrumb, Spin} from "antd";

import {Link, useParams} from "react-router-dom";
import {EditServiceForm} from "../../../../components/calls/service/edit-service-form";
import {ShareServiceWidget} from "../../../../components/calls/service/share-service-widget/share-service-widget";
import {FullPageLoader} from "../../../../components/full-page-loader";
import {DashboardWrapper} from "../../../../layouts/dashboard-wrapper";
import {useGetService} from "../../../../react-query-hooks/service";
import {StyledPageWrapper} from "../../../../styles/dashboard/calls/calls";

const EditCallPage = () => {
  const {id} = useParams();
  const serviceQueryInfo = useGetService(id as string);
  const pageTitle = "Edit a service";

  if (serviceQueryInfo.isLoading) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <FullPageLoader />
      </DashboardWrapper>
    );
  }

  if (serviceQueryInfo.isError) {
    return (
      <DashboardWrapper pageTitle={pageTitle}>
        <Alert
          message="Error"
          description={serviceQueryInfo.error.message}
          type="error"
          showIcon
        />
      </DashboardWrapper>
    );
  }
  return (
    <DashboardWrapper pageTitle={pageTitle}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/calls">1:1 Calls</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span
            style={{
              opacity: serviceQueryInfo.isFetching ? 0.2 : 1,
            }}
          >
            {" "}
            {serviceQueryInfo.isLoading ? <Spin /> : serviceQueryInfo.data.name}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <StyledPageWrapper>
        <div className="main-area">
          <Spin spinning={serviceQueryInfo.isFetching}>
            <EditServiceForm
              serviceId={id as string}
              serviceData={serviceQueryInfo.data}
            />
          </Spin>
        </div>
        <div className="widget-area">
          <ShareServiceWidget
            serviceId={id as string}
            serviceData={serviceQueryInfo.data}
          />
        </div>
      </StyledPageWrapper>
    </DashboardWrapper>
  );
};

export {EditCallPage};
