import {useForm, useFieldArray, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FacebookFilled,
  FileOutlined,
  MailFilled,
  PlusCircleFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import {Button, Input, Modal, Space, Tooltip, message} from "antd";
import {
  StyledCreateServiceHeadingBox,
  StyledFormWrapper,
} from "../../../../styles/onboarding/onboarding";
import {useCreateProductContext} from "../../../../context/products/add-product-context";
import {
  StyledContentListItem,
  StyledContentListUploadWrapper,
} from "./content-upload.style";
import {
  TGetProductApiResponse,
  TProductMedia,
  TRenameContentPayload,
  TUploadContentPayload,
  useDeleteContent,
  useRenameContent,
  useUploadContent,
} from "../../../../react-query-hooks/products";
import {useEffect, useRef, useState} from "react";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {StyledInputLabelWrapper} from "../../../input/input.style";
import * as yup from "yup";
import {useGetProfile} from "../../../../react-query-hooks/profile";
import {CustomModal} from "../../../modal";
import {useNavigate} from "react-router-dom";
import {StyledSuccessServiceContent} from "../../../calls/service/service-form/service-form.style";

type TContentUploadFormValues = {
  contents: TProductMedia[];
};
type TProductContentUploadProps = {
  productData?: TGetProductApiResponse;
};
type TShowRenameModalArg = {
  fileExtension: string;
  fileName: string;
  contentId: number;
  productId: number;
};

export function ProductContentUpload({productData}: TProductContentUploadProps) {
  const {dispatch} = useCreateProductContext();
  const navigate = useNavigate();
  const getProfileQuery = useGetProfile();
  const deleteProductContentMutation = useDeleteContent();
  const uploadProductContentMutation = useUploadContent();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [openSuccessfulModal, setOpenSuccessfulModal] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [toolTipText, setToolTipText] = useState<string>("copy service url");
  const [renameModalDetails, setRenameModalDetails] = useState<TShowRenameModalArg>();

  const {control, setValue} = useForm<TContentUploadFormValues>({
    // resolver: yupResolver(productFormSchema),
    mode: "onChange",
    defaultValues: {
      contents: productData?.medias,
    },
  });

  const {fields: contentFields} = useFieldArray({
    control,
    name: "contents",
    keyName: "_id",
  });

  useEffect(() => {
    if (productData) {
      setValue("contents", productData?.medias);
    }
  }, [productData, setValue]);

  function handleContentUploadButtonClick() {
    fileInputRef.current?.click();
  }

  function handleContentInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    // const imageFileFormats = [
    //   ".jpg",
    //   ".jpeg",
    //   ".apng",
    //   ".avif",
    //   ".jfif",
    //   ".pjpeg",
    //   ".pjp",
    //   ".svg",
    //   ".webp",
    //   ".ico",
    //   ".cur",
    //   ".avif",
    //   ".bmp",
    //   ".tif",
    //   ".tiff",
    //   ".gif",
    //   ".png",
    //   ".eps",
    //   ".raw",
    //   ".cr2",
    //   ".nef",
    //   ".orf",
    //   ".sr2",
    // ];
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const fileType = file.type.split("/")[0];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");

      const fileName = name.substring(0, lastDot);
      const fileExtension = name.substring(lastDot + 1);

      const isVideoFormat = fileType.toLowerCase() === "video";
      // const isImageFormat = fileType.toLowerCase() === "image";
      const isDocxFormat = fileExtension.toLowerCase() === "docx";
      const isPdfFormat = fileExtension.toLowerCase() === "pdf";
      const isCsvFormat = fileExtension.toLowerCase() === "csv";
      const isXlsxFormat = fileExtension.toLowerCase() === "xlsx";
      const isXlsFormat = fileExtension.toLowerCase() === "xls";
      const isAudioFormat = fileType.toLowerCase() === "audio";

      // console.log({fileName, fileExtension, file, fileType, name});

      const isAllowedFormat =
        isVideoFormat ||
        isDocxFormat ||
        isPdfFormat ||
        isAudioFormat ||
        isCsvFormat ||
        isXlsxFormat ||
        isXlsFormat;

      if (file.size / 1024 / 1024 > 100) {
        return message.error("Max file size is 100MB");
      } else if (!isAllowedFormat) {
        return message.error(
          "File not allowed! Only video, audio and Ebook formats are allowed"
        );
      } else {
        const reader = new FileReader();
        reader.onload = function (event) {
          if (event.target && event.target.result) {
            const imageData = event.target.result;
            const blob = new Blob([imageData], {type: file.type});
            const productId = productData?.product.id as number;

            const payload: TUploadContentPayload = {
              data: blob,
              fileExtension,
              fileName,
              productId,
              fileType: file.type,
            };
            uploadProductContentMutation.mutate(payload, {
              onSuccess: () => {
                message.success(
                  "Your content is currently being uploaded! Please, stay on this page!"
                );
              },
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  }

  function handleContentDelete(contentId: string | number) {
    if (productData) {
      const payload = {
        productId: productData.product.id as number,
        contentId: contentId,
      };
      deleteProductContentMutation.mutate(payload, {
        onSuccess: () =>
          message.success("The selected content has been deleted successfully!"),
      });
    }
  }

  function handleShowRenameContentModal(args: TShowRenameModalArg) {
    setRenameModalDetails(args);
    setIsRenameModalOpen(true);
  }
  function handleRenameContentModalOk() {
    setIsRenameModalOpen(false);
  }
  function handleRenameContentModalCancel() {
    setRenameModalDetails(undefined);
    setIsRenameModalOpen(false);
  }
  const handleShowSuccessModal = () => {
    setOpenSuccessfulModal(true);
  };

  const handleSuccessModalOk = () => {
    setOpenSuccessfulModal(false);
    navigate("/dashboard/products");
  };

  return (
    <div style={{marginTop: "1.8rem"}}>
      <StyledFormWrapper style={{marginTop: 0}}>
        <form>
          <Space direction="vertical" size={24} style={{display: "flex"}}>
            <StyledContentListUploadWrapper>
              <Space size={16} direction="vertical">
                <div>
                  <p className="content-list-title">
                    You can add multiple files. PDF, Videos, Audio, Docx up to 100MB of
                    size.
                  </p>
                  <Space direction="vertical" size={12} style={{display: "flex"}}>
                    {contentFields.slice(0, 4).map((item, index) => (
                      <StyledContentListItem key={item._id}>
                        <div className="content-list-item-info">
                          <div className="content-list-item-name-wrapper">
                            <FileOutlined className="content-list-item-file-icon" />{" "}
                            <p className="content-list-item-name">
                              {`${item.file_name} ${item.file_type}. ${item.file_size}`}
                            </p>
                          </div>
                          <div>
                            <Space size={16}>
                              <div
                                onClick={() =>
                                  handleShowRenameContentModal({
                                    fileName: item.file_name,
                                    fileExtension: item.file_type,
                                    contentId: item.id,
                                    productId: item.productId,
                                  })
                                }
                              >
                                <EditOutlined
                                  style={{
                                    fontSize: "2.4rem",
                                    cursor: "pointer",
                                    color: "#9747FF",
                                  }}
                                  title="Edit content"
                                />
                              </div>
                              <div onClick={() => handleContentDelete(item.id)}>
                                <DeleteOutlined
                                  style={{
                                    fontSize: "2.4rem",
                                    cursor: "pointer",
                                    color: "#9747FF",
                                  }}
                                  title="Delete content"
                                />
                              </div>
                            </Space>
                          </div>
                        </div>
                        {/* <div>
                          <Button
                            onClick={() =>
                              handleShowRenameContentModal({
                                fileName: item.file_name,
                                fileExtension: item.file_type,
                                contentId: item.id,
                                productId: item.productId,
                              })
                            }
                            type="link"
                          >
                            Rename
                          </Button>
                        </div> */}
                      </StyledContentListItem>
                    ))}
                  </Space>
                </div>
                {contentFields.length < 4 && (
                  <>
                    <input
                      ref={fileInputRef}
                      type="file"
                      onChange={handleContentInputChange}
                      style={{display: "none"}}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={handleContentUploadButtonClick}
                        size="large"
                        shape="round"
                        icon={<PlusCircleFilled />}
                        disabled={deleteProductContentMutation.isLoading}
                        loading={uploadProductContentMutation.isLoading}
                      >
                        Add content
                      </Button>
                    </div>
                  </>
                )}
              </Space>
            </StyledContentListUploadWrapper>
            {/* <p style={{fontSize: "1.2rem"}}>
              *As you add your content, it gets saved automatically. Once sales start,
              you'll gain access to view your <b>sales and insights</b>. Share your
              product link with your audience to inform them about your available product.
              Click on <b>product</b> at the menu bar to see the products you've created.
            </p> */}
            <div>
              <Button
                disabled={productData ? Boolean(productData.medias.length < 1) : false}
                shape="round"
                type="primary"
                size="large"
                onClick={() => handleShowSuccessModal()}
              >
                Update digital product
              </Button>
            </div>
          </Space>
        </form>
      </StyledFormWrapper>
      {renameModalDetails && (
        <Modal
          title="Rename file"
          footer={null}
          visible={isRenameModalOpen}
          onOk={handleRenameContentModalOk}
          onCancel={handleRenameContentModalCancel}
          maskClosable={false}
        >
          <StyledModalContentWrapper>
            <RenameContentModalContent
              renameModalDetails={renameModalDetails}
              handleRenameContentModalOk={handleRenameContentModalOk}
              handleRenameContentModalCancel={handleRenameContentModalCancel}
            />
          </StyledModalContentWrapper>
        </Modal>
      )}
      <CustomModal
        visibleState={openSuccessfulModal}
        title="Digital product created!"
        okFunc={handleSuccessModalOk}
        cancelFunc={handleSuccessModalOk}
        footerContent={[
          <Button
            key="publicProfile"
            type="primary"
            shape="round"
            // onClick={() => navigate(`/${getProfileQuery.data?.username}`)}
            href={`/${getProfileQuery.data?.username}`}
            target="_blank"
          >
            View my public profile
          </Button>,
          <Button key="gotIt" shape="round" type="default" onClick={handleSuccessModalOk}>
            Got it!
          </Button>,
        ]}
      >
        <StyledModalContentWrapper>
          <StyledSuccessServiceContent>
            <p className="success-text">
              Your digital product has been successfully created! Share your product link
              to start making sales.
            </p>
            <div className="success-share-info">
              <Input.Group compact style={{display: "flex"}}>
                <Input
                  size="large"
                  readOnly
                  defaultValue={`${window.location.origin}/${getProfileQuery.data?.username}/products/details?slug=${productData?.product.slug}&productId=${productData?.product.id}`}
                />
                <Tooltip title={toolTipText}>
                  <Button
                    size="large"
                    icon={<CopyOutlined />}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(
                          `${window.location.origin}/${getProfileQuery.data?.username}/products/details?slug=${productData?.product.slug}&productId=${productData?.product.id}`
                        )
                        .then(() => setToolTipText("copied"))
                    }
                  />
                </Tooltip>
              </Input.Group>
              <p>or</p>
              <Space direction="horizontal" size={16}>
                <div>
                  <Button
                    shape="circle"
                    size="large"
                    icon={<FacebookFilled />}
                    style={{backgroundColor: "#EBEEF5", color: "#3B5998", border: "none"}}
                    type="link"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcoachli.co%2F${window.location.origin}/${getProfileQuery.data?.username}/products/details?slug=${productData?.product.slug}&productId=${productData?.product.id}&amp`}
                    title="Share on facebook"
                  />
                </div>
                <div>
                  <Button
                    title="Share on twitter"
                    shape="circle"
                    size="large"
                    icon={<TwitterOutlined />}
                    target="_blank"
                    style={{backgroundColor: "#E6F7FD", color: "#00ACEE", border: "none"}}
                    href={`https://twitter.com/intent/tweet?text=I just created a product called "${productData?.product.title}" on Coachli. Purchase my product on Coachli!&url=${window.location.origin}/${getProfileQuery.data?.username}/products/details?slug=${productData?.product.slug}&productId=${productData?.product.id}&hashtags=coachli,useCoachli`}
                  />
                </div>
                <div>
                  <Button
                    title="Send mail"
                    shape="circle"
                    size="large"
                    icon={<MailFilled />}
                    style={{backgroundColor: "#FBECEB", color: "#DB4437", border: "none"}}
                    href={`mailto:?subject=I wanted you to see this site&amp;body=I created a digital product ${window.location.origin}/${getProfileQuery.data?.username}/products/details?slug=${productData?.product.slug}&productId=${productData?.product.id}`}
                  />
                </div>
              </Space>
            </div>
          </StyledSuccessServiceContent>
        </StyledModalContentWrapper>
      </CustomModal>
    </div>
  );
}

function RenameContentModalContent({
  renameModalDetails,
  handleRenameContentModalCancel,
  handleRenameContentModalOk,
}: {
  renameModalDetails: TShowRenameModalArg;
  handleRenameContentModalOk: () => void;
  handleRenameContentModalCancel: () => void;
}) {
  const renameProductContentMutation = useRenameContent();
  const renameSchema = yup.object().shape({
    fileName: yup.string().trim().required("File name is required"),
  });

  const {handleSubmit, formState, control, watch} = useForm<{fileName: string}>({
    resolver: yupResolver(renameSchema),
    mode: "onChange",
    defaultValues: {
      fileName: renameModalDetails.fileName,
    },
  });

  const onSubmit = async (values: {fileName: string}) => {
    const payload: TRenameContentPayload = {
      contentId: renameModalDetails.contentId,
      fileExtension: renameModalDetails.fileExtension,
      fileName: values.fileName,
      productId: renameModalDetails.productId,
    };

    renameProductContentMutation.mutate(payload, {
      onSuccess: () => {
        message.success("Your media content has been renamed successfully!");
        handleRenameContentModalOk();
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space size={12} direction="vertical" style={{display: "flex"}}>
        <div>
          <StyledInputLabelWrapper>
            <label id="rename_label" htmlFor="fileName">
              What would you like to name your file?
            </label>
          </StyledInputLabelWrapper>

          <Controller
            control={control}
            name="fileName"
            render={({field: {onChange, value, ref}}) => (
              <Input onChange={onChange} value={value} ref={ref} />
            )}
          />
          {formState.errors.fileName?.message && (
            <div role="alert" className="ant-form-item-explain-error">
              {formState.errors.fileName?.message}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "8px",
          }}
        >
          <div>
            <Button
              disabled={
                Object.keys(formState.errors).length > 0 ||
                Object.values(watch()).length < 1
              }
              htmlType="submit"
              type="primary"
              shape="round"
              size="large"
              loading={renameProductContentMutation.isLoading}
            >
              Done
            </Button>
          </div>
          <div>
            <Button
              onClick={handleRenameContentModalCancel}
              shape="round"
              size="large"
              type="ghost"
              disabled={renameProductContentMutation.isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Space>
    </form>
  );
}
