import {Modal} from "antd";
import {StyledCalendarModalWrapper} from "../../../../../../../styles/booking/booking";

interface ICalendarModalProps {
  title: string;
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
  footerContent?: React.ReactNode[];
  children: React.ReactNode;
}

const CalendarModal: React.FC<ICalendarModalProps> = ({
  title,
  visibleState,
  okFunc,
  cancelFunc,
  footerContent,
  children,
}) => {
  return (
    // <StyledCalendarModalWrapper>
    <Modal
      visible={visibleState}
      zIndex={3000}
      title={title}
      onOk={okFunc}
      onCancel={cancelFunc}
      footer={footerContent}
      width="70rem"
      destroyOnClose
    >
      {children}
    </Modal>
    // </StyledCalendarModalWrapper>
  );
};

export {CalendarModal};
