import {Modal} from "antd";

interface ICallDetailsModalProps {
  title: string;
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
  footerContent?: React.ReactNode[];
  children: React.ReactNode;
}

const CallDetailsModal: React.FC<ICallDetailsModalProps> = ({
  title,
  visibleState,
  okFunc,
  cancelFunc,
  footerContent,
  children,
}) => {
  return (
    <Modal
      visible={visibleState}
      zIndex={3000}
      title={title}
      onOk={okFunc}
      onCancel={cancelFunc}
      footer={footerContent}
    >
      {children}
    </Modal>
  );
};

export {CallDetailsModal};
