import {UseMutationResult} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {useBookingData} from "../../../container/booking/booking-context";
import {useCalendar} from "../../../hooks/use-calendar";
import {TCustomerPaidBundleApiResponse} from "../../../react-query-hooks/customer";
import {
  TTimeslotsPayload,
  useGetPublicProfileBooking,
} from "../../../react-query-hooks/public-profile";
import {CalendarBody} from "../calendar-body";
import {CalendarHeader} from "../calendar-header";
import {StyledCalendarWrapper} from "../calendar.style";

const BundleCalendar = ({
  timeslotsMutation,
  bundle,
}: {
  timeslotsMutation: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    TTimeslotsPayload,
    unknown
  >;
  bundle: TCustomerPaidBundleApiResponse;
}) => {
  const getPublicProfileBookingQuery = useGetPublicProfileBooking({
    mentorUsername: bundle.coach_username,
    id: String(bundle.service.id),
    slug: bundle.service.slug,
  });
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

  const {setValues, data} = useBookingData();

  function getDatesInRange(startDate: Date, endDate: Date) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      const dateToAdd = new Date(date);
      dates.push(
        `${dateToAdd.getFullYear()}-${dateToAdd.getMonth() + 1}-${dateToAdd.getDate()}`
      );
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const unavailableDateRanges = getPublicProfileBookingQuery.data?.unavailableDates.map(
    (date) => getDatesInRange(new Date(date.from), new Date(date.to))
  );
  let unavailableDateRangesState: string[] = [];

  if (unavailableDateRanges) {
    const newUD = [].concat(...(unavailableDateRanges as any));
    const uniqueArryDateRange = [...(new Set(newUD) as any)];
    unavailableDateRangesState = [...uniqueArryDateRange];
  }

  const arrDays = [];
  for (const key in getPublicProfileBookingQuery.data?.days) {
    arrDays.push({
      name: key,
      value: (getPublicProfileBookingQuery.data?.days as any)[key].length > 0,
    });
  }
  const arrDaysNum = arrDays
    .filter((day) => day.value)
    .map((day) =>
      day.name === "sun"
        ? 0
        : day.name === "mon"
        ? 1
        : day.name === "tue"
        ? 2
        : day.name === "wed"
        ? 3
        : day.name === "thu"
        ? 4
        : day.name === "fri"
        ? 5
        : day.name === "sat"
        ? 6
        : null
    );
  const {
    days,
    currentMonth: newCurrentMonth,
    currentYear: year,
    currentSelectedDate,
    setCurrentSelectedDate,
  } = useCalendar(currentMonth, currentYear, arrDaysNum, unavailableDateRangesState);

  const handlePrev = () => {
    setCurrentMonth((prevState) => prevState - 1);
    if (currentMonth < 1) {
      setCurrentMonth(11);
      setCurrentYear((prevState) => prevState - 1);
    }
  };

  const handleNext = () => {
    setCurrentMonth((prevState) => prevState + 1);
    if (currentMonth > 10) {
      setCurrentMonth(0);
      setCurrentYear((prevState) => prevState + 1);
    }
  };
  const handleSetCurrentDate = (day: number | null) => {
    if (!day) {
      return;
    }
    // const str = `${year}-${currentMonth + 1}-${day}`;
    // const selectedDateString = new Date(str);
    const selectedDateString = new Date(year, currentMonth, day);
    setValues({
      ...data,
      selectedDateString,
      booking_date: day,
      booking_year: year,
      booking_month: currentMonth + 1,
    });
    setCurrentSelectedDate(selectedDateString);

    const payload = {
      mentorId: getPublicProfileBookingQuery.data?.profile.userId,
      serviceId: getPublicProfileBookingQuery.data?.service.id,
      date: day,
      year,
      month: currentMonth + 1,
      day:
        selectedDateString.getDay() === 0
          ? "sun"
          : selectedDateString.getDay() === 1
          ? "mon"
          : selectedDateString.getDay() === 2
          ? "tue"
          : selectedDateString.getDay() === 3
          ? "wed"
          : selectedDateString.getDay() === 4
          ? "thu"
          : selectedDateString.getDay() === 5
          ? "fri"
          : "sat",
      mentorTimeZone: getPublicProfileBookingQuery.data?.timeZone as string,
      userTimeZone: data.selectedTimeZone as string,
    };
    timeslotsMutation.mutate(payload);
  };
  return (
    <StyledCalendarWrapper>
      <CalendarHeader
        currentMonth={newCurrentMonth}
        year={year}
        month={currentMonth}
        currentSelectedDate={currentSelectedDate}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <CalendarBody
        days={days}
        setCurrentDate={handleSetCurrentDate}
        currentSelectedDate={currentSelectedDate}
      />
    </StyledCalendarWrapper>
  );
};

export {BundleCalendar};
