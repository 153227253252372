import {Space} from "antd";
import {
  StyledTermsOrderList,
  StyledTermsOrderListItem,
} from "../../terms/terms-list/terms-list.style";
import {TermsListItem} from "../../terms/terms-list/terms-list-item";

export function PrivacyPolicyList() {
  return (
    <div>
      <StyledTermsOrderList>
        <StyledTermsOrderListItem>
          <TermsListItem title="information we collect from you">
            <Space direction="vertical" size={20}>
              <p>
                We collect the information you provide to us and this information is
                necessary for the adequate performance of the contractual arrangement
                which is in place between you and us and allow us to comply with our legal
                obligations.
              </p>
              <p>
                Account Signup Information; When you create the account, we ask you to
                provide the signup information, such as{" "}
                <strong>
                  email, name, surname, phone, username, passwords, personal number,
                  address, payment Information
                </strong>
                .
              </p>
              <p>
                To order and use features of Site, we may require you to provide certain
                financial information in order to facilitate the processing of payments.
                We collect your credit or debit card number, credit or debit card type,
                credit or debit card expiration date, billing address, name and surname.
              </p>
              <p>
                Login information; we collect login information if you are logging to our
                account with authentication data.
              </p>
              <p>
                <strong>
                  {" "}
                  We collect some Google-related information when you authenticate using
                  Google Auth, The information retrieved is then now used to help us
                  connect your Google calendar, so your booked events can reflect on your
                  calendar.
                </strong>
              </p>
              <p>
                See{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  here
                </a>{" "}
                to obtain more information about Google's User data policy
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="information we collect automatically">
            <Space direction="vertical" size={20}>
              <p>
                When you use our Site or contact us directly we may collect information,
                including your personal information, about the way you act in our Site,
                the Services you use and how you use them. This information is necessary
                for the adequate performance of the contract between you and us, to enable
                us to comply with legal obligations and given our legitimate interest in
                being able to provide and improve the functionalities of the Site.
              </p>
              <p>
                Log data and device information; we automatically collect log data and
                device information when you access and use the Site, even if you have not
                created an account or logged in. That information includes, among other
                things:{" "}
                <strong>
                  Internet protocol (IP) addresses, Date/time stamp, Browser type,
                  Tracking technologies and Cookies
                </strong>
                . We use Cookies. We also automatically collect information about device’s
                operating system, geo-location data. We collect information about your
                approximate location as determined by data such as your IP address to
                offer you an improved user experience. Such data may be collected only
                when you access the site using your device usage information.
              </p>
              <p>
                We use a tool called <strong>"Google Analytics"</strong> to collect
                information about your interactions with the Site (what pages you visit,
                such as the pages or content you view, your searches for listings,
                bookings you have made, and other actions on the site.
                <br /> In consequence, Google, Inc. plants a permanent cookie on your web
                browser to identify you as a unique user the next time you visit this
                Site). For more information please visit Google. Publicly available
                personal Information.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="the way we use your information">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  We process your information adhering to the general data processing
                  principles. We may use the information we collect through our site for a
                  number of reasons, including:{" "}
                </p>
                <ul style={{listStyleType: "square"}}>
                  <li>To identify user </li>
                  <li>To create account </li>
                  <li>To create statistics and analyse market</li>
                  <li>To stay connected </li>
                  <li>To customise marketing </li>
                  <li>To send billing information</li>
                  <li>To manage user orders </li>
                  <li>To contact user </li>
                  <li>To improve services </li>
                  <li>To ensure data security and prevent fraud </li>
                  <li>To comply with applicable laws </li>
                  <li>To request feedback </li>
                  <li>To post testimonials </li>
                  <li>To provide support </li>
                </ul>
                <p>
                  We will normally collect personal information from you only where we
                  have your consent to do so, where we need the personal information to
                  perform a contract with you, or where the processing is in our
                  legitimate business interests.
                </p>
              </div>
              <p>
                At Coachli, the use and transfer of information received from Google via
                our application will adhere to the{" "}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  “Google API Services User Data Policy”
                </a>
                , including the limited use requirements.
              </p>
              <p>
                We only intend to use the information retrieved to Schedule your bookings
                on your google calendar, delete the events from your calendar when you
                cancel such bookings, and update the events on your calendar when you
                reschedule.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="direct marketing">
            <Space direction="vertical" size={20}>
              <p>
                We may use your provided contact details for direct marketing. These
                direct marketing offers, depending on your preferences, may be
                personalised taking into account any other information which you have
                provided to us (e.g. location, social media profile information, etc.) or
                we have collected or generated from other sources as described below.
              </p>
              <p>
                If you wish to withdraw the consent for direct marketing, and refuse to
                receive information from us, you may exercise such option at any time you
                wish by updating your preferences in your account, following the
                instructions to unsubscribe in the received email, clicking on a link for
                that purpose at the bottom of our e-mail with the newsletters.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="how we may share your information">
            <Space direction="vertical" size={20}>
              <div>
                <p>
                  We may share your information with third parties that we use to provide
                  and support our Services:
                </p>
                <ol type="i">
                  <li>
                    Invoicing and billing company - <strong>Stripe</strong>
                  </li>
                  <li>
                    Analytics - <strong>Google Analytics</strong>
                  </li>
                </ol>
              </div>
              <div>
                <p>We may also disclose your information to third parties:</p>
                <ol type="i">
                  <li>
                    Where required by law or regulatory requirement, court order or other
                    judicial authorisation
                  </li>
                  <li>
                    In response to lawful requests by public authorities, including for
                    the purposes of meeting national security and law enforcement
                    requirements
                  </li>
                  <li>
                    In connection with the sale, transfer, merger, bankruptcy,
                    restructuring or other re-organisation of a business
                  </li>
                  <li>
                    To protect or defend our rights, interests or property, or that of
                    third parties
                  </li>
                  <li>
                    To investigate any wrongdoing in connection with our products and
                    Services
                  </li>
                  <li>To protect the vital interests of an individual.</li>
                </ol>
              </div>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="cookies">
            <Space direction="vertical" size={20}>
              <p>
                Cookies are small text files stored by your browser on your computer when
                you visit our Site. We use cookies to improve our Site and make it easier
                to use. Cookies permit us to recognise users and avoid repetitive requests
                for the same information. Cookies from our site cannot be read by other
                sites.
              </p>
              <div>
                <p>
                  Most browsers will accept cookies unless you change your browser
                  settings to refuse them. Cookies we use on our site:
                </p>
                <ol type="i">
                  <li>
                    Strictly necessary cookies - These cookies are required for the
                    operation of our site. They help us to show you the right information,
                    customise your experience, and allow us to implement and maintain
                    security features as well as to help us detect malicious activities.
                    Without these cookies operation of the website would be impossible or
                    its functioning may be severely affected.{" "}
                  </li>
                </ol>
              </div>
              <p>
                You may find more information about how to delete cookies, as well as the
                other useful information related to the use of the cookies, on the website{" "}
                <a
                  href="http://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  http://www.allaboutcookies.org/
                </a>
                .
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="information from minors">
            <Space direction="vertical" size={20}>
              <p>
                This Site and our Services are not intended for or directed to persons
                under the age of 18. We do not knowingly collect or ask for information
                from minors. We do not knowingly allow minors to use our Site or Services.
                We will delete any information we discover is collected from a minor.
                Please contact us using the contact details below if you believe we might
                have information about a minor.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="sensitive information">
            <Space direction="vertical" size={20}>
              <p>
                We do not collect sensitive information such as political opinions,
                religious or philosophical beliefs, racial or ethnic origin, genetic data,
                biometric data, health data or data related a sexual orientation. Please
                do not send, upload, or provide us any sensitive data and contact us using
                the contact details below if you believe that we might have such
                information. We have a right to delete any information we believe it might
                contain sensitive data.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="payment information">
            <Space direction="vertical" size={20}>
              <p>
                To order and use our Services we may require you to provide certain
                financial information to facilitate the processing of payments. We will
                not store or collect your payment card details. That information is
                provided directly to our third-party payment processors whose use of your
                personal information is governed by their Privacy Policy. All payment data
                is stored by Flutterwave Inc. You may find their Privacy Policy link(s){" "}
                <a
                  href="https://dispute.flutterwave.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  here
                </a>
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="third-party links">
            <Space direction="vertical" size={20}>
              <p>
                Our Site may have links to other websites. Please review their Privacy
                Policies to learn more about how they collect and use your personal data,
                because we do not control their policies and personal data processing
                practices.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="retention">
            <Space direction="vertical" size={20}>
              <p>
                We retain your personal information to provide services to you and as
                otherwise necessary to comply with our legal obligation, resolve disputes,
                and enforce our agreements. We will retain your personal information not
                longer than you keep your account, unless we are otherwise required by law
                or regulations to retain your personal information longer. If you would
                like to stop us using your personal information, you shall request that we
                erase your personal information and close your account.
              </p>
              <p>
                Please note that even after deletion of your account we may keep some of
                your information for tax, legal reporting and auditing obligations.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="security">
            <Space direction="vertical" size={20}>
              <p>
                We have implemented security measures designed to protect the personal
                information you share with us, including physical, electronic and
                procedural measures. Among other things, we regularly monitor our systems
                for possible vulnerabilities and attacks. Regardless of the measures and
                efforts taken by us, the transmission of information via internet, email
                or text message is not completely secure.
              </p>
              <p>
                We do not guarantee the absolute protection and security of your personal
                information or any other user content you upload, publish or otherwise
                share with us or anyone else. We therefore encourage you to avoid
                providing us or anyone with any sensitive information of which you believe
                its disclosure could cause you substantial or irreparable harm.
              </p>
              <p>
                If you have any questions regarding the security of our Site or Services,
                you are welcome to contact us at{" "}
                <a
                  href="mailto:hello@coachli.co"
                  style={{color: "#00171F", textDecoration: "underline"}}
                >
                  hello@coachli.co
                </a>
                .
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="your rights">
            <Space direction="vertical" size={20}>
              <p>
                You are entitled to a range of rights regarding the protection of your
                personal information. Those rights are: The right to access the
                information we have about you. If you wish to access your personal
                information that we collect, you can do so at any time by contacting us
                using the contact details provided below. The right to rectify inaccurate
                information about you.
              </p>
              <p>
                You can correct, update or request deletion of your personal information
                by contacting us using the contact details provided below. The right to
                object the processing. When we rely on your consent to process your
                personal information, you may withdraw consent at any time by contacting
                us using the contact details provided below. This will not affect the
                lawfulness of processing prior to the withdrawal of your consent. The
                right to lodge a complaint.
              </p>
              <p>
                You can raise questions or complaints to the national data protection
                agency in your country of residence in the event where your rights may
                have been infringed. However, we recommend attempting to reach a peaceful
                resolution of the possible dispute by contacting us first. The right to
                erase any data concerning you. You may demand erasure of data without
                undue delay for legitimate reasons, e.g. where data is no longer necessary
                for the purposes it was collected, or where the data has been unlawfully
                processed.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="application of policy">
            <Space direction="vertical" size={20}>
              <p>
                This applies only to the Services offered by our Company. Our Policy does
                not apply to Services offered by other companies or individuals, including
                products or sites that may be displayed to you in search results, sites
                that may include our Services or other sites linked from our Site or
                Services.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="amendments">
            <Space direction="vertical" size={20}>
              <p>
                Our Policy may change from time to time. We will post any Policy changes
                on our Site and, if the changes are significant, we may consider providing
                a more explicit notice (including, for certain Services, email
                notification of Policy changes).
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="acceptance of this policy">
            <Space direction="vertical" size={20}>
              <p>
                We assume that all users of this Site have carefully read this document
                and agree to its contents. If someone does not agree with this Policy,
                they should refrain from using our Site. We reserve the right to change
                our Policy at any time and inform by using the way as indicated in Section
                15. Continued use of this Site implies acceptance of the revised Policy.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
        <StyledTermsOrderListItem>
          <TermsListItem title="further information">
            <Space direction="vertical" size={20}>
              <p>
                If you have any further questions regarding the data we collect, or how we
                use it, then please feel free to contact us at the details as indicated
                above.
              </p>
            </Space>
          </TermsListItem>
        </StyledTermsOrderListItem>
      </StyledTermsOrderList>
    </div>
  );
}
