import styled from "styled-components";

export const StyledSuccessBookingInfo = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
`;
export const StyledSuccessBookingShareSection = styled.div`
  margin-top: 4rem;
`;

export const StyledSuccessBookingShareSectionInfoWrapper = styled.div`
  //   padding: 4rem 0;
`;

export const StyledSuccessBookingShareSectionInfo = styled.div`
  padding: 3rem 1rem;
  background: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .info-text {
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;
    color: #00171f;
    // max-width: 37.4rem;
    .mentor-name {
      text-transform: capitalize;
    }
  }
  .share-heading {
    font-weight: 600;
    font-size: 1.6rem;
    color: #000709;
  }
`;
