import {UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {Spin, Divider, Space, Alert} from "antd";
import {AxiosError} from "axios";
import moment from "moment";
import {useState} from "react";
import {useBookingData} from "../../../../../../../container/booking/booking-context";
import {TCustomerPaidBundleApiResponse} from "../../../../../../../react-query-hooks/customer";
import {
  ServiceBookingPayload,
  TBookingPayload,
  TInitiateBundleBookingPayload,
  TPublicProfileBookingApiResponse,
  TTimeslotsPayload,
  useCreateBundleBooking,
} from "../../../../../../../react-query-hooks/public-profile";
import {
  StyledBookingRightColumnHeader,
  StyledBookingRightColumnWrapper,
  StyledBookingTimeComponent,
  StyledBookingTimeComponentItemWrapper,
  StyledBookingTimeComponentButton,
  StyledBookingTimeComponentConfirmButton,
} from "../../../../../../../styles/booking/booking";
import {CustomCalendar} from "../../../../../../calendar";
import {BundleCalendar} from "../../../../../../calendar/customer/bundle-calendar";
import {RootState} from "../../../../../../../redux/store";
import {useSelector} from "react-redux";

type TCalendarContentProps = {
  updateStep: (step: number) => void;
  currentStep?: number;
  timeslotsMutation: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    TTimeslotsPayload,
    unknown
  >;
  bundleBookingMutation: UseMutationResult<
    any,
    AxiosError<
      {
        message: string;
      },
      any
    >,
    ServiceBookingPayload,
    unknown
  >;
  publicProfileData?: TPublicProfileBookingApiResponse;
  bundle: TCustomerPaidBundleApiResponse;
};

const CalendarContent: React.FC<TCalendarContentProps> = ({
  updateStep,
  timeslotsMutation,
  bundleBookingMutation,
  publicProfileData,
  bundle,
}) => {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const {setValues, data} = useBookingData();
  const [showConfirm, setShowConfirm] = useState("");
  const queryClient = useQueryClient();

  const handleConfirm = () => {
    setValues({...data, timeSelected: showConfirm});
    // updateStep(2);
    const tz = moment.tz.guess(true);
    const payload: ServiceBookingPayload = {
      mentorId: Number(publicProfileData?.profile.userId),
      serviceId: Number(publicProfileData?.service.id),
      mentor_timezone: publicProfileData?.timeZone as string,
      user_timezone: data.selectedTimeZone ?? tz,
      email_address: userInfo?.user?.emailAddress,
      phone_number: userInfo?.user?.phoneNumber,
      booking_date: data.booking_date,
      booking_year: data.booking_year,
      booking_month: data.booking_month,
      timeSlot: showConfirm,
      booking_type: publicProfileData?.service.audioLocation ? "audio" : "video",
      currency: publicProfileData?.service.callPriceCurrency as string,
      amount: Number(publicProfileData?.service.callPrice),
      first_name: bundle.coach.firstName,
      last_name: bundle.coach.lastName,
      notes: "null", //TODO: confirm that this does not affect initial note
    };
    // console.log({payload});
    bundleBookingMutation.mutate(payload, {
      onSuccess: (data) => {
        updateStep(2);
      },
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries({queryKey: ["customer_upcoming_calls"]}),
          queryClient.invalidateQueries({queryKey: ["customer_paid_bundles"]}),
        ]),
    });
  };

  return (
    <div>
      <StyledBookingRightColumnHeader>
        Select date and time for your next session
      </StyledBookingRightColumnHeader>
      <div>
        {bundleBookingMutation.isError && (
          <Alert
            message="Error"
            description={bundleBookingMutation.error.message}
            type="error"
            showIcon
            closable
          />
        )}
      </div>
      <StyledBookingRightColumnWrapper>
        <div>
          <BundleCalendar bundle={bundle} timeslotsMutation={timeslotsMutation} />
        </div>
        {timeslotsMutation.isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "65vh",
              width: "100%",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {timeslotsMutation.isSuccess && (
          <StyledBookingTimeComponent id="time-component">
            <div className="time-component-heading">
              {data.selectedDateString.toDateString()}
            </div>
            <Divider style={{marginTop: "1.8rem", marginBottom: "2.4rem"}} />
            <Space size={8} direction="vertical" style={{display: "flex"}}>
              {timeslotsMutation.data.data.timeSlots.map((timeSlot: string) => (
                <StyledBookingTimeComponentItemWrapper key={timeSlot}>
                  <StyledBookingTimeComponentButton
                    block
                    size="large"
                    onClick={() => setShowConfirm(timeSlot)}
                  >
                    {/* {data.selectedTimeZone} */}
                    {/* {getCurrentTimeSlots(
                      timeSlot,
                      data.selectedTimeZone,
                      bookingServiceData.timeZone
                    )} */}
                    {timeSlot}
                  </StyledBookingTimeComponentButton>
                  {showConfirm === timeSlot && (
                    <StyledBookingTimeComponentConfirmButton
                      block
                      size="large"
                      onClick={handleConfirm}
                      loading={bundleBookingMutation.isLoading}
                    >
                      Confirm
                    </StyledBookingTimeComponentConfirmButton>
                  )}
                </StyledBookingTimeComponentItemWrapper>
              ))}
            </Space>
          </StyledBookingTimeComponent>
        )}
      </StyledBookingRightColumnWrapper>
    </div>
  );
};

export {CalendarContent};
