import styled from "styled-components";

export const StyledWidgetWrapper = styled.div`
  .widget-heading {
    font-weight: 600;
    font-size: 1.6rem;
    color: #00171f;
  }

  .widget-card {
    margin-top: 1.6rem;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 1.6rem;

    .widget-card-bio {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
`;

export const StyledWidgetInfoBlock = styled.div`
  background-color: #fff1f0;
  border-radius: 5px;
  padding: 1.1rem 2rem;
  font-weight: 400;
  font-size: 1.4rem;
`;
export const StyledWidgetNewTag = styled.div`
  position: absolute;
  top: -10px;
  background: #fff;
  display: inline-flex;
  padding: 4px;
  border-radius: 40px;
  z-index: 1;

  .new-text {
    transform: rotate(-23.752deg);
    background: linear-gradient(257deg, #f759ab 1.69%, #fadb14 78.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`;
