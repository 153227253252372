import styled from "styled-components";

export const StyledCoverImageWrapper = styled.div`
  padding: 2rem;
  border-radius: 5px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .upload-text {
    font-size: 1.6rem;
  }
`;
