import styled from "styled-components";

export const StyledWhatMakesUsSpecialContentWrapper = styled.div`
  margin-top: 10rem;
`;
export const StyledLine = styled.div`
  height: 1px;
  background-color: #b6b4bd;
  margin: 4rem 0;
`;
