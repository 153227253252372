import styled from "styled-components";

export const StyledMessageCustomerListItem = styled.div<{isActive?: boolean}>`
  padding: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  // gap: 3rem,
  cursor: pointer;
  background-color: ${({isActive}) => (isActive ? "#f5edff" : "#fff")};
  .message-customer-list-item-time {
    font-weight: 400;
    font-size: 1.4rem;
    color: #595959;
  }
  .avatar-name-container {
    display: flex;
    align-items: center;
    gap: 8px;
    .avatar-comp {
      background-color: ${({isActive}) => (isActive ? "#9747ff" : "#F5EDFF")};
      color: ${({isActive}) => (isActive ? "#fff" : "#5B2B99")};
    }
    .message-customer-list-item-name {
      font-weight: 600;
      font-size: 1.4rem;
      color: ${({isActive}) => (isActive ? "#5b2b99" : "#00171f")};
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem;
    .avatar-name-container {
      .avatar-comp {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 10px;
      }
      .message-customer-list-item-name {
        display: none;
      }
    }
  }
`;
