import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {useClient} from "../../utils/hooks";
import {AnalyticsEvent} from "../../utils/analytics";
import {message} from "antd";
import {TProfileApiResponse} from "../../redux/profile/i-profile-1";
import {CurrencyResponse, PaginationMeta} from "../../redux/service/i-service";
import {Currency} from "../../components/calls/service/service-form/i-service-form";

export type TLiveClassApiResponse = {
  activeSession: {
    name: string;
    startDate: string;
    startTime: string;
    duration: number;
    endTime: string;
  };
  activeSessionId: number;
  attendees: number;
  bookingQuestions: {
    question: string;
    required: boolean;
  }[];
  callPrice: number;
  callPriceCurrency: string;
  cover_image: string;
  createdAt: Date;
  currencies: CurrencyResponse[];
  description: string;
  hide_from_menu: boolean;
  id: number;
  name: string;
  reference: string;
  roomId: string;
  session_count: number;
  session_info: {
    name: string;
    startDate: string;
    startTime: string;
    duration: number;
    endTime: string;
  }[];
  slug: string;
  statusId: number;
  statusName: "Paused" | "Active" | "In Progress";
  topic_type: "single" | "series";
  request_phone_number: boolean;
  // topics: string[];
  updatedAt: Date;
  url_redirect?: string;
  userId: number;
};

export type GetLiveClassApiResponse = TLiveClassApiResponse & {
  bookedCounts: number;
};

export type TCreateLiveClassApiPayload = Omit<
  TLiveClassApiResponse,
  | "id"
  | "userId"
  | "slug"
  | "activeSession"
  | "roomId"
  | "activeSessionId"
  | "session_count"
  | "statusId"
  | "reference"
  | "createdAt"
  | "updatedAt"
  | "bookingQuestions"
  | "session_info"
  | "cover_image"
  | "hide_from_menu"
  | "statusName"
  | "currencies"
> & {
  session_info: {
    name: string;
    startDate: string;
    startTime: string;
    duration: number | string;
  }[];
  coverImage: string;
  hideFromMenu: boolean;
  isPauseable: boolean;
  currencies: Currency[];
} & Partial<{
    bookingQuestions: {
      question: string;
      required: boolean;
    }[];
    // co_hosts: {
    //   host_id: number;
    //   email_address: string;
    //   first_name: string;
    //   last_name: string;
    // }[];
  }>;

export type PublicProfileLiveClassApiResponseEventCohost = {
  id: number;
  userId: number;
  username: string;
  description: string;
  buffer_time: number;
  shortBio: string;
  twitter: string | null;
  facebook: string | null;
  instagram: string | null;
  tiktok: string | null;
  discord: string | null;
  youtube: string | null;
  dribble: string | null;
  behance: string | null;
  twitch: string | null;
  github: string | null;
  website: string | null;
  linkedin: string | null;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    onboarding_reminder: number;
    country: string;
    password: null;
    statusId: number;
    isSocialAuth: boolean;
    avatar: string | null;
    cover_image: string | null;
    createdAt: string;
    updatedAt: string;
  };
};
export type PublicProfileLiveClassApiResponseEvent = TLiveClassApiResponse & {
  co_hosts: PublicProfileLiveClassApiResponseEventCohost[];
};
export type PublicProfileApiResponseEvent = TLiveClassApiResponse & {
  is_co_host: boolean;
  hostProfile?: PublicProfileLiveClassApiResponseEventCohost;
};
export type TPublicProfileLiveClassApiResponse = TProfileApiResponse & {
  event: PublicProfileLiveClassApiResponseEvent;
  totalSessions: number;
  bookedCounts: number;
  spotLeft: number;
  // statusName: "Paused" | "Active" | "In Progress";
  activeSession: {
    name: string;
    startDate: string;
    startTime: string;
    duration: number;
    endTime: string;
  };
  timeZone: {
    id: number;
    userId: number;
    coachli_timezone: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type TLiveClassBookingPayload = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coach_timezone: string;
  customer_timezone: string;
  phone_number?: string;
  eventId: number;
  amount: number;
  currency: string;
  slug: string;
  reference: string | null;
};
export type TPremiumLiveClassBookingPayload = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coach_timezone: string;
  customer_timezone: string;
  eventId: number;
  amount: number;
  reference: string;
  currency: string;
};

export type TLiveClassBookingApiResponse = {
  userId: number;
  eventId: number;
  paymentProvider: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  reference: string;
  statusId: number;
  amount: number;
  isStripe: boolean;
  redirectUrl?: string;
  currency: string;
  isPaid: boolean;
  username: string;
};
export type TPremiumLiveClassBookingApiResponse = {
  userId: number;
  eventId: number;
  paymentProvider: string;
  emailAddress: string;
  reference: string;
  statusId: number;
  amount: number;
  isStripe: boolean;
  redirectUrl?: string;
};

export type TAttendee = {
  coach_end_time: string;
  coach_event_time: string;
  createdAt: string;
  current_session: number;
  customer_end_time: string;
  customer_event_time: string;
  emailAddress: string;
  eventId: number;
  event_date: string;
  firstName: string;
  id: number;
  is_paid: boolean;
  is_series: boolean;
  lastName: string;
  liveEvent: TLiveClassApiResponse;
  paymentProvider: string;
  reference: string;
  roomId: string;
  statusId: number;
  updatedAt: Date;
  userId: number;
};
export type TLiveClassAttendeesApiResponse = {
  totalRevenue: {currency: string; revenue: number}[];
  totalAttendees: number;
  attendees: TAttendee[];
  event: TLiveClassApiResponse;
};

export type TLiveClassAttendeeDetailsApiResposne = {
  booking: {
    coach_end_time: string;
    coach_event_time: string;
    createdAt: string;
    current_session: number;
    customer_end_time: string;
    customer_event_time: string;
    emailAddress: string;
    eventId: number;
    event_date: string;
    firstName: string;
    id: number;
    is_paid: boolean;
    is_series: boolean;
    lastName: string;
    paymentProvider: string;
    phone_number: string;
    reference: string;
    roomId: string;
    statusId: number;
    updatedAt: string;
    userId: number;
  };
  event: TLiveClassApiResponse;
  totalPurchases: number;
  totalVolume: number;
};

export type TJoinLiveClassApiResponse = {
  event: TLiveClassApiResponse;
  booking: {
    bookingToken: string;
  };
  bookingToken: string;
  videoPlatform: "dailyCo" | "videoSdk";
  dailyCoUrl: string;
};

export type TCustomerEvent = {
  id: number;
  userId: number;
  eventId: number;
  paymentProvider: string;
  roomId: string;
  current_session: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  is_series: boolean;
  is_paid: boolean;
  event_date: string;
  reference: string;
  coach_event_time: string;
  coach_end_time: string;
  customer_event_time: string;
  customer_end_time: string;
  statusId: number;
  createdAt: Date;
  updatedAt: Date;
  liveEvent: TLiveClassApiResponse;
  statusName: string;
};

export type TGetLiveClassesCustomerApiResponse = {
  events: TCustomerEvent[];
};

const useGetLiveClasses = () => {
  const client = useClient();

  return useInfiniteQuery<
    {data: TLiveClassApiResponse[]; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes"],
    queryFn: ({pageParam = 1}) =>
      client(`live-event/upcoming?page=${pageParam}&per_page=5`).then((data) => data),
    refetchInterval: 300000,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      // console.log({lastPage, pages});

      if (lastPage.meta) {
        return lastPage.meta.next_page === 0 ? undefined : lastPage.meta.next_page;
      } else {
        return undefined;
      }
    },
  });
};
const useGetPastLiveClasses = (page: number = 1, per_page: number = 5) => {
  const client = useClient();

  return useQuery<
    {data: TLiveClassApiResponse[]; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_past", page],
    queryFn: () =>
      client(`live-event/past?page=${page}&per_page=${per_page}`).then((data) => data),
    refetchInterval: 300000,
    keepPreviousData: true,
  });
};
const useGetLiveClassesDraft = () => {
  const client = useClient();

  return useQuery<
    TLiveClassApiResponse[],
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_draft"],
    queryFn: () => client(`live-event/draft`).then((data) => data.data),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
const useGetLiveClass = (eventId: string) => {
  const client = useClient();

  return useQuery<
    GetLiveClassApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes", eventId],
    queryFn: () => client(`live-event/fetch/${eventId}`).then((data) => data.data),
    // staleTime: Infinity,
    // refetchOnWindowFocus: true,
  });
};
const useCreateLiveClass = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (liveClassData: TCreateLiveClassApiPayload) =>
      client(`live-event`, {
        method: "POST",
        data: liveClassData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Live events", "Live class create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Live events", "Live class create success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["live_classes"]}),
  });
};
const useSaveLiveClassAsDraft = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (liveClassData: Partial<TCreateLiveClassApiPayload>) =>
      client(`draft/live-event`, {
        method: "POST",
        data: liveClassData,
      }),

    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Live events", "Live class draft fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Live events", "Live class draft success");
    },
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["live_classes"]}),
        queryClient.invalidateQueries({queryKey: ["live_classes_draft"]}),
      ]),
  });
};
const useUpdateLiveClass = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (liveClassData: TCreateLiveClassApiPayload & {id: string}) =>
      client(`live-event/${liveClassData.id}`, {
        method: "POST",
        data: liveClassData,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["live_classes", String(values.id)]);

      const oldLiveClass = queryClient.getQueryData(["live_classes", String(values.id)]);
      queryClient.setQueryData(["live_classes", String(values.id)], values);

      return () =>
        queryClient.setQueryData(["live_classes", String(values.id)], oldLiveClass);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Live events", "Live class update fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data, values) => {
      AnalyticsEvent("Live events", "Live class update success");
      Promise.all([
        queryClient.setQueryData(["live_classes", String(values.id)], {
          ...data.data,
          currencies: values.currencies,
        }),
        queryClient.invalidateQueries({queryKey: ["live_classes"]}),
        queryClient.invalidateQueries({queryKey: ["live_classes_draft"]}),
      ]);
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["live_classes", String(values.id)]}),
        queryClient.invalidateQueries({queryKey: ["live_classes"]}),
        queryClient.invalidateQueries({queryKey: ["live_classes_draft"]}),
      ]),
  });
};
const useDeleteLiveClass = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (eventId: string) =>
      client(`live-event/${eventId}`, {
        method: "DELETE",
      }),
    onMutate: (eventId) => {
      const oldEvent:
        | InfiniteData<{
            data: TLiveClassApiResponse[];
            meta: PaginationMeta;
          }>
        | undefined = queryClient.getQueryData(["live_classes"]);
      const newPagesArray =
        oldEvent?.pages.map((page) => ({
          data: page.data.filter((val) => String(val.id) !== String(eventId)),
          meta: page.meta,
        })) ?? [];
      queryClient.setQueryData(
        ["live_classes"],
        (
          oldValues:
            | InfiniteData<{
                data: TLiveClassApiResponse[];
                meta: PaginationMeta;
              }>
            | undefined
        ) => {
          return {
            pageParams: oldValues?.pageParams ?? [],
            pages: newPagesArray,
          };
        }
      );

      return () => queryClient.setQueryData(["live_classes"], oldEvent);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Live event", "Live event delete fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: () => {
      AnalyticsEvent("Live event", "Live event delete success");
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["live_classes"],
        refetchPage: (lastPage, index, allPages) => {
          // console.log({lastPage, index, allPages});
          return index === 0;
        },
      }),
  });
};
const useGetPublicProfileLiveClass = (payload: {
  username: string | undefined;
  slug: string | null;
  eventId: string | null;
  customerTimezone: string | null;
}) => {
  const client = useClient();

  return useQuery<
    TPublicProfileLiveClassApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["public_profile_live_class", payload.slug],
    queryFn: () =>
      client(`live-event-book`, {
        method: "POST",
        data: payload,
      }).then((data) => data.data),
    enabled:
      !!payload.slug &&
      !!payload.eventId &&
      !!payload.username &&
      !!payload.customerTimezone,
  });
};
const useCreateLiveClassBooking = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation<
    {data: TLiveClassBookingApiResponse},
    AxiosError,
    TLiveClassBookingPayload,
    () => void
  >({
    mutationFn: (bookingData) =>
      client(`live-event-booking`, {
        method: "POST",
        data: {
          firstName: bookingData.firstName,
          lastName: bookingData.lastName,
          emailAddress: bookingData.emailAddress.toLowerCase(),
          coach_timezone: bookingData.coach_timezone,
          customer_timezone: bookingData.customer_timezone,
          eventId: bookingData.eventId,
          currency: bookingData.currency,
          amount: bookingData.amount,
          phone_number: bookingData.phone_number,
        },
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Live class booking create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Live class booking create success");
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({
        queryKey: ["public_profile_live_class", values.slug],
      }),
  });
};
const useCreatePremiumLiveClassBooking = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation<
    {data: TLiveClassBookingApiResponse},
    AxiosError,
    TLiveClassBookingPayload,
    () => void
  >({
    mutationFn: (bookingData) =>
      client(`live-event-coach-subscribe`, {
        method: "POST",
        data: {
          firstName: bookingData.firstName,
          lastName: bookingData.lastName,
          emailAddress: bookingData.emailAddress.toLowerCase(),
          coach_timezone: bookingData.coach_timezone,
          customer_timezone: bookingData.customer_timezone,
          eventId: bookingData.eventId,
          amount: bookingData.amount,
          reference: bookingData.reference,
          currency: bookingData.currency,
        },
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Live class booking create fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Live class booking create success");
    },
    onSettled: (data, error, values) =>
      queryClient.invalidateQueries({
        queryKey: ["public_profile_live_class", values.slug],
      }),
  });
};
const useGetAttendeesInsight = (
  eventId?: string | number,
  page: number = 1,
  per_page: number = 5
) => {
  const client = useClient();

  return useQuery<
    {data: TLiveClassAttendeesApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_attendees", eventId, page],
    queryFn: () =>
      client(`live-event/attendees/${eventId}?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    enabled: !!eventId,
    keepPreviousData: true,
  });
};
const useGetAttendeesDetails = (payload: {
  eventId?: string | number;
  attendeeId?: string | number;
  attendeeEmail?: string;
}) => {
  const client = useClient();

  return useQuery<
    TLiveClassAttendeeDetailsApiResposne,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_attendees", payload.attendeeId],
    queryFn: () =>
      client(
        `live-event/attendees/${payload.eventId}/${payload.attendeeId}/${payload.attendeeEmail}`
      ).then((data) => data.data),
    enabled: !!payload.eventId && !!payload.attendeeId && !!payload.attendeeEmail,
  });
};
const useJoinLiveClass = () => {
  const client = useClient();
  return useMutation<
    {data: TJoinLiveClassApiResponse},
    AxiosError,
    string | number,
    () => void
  >({
    mutationFn: (eventId) =>
      client(`live-event/join/${eventId}`, {
        method: "GET",
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Live class booking join fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Live class booking join success");
    },
  });
};
const useGetLiveClassesCustomer = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TGetLiveClassesCustomerApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_customer", page],
    queryFn: () =>
      client(`customer/live-events/upcoming?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    refetchInterval: 300000,
    keepPreviousData: true,
  });
};
const useGetPastLiveClassesCustomer = (page = 1, per_page = 5) => {
  const client = useClient();

  return useQuery<
    {data: TGetLiveClassesCustomerApiResponse; meta: PaginationMeta},
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["live_classes_past_customer", page],
    queryFn: () =>
      client(`customer/live-events/past?page=${page}&per_page=${per_page}`).then(
        (data) => data
      ),
    refetchInterval: 300000,
    keepPreviousData: true,
  });
};
const useCustomerJoinLiveClass = () => {
  const client = useClient();
  return useMutation<
    {data: TJoinLiveClassApiResponse},
    AxiosError,
    string | number,
    () => void
  >({
    mutationFn: (eventReference) =>
      // client(`customer/live-events/join/${eventId}`, {
      //   method: "GET",
      // }),
      client(`live-event/booking/join/${eventReference}`, {
        method: "GET",
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Booking", "Customer Live class booking join fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Booking", "Customer Live class booking join success");
    },
  });
};
const useLiveClassAttendeeExportCSV = () => {
  const client = useClient();
  return useMutation({
    mutationFn: (eventId: string) =>
      client(`live-event/attendees-csv/${eventId}`, {
        method: "GET",
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Coach attendee", "Coach attendee export CSV fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Coach attendee", "Coach attendee export CSV success");
    },
  });
};

const useCoachScheduleClass = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation<
    {data: TJoinLiveClassApiResponse},
    AxiosError,
    {
      eventId: string | number;
      scheduleId: string | number;
      message: string;
      newDate: string;
      newTime: string;
      // timeZone: string;
      duration: string | number;
    },
    () => void
  >({
    mutationFn: (payload) =>
      // client(`customer/live-events/join/${eventId}`, {
      //   method: "GET",
      // }),
      client(`live-event`, {
        method: "PATCH",
        data: {
          eventId: payload.eventId,
          scheduleId: payload.scheduleId,
          message: payload.message,
          newDate: payload.newDate,
          newTime: payload.newTime,
          // timeZone: payload.timeZone,
          duration: payload.duration,
        },
      }),

    onError: (error) => {
      message.error(error.message);
      AnalyticsEvent("Live event", "Coach schedule class fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Live event", "Coach schedule class success");
    },
    onSettled: (data, error, values) =>
      Promise.all([
        queryClient.invalidateQueries({queryKey: ["live_classes"]}),
        queryClient.invalidateQueries({
          queryKey: ["live_classes", String(values.eventId)],
        }),
      ]),
  });
};

export {
  useGetLiveClass,
  useGetLiveClasses,
  useGetPastLiveClasses,
  useGetLiveClassesDraft,
  useCreateLiveClass,
  useSaveLiveClassAsDraft,
  useUpdateLiveClass,
  useDeleteLiveClass,
  useGetPublicProfileLiveClass,
  useCreateLiveClassBooking,
  useCreatePremiumLiveClassBooking,
  useGetAttendeesInsight,
  useGetAttendeesDetails,
  useJoinLiveClass,
  useGetLiveClassesCustomer,
  useGetPastLiveClassesCustomer,
  useCustomerJoinLiveClass,
  useLiveClassAttendeeExportCSV,
  useCoachScheduleClass,
};
