import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {IAvailabilityFormValues} from "./i-availability-form";
import {
  createAvailabilityService,
  getUserAvailabilityService,
} from "./availability-service";

interface IInitialState {
  availabilities: {
    sun?: {from: string; to: string}[];
    mon?: {from: string; to: string}[];
    tue?: {from: string; to: string}[];
    wed?: {from: string; to: string}[];
    thu?: {from: string; to: string}[];
    fri?: {from: string; to: string}[];
    sat?: {from: string; to: string}[];
  };
  createStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchStatus: "idle" | "pending" | "resolved" | "rejected";
  message: string;
}

const initialState: IInitialState = {
  availabilities: {},
  createStatus: "idle",
  fetchStatus: "idle",
  message: "",
};

//create availability
export const createAvailability = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  IAvailabilityFormValues,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("availability/createAvailability", async (selections, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await createAvailabilityService(selections, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//get user unavailabilities
export const getAvailabilities = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("availability/getAvailabilities", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getUserAvailabilityService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    resetUserAvailabilityState: (state) => {
      state.createStatus = "idle";
      state.fetchStatus = "idle";
      state.message = "";
      state.availabilities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAvailability.pending, (state) => {
        state.createStatus = "pending";
      })
      .addCase(createAvailability.fulfilled, (state, action) => {
        state.createStatus = "resolved";
        // state.availabilities = action.payload.data;
      })
      .addCase(createAvailability.rejected, (state, action: any) => {
        state.createStatus = "rejected";
        state.message = action.payload;
        state.availabilities = {};
      })
      .addCase(getAvailabilities.pending, (state) => {
        state.fetchStatus = "pending";
      })
      .addCase(getAvailabilities.fulfilled, (state, action) => {
        state.fetchStatus = "resolved";
        state.availabilities = action.payload.data;
      })
      .addCase(getAvailabilities.rejected, (state, action: any) => {
        state.fetchStatus = "rejected";
        state.message = action.payload;
      });
  },
});

export const {resetUserAvailabilityState} = availabilitySlice.actions;

export default availabilitySlice.reducer;

// export {};
