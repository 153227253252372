import {Dispatch, PropsWithChildren, useReducer} from "react";
import {
  TBookingActions,
  bookingReducer,
  initialBookingState,
} from "../../reducers/booking/booking-reducer";
import {createContext} from "../create-context";

type TBookingContextState = {
  state: typeof initialBookingState;
  dispatch: Dispatch<TBookingActions>;
};

const [useContext, ContextProvider] = createContext<TBookingContextState>();

export const useBookingContext = useContext;

export const BookingProvider = ({children}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(bookingReducer, initialBookingState);

  return <ContextProvider value={{state, dispatch}}>{children}</ContextProvider>;
};
