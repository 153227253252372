import {
  StyledCalendarBody,
  StyledCalendarDay,
  StyledCalendarWeekDay,
} from "./calendar-body.style";

type Day = {
  value: number | null;
  isAvailable: boolean;
  isSelected: boolean;
  date: string;
};

const CalendarBody = ({
  days,
  setCurrentDate,
  currentSelectedDate,
}: {
  days: [{value: number; isAvailable: boolean; isSelected: boolean; date: string}];
  setCurrentDate: (day: number | null) => void;
  currentSelectedDate?: Date;
}) => {
  const handleDayClassname = (day: Day) => {
    const className = day.isSelected
      ? "calendar-day day-available selected-date"
      : day.isAvailable
      ? "calendar-day day-available"
      : "";
    return className;
  };
  return (
    <StyledCalendarBody>
      <StyledCalendarWeekDay className="calendar-week-day">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </StyledCalendarWeekDay>
      <StyledCalendarDay className="calendar-days">
        {days.map(
          (
            day: {
              value: number | null;
              isAvailable: boolean;
              isSelected: boolean;
              date: string;
            },
            idx: number
          ) => (
            <div
              key={idx}
              onClick={() => {
                window.scrollTo(0, 900);
                setCurrentDate(day.value);
              }}
              className={handleDayClassname(day)}
            >
              {day.value !== null ? day.value : null}
            </div>
          )
        )}
      </StyledCalendarDay>
    </StyledCalendarBody>
  );
};

export {CalendarBody};
