import {yupResolver} from "@hookform/resolvers/yup";
import {Space, Button, message} from "antd";
import {useForm} from "react-hook-form";
import {useUpdatePassword} from "../../../react-query-hooks/settings";
import {CustomInputComponent} from "../../input/input-component";
import {StyledEmailUpdateFormContainer} from "../email-update-form/email-update-form.style";
import {IPasswordUpdateFormValues} from "./i-password-update-form";
import {passwordUpdatechema} from "./password-update-form-schema";

const PasswordUpdateForm = () => {
  const updatePasswordQueryMutation = useUpdatePassword();

  const {
    handleSubmit,
    formState: {errors},
    watch,
    reset,
    control,
  } = useForm<IPasswordUpdateFormValues>({
    resolver: yupResolver(passwordUpdatechema),
    mode: "onChange",
  });

  const onSubmit = async (values: IPasswordUpdateFormValues) => {
    updatePasswordQueryMutation.mutate(values, {
      onSuccess: () => {
        message.success("Password changed successfully!");
        reset();
      },
    });
  };
  return (
    <div>
      <StyledEmailUpdateFormContainer>
        <h2 className="email-heading-text">Password </h2>
        <p className="email-heading-sub-text">
          Confirm your current password before setting a new one. 8 characters minimum
        </p>
      </StyledEmailUpdateFormContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: "2.4rem"}}>
        <Space direction="vertical" size={24} style={{display: "flex"}}>
          <CustomInputComponent
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder="Current password"
            error={errors.oldPassword?.message}
            control={control}
          />
          <CustomInputComponent
            id="currentPassword"
            name="currentPassword"
            type="password"
            placeholder="New password"
            error={errors.currentPassword?.message}
            control={control}
          />
          <div>
            <Button
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              loading={updatePasswordQueryMutation.isLoading}
              size="large"
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Save changes
            </Button>
          </div>
        </Space>
      </form>
    </div>
  );
};

export {PasswordUpdateForm};
