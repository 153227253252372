import {Alert, Button, Col, Modal, Row, Space, Spin, Table} from "antd";
import {
  TCustomerEvent,
  useGetPastLiveClassesCustomer,
} from "../../../../react-query-hooks/live-events/live-classes";
import {FullPageLoader} from "../../../full-page-loader";
import {
  StyledDescriptionText,
  StyledNoUpcomingCallBox,
} from "../../../calls/upcoming-calls/upcoming-calls.style";
// @ts-ignore
import Truncate from "react-truncate-html";
import {ColumnsType} from "antd/lib/table";
import {useState} from "react";
import {StyledInfoItem} from "../../../customers/details-page/info-section/info-section.style";
import {StyledModalContentWrapper} from "../../../calls/upcoming-calls/call-card/call-card.style";
import {LoadingSpinner} from "../../../icons/icons";
import {CustomPagination} from "../../../pagination";

interface DataType {
  key: string;
  name: string;
  price: string;
  description: string;
}

const CustomerPastLiveClasses = () => {
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<TCustomerEvent>();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getLiveClassesQuery = useGetPastLiveClassesCustomer(page);
  if (getLiveClassesQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (
    getLiveClassesQuery.isError &&
    getLiveClassesQuery.error.message !==
      "Sorry, You currently do not have any live events!"
  ) {
    // console.log({failure: getLiveClassesQuery.error});
    return (
      <Alert
        message="Error"
        description={getLiveClassesQuery.error.message}
        type="error"
        showIcon
      />
    );
  }

  let pastClassData: DataType[] = [];

  if (getLiveClassesQuery.data) {
    pastClassData = getLiveClassesQuery.data.data.events.map((pastClass) => ({
      key: pastClass.id.toString(),
      name: pastClass.liveEvent.name,
      description: pastClass.liveEvent.description,
      price:
        pastClass.liveEvent.callPrice === 0
          ? "Free"
          : pastClass.liveEvent.callPriceCurrency +
            " " +
            pastClass.liveEvent.callPrice.toLocaleString("en-US"),
    }));
  }

  const showModal = (id: string) => {
    const modalContent = getLiveClassesQuery?.data?.data.events.find(
      (data) => data.id === +id
    );
    if (modalContent) {
      setModalContent(modalContent);
      setIsModalOpen(true);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: "17.425rem",
      key: "name",
      render: (text) => (
        <>
          <StyledDescriptionText style={{textTransform: "capitalize"}}>
            {text}
          </StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: "17.425rem",
      key: "price",
      render: (text) => (
        <>
          <StyledDescriptionText>{text}</StyledDescriptionText>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "17.425rem",
      key: "description",
      render: (text) => (
        <>
          <StyledDescriptionText dangerouslySetInnerHTML={{__html: text}} />
        </>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => showModal(record.key)}>
          View details
        </Button>
      ),
    },
  ];
  return (
    <Space direction="vertical" size={29} style={{display: "flex"}}>
      {getLiveClassesQuery.data && getLiveClassesQuery.data.data.events.length > 0 ? (
        <>
          <Table
            columns={columns}
            dataSource={pastClassData}
            loading={getLiveClassesQuery.isFetching}
            scroll={{x: 100}}
            pagination={false}
          />
          <CustomPagination
            isPreviousData={getLiveClassesQuery.isPreviousData}
            page={page}
            paginationMeta={getLiveClassesQuery.data.meta}
            setPage={setPage}
          />
        </>
      ) : (
        <Spin
          spinning={getLiveClassesQuery.isFetching}
          tip="Updating..."
          indicator={LoadingSpinner}
        >
          <div style={{width: "100%"}}>
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                You do not have any past live class at the moment.
              </p>
            </StyledNoUpcomingCallBox>
          </div>
        </Spin>
      )}
      <Modal
        title={modalContent?.liveEvent.name}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <StyledModalContentWrapper>
          <Row gutter={[32, 20]}>
            <Col span={24} md={8}>
              <StyledInfoItem>
                <p className="info-title">Name</p>
                <p className="info-value">{modalContent?.liveEvent.name}</p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={8}>
              <StyledInfoItem>
                <p className="info-title">Price</p>
                <p className="info-value">
                  {modalContent?.liveEvent.callPrice === 0
                    ? "Free"
                    : modalContent?.liveEvent.callPriceCurrency +
                      " " +
                      modalContent?.liveEvent.callPrice.toLocaleString("en-US")}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24} md={8}>
              <StyledInfoItem>
                <p className="info-title">Attendees</p>
                <p className="info-value">
                  {modalContent?.liveEvent.attendees === -1
                    ? "Unlimted"
                    : modalContent?.liveEvent.attendees}
                </p>
              </StyledInfoItem>
            </Col>
            <Col span={24}>
              <StyledInfoItem>
                <p className="info-title">Description</p>
                <div
                  className="info-value"
                  dangerouslySetInnerHTML={{
                    __html: modalContent?.liveEvent.description ?? "No description",
                  }}
                />
              </StyledInfoItem>
            </Col>
          </Row>
        </StyledModalContentWrapper>
      </Modal>
    </Space>
  );
};

export {CustomerPastLiveClasses};
