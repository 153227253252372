import {Space} from "antd";
import {TermsLastRevised} from "../terms/last-revised";
import {PrivacyPolicySubHeading} from "./sub-heading";
import {PrivacyPolicyList} from "./privacy-policy-list";

export function PrivacyPolicyInfo() {
  return (
    <div>
      <Space size={40} direction="vertical">
        <PrivacyPolicySubHeading />
        <TermsLastRevised />
        <PrivacyPolicyList />
      </Space>
    </div>
  );
}
