import axios from "axios";
import {API_BASE_URL} from "../../services/config";

// Verify Username
export const verifyUsernameService = async (username: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_BASE_URL + "username", {username}, config);

  return response.data;
};
