import {Button, Dropdown} from "antd";
import {Dispatch, SetStateAction} from "react";
import {Link} from "react-router-dom";
import {AnalyticsEvent} from "../../../../utils/analytics";
import {
  MobileNavContainer,
  StyledMobileNavbarMenuList,
  StyledMobileNavbarMenuListItem,
  StyledMobileNavLinkItem,
  StyledMobileNavLinkItemCTA,
} from "./mobile-nav.style";
import {menu} from "..";
import {DownOutlined} from "@ant-design/icons";

type MobileNavProps = {
  menuActive: boolean;
  setNavIconClicked: Dispatch<SetStateAction<boolean>>;
};

const MobileNav: React.FC<MobileNavProps> = ({menuActive, setNavIconClicked}) => {
  const handleClick = () => {
    setNavIconClicked(!menuActive);
  };
  return (
    <MobileNavContainer menuActive={menuActive}>
      <StyledMobileNavbarMenuList>
        <StyledMobileNavbarMenuListItem
          onClick={() => AnalyticsEvent("Home Mobile Navbar", `Clicked on pricing`)}
        >
          <StyledMobileNavLinkItem to="/home/pricing">Pricing</StyledMobileNavLinkItem>
        </StyledMobileNavbarMenuListItem>
        <StyledMobileNavbarMenuListItem
          onClick={() => AnalyticsEvent("Home Mobile Navbar", `Clicked on contact`)}
        >
          <StyledMobileNavLinkItem to="/home/contact">Contact</StyledMobileNavLinkItem>
        </StyledMobileNavbarMenuListItem>

        <StyledMobileNavbarMenuListItem
          onClick={() => AnalyticsEvent("Home Mobile Navbar", `Clicked on blog`)}
        >
          <a
            className="navbar-menu-list-item-link"
            href="https://medium.com/coachli"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
        </StyledMobileNavbarMenuListItem>
        <StyledMobileNavbarMenuListItem
          onClick={() => AnalyticsEvent("Home Navbar", `Clicked on live events`)}
        >
          <StyledMobileNavLinkItem to="/home/live-events">
            Live events
          </StyledMobileNavLinkItem>
        </StyledMobileNavbarMenuListItem>
        <StyledMobileNavbarMenuListItem
          onClick={() =>
            AnalyticsEvent("Home Mobile Navbar", `Clicked on Join our community`)
          }
        >
          <a
            className="navbar-menu-list-item-link"
            href="https://join.slack.com/t/coachli-community/shared_invite/zt-1mkjrwhk4-dFmunoxUIr3Rfrn4D3MgBw"
            target="_blank"
            rel="noreferrer"
          >
            Join our community
          </a>
        </StyledMobileNavbarMenuListItem>
        <StyledMobileNavbarMenuListItem>
          <StyledMobileNavLinkItemCTA
            onClick={() =>
              AnalyticsEvent("Home Mobile Navbar", `Clicked on Create a free account`)
            }
          >
            <Link to="/auth/register" style={{color: "#fff"}}>
              <Button shape="round" size="large">
                Create a free account
              </Button>
            </Link>
          </StyledMobileNavLinkItemCTA>
          <StyledMobileNavLinkItemCTA
            onClick={() => AnalyticsEvent("Home Mobile Navbar", `Clicked on Login`)}
          >
            {/* <Button
              href="https://join.slack.com/t/coachli-community/shared_invite/zt-1mkjrwhk4-dFmunoxUIr3Rfrn4D3MgBw"
              target="_blank"
              rel="noreferrer"
              shape="round"
              type="primary"
              size="large"
            >
              Join our community
            </Button> */}
            <Dropdown overlay={menu} forceRender trigger={["click", "hover"]}>
              <Button
                // target="_blank"
                // rel="noreferrer"
                shape="round"
                type="primary"
                size="large"
              >
                Log in <DownOutlined />
              </Button>
            </Dropdown>
          </StyledMobileNavLinkItemCTA>
        </StyledMobileNavbarMenuListItem>
      </StyledMobileNavbarMenuList>
    </MobileNavContainer>
  );
};

export {MobileNav};
