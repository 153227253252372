import {CustomerChatList} from "../../chat-list/customer";
import {CustomerMessageCustomerList} from "../../message-customer-list/customer";
import {StyledMessageListBox} from "../message-list.style";

const CustomerMessageList = () => {
  return (
    <StyledMessageListBox>
      <CustomerMessageCustomerList />
      <CustomerChatList />
    </StyledMessageListBox>
  );
};

export {CustomerMessageList};
