import {PropsWithChildren} from "react";
import {createContext} from "../create-context";
import {FullPageLoader} from "../../components/full-page-loader";
import {Alert} from "antd";
import {TGetProductApiResponse, useGetProduct} from "../../react-query-hooks/products";
import {useParams} from "react-router-dom";

type TViewProductState = {
  productData: TGetProductApiResponse;
};

type TViewProductContextState = {
  state: TViewProductState;
};

const [useContext, ContextProvider] = createContext<TViewProductContextState>();

export const useViewProductContext = useContext;

export const ViewProductProvider = ({children}: PropsWithChildren) => {
  const {id} = useParams();
  const getProduct = useGetProduct(id);

  if (getProduct.isLoading) {
    return <FullPageLoader />;
  }

  if (getProduct.isError) {
    return (
      <Alert
        message="Error"
        description={getProduct.error.message}
        type="error"
        showIcon
      />
    );
  }

  const state: TViewProductState = {
    productData: getProduct.data,
  };

  return <ContextProvider value={{state}}>{children}</ContextProvider>;
};
