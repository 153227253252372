import {OneToOneOutlined, RetweetOutlined} from "@ant-design/icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, message, Radio} from "antd";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {StyledCallLocationItem} from "../../../../styles/onboarding/onboarding";
import {AnalyticsEvent} from "../../../../utils/analytics";
import {CustomModal} from "../../../modal";
import {
  StyledModalContentInfo,
  StyledModalContentWrapper,
} from "../call-card/call-card.style";

interface ICallCategoryModalProps {
  visibleState: boolean;
  okFunc: () => void;
  cancelFunc: () => void;
}

const schema = yup.object().shape({
  callCategory: yup.string().required().oneOf(["oneOff", "bundle"], "Select a category"),
});

const CallCategoryModal: React.FC<ICallCategoryModalProps> = ({
  cancelFunc,
  okFunc,
  visibleState,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm<{callCategory: "oneOff" | "bundle"}>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      callCategory: "oneOff",
    },
  });

  const onSubmit = (values: {callCategory: "oneOff" | "bundle"}) => {
    const {callCategory} = values;
    if (callCategory === "oneOff") {
      AnalyticsEvent("Service Option Modal", `Selected one off call category`);
      return navigate("/dashboard/calls/new");
    }
    if (callCategory === "bundle") {
      AnalyticsEvent("Service Option Modal", `Selected bundle call category`);
      return navigate("/dashboard/calls/bundle/new");
    }
    message.warn("Select a valid category");
  };
  return (
    <CustomModal
      visibleState={visibleState}
      title="Create a New Service"
      okFunc={okFunc}
      cancelFunc={cancelFunc}
      footerContent={[
        <Button
          disabled={Object.keys(errors).length > 0 || Object.values(watch()).length < 1}
          key="continue"
          shape="round"
          type="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>,
        <Button key="cancel" type="default" shape="round" onClick={() => cancelFunc()}>
          Cancel
        </Button>,
      ]}
    >
      <StyledModalContentWrapper>
        {" "}
        <StyledModalContentInfo>
          <p className="content-text">
            Create a new 1:1 call service, choose between a one off call or a bundle of
            calls with your customers.
          </p>
          <div
            style={{
              marginTop: "2.4rem",
            }}
          >
            <Controller
              control={control}
              name="callCategory"
              render={({field: {onChange, value}}) => (
                <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
                  <StyledCallLocationItem active={watch("callCategory") === "oneOff"}>
                    <div className="icon-container">
                      {/* <img src="/images/video.svg" alt="video icon" /> */}
                      <OneToOneOutlined
                        style={{
                          fontSize: "2.5rem",
                        }}
                      />
                    </div>
                    <div className="loaction-info">
                      <h3 className="title">One off call</h3>
                      <p className="sub-info">
                        One off call with your customer, perfect for initial consultations
                      </p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                      <Radio value={"oneOff"} />
                    </div>
                  </StyledCallLocationItem>
                  <StyledCallLocationItem
                    style={{marginTop: "2.4rem"}}
                    active={watch("callCategory") === "bundle"}
                  >
                    <div className="icon-container">
                      {/* <img src="/images/phone.svg" alt="phone icon" /> */}
                      <RetweetOutlined
                        style={{
                          fontSize: "2.5rem",
                        }}
                      />
                    </div>
                    <div className="loaction-info">
                      <h3 className="title">Coaching bundle</h3>
                      <p className="sub-info">
                        Multiple 1:1 sessions sold as one bundle, perfect for long term
                        coaching where more than one session is required
                      </p>
                    </div>
                    <div style={{marginLeft: "auto"}}>
                      <Radio value={"bundle"} />
                    </div>
                  </StyledCallLocationItem>
                </Radio.Group>
              )}
            />
          </div>
        </StyledModalContentInfo>
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

export {CallCategoryModal};
