import {CalendarOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {Space} from "antd";
import {
  StyledSessionsSection,
  StyledSessionsSectionBox,
  StyledSessionsSectionBoxItem,
} from "./sessions-section.style";
import _ from "lodash";
import moment from "moment";

const SessionsSection = ({
  sessions,
  activeSession,
}: {
  sessions: {
    name?: string;
    startDate: string;
    startTime: string;
    duration: number;
    endTime: string;
  }[];
  activeSession: {
    name?: string;
    startDate: string;
    startTime: string;
    duration: number;
    endTime: string;
  };
}) => {
  return (
    <StyledSessionsSection>
      <h1 className="content-heading">Sessions</h1>
      <div className="content-info-text">
        <StyledSessionsSectionBox>
          {sessions.map((sessionInfo, index) => (
            <StyledSessionsSectionBoxItem key={sessionInfo.startDate + index}>
              {/* <p>{_.isEqual(sessionInfo, activeSession) ? "yES EQUAL" : "nOOO"}</p> */}
              <p className="session-name">{sessionInfo.name}</p>
              <div className="session-box-item-content-wrapper">
                <div className="session-box-item-content">
                  <Space size={10}>
                    <CalendarOutlined style={{color: "#7939CC"}} />
                    <span>
                      {moment(sessionInfo.startDate).format("ddd, MMM DD YYYY")}
                    </span>
                  </Space>
                </div>
                <div className="session-box-item-content">
                  <Space size={10}>
                    <ClockCircleOutlined style={{color: "#7939CC"}} />
                    <span>
                      {sessionInfo.startTime} - {sessionInfo.endTime}
                    </span>
                  </Space>
                </div>
              </div>
            </StyledSessionsSectionBoxItem>
          ))}
        </StyledSessionsSectionBox>
      </div>
    </StyledSessionsSection>
  );
};

export {SessionsSection};
