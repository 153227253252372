import {usePaystackPayment} from "react-paystack";
import {useCallback, useEffect} from "react";
import {AnalyticsEvent} from "../../../../../utils/analytics";
import {FullPageLoader} from "../../../../../components/full-page-loader";
import {PaystackProps} from "react-paystack/dist/types";
import {useNavigate} from "react-router-dom";

export type TPayStackPaymentStepProps = {
  updateStep: (step: number) => void;
  psConfigParams: PaystackProps;
  successRedirectLink: string;
  handleCloseModal: () => void;
};

const NewPayWithPaystack = ({
  updateStep,
  psConfigParams,
  successRedirectLink,
  handleCloseModal,
}: TPayStackPaymentStepProps) => {
  const navigate = useNavigate();

  // you can call this function anything
  const onSuccess = useCallback(() => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    AnalyticsEvent("Paystack payment", "Paystack payment success");
    navigate(successRedirectLink);
    handleCloseModal();
    // resetState();
  }, [handleCloseModal, navigate, successRedirectLink]);

  // you can call this function anything
  const onClose = useCallback(() => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    AnalyticsEvent("Paystack payment", "Closed paystack payment modal");
    updateStep(1);
  }, [updateStep]);

  const initializePayment = usePaystackPayment(psConfigParams);

  useEffect(() => {
    initializePayment(onSuccess, onClose);
  }, [initializePayment, onClose, onSuccess]);

  return (
    <div style={{height: "50vh"}}>
      <FullPageLoader />
    </div>
  );
};

export {NewPayWithPaystack};
