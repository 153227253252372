import {Result, Space} from "antd";
import {Footer} from "../../../../components/public-profile/footer";
import {
  StyledPublicProfileContainer,
  StyledPublicProfileLiveClassesDetailsHeading,
  StyledPublicProfileLiveClassesDetailsImageWrapper,
} from "../../../../styles/public-profile/public-profile";
import {useEffect} from "react";
import {PublicProfileNavbar} from "../../../../components/public-profile/navbar";
import {useProductPurchaseContext} from "../../../../context/products/product-purchase-context";
import {ProductPurchaseModal} from "../../../../components/public-profile/offering/product-details/purchase-modal";
import {ProductMainDetailsSection} from "../../../../components/public-profile/digital-products-section/details/main-details-section";

const AnotherPublicProfileProductsDetailsPage = () => {
  const {state} = useProductPurchaseContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!state.productData) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={"You are missing some parameters!"}
      />
    );
  }
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <div style={{padding: "2rem 2rem 20.4rem 2rem"}}>
        <StyledPublicProfileContainer style={{maxWidth: "120.2rem"}}>
          <PublicProfileNavbar
            avatarUrl={state.productData.user.avatar}
            firstName={state.productData.user.firstName}
            lastName={state.productData.user.lastName}
            username={state.productData.profile.username}
          />
          <Space
            direction="vertical"
            size={35}
            style={{
              display: "flex",
            }}
          >
            <StyledPublicProfileLiveClassesDetailsHeading>
              {state.productData.product.title}
            </StyledPublicProfileLiveClassesDetailsHeading>
            <StyledPublicProfileLiveClassesDetailsImageWrapper>
              <img
                src={state.productData.product.coverImage}
                alt={state.productData.product.title}
                className="banner-image"
              />
            </StyledPublicProfileLiveClassesDetailsImageWrapper>
            <ProductMainDetailsSection productData={state.productData} />
          </Space>
          <ProductPurchaseModal />
        </StyledPublicProfileContainer>
      </div>
      <Footer />
    </div>
  );
};

export {AnotherPublicProfileProductsDetailsPage};
