import {Button, Spin, Upload, message} from "antd";
import {
  StyledCoverImageWrapper,
  StyledInputLabelWrapper,
} from "../../../../input/input.style";
import ImgCrop from "antd-img-crop";
import {UploadOutlined} from "@ant-design/icons";
import {API_BASE_URL} from "../../../../../services/config";
import {RootState} from "../../../../../redux/store";
import {useSelector} from "react-redux";
import type {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import type {UploadChangeParam} from "antd/es/upload";
import {
  Control,
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {IServiceFormValues} from "../i-service-form";
import {useState} from "react";

export type TServiceInputProps = {
  control: Control<IServiceFormValues, any>;
  watch: UseFormWatch<IServiceFormValues>;
  errors: FieldErrorsImpl<IServiceFormValues>;
  setError: UseFormSetError<IServiceFormValues>;
  clearErrors: UseFormClearErrors<IServiceFormValues>;
  setValue: UseFormSetValue<IServiceFormValues>;
};

type TServiceCoverImageUploadProps = TServiceInputProps & {
  cover_image_url?: string | null;
};

export function ServiceCoverImageUpload({
  clearErrors,
  errors,
  setError,
  setValue,
  cover_image_url,
}: TServiceCoverImageUploadProps) {
  const {userInfo} = useSelector((state: RootState) => state.userLogin);
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "pending" | "resolved" | "rejected"
  >("idle");
  const beforeUpload = async (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isLt5M;
  };
  const handleImageChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // console.log(info);
    if (info.file.status === "uploading") {
      setUploadStatus("pending");
      return;
    }
    if (info.file.status === "error") {
      setUploadStatus("rejected");
      setError("cover_image", info.file.response.message);
      message.error(info.file.response.message);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      setUploadStatus("resolved");
      clearErrors("cover_image");
      const imgUrl = info.file.response.data.url;
      setValue("cover_image", imgUrl);
      message.success("Your cover image has been uploaded successfully!");
    }
  };
  const onImageRemove = (file: UploadFile) => {
    clearErrors("cover_image");
  };
  return (
    <div>
      <StyledInputLabelWrapper>
        <label id="cover-image-id" htmlFor="cover-image">
          Cover image
        </label>
      </StyledInputLabelWrapper>
      <Spin spinning={uploadStatus === "pending"}>
        <StyledCoverImageWrapper>
          <ImgCrop rotationSlider aspect={1.9}>
            <Upload
              name="cover_image"
              // showUploadList={false}
              headers={{
                authorization: `Bearer ${userInfo.accessToken}`,
              }}
              action={`${API_BASE_URL}services/banner/upload`}
              onChange={handleImageChange}
              beforeUpload={beforeUpload}
              method="POST"
              listType="picture"
              maxCount={1}
              onRemove={onImageRemove}
              defaultFileList={
                cover_image_url
                  ? [
                      {
                        uid: "1",
                        name: "cover_image",
                        status: "done",
                        url: `${cover_image_url ?? ""}`,
                      },
                    ]
                  : undefined
              }
            >
              <Button
                // onClick={handleUplpoadButtonClick}
                icon={<UploadOutlined />}
                size="large"
                type="primary"
              >
                Upload image
              </Button>
            </Upload>
          </ImgCrop>
          <p style={{textAlign: "center"}}>
            Images should be horizontal, at least 1280x720px
          </p>
        </StyledCoverImageWrapper>
      </Spin>
      {errors.cover_image?.message && (
        <div role="alert" className="ant-form-item-explain-error">
          {errors.cover_image?.message}
        </div>
      )}
    </div>
  );
}
