import {Space} from "antd";
import {OfferingTitle} from "../../offering-title";
import {PublicProfileOfferingImage} from "../../offering-image";
import {ServiceDescription} from "./description";
import {ServiceTimeInfo} from "./time-info";
import {ServiceTimezoneInfo} from "./timezone-info";
import {ServiceCurrencyInfo} from "./currency-info";
import {useServiceBookingContext} from "../../../../../context/service-booking/service-booking.context";

export function ServiceInfo() {
  const {state} = useServiceBookingContext();

  if (!state.serviceData) {
    return <div></div>;
  }
  return (
    <div>
      <Space size={24} direction="vertical" style={{display: "flex"}}>
        <OfferingTitle title={state.serviceData.service.name} />
        {state.serviceData.service.cover_picture && (
          <PublicProfileOfferingImage
            src={state.serviceData.service.cover_picture}
            alt={state.serviceData.service.description}
          />
        )}

        <ServiceDescription />
        <ServiceTimeInfo />
        <ServiceTimezoneInfo />
        <ServiceCurrencyInfo serviceCurrencies={state.serviceData.service.currencies} />
      </Space>
    </div>
  );
}
