import {Avatar, Space} from "antd";
import {OfferingTitle} from "../../../public-profile/offering/offering-title";
import {PublicProfileOfferingImage} from "../../../public-profile/offering/offering-image";
import {StyledPublicProfileOfferingMainSectionWrapper} from "../../../public-profile/offering/offering.style";
import {ShareOfferingWidget} from "../../../public-profile/offering/share-offering";
import {StyledLiveClassInfoWidget} from "../../../public-profile/offering/product-details/widget/widget-info.style";
import {DollarOutlined, UserOutlined} from "@ant-design/icons";
import {MainProductDetails} from "./about-section";
import {TGetCustomerProductApiResponse} from "../../../../react-query-hooks/products";
import {StyledLiveClassesHostInfoWidget} from "../../../public-profile/live-classes-section/details/main-details-section.style";
import {
  handleFacebookLink,
  handleMailLink,
  handleProductLink,
  handleTwitterLink,
} from "../../../../utils/products";

type TCustomerProductDetails = {
  productData: TGetCustomerProductApiResponse;
};

export function CustomerProductDetails({productData}: TCustomerProductDetails) {
  const extraParams = {
    facebookLink: handleFacebookLink({
      username: productData.coach.profile.username,
    }),
    twitterLink: handleTwitterLink({
      textContent: `I just purchased a product by ${productData.coach.firstName} ${productData.coach.lastName} titled ${productData.product.title} on Coachli!`,
      username: productData.coach.profile.username,
    }),
    mailLink: handleMailLink({
      body: `I just purchased a product by ${productData.coach.firstName} ${productData.coach.lastName} titled ${productData.product.title} on Coachli! ${window.location.origin}/${productData.coach.profile.username}`,
      subject: "",
    }),
    copyLink: handleProductLink({
      productId: productData.product.id,
      slug: productData.product.slug,
      username: productData.coach.profile.username,
      windowNav: true,
    }),
  };
  return (
    <div>
      <Space
        direction="vertical"
        size={16}
        style={{
          display: "flex",
        }}
      >
        <PublicProfileOfferingImage
          src={productData.product.coverImage}
          alt={productData.product.title}
        />
        <OfferingTitle title={productData.product.title} />
        <StyledPublicProfileOfferingMainSectionWrapper>
          <div className="offering-main-details-info">
            <MainProductDetails
              mediaLogs={productData.mediaLogs}
              productDescription={productData.product.description}
            />
          </div>
          <div className="offering-widget-info-content" style={{top: "2rem"}}>
            <div className="widget-flex">
              <div>
                <Space size={16} direction="vertical" style={{display: "flex"}}>
                  <h2 style={{fontSize: "2rem", fontWeight: 700}}>Price</h2>
                  <StyledLiveClassInfoWidget>
                    <div>
                      <div className="offering-content-item ">
                        <Space size={10}>
                          <DollarOutlined style={{color: "#7939CC"}} />
                          {productData.mediaLogs[0].amount < 1 ? (
                            <span className="booking-info-content-item-value">Free</span>
                          ) : (
                            <span className="booking-info-content-item-value">
                              {`${productData.mediaLogs[0].currency} ${productData.mediaLogs[0].amount}`}
                            </span>
                          )}
                        </Space>
                      </div>
                    </div>
                  </StyledLiveClassInfoWidget>
                </Space>
              </div>
              <StyledLiveClassesHostInfoWidget>
                <h2 className="host-heading-text">Created by</h2>
                <div className="host-info-wrapper">
                  <div className="host-avatar-wrapper">
                    {productData.coach.avatar ? (
                      <Avatar size={87} src={productData.coach.avatar} />
                    ) : (
                      <Avatar size={87} icon={<UserOutlined />} />
                    )}
                  </div>
                  <div className="host-name-info-wrapper">
                    <p className="host-name">{`${productData.coach.firstName} ${productData.coach.lastName}`}</p>
                    <p className="host-role">{`${productData.coach.profile.shortBio}`}</p>
                  </div>
                </div>
              </StyledLiveClassesHostInfoWidget>
              <ShareOfferingWidget title="Share product via" extraParams={extraParams} />
            </div>
          </div>
        </StyledPublicProfileOfferingMainSectionWrapper>
      </Space>
    </div>
  );
}
