import axios from "axios";
import {API_BASE_URL} from "../../services/config";

// Get user timezone
export const getTimezoneService = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_BASE_URL + "timezone", config);

  return response.data;
};

// update user timezone
export const updateTimezoneService = async (timezone: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_BASE_URL + "timezone",
    {timeZone: timezone},
    config
  );

  return response.data;
};
