import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Input, message, Spin} from "antd";
import {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchAccountBalance,
  fetchPayouts,
  fetchPendingPayout,
  withdrawRequest,
} from "../../../redux/payout/payout-slice";
import {AppDispatch, RootState} from "../../../redux/store";
import {StyledModalContentWrapper} from "../../calls/upcoming-calls/call-card/call-card.style";
import {CustomModal} from "../../modal";
import {
  StyledBalanceInfoBox,
  StyledBalanceInfoBoxWrapper,
  StyledBalanceSectionWrapper,
} from "./balance-section.style";
import {TWithdrawFormValues} from "./i-balance";
import {withdrawFormSchema} from "./withdraw-form-validation-schema";
import {TBank} from "../../../react-query-hooks/payout";

const BalanceSection = ({
  connectedBank,
  hasConnectedBank,
}: {
  connectedBank?: TBank;
  hasConnectedBank: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openWithrawModal, setOpenWithdrawModal] = useState<boolean>(false);
  const {
    fetchAccountBalanceStatus,
    accountBalance,
    withdrawRequestStatus,
    message: withdrawalErrorMessage,
    pendingPayout,
    fetchPendingPayoutRequestStatus,
  } = useSelector((state: RootState) => state.userPayout);
  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // console.log({accountBalance});
  const {
    handleSubmit,
    formState: {errors},
    watch,
    control,
    reset,
  } = useForm<TWithdrawFormValues>({
    resolver: yupResolver(withdrawFormSchema),
    mode: "onChange",
  });
  const handleCancel = () => {
    // dispatch(payoutLogoutReset());
    reset();
    setOpenWithdrawModal(false);
  };
  const handleOk = useCallback(() => {
    reset();
    setOpenWithdrawModal(false);
  }, [reset]);

  const showWithdrawModal = () => {
    setOpenWithdrawModal(true);
  };

  useEffect(() => {
    dispatch(fetchAccountBalance(null));
    dispatch(fetchPendingPayout(null));
  }, [dispatch]);

  useEffect(() => {
    if (withdrawRequestStatus === "resolved") {
      message.success(
        "Your request has been submitted successfully! Your proceeds would be deposited to your bank within 24 hours"
      );
      dispatch(fetchPayouts(null));
      dispatch(fetchAccountBalance(null));
      dispatch(fetchPendingPayout(null));
      handleOk();
    }
    if (withdrawRequestStatus === "rejected") {
      message.error(withdrawalErrorMessage);
      // handleOk();
    }
  }, [dispatch, handleOk, withdrawRequestStatus, withdrawalErrorMessage]);

  useEffect(() => {
    if (fetchPendingPayoutRequestStatus === "rejected") {
      message.error(withdrawalErrorMessage);
    }
  }, [fetchPendingPayoutRequestStatus, withdrawalErrorMessage]);

  const onSubmit = async (values: TWithdrawFormValues) => {
    // console.log({values});
    dispatch(withdrawRequest(values));
  };

  return (
    <Spin
      spinning={
        fetchAccountBalanceStatus === "pending" ||
        fetchPendingPayoutRequestStatus === "pending"
      }
    >
      <StyledBalanceSectionWrapper>
        <h2 className="balance-section-title">Your Balance 💰</h2>
        <StyledBalanceInfoBoxWrapper>
          <StyledBalanceInfoBox>
            <p>
              Once a withdrawal request is submitted, the money will be sent to your bank
              account within 24 hours.{" "}
            </p>

            <div className="balance-info-box-bottom">
              <div>
                <p className="payout-pending">Pending payout</p>
                <p className="payout-pending-value">
                  {connectedBank?.currency ?? ""}{" "}
                  {numberWithCommas(Math.round(Number(pendingPayout)))}
                </p>
              </div>
              <div>
                <p className="payout-ready">Ready to withdraw</p>
                <p className="payout-ready-value">
                  {(connectedBank?.currency ?? "") +
                    " " +
                    numberWithCommas(Math.round(Number(accountBalance.balance)))}
                </p>
              </div>
              <div>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  disabled={
                    hasConnectedBank === false || (accountBalance.balance as number) < 99
                  }
                  block
                  onClick={showWithdrawModal}
                >
                  Withdraw funds
                </Button>
              </div>
            </div>
          </StyledBalanceInfoBox>
        </StyledBalanceInfoBoxWrapper>
        <CustomModal
          visibleState={openWithrawModal}
          title="Withdraw your earnings into your account"
          okFunc={handleOk}
          cancelFunc={handleCancel}
          footerContent={[
            <Button key="cancel" shape="round" type="default" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="save"
              type="primary"
              shape="round"
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(errors).length > 0 || Object.values(watch()).length < 1
              }
              loading={withdrawRequestStatus === "pending"}
            >
              Withdraw earnings
            </Button>,
          ]}
        >
          <>
            <StyledModalContentWrapper>
              <p>
                Your earnings will be sent to:
                <br />
                {connectedBank?.bank_name} - {connectedBank?.account_number} -{" "}
                {connectedBank?.account_name}
              </p>
              <div style={{margin: "3.3rem 0"}}>
                {/* <Space direction="vertical" size={32} style={{display: "flex"}}> */}
                <p>Amount:</p>
                <Controller
                  control={control}
                  name="amount"
                  render={({field: {onChange, value}}) => (
                    <>
                      {/* <StyledInputLabelWrapper>
                        <label id="amount" htmlFor="amount">
                          <span style={{color: "#f5222d", marginRight: ".8rem"}}>*</span>{" "}
                          <span>Account number</span>
                        </label>
                      </StyledInputLabelWrapper> */}
                      <Input
                        id="amount"
                        status={errors.amount?.message && "error"}
                        size="large"
                        placeholder="Enter amount"
                        // disabled={
                        //   !watch("countryCode") ||
                        //   !watch("bankCode") ||
                        //   watch("accountNumber").length === 10
                        // }
                        onChange={onChange}
                        value={value}
                      />
                      {errors.amount?.message && (
                        <div role="alert" className="ant-form-item-explain-error">
                          {errors.amount?.message}
                        </div>
                      )}
                    </>
                  )}
                />
                {connectedBank?.country_name.toLowerCase() === "nigeria" && (
                  <p>NGN 50.00 will be deducted from your withdrawal amount</p>
                )}
                {/* </Space> */}
              </div>
            </StyledModalContentWrapper>
          </>
        </CustomModal>
      </StyledBalanceSectionWrapper>
    </Spin>
  );
};

export {BalanceSection};
