export const staggerChildren = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

export const wordAnimation = {
  initial: {
    y: 100,
  },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};
export const featureAnimation = {
  initial: {
    y: 500,
    opacity: 1,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};

export const riseWithFade = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 0.7,
      delay: 1.3,
    },
  },
};
export const reveal = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 0.7,
      delay: 1.3,
    },
  },
};

export const imageAnimation = {
  initial: {
    y: 100,
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};

export const leftAnimation = {
  initial: {
    x: -1000,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};

export const draw = {
  hidden: {pathLength: 0, opacity: 0},
  visible: () => {
    const delay = 1 * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: {delay, type: "spring", duration: 1.5, bounce: 0},
        opacity: {delay, duration: 0.01},
        repeat: Infinity,
      },
    };
  },
};

export const getRandomTransformOrigin = () => {
  const value = (16 + 40 * Math.random()) / 100;
  const value2 = (15 + 36 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2,
  };
};

const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

const randomDuration = () => Math.random() * 0.07 + 0.23;

export const shakeVariants = {
  animate: {
    // rotate: [1, -1.4, 0],
    scale: 1.1,
    transition: {
      // delay: getRandomDelay(),
      repeat: Infinity,
      // duration: randomDuration(),
      duration: 2,
      // repeatType: "",
      // repeatDelay: 1,
    },
  },
  // transition: {
  //   repeat: Infinity,
  //   repeatType: "reverse",
  //   duration: 2,
  // },
};
