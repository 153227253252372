import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {message} from "antd";
import {AxiosError} from "axios";
import {TUnavailabilityApiResponse} from "../redux/unavailability/i-unavailability-form";
import {AnalyticsEvent} from "../utils/analytics";
import {useClient} from "../utils/hooks";
export type TAvailabilityApiResponse = {
  sun: {from: string; to: string}[];
  mon: {from: string; to: string}[];
  tue: {from: string; to: string}[];
  wed: {from: string; to: string}[];
  thu: {from: string; to: string}[];
  fri: {from: string; to: string}[];
  sat: {from: string; to: string}[];
};

export type TUnavailabilityPayload = {
  selections: {
    from: string;
    to: string;
  }[];
};

const useGetAvailability = () => {
  const client = useClient();

  return useQuery<
    TAvailabilityApiResponse,
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["availability"],
    queryFn: () => client(`availability`).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

const useGetUnavailability = () => {
  const client = useClient();

  return useQuery<
    TUnavailabilityApiResponse[],
    AxiosError<{status: boolean; message: string}>
  >({
    queryKey: ["unavailability"],
    queryFn: () => client(`unavailability`).then((data) => data.data),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

const useUpdateAvailability = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TAvailabilityApiResponse) =>
      client(`availability`, {
        method: "POST",
        data: payload,
      }),
    onMutate: (values) => {
      queryClient.cancelQueries(["availability"]);

      const oldAvailability = queryClient.getQueryData(["availability"]);
      queryClient.setQueryData(["availability"], values);

      return () => queryClient.setQueryData(["availability"], oldAvailability);
    },
    onError: (error: AxiosError<{message: string}>, values, rollback) => {
      message.error(error.message);
      AnalyticsEvent("Availability", "Availability Update Fail");
      if (typeof rollback === "function") {
        rollback();
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["availability"], data);
      AnalyticsEvent("Availability", "Availability Update Success");
      message.success("Your availability preferences has been saved successfully");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["availability"]}),
  });
};

const useUpdateUnavailability = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: TUnavailabilityPayload) =>
      client(`unavailability`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Unavailability", "Unavailability Update Fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Unavailability", "Unavailability Update Success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["unavailability"]}),
  });
};
const useAddBufferTime = () => {
  const queryClient = useQueryClient();
  const client = useClient();
  return useMutation({
    mutationFn: (payload: {bufferTime: number}) =>
      client(`booking/buffer-time`, {
        method: "POST",
        data: payload,
      }),
    onError: (error: AxiosError<{message: string}>) => {
      message.error(error.message);
      AnalyticsEvent("Buffer time", "Buffer time Update Fail");
    },
    onSuccess: () => {
      AnalyticsEvent("Buffer time", "Buffer time Update Success");
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ["profile"]}),
  });
};

export {
  useGetAvailability,
  useUpdateAvailability,
  useGetUnavailability,
  useUpdateUnavailability,
  useAddBufferTime,
};
