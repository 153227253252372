import {PropsWithChildren} from "react";
import {StyledTermsListItemHeading} from "./terms-list-item.style";
import {Space} from "antd";

type TermsListItemProps = {
  title: string;
};

export function TermsListItem({children, title}: PropsWithChildren<TermsListItemProps>) {
  return (
    <div>
      <Space direction="vertical" size={20}>
        <StyledTermsListItemHeading>{title}</StyledTermsListItemHeading>
        <div>{children}</div>
      </Space>
    </div>
  );
}
