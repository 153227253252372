import styled from "styled-components";

export const BrandImageStyledContainer = styled.div`
  cursor: pointer;
  width: 16rem;
  height: 5.193rem;
`;

export const BrandImageStyledComponent = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const StyledLogoSvg = styled.svg`
  animation: fill-text 0.5s ease forwards 3.5s;

  path:nth-child(1) {
    stroke-dasharray: 83;
    stroke-dashoffset: 83;
    animation: text-animation 2s ease forwards;
  }
  path:nth-child(2) {
    stroke-dasharray: 89;
    stroke-dashoffset: 89;
    animation: text-animation 2s ease forwards 0.3s;
  }
  path:nth-child(3) {
    stroke-dasharray: 110;
    stroke-dashoffset: 110;
    animation: text-animation 2s ease forwards 0.6s;
  }
  path:nth-child(4) {
    stroke-dasharray: 83;
    stroke-dashoffset: 83;
    animation: text-animation 2s ease forwards 0.9s;
  }
  path:nth-child(5) {
    stroke-dasharray: 108;
    stroke-dashoffset: 108;
    animation: text-animation 2s ease forwards 1.2s;
  }
  path:nth-child(6) {
    stroke-dasharray: 56;
    stroke-dashoffset: 56;
    animation: text-animation 2s ease forwards 1.5s;
  }
  path:nth-child(7) {
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    animation: text-animation 2s ease forwards 1.8s;
  }
`;
