import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
  exportCSVService,
  getCustomerMetricsService,
  getCustomerPrevPurchasesService,
  getCustomerService,
  getCustomersService,
} from "./customer-service";

interface IInitialState {
  customerMetrics: {
    totalUniqueCustomers?: number;
    totalRevenue?: number;
    totalCalls?: number;
    currency?: string;
  };
  customers: {
    id: number;
    userId: number;
    serviceId: number;
    mentor_timezone: string;
    user_timezone: string;
    booking_year: string;
    booking_month: string;
    booking_date: string;
    timeslot: string;
    email_address: string;
    first_name: string;
    last_name: string;
    amount: number;
    roomId: string | null;
    notes: string | null;
    phone_number: string;
    booking_type: string;
    is_bundle: boolean;
    statusId: number;
    reference: string;
    is_paid: boolean;
    bookingQuestions: [] | null;
    statusName: string;
  }[];
  customer: {
    booking?: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
      numberOfPurchases: number;
      totalVolume: number;
    };
  };
  customerPrevPurchases: {
    id: number;
    userId: number;
    serviceId: number;
    mentor_timezone: string;
    user_timezone: string;
    booking_year: string;
    booking_month: string;
    booking_date: string;
    timeslot: string;
    email_address: string;
    first_name: string;
    last_name: string;
    amount: number;
    roomId: string | null;
    notes: string | null;
    phone_number: string;
    booking_type: string;
    is_bundle: boolean;
    statusId: number;
    reference: string;
    is_paid: boolean;
    bookingQuestions: [] | null;
    createdAt: Date;
    statusName: string;
    service: {
      id: number;
      userId: number;
      name: string;
      slug: string;
      description: string;
      status_id: number;
      callDuration: number;
      callPriceCurrency: string;
      callPrice: number;
      hideServiceFromMenu: boolean;
      videoLocation: boolean;
      audioLocation: boolean;
      bookingQuestions: [
        {
          question: string;
          required: boolean;
        }
      ];
      createdAt: Date;
      updatedAt: Date;
      statusName: string;
    };
  }[];
  fetchMetricsStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchCustomersStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchCustomerStatus: "idle" | "pending" | "resolved" | "rejected";
  fetchCustomerPrevPurchasesStatus: "idle" | "pending" | "resolved" | "rejected";
  downloadCSVFileStatus: "idle" | "pending" | "resolved" | "rejected";
  CSVFile: any;
  message: string;
}

const initialState: IInitialState = {
  customerMetrics: {},
  customers: [],
  customer: {},
  customerPrevPurchases: [],
  fetchMetricsStatus: "idle",
  fetchCustomersStatus: "idle",
  fetchCustomerStatus: "idle",
  fetchCustomerPrevPurchasesStatus: "idle",
  downloadCSVFileStatus: "idle",
  CSVFile: null,
  message: "",
};

//Get customer metrics
export const getCustomerMetrics = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("customer/getCustomerMetrics", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getCustomerMetricsService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get customers
export const getCustomers = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("customer/getCustomers", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getCustomersService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get customer details
export const getCustomer = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("customer/getCustomerDetails", async (id, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getCustomerService(id, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get customer previous purchases
export const getCustomerPrevPurchases = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  {mentorId: string; customerEmail: string},
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("customer/getCustomerPrevPurchases", async (payload, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await getCustomerPrevPurchasesService(payload, token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Export customer CSV data
export const exportCSV = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  null,
  {
    // Optional fields for defining thunkApi field types
    state: RootState;
  }
>("customer/exportCSV", async (_, thunkAPI) => {
  try {
    const token: string = thunkAPI.getState().userLogin.userInfo.accessToken;
    return await exportCSVService(token);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetUserCustomerUpadatesState: (state) => {
      state.fetchMetricsStatus = "idle";
      state.fetchCustomersStatus = "idle";
      state.fetchCustomerStatus = "idle";
      state.fetchCustomerPrevPurchasesStatus = "idle";
      state.downloadCSVFileStatus = "idle";
      state.message = "";
    },
    resetUserCustomerState: (state) => {
      state.message = "";
      state.fetchMetricsStatus = "idle";
      state.fetchCustomersStatus = "idle";
      state.fetchCustomerStatus = "idle";
      state.fetchCustomerPrevPurchasesStatus = "idle";
      state.downloadCSVFileStatus = "idle";
      state.customerMetrics = {};
      state.customers = [];
      state.customer = {};
      state.customerPrevPurchases = [];
      state.CSVFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerMetrics.pending, (state) => {
        state.fetchMetricsStatus = "pending";
      })
      .addCase(getCustomerMetrics.fulfilled, (state, action) => {
        state.fetchMetricsStatus = "resolved";
        state.customerMetrics = action.payload.data;
      })
      .addCase(getCustomerMetrics.rejected, (state, action: any) => {
        state.fetchMetricsStatus = "rejected";
        state.message = action.payload;
        state.customerMetrics = {};
      })
      .addCase(getCustomers.pending, (state) => {
        state.fetchCustomersStatus = "pending";
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.fetchCustomersStatus = "resolved";
        state.customers = action.payload.data.transactions;
      })
      .addCase(getCustomers.rejected, (state, action: any) => {
        state.fetchCustomersStatus = "rejected";
        state.message = action.payload;
        state.customers = [];
      })
      .addCase(getCustomer.pending, (state) => {
        state.fetchCustomerStatus = "pending";
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.fetchCustomerStatus = "resolved";
        state.customer = action.payload.data;
      })
      .addCase(getCustomer.rejected, (state, action: any) => {
        state.fetchCustomerStatus = "rejected";
        state.message = action.payload;
        state.customer = {};
      })
      .addCase(getCustomerPrevPurchases.pending, (state) => {
        state.fetchCustomerPrevPurchasesStatus = "pending";
      })
      .addCase(getCustomerPrevPurchases.fulfilled, (state, action) => {
        state.fetchCustomerPrevPurchasesStatus = "resolved";
        state.customerPrevPurchases = action.payload.data.transactions;
      })
      .addCase(getCustomerPrevPurchases.rejected, (state, action: any) => {
        state.fetchCustomerPrevPurchasesStatus = "rejected";
        state.message = action.payload;
        state.customerPrevPurchases = [];
      })
      .addCase(exportCSV.pending, (state) => {
        state.downloadCSVFileStatus = "pending";
      })
      .addCase(exportCSV.fulfilled, (state, action) => {
        state.downloadCSVFileStatus = "resolved";
        state.CSVFile = action.payload;
      })
      .addCase(exportCSV.rejected, (state, action: any) => {
        state.downloadCSVFileStatus = "rejected";
        state.message = action.payload;
      });
  },
});

export const {resetUserCustomerUpadatesState, resetUserCustomerState} =
  customerSlice.actions;

export default customerSlice.reducer;
