import {Button, Result, Tabs, Tag} from "antd";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {FullPageLoader} from "../../components/full-page-loader";
import {CoachInfoSection} from "../../components/public-profile/coach-info-section";
import {Footer} from "../../components/public-profile/footer";
import {ServicesSection} from "../../components/public-profile/services-section";
import {useGetPublicProfile} from "../../react-query-hooks/public-profile";
import {
  StyledPublicProfileContainer,
  StyledPublicProfileCountTag,
  StyledPublicProfileCountTagWrapper,
  StyledPublicProfileWrapper,
} from "../../styles/public-profile/public-profile";
import ReactGA from "react-ga";
import {LiveClassesSection} from "../../components/public-profile/live-classes-section";
import {StyledNoUpcomingCallBox} from "../../components/calls/upcoming-calls/upcoming-calls.style";
import {Helmet} from "react-helmet-async";
import {StyledCoachInfoBannerWrapper} from "../../components/public-profile/coach-info-section/coach-info-section.style";
import {DigitalProductsSection} from "../../components/public-profile/digital-products-section";

const PublicProfile = () => {
  const {username} = useParams();
  const getPublicProfileQuery = useGetPublicProfile(username);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (getPublicProfileQuery.isLoading) {
    return <FullPageLoader />;
  }

  if (getPublicProfileQuery.isError) {
    return (
      <Result
        status="error"
        title="An Error Occured"
        subTitle={getPublicProfileQuery.error.message}
        extra={[
          <Button
            onClick={() => getPublicProfileQuery.refetch()}
            shape="round"
            type="default"
            key="console"
          >
            Try again
          </Button>,
        ]}
      />
    );
  }

  const pageDesc = getPublicProfileQuery.data.shortBio;
  return (
    <div style={{position: "relative", minHeight: "100vh"}}>
      <Helmet>
        <title>
          Coachli |{" "}
          {getPublicProfileQuery.data.firstName +
            " " +
            getPublicProfileQuery.data.lastName}{" "}
        </title>
        <meta name="description" content={pageDesc} />
        <link rel="canonical" href={`/${getPublicProfileQuery.data.username}`} />
        <meta property="og:url" content={`/${getPublicProfileQuery.data.username}`} />
        <meta
          property="og:title"
          content={
            getPublicProfileQuery.data.firstName + getPublicProfileQuery.data.lastName
          }
        />
        <meta property="og:description" content={pageDesc} />
        {/* <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" /> */}
        {/* <meta property="og:image" content={image} />
            <meta name="og:image" content={image} /> */}
        <meta property="og:type" content="website" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="twitter:title"
          content={
            getPublicProfileQuery.data.firstName + getPublicProfileQuery.data.lastName
          }
        />
        <meta name="twitter:description" content={pageDesc} />
        {/* <meta property="twitter:image" content={image} /> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image:alt" content="Coachli Logo" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={`/${getPublicProfileQuery.data.username}`} />
      </Helmet>
      <StyledCoachInfoBannerWrapper>
        <img
          src={
            getPublicProfileQuery.data.cover_image
              ? getPublicProfileQuery.data.cover_image
              : "/images/profile-banner-default.png"
          }
          alt="This is a profile banner"
          className="profile-banner-image"
        />
      </StyledCoachInfoBannerWrapper>
      <StyledPublicProfileWrapper>
        <StyledPublicProfileContainer>
          <CoachInfoSection />
          {(getPublicProfileQuery.data.events === undefined ||
            getPublicProfileQuery.data.events?.length < 1) &&
          getPublicProfileQuery.data.services.length < 1 &&
          getPublicProfileQuery.data.products.length < 1 ? (
            <StyledNoUpcomingCallBox>
              <p className="info-text">
                This profile does not have any offerings at the moment. Check again soon!
              </p>
            </StyledNoUpcomingCallBox>
          ) : (
            <Tabs style={{marginTop: "4rem"}} centered defaultActiveKey="1">
              <Tabs.TabPane
                tab={
                  <StyledPublicProfileCountTagWrapper>
                    All{" "}
                    {getPublicProfileQuery.data.services.length +
                      (getPublicProfileQuery.data.events
                        ? getPublicProfileQuery.data.events.length
                        : 0) +
                      getPublicProfileQuery.data.products.length >
                      0 && (
                      <StyledPublicProfileCountTag>
                        {getPublicProfileQuery.data.services.length +
                          (getPublicProfileQuery.data.events
                            ? getPublicProfileQuery.data.events.length
                            : 0) +
                          getPublicProfileQuery.data.products.length}
                      </StyledPublicProfileCountTag>
                    )}
                  </StyledPublicProfileCountTagWrapper>
                }
                key="1"
              >
                <>
                  {getPublicProfileQuery.data.services.length > 0 && <ServicesSection />}
                  {getPublicProfileQuery.data.events &&
                    getPublicProfileQuery.data.events.length > 0 && (
                      <LiveClassesSection
                        classes={getPublicProfileQuery.data.events}
                        coachTimezone={
                          getPublicProfileQuery.data.coachTimezone.coachli_timezone
                        }
                        username={getPublicProfileQuery.data.username}
                      />
                    )}
                  {getPublicProfileQuery.data.products.length > 0 && (
                    <DigitalProductsSection
                      products={getPublicProfileQuery.data.products}
                      username={getPublicProfileQuery.data.username}
                    />
                  )}
                </>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <StyledPublicProfileCountTagWrapper>
                    1:1 Sessions{" "}
                    {getPublicProfileQuery.data.services.length > 0 && (
                      <StyledPublicProfileCountTag>
                        {getPublicProfileQuery.data.services.length}
                      </StyledPublicProfileCountTag>
                    )}
                  </StyledPublicProfileCountTagWrapper>
                }
                key="2"
              >
                {getPublicProfileQuery.data.services.length > 0 ? (
                  <ServicesSection />
                ) : (
                  <StyledNoUpcomingCallBox>
                    <p className="info-text">
                      This profile does not have any 1:1 session at the moment. Check
                      again soon!
                    </p>
                  </StyledNoUpcomingCallBox>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <StyledPublicProfileCountTagWrapper>
                    Store{" "}
                    {getPublicProfileQuery.data.products.length > 0 && (
                      <StyledPublicProfileCountTag>
                        {getPublicProfileQuery.data.products.length}
                      </StyledPublicProfileCountTag>
                    )}
                  </StyledPublicProfileCountTagWrapper>
                }
                key="3"
              >
                {getPublicProfileQuery.data.products.length > 0 ? (
                  <DigitalProductsSection
                    products={getPublicProfileQuery.data.products}
                    username={getPublicProfileQuery.data.username}
                  />
                ) : (
                  <StyledNoUpcomingCallBox>
                    <p className="info-text">
                      This profile does not have any products at the moment. Check again
                      soon!
                    </p>
                  </StyledNoUpcomingCallBox>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <StyledPublicProfileCountTagWrapper>
                    Live events{" "}
                    {(getPublicProfileQuery.data.events
                      ? getPublicProfileQuery.data.events.length
                      : 0) > 0 && (
                      <StyledPublicProfileCountTag>
                        {getPublicProfileQuery.data.events
                          ? getPublicProfileQuery.data.events.length
                          : 0}
                      </StyledPublicProfileCountTag>
                    )}
                  </StyledPublicProfileCountTagWrapper>
                }
                key="4"
              >
                {getPublicProfileQuery.data.events &&
                getPublicProfileQuery.data.events.length > 0 ? (
                  <LiveClassesSection
                    classes={getPublicProfileQuery.data.events}
                    coachTimezone={
                      getPublicProfileQuery.data.coachTimezone.coachli_timezone
                    }
                    username={getPublicProfileQuery.data.username}
                  />
                ) : (
                  <StyledNoUpcomingCallBox>
                    <p className="info-text">
                      This profile does not have any live event at the moment. Check again
                      soon!
                    </p>
                  </StyledNoUpcomingCallBox>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </StyledPublicProfileContainer>
      </StyledPublicProfileWrapper>
      <Footer />
    </div>
  );
};

export {PublicProfile};
